import React, { FC, useState } from "react";
import { RulesStatusByCategory } from "../../../../../models/evaluations/ServiceStatsByScoreCard";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import Tooltip from "components/octo-ui/Tootlip/Tooltip";

interface ServiceRulesListPerScoreCardComponentProps {
  rulesStatusByCategory: RulesStatusByCategory;
}

const TABS = {
  ALL: "All",
  SUCCESS: "Success",
  FAILURE: "Failure",
};

const ServiceRulesListPerScoreCardComponent: FC<
  ServiceRulesListPerScoreCardComponentProps
> = ({ rulesStatusByCategory }) => {
  const [filter, setFilter] = useState(TABS.ALL);
  const [categoryFilter, setCategoryFilter] = useState("all");

  const rulesCategoriesNames = Object.keys(rulesStatusByCategory);

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCategoryFilter(event.target.value);
  };

  return (
    <div id="service-scorecard-rules-container" className="space-y-6">
      <div className="mx-auto p-4 sm:px-6 lg:px-8">
        <div className="flex justify-between mb-4">
          <div>
            {Object.values(TABS).map((tab) => (
              <button
                key={tab}
                className={`px-4 py-2 rounded mr-2 ${
                  filter === tab ? "bg-gray-200" : ""
                }`}
                onClick={() => setFilter(tab)}
              >
                {tab}
              </button>
            ))}
          </div>

          <div className="relative inline-flex">
            <svg
              className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 412 232"
            >
              <path
                d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.763-9.763 25.592 0 35.355l181 181c9.763 9.763 25.592 9.763 35.355 0l181-181c9.762-9.763 9.762-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                fill="#648299"
                fill-rule="nonzero"
              />
            </svg>
            <select
              value={categoryFilter}
              onChange={handleCategoryChange}
              className="border border-gray-300 rounded text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none"
            >
              <option value="all">All Categories</option>
              {rulesCategoriesNames.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div id="rules-by-category-list" className="bg-white p-4 rounded">
          {rulesCategoriesNames.map((category, index) => {
            if (categoryFilter !== "all" && category !== categoryFilter)
              return null;

            const rules = rulesStatusByCategory[category];
            const successfulRules = rules?.filter(
              (rule) => rule.isRuleEvaluationSuccessful
            );
            const failedRules = rules?.filter(
              (rule) => !rule.isRuleEvaluationSuccessful
            );

            let displayRules = rules;
            if (filter === TABS.SUCCESS) {
              if (!successfulRules || successfulRules.length === 0) {
                return (
                  <div key={index} className="my-4">
                    <p>No successful rules in category {category}</p>
                  </div>
                );
              }
              displayRules = successfulRules;
            }
            if (filter === TABS.FAILURE) {
              if (!failedRules || failedRules.length === 0) {
                return (
                  <div key={index} className="my-4">
                    <p>No failed rules in category {category}</p>
                  </div>
                );
              }
              displayRules = failedRules;
            }

            return (
              <div key={index} className="my-4">
                <div className="px-4 py-2 border-b border-black mb-4 flex items-center gap-6">
                  <h3 className="font-semibold text-xl">{category}</h3>
                  <p className="text-sm">{displayRules.length} Rules</p>
                </div>

                {displayRules?.map((rule, key) => (
                  <div
                    key={key}
                    className=" p-3 mb-2 flex items-center justify-between"
                  >
                    {rule.isRuleEvaluationSuccessful ? (
                      <CheckCircleIcon className="h-6 text-green-600 mr-6" />
                    ) : (
                      <XCircleIcon className="h-6 text-red-500 mr-6" />
                    )}
                    <div className="flex-1 my-1 pr-4">
                      <Tooltip direction="top" content={[rule.ruleExpression]}>
                        <p className="font-medium text-md p-1 cursor-default">
                            {rule.ruleName}
                        </p>
                      </Tooltip>
                      {!rule.isRuleEvaluationSuccessful && rule.actualValue && (
                        <p className="text-xs text-gray-400">
                          Actual value: {rule.actualValue}
                        </p>
                      )}
                      {rule.evaluationErrors && (
                        <div className="">
                          {rule.evaluationErrors.map((errorDescription, i) => (
                            <p
                              key={`rule-error-${i}`}
                              className="text-xs text-gray-400"
                            >
                              {errorDescription}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="flex items-center">
                      <span className="mr-2 text-sm font-medium bg-cyan-200 text-black px-2 py-0.5">
                        {rule.ruleScore} PTS
                      </span>
                    </div>
                  </div>
                ))}
                {index !== rulesCategoriesNames.length - 1 && (
                  <hr className="my-4" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServiceRulesListPerScoreCardComponent;
