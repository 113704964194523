export const API_ENDPOINTS = {
  /* Teams */
  getTeams: () => `/api/v2/teams`,
  getTeamById: (id: string) => `/api/v2/teams/${id}`,
  updateTeam: (id: string) => `/api/v2/teams/${id}`,
  deleteTeam: (id: string) => `/api/v2/teams/${id}`,
  createTeam: () => `/api/v2/teams`,
  createTeamMember: (teamId: string) => `/api/v2/teams/${teamId}/members/bulk`,
  detachTeamMember: (teamId: string) =>
    `/api/v2/teams/${teamId}/members/detach`,
  assignServices: (teamId: string) => `/api/v2/teams/${teamId}/services/bulk`,
  getTeamsRankings: () => `/api/v2/teams/evaluations`,
  getTeamMembers: (teamId: string) => `/api/v2/teams/${teamId}/members`,
  getTeamServices: (teamId: string) => `/api/v2/teams/${teamId}/services`,
  getTeamHealth: () => `/api/v2/dashboard/TEAMS_HEALTH`,

  /* Users */
  getUsers: () => `/api/v2/users`,
  createUsersBulk: () => `/api/v2/users/bulk`,
  getProfile: () => `/api/v2/users/me`,
  createAccount: () => `/api/v2/public/accounts`,
  updateProfile: () => `/api/v2/users/me`,

  /* Services */
  getServices: () => `/api/v2/services`,
  getServicesRich: () => `/api/internal/v1/services`,
  getEvaluatedServices: () => `/api/v2/services/evaluations`,
  getServiceById: (id: string) => `/api/v2/services/${id}`,
  getServiceHealth: () => `api/v2/dashboard/SERVICES_HEALTH`,
  createServices: () => "/api/internal/v1/services/bulk",

  /* Services scores */
  getServicesScores: () => `/api/internal/v1/score/services`,

  /* ScoreCards */
  getScoreCards: () => `/api/internal/v1/scorecards`,
  getScoreCardById: (id: string) => `/api/internal/v1/scorecards/${id}`,
  getRules: () => `/api/v2/scorecards/rules`,

  /* Evaluations */
  getEvaluationsLevels: () => `/api/v2/evaluations/levels`,
  getLatestEvaluation: (id: string) =>
    `api/internal/v1/evaluations/service/${id}/last`,
  evaluateService: (scorecardId: string, serviceId: string) =>
    `/api/internal/v1/evaluations/scorecard/${scorecardId}/evaluate/${serviceId}`,

  /* Initiatives */
  createInitiative: () => `/api/v2/initiatives`,
  addInitiativeRules: (id: string) => `/api/v2/initiatives/${id}/rules`,
  addInitiativeServices: (id: string) => `/api/v2/initiatives/${id}/services`,
  getInitiatives: () => `/api/v2/initiatives`,
  getInitiativeById: (id: string) => `/api/v2/initiatives/${id}`,
  getInitiativeRules: (id: string) => `/api/v2/initiatives/${id}/rules`,
  getInitiativeServices: (id: string) => `/api/v2/initiatives/${id}/services`,
  updateInitiatives: () => `/api/v2/initiatives`,
  updateInitiative: (id: string) => `/api/v2/initiatives/${id}`,

  /*SelfService*/
  getClusters: () => `api/internal/v1/self-service/clusters`,
  getKubeConfig: (id: string) =>
    `api/internal/v1/self-service/clusters/${id}/kubeconfig`,
  getClusterDetails: (id: string) =>
    `/api/internal/v1/self-service/clusters/${id}`,
  getClusterFoundations: (id: string) =>
    `/api/internal/v1/self-service/clusters/${id}/foundations`,
  deleteCluster: (id: string) => `/api/internal/v1/self-service/clusters/${id}`,
  getClusterManagedRessources: (id: string) =>
    `/api/internal/v1/self-service/clusters/${id}/managed-resources`,

  /* Invitations */
  getInvitations: () => `/api/v2/public/invitations`,

  /* Roadmaps */
  createRoadmap: () => `/api/v2/roadmaps`,
  addRoadmapInitiatives: (id: string) => `/api/v2/roadmaps/${id}/initiatives`,
  getRoadmaps: () => `api/v2/roadmaps`,
  getRoadmap: (id: string) => `api/v2/roadmaps/${id}`,

  /* Dashboards */
  createDashboard: () => `/api/v2/dashboard/create`,
  deleteDashboard: (id: string) => `/api/v2/dashboard/${id}`,
  editDashboard: (id: string) => `/api/v2/dashboard/${id}`,
  getWidgetData: (key: string) => `/api/v2/dashboard/${key}`,
  // configurations
  getIntegrationsConfig: () => "/api/internal/v1/config",
  getIntegrationInstances: ()=> "/api/v2/integrations-instances",
  getIntegrationConnectivityTest: (integrationKey: string) =>
    `/api/internal/v1/config/${integrationKey}/connection_validations`,

  // discovery
  getImportedServices: (provider: string) =>
    `/api/internal/v1/services/discovery/${provider}`,
  importTeams: () => "/api/v2/teams/discovery",
  importMembers: () => "/api/v2/users/discovery",

  //config
  updateConfig: (integrationKey: string) =>
    `/api/internal/v1/config/${integrationKey}`,

  // metrics
  getPullRequests: () => "/api/v2/metrics/pull-requests",
  getFilteredPullRequests: () => "/api/v2/metrics/pull-requests/filter",

  /* domains */
  getDomains: () => "/api/v2/domains",
  createDomain: () => "/api/v2/domains",
  updateDomain: (id: string) => `/api/v2/domains/${id}`,
  deleteDomain: (id: string) => `/api/v2/domains/${id}`,

  // instances
  createIntegrationInstance: () => "/api/v2/integrations-instances",
  updateIntegrationInstance: (id: string) => `/api/v2/integrations-instances/${id}`,
  deleteIntegrationInstance: (id: string) => `/api/v2/integrations-instances/${id}`,
  getInstanceValidity: (id: string) => `/api/v2/integrations-instances/${id}/validate`,
  
  //resources
  getResources: () => "/api/v2/resources",
  deleteResource: (id: string) => `/api/v2/resources/${id}`,

  // integrations
  getIntegrationsStatus: () => "/api/internal/v1/integrations/templates/status",
  createResource: () => "/api/v2/resources",
};
