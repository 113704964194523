import { STEPPER_MODE } from "hooks/stepper/constant";
import { useMemo, useState } from "react";
import { StepDataType, StepperModeType, StepType } from "types/Stepper";

export interface UseStepperProps {
  steps: StepType[];
  defaultStep: string;
  mode?: StepperModeType;
  displaySteps?: boolean;
}

function sortByOrder(stepOne: StepType, stepTwo: StepType) {
  return stepOne.order - stepTwo.order;
}

export const useStepper = <T extends StepDataType>({
  mode = STEPPER_MODE.STEP,
  displaySteps = true,
  ...props
}: UseStepperProps) => {
  const { steps, defaultStep } = props;
  const [currentStep, setCurrentStep] = useState(
    () => steps.find((step) => step.stepKey === defaultStep) ?? steps[0]
  );
  const [stepData, setStepData] = useState<StepDataType>({});

  const [visitedSteps, setVisitedSteps] = useState<string[]>([defaultStep]);

  const indexes = useMemo(
    () => steps.sort(sortByOrder).map((step) => step.stepKey),
    [steps]
  );

  const addStepToVisited = (stepKey: string) => {
    if (!visitedSteps.includes(stepKey)) {
      setVisitedSteps((prev) => [...prev, stepKey]);
    }
  };

  const removeStepFromVisited = (stepKey: string) => {
    setVisitedSteps((prev) => prev.filter((key) => key !== stepKey));
  };

  const goNextStep = () => {
    const nextIndex = indexes.indexOf(currentStep.stepKey) + 1;
    if (nextIndex >= indexes.length) {
      return;
    }

    const nextStep = steps[nextIndex];
    setCurrentStep(nextStep);
    addStepToVisited(nextStep.stepKey);
  };
  const goPrevStep = () => {
    const prevIndex = indexes.indexOf(currentStep.stepKey) - 1;

    if (prevIndex < 0) {
      return;
    }

    const prevStep = props.steps[prevIndex];
    setCurrentStep(prevStep);
    removeStepFromVisited(currentStep.stepKey);
  };

  const goOnChange = (stepKey: string) => {
    const targetStep = steps.find((step) => step.stepKey === stepKey);

    if (targetStep) {
      setCurrentStep(targetStep);
      setVisitedSteps([targetStep.stepKey]);
    }
  };

  const isCurrentStep = (stepKey: string) => {
    return stepKey === currentStep?.stepKey;
  };
  const isStepVisited = (stepKey: string) => {
    return visitedSteps.includes(stepKey);
  };

  const onSubmit = (data: Partial<T>) => {
    setStepData((prevState) => ({
      ...prevState,
      ...data,
    }));
    // do we make this logic outside or let it be here?
    goNextStep();
  };

  return {
    currentStep,
    goNextStep,
    goPrevStep,
    isCurrentStep,
    isStepVisited,
    steps,
    stepData,
    onSubmit,
    goOnChange,
    mode,
    displaySteps,
  };
};
