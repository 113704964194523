import { Heading, Text } from "_sredx/_ui";
import { ProgressStepper } from "_sredx/components/ProgressStepper";
import { ServiceBaseDto } from "_sredx/types";
import { useState } from "react";
import { OnboardingServicesImportStepContainer } from "_sredx/containers";
import { OnboardingTeamsImportStepContainer } from "_sredx/containers";
import { OnboardingUsersImportStepContainer } from "_sredx/containers";
import { ProviderConfigurationStepContainer } from "_sredx/containers";
import styles from "./AdminOnboardingContainer.module.css";
import { OrderedAdminOnboardingSteps, OrderedStep } from "./types";


export const AdminOnboardingContainer = ( ) => {
  const [currentStep, setCurrentStep] = useState<OrderedStep>(OrderedAdminOnboardingSteps[0]);

  const [providerValidationsResults, setProviderValidationsResults] = useState<
    Record<string, boolean>
  >({});
  const [importedTeamsIds, setImportedTeamsIds] = useState<string[]>([]);

  const handleOnAddProviderValidationResult = (
    provider: string,
    result: boolean
  ) => {
    setProviderValidationsResults((prevResults) => ({
      ...prevResults,
      [provider]: result,
    }));
  };

  const handleOnNextStep = () => {
    setCurrentStep(OrderedAdminOnboardingSteps[currentStep.step]);
  };

  const handleOnPreviousStep = () => {
    setCurrentStep(OrderedAdminOnboardingSteps[currentStep.step - 2]);
  };

  const [checkedServices, setCheckedServices] = useState<ServiceBaseDto[]>([]);

  const handleNextStep = (stepKey: string) => {
    const step = OrderedAdminOnboardingSteps.find(
      (step) => step.key === stepKey
    );
    if (step) setCurrentStep(step);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading_wrapper}>
        <Heading level={1}>Welcome !</Heading>
        <Text> Step by step: your guide to getting started </Text>
      </div>

      <ProgressStepper
        steps={OrderedAdminOnboardingSteps}
        currentStep={currentStep.step}
      />

      <Text className={styles.title}>{currentStep.label}</Text>

      {currentStep == OrderedAdminOnboardingSteps[0] && (
        <ProviderConfigurationStepContainer
          providerValidationResults={providerValidationsResults}
          handleAddProviderValidationResult={
            handleOnAddProviderValidationResult
          }
          onNextStep={handleOnNextStep}
        />
      )}

      {currentStep == OrderedAdminOnboardingSteps[1] && (
        <OnboardingServicesImportStepContainer
          providersValidationResults={providerValidationsResults}
          onNextStep={handleOnNextStep}
          onPreviousStep={handleOnPreviousStep}
          selectedServices={checkedServices}
          setSelectedServices={setCheckedServices}
        />
      )}

      {currentStep == OrderedAdminOnboardingSteps[2] && (
        <OnboardingTeamsImportStepContainer
          selectedServices={checkedServices}
          onPreviousStep={handleOnPreviousStep}
          setImportedTeamsIds={setImportedTeamsIds}
          setNextStep={handleNextStep}
        />
      )}

      {currentStep == OrderedAdminOnboardingSteps[3] && (
        <OnboardingUsersImportStepContainer
          onNextStep={handleOnNextStep}
          onPreviousStep={handleOnPreviousStep}
          importedTeamsIds={importedTeamsIds}
        />
      )}
    </div>
  );
};
