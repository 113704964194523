import { forwardRef } from "react";
import { IconProps } from "./types";

export const NetworkIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          d="M19.568 16.267a2.419 2.419 0 0 0-1.428.466l.006-.005-2.127-1.892a4.145 4.145 0 0 0-.137-5.192l.006.008 2.35-2.3c.376.247.84.394 1.337.394 1.34 0 2.425-1.062 2.425-2.373C22 4.063 20.915 3 19.575 3c-1.34 0-2.424 1.062-2.424 2.373 0 .488.15.941.408 1.318l-.006-.009-2.35 2.3a4.35 4.35 0 0 0-2.736-.958c-.604 0-1.179.121-1.701.34l.028-.01-.665-1.183c.51-.434.83-1.07.83-1.779 0-1.3-1.077-2.354-2.405-2.354-1.327 0-2.404 1.055-2.404 2.354 0 1.3 1.077 2.354 2.404 2.354h.015c.256-.004.5-.047.73-.123l-.017.005.668 1.189c-1.133.784-1.863 2.06-1.866 3.504.007.44.08.862.213 1.257l-.008-.03-.918.38a2.415 2.415 0 0 0-1.97-1.007C4.073 12.92 3 13.972 3 15.27c0 1.297 1.074 2.349 2.4 2.349 1.326 0 2.4-1.052 2.4-2.35 0-.166-.017-.328-.05-.484l.002.015.915-.38a4.357 4.357 0 0 0 3.8 2.2 4.375 4.375 0 0 0 2.902-1.091l-.004.003 2.134 1.898a2.283 2.283 0 0 0-.348 1.203c0 1.307 1.083 2.367 2.419 2.367 1.335 0 2.418-1.06 2.418-2.367s-1.083-2.366-2.418-2.366h-.002ZM7.116 5.379c0-.784.649-1.42 1.45-1.42.802 0 1.451.636 1.451 1.42 0 .785-.649 1.42-1.45 1.42-.801 0-1.45-.636-1.451-1.42ZM5.38 16.69c-.801 0-1.45-.635-1.45-1.42 0-.783.65-1.418 1.45-1.418.801 0 1.45.634 1.45 1.418 0 .784-.65 1.42-1.45 1.42ZM19.568 3.96c.802 0 1.451.636 1.451 1.42 0 .785-.65 1.42-1.45 1.42-.802 0-1.452-.635-1.452-1.42.002-.783.65-1.418 1.451-1.42ZM9.05 12.322c0-1.851 1.533-3.351 3.424-3.351 1.89 0 3.424 1.5 3.424 3.35s-1.533 3.351-3.424 3.351c-1.89-.002-3.421-1.5-3.424-3.35Zm10.518 7.731c-.801 0-1.45-.635-1.45-1.42 0-.784.649-1.42 1.45-1.42.802 0 1.451.636 1.451 1.42-.001.784-.65 1.419-1.45 1.42Z"
        />
      </svg>
    );
  }
);
