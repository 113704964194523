import { useMutation, useQueryClient } from "react-query";
import { ApiErrorResponse, IntegrationInstanceUpdateDto } from "_sredx/types";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";

export const useUpdateIntegrationInstance = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, ApiErrorResponse, IntegrationInstanceUpdateDto>({
    mutationKey: [MUTATION_KEYS.updateInstance],
    mutationFn: (dto) => {
      return ClientApi.patch(
        API_ENDPOINTS.updateIntegrationInstance(dto.id),
        dto
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.integrationInstances], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.servicesDiscovery], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.connectionTest], {
        exact: false,
      });
    },
  });
};
