import { Loader, Text } from "_sredx/_ui";
import { ServiceDeleteModal } from "_sredx/components/ServiceDeleteModal";
import { useModal } from "_sredx/hooks";
import { useInfiniteGetEvaluatedServices } from "_sredx/services";
import { ServiceSearchCriteria } from "_sredx/types";
import { ServiceCard } from "components/service/ServiceCard/ServiceCard";
import { EditServiceForm } from "components/service/ServiceForm";
import { useDeleteService } from "hooks/useDeleteService";
import Service from "models/service/Service";
import { useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { SearchParams } from "../types";
import { mapInfiniteServices } from "./helpers";
import styles from "./ServiceListingCardContainer.module.css";

interface ServiceListingCardContainerProps
  extends SearchParams<ServiceSearchCriteria> {}

export const ServiceListingCardContainer = ({
  query,
}: ServiceListingCardContainerProps) => {
  //hooks
  const { openModal, closeModal } = useModal();
  const {
    data: infiniteServices,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteGetEvaluatedServices({
    params: {
      ...query,
      pageSize: 12,
    },
  });
  const { ref } = useInView({
    threshold: 0,
    onChange: async (inView) => {
      if (inView && hasNextPage) {
        await fetchNextPage();
      }
    },
  });
  const deleteServiceMutation = useDeleteService();

  //derived Data
  const services = useMemo(
    () => mapInfiniteServices(infiniteServices),
    [infiniteServices]
  );

  // handlers
  const handleDeleteService = (service: Service) => {
    openModal({
      content: (
        <ServiceDeleteModal
          service={{ name: service.name, id: service.id }}
          onClose={closeModal}
          deleteMutation={deleteServiceMutation}
        />
      ),
    });
  };
  const handleEditService = (service: Service) => {
    openModal({
      content: <EditServiceForm service={service} hide={closeModal} />,
    });
  };

  if (isLoading)
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text>Loading Services...</Text>
      </div>
    );

  return (
    <>
      <div className={styles.cardlist_wrapper}>
        {services.map((service) => (
          <ServiceCard
            clickable
            key={service.id}
            service={service as Service}
            renderFooter
            onDelete={handleDeleteService}
            onEdit={handleEditService}
            link={`/services/${service.id}`}
          />
        ))}
        <div ref={ref}></div>
      </div>
      {isFetching && (
        <div className={styles.fetching_wrapper}>
          <Loader size={"large"} />
        </div>
      )}
    </>
  );
};
