import { useGetLatestEvaluation } from "_sredx/services/evaluations/useGetLatestEvaluation";
import { useParams } from "react-router-dom";
import styles from "./ServiceDetailsScorecardsContainer.module.css";
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  Loader,
  Text,
} from "_sredx/_ui";
import { useEvaluateService, useGetEvaluationsLevels, useGetScoreCards } from "_sredx/services";
import { ScorecardRowAccordionHeader } from "_sredx/components";
import { ScorecardRowAccordionContainer } from "_sredx/components/ScorecardRowAccordionContainer";
import { extractRules } from "./utils";

export const ServiceDetailsScorcardsContainer = () => {
  const { serviceId } = useParams();
  const { data: scorecards, isLoading: isLoadingScorecards } = useGetScoreCards(
    { includeOverview: false }
  );
  const { data: evals } = useGetLatestEvaluation({
    serviceId: serviceId ?? "",
  });

  const { data: latestEvalLevel } = useGetEvaluationsLevels({
    params: {
      serviceIds: [serviceId ?? ""],
    },
  });

  const { mutate } = useEvaluateService();

  const handleEvaluate = (scorecardId: string) => {
    if (!serviceId) return;
    mutate({
      serviceId,
      scorecardId,
    });
  };

  if (isLoadingScorecards)
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text>Loading Scorecards...</Text>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      {scorecards?.map((scorecard) => {
        if (evals?.find((e) => e?.scoreCardId === scorecard?.id)) {
          const extractedRules = extractRules(evals, scorecard);
          return (
            <Accordion key={scorecard.id}>
              {({ isOpen, toggle }) => (
                <>
                  <AccordionHeader toggle={toggle}>
                    <ScorecardRowAccordionHeader
                      scorecard={scorecard}
                      evaluation={evals?.find(
                        (e) => e?.scoreCardId === scorecard?.id
                      )}
                      level={
                        latestEvalLevel?.find(
                          (e) => e.scorecard.scoreCardId === scorecard.id
                        )?.currentLevel
                      }
                      isOpen={isOpen}
                      onEvaluate={handleEvaluate}
                    />
                  </AccordionHeader>
                  <AccordionContent isOpen={isOpen}>
                    <ScorecardRowAccordionContainer
                      rules={extractedRules}
                      possibleLevels={scorecard.levels ?? []}
                      evaluationLevels={latestEvalLevel?.find(
                        (e) => e.scorecard.scoreCardId === scorecard.id
                      )}
                    />
                  </AccordionContent>
                </>
              )}
            </Accordion>
          );
        }
      })}
    </div>
  );
};
