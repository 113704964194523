import { forwardRef } from "react";
import { IconProps } from "./types";

export const ArgoIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="9.3 -8.3 64 64"
        {...props}
        ref={ref}
      >
        <title>Argo cd</title>
        <g fill="#fefefe">
          <path d="M37.7 43.6v.8l.2-.6c0-.3-.3-.1-.2-.2zm14.5-18.8zM43 1.7z" />
        </g>
        <path
          d="m50.8 48.1.2-2.4c.2-1.7 0-3.5.4-5.3.3-.8-.5-1.9.5-2.7l-.3-.7c-.2-.5 0-.9 0-1.3 0-1.5 0-3 .3-4.6l.3-6.3c-4.5 2-8.8-.8-9.5-4.3-.5-2.3-.2-4.5 1.6-6.2l.8-1C45.8 9 45.3 5 41.6 2c.5-.3 1-.2 1.5-.2C38.5.5 33 3.7 31 8.4c-.5 1.4-.8 3-.7 4.5 4.5-2.1 8.6.4 9.7 4.2 1.2 4-2 8.2-6 8.2-1.2 0-2.4-.2-3.5-.5l.2 6.6c.2 1.4.4 2.8.3 4.3v1.7c.3 1.4.4 2.8.5 4.2a.8.8 0 0 1 0-.1v.1l.3 7.2c.1 1-.2 1.5-1.2 1.7l-3.2 1c1.5.2 2.9.3 4.3.2 1.5 0 2.2-.7 2.4-2.2l.2-2.9.3-5.2h.2v5l.1 2.2c.2 3.5.3 3.5-3.2 4.2l-.2.1.6.4.2.1h2.5c1.5 0 2.2-.9 2.3-2.4 0-1 .2-2 .3-2.9a13 13 0 0 1 .3-3.7v-.7s0-.1 0 0c-.2 0 0-.2 0 .1l.2-.4V51c0 2.5-.1 2.7-2.5 3.4l-2 .6c-.4.1-1 0-1.4.6 2 0 3.7.1 5.5-.2 1.8-.3 2.5-1 2.9-2.8.3-1.3.4-2.6.9-4l.1.3.8 3.7c.3 1.8 1 2.5 2.8 2.8 1.8.3 3.6.1 5.5.2-.2-.5-.7-.4-1-.5l-3.5-1c-.9-.4-1.5-.9-1.5-2v-8.4h.4l.4 5.3c.2 4.1.7 4.6 4.8 4.3.2-.4.8 0 1-.5-1 0-1.9-.5-2.8-.8-.5-.1-.8-.6-.8-1.2 0-2.9 0-5.8.4-8.6l.6 7.2c0 1.2.6 2 1.8 2.1h4.8c-.8-.4-1.7-.6-2.6-.9-2-.6-2-.6-1.8-2.6zM38.4 6.8A1.5 1.5 0 0 1 37 8.2c-.9 0-1.6-.4-1.6-1.6.1-.8.6-1.3 1.6-1.4 1 0 1.4.7 1.4 1.6zm6.6 24c0 2.3-1.7 4.2-3.6 4.2-1.8 0-3.7-2.1-3.7-4.2l-.3-2c1.3.8 2.4 1.8 4 1.8 1.4 0 2.6-.8 3.9-1.7.2.8-.4 1.3-.3 2z"
          fill="#fe733e"
        />
        <path
          d="m31 35.7-.2-.1a22.1 22.1 0 0 1-12-16.9c-.8-6.5.7-12.5 5-17.7 4-4.7 9-7.5 15-8.1a22 22 0 0 1 18 5.8C61 2.6 63.3 7.4 64 13c.5 4.8-.4 9.4-2.8 13.7-2.3 3.8-5.3 6.9-9.3 8.9l-.3.1c-.2.4-.3.9 0 1.3l.2-.3c6.1-3.2 10.4-8 12.3-14.7.2-.5.5-.8 1.1-.8.9.1 1.1-.4 1.1-1.2v-8.2c0-.6-.1-1.2-.7-1.3-1.2-.1-1.3-1-1.5-1.8a22 22 0 0 0-7.7-11.4 22.9 22.9 0 0 0-25.2-3.1c-6.7 3-11 8.3-12.8 15.4-.2.5-.1 1-.9.8-1-.2-1.4.4-1.4 1.4V20c0 1 .4 1.4 1.3 1.2.8-.2 1 .2 1.2.8.7 2.7 1.9 5 3.5 7.3 2.4 3.2 5.3 5.8 9 7.4l-.1-1z"
          fill="#b5d2f3"
        />
        <path
          d="M31.1 37.4c-.8 2.3-3 2.2-4.8 2.8 1.4.4 2.8.8 4.2.5.8-.1 1 .2 1 .7v.1l.1.1c0-1.4-.2-2.8-.5-4.2z"
          fill="#fe684b"
        />
        <path
          d="M51.4 40.5c1.8.8 3.5 0 5.2-.3-1.7-.7-3.9-.3-4.7-2.5-1 .8-.2 1.9-.5 2.7z"
          fill="#fe6446"
        />
        <path
          d="m38 43.4-.1.4-.1.6a13 13 0 0 0-.3 3.7c.4.2 0 .8.5 1v-5.7z"
          fill="#fed6c8"
        />
        <path d="m34.7 41.4-.3 5.2.5-.3v-5h-.2z" fill="#feebe3" />
        <path d="M29.6 53.5h2.8l-.2-.2-.6-.4a4 4 0 0 0-2 .6z" fill="#fe9166" />
        <path
          d="M50.2 53.5h2.7c-.5-.4-1-.6-1.7-.5-.2.4-.8 0-1 .5z"
          fill="#fe8f64"
        />
        <path
          d="M52 35.5c4-2 7-5 9.3-8.9 2.4-4.2 3.3-8.8 2.8-13.6a22.1 22.1 0 0 0-7.4-14.3A22 22 0 0 0 39-7.1c-6 .6-11.1 3.4-15 8a22.2 22.2 0 0 0-5.1 17.8 22.1 22.1 0 0 0 12 16.9h.3l-.3-4.2-.3-.3c-1.1-.8-2-1.8-3-2.8-.3-.3-.7-.5-.7-1-.6.5-1.1.2-1.5-.4-2.1-2.9-3.4-6-3.5-9.7-.2-3.1 0-6.2 1.4-9.2C25 4.2 27.5 1.3 31.1-1c.5-.3.9 0 1.3.3.4 0 .8-.2 1-.5v-.1C33-2 33-2.8 33.9-3c.8-.2 1.3.5 1.3 1.4C41.6-3.7 47.5-3 52.8 1.2c4 3 6.3 7.1 7 12.1A18.4 18.4 0 0 1 52.4 31a10 10 0 0 1-.3.2 24 24 0 0 0-.3 4.6l.3-.2z"
          fill="#e2f5fc"
        />
        <path
          d="M54.1 29.6c4.5-4.5 6.6-9.8 5.7-16.2-.7-5-3-9.1-7-12.2-5.3-4-11.2-5-17.6-2.8-.5.3-1 1-1.6.6l-.2-.1c-.2.3-.6.5-1 .5v.2C31.8.9 30.4 1.2 29.5 2a17.7 17.7 0 0 0-3.1 24 1.8 1.8 0 0 1 .3 1.3c0 .5.4.8.7 1 1 1 1.9 2 3 2.9l.3.2-.2-6.6c-2-1.2-3.3-2.8-3.6-5.2-.3-3 1-5.1 3.4-6.7-.1-1.6.2-3 .7-4.5 1.9-4.8 7.4-7.9 12-6.7 3 .5 5.5 1.8 7.3 4.4 1.4 2 2.2 4.3 2 6.8 1.7 1.3 3.1 2.8 3.3 5 .3 3.1-1 5.3-3.5 6.9l-.3 6.3.4-.1c.7-.4 1.3-.9 1.8-1.4z"
          fill="#cdecf6"
        />
        <path
          d="M46.7 12.6c1.9-1 3.7-.4 5.5.2h.2c.2-2.5-.6-4.7-2-6.7-1.8-2.6-4.3-4-7.3-4.4-.5 0-1-.1-1.5.2C45.3 5 45.8 9 45 13.4l1.6-.8z"
          fill="#fe6b3c"
        />
        <path
          d="M55.8 18c-.3-2.3-1.7-3.8-3.4-5.1l-.2-.1c-1.8-.6-3.6-1.1-5.5-.2l-1.3.6-.3.2-.8 1c-1.8 1.7-2 3.8-1.6 6.1.7 3.5 5 6.4 9.5 4.3 2.6-1.6 3.9-3.8 3.6-6.8zM49 24c-2.9 0-5.2-2.3-5.2-5.2 0-3 2.4-5.2 5.3-5.2 3 0 5.2 2.3 5.2 5.3S52 24 49 24z"
          fill="#fe6142"
        />
        <path
          d="M30.4 12.9c-2.4 1.5-3.7 3.7-3.4 6.7.3 2.3 1.7 4 3.6 5.2 1.1.3 2.3.5 3.5.5 4 0 7.2-4.3 6-8.2a6.8 6.8 0 0 0-9.7-4.3zM33.6 24c-3 0-5.3-2.2-5.3-5.2s2.3-5.2 5.3-5.3c3 0 5.3 2.4 5.3 5.3S36.5 24 33.6 24z"
          fill="#fe6143"
        />
        <path
          d="M38.4 31.3c-.2-.2-.3-.5-.7-.5 0 2 1.9 4.2 3.7 4.2 2 0 3.7-1.9 3.7-4.1-.8.3-1.3 1.1-2.1 1.4-1.7.4-3.2.3-4.6-1z"
          fill="#070909"
        />
        <path
          d="M45.3 29c-1.3.8-2.5 1.6-4 1.6s-2.6-1-3.9-1.9l.3 2.1c.4 0 .5.3.7.5 1.3 1.3 2.9 1.4 4.6 1 .8-.3 1.3-1 2-1.4-.1-.7.5-1.2.3-2z"
          fill="#fdfcfb"
        />
        <path
          d="M37 5.2c-1 0-1.5.6-1.6 1.4 0 1.2.7 1.6 1.6 1.6a1.5 1.5 0 0 0 1.4-1.4c0-.9-.4-1.6-1.4-1.6z"
          fill="#fea777"
        />
        <path
          d="M26.8 27.3a1.8 1.8 0 0 0-.3-1.3L25 24a17.4 17.4 0 0 1 4.5-22c1-.7 2.3-1 2.7-2.3l.1-.2c-.4-.3-.8-.6-1.4-.2-3.5 2.1-6 5-7.8 8.8a19.7 19.7 0 0 0 2.1 19c.4.5.9.8 1.5.3z"
          fill="#fdfefe"
        />
        <path
          d="M35.1-1.6c0-1-.4-1.6-1.3-1.4s-.8 1-.4 1.8a1.4 1.4 0 0 0 .1.2c.8.4 1.1-.3 1.7-.6z"
          fill="#fbfdfe"
        />
        <path
          d="M54.3 18.9c0-3-2.2-5.3-5.2-5.3-2.9 0-5.2 2.3-5.3 5.2A5.2 5.2 0 0 0 49 24c3 0 5.3-2.2 5.3-5.1zm-5 .6c-1 0-1.6-.6-1.7-1.5 0-1 .8-1.8 1.7-1.7 1 0 1.5.6 1.6 1.6-.1 1-.6 1.6-1.6 1.6zm-15.7-6c-3 0-5.3 2.3-5.3 5.3s2.3 5.3 5.3 5.2c3 0 5.3-2.3 5.3-5.2 0-3-2.4-5.3-5.3-5.2zm0 6c-1 0-1.6-.6-1.7-1.6 0-.9.8-1.7 1.7-1.6 1 0 1.5.6 1.6 1.6-.1 1-.6 1.6-1.6 1.6z"
          fill="#fefefe"
        />
        <path
          d="M49.3 16.3c-1-.1-1.8.7-1.7 1.7.1 1 .7 1.5 1.7 1.5s1.5-.7 1.6-1.6c-.1-1-.7-1.6-1.6-1.6z"
          fill="#090b0b"
        />
        <path
          d="M33.6 16.3c-1-.1-1.8.7-1.7 1.6.1 1 .7 1.6 1.7 1.6s1.5-.7 1.6-1.6c-.1-1-.7-1.6-1.6-1.6z"
          fill="#090c0c"
        />
      </svg>
    );
  }
);
