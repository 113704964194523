import { InitiativeDetailsContainer } from "_sredx/containers";
import { Card, PageContent, TabItem, Tabs } from "_sredx/_ui";
import { AppReturnButton } from "_sredx/Layout";
import styles from "./InitiativeDetailsPage.module.css";
import { NavLink, Outlet } from "react-router-dom";

export const InitiativeDetailsPage = () => {
  return (
    <PageContent className={styles.wrapper}>
      <AppReturnButton to={"/initiatives"} label={"Back to initiatives"} />
      <InitiativeDetailsContainer />

      <Card>
        <Tabs>
          <NavLink to="rules">
            {({ isActive }) => <TabItem label={"Rules"} active={isActive} />}
          </NavLink>
          <NavLink to="services">
            {({ isActive }) => <TabItem label={"Services"} active={isActive} />}
          </NavLink>
        </Tabs>
        <Outlet />
      </Card>
    </PageContent>
  );
};
