import {
  Button,
  ButtonLabel,
  List,
  ListItem,
  ListTitle,
  Text,
} from "_sredx/_ui";
import { RuleDto } from "_sredx/types";
import { RuleRow } from "./RuleRow";
import { InitiativeCreationRulesListAction } from "./types";
import styles from "./InitiativeCreationRulesList.module.css";

interface InitiativeCreationRulesListProps {
  rules: RuleDto[];
  onAction: (action: InitiativeCreationRulesListAction) => void;
  emptyMessage?: string;
  withAddAction?: boolean;
}

export const InitiativeCreationRulesList = ({
  rules,
  onAction,
  emptyMessage = "No rules",
  withAddAction = false,
}: InitiativeCreationRulesListProps) => {
  const handleOnAddService = () => {
    onAction({
      type: "CLICK_ADD_RULES",
    });
  };

  if (rules.length === 0) {
    return (
      <div className={styles.empty_wrapper}>
        <Text className={styles.empty_content}>{emptyMessage}</Text>
        {withAddAction && (
          <Button onClick={handleOnAddService} variant="neutral">
            <ButtonLabel>Add Rules</ButtonLabel>
          </Button>
        )}
      </div>
    );
  }
  return (
    <>
      <List>
        <ListTitle>Rules</ListTitle>
        {rules.map((rule) => (
          <ListItem key={rule.id}>
            <RuleRow rule={rule} onAction={onAction} />
          </ListItem>
        ))}
      </List>
      {withAddAction && (
        <div className={styles.action_wrapper}>
          <Button onClick={handleOnAddService} variant="neutral">
            <ButtonLabel>Add Rules</ButtonLabel>
          </Button>
        </div>
      )}
    </>
  );
};
