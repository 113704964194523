import { useDeleteDomain } from "_sredx/services";
import {
  Button,
  ButtonLabel,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Text,
} from "_sredx/_ui";
import { DomainBaseDto } from "_sredx/types";

import styles from "./DomainDeleteContainer.module.css";

interface DomainDeleteContainerProps {
  domain: DomainBaseDto;
  closeModal?: () => void;
}

export const DomainDeleteContainer = ({
  closeModal,
  domain,
}: DomainDeleteContainerProps) => {
  // services
  const { mutate: deleteDomain } = useDeleteDomain();

  // event handlers
  const handleOnDelete = () => {
    deleteDomain(domain.id);
    closeModal && closeModal();
  };

  const handleOnCancel = () => {
    closeModal && closeModal();
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Delete Domain</CardTitle>
      </CardHeader>
      <CardBody>
        <div className={styles.body}>
          <Text size={"md"}>
            are you sure you want to delete the <strong>{domain.name}</strong>{" "}
            domain ?
          </Text>
          <div className={styles.action_buttons}>
            <Button
              variant={"neutral"}
              ghost
              onClick={handleOnCancel}
              type={"button"}
            >
              <ButtonLabel>cancel</ButtonLabel>
            </Button>
            <Button onClick={handleOnDelete} variant={"danger"}>
              <ButtonLabel>delete</ButtonLabel>
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
