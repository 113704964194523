import { useState } from "react";
import { AdvancedFilterConfig } from "../types";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import styles from "./AdvancedFiltersProperty.module.css";
import { SelectProperty } from "../SelectProperty";

const getPropertyLabel = (
  config: AdvancedFilterConfig[],
  key?: AdvancedFilterConfig["key"]
) => {
  if (!key) return "Select property";
  const property = config.find((property) => property.key === key);
  return property?.label || key;
};

interface AdvancedFiltersPropertyProps {
  options: AdvancedFilterConfig[];
  disabledOptions?: AdvancedFilterConfig["key"][];
  value?: AdvancedFilterConfig["key"];
  onChange: (value: AdvancedFilterConfig["key"]) => void;
}
export const AdvancedFiltersProperty = ({
  options,
  onChange,
  disabledOptions = [],
  value,
}: AdvancedFiltersPropertyProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handlePropertyChange = (propertyKey: AdvancedFilterConfig["key"]) => {
    setPopoverOpen(false);
    onChange(propertyKey);
  };

  return (
    <div className={styles.wrapper}>
      <Popover onOpenChange={(val) => setPopoverOpen(val)} open={popoverOpen}>
        <PopoverTrigger open={popoverOpen}>
          {getPropertyLabel(options, value)}
        </PopoverTrigger>
        <PopoverContent className={styles.content}>
          <SelectProperty
            value={value}
            options={options}
            onChange={handlePropertyChange}
            disabledOptions={disabledOptions}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
