import { ExternalLinkIcon } from "@heroicons/react/solid";
import { TableCell, TableRow } from "_sredx/_ui";
import { ManagedResource } from "_sredx/types";
import StatusIndicator from "components/octo-ui/StatusIndicator/StatusIndicator";
import { resolveLogoText } from "components/self-service/ClusterCard/utils";
import { Link } from "react-router-dom";

interface ManagedResourcesDataTableRowProps {
  managedResource: ManagedResource;
}

export const ManagedResourcesDataTableRow = ({
  managedResource,
}: ManagedResourcesDataTableRowProps) => {
  const readinessState =
    managedResource.ready === "True" ? "success" : "danger";
  const syncState = managedResource.synced === "True" ? "success" : "danger";

  const readinessLabel =
    managedResource.ready === "True" ? "Ready" : "Not Ready";
  const syncLabel = managedResource.synced === "True" ? "Synced" : "Not synced";

  return (
    <TableRow>
      <TableCell>
        <span>{resolveLogoText(managedResource.type)}</span>
      </TableCell>
      <TableCell>
        <Link to={managedResource.link} target={"_blank"}>
          {managedResource.id}
          <ExternalLinkIcon className="h-4 w-4" />
        </Link>
      </TableCell>
      <TableCell>
        <StatusIndicator status={readinessState} label={readinessLabel} />
      </TableCell>
      <TableCell>
        <StatusIndicator status={syncState} label={syncLabel} />
      </TableCell>
      <TableCell>
        <span>{managedResource.age}</span>
      </TableCell>
    </TableRow>
  );
};
