import React from "react";
import clsx from "clsx";
import styles from "./TableHead.module.css";

interface TableHeadProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  width?: string;
}
export const TableHead = React.forwardRef<HTMLTableCellElement, TableHeadProps>(
  ({ className, width, children, align = "left", ...props }, ref) => {
    return (
      <th
        ref={ref}
        className={clsx("nemo-table-head", styles.wrapper, className)}
        align={align}
        {...props}
        style={{
          width: width,
        }}
      >
        {children}
      </th>
    );
  }
);
