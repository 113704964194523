import {
  Avatar,
  Card,
  Empty,
  Loader,
  Pagination,
  ProgressBar,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
} from "_sredx/_ui";
import { CodeIcon } from "_sredx/_ui/Icon";
import { INITIATIVE_SEARCH_CRITERIA_DEFAULT } from "_sredx/containers";
import { ServiceRichDto } from "_sredx/types";
import { resolveIntegration } from "_sredx/utils";
import styles from "./ServiceDatatable.module.css";
import { ServiceScore } from "./ServiceScore";

const SERVICE_TABLE_HEADER = [
  "Service",
  "Owners",
  "Integrations",
  "Last evaluation",
  "Levels",
  "Score",
  "Passing rules",
];

interface ServiceDatatableProps {
  isLoading?: boolean;
  isFetching: boolean;
  services?: ServiceRichDto[];
  onAddServiceClick?: () => void;
  onClickService: (serviceId: string) => void;
  totalPages?: number;
  currentPage?: number;
  totalElements?: number;
  onPageChange?: (page: number) => void;
}

export const ServiceDatatable = ({
  services = [],
  onClickService,
  onAddServiceClick = undefined,
  isLoading,
  isFetching,
  totalPages = 1,
  currentPage = 0,
  totalElements,
  onPageChange,
}: ServiceDatatableProps) => {
  //derived data
  const showPagination = totalPages > 1;
  const count =
    (INITIATIVE_SEARCH_CRITERIA_DEFAULT.pageSize || 10) * currentPage +
    services.length;

  // Events handlers
  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };
  if (isLoading) {
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading services...</Text>
        </div>
      </Card>
    );
  }
  if (!isLoading && services && services.length === 0) {
    return (
      <Card>
        <Empty
          message={"No Services found for your organization."}
          actionLabel={"Add new Service"}
          onActionClick={onAddServiceClick}
        />
      </Card>
    );
  }
  return (
    <Table>
      <TableHeader>
        {SERVICE_TABLE_HEADER.map((header) => (
          <TableHead key={header}>{header}</TableHead>
        ))}
      </TableHeader>
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {services.map((service) => (
          <TableRow
            className={styles.row_wrapper}
            key={service.id}
            onClick={() => onClickService(service.id)}
          >
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <div className={styles.service_icon}>
                  <CodeIcon />
                </div>
                <span className={styles.service_name}>{service.name}</span>
              </div>
            </TableCell>

            <TableCell>
              <div className={styles.teams_cell}>
                {service.owners.slice(0, 2).map((owner) => (
                  <Avatar
                    key={owner.id}
                    size={20}
                    name={owner.name}
                    rectangle
                    avatarStyle="identicon"
                  />
                ))}
                {service.owners.length > 2 && (
                  <span className={styles.more_owners}>
                    +{service.owners.length - 2}
                  </span>
                )}
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.integrations}>
                {service.tools.slice(0, 2).map((t,i) => (
                  <div key={i} className={styles.integration_icon}>
                    {resolveIntegration(t.key).icon}
                  </div>
                ))}
                {service.tools.length > 2 && (
                  <span className={styles.more_owners}>
                    +{service.tools.length - 2}
                  </span>
                )}
              </div>
            </TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
            <TableCell>
              <ServiceScore
                score={service.score}
                total={service.totalPossible}
              />
            </TableCell>
            <TableCell>
              <ServiceScore
                score={service.successfulRulesCount}
                total={service.allRulesCount}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableFooterCell colSpan={SERVICE_TABLE_HEADER.length}>
          <div className={styles.footer}>
            <span>
              {count} / {totalElements} services
            </span>
            {showPagination && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handleOnPageChange}
                currentPage={currentPage}
              />
            )}
          </div>
        </TableFooterCell>
      </TableFooter>
    </Table>
  );
};
