import {Button, ButtonLabel, List, ListItem, ListTitle, Text,} from "_sredx/_ui";
import {MemberRow} from "../MemberRow";
import {MemberListAction} from "../types";
import styles from "./MembersList.module.css";
import clsx from "clsx";
import {Member} from "_sredx/types";

interface MembersListProps {
  members?: Member[];
  onAction: (action: MemberListAction) => void;
  emptyMessage?: string;
  scrollable?: boolean;
  withAddAction?: boolean;
}

export const MembersList = ({
  members = [],
  emptyMessage = "No members",
  onAction,
  withAddAction = false,
  scrollable = false,
}: MembersListProps) => {
  const handleOnAddMember = () => {
    onAction({
      type: "CLICK_ADD_MEMBERS",
    });
  };

  if (members.length === 0) {
    return (
      <div className={styles.empty_wrapper}>
        <Text className={styles.empty_content}>{emptyMessage}</Text>
        {withAddAction && (
          <Button onClick={handleOnAddMember} variant="neutral">
            <ButtonLabel>Add Members</ButtonLabel>
          </Button>
        )}
      </div>
    );
  }
  return (
    <>
      <List>
        <ListTitle>Members</ListTitle>
        <div
          className={clsx(
            styles.list_wrapper,
            scrollable && styles.list_scrollable
          )}
        >
          {members.map((member) => (
            <ListItem key={member.email}>
              <MemberRow member={member} onAction={onAction} />
            </ListItem>
          ))}
        </div>
      </List>
      {withAddAction && (
        <div className={styles.action_wrapper}>
          <Button onClick={handleOnAddMember} variant="neutral">
            <ButtonLabel>Add Members</ButtonLabel>
          </Button>
        </div>
      )}
    </>
  );
};
