import { forwardRef, TextareaHTMLAttributes } from "react";
import clsx from "clsx";
import styles from "./TextArea.module.css";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  fullWidth?: boolean;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ fullWidth = false, ...props }) => {
    return (
      <textarea
        className={clsx(
          "nemo-text-area",
          styles.wrapper,
          fullWidth && styles.fullWidth
        )}
        {...props}
      />
    );
  }
);
