import { useAxios } from "hooks/useAxios";
import { useQuery } from "react-query";
import { ManagedResource } from "types/selfService";
import { SELF_SERVICE_PATHS } from "./paths";

const CLUSTER_MANAGED_RESOURCES_QUERY_KEY = "CLUSTER_MANAGED_RESOURCES";

export const useClusterManagedResources = (clusterName: string) => {
  const { axios } = useAxios();
  const fetchManagedRessources = async () => {
    const response = await axios(
      SELF_SERVICE_PATHS.CLUSTER_DETAIL_MANAGED_RESOURCES(clusterName)
    );
    return response.data;
  };

  return useQuery<ManagedResource[] | undefined, Error>(
    [CLUSTER_MANAGED_RESOURCES_QUERY_KEY, clusterName],
    fetchManagedRessources
  );
};
