
import { forwardRef } from "react";
import { IconProps } from "./types";

export const VeleroIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
        <svg
        id="svg2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <defs>
          <style>
            {
              ".cls-1{fill:#239de0;}.cls-2{fill:#fff;}.cls-3{isolation:isolate;}.cls-4{fill:#696566;}"
            }
          </style>
        </defs>
        <title>Velero</title>
        <g id="path14">
          <path
            className="cls-1"
            d="M153.24933,145.2828c-4.34533,0-6.42933,1.6532-8.84133,3.56774-2.61867,2.08013-5.58934,4.43586-11.32826,4.43586-5.7396,0-8.70894-2.35573-11.32813-4.43586-2.41147-1.91454-4.4948-3.56774-8.83854-3.56774-4.34533,0-6.42867,1.6532-8.84013,3.56774-2.61987,2.08013-5.58854,4.43586-11.32814,4.43586s-8.70839-2.35573-11.3276-4.43586c-2.412-1.91454-4.49534-3.56774-8.83906-3.56774s-6.42654,1.6532-8.83854,3.56774a19.10381,19.10381,0,0,1-6.38026,3.71453,69.69708,69.69708,0,0,0,40.34333,17.17507c.41813.0344.838.06773,1.2588.09586,1.5052.09733,3.0192.164,4.54893.164,1.53027,0,3.04427-.06667,4.54947-.164.4208-.02813.8396-.06146,1.25946-.09586a69.87987,69.87987,0,0,0,47.17-24.06667,12.79175,12.79175,0,0,0-3.24-.39066"
          />
        </g>
        <g id="path16">
          <path
            className="cls-1"
            d="M52.412,93.07081c4.724,0,7.05253-1.84787,9.51773-3.8052,2.60053-2.06454,5.2896-4.19947,10.6484-4.19947s8.04787,2.13494,10.64893,4.19947c2.46507,1.95733,4.7932,3.8052,9.51774,3.8052s7.05253-1.84787,9.51827-3.8052c2.60053-2.06454,5.29-4.19947,10.65-4.19947,5.35774,0,8.04787,2.13494,10.6484,4.19947,2.46454,1.95733,4.79267,3.8052,9.51827,3.8052s7.0536-1.84787,9.51893-3.8052c2.6-2.06454,5.29067-4.19947,10.65067-4.19947,5.35865,0,8.04933,2.13494,10.65066,4.19947,2.41734,1.91933,4.71867,3.724,9.264,3.79226a69.43253,69.43253,0,0,0-4.39067-18.32346,17.91343,17.91343,0,0,1-5.77867-3.43026c-2.51733-1.99733-4.89332-3.8844-9.74532-3.8844s-7.22933,1.88707-9.74533,3.8844c-2.55334,2.02613-5.192,4.1204-10.42427,4.1204s-7.87133-2.0948-10.42347-4.1204c-2.5156-1.99733-4.89227-3.8844-9.7432-3.8844-4.8516,0-7.22919,1.88707-9.74479,3.8844-2.55267,2.02613-5.19121,4.1204-10.42347,4.1204-5.23173,0-7.87027-2.0948-10.42241-4.1204-2.51613-1.99733-4.89267-3.8844-9.74427-3.8844-4.85106,0-7.2276,1.88707-9.7432,3.8844-2.55266,2.0256-5.1912,4.1204-10.42293,4.1204-5.23227,0-7.8708-2.0948-10.42293-4.1204-.5656-.44946-1.1276-.8896-1.7088-1.3072A69.42817,69.42817,0,0,0,35.07346,85.314c3.552.6032,5.7276,2.29014,7.82026,3.9516,2.46507,1.95734,4.79374,3.8052,9.51827,3.8052"
          />
        </g>
        <g id="path18">
          <path
            className="cls-1"
            d="M52.412,74.3328c4.85106,0,7.22813-1.88693,9.74373-3.88387,2.55213-2.02546,5.19067-4.12093,10.4224-4.12093s7.87026,2.09547,10.4224,4.12093c2.51667,1.99693,4.89267,3.88387,9.74427,3.88387s7.22814-1.88693,9.7448-3.88387c2.55214-2.02546,5.19014-4.12093,10.42347-4.12093,5.23173,0,7.8692,2.09547,10.42187,4.12093,2.51667,1.99693,4.8932,3.88387,9.7448,3.88387,4.85093,0,7.22826-1.88693,9.74426-3.88387,2.55334-2.02546,5.192-4.12093,10.42534-4.12093,5.232,0,7.872,2.09547,10.424,4.12093a18.94339,18.94339,0,0,0,4.56133,2.92293A69.97149,69.97149,0,0,0,40.7708,69.0036c.65987.46613,1.28187.95734,1.8964,1.44534,2.51666,1.99693,4.8932,3.88387,9.7448,3.88387"
          />
        </g>
        <g id="path20">
          <path
            className="cls-1"
            d="M153.24933,125.81613c-4.472,0-6.60267,1.69121-9.06667,3.64787-2.57334,2.0428-5.48934,4.35733-11.10293,4.35733s-8.52867-2.31453-11.1016-4.35733c-2.46613-1.95666-4.59534-3.64787-9.06507-3.64787-4.47132,0-6.60053,1.69121-9.06666,3.64787-2.57293,2.0428-5.48906,4.35733-11.1016,4.35733s-8.52867-2.31453-11.10161-4.35733c-2.4656-1.95666-4.59427-3.64787-9.06506-3.64787s-6.59947,1.69121-9.06507,3.64787c-2.57293,2.0428-5.488,4.35733-11.10106,4.35733-5.61254,0-8.52867-2.31453-11.1016-4.35733-.65254-.51813-1.28387-1.014-1.9396-1.4676a70.27086,70.27086,0,0,0,14.42093,21.2208c3.43013-.3156,5.31973-1.80267,7.4588-3.50107,2.61973-2.07866,5.58853-4.43587,11.3276-4.43587,5.7396,0,8.70827,2.3572,11.3276,4.43587,2.412,1.91454,4.49533,3.56773,8.83906,3.56773,4.34427,0,6.42706-1.65319,8.84013-3.56773,2.61814-2.07866,5.58854-4.43587,11.32814-4.43587s8.70773,2.3572,11.32653,4.43587c2.41307,1.91454,4.4964,3.56773,8.84013,3.56773,4.34426,0,6.42827-1.65319,8.83894-3.56773,2.62-2.07866,5.59068-4.43587,11.33067-4.43587a15.76134,15.76134,0,0,1,5.94532,1.0728,70.11763,70.11763,0,0,0,6.59068-10.44946,27.05781,27.05781,0,0,1-3.47067-2.43961c-2.464-1.95666-4.59466-3.64787-9.06533-3.64787"
          />
        </g>
        <g id="path22">
          <path
            className="cls-1"
            d="M173.30133,94.8776c-5.27067-.036-7.95334-2.14014-10.53334-4.18693-2.464-1.9568-4.79466-3.8052-9.51866-3.8052-4.72533,0-7.05468,1.8484-9.52,3.8052-2.6,2.06453-5.29067,4.19946-10.6496,4.19946-5.36,0-8.04853-2.13493-10.65-4.19946-2.4652-1.9568-4.79333-3.8052-9.51666-3.8052-4.72561,0-7.05374,1.8484-9.51827,3.8052-2.60214,2.06453-5.29067,4.19946-10.65,4.19946-5.3588,0-8.04787-2.13493-10.64894-4.19946-2.4652-1.9568-4.79319-3.8052-9.51773-3.8052-4.724,0-7.05213,1.8484-9.51773,3.8052-2.6,2.06453-5.2896,4.19946-10.6484,4.19946-5.35934,0-8.0484-2.13493-10.64893-4.19946-1.98654-1.57667-3.90627-3.06573-7.04014-3.5912a69.77211,69.77211,0,0,0-1.1432,10.19946c-.03493.898-.06933,1.7948-.06933,2.70107s.0344,1.80413.06933,2.70213c.01454.39106.02187.78427.0432,1.17387,4.59014.35053,7.19534,2.3792,9.49734,4.2068,2.4136,1.9156,4.69373,3.72546,9.29173,3.72546s6.8776-1.80986,9.29107-3.72546c2.52667-2.00627,5.38906-4.27867,10.87506-4.27867s8.34894,2.2724,10.87494,4.27867c2.41413,1.9156,4.69427,3.72546,9.29173,3.72546s6.87813-1.80986,9.2916-3.72546c2.52613-2.00627,5.38906-4.27867,10.87667-4.27867,5.48533,0,8.34893,2.2724,10.87439,4.27867,2.41467,1.9156,4.69427,3.72546,9.29227,3.72546,4.5976,0,6.87893-1.80986,9.29226-3.72546,2.52666-2.00627,5.39068-4.27867,10.87733-4.27867s8.34933,2.2724,10.876,4.27867c2.244,1.7808,4.39733,3.45054,8.37733,3.68027q.542-3.19461.788-6.47974c.06534-.85627.11867-1.714.15067-2.57813V97.29586c-.032-.8652-.14-2.41827-.14-2.41827"
          />
        </g>
        <g id="path24">
          <path
            className="cls-1"
            d="M153.24933,106.35107c-4.6,0-6.88,1.80986-9.29333,3.72653-2.52666,2.0052-5.39067,4.2776-10.87627,4.2776-5.486,0-8.34906-2.2724-10.8756-4.27761-2.41347-1.91666-4.69373-3.72653-9.29107-3.72653-4.598,0-6.87919,1.80987-9.29333,3.72653-2.526,2.0052-5.38907,4.27761-10.87493,4.27761s-8.34894-2.2724-10.87507-4.27761c-2.414-1.91666-4.69426-3.72653-9.2916-3.72653s-6.8776,1.80987-9.2912,3.72653c-2.526,2.0052-5.38906,4.27761-10.87493,4.27761-5.48546,0-8.34947-2.2724-10.8756-4.27761-2.12133-1.68387-4.14107-3.28333-7.71813-3.64733a69.41682,69.41682,0,0,0,3.72293,16.9504,19.40583,19.40583,0,0,1,5.8052,3.51827c2.4656,1.95733,4.59427,3.648,9.0656,3.648,4.4708,0,6.6-1.69067,9.06507-3.648,2.57293-2.04213,5.48853-4.3568,11.10107-4.3568s8.52867,2.31466,11.1016,4.3568c2.46506,1.95733,4.59426,3.648,9.06506,3.648s6.60054-1.69067,9.06507-3.648c2.57293-2.04213,5.4896-4.3568,11.10319-4.3568,5.612,0,8.52867,2.31466,11.10094,4.3568,2.4652,1.95733,4.5944,3.648,9.06573,3.648,4.47093,0,6.60026-1.69067,9.06559-3.648,2.57334-2.04213,5.49068-4.3568,11.104-4.3568,5.612,0,8.52932,2.31466,11.10266,4.3568a25.59594,25.59594,0,0,0,2.86133,2.062A69.3386,69.3386,0,0,0,172.04,114.28334c-4.58934-.34947-7.196-2.3792-9.49866-4.20573-2.41333-1.91667-4.69333-3.72653-9.292-3.72653"
          />
        </g>
        <g id="path26">
          <path
            className="cls-2"
            d="M141.91866,145.71614c-2.41066,1.91453-4.49466,3.56773-8.83893,3.56773-4.34373,0-6.42707-1.6532-8.84014-3.56773-2.61879-2.07867-5.58693-4.43587-11.32653-4.43587s-8.71,2.3572-11.32813,4.43587c-2.41307,1.91453-4.49586,3.56773-8.84013,3.56773-4.34374,0-6.42707-1.6532-8.83907-3.56773-2.61934-2.07867-5.588-4.43587-11.32761-4.43587-5.73906,0-8.70786,2.3572-11.3276,4.43587-2.13906,1.6984-4.02866,3.18546-7.4588,3.50106,1.14787,1.1588,2.34267,2.2708,3.5676,3.34787a19.10381,19.10381,0,0,0,6.38026-3.71453c2.412-1.91454,4.4948-3.56774,8.83854-3.56774s6.42707,1.6532,8.83906,3.56774c2.6192,2.08013,5.588,4.43586,11.32761,4.43586s8.70827-2.35573,11.32813-4.43586c2.41147-1.91454,4.4948-3.56774,8.84014-3.56774,4.34373,0,6.42706,1.6532,8.83853,3.56774,2.6192,2.08013,5.58853,4.43586,11.32813,4.43586,5.73894,0,8.7096-2.35573,11.32827-4.43586,2.412-1.91454,4.496-3.56774,8.84132-3.56774a12.79186,12.79186,0,0,1,3.24.39067c.932-1.08027,1.83867-2.1844,2.70533-3.3204a15.76114,15.76114,0,0,0-5.94532-1.0728c-5.74,0-8.71066,2.3572-11.33067,4.43587"
          />
        </g>
        <g id="path28">
          <path
            className="cls-2"
            d="M153.24933,122.54213c-5.61333,0-8.53067,2.31467-11.104,4.35681-2.46533,1.95733-4.59466,3.648-9.0656,3.648-4.47133,0-6.60053-1.69067-9.06573-3.648-2.57226-2.04213-5.48893-4.35681-11.10093-4.35681-5.6136,0-8.53027,2.31467-11.1032,4.35681-2.46454,1.95733-4.59427,3.648-9.06506,3.648s-6.6-1.69067-9.06507-3.648c-2.57293-2.04213-5.48906-4.35681-11.1016-4.35681S64.05,124.8568,61.47706,126.89894c-2.46506,1.95733-4.59426,3.648-9.06506,3.648-4.47134,0-6.6-1.69067-9.0656-3.648a19.40624,19.40624,0,0,0-5.8052-3.51827q.83359,2.3478,1.8296,4.61573c.65573.4536,1.28707.94946,1.9396,1.4676,2.57293,2.0428,5.48906,4.35733,11.1016,4.35733,5.61306,0,8.52813-2.31453,11.10106-4.35733,2.4656-1.95666,4.59427-3.64787,9.06507-3.64787s6.59946,1.69121,9.06506,3.64787c2.57294,2.0428,5.48907,4.35733,11.10161,4.35733s8.52867-2.31453,11.1016-4.35733c2.46613-1.95666,4.59534-3.64787,9.06666-3.64787,4.46974,0,6.59894,1.69121,9.06507,3.64787,2.57293,2.0428,5.488,4.35733,11.1016,4.35733s8.52961-2.31453,11.10293-4.35733c2.464-1.95666,4.59466-3.64787,9.06667-3.64787,4.47067,0,6.60133,1.69121,9.06533,3.64787a27.05807,27.05807,0,0,0,3.47067,2.4396q.748-1.45219,1.428-2.94267a25.5941,25.5941,0,0,1-2.86133-2.062c-2.57334-2.04213-5.49067-4.3568-11.10266-4.3568"
          />
        </g>
        <g id="path30">
          <path
            className="cls-2"
            d="M153.24933,103.80414c-5.48667,0-8.35067,2.27239-10.87733,4.27867-2.41333,1.9156-4.69466,3.72546-9.29227,3.72546-4.598,0-6.8776-1.80986-9.29227-3.72546-2.52546-2.00627-5.38906-4.27867-10.8744-4.27867-5.48759,0-8.35053,2.27239-10.87666,4.27867-2.41347,1.9156-4.69427,3.72546-9.2916,3.72546s-6.8776-1.80986-9.29174-3.72546c-2.526-2.00627-5.38907-4.27867-10.87493-4.27867s-8.34893,2.27239-10.87506,4.27867c-2.414,1.9156-4.69321,3.72546-9.29107,3.72546-4.59746,0-6.87813-1.80986-9.29173-3.72546-2.302-1.8276-4.9072-3.85627-9.49733-4.2068.0468.85626.1172,1.7068.19533,2.55426,3.57707.364,5.5968,1.96347,7.71813,3.64733,2.52613,2.0052,5.39013,4.2776,10.8756,4.2776,5.48587,0,8.34893-2.2724,10.87494-4.27761,2.41359-1.91666,4.69373-3.72653,9.2912-3.72653s6.8776,1.80987,9.2916,3.72653c2.52613,2.0052,5.38906,4.27761,10.87506,4.27761s8.34894-2.2724,10.87494-4.27761c2.41413-1.91666,4.69533-3.72653,9.29333-3.72653,4.59733,0,6.8776,1.80987,9.29107,3.72653,2.52653,2.0052,5.3896,4.27761,10.8756,4.27761,5.4856,0,8.3496-2.2724,10.87626-4.27761,2.41333-1.91666,4.69333-3.72653,9.29333-3.72653,4.59866,0,6.87866,1.80987,9.292,3.72653,2.30267,1.82653,4.90933,3.85627,9.49867,4.20573.17467-.83333.32-1.67653.46266-2.5208-3.98-.2292-6.13333-1.89893-8.37733-3.67974-2.52666-2.00626-5.38933-4.27867-10.876-4.27867"
          />
        </g>
        <g id="path32">
          <path
            className="cls-2"
            d="M52.412,94.89014c5.3588,0,8.0484-2.13494,10.6484-4.19946,2.46507-1.9568,4.79373-3.8052,9.51773-3.8052,4.72454,0,7.05253,1.8484,9.51773,3.8052,2.60107,2.06453,5.29014,4.19946,10.64894,4.19946s8.04786-2.13494,10.65-4.19946c2.46453-1.9568,4.79266-3.8052,9.51826-3.8052,4.72334,0,7.05147,1.8484,9.51667,3.8052,2.60147,2.06453,5.29,4.19946,10.65,4.19946,5.35894,0,8.04959-2.13494,10.64959-4.19946,2.46533-1.9568,4.79466-3.8052,9.52-3.8052,4.724,0,7.05467,1.8484,9.51866,3.8052,2.58,2.0468,5.26267,4.15666,10.53334,4.19267l-.01066-.16667c-.04135-.55307-.072-1.10933-.12666-1.6588-4.54533-.06827-6.84667-1.87293-9.264-3.79227-2.60134-2.06453-5.292-4.19946-10.65067-4.19946-5.36,0-8.05066,2.13494-10.65066,4.19946-2.46534,1.95734-4.79333,3.8052-9.51893,3.8052s-7.05374-1.84786-9.51827-3.8052c-2.60054-2.06453-5.29067-4.19946-10.6484-4.19946-5.36,0-8.04946,2.13494-10.65,4.19946-2.46573,1.95734-4.79373,3.8052-9.51827,3.8052s-7.05267-1.84786-9.51773-3.8052c-2.60107-2.06453-5.29014-4.19946-10.64893-4.19946s-8.04787,2.13494-10.6484,4.19946c-2.46521,1.95734-4.79374,3.8052-9.51774,3.8052-4.72453,0-7.05319-1.84786-9.51826-3.8052-2.09267-1.66146-4.26827-3.34839-7.82027-3.9516-.12653.59173-.2396,1.18866-.35053,1.78547,3.13386.52546,5.0536,2.01453,7.04013,3.5912,2.60053,2.06453,5.2896,4.19946,10.64893,4.19946"
          />
        </g>
        <g id="path34">
          <path
            className="cls-2"
            d="M52.412,75.42454c5.23173,0,7.87026-2.0948,10.42293-4.1204,2.5156-1.99733,4.89213-3.8844,9.7432-3.8844,4.8516,0,7.22813,1.88707,9.74426,3.8844,2.55213,2.0256,5.19067,4.1204,10.4224,4.1204,5.23226,0,7.8708-2.09426,10.42347-4.1204,2.5156-1.99733,4.89319-3.8844,9.7448-3.8844,4.85093,0,7.22759,1.88707,9.74319,3.8844,2.55213,2.0256,5.19107,4.1204,10.42347,4.1204s7.87094-2.09426,10.42426-4.1204c2.516-1.99733,4.89333-3.8844,9.74533-3.8844s7.228,1.88707,9.74533,3.8844a17.91379,17.91379,0,0,0,5.77866,3.43027c-.17733-.45574-.35334-.912-.53867-1.36254a18.94339,18.94339,0,0,1-4.56133-2.92293c-2.552-2.02546-5.192-4.12093-10.424-4.12093-5.23333,0-7.872,2.09547-10.42534,4.12093-2.516,1.99693-4.89332,3.88387-9.74427,3.88387-4.8516,0-7.22813-1.88693-9.7448-3.88387-2.55267-2.02546-5.19013-4.12093-10.42186-4.12093-5.23334,0-7.87133,2.09547-10.42347,4.12093-2.51667,1.99693-4.8932,3.88387-9.7448,3.88387s-7.2276-1.88693-9.74427-3.88387C80.44835,68.42348,77.80982,66.328,72.57809,66.328s-7.87027,2.09547-10.4224,4.12093c-2.5156,1.99693-4.89267,3.88387-9.74373,3.88387-4.8516,0-7.22814-1.88693-9.7448-3.88387-.61454-.488-1.23653-.9792-1.8964-1.44534-.16454.33133-.3312.66-.49053.99334.5812.4176,1.1432.85773,1.7088,1.3072,2.55213,2.0256,5.19066,4.1204,10.42293,4.1204"
          />
        </g>
      </svg>
    );
  }
);



