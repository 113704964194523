import {forwardRef} from "react";
import {IconProps} from "./types";

export const EdgeClusterIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
          <path fill={color} fillRule="evenodd" d="M17 3.65a2.35 2.35 0 1 0 0 4.7 2.35 2.35 0 0 0 0-4.7ZM13.35 6a3.65 3.65 0 1 1 .904 2.405l-3.84 2.304a3.642 3.642 0 0 1 0 2.582l3.84 2.303a3.65 3.65 0 1 1-.67 1.114l-3.839-2.303a3.65 3.65 0 1 1 0-4.81l3.84-2.304A3.642 3.642 0 0 1 13.35 6ZM7 9.65a2.35 2.35 0 1 0 0 4.7 2.35 2.35 0 0 0 0-4.7Zm10 6a2.35 2.35 0 1 0 0 4.7 2.35 2.35 0 0 0 0-4.7Z" clipRule="evenodd"/>
      </svg>
    );
  }
);
