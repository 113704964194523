import { forwardRef } from "react";
import { IconProps } from "./types";

export const ThreeDotsIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          d="M3.25 9C2.01 9 1 10.01 1 11.25c0 1.241 1.01 2.25 2.25 2.25s2.25-1.009 2.25-2.25C5.5 10.01 4.49 9 3.25 9Zm8.236 0c-1.24 0-2.25 1.01-2.25 2.25 0 1.241 1.01 2.25 2.25 2.25s2.25-1.009 2.25-2.25c0-1.24-1.01-2.25-2.25-2.25Zm7.953 0c-1.24 0-2.25 1.01-2.25 2.25 0 1.241 1.01 2.25 2.25 2.25s2.25-1.009 2.25-2.25c0-1.24-1.01-2.25-2.25-2.25Z"
        />
      </svg>
    );
  }
);
