import {
  OnBoardingStep,
  OnBoardingSteps,
} from "_sredx/components/UserOnBoarding/types";
import { useEffect } from "react";
import { OnBoardingCardSpinner } from "../OnBoardingCardSpinner";

interface UserLoadingCardStepProps extends OnBoardingStep {}

export const UserLoadingCardStep = ({
  currentStep,
  onNextStep,
}: UserLoadingCardStepProps) => {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    // Check if the current step is the loading step
    if (currentStep === OnBoardingSteps.LOADING_STEP) {
      // Set a timeout to call onNextStep after 2 seconds
      timeoutId = setTimeout(() => {
        if (onNextStep) {
          onNextStep();
        }
      }, 2000);
    }

    // Cleanup function to clear the timeout when the component is unmounted or when the user progresses manually
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [currentStep, onNextStep]);

  if (currentStep !== OnBoardingSteps.LOADING_STEP) {
    return null;
  }
  return <OnBoardingCardSpinner />;
};
