import clsx from "clsx";
import { Text } from "../Text";
import styles from "./KeyValue.module.css";

interface KeyValueProps {
  label: string | React.ReactNode;
  value: string | React.ReactNode;
  className?: string;
}

export const KeyValue = ({ label, value, className }: KeyValueProps) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      {typeof label === "string" ? (
        <Text size={"sm"} className="text-gray-500">
          {label}
        </Text>
      ) : (
        label
      )}

      {typeof value === "string" ? <Text size={"md"}>{value}</Text> : value}
    </div>
  );
};
