import { forwardRef } from "react";
import { IconProps } from "./types";

export const ConfluenceIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>Confluence</title>
        <path
          fill="url(#confluenceA)"
          d="M2.26 17.316c-.216.354-.46.765-.667 1.091a.669.669 0 0 0 .225.91l4.344 2.672a.668.668 0 0 0 .92-.217l.005-.01c.173-.29.398-.668.641-1.072 1.72-2.84 3.45-2.492 6.573-1.002l4.307 2.048a.67.67 0 0 0 .892-.317l.008-.018 2.066-4.676a.668.668 0 0 0-.334-.875 523.97 523.97 0 0 1-4.344-2.066c-5.853-2.844-10.829-2.66-14.635 3.531Z"
        />
        <path
          fill="url(#confluenceB)"
          d="M21.738 6.7c.217-.355.46-.766.669-1.093a.67.67 0 0 0-.225-.909l-4.344-2.673a.668.668 0 0 0-.951.22c-.174.291-.398.669-.642 1.073-1.72 2.84-3.45 2.492-6.573 1.002L5.38 2.282a.668.668 0 0 0-.89.316l-.009.018-2.068 4.679a.668.668 0 0 0 .333.875c.91.427 2.717 1.28 4.344 2.065 5.868 2.84 10.843 2.65 14.65-3.536Z"
        />
        <defs>
          <linearGradient
            id="confluenceA"
            x1="22.317"
            x2="5.58"
            y1="24.662"
            y2="19.653"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".18" stopColor="#0052CC" />
            <stop offset="1" stopColor="#2684FF" />
          </linearGradient>
          <linearGradient
            id="confluenceB"
            x1="2.54"
            x2="15.686"
            y1=".643"
            y2="8.198"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".18" stopColor="#0052CC" />
            <stop offset="1" stopColor="#2684FF" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
);