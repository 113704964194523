import Box from "components/octo-ui/Box/Box";
import {
  resolveStepperHeaderItemStyle,
  resolveStepperHeaderTitle,
} from "componentsv2/shared/StepperComponent/utils/stepperUtils";
import { STEPPER_MODE } from "hooks/stepper/constant";
import { useStepperContext } from "hooks/stepper/useStepperContext";
import { FC } from "react";
import { StepType } from "types/Stepper";
import styles from "./Stepper.module.css";

interface StepperHeaderItemProps {
  step: StepType;
}

export const StepperHeaderItem: FC<StepperHeaderItemProps> = ({ step }) => {
  const { icon: Icon, order, description, stepKey } = step;
  const { isStepVisited, mode, goOnChange, displaySteps } = useStepperContext();

  const itemStyle = resolveStepperHeaderItemStyle({
    condition: isStepVisited(stepKey),
  });

  const handleOnClick = () => {
    if (mode === STEPPER_MODE.TAB) {
      goOnChange(stepKey);
    }
  };

  return (
    <div
      data-testid={"header-wrapper"}
      onClick={handleOnClick}
      className={styles.StepperHeaderItem_wrapper}
    >
      <div className={`${styles.StepperHeaderItem} ${itemStyle}`}>
        {/* icon */}
        {Icon && (
          <Box className={` ${styles.StepperHeaderItem_icon}`}>
            <Icon />
          </Box>
        )}
        <Box className={styles.StepperHeaderItem_details}>
          {/* title */}
          {displaySteps && (
            <Box className={styles.StepperHeaderItem_title}>
              {resolveStepperHeaderTitle(order)}
            </Box>
          )}

          {/* description */}
          <Box className={styles.StepperHeaderItem_description}>
            {description}
          </Box>
        </Box>
      </div>
    </div>
  );
};
