import {
  FILTER_OPERATORS,
  FILTER_PROPERTY_TYPES,
  FilterPropertyType,
} from "../types";

const STRING_OPERATORS = [
  {
    value: FILTER_OPERATORS.EQUALS,
    label: "Equals",
  },
  {
    value: FILTER_OPERATORS.NOT_EQUALS,
    label: "Not equals",
  },
  {
    value: FILTER_OPERATORS.CONTAINS,
    label: "Contains",
  },
  {
    value: FILTER_OPERATORS.NOT_CONTAINS,
    label: "Not contains",
  },
];

const DATE_OPERATORS = [
  {
    value: FILTER_OPERATORS.FROM,
    label: "From",
  },
  {
    value: FILTER_OPERATORS.AFTER,
    label: "After",
  },
  {
    value: FILTER_OPERATORS.BEFORE,
    label: "Before",
  },
];

const NUMBER_OPERATORS = [
  {
    value: FILTER_OPERATORS.EQUALS,
    label: "Equals",
  },
  {
    value: FILTER_OPERATORS.NOT_EQUALS,
    label: "Not equals",
  },
  {
    value: FILTER_OPERATORS.GREATER_THAN,
    label: "Greater than",
  },
  {
    value: FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
    label: "Greater than or equal",
  },
  {
    value: FILTER_OPERATORS.LESS_THAN,
    label: "Less than",
  },
  {
    value: FILTER_OPERATORS.LESS_THAN_OR_EQUAL,
    label: "Less than or equal",
  },
  {
    value: FILTER_OPERATORS.BETWEEN,
    label: "Between",
  },
];

const BOOLEAN_OPERATORS = [
  {
    value: FILTER_OPERATORS.IS,
    label: "Is",
  },
];

const ENUM_OPERATORS = [
  {
    value: FILTER_OPERATORS.IN,
    label: "In",
  },
  {
    value: FILTER_OPERATORS.NOT_IN,
    label: "Not in",
  },
];

const DURATION_OPERATORS = [
  {
    value: FILTER_OPERATORS.EQUALS,
    label: "Equals",
  },
  {
    value: FILTER_OPERATORS.NOT_EQUALS,
    label: "Not equals",
  },
  {
    value: FILTER_OPERATORS.GREATER_THAN,
    label: "Greater than",
  },
  {
    value: FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
    label: "Greater than or equal",
  },
  {
    value: FILTER_OPERATORS.LESS_THAN,
    label: "Less than",
  },
  {
    value: FILTER_OPERATORS.LESS_THAN_OR_EQUAL,
    label: "Less than or equal",
  },
  {
    value: FILTER_OPERATORS.BETWEEN,
    label: "Between",
  },
];

const LIST_OPERATORS = [
  {
    value: FILTER_OPERATORS.IN,
    label: "In",
  },
  {
    value: FILTER_OPERATORS.NOT_IN,
    label: "Not in",
  },
];

export const getOperatorOptions = (type?: FilterPropertyType) => {
  switch (type) {
    case FILTER_PROPERTY_TYPES.STRING:
      return STRING_OPERATORS;
    case FILTER_PROPERTY_TYPES.DATE:
      return DATE_OPERATORS;
    case FILTER_PROPERTY_TYPES.NUMBER:
      return NUMBER_OPERATORS;
    case FILTER_PROPERTY_TYPES.BOOLEAN:
      return BOOLEAN_OPERATORS;
    case FILTER_PROPERTY_TYPES.ENUM:
      return ENUM_OPERATORS;
    case FILTER_PROPERTY_TYPES.DURATION:
      return DURATION_OPERATORS;
    case FILTER_PROPERTY_TYPES.LIST:
      return LIST_OPERATORS;

    default:
      return [];
  }
};
