import { useQuery } from "react-query";
import { useAxios } from "../useAxios";
import Media from "models/scorecard/Media";
import { getAllLevelsMedia } from "services/media/Media.service";

export const LEVELS_MEDIA_QUERY = "levelsMedia";

export const useLevelsMedia = () => {
  const { axios } = useAxios();
  return useQuery<Media[], Error>(
    LEVELS_MEDIA_QUERY,
    (): Promise<Media[]> => getAllLevelsMedia(axios)
  );
};
