import { Button, ButtonIcon } from "_sredx/_ui";
import { TrashIcon } from "_sredx/_ui/Icon";
import { RoadmapCreationInitiativesActions } from "../RoadmapCreationInitiatives";
import { InitiativeBaseDto } from "_sredx/types";
import styles from "./RoadmapCreationInitiativesList.module.css";

interface InitiativeRowProps {
  initiative: InitiativeBaseDto;
  onAction: (action: RoadmapCreationInitiativesActions) => void;
}

export const InitiativeRow = ({ onAction, initiative }: InitiativeRowProps) => {
  const handleOnRemoveInitiative = () => {
    onAction({
      type: "DELETE_INITIATIVE",
      payload: {
        initiative,
      },
    });
  };
  return (
    <div className={styles.row_wrapper}>
      <div className={styles.row_cell}> {initiative.name}</div>
      <div className={styles.row_cell}> {initiative.description}</div>

      <Button onClick={handleOnRemoveInitiative} variant={"neutral"} ghost>
        <ButtonIcon>
          <TrashIcon />
        </ButtonIcon>
      </Button>
    </div>
  );
};
