export const capitalizeFirstLetter = (string = "") => {
  const slices = string
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

  return slices.join(" ");
};

export const capitalizeString = (string = "") => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
