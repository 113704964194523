import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../useAxios";
import { evaluateAllScoreCards } from "../../services/scorecards/ScoreCards.service";
import { SERVICES_SCORES_QUERY } from "../useServicesScores";

export function useEvaluateAllScoreCards(connexionId: string) {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      return await evaluateAllScoreCards(axios, connexionId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([SERVICES_SCORES_QUERY]);
    },
  });
}
