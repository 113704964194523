import {
  Button,
  Input,
  Loader,
  Pagination,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  Text,
} from "_sredx/_ui";
import styles from "./TeamDetailsMembersContainer.module.css";
import { useParams, useSearchParams } from "react-router-dom";
import {
  TeamDetailsMemberListHeader,
  TeamDetailsMemberListRow,
} from "_sredx/components/TeamDetailsMembers";
import { useModal } from "_sredx/hooks";
import { TeamInvitationContainer } from "../TeamInvitationContainer";
import { Member, UserSearchCriteria } from "_sredx/types";
import { API_PAGE_SIZE_DEFAULT } from "_sredx/constants/api";
import { ChangeEventHandler, useMemo, useState } from "react";
import { useGetTeam } from "_sredx/services/teams/useGetTeam";
import { useGetUsers } from "_sredx/services/users";
import {
  chainTeamMembersCreationMutations,
  mapMembersToTeamMembers,
} from "./utils";
import { useTeamMember } from "_sredx/services/teams/useTeamMember";
import { useCreateUsers } from "_sredx/services/users/useCreateUsers";
import { parsePageNumber } from "_sredx/utils";
import { ProgressBar } from "_sredx/_ui/ProgressBar/ProgressBar";
import { useDetachMember } from "_sredx/services/teams/useDetachMember";

const TEAM_SEARCH_CRITERIA_DEFAULT: UserSearchCriteria = {
  pageNumber: 1,
  pageSize: API_PAGE_SIZE_DEFAULT,
};

export const TeamDetailsMembersContainer = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: users,
    isLoading,
    isFetching,
    error,
  } = useGetUsers({
    params: {
      ...TEAM_SEARCH_CRITERIA_DEFAULT,
      pageNumber: parsePageNumber(searchParams.get("page")),
      teamId: teamId ?? "",
      email: searchQuery,
    },
  });
  const { data: teamData } = useGetTeam({
    id: teamId ?? "",
  });

  const { mutateAsync: assignMembers } = useTeamMember();
  const { mutateAsync: mutateNewUsers } = useCreateUsers();
  const { mutate: detachMember } = useDetachMember();

  const displayedMembers = useMemo(() => {
    if (!users || !teamData) {
      return [];
    }
    return mapMembersToTeamMembers(users.content, teamData.members);
  }, [users, teamData]);

  const { openModal, closeModal } = useModal();

  // Derived variables
  const showPagination = users && users.totalPages > 1;

  // Event handlers
  const handleOnSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const handleSubmitInvite = async (members: Member[]) => {
    chainTeamMembersCreationMutations({
      teamId: teamId ?? "",
      members,
      mutateNewUsers,
      assignMembers,
    });

    closeModal();
  };

  const handleClickAdd = () => {
    openModal({
      content: (
        <div className={styles.add_modal_wrapper}>
          <TeamInvitationContainer
            members={[]}
            onSubmit={handleSubmitInvite}
            hide={closeModal}
            teamId={teamId}
          />
        </div>
      ),
      options: {},
    });
  };
  const handleUnlinkMember = (member: Member) => {
    if (!teamId || !member.id) return;
    detachMember({
      teamId: teamId,
      memberId: member.id,
    });
  };

  const handleOnPageChange = (page: number) => {
    setSearchParams({
      page: (page + 1).toString(),
    });
  };
  if (isLoading)
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text>Loading Members...</Text>
      </div>
    );
  if (!users || error)
    return (
      <div className={styles.loader_wrapper}>
        <Text>Oops, an error has occurred while fetching members:</Text>
        {/* <Text>{error.message}</Text> */}
      </div>
    ); //TODO error
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Input
          placeholder="Search members"
          value={searchQuery}
          onChange={handleOnSearch}
        />
        <Button onClick={handleClickAdd}>Add members</Button>
      </div>
      <div className={styles.table_wrapper}>
        <Table>
          <TeamDetailsMemberListHeader />
          <TableBody>
            {isFetching && <ProgressBar position={"absolute"} />}
            {displayedMembers.map((member) => (
              <TeamDetailsMemberListRow
                onUnlink={handleUnlinkMember}
                key={member.id}
                member={member}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableFooterCell colSpan={7}>
              <div className={styles.footer}>
                <Text>{displayedMembers.length} members</Text>
                {showPagination && (
                  <Pagination
                    totalPages={users.totalPages}
                    onPageChange={handleOnPageChange}
                  />
                )}
              </div>
            </TableFooterCell>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
};
