import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import { useMutation, useQueryClient } from "react-query";

export interface UserCreationDto {
  email?: string;
  firstName?: string;
  lastName?: string;
  inviteUser?: boolean;
  source?: string;
  username?: string;
}

export const useCreateUsers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEYS.affectMember],
    mutationFn: (users: UserCreationDto[]) => {
      return ClientApi.post(API_ENDPOINTS.createUsersBulk(), users);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.teams], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.users], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.team], {
        exact: false,
      });
    },
  });
};
