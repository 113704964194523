import styles from "./PRCycleTimeSimpleBar.module.css";
import { PullRequestDto } from "_sredx/types";

const formatSeconds = (seconds: number) => {
  if (seconds < 60) {
    return `${Math.floor(seconds)}s`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  if (minutes < 60) {
    return `${minutes}m ${remainingSeconds ? remainingSeconds + "s" : ""}`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);
  if (hours < 24) {
    return `${hours}h ${remainingMinutes ? remainingMinutes + "m" : ""}`;
  }
  const days = Math.floor(hours / 24);
  const remainingHours = Math.floor(hours % 24);
  return `${days}d ${remainingHours ? remainingHours + "h" : ""}`;
};

interface PRCycleTimeSimpleBarProps {
  pullRequest: PullRequestDto;
}

export const PRCycleTimeSimpleBar = ({
  pullRequest,
}: PRCycleTimeSimpleBarProps) => {
  const {
    codingTime,
    pickupTime,
    approvalTime,
    reviewTime,
    timeToMerge,
    prCycleTime,
    timeToReview,
  } = pullRequest;
  const totalCycleTime = codingTime + pickupTime + approvalTime + reviewTime;

  return (
    <div className={styles.wrapper}>
      <div className={styles.bars_wrapper}>
        <div
          className={styles.p1}
          style={{
            flexBasis: `${(codingTime / totalCycleTime) * 100}%`,
          }}
        />
        <div
          className={styles.p2}
          style={{
            flexBasis: `${(pickupTime / totalCycleTime) * 100}%`,
          }}
        />
        <div
          className={styles.p3}
          style={{
            flexBasis: `${(reviewTime / totalCycleTime) * 100}%`,
          }}
        />
        <div
          className={styles.p4}
          style={{
            flexBasis: `${(approvalTime / totalCycleTime) * 100}%`,
          }}
        />
      </div>

      <div className={styles.legend_wrapper}>
        <div className={styles.legendp1}>
          Coding time: <strong>{formatSeconds(codingTime)}</strong>
        </div>
        <div className={styles.legendp2}>
          Pickup time: <strong>{formatSeconds(pickupTime)}</strong>
        </div>
        <div className={styles.legendp3}>
          Review time: <strong>{formatSeconds(reviewTime)}</strong>
        </div>
        <div className={styles.legendp4}>
          Approval time: <strong>{formatSeconds(approvalTime)}</strong>
        </div>
      </div>
      <div className={styles.legend_wrapper}>
        <div className={styles.legendp5}>
          Time to review: <strong>{formatSeconds(timeToReview || 0)}</strong>
        </div>

        <div className={styles.legendp5}>
          Time to merge: <strong>{formatSeconds(timeToMerge || 0)}</strong>
        </div>
        <div className={styles.legendp5}>
          Cycle time: <strong>{formatSeconds(prCycleTime || 0)}</strong>
        </div>
      </div>
    </div>
  );
};
