import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { useMutation, useQueryClient } from "react-query";
import { ClientApi } from "../clientApi";

export const useDeleteDashboard = () => {
    const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dashboardId: string) =>
      ClientApi.delete(API_ENDPOINTS.deleteDashboard(dashboardId), {}),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.me]);
    },
  });
};
