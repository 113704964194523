import * as SelectPrimitive from "@radix-ui/react-select";
import { PropsWithChildren } from "react";

interface SelectProps {
  onChange: (value: string) => void;
  defaultValue?: string;
}

export const Select = ({
  children,
  defaultValue,
  onChange,
}: PropsWithChildren<SelectProps>) => {
  return (
    <SelectPrimitive.Root onValueChange={onChange} defaultValue={defaultValue}>
      {children}
    </SelectPrimitive.Root>
  );
};
