import clsx from "clsx";
import { useState } from "react";

import { TableCell, TableRow, Text } from "_sredx/_ui";
import { EvaluationStatus } from "../EvaluationStatus";
import { resolveProgressColor } from "../InitiativeDetailsRules/utils";
import { ChevronDownIcon } from "_sredx/_ui/Icon";
import { ProgressBar } from "_sredx/_ui/ProgressBar/ProgressBar";

import {
  InitiativeServiceEvaluation,
  InitiativeServiceOnRuleEvaluation,
} from "./types";
import { calculateTotalSuccessRules } from "./utils";
import styles from "./InitiativeDetailsServices.module.css";

interface InitiativeDetailsServicesRowsProps {
  initiativeServiceEvaluation: InitiativeServiceEvaluation;
}

export const InitiativeDetailsServicesRows = ({
  initiativeServiceEvaluation,
}: InitiativeDetailsServicesRowsProps) => {
  // State
  const [foldRowOpen, setFoldRowOpen] = useState(false);

  // Derived variables
  const rules = initiativeServiceEvaluation.rules ?? [];
  const totalSuccessRules = calculateTotalSuccessRules(
    initiativeServiceEvaluation
  );
  const progress = (totalSuccessRules / rules.length) * 100;

  const handleClickRow = () => {
    setFoldRowOpen(!foldRowOpen);
  };
  return (
    <>
      <TableRow
        onClick={handleClickRow}
        className={clsx(styles.service_row, foldRowOpen && styles.open)}
      >
        <TableCell>{initiativeServiceEvaluation.name}</TableCell>
        <TableCell>
          {initiativeServiceEvaluation.success != null ? (
            <EvaluationStatus success={initiativeServiceEvaluation.success} />
          ) : (
            "-"
          )}
        </TableCell>

        <TableCell>{rules.length}</TableCell>

        <TableCell>
          {rules.length ? (
            <div className={styles.progress}>
              <Text>
                {totalSuccessRules}/{rules.length}
              </Text>

              <ProgressBar
                infinite={false}
                percent={progress}
                rounded
                color={resolveProgressColor(progress)}
              />
            </div>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell
          align="right"
          style={{
            width: "fit-content",
          }}
        >
          <div
            className={clsx(
              styles.chevron_down_icon,
              foldRowOpen && styles.open
            )}
          >
            <ChevronDownIcon />
          </div>
        </TableCell>
      </TableRow>
      <RulesRow rules={rules} foldRowOpen={foldRowOpen} />
    </>
  );
};

const RulesRow = ({
  rules,
  foldRowOpen,
}: {
  rules: InitiativeServiceOnRuleEvaluation[];
  foldRowOpen: boolean;
}) => {
  return (
    <TableRow className={clsx(styles.fold_row, foldRowOpen && styles.open)}>
      {rules.length ? (
        <>
          <TableCell>
            <div className={styles.rules_wrapper}>
              <Text className={styles.rules_title}>rules</Text>
              {rules.map((rule) => (
                <div className={styles.rule_name}>
                  <Text>{rule.ruleName}</Text>
                </div>
              ))}
            </div>
          </TableCell>

          <TableCell colSpan={4}>
            <div className={styles.rules_wrapper}>
              <span style={{ visibility: "hidden" }}>rules</span>
              {rules.map((rule) => (
                <EvaluationStatus success={rule.success} />
              ))}
            </div>
          </TableCell>
        </>
      ) : (
        <TableCell colSpan={5}>
          <div className={styles.empty_rules_wrapper}>
            <Text>No rules found for this service</Text>
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};
