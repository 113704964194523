import { useQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { PagedResponse, UserDto, UserSearchCriteria } from "_sredx/types";
import { ClientApi } from "_sredx/services/clientApi";

interface UseGetUsersProps {
  params: UserSearchCriteria;
}

export const useGetUsers = ({ params }: UseGetUsersProps) => {
  return useQuery<PagedResponse<UserDto>>({
    queryKey: [QUERY_KEYS.users, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getUsers(), {
        params,
      });
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
