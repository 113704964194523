import { useParams } from "react-router-dom";
import {
  useGetInitiative,
  useGetInitiativeRules,
  useGetInitiativeServices,
  useUpdateInitiative,
} from "_sredx/services";
import { useToaster } from "hooks/useToaster";
import { useModal } from "_sredx/hooks";
import { ServiceBaseDto } from "_sredx/types";
import { ServicesAssignmentContainer } from "../ServicesAssignmentContainer";
import { InitiativeDetailsServices } from "_sredx/components";

import { mapInitiativeServicesWithEvaluation } from "./utils";
import styles from "./InitiativeDetailsServicesContainer.module.css";

export const InitiativeDetailsServicesContainer = () => {
  // State
  const { initiativeId = "" } = useParams<{ initiativeId: string }>();

  // Hooks
  const { addToast } = useToaster();
  const { openModal, closeModal } = useModal();

  // Services
  const { mutate: updateInitiative } = useUpdateInitiative();

  const { data: initiativeServicesData, isLoading: isLoadingServices } =
    useGetInitiativeServices({
      id: initiativeId,
    });

  const { data: initiativeRulesData, isLoading: isLoadingRules } =
    useGetInitiativeRules({
      id: initiativeId,
    });

  const { data: initiative, isLoading: isLoadingInitiative } = useGetInitiative(
    {
      id: initiativeId,
    }
  );

  // Derived variables
  const isLoading = isLoadingInitiative || isLoadingServices || isLoadingRules;

  const InitiativeEvaluatedServices = mapInitiativeServicesWithEvaluation(
    initiative,
    initiativeServicesData,
    initiativeRulesData
  );

  // Events handlers
  const handleOnUpdateServices = () => {
    openModal({
      content: (
        <div className={styles.modal_services_wrapper}>
          <ServicesAssignmentContainer
            onCancel={closeModal}
            onConfirm={handleOnSubmitUpdateServices}
            selectedServices={initiativeServicesData}
          />
        </div>
      ),
    });
  };

  const handleOnSubmitUpdateServices = (services: ServiceBaseDto[]) => {
    closeModal();
    updateInitiative(
      {
        id: initiativeId ?? "",
        serviceIds: services.map((service) => service.id),
      },
      {
        onError: (error) => {
          addToast({
            type: "error",
            message: error.message,
          });
        },
      }
    );
  };

  return (
    <InitiativeDetailsServices
      isLoading={isLoading}
      initiativeServices={InitiativeEvaluatedServices}
      onUpdateServices={handleOnUpdateServices}
    />
  );
};
