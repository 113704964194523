import { Switch } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { Avatar, Checkbox, TableCell, TableRow, Text } from "_sredx/_ui";
import { EXTERNAL_PROVIDERS } from "_sredx/containers/ProviderConfigurationStepContainer/types";
import { UserProfileDto } from "_sredx/types";
import clsx from "clsx";
import styles from "./OnboardingImportedUserRow.module.css";

interface OnboardingImportedUserRowProps {
  user: UserProfileDto;
  selectedUsers: UserProfileDto[];
  onCheckChange: (checked: boolean, user: UserProfileDto) => void;
  onInviteUser: (user: UserProfileDto, inviteUser: boolean) => void;
  invitedUsers: UserProfileDto[];
}

export const OnboardingImportedUserRow = ({
  user,
  selectedUsers,
  onCheckChange,
  onInviteUser,
  invitedUsers,
}: OnboardingImportedUserRowProps) => {

    //Derived variables
  const checked = selectedUsers.includes(user);
  const provider = EXTERNAL_PROVIDERS.find(
    (provider) =>
      provider.discoveryKey?.toLowerCase() == user.source?.toLowerCase()
  );
  const disabled = !selectedUsers.map(u => u.email).includes(user.email) || !user.email || !user.email.length ;
  const switchEnabled = invitedUsers.includes(user);
  const displayName = !user.firstName && !user.lastName ? user.username : `${user.firstName ?? ""} ${user.lastName ?? ""}`
  const ProviderIcon = provider?.iconComponent!;

  return (
    <TableRow>
      <TableCell>
        <Checkbox checked={checked} onCheck={(e) => onCheckChange(e, user)} label={displayName}>
            <div className={styles.icon_wrapper}>
             {ProviderIcon &&  <ProviderIcon />}
            </div> 
        </Checkbox>
      </TableCell>
      <TableCell>
        <Text>{user.email}</Text>
      </TableCell>

      <TableCell align={"left"}>
        <div className={styles.teams_wrapper}>
          {user.teams.slice(0, 1).map((team) => (
            <div key={team.id} className={styles.team_wrapper}>
              <Avatar
                size={12}
                name={team.name}
                rectangle
                avatarStyle="identicon"
              />
              <Text className={styles.team_name} size="sm">
                {team.name}
              </Text>
            </div>
          ))}
          {user.teams.length > 1 && (
            <div className={styles.team_wrapper}>{`+${
              user.teams.length - 1
            }`}</div>
          )}
        </div>
      </TableCell>

      <TableCell align="right">
          <Switch
            checked={switchEnabled}
            onChange={(val) => onInviteUser(user, val)}
            className={clsx(styles.switch, switchEnabled && styles.enabled)}
            disabled={disabled}
          >
            <div
            className={clsx(styles.switch_icon_wrapper, switchEnabled && styles.switch_icon_enabled)}
            >
                {switchEnabled && <CheckIcon className={styles.check_icon} />}
            </div>
          </Switch>
      </TableCell>
    </TableRow>
  );
};



