import SelectSearchInput from "components/basic/SelectSearchInput/SelectSearchInput";
import SortFilterComponent, {
  SortOption,
} from "components/basic/SortFilterComponent/SortFilterComponent";
import Box from "components/octo-ui/Box/Box";
import ServiceCardSkeleton from "componentsv2/specific/ServiceCard/ServiceCardSkeleton";
import { useServices } from "hooks/useServices";
import Owner from "models/service/Owner";
import { useMemo, useState } from "react";
import {
  SERVICE_FILTER_OPTION,
  filterServices,
  getTeamList,
  sortServices,
} from "./utils";
import { ServiceCard } from "components/service/ServiceCard/ServiceCard";
import { TextInput } from "components/octo-ui/Forms/TextInput";
import { DeleteServiceModal } from "componentsv2/specific/DeleteServiceModal/DeleteServiceModal";
import { EditServiceModal } from "componentsv2/specific/EditServiceModal/EditServiceModal";


export const ServiceListContainer = () => {
  // state
  const [searchValue, setSearchValue] = useState("");
  const [selectedTeams, setSelectedTeams] = useState<Owner[] | undefined>([]);
  const [selectedSortOption, setSelectedSortOption] = useState<
    SortOption | undefined
  >(undefined);

  // data fetching
  const { data: services, isError, isLoading } = useServices();

  // derived data
  const filtredServices = useMemo(() => {
    return filterServices(services, searchValue, selectedTeams);
  }, [services, searchValue, selectedTeams]);

  const sortedServices = useMemo(() => {
    return sortServices(filtredServices, selectedSortOption);
  }, [filtredServices, selectedSortOption]);

  const teamList = useMemo(() => {
    return getTeamList(services);
  }, [services]);

  const { handleDelete } = DeleteServiceModal();
  const { handleEdit } = EditServiceModal();

  if (isLoading) {
    return (
      <Box className="grid gap-5 md:grid-cols-2 lg:grid-cols-3">
        <ServiceCardSkeleton />
        <ServiceCardSkeleton />
        <ServiceCardSkeleton />
      </Box>
    );
  }

  if (isError) {
    return <p className="text-center text-gray-500">Something went wrong...</p>;
  }

  return (
    <Box>
      <Box className="flex flex-col basis-auto md:flex-row justify-evenly md:justify-center gap-4 md:gap-1 bg-white mb-5 mx-1 px-2 pb-4 md:mx-0">
        <TextInput
          className="flex-1 min-w-0"
          placeholder="Search for a service"
          onChange={(e: any) => setSearchValue(e.target.value)}
        />

        <SelectSearchInput
          componentName={"Teams"}
          team
          options={teamList}
          onChange={setSelectedTeams}
        />

        <SortFilterComponent
          sortOptions={SERVICE_FILTER_OPTION}
          selectedSort={selectedSortOption}
          onChange={(option) => setSelectedSortOption(option)}
        />
      </Box>
      {!sortedServices || sortedServices.length === 0 ? (
        <Box className="flex flex-col justify-center items-center w-full">
          No services found
        </Box>
      ) : (
        <Box className="grid grid-cols-1 p-1 gap-4 sm:grid-cols-3">
          {sortedServices.map((service, index) => (
            <ServiceCard
              clickable
              key={service.id}
              service={service}
              renderFooter
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

