import { useQuery } from "react-query";
import { SELF_SERVICE_QUERY_KEYS, SELF_SERVICE_PATHS } from "./paths";
import { useAxios } from "hooks/useAxios";
import { GitopsAccountDto } from "types/selfService";

export const useGitopsAccounts = () => {
  const { axios } = useAxios();
  const fetchGitopsAccounts = async () => {
    const response = await axios(SELF_SERVICE_PATHS.GITOPS_ACCOUNTS);
    return response.data;
  };

  return useQuery<GitopsAccountDto[]>(
    [SELF_SERVICE_QUERY_KEYS.GITOPS_ACCOUNTS],
    fetchGitopsAccounts,
    {
      // initialData: gitopsAccountsMocks,
    }
  );
};
