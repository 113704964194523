import { useAxios } from "hooks/useAxios";
import { useQuery } from "react-query";
import { SELF_SERVICE_PATHS } from "./paths";

export const useCloudAccounts = () => {
  const {axios} = useAxios();
  const fetchCloudAccounts = async () => {
    const response = await axios(SELF_SERVICE_PATHS.CLOUD_ACCOUNTS);
    return response.data;
  };
  return useQuery("cloudAccounts", fetchCloudAccounts, {
    // initialData: [
    //   {
    //     name: "account-saad",
    //     provider: "scaleway",
    //   },
    //   {
    //     name: "nemo-scaleway",
    //     provider: "scaleway",
    //   },
    // ],
  });
};
