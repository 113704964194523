import React from "react";
import styles from "./ProgressStepper.module.css";
import clsx from "clsx";
import { Text } from "_sredx/_ui";

interface ProgressStepperProps {
  steps: any[];
  currentStep: number;
  renderLabels?: boolean;
}

export const ProgressStepper = ({
  steps,
  currentStep,
  renderLabels = false,
}: ProgressStepperProps) => {
  const totalSteps = steps.length;
  const width = `${(100 / (totalSteps - 1)) * (currentStep - 1)}%`;
  return (
    <div className={clsx(styles.wrapper)}>
      <div className={styles.step_container} data-width={width}>
        {steps.map(({ step, label }) => (
          <div key={step} className={styles.step_wrapper}>
            <div
              className={clsx(
                styles.step_style,
                step < currentStep && styles.done_steps,
                step == currentStep && styles.current_step
              )}
            >
              <Text size="lg" className={clsx(styles.step_count)}>
                {step}
              </Text>
            </div>
            {renderLabels && (
              <div className={styles.steps_label_wrapper}>
                <span className={styles.step_label}>{label}</span>
              </div>
            )}
          </div>
        ))}

        <div className={styles.progress} style={{ width: width }}></div>
      </div>
    </div>
  );
};
