import styles from "./PlannerHeader.module.css";
import clsx from "clsx";
import { PropsWithChildren } from "react";

export const PlannerHeader = ({ children }: PropsWithChildren) => {
  return (
    <div className={clsx("nemo-planner-header", styles.wrapper)}>
      {children}
    </div>
  );
};
