import { Button, Card, Heading, Text } from "_sredx/_ui";
import { ServiceBaseDto } from "_sredx/types";
import styles from "./ServiceAnnotationsModal.module.css";

interface ServiceAnnotationsModalProps {
  service: ServiceBaseDto;
  onClose: () => void;
}

export const ServiceAnnotationsModal = ({
  service,
  onClose,
}: ServiceAnnotationsModalProps) => {
  return (
    <Card>
      <div className={styles.wrapper}>
        <Heading level={3}>Annotations</Heading>

        <div className={styles.content}>
          {service.metadata &&
            service.metadata.annotations &&
            Object.keys(service.metadata.annotations).map((key, i) => (
              <>
                <div className={styles.key} key={i}>
                  {key}
                </div>
                {service.metadata && service.metadata.annotations && (
                  <Text size="sm" key={`val-${key}`}>{service.metadata.annotations?.[key]}</Text>
                )}
              </>
            ))}
        </div>
        <Button className={styles.close} onClick={onClose}>
          Close
        </Button>
      </div>
    </Card>
  );
};
