import clsx from "clsx";
import Tooltip from "components/octo-ui/Tootlip/Tooltip";
import { IconType } from "react-icons";
import styles from "./ClusterCardBadges.module.css";

interface ClusterCardBadgesProps {
  badges: { icon: IconType; value: string; key: string }[];
}

export const ClusterCardBadges = ({ badges }: ClusterCardBadgesProps) => {
  return (
    <div className={clsx(styles.wrapper)}>
      {badges.map((badge, i) => (
        <Tooltip key={i} content={[badge.key]} delay={0} direction="top">
          <div className={styles.badge}>
            <badge.icon />
            {badge.value}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
