import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import {
  PagedResponse,
  RoadmapBaseDto,
  RoadmapSearchingCriteria,
} from "_sredx/types";
import { useQuery } from "react-query";

interface UseGetRoadmapsProps {
  params: RoadmapSearchingCriteria;
}

export const useGetRoadmaps = ({ params }: UseGetRoadmapsProps) => {
  return useQuery<PagedResponse<RoadmapBaseDto>>({
    queryKey: [QUERY_KEYS.roadmaps, params],
    queryFn: () =>
      ClientApi.get(API_ENDPOINTS.getRoadmaps(), {
        params,
      }),
    refetchOnWindowFocus: false,
    retry: 0,
  });
};
