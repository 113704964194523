import { UserProfileFormData } from "_sredx/components";
import { AccountCreationDto } from "_sredx/types";

export const toAccountCreationDto = (
  data: UserProfileFormData,
  invitationId: string
): AccountCreationDto => {
  return {
    invitationId: invitationId,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    jobTitle: data.jobTitle,
    password: data.password,
  };
};
