import styles from "./InitiativeCreationRules.module.css";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "_sredx/_ui";
import { InitiativeCreationRulesList } from "_sredx/components";
import { RuleDto } from "_sredx/types";
import { InitiativeCreationRulesAction } from "./types";

interface InitiativeCreationRulesProps {
  rules: RuleDto[];
  onAction: (action: InitiativeCreationRulesAction) => void;
}

export const InitiativeCreationRules = ({
  rules,
  onAction,
}: InitiativeCreationRulesProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Initiative Rules</CardTitle>
        <CardSubtitle>
          Define initiatives goals by associating rules
        </CardSubtitle>
      </CardHeader>
      <CardBody noPadding>
        <div className={styles.body_wrapper}>
          <InitiativeCreationRulesList
            rules={rules}
            onAction={onAction}
            emptyMessage={"This initiative has no rules"}
            withAddAction
          />
        </div>
      </CardBody>
    </Card>
  );
};
