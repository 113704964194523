import { stringToHSLColor } from "utils/common";
import styles from "./styles.module.css";

interface TagProps {
  /**
   * String that goes into the tag */
  tag: string;
  /** Function that is called when the tag is clicked */
  onClick?: () => void;
  /** Whether the tag should be colored or not */
  colored?: boolean;
}

export const Tag = ({ tag, onClick, colored = true }: TagProps) => {
  return (
    <div
      className={styles.tag}
      style={
        colored
          ? {
              ...stringToHSLColor(tag),
              border: "none",
            }
          : {}
      }
    >
      {/* {colored && (
        <span
          style={{
            backgroundColor: stringToHSLColor(tag).color,
            height: 8,
            width: 8,
            borderRadius: "50%",
          }}
        ></span>
      )} */}
      <span>{tag}</span>
      {onClick && (
        <span style={{ cursor: "pointer" }} onClick={onClick}>
          &times;
        </span>
      )}
    </div>
  );
};
