import { InitiativeDto } from "_sredx/types";

export const calculateDurationInMonths = (initiative: InitiativeDto) => {
  const startDate = new Date(initiative.startDate);
  const endDate = new Date(initiative.endDate);
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();

  // Calculate the total months between start and end dates
  const totalMonths = (endYear - startYear) * 12 + (endMonth - startMonth);
  return totalMonths + 1;
};

export const getRoadmapCalendarRowOptions = (initiatives: InitiativeDto[]) => {
  const campaignOptions: Array<{
    initiative: InitiativeDto;
    position: { row: number; startColumn: number; endColumn: number };
  }> = [];

  initiatives
    .sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    )
    .forEach((initiative, index) => {
      const duration = calculateDurationInMonths(initiative);
      const startColumn = new Date(initiative.startDate).getMonth() + 1;
      const endColumn = startColumn + duration - 1;
      const row = index + 1;
      campaignOptions.push({
        initiative,
        position: { row, startColumn, endColumn },
      });
    });

  return campaignOptions;
};
