interface IconProps {
  size?: number;
  color?: string;
}
export const CloseIcon = ({ size, color = "currentColor" }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="m8.552 7.62 4.15 3.952-.702.668-4.149-3.952-4.15 3.952L3 11.572 7.15 7.62 3 3.668 3.701 3l4.15 3.952L12.001 3l.701.668-4.15 3.952Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
