import {PropsWithChildren} from "react";
import clsx from "clsx";
import styles from "./MenuItemLink.module.css";

interface MenuItemLinkProps {
  active?: boolean;
}

export const MenuItemLink = ({ children, active = false }: PropsWithChildren<MenuItemLinkProps>) => {
  return (
    <div className={clsx("nemo-menu-item-link", styles.wrapper, active && styles.active )}>
      {children}
    </div>
  );
};
