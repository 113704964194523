import styles from "./ServicesDropRiseList.module.css";
import { resolvePercentage } from "./ServicesDropRiseList";
import { useEffect, useState } from "react";

export const TeamsDropRiseList = ({
  data,
  sort = "DESC",
}: {
  data: { totalPercentageDifference: number; teamName: string }[];
  sort?: "DESC" | "ASC";
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const [displayedData, setDisplayedData] = useState<
    { totalPercentageDifference: number; teamName: string }[]
  >([]);

  useEffect(() => {
    if (sort === "ASC") {
      const sortedData = data.sort(
        (a, b) => a.totalPercentageDifference - b.totalPercentageDifference
      );

      if (expanded) {
        setDisplayedData(sortedData);
      } else {
        setDisplayedData(sortedData.slice(0, 10));
      }
    }
    if (sort === "DESC") {
      const sortedData = data.sort(
        (b, a) => a.totalPercentageDifference - b.totalPercentageDifference
      );

      if (expanded) {
        setDisplayedData(sortedData);
      } else {
        setDisplayedData(sortedData.slice(0, 10));
      }
    }
  }, [data, sort, expanded]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.services_list}>
        {displayedData.map((team, i) => (
          <div key={team.teamName} className={styles.service_row}>
            <div>{team.teamName}</div>
            {resolvePercentage(team.totalPercentageDifference)}
          </div>
        ))}
        <div className={styles.show_more}>
          <button onClick={handleExpand}>
            {expanded ? "Show Less" : "Show More"}
          </button>
        </div>
      </div>
    </div>
  );
};
