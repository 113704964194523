import { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./PageContent.module.css";

interface PageWrapperProps {
  fullWidth?: boolean;
  className?: string;
  maxWidth?: number;
  minWidth?: number;
  align?: "flex-start" | "center" | "flex-end";
  noPadding?: boolean;
}
export const PageContent = ({
  children,
  fullWidth = true,
  className,
  align = "center",
  maxWidth = 1500,
  minWidth = 320,
 
  noPadding,
}: PropsWithChildren<PageWrapperProps>) => {
  const contentClassNames = clsx(fullWidth && styles.fullWidth, className);
  return (
    <div
      className={clsx(
        "nemo-page-wrapper",
        styles.wrapper,
        noPadding && styles.nopadding
      )}
    >
      <div
        className={contentClassNames}
        style={{
          maxWidth: `${maxWidth}px`,
          minWidth: `${minWidth}px`,
          alignSelf: align,
        }}
      >
        {children}
      </div>
    </div>
  );
};
