import { Media } from "_sredx/types";
import React from "react";

import styles from "./ScoreCardLevels.module.css";

export interface ScoreCardLevelsImageProps {
  image?: Media;
}

export const ScoreCardLevelsImage = ({ image }: ScoreCardLevelsImageProps) => {
  if (!image) {
    return (
      <div className={styles.image_wrapper}>
        <img alt={"placeholder"} src={"/images/placeholder-image.svg"} />
      </div>
    );
  }

  return (
    <div className={styles.image_wrapper}>
      <img
        alt={"level"}
        src={`data:image/jpeg;charset=utf-8;base64,${image.mediaData}`}
      />
    </div>
  );
};
