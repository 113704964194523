import { useMemo, useState } from "react";
import clsx from "clsx";
import { GetOptionsByKeyFn, OptionsData } from "../../types";
import { AdvancedFiltersInputValueProps } from "../types";
import { Popover, PopoverContent, PopoverTrigger } from "../../Popover";
import { InputListContent } from "./InputListContent";
import inputStyles from "../Input.module.css";
import { useDebounce } from "_sredx/hooks";

interface InputListProps extends AdvancedFiltersInputValueProps {
  getOptionsByKey: GetOptionsByKeyFn;
}

export const InputList = ({
  value,
  onChange,
  getOptionsByKey,
  propertyKey,
}: InputListProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedValues, setSelectedValues] = useState<OptionsData[]>(
    value || []
  );
  const [popoverOpen, setPopoverOpen] = useState(false);
  const useFetchOptions = getOptionsByKey(propertyKey);

  const debouncedQuery = useDebounce(searchQuery, 400);

  const { isLoading, data, fetchNextPage, isFetching } = useFetchOptions({
    searchQuery: debouncedQuery,
  });

  const options = useMemo(() => {
    return data || [];
  }, [data]);

  const displayOptions = useMemo(() => {
    return options.filter(
      (option) => !selectedValues.find((v) => v.value === option.value)
    );
  }, [options, selectedValues]);

  const handleOnRemove = (_value: OptionsData) => {
    setSelectedValues((oldValues) => {
      return oldValues.filter((v) => v.value !== _value.value);
    });
  };

  const handleOnAdd = (_value: OptionsData) => {
    setSelectedValues((oldValues) => {
      return [...oldValues, _value];
    });
  };

  const handleOnClose = (open: boolean) => {
    setPopoverOpen(open);
    if (!open) {
      onChange(selectedValues);
    }
  };

  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleOnIntersection = () => {
    if (fetchNextPage) {
      fetchNextPage();
    }
  };

  return (
    <Popover onOpenChange={handleOnClose} open={popoverOpen}>
      <PopoverTrigger>
        <div className={clsx(inputStyles.list_label_w)}>
          {selectedValues.length ? (
            <span>{selectedValues.length} selected</span>
          ) : (
            <span className={inputStyles.trigger_label}>Value</span>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <div className={inputStyles.list_content_w}>
          <input
            className={inputStyles.search_bar_i}
            type={"text"}
            placeholder={"search"}
            value={searchQuery}
            onChange={handleOnSearch}
          />

          <InputListContent
            options={displayOptions}
            selectedOptions={selectedValues}
            isLoading={isLoading}
            isFetching={isFetching}
            onAdd={handleOnAdd}
            onRemove={handleOnRemove}
            onIntersection={handleOnIntersection}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
