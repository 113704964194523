import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import { useMutation, useQueryClient } from "react-query";

export interface UserUpdateRequestDto {
  email?: string;
  firstName?: string;
  lastName?: string;
  onboarded?: boolean;
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEYS.updateProfile],
    mutationFn: (userUpdateRequestDto: UserUpdateRequestDto) => {
      return ClientApi.patch(API_ENDPOINTS.updateProfile(), userUpdateRequestDto);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.users], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.me], {
        exact: false,
      });
    },
  });
};
