import { forwardRef } from "react";
import { IconProps } from "./types";

export const BoxIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          fillRule="evenodd"
          d="m11.65 6.194-.06.03-7.684 3.842 7.683 3.842.061.03.06-.03 7.684-3.842-7.683-3.842-.061-.03Zm.192-1.176a1.042 1.042 0 0 0-.384 0Zm0 0c.148.028.281.095.37.14l.026.013 8.736 4.368a.589.589 0 0 1 0 1.054l-8.736 4.368-.026.013a1.413 1.413 0 0 1-.37.14 1.045 1.045 0 0 1-.384 0 1.413 1.413 0 0 1-.37-.14l-.026-.013-8.736-4.368a.589.589 0 0 1 0-1.054l8.736-4.368.026-.013c.089-.045.222-.112.37-.14m-9.396 9.315a.59.59 0 0 1 .79-.263l8.737 4.368.061.03.06-.03 8.738-4.368a.59.59 0 0 1 .526 1.053l-8.736 4.369-.026.012c-.089.045-.222.113-.37.14a1.04 1.04 0 0 1-.384 0 1.404 1.404 0 0 1-.37-.14l-.026-.012-8.736-4.369a.589.589 0 0 1-.264-.79Z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
);
