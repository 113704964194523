import { CheckIcon, XIcon } from "_sredx/_ui/Icon";
import clsx from "clsx";
import React from "react";
import { ServiceProvider } from "types/discovery";
import styles from "./OnboardingProviderConfigFormHeader.module.css";

interface OnboardingProviderConfigFormHeaderProps {
  handleClick: () => void;
  serviceProvider: ServiceProvider;
  validationResult: boolean | undefined;
}

export const OnboardingProviderConfigFormHeader = ({
  handleClick,
  serviceProvider,
  validationResult,
}: OnboardingProviderConfigFormHeaderProps) => {
  const ProviderIcon = serviceProvider.iconComponent;
  return (
    <div className={styles.wrapper} onClick={handleClick}>
      <div className={styles.title}>
        <div className={styles.icon}>{ProviderIcon && <ProviderIcon />}</div>

        <span>{serviceProvider.name} Configuration</span>
      </div>

      <div className={clsx(validationResult === undefined && styles.dot)}>
        {validationResult && (
          <CheckIcon className={clsx(styles.icon, styles.check_icon)} />
        )}
        {validationResult === false && (
          <XIcon className={clsx(styles.icon, styles.x_icon)} />
        )}
      </div>
    </div>
  );
};
