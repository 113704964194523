interface Star {
  x: number;
  y: number;
  z: number;
}
const STARS_SPEED = 0.1;

export const makeStars = (count: number) => {
  const out = [];
  for (let i = 0; i < count; i++) {
    const cryptoRandomBytes = new Uint32Array(3);
    crypto.getRandomValues(cryptoRandomBytes);

    const s = {
      x: (cryptoRandomBytes[0] / (0xffffffff + 1)) * 1600 - 800,
      y: (cryptoRandomBytes[1] / (0xffffffff + 1)) * 1600 - 800,
      z: (cryptoRandomBytes[2] / (0xffffffff + 1)) * 1000,
    };
    out.push(s);
  }
  return out;
};

export const clearCanvas = (
  c: CanvasRenderingContext2D | null,
  canvas: HTMLCanvasElement
) => {
  if (!c || !canvas) return;
  c.clearRect(0, 0, canvas.width, canvas.height);
};

export const putPixel = (
  c: CanvasRenderingContext2D | null,
  x: number,
  y: number,
  brightness: number
) => {
  if (!c) return;
  const intensity = brightness * 255;
  const rgb = "rgb(" + intensity + "," + intensity + "," + intensity + ")";
  c.fillStyle = rgb;
  c.fillRect(x, y, 3, 3);
};

export const moveStars = (stars: Star[], distance: number) => {
  const count = stars.length;
  for (let i = 0; i < count; i++) {
    const s = stars[i];
    s.z -= distance;
    while (s.z <= 1) {
      s.z += 1000;
    }
  }
};

export const canvasStarProcedure = (
  canvas: HTMLCanvasElement,
  c: CanvasRenderingContext2D | null | null
) => {
  if (!c) return;
  let w: number;
  let h: number;

  const setCanvasExtents = () => {
    w = document.body.clientWidth;
    h = document.body.clientHeight;
    canvas.width = w;
    canvas.height = h;
  };

  setCanvasExtents();

  window.onresize = () => {
    setCanvasExtents();
  };

  let stars = makeStars(1000);

  const clear = () => {
    c.clearRect(0, 0, canvas.width, canvas.height);
  };

  let prevTime: number;
  const init = (time: number) => {
    prevTime = time;
    requestAnimationFrame(tick);
  };
  const tick = (time: number) => {
    let elapsed = time - prevTime;
    prevTime = time;
    moveStars(stars, elapsed * STARS_SPEED);
    clear();
    const cx = w / 2;
    const cy = h / 2;
    const count = stars.length;
    for (var i = 0; i < count; i++) {
      const star = stars[i];
      const x = cx + star.x / (star.z * 0.001);
      const y = cy + star.y / (star.z * 0.001);
      if (x < 0 || x >= w || y < 0 || y >= h) continue;
      const d = star.z / 1000.0;
      const b = 1 - d * d;
      putPixel(c, x, y, b);
    }
    requestAnimationFrame(tick);
  };
  requestAnimationFrame(init);
};
