import { useQuery } from "react-query";
import {
  PagedResponse,
  ServiceBaseDto,
  ServiceSearchCriteria,
} from "_sredx/types";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";

interface UseGetServicesProps {
  params: ServiceSearchCriteria;
}

export const useGetServices = ({ params }: UseGetServicesProps) =>
  useQuery<PagedResponse<ServiceBaseDto>>({
    queryKey: [QUERY_KEYS.services, params],
    queryFn:  () => {
      return ClientApi.get(API_ENDPOINTS.getServices(), {
        params,
      });
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,

  });
