import {FormField} from "components/basic/FormField";
import {TextInput} from "components/octo-ui/Forms/TextInput";
import {customStyles} from "components/scorecard/ScoreCardForm/EditRuleForm/styles";
import {FormActions} from "components/scorecard/ScoreCardForm/FormActions/FormActions";
import {UploadImages} from "componentsv2/shared/UploadImages/UploadImages";
import Media from "models/scorecard/Media";
import React, {FC, useEffect, useState} from "react";
import {FormProvider, SubmitHandler, useForm, useFormState} from "react-hook-form";
import {ExtendedLevel} from "utils/scoreCard";
import Level from "../../../../models/scorecard/Level";
import Rule from "../../../../models/scorecard/Rule";
import {FormErrorMessageComponent} from "../../../basic/FormErroMessage/FormErrorMessageComponent";
import {levelDescriptionValidator, levelNameValidator,} from "./EditLevelFormValidators";
import SelectSearchInput from "./SelectSearchInput";

interface EditRuleComponentProps {
    rules: Rule[];
    originalLevel: Level;
    isNewLevel: boolean;
    closeEditLevelComponentCallBack: Function;
    selectedRulesIds: string[];

    setSelectedRulesIds(rulesIds: string[]): void;

    updateLevelDetailsCallBack(level: Level): void;

    createNewLevelCallBack(level: Level): void;
}

const EditLevelFormComponent: FC<EditRuleComponentProps> = ({
                                                                rules,
                                                                originalLevel,
                                                                isNewLevel,
                                                                selectedRulesIds,
                                                                setSelectedRulesIds,
                                                                closeEditLevelComponentCallBack,
                                                                updateLevelDetailsCallBack,
                                                                createNewLevelCallBack,
                                                            }) => {
    const [updatedLevel, setUpdatedLevel] = useState({...originalLevel});
    const methods = useForm<ExtendedLevel>({
        mode: "onSubmit",
    });
    const {isSubmitting, isDirty, errors} = useFormState({
        control: methods.control,
    });
    useEffect(() => {
        // Set rule to edit
        setUpdatedLevel(originalLevel);
    }, [originalLevel]);

    useEffect(() => {
        methods.reset(updatedLevel);
    }, [methods, updatedLevel]);


    const saveLevel: SubmitHandler<ExtendedLevel> = async (savedLevel) => {
        if (isNewLevel) {
            createNewLevelCallBack(savedLevel);
        } else {
            updateLevelDetailsCallBack(savedLevel);
        }
        methods.reset();
    };
    const isValidateButtonDisabled = !isDirty || isSubmitting;

    const handleOnImageChange = (file: File) => {
        const image = {
            mediaData: URL.createObjectURL(file),
        } as Media;
        methods.setValue('imageFile', file)
        methods.setValue('image', image)
    }

    return (
        <FormProvider {...methods}>
            <form
                id="edit-level-form"
                className="space-y-8 ml-5 p-5"
                onSubmit={methods.handleSubmit(saveLevel)}
            >
                <FormActions
                    formId={"edit-level-form"}
                    showResetButton={isValidateButtonDisabled}
                    isSaveButtonDisabled={isValidateButtonDisabled}
                    onReset={() => closeEditLevelComponentCallBack()}
                >
                    <div className="space-y-8 ">
                        <div className="grid grid-cols-10">
                            <div id="editRuleFormFields" className="col-span-9">
                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-6">
                                        <div className="mt-1">
                                            <label
                                                htmlFor="image"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Level Image
                                            </label>
                                            <FormField
                                                id={"level-image-field"}
                                                name={"image"}
                                                component={UploadImages}
                                                onChange={handleOnImageChange}
                                                // rules={{
                                                //     required:{
                                                //         value:true,
                                                //         message:"image is required"
                                                //     },
                                                //     validate:levelImageValidator
                                                // }}
                                            />
                                            <FormErrorMessageComponent
                                                errors={errors}
                                                fieldName={`imageFile`}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Level Name
                                            </label>
                                            <FormField
                                                id="level-name-field"
                                                name={"name"}
                                                component={TextInput}
                                                type={"text"}
                                                defaultValue={updatedLevel.name}
                                                placeholder="Name"
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "The name is required.",
                                                    },
                                                    validate: levelNameValidator,
                                                }}
                                                error={errors.name?.message}
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                        <div className="mt-1">
                                            <label
                                                htmlFor={"description"}
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Level Description
                                            </label>
                                            <FormField
                                                id="level-description-field"
                                                name={"description"}
                                                component={TextInput}
                                                type={"textarea"}
                                                defaultValue={updatedLevel.description}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "The description is required.",
                                                    },
                                                    validate: levelDescriptionValidator,
                                                }}
                                                rows={3}
                                                placeholder="Description "
                                                error={errors.description?.message}
                                            />
                                        </div>
                                    </div>

                                    {
                                        <div className="sm:col-span-6">
                                            <div className="sm:col-span-6">
                                                <label
                                                    htmlFor={`rules`}
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Rules
                                                </label>
                                                <div className="mt-1">
                                                    <FormField
                                                        id="level-rules-field"
                                                        componentName={"Rules"}
                                                        options={rules.filter(
                                                            (rule) => !selectedRulesIds.includes(rule.id!)
                                                        )}
                                                        name={`ruleIds`}
                                                        component={SelectSearchInput}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: "You must choose one rule at minimum.",
                                                            },
                                                        }}
                                                        originalValues={
                                                            updatedLevel
                                                                ? rules.filter((rule) =>
                                                                    originalLevel.ruleIds?.includes(rule.id!)
                                                                )
                                                                : []
                                                        }
                                                        styles={customStyles}
                                                        selectedRulesIds={selectedRulesIds}
                                                        setSelectedRulesIds={setSelectedRulesIds}
                                                    />
                                                    <FormErrorMessageComponent
                                                        errors={errors}
                                                        fieldName={`ruleIds`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={"col-span-1 flex justify-center m-0"}>
                                <div
                                    className={"h-6 w-6"}
                                    id="close-edit-level-form-button"
                                    onClick={() => closeEditLevelComponentCallBack()}
                                >
                                    <button className="text-red-700 hover:bg-gray-200 h-6 w-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormActions>
            </form>
        </FormProvider>
    );
};

export default EditLevelFormComponent;
