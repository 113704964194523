import {useAxios} from "hooks/useAxios";
import Media from "models/scorecard/Media";
import {useQuery} from "react-query";
import {getLevelsMediaByOwnersIdAndType} from "services/media/Media.service";

interface UseLevelsMediaByOwnerTypeAndIdsProps {
    levelsId: string[]
    ownerType: string,
}

export const LEVELS_MEDIA_BY_OWNER_TYPE_AND_IDS = 'level-media-by-owner-type-and-ids'
export const useLevelsMediaByOwnerTypeAndIds = ({levelsId, ownerType}: UseLevelsMediaByOwnerTypeAndIdsProps) => {
    const {axios} = useAxios();
    return useQuery<Media[], Error>({
        queryKey: [LEVELS_MEDIA_BY_OWNER_TYPE_AND_IDS],
        queryFn: (): Promise<Media[]> => getLevelsMediaByOwnersIdAndType(axios, levelsId, ownerType),
        enabled: levelsId && levelsId.length !== 0,
        refetchOnWindowFocus: false
    })

}