import {
  BackstageIcon,
  BitbucketIcon,
  ConfluenceIcon,
  GithubIcon,
  GitlabIcon,
  JiraIcon,
  LeanIxIcon,
  PagerDutyIcon,
} from "_sredx/_ui/Icon";
import { ServiceProvider } from "types/discovery";

export const SERVICE_PROVIDERS_KEYS = {
  GITHUB: "github",
  GITLAB: "gitlab",
  BITBUCKET: "bitbucket",
  JIRA: "jira",
  BACKSTAGE: "backstage",
  LEANIX: "leanix",
  CONFLUENCE: "confluence",
};

export type ServiceProviderKeyType =
  (typeof SERVICE_PROVIDERS_KEYS)[keyof typeof SERVICE_PROVIDERS_KEYS];

export const SERVICE_PROVIDERS: ServiceProvider[] = [
  {
    id: 1,
    name: "Github",
    iconComponent: GithubIcon,
    configured: false,
    supported: true,
    key: SERVICE_PROVIDERS_KEYS.GITHUB,
  },

  {
    id: 2,
    name: "Gitlab",
    iconComponent: GitlabIcon,
    configured: false,
    supported: true,
    key: SERVICE_PROVIDERS_KEYS.GITLAB,
  },
  {
    id: 3,
    name: "Backstage",
    iconComponent: BackstageIcon,
    configured: false,
    supported: true,
    key: SERVICE_PROVIDERS_KEYS.BACKSTAGE,
  },
  {
    id: 4,
    name: "Bitbucket",
    iconComponent: BitbucketIcon,
    configured: false,
    supported: false,
    key: SERVICE_PROVIDERS_KEYS.BITBUCKET,
  },
  {
    id: 5,
    name: "Jira",
    iconComponent: JiraIcon,
    configured: false,
    supported: false,
    key: SERVICE_PROVIDERS_KEYS.JIRA,
  },

  {
    id: 6,
    name: "LeanIX",
    iconComponent: LeanIxIcon,
    configured: false,
    supported: false,
    key: SERVICE_PROVIDERS_KEYS.LEANIX,
  },
  {
    id: 7,
    name: "Confluence",
    iconComponent: ConfluenceIcon,
    configured: false,
    supported: false,
    key: SERVICE_PROVIDERS_KEYS.CONFLUENCE,
  },
  {
    id: 8,
    name: "PagerDuty",
    iconComponent: PagerDutyIcon,
    configured: false,
    supported: false,
    key: "pagerduty",
  },
];
