import { IconProps } from "_sredx/_ui/Icon/types";
import { forwardRef } from "react";

export const FlagIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          d="M12.345 3.904c-2.341 0-2.965-1.404-5.93-1.404-1.912 0-3.082 1.444-3.082 1.444v13.088a.468.468 0 0 0 .936 0v-5.967a3.712 3.712 0 0 1 2.03-.607c2.964 0 3.822 1.405 6.163 1.405 2.34 0 3.979-1.405 3.979-1.405V2.5s-1.756 1.404-4.096 1.404Zm3.16 6.077c-.53.345-1.65.945-3.043.945-.92 0-1.549-.252-2.344-.572-.97-.39-2.069-.832-3.82-.832-.8 0-1.479.201-2.029.47V4.325c.353-.32 1.121-.888 2.146-.888 1.377 0 2.152.335 2.973.688.817.352 1.663.717 2.957.717 1.247 0 2.342-.345 3.16-.716V9.98Z"
        />
      </svg>
    );
  }
);
