import { ParentSize } from "@visx/responsive";
import { StackedBarChart } from "_sredx/components";
import { useGetWidgetData } from "_sredx/services";
import { ScorecardsLevelsHealthsDto } from "_sredx/types";
import { DASHBOARD_WIDGETS } from "_sredx/types/dashboards";
import { useMemo } from "react";
// import { Keys } from "./types";
import { getLevelsNames, transformToStackedBarData } from "./utils";

export const ScoreCardsHealthPerLevelsContainer = () => {
  const { data, isError } = useGetWidgetData<ScorecardsLevelsHealthsDto>({
    key: DASHBOARD_WIDGETS.SCORECARDS.SCORECARDS_HEALTH_PER_LEVELS,
  });
  const mappedStackedBarChartData = useMemo(() => {
    if (!data) return { transformedData: [], maxLevels: 0 };
    return transformToStackedBarData(data);
  }, [data]);

  const levelsNames = useMemo(() => {
    if (!data) return [];
    return getLevelsNames(data);
  }, [data]);

  if (isError) return null;
  return (
    <ParentSize>
      {({ width, height }) => (
        <StackedBarChart
          keys={Array.from(
            { length: mappedStackedBarChartData.maxLevels },
            (_, i) => `${i}`
          )}
          data={mappedStackedBarChartData.transformedData}
          levelsNames={levelsNames}
          width={width}
          height={height}
        />
      )}
    </ParentSize>
  );
};
