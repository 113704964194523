import style from "./HeaderAction.module.css";
import clsx from "clsx";

interface HeaderActionProps{
    onLogin : () => void;
}

const HeaderAction = ({onLogin } : HeaderActionProps) => {
  return (
    <>
      <button
        className={clsx(
          "btn",
          "btn_outlined",
          style.wrapper
        )}
        onClick={onLogin}
      >
        Log in
      </button>
    </>
  );
}

export default HeaderAction