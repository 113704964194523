import { Button, ButtonLabel, Card, Heading } from '_sredx/_ui'
import React from 'react';
import styles from "./OnboardingServicesImportStepContainer.module.css";


interface NoServicesSelectedWarningModalProps{
    onClose: () => void;
}

export const NoServicesSelectedWarningModal = ({onClose} : NoServicesSelectedWarningModalProps) => {
  return (
    <Card>
        <div className={styles.modal_wrapper}>
          <Heading level={4}> No services selected </Heading>
          <hr />
          <p>
            It seems no service was selected for importation.
            <br />
            You need to at least select one service.
          </p>
  
          <Button className={styles.button} variant="neutral" onClick={onClose}>
            <ButtonLabel>Ok</ButtonLabel>
          </Button>
        </div>
      </Card>
  )
}
