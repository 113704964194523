import styles from "./Pagination.module.css";
import clsx from "clsx";
import ReactPaginate from "react-paginate";
import { ReactNode } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "_sredx/_ui/Icon";

interface PaginationProps {
  breakLabel?: ReactNode;
  pageRangeDisplayed?: number;
  currentPage?: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const Pagination = ({
  breakLabel = "...",
  pageRangeDisplayed = 5,
  currentPage = 0,
  totalPages,
  onPageChange,
}: PaginationProps) => {
  const handlePageClick = ({ selected }: { selected: number }) => {
    onPageChange(selected);
  };

  return (
    <div className={clsx("nemo-pagination", styles.wrapper)}>
      <ReactPaginate
        breakLabel={breakLabel}
        nextLabel={
          <div className={styles.next_label}>
            <ChevronRightIcon />
          </div>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={totalPages}
        previousLabel={
          <div className={styles.prev_label}>
            <ChevronLeftIcon />
          </div>
        }
        renderOnZeroPageCount={null}
        containerClassName={styles.container}
        activeLinkClassName={styles.active_link}
        pageLinkClassName={styles.page_link}
        initialPage={currentPage}
      />
    </div>
  );
};
