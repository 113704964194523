import { PageLayout } from "components/Layout";
import React from "react";
import { ScoreCardFormComponent } from "components/scorecard/ScoreCardForm/ScoreCardFormComponent/ScoreCardFormComponent";

export const CreateNewScoreCardPage = () => {
  return (
    <PageLayout title={"Create a new ScoreCard"} extended>
      <ScoreCardFormComponent />
    </PageLayout>
  );
};
