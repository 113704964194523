import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { useMutation, useQueryClient } from "react-query";
import { ClientApi } from "../clientApi";

export const useEvaluateService = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.evaluateService],
    mutationFn: (data: EvaluateServiceDto) => {
      return ClientApi.post(API_ENDPOINTS.evaluateService(data.scorecardId, data.serviceId));
    },
    onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.evaluationsLevels);
        queryClient.invalidateQueries(QUERY_KEYS.scoreCards);
        queryClient.invalidateQueries(QUERY_KEYS.services_scores);
        }
  });
};

interface EvaluateServiceDto {
  serviceId: string;
  scorecardId: string;
}
