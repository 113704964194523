import {AxiosInstance, AxiosResponse} from "axios";
import Media from "models/scorecard/Media";


export const getAllLevelsMedia = (
    axios: AxiosInstance
): Promise<Media[]> => {
    return axios.get(`media`, {params: {ownerType: "level"}}).then((r) => {
        return r.data;
    });
};


export const getLevelMedia = (
    axios: AxiosInstance,
    levelId: String
): Promise<Media> => {
    return axios.get(`media/level/${levelId}`).then((r) => {
        return r.data;
    });
};

export const createLevelMedia = async (axios: AxiosInstance, mediaDto: FormData): Promise<string> => {
    const r = await axios.post<any, AxiosResponse, FormData>('media', mediaDto)
    return r.data;


}
export const updateLevelMedia = async (axios: AxiosInstance, mediaDto: FormData): Promise<string> => {
    const r = await axios.put<any, AxiosResponse, FormData>('media', mediaDto)
    return r.data;


}

export const getLevelsMediaByOwnersIdAndType = async (axios: AxiosInstance, levelIds: string[], ownerType: string): Promise<Media[]> => {
    const idsString = levelIds.join('&ids=');
    const r = await axios.get<any, AxiosResponse<Media[]>>(`media/${ownerType}/ids?ids=${idsString}`)
    return r.data
}
  