import { PageContent } from "_sredx/_ui";
import { ScoreCardsListingContainer } from "_sredx/containers";

export const ScorecardsListingPage = () => {
  return (
    <PageContent>
      <ScoreCardsListingContainer />
    </PageContent>
  );
};
