import { DomainBaseDto, DomainSearchCriteria } from "_sredx/types";
import { API_PAGE_SIZE_DEFAULT } from "_sredx/constants";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { parsePageNumber } from "_sredx/utils";
import { useGetDomains } from "_sredx/services";
import { useDebounce, useModal } from "_sredx/hooks";
import { Button, ButtonLabel, Heading, Searchbar } from "_sredx/_ui";
import {
  DOMAIN_ROW_ACTIONS_TYPE,
  DomainDataTable,
  DomainRowActionType,
} from "_sredx/components";
import { DomainCreationContainer } from "../DomainCreationContainer";
import { DomainUpdateContainer } from "../DomainUpdateContainer";

import styles from "./DomainListingContainer.module.css";
import { DomainDeleteContainer } from "../DomainDeleteContainer";

export const DOMAIN_SEARCH_CRITERIA_DEFAULT: DomainSearchCriteria = {
  pageNumber: 1,
  pageSize: API_PAGE_SIZE_DEFAULT,
};

export const DomainsListingContainer = () => {
  // State
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");

  // Hooks
  const debouncedQuery = useDebounce(searchQuery, 400);
  const { openModal, closeModal } = useModal();

  // Derived variables
  const currentPage = parsePageNumber(searchParams.get("page"));

  // Services
  const {
    data: domains,
    isLoading,
    isFetching,
  } = useGetDomains({
    params: {
      ...DOMAIN_SEARCH_CRITERIA_DEFAULT,
      name: debouncedQuery,
      pageNumber: currentPage,
    },
  });

  // Event handlers
  const handleOnAddDomainClick = () => {
    openModal({
      content: (
        <div className={styles.modal_wrapper}>
          <DomainCreationContainer closeModal={closeModal} />
        </div>
      ),
    });
  };

  const handleOnSearch = (value: string) => {
    setSearchQuery(value);
  };

  const handleOnPageChange = (page: number) => {
    setSearchParams({
      page: (page + 1).toString(),
    });
  };

  const handleOnAction = (
    domain: DomainBaseDto,
    actionType: DomainRowActionType
  ) => {
    switch (actionType) {
      case DOMAIN_ROW_ACTIONS_TYPE.UPDATE:
        openModal({
          content: (
            <div className={styles.modal_wrapper}>
              <DomainUpdateContainer closeModal={closeModal} domain={domain} />
            </div>
          ),
        });
        break;
      case DOMAIN_ROW_ACTIONS_TYPE.DELETE:
        openModal({
          content: (
            <div
              style={{
                width: "600px",
              }}
            >
              <DomainDeleteContainer closeModal={closeModal} domain={domain} />
            </div>
          ),
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header_wrapper}>
        <div className={styles.title_wrapper}>
          <Heading>Domains</Heading>
          <p>Discover, create and manage your domains with ease</p>
        </div>
        <Button onClick={handleOnAddDomainClick}>
          <ButtonLabel>Add new domain</ButtonLabel>
        </Button>
      </div>

      <div className={styles.search_filter_wrapper}>
        <div className={styles.searchbar_wrapper}>
          <Searchbar
            value={searchQuery}
            onChange={handleOnSearch}
            placeholder="Search domains by name"
          />
        </div>
      </div>

      <div className={styles.table_wrapper}>
        <DomainDataTable
          domains={domains && domains.content}
          isLoading={isLoading}
          isFetching={isFetching}
          onAddDomains={handleOnAddDomainClick}
          totalPages={domains && domains.totalPages}
          onPageChange={handleOnPageChange}
          currentPage={currentPage}
          totalElements={domains && domains.totalElements}
          onAction={handleOnAction}
        />
      </div>
    </div>
  );
};
