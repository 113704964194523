import { ReactNode } from "react";

export const HEADER_USER_ACTIONS_TYPE = {
  LOGOUT: "LOGOUT",
} as const;

export type HeaderUserActionType = keyof typeof HEADER_USER_ACTIONS_TYPE;

export interface HeaderUserAction {
  type: HeaderUserActionType;
  component: ReactNode;
}
