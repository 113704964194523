import { PageContent } from "_sredx/_ui";
import { MembersListingContainer } from "_sredx/containers/MembersListingContainer";
import React from "react";

export const MembersListingPage = () => {
  return (
    <PageContent>
      <MembersListingContainer />
    </PageContent>
  );
};
