import React, { PropsWithChildren } from "react";
import styles from "./Card.module.css";
import clsx from "clsx";

export interface CardProps {
  clickable?: boolean;
  className?: string;
}

export const Card = ({
  children,
  clickable,
  className,
}: PropsWithChildren<CardProps>) => {
  const classNames = clsx(
    "nemo-card",
    styles.wrapper,
    clickable && styles.clickable,
    className
  );

  return <div className={classNames}>{children}</div>;
};
