import { useEffect } from "react";
import { ServiceInput } from "../../../models/service/Service";
import { useAddServices } from "../../../hooks/useAddServices";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { ServiceAddBody } from "components/service/ServiceAddBody";

interface ConfirmationStepProps {
  services: ServiceInput[];
  onPreviousStep: () => void;
}

export const ConfirmationStepContainer = ({
  services,
}: ConfirmationStepProps) => {
  const { mutate, isLoading, isSuccess, isError } = useAddServices();
  const navigate = useNavigate();

  useEffect(() => {
    mutate(services);
  }, [services, mutate]);

  const resolveState = () => {
    switch (true) {
      case isLoading:
        return <div>Loading...</div>;
      case isError:
        return <div>Error while saving</div>;
      case isSuccess:
        return (
          <div className="flex flex-col items-center gap-6">
            <div className="flex items-center gap-4 text-black">
              <CheckCircleIcon className="h-10" /> Services Added successfully
            </div>
            <button
              onClick={() => navigate("/services")}
              className="bg-black text-white px-4 py-2 hover:bg-cyan-500 transition"
            >
              Return to services
            </button>
          </div>
        );
    }
  };
  return (
    <ServiceAddBody>
      <h1>Confirmation</h1>
      <div className="h-96 flex items-center justify-center">
        {resolveState()}
      </div>
    </ServiceAddBody>
  );
};
