import { getPercentageColor } from "../utils";
import styles from "./InitiativeDataTableRow.module.css";

interface InitiativeProgressProps {
  // initiativeEvaluationResults: InitiativeEvaluationResultDto[];
  progress: number;
}

export const InitiativeProgressBar = ({
  progress,
}: InitiativeProgressProps) => {
  // Derived Variables
  const percentageColor = getPercentageColor(progress);

  if (progress == null) return <span>-</span>;

  return (
    <div className={styles.rules_progress_bar_wrapper}>
      <div className={styles.value} style={{ color: percentageColor }}>
        {progress}%
      </div>

      <div className={styles.progress_wrapper}>
        <div
          className={styles.progress_value}
          style={{
            width: `${progress}%`,
            backgroundColor: percentageColor,
          }}
        />
      </div>
    </div>
  );
};
