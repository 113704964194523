import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  KeyValue,
  LogoSREDXBlack,
} from "_sredx/_ui";
import styles from "./TeamOverview.module.css";
import { TeamBaseDto } from "_sredx/types";
import { resolveIntegration } from "_sredx/utils";

interface TeamOverviewProps {
  team: TeamBaseDto;
}

export const TeamOverview = ({ team }: TeamOverviewProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>About</CardTitle>
      </CardHeader>

      <CardBody>
        <div className={styles.body}>
          <KeyValue label={"Team Name"} value={team.name} />
          <KeyValue
            className={styles.description}
            label={"Team Description"}
            value={team.description}
          />
          <KeyValue
            label={"Imported from"}
            value={
              <div className={styles.importedFrom}>
                {team.source == "sredx" || team.source == null ? (
                  <div className={styles.sredx_logo}>
                    <LogoSREDXBlack />
                  </div>
                ) : (
                  <div className={styles.integration_button}>
                    <div>{resolveIntegration(team.source).icon}</div>
                    {resolveIntegration(team.source).name}
                  </div>
                )}
              </div>
            }
          />
          <KeyValue label={"Imported by"} value={team.importedBy ?? "-"} />
          <KeyValue
            label={"Import date"}
            value={new Date(team.createdAt).toUTCString()}
          />
        </div>
      </CardBody>
      <CardFooter />
    </Card>
  );
};
