import React from 'react';
import ProgressBar from './progress-bar/ProgressBar';
import styles from "./AverageScoreStyles.module.css";
import "styles/fonts.css";
import clsx from 'clsx';

interface AverageScoreProps {
    score: number,
    totalScore: number
}

export const  AverageScore = ({score, totalScore}: AverageScoreProps) => {
  return (
    <div>
          <div className={styles.average_score_progress }>
            <span className={"text_sm"}>Average Score</span>
            <span className={clsx("text_sm", "bold") }>{score}/{totalScore}</span>
          </div>
            <ProgressBar percentage={score/totalScore*100}/>
          </div>
  )
}
