import React, { PropsWithChildren, forwardRef } from "react";
import { Text } from "_sredx/_ui";
import clsx from "clsx";
import styles from "./Checkbox.module.css";

export interface CheckBoxProps extends React.HTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  onCheck: (checked: boolean) => void;
  label: string;
}

export const Checkbox = forwardRef<HTMLInputElement, PropsWithChildren<CheckBoxProps>>(
  ({ className, checked, label, onCheck, children }, ref) => {
    return (
      <label className={clsx(styles.wrapper, className)}>
        <input
          type={"checkbox"}
          checked={checked}
          onChange={(e) => onCheck(e.target.checked)}
          ref={ref}
        />
        {children}
        <Text>{label}</Text>
      </label>
    );
  }
);
