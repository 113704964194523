import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { Line } from "@visx/shape";

interface CompositeBarSegmentLineProps {
  x: number;
  y: number;
  label: string;
  width: number;
  height?: number;
  strokeWidth?: number;
  strokeColor?: string;
}

export const CompositeBarSegmentLine = ({
  x,
  y,
  label,
  width,
  height = 10,
  strokeWidth = 1,
  strokeColor = "#000000",
}: CompositeBarSegmentLineProps) => {
  return (
    <Group top={y} left={x}>
      <Text
        fontSize={12}
        x={width}
        textAnchor={"end"}
        verticalAnchor={"end"}
        y={0}
        dx={0}
        dy={height - 8}
      >
        {label}
      </Text>
      <Line
        from={{ x: 0, y: height }}
        to={{
          x: width,
          y: height,
        }}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </Group>
  );
};
