import {forwardRef} from "react";
import {IconProps} from "./types";

export const TrophyIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        {/*<path*/}
        {/*  fill={color}*/}
        {/*  d="M16.34 16.857h-2.204a2.106 2.106 0 0 0-.326-.376c-.294-.287-.72-.705-.971-1.853 1.446-.397 2.435-1.439 2.46-1.453 1.623-.808 4.17-5.834 4.188-8.268.008-.986-.393-1.4-.73-1.574-.57-.298-1.35-.142-2.41.496V3.5a.5.5 0 0 0-.5-.499H7.64a.5.5 0 0 0-.5.5v.33c-1.063-.635-1.84-.791-2.41-.497-.337.174-.737.588-.73 1.574.018 2.434 2.566 7.46 4.19 8.271.024.015 1.01 1.056 2.459 1.453-.252 1.145-.677 1.567-.971 1.854-.114.113-.238.23-.33.376H7.144a.5.5 0 0 0-.5.5v3.14a.5.5 0 0 0 .5.499h9.196a.5.5 0 0 0 .5-.5v-3.14a.502.502 0 0 0-.5-.503Zm.007-6.677V5.017c1.453-1 1.903-.823 1.95-.798.12.064.191.316.187.677-.007 1.464-1.084 3.987-2.158 5.685.014-.131.021-.266.021-.4ZM5 4.896c-.003-.361.068-.617.188-.677.05-.025.496-.198 1.95.798v5.16c0 .134.007.269.02.404C6.088 8.883 5.01 6.36 5 4.896Zm3.14 5.284V3.996h7.209v6.18a3.604 3.604 0 0 1-7.209.004Zm4.647 6.677h-2.084c.33-.383.709-.982.939-2.077h.206c.23 1.095.61 1.698.939 2.077Zm-5.14 3.144V17.86h8.195V20H7.647Z"*/}
        {/*/>*/}
          <path fill={color} fillRule="evenodd" d="M5.465 5.166A1.65 1.65 0 0 1 7.106 3.35h9.787c.977 0 1.74.844 1.642 1.816l-.12 1.184h2.04c.66 0 1.195.535 1.195 1.195V9a4.65 4.65 0 0 1-4.15 4.623 4.239 4.239 0 0 1-1.787 2.154c-.882.538-1.948.787-3.063.854v3.719H15a.65.65 0 1 1 0 1.3H9a.65.65 0 1 1 0-1.3h2.35v-3.72c-1.116-.066-2.182-.315-3.064-.853A4.24 4.24 0 0 1 6.5 13.623 4.65 4.65 0 0 1 2.349 9V7.545c0-.66.536-1.195 1.196-1.195h2.04l-.12-1.184Zm.252 2.484H3.65V9a3.351 3.351 0 0 0 2.534 3.25l-.467-4.6Zm1.932 5.327a.646.646 0 0 0-.083-.296 4.55 4.55 0 0 1-.065-.322l-.743-7.324a.35.35 0 0 1 .348-.385h9.787a.35.35 0 0 1 .348.385l-.743 7.324a4.58 4.58 0 0 1-.065.322.647.647 0 0 0-.083.296c-.256.793-.72 1.328-1.314 1.69-.769.469-1.813.683-3.036.683-1.224 0-2.268-.214-3.037-.683-.594-.362-1.058-.897-1.314-1.69Zm10.167-.728A3.351 3.351 0 0 0 20.35 9V7.65h-2.068l-.466 4.6Z" clipRule="evenodd"/>
      </svg>
    );
  }
);
