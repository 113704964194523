import Service from "models/service/Service";
import { useState } from "react";
import { UseMutationResult } from "react-query";

interface DeleteModalBodyProps {
    service: Service;
    hide: () => void;
    deleteServiceMutation: UseMutationResult<void, unknown, string>;
}
  
export const DeleteServiceModalBody: React.FC<DeleteModalBodyProps> = ({ service, hide, deleteServiceMutation }) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState("");
    const [error, setError] = useState("");
  
    const confirmDelete = async () => {
        if (deleteConfirmation === service.name) {
            try {
                hide();
                await deleteServiceMutation.mutateAsync(service.id);
            } catch (err) {
                console.error('Error deleting the service', err);
            }
        } else {
            setError("The name does not match, please try again.");
        }
    };
  
    return (
      <div className="space-y-2">
          <h3 className="text-lg font-semibold">Confirm Deletion</h3>
          <p className="font-light" >For safety measures, you're requested to accurately type the name of the service you wish to delete.</p>
          <p className="text-red-500">Service Name: {service.name}</p>
          <input 
              type="text" 
              value={deleteConfirmation} 
              onChange={(e) => setDeleteConfirmation(e.target.value)} 
              placeholder="Type service name to confirm" 
              className="w-full"
          />
          {error && <p className="text-red-500 mt-2">{error}</p>}
          <div className="flex justify-end my-2">
              <button 
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={hide}
              >
                  Cancel
              </button>

              <button 
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={confirmDelete}
              >
                  Delete
              </button>
          </div>
      </div>

    );
};