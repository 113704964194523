import {
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_REDIRECT_URI,
  REACT_APP_KEYCLOAK_URL,
} from "_sredx/config/env";
import { AuthProviderProps } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

export const OIDC_CONFIG: AuthProviderProps = {
  authority: REACT_APP_KEYCLOAK_URL,
  client_id: REACT_APP_KEYCLOAK_CLIENT_ID,
  redirect_uri: REACT_APP_KEYCLOAK_REDIRECT_URI,
  response_type: "code",
  scope: "openid profile email",
  post_logout_redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};
