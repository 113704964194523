import clsx from "clsx";
import styles from "./Avatar.module.css";
import { createAvatar } from "@dicebear/core";
import { useMemo } from "react";
import { styleResolver } from "./utils";

interface AvatarProps {
  name: string;
  size?: number;
  rectangle?: boolean;
  avatarStyle?: "initials" | "identicon";
}

export const Avatar = ({
  name,
  size = 24,
  rectangle = false,
  avatarStyle = "initials",
}: AvatarProps) => {
  const avatarSrc = useMemo(() => {
    return createAvatar(styleResolver(avatarStyle), {
      size: size,
      seed: name,
    }).toDataUriSync();
  }, [name, size, avatarStyle]);

  return (
    <div
      className={clsx("nemo-avatar", styles.wrapper)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: rectangle ? "0" : `${size}px`,
      }}
    >
      <img
        src={avatarSrc}
        alt={name}
        className={clsx("nemo-avatar-img", styles.img)}
      />
    </div>
  );
};
