import { Loader, Text } from "_sredx/_ui";
import { TeamSwitcher } from "_sredx/components";
import { TeamInitiativesContainer } from "_sredx/containers/TeamInitiativesContainer";
import { TeamServicesContainer } from "_sredx/containers/TeamServicesContainer";
import { useTeamContext } from "_sredx/context";
import { TeamInfoDto } from "_sredx/types";
import clsx from "clsx";
import React from "react";
import { AchievementsContainer } from "../AchievementsContainer";
import { NextLevelActionsContainer } from "../NextLevelActionsContainer";
import styles from "./HomeContainer.module.css";

export const HomeContainer = () => {
  // Hooks
  const { teams, selectedTeam, setSelectedTeam, isLoading } = useTeamContext();

  // Event handlers
  const handleOnSelect = (team: TeamInfoDto) => {
    setSelectedTeam(team);
  };

  // UI waterfall
  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text>Loading...</Text>
      </div>
    );
  }

  if (!teams.length) {
    return <div>You haven't joined a team yet.</div>;
  }

  if (!selectedTeam) return null;

  return (
    <div className={clsx(styles.wrapper)}>
      <div className={styles.header}>
        <TeamSwitcher
          teams={teams}
          onSelect={handleOnSelect}
          selectedTeam={selectedTeam}
        />
        <Text size={"lg"}>
          This is your team home, The gateway to everything
        </Text>
      </div>

      <AchievementsContainer />
      <NextLevelActionsContainer />
      <TeamServicesContainer />
      <TeamInitiativesContainer />
    </div>
  );
};
