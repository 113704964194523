import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import { ApiErrorResponse, InstanceCreationDto } from "_sredx/types";
import { useMutation, useQueryClient } from "react-query";

export const useCreateInstance = () => {
  const queryClient = useQueryClient();
  return useMutation<string, ApiErrorResponse, InstanceCreationDto>({
    mutationKey: [MUTATION_KEYS.createInstance],
    mutationFn: (data: InstanceCreationDto) =>
      ClientApi.post(API_ENDPOINTS.createIntegrationInstance(), data),

    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.integrationInstances], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.servicesDiscovery], {
        exact: false,
      });
    },
  });
};
