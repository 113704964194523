import { CheckCircleIcon } from "@heroicons/react/outline";
import styles from "./ScorecardAccordionCategoryRow.module.css";
import { Text } from "_sredx/_ui";
import clsx from "clsx";

export const ScorecardAccordionCategoryRow = ({ category }: any) => {
  const successfulRules = category.rules.filter((rule: any) => rule.success);
  return (
    <div className={styles.wrapper}>
      <Text size="lg">{category.category}</Text>
      <div className={styles.right_section}>
        {successfulRules === category.rules.length ? (
          <CheckCircleIcon className={clsx(styles.icon, styles.success)} />
        ) : (
          <div className={clsx(styles.icon, styles.fail)}>
            <Text size="xs">{category.rules.length - successfulRules.length}</Text>
          </div>
        )}
        {successfulRules.length}/{category.rules.length}
      </div>
    </div>
  );
};
