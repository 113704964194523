import ServiceRow from 'componentsv2/specific/ServiceRow/ServiceRow';
import { useServicesScores } from 'hooks/useServicesScores';
import React, { useMemo } from 'react'

import { generateServicesRanks } from './utils';
import LeaderBoardPanel from 'componentsv2/specific/LeaderBoardPanel/LeaderBoardPanel';
import ScoreCard from 'models/scorecard/ScoreCard';
import ScorecardsFilterComponent from 'pages/admin/leaderboard/modules/ScorecardsFilterComponent';
import ServiceRowSkeleton from 'componentsv2/specific/ServiceRow/ServiceRowSkeleton';

const RankedServiceListContainer = () => {

    const { isLoading, isError, data: serviceScores } = useServicesScores();
    const [selectedScorecardIds, setSelectedScorecardIds] = React.useState<
      string[]
    >([]);
  
    const handleSelectedScorecards = (scorecards: ScoreCard[] | undefined) => {
        if (!!scorecards) {
          setSelectedScorecardIds(scorecards.map((scorecard) => scorecard.id));
        } else {
          setSelectedScorecardIds([]);
        }
    };
    
    const servicesRanks = useMemo(
        () => generateServicesRanks(serviceScores ?? [], selectedScorecardIds),
        [serviceScores, selectedScorecardIds]
    );

    if (isLoading) {
        return (
            <LeaderBoardPanel heading='Rank Highlights'>
                <ServiceRowSkeleton />
                <ServiceRowSkeleton />
                <ServiceRowSkeleton />
                <ServiceRowSkeleton />
                <ServiceRowSkeleton />
                <ServiceRowSkeleton />
                <ServiceRowSkeleton />
                <ServiceRowSkeleton />
            </LeaderBoardPanel>
        )
    }
  
    if (isError) {
        return <p className="text-center text-gray-500">Something went wrong...</p>;
    }

    
  return (
    <section>
        <LeaderBoardPanel
            heading='Rank Highlights'
            filter={
                <ScorecardsFilterComponent 
                    onChange={handleSelectedScorecards}
                />
            }
        >
            {servicesRanks?.map((serviceRank, i) => ( 
                <React.Fragment
                    key={serviceRank.service.id}
                >
                    <ServiceRow
                        serviceRank={serviceRank}
                        position={i+1}
                    /> 
                    <hr className='my-2' />

                </React.Fragment>
                
            ))}
        </LeaderBoardPanel>
    </section>
  )
}

export default RankedServiceListContainer