import create from "zustand";

interface Toast {
  id: number;
  message: string;
  type: "success" | "error" | "warning" | "info";
}
interface ToastPayload {
  message: string;
  type: "success" | "error" | "warning" | "info";
}
interface ToastStore {
  toasts: Toast[];
  addToast: (payload:ToastPayload) => void;
  removeToast: (id: number) => void;
}

export const useToaster = create<ToastStore>((set) => ({
  toasts: [],
  addToast: ({ message, type }) =>
    set((state) => ({
      toasts: [...state.toasts, { id: Date.now(), message, type }],
    })),
  removeToast: (id) =>
    set((state) => ({
      toasts: state.toasts.filter((toast) => toast.id !== id),
    })),
}));
