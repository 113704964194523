import { AdvancedFilterConfig } from "../types";

export const groupPropertiesByCategory = (
  properties: AdvancedFilterConfig[],
) => {
  return properties.reduce(
    (acc, property) => {
      if (!acc[property.category]) {
        acc[property.category] = [];
      }
      acc[property.category].push(property);
      return acc;
    },
    {} as Record<string, AdvancedFilterConfig[]>,
  );
};

export const searchProperties = (
  properties: AdvancedFilterConfig[],
  query: string,
) => {
  return properties.filter((property) => {
    return (
      property.label.toLowerCase().includes(query.toLowerCase()) ||
      property.key.toLowerCase().includes(query.toLowerCase()) ||
      (property.description &&
        property.description.toLowerCase().includes(query.toLowerCase())) ||
      property.category.toLowerCase().includes(query.toLowerCase())
    );
  });
};
