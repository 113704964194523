import { round } from "lodash";
import { InitiativeEvaluationResultDto } from "_sredx/types";

export const calculateProgressPercentage = (
  initiativeEvaluationResultDtos: InitiativeEvaluationResultDto[]
) => {
  if (!initiativeEvaluationResultDtos.length) return null;

  const progress = initiativeEvaluationResultDtos.reduce((acc, curr) => {
    if (curr.ruleEvaluationResult.success) {
      acc++;
    }
    return acc;
  }, 0);
  return round((progress / initiativeEvaluationResultDtos.length) * 100, 1);
};

export const getPercentageColor = (percentage: number | null) => {
  if (percentage == null) return "#000000";
  if (percentage >= 80) return "#00b341";
  if (percentage >= 50) return "#f5a623";
  return "#ff0000";
};
