import { useGetResources } from "_sredx/services/resources/useGetResources";
import styles from "./ResourcesListingContainer.module.css";
import { ResourcesDataTable } from "_sredx/components/ResourcesDataTable/ResourcesDataTable";
import { useSearchParams } from "react-router-dom";
import { parsePageNumber } from "_sredx/utils";
import { ResourceDto } from "_sredx/types/resources";
import { useModal } from "_sredx/hooks";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
} from "_sredx/_ui";
import { ResourceDeleteWarningModal } from "_sredx/components/ResourceDeleteWarningModal";
import { useDeleteResource } from "_sredx/services/resources/useDeleteResource";

export const ResourcesListingContainerr = () => {
  //state
  const [searchParams, setSearchParams] = useSearchParams();

  //derived variables
  const currentPage = parsePageNumber(searchParams.get("page"));

  //hooks
  const { data, isLoading, isFetching } = useGetResources({
    params: {
      pageNumber: currentPage,
      pageSize: 10,
    },
  });
  const { mutate: deleteResource } = useDeleteResource();
  const { openModal, closeModal } = useModal();

  const deleteModalMutation = (id:string) => {
    deleteResource(id,{
      onSuccess: () => {
        closeModal();
      }
    });
  };

  const handleDeleteResource = (resource: ResourceDto) => {
    openModal({
      content: (
        <ResourceDeleteWarningModal
          onDelete={deleteModalMutation}
          resource={resource}
          closeModal={closeModal}
        />
      ),
    });
  };

  //handlers
  const handlePageChange = (page: number) => {
    setSearchParams({ page: (page + 1).toString() });
  };
  return (
    <div className={styles.wrapper}>
      {/* TODO Filters */}

      <ResourcesDataTable
        resources={data && data.content}
        totalElements={data && data.totalElements}
        totalPages={data && data.totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        isLoading={isLoading}
        isFetching={isFetching}
        onDeleteResource={handleDeleteResource}
        onClickResource={() => {}}
        onAddResourceClick={() => {}}
      />
    </div>
  );
};
