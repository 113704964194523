import React from "react";

export const mergeRefs =<T = any> (...refs: React.Ref<T>[]): React.RefCallback<T> => {
    return (value: T) => {
        for (const ref of refs) {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref != null) {
                (ref as React.MutableRefObject<T | null>).current = value;
            }
        }
    };
}

export const dataURLtoFile = (dataurl: string, filename: string): File | null => {
    // Define a list of allowed image MIME types
    const allowedMimeTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];

    // Parse the data URL using a safer approach
    const parts = RegExp(/^data:([^;]+);base64,(.+)$/).exec(dataurl);
    if (!parts || parts.length !== 3) {
        return null; // Invalid data URL
    }

    const [, mime, data] = parts;

    // Check if the MIME type is in the allowed list
    if (!allowedMimeTypes.includes(mime)) {
        return null; // Invalid MIME type
    }

    // Convert base64 data to a Uint8Array
    const decodedData = atob(data);
    const byteArray = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
    }

    // Create a Blob
    const blob = new Blob([byteArray], { type: mime });

    try {
        // Try to create a File (may not be supported in older browsers)
        return new File([blob], filename, { type: mime });
    } catch (e) {
        return null;
    }
};

export const resolveMediaUrl = (mediaURl : string) => {
    if(mediaURl.includes('blob')) {
        return mediaURl
    }
    return `data:image/jpeg;charset=utf-8;base64,${mediaURl}`
}
