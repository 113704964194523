import React from 'react';

export const DTrack = () => {
  return (
    <svg width="20" height="24" viewBox="0 0 100 215" version="1.1">
      <g id="Symbol">
        <g transform="matrix(3.41938,0,0,3.41938,62.8551,21.9764)">
          <path
            d="M0,7.701C0,10.047 -1.909,11.955 -4.254,11.955L-11.955,11.955C-14.3,11.955 -16.209,10.047 -16.209,7.701L-16.209,0.001C-16.209,-2.345 -14.3,-4.253 -11.955,-4.253L-4.254,-4.253C-1.909,-4.253 0,-2.345 0,0.001L0,7.701ZM-3.895,-6.427L-12.314,-6.427C-15.665,-6.427 -18.382,-3.71 -18.382,-0.359L-18.382,8.061C-18.382,11.412 -15.665,14.128 -12.314,14.128L-3.895,14.128C-0.543,14.128 2.173,11.412 2.173,8.061L2.173,-0.359C2.173,-3.71 -0.543,-6.427 -3.895,-6.427"
            style={{ fill: 'rgb(255,0,207)', fillRule: 'nonzero' }}
          />
        </g>
        <g transform="matrix(3.41938,0,0,3.41938,128.299,95.836)">
          <path
            d="M0,7.701C0,10.047 -1.909,11.955 -4.254,11.955L-11.955,11.955C-14.3,11.955 -16.209,10.047 -16.209,7.701L-16.209,0.001C-16.209,-2.345 -14.3,-4.253 -11.955,-4.253L-4.254,-4.253C-1.909,-4.253 0,-2.345 0,0.001L0,7.701ZM-3.895,-6.427L-12.314,-6.427C-15.665,-6.427 -18.382,-3.71 -18.382,-0.359L-18.382,8.061C-18.382,11.412 -15.665,14.128 -12.314,14.128L-3.895,14.128C-0.543,14.128 2.173,11.412 2.173,8.061L2.173,-0.359C2.173,-3.71 -0.543,-6.427 -3.895,-6.427"
            style={{ fill: 'rgb(255,0,207)', fillRule: 'nonzero' }}
          />
        </g>
        <g transform="matrix(3.41938,0,0,3.41938,62.8551,165.998)">
          <path
            d="M0,7.701C0,10.047 -1.909,11.955 -4.254,11.955L-11.955,11.955C-14.3,11.955 -16.209,10.047 -16.209,7.701L-16.209,0.001C-16.209,-2.345 -14.3,-4.253 -11.955,-4.253L-4.254,-4.253C-1.909,-4.253 0,-2.345 0,0.001L0,7.701ZM-3.895,-6.427L-12.314,-6.427C-15.665,-6.427 -18.382,-3.71 -18.382,-0.359L-18.382,8.061C-18.382,11.412 -15.665,14.128 -12.314,14.128L-3.895,14.128C-0.543,14.128 2.173,11.412 2.173,8.061L2.173,-0.359C2.173,-3.71 -0.543,-6.427 -3.895,-6.427"
            style={{ fill: 'rgb(255,0,207)', fillRule: 'nonzero' }}
          />
        </g>
      </g>
    </svg>
  )
}
