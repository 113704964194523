import { isEmpty } from "_sredx/utils";
import { InitiativeCreationAction, InitiativeCreationFormData } from "./types";
import {
  InitiativeCreationDto,
  InitiativeService,
  RuleDto,
  ServiceRichDto,
} from "_sredx/types";

export const mapInitiativeCreationFormDataToInitiativeCreationDto = (
  initiativeCreationFormData: InitiativeCreationFormData
): InitiativeCreationDto => {
  return {
    name: initiativeCreationFormData.generalInfo.name,
    description: initiativeCreationFormData.generalInfo.description,
    startDate: initiativeCreationFormData.schedulingInfo.startDate,
    endDate: initiativeCreationFormData.schedulingInfo.endDate,
    status: initiativeCreationFormData.generalInfo.status,
  };
};

export const isValidInitiativeCreationFormData = (
  initiativeCreationFormData?: InitiativeCreationFormData
): boolean => {
  if (!initiativeCreationFormData) return false;

  return (
    isValidInitiativeCreationGeneralInfo(
      initiativeCreationFormData.generalInfo
    ) &&
    isValidInitiativeCreationSchedulingInfo(
      initiativeCreationFormData.schedulingInfo
    ) &&
    isValidInitiativeCreationRules(initiativeCreationFormData.rules) &&
    isValidInitiativeCreationServices(initiativeCreationFormData.services)
  );
};

const isValidInitiativeCreationGeneralInfo = (
  generalInfo: InitiativeCreationFormData["generalInfo"]
): boolean => {
  if (!generalInfo) return false;

  return (
    !isEmpty(generalInfo.name) &&
    !isEmpty(generalInfo.status) &&
    !isEmpty(generalInfo.description)
  );
};

const isValidInitiativeCreationSchedulingInfo = (
  schedulingInfo: InitiativeCreationFormData["schedulingInfo"]
): boolean => {
  if (!schedulingInfo) return false;

  return (
    !isEmpty(schedulingInfo.startDate) &&
    !isEmpty(schedulingInfo.endDate) &&
    schedulingInfo.startDate! <= schedulingInfo.endDate!
  );
};

const isValidInitiativeCreationRules = (
  rules: InitiativeCreationFormData["rules"]
): boolean => {
  if (!rules) return false;

  return !isEmpty(rules);
};

const isValidInitiativeCreationServices = (
  services: InitiativeCreationFormData["services"]
): boolean => {
  if (!services) return false;

  return !isEmpty(services);
};

export const initiativeCreationReducer = (
  state: InitiativeCreationFormData,
  action: InitiativeCreationAction
): InitiativeCreationFormData => {
  switch (action.type) {
    case "SET_GENERAL_INFO":
      return {
        ...state,
        generalInfo: action.payload.generalInfo,
      };
    case "SET_SCHEDULING_INFO":
      return {
        ...state,
        schedulingInfo: action.payload.schedulingInfo,
      };
    case "SET_RULES":
      return {
        ...state,
        rules: action.payload.rules,
      };
    case "DELETE_RULE":
      return {
        ...state,
        rules: deleteRule(state.rules, action.payload.rule),
      };

    case "SET_SERVICES":
      return {
        ...state,
        services: action.payload.services,
      };
    case "DELETE_SERVICE":
      return {
        ...state,
        services: deleteService(state.services, action.payload.service),
      };
    default:
      return state;
  }
};

const deleteRule = (rules: RuleDto[], rule: RuleDto) => {
  return rules.filter((r) => r.id != rule.id);
};

const deleteService = (
  services: InitiativeService[],
  service: InitiativeService
) => {
  return services.filter((s) => s.id != service.id);
};

export const mapInitiativeServicesToServiceBaseDtos = (
  initiativeService: InitiativeService[]
) => {
  return initiativeService.map(
    (s) =>
      ({
        id: s.id,
        name: s.name,
        owners: [],
        domains: [],
        tools: [],
        source: "",
        allRulesCount: 0,
        activeRulesCount: 0,
        description: "",
        failedPercentage: 0,
        failedRulesCount: 0,
        score: 0,
        scorePercentage: 0,
        successfulRulesCount: 0,
        tags: [],
        successPercentage: 0,
        tag: "",
        totalPossible: 0,
      } as ServiceRichDto)
  );
};
