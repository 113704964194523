import { Button, ButtonLabel, Card, Heading } from "_sredx/_ui";
import styles from "./OnboardingImportedUsersTable.module.css";

interface UserChoiceModalProps {
  onClose: () => void;
}

export const UserChoiceModal = ({ onClose }: UserChoiceModalProps) => {
  return (
    <Card>
      <div className={styles.modal_wrapper}>
        <Heading level={4}> Duplicated users </Heading>
        <hr />
        <p>
          It seems another user with the same email was selected.
          <br />
          There cannot be two users with the same email so choose one of them.
        </p>

        <Button className={styles.button} variant="neutral" onClick={onClose}>
          <ButtonLabel>Ok</ButtonLabel>
        </Button>
      </div>
    </Card>
  );
};
