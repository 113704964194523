import { useModal } from "hooks/useModal";
import styles from "./ClusterDetailsDeleteContainer.module.css";
import React from "react";
import { ClusterDeleteModal } from "components/self-service/ClusterDetails/ClusterDeleteModal";
import { useDeleteCluster } from "services/self-service/useDeleteCluster";
import { useNavigate } from "react-router-dom";
import { ADMIN_SELF_SERVICE_PAGE } from "routes/paths";
import { Button } from "components/octo-ui/Button/Button";

interface ClusterDetailsDeleteContainerProps {
  id: string;
}
export const ClusterDetailsDeleteContainer = ({
  id,
}: ClusterDetailsDeleteContainerProps) => {
  const { show, hide } = useModal();
  const navigate = useNavigate();

  const { mutate } = useDeleteCluster(id);

  const handleDeleteCluster = () => {
    mutate(void 1, {
      onSuccess: () => {
        hide();
        navigate(ADMIN_SELF_SERVICE_PAGE);
      },
    });
  };
  const onDeleteCluster = () => {
    show({
      body: <ClusterDeleteModal />,
      options: {
        actions: (
          <div className="flex gap-4">
            <button onClick={hide}>Cancel</button>
            <Button onClick={handleDeleteCluster} variant="danger">
              Delete
            </Button>
          </div>
        ),
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.title}>Delete this cluster</h3>
        <p>
          Deleting your cluster will permanently destroy your cluster and all of
          its associated pools and Instances.
        </p>
      </div>
      <button onClick={onDeleteCluster} className={styles.deleteButton}>
        Delete this cluster
      </button>
    </div>
  );
};
