import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import { TeamInfoDto } from "_sredx/types";
import { TeamSwitcherItem } from "./TeamSwitcherItem";
import styles from "./TeamSwitcher.module.css";
import { TeamSwitcherTrigger } from "./TeamSwitcherTrigger";

interface TeamSwitcherProps {
  selectedTeam?: TeamInfoDto;
  teams?: TeamInfoDto[];
  onSelect?: (team: TeamInfoDto) => void;
}

export const TeamSwitcher = ({
  selectedTeam,
  teams = [],
  onSelect,
}: TeamSwitcherProps) => {
  // Event handlers
  const handleOnSelectTeam = (team: TeamInfoDto) => {
    onSelect && onSelect(team);
  };

  if (!teams.length) {
    return null;
  }

  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger className={styles.trigger}>
        <TeamSwitcherTrigger selectedTeam={selectedTeam} />
      </RadixDropdownMenu.Trigger>

      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content className={styles.dropdown_menu}>
          {teams.map((team, i) => (
            <RadixDropdownMenu.Item
              key={i}
              className={styles.dropdown_menu_item}
              onSelect={() => handleOnSelectTeam(team)}
            >
              <TeamSwitcherItem
                team={team}
                isSelected={selectedTeam && selectedTeam.id === team.id}
              />
            </RadixDropdownMenu.Item>
          ))}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};
