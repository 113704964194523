import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ApiErrorResponse, UpdateInitiativesDto } from "_sredx/types";
import { ClientApi } from "../clientApi";

export const useUpdateInitiatives = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, ApiErrorResponse, UpdateInitiativesDto[]>({
    mutationKey: [MUTATION_KEYS.updateInitiatives],
    mutationFn: (dtos) => {
      return ClientApi.patch(API_ENDPOINTS.updateInitiatives(), dtos);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.initiatives], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.initiativeRules], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.initiativeServices], {
        exact: false,
      });
    },
  });
};
