import { PageContent } from "_sredx/_ui";
import React from "react";
import { PullRequestMetricsContainer } from "_sredx/containers/PullRequestMetricsContainer";

export const MetricPullRequestsPage = () => {
  return (
    <PageContent>
      <PullRequestMetricsContainer />
    </PageContent>
  );
};
