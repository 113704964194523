export type UserProfileFormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  jobTitle: OrganizationJobTitleType;
};

export const ORGANIZATION_JOB_TITLES = {
  DEVELOPER: "developer",
} as const;
export type OrganizationJobTitleType =
  (typeof ORGANIZATION_JOB_TITLES)[keyof typeof ORGANIZATION_JOB_TITLES];
