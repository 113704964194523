import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { PagedResponse } from "_sredx/types";
import { useQuery } from "react-query";
import { ClientApi } from "../clientApi";
import { ResourceDto, ResourceSearchCriteria } from "_sredx/types/resources";

interface UseGetResourcesProps {
  params: ResourceSearchCriteria;
}

export const useGetResources = ({ params }: UseGetResourcesProps) =>
  useQuery<PagedResponse<ResourceDto>>({
    queryKey: [QUERY_KEYS.resources, params],
    queryFn: async () => {
      return await ClientApi.get(API_ENDPOINTS.getResources(), {
        params,
      });
    },
    keepPreviousData: true,
  });
