import { Button, ButtonLabel, Heading, Searchbar } from "_sredx/_ui";
import { TeamDataTable } from "_sredx/components";
import { API_PAGE_SIZE_DEFAULT } from "_sredx/constants/api";
import { useGetTeams } from "_sredx/services";
import { TeamSearchCriteria } from "_sredx/types";
import { parsePageNumber } from "_sredx/utils";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import styles from "./TeamListingContainer.module.css";

const TEAM_SEARCH_CRITERIA_DEFAULT: TeamSearchCriteria = {
  pageNumber: 1,
  pageSize: API_PAGE_SIZE_DEFAULT,
};

export const TeamListingContainer = () => {
  // State
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");

  // Hooks
  const navigate = useNavigate();

  // Derived variables
  const currentPage = parsePageNumber(searchParams.get("page"));

  // Services
  const {
    data: teams,
    isLoading,
    isFetching,
  } = useGetTeams({
    params: {
      ...TEAM_SEARCH_CRITERIA_DEFAULT,
      name: searchQuery,
      pageNumber: currentPage,
    },
  });

  // Event handlers
  const handleOnAddTeamClick = () => {
    navigate("/teams/create");
  };

  const handleClickTeamRow = (teamId: string) => {
    navigate(`/teams/${teamId}`);
  };

  const handleOnSearch = (value: string) => {
    setSearchQuery(value);
  };

  const handleOnPageChange = (page: number) => {
    setSearchParams({
      page: (page + 1).toString(),
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header_wrapper}>
        <div className={styles.title_wrapper}>
          <Heading>Teams</Heading>
          <p>Discover, create and manage your teams with ease</p>
        </div>
        <Button onClick={handleOnAddTeamClick}>
          <ButtonLabel>Add new team</ButtonLabel>
        </Button>
      </div>

      <div className={styles.search_filter_wrapper}>
        <div className={styles.searchbar_wrapper}>
          <Searchbar
            value={searchQuery}
            onChange={handleOnSearch}
            placeholder="Search teams by name"
          />
        </div>
      </div>

      <div className={styles.table_wrapper}>
        <TeamDataTable
          teams={teams && teams.content}
          isLoading={isLoading}
          isFetching={isFetching}
          onTeamRowClick={handleClickTeamRow}
          onAddTeamClick={handleOnAddTeamClick}
          totalPages={teams && teams.totalPages}
          onPageChange={handleOnPageChange}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};
