import { forwardRef } from "react";
import { IconProps } from "./types";

export const VpcIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <defs>
          <style>
            {".cls-1{fill:#aecbfa;}.cls-2{fill:#669df6;}.cls-3{fill:#4285f4;}"}
          </style>
        </defs>
        <title>{"Icon_24px_VirtualPrivateCloud_Color"}</title>
        <g data-name="Product Icons">
          <rect className="cls-1" x={16} y={2} width={6} height={6} />
          <rect className="cls-2" x={19} y={2} width={3} height={6} />
          <rect className="cls-1" x={16} y={16} width={6} height={6} />
          <rect className="cls-2" x={19} y={16} width={3} height={6} />
          <rect className="cls-1" x={2} y={2} width={6} height={6} />
          <rect className="cls-2" x={5} y={2} width={3} height={6} />
          <rect className="cls-1" x={2} y={16} width={6} height={6} />
          <rect className="cls-2" x={5} y={16} width={3} height={6} />
          <rect className="cls-2" x={8} y={4} width={8} height={2} />
          <rect className="cls-2" x={8} y={18} width={8} height={2} />
          <rect className="cls-2" x={18} y={8} width={2} height={8} />
          <rect className="cls-2" x={4} y={8} width={2} height={8} />
          <rect className="cls-3" x={4} y={8} width={2} height={2} />
          <rect className="cls-3" x={18} y={8} width={2} height={2} />
          <rect className="cls-3" x={8} y={4} width={2} height={2} />
          <rect className="cls-3" x={8} y={18} width={2} height={2} />
        </g>
      </svg>
    );
  }
);
