import styles from "./AppReturnButton.module.css";
import { Link } from "react-router-dom";
import { Text } from "_sredx/_ui";
import { ChevronLeftIcon } from "_sredx/_ui/Icon";

interface AppReturnButtonProps {
  to: string;
  label: string;
}

export const AppReturnButton = ({ to, label }: AppReturnButtonProps) => {
  return (
    <Link to={to} className={styles.wrapper}>
      <ChevronLeftIcon height={20} />
      <Text size="md">{label}</Text>
    </Link>
  );
};
