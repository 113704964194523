import { FormField } from "components/basic/FormField";
import { TagsInput } from "components/octo-ui/Forms";
import { TextInput } from "components/octo-ui/Forms/TextInput";
import ScoreCard from "models/scorecard/ScoreCard";
import React from "react";
import { FieldErrors } from "react-hook-form";

interface MainInfoFormProps {
  errors: FieldErrors<ScoreCard>;
}

export const MainInfoForm = ({ errors }: MainInfoFormProps) => {
  return (
    <form
      data-testid="scorecard-edit-form"
      id="scorecard-edit-form"
      className="grid sm:grid-cols-5"
    >
      <div className="sm:col-span-5 bg-white px-5 py-5 overflow-hidden  font-sans">
        <div id="mainInfosHeader" className={"flex justify-between"}>
          <h3 className="text-2xl leading-6 font-mono font-medium text-gray-900">
            Main Infos
          </h3>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <div className="mt-1">
              <label
                htmlFor={`name`}
                className="block text-sm font-medium text-gray-700"
              >
                ScoreCard Name
              </label>
              <FormField
                type={"text"}
                id="scoreCardName"
                name={"name"}
                component={TextInput}
                placeholder={"Name"}
                rules={{
                  required: {
                    value: true,
                    message: "You must specify a name.",
                  },
                }}
                error={errors.name?.message}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <div className="mt-1">
              <label
                htmlFor={`tag`}
                className="block text-sm font-medium text-gray-700"
              >
                ScoreCard Tag
              </label>
              <FormField
                rules={{
                  required: {
                    value: true,
                    message: "You must specify a Tag.",
                  },
                }}
                id="scoreCardTag"
                type={"text"}
                name={"tag"}
                data-testid="scoreCardTag"
                component={TextInput}
                placeholder="Tag"
                error={errors.tag?.message}
              />
            </div>
          </div>
          <div className="sm:col-span-6">
            <div className="mt-1">
              <label
                htmlFor={`targetTags`}
                className="block text-sm font-medium text-gray-700"
              >
                Target Tags
              </label>
              <FormField
                id={"scorCardTargetTags"}
                name={"targetTags"}
                component={TagsInput}
                colored={true}
                label={""}
              />
            </div>
          </div>
          <div className="sm:col-span-6">
            <div className="mt-1">
              <label
                htmlFor={`description`}
                className="block text-sm font-medium text-gray-700"
              >
                About
              </label>
              <FormField
                id="scoreCardDescription"
                name={"description"}
                component={TextInput}
                type={"textarea"}
                rows={3}
                placeholder={"Write a few sentences about the scorecard."}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
