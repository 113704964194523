import styles from "./MembersSelectAutoComplete.module.css";
import { Avatar, Text } from "_sredx/_ui";
import { Member } from "_sredx/types";
import {
  UseMultipleSelectionActions,
  UseMultipleSelectionPropGetters,
} from "downshift";

interface SelectedMembersProps {
  selectedMembers?: Member[];
  getSelectedItemProps: UseMultipleSelectionPropGetters<Member>["getSelectedItemProps"];
  removeSelectedItem: UseMultipleSelectionActions<Member>["removeSelectedItem"];
}

export const SelectedMembers = ({
  selectedMembers = [],
  getSelectedItemProps,
  removeSelectedItem,
}: SelectedMembersProps) => {
  return (
    <>
      {selectedMembers.map((member, index) => {
        const name = `${member.firstName} ${member.lastName}`;
        return (
          <div
            className={styles.item_wrapper}
            key={`selected-item-${index}`}
            {...getSelectedItemProps({
              selectedItem: member,
              index,
            })}
          >
            {name.trim() && <Avatar name={name} size={18} />}
            <Text>{member.email}</Text>
            <div
              className={styles.x_icon}
              onClick={(e) => {
                e.stopPropagation();
                removeSelectedItem(member);
              }}
            >
              <Text size={"sm"}>&#10005;</Text>
            </div>
          </div>
        );
      })}
    </>
  );
};
