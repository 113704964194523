import { TextInput } from 'components/octo-ui/Forms/TextInput';
import { CloudProvider, CreateCloudAccountDTO } from 'types/selfService';
import * as yup from 'yup';


export interface CloudAccountFormData {
    name: string;
    provider: string;
    accessKey?: string;
    projectId?: string;
    secretKey?: string;
}

export const schema = yup.object<CloudAccountFormData>().shape({
    name: yup.string().required('Name is required.'),
    provider: yup.string().required('Provider is required.'),
    projectId: yup.string().when('provider', (provider, schema) => {
        return provider[0] === 'scaleway'
            ? schema.required('ProjectId is required for Scaleway.')
            : schema;
    }),
    // accessKey: yup.string().when('provider', (provider, schema) => {
    //     return provider[0] === 'scaleway'
    //         ? schema.required('AccessKey is required for Scaleway.')
    //         : schema;
    // }),
    // secretKey: yup.string().when('provider', (provider, schema) => {
    //     return provider[0] === 'scaleway'
    //         ? schema.required('SecretKey is required for Scaleway.')
    //         : schema;
    // }),
    accessKey: yup.string().required('Access key is required.'),
    secretKey: yup.string().required('secret Key is required.')
});


export function mapFormDataToApiData(formData: CloudAccountFormData): CreateCloudAccountDTO {
    // in case api will change later
    return {
        name: formData.name,
        provider: formData.provider,
        accessKey: formData.accessKey,
        projectId: formData.projectId,
        secretKey: formData.secretKey,
    };
}

export const providerFieldsConfig: Record<CloudProvider, any> = {
    scaleway: [
        {
            id: 'projectId',
            name: 'projectId',
            label: 'Project Id',
            placeholder: 'Enter Scaleway project id',
            component: TextInput,
        },
        {
            id: 'accessKey',
            name: 'accessKey',
            label: 'Access Key',
            placeholder: 'SCWXXXXXXXXXXXXXXXXX',
            component: TextInput,
            type: 'password'
        },
        {
            id: 'secretKey',
            name: 'secretKey',
            label: 'Secret Key',
            placeholder: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
            component: TextInput,
            type: 'password'
        },   
    ],
    aws: [],
    gcp: [],
    azure: [],
}