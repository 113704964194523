import clsx from "clsx";
import styles from "./ScoreCardMainInfo.module.css";
import React from "react";
import { ScoreCardDto } from "_sredx/types";
import { Text } from "_sredx/_ui";

interface ScoreCardMainInfoProps {
  scoreCard: ScoreCardDto;
}

export const ScoreCardMainInfo = ({ scoreCard }: ScoreCardMainInfoProps) => {
  const { name, description } = scoreCard;

  return (
    <div className={clsx(styles.wrapper)}>
      <Text size={"md"} className={styles.name}>
        {name}
      </Text>
      <Text size={"sm"} className={styles.description}>
        {description}
      </Text>
    </div>
  );
};
