import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  ADMIN_SELF_SERVICE_CLUSTERS_PAGE,
  ADMIN_SELF_SERVICE_CONFIG_PAGE,
  ADMIN_SELF_SERVICE_PAGE,
} from "routes/paths";
import { ADMIN_ROUTES } from "routes/routes";
import styles from "./AdminSelfServicePageLayout.module.css";

export const AdminSelfServiceClustersPageLayout = () => {
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = useState("overview");
  const navOptions = ADMIN_ROUTES.find(
    (route) => route.path === ADMIN_SELF_SERVICE_PAGE
  )?.children;

  useEffect(() => {
    const normalizedPath = pathname.endsWith("/")
      ? pathname.slice(0, -1)
      : pathname;

    if (normalizedPath.includes(ADMIN_SELF_SERVICE_CLUSTERS_PAGE)) {
      setCurrentTab("clusters");
    } else if (normalizedPath === ADMIN_SELF_SERVICE_CONFIG_PAGE) {
      setCurrentTab("configuration");
    } else if (normalizedPath === ADMIN_SELF_SERVICE_PAGE) {
      setCurrentTab("clusters");
    } else {
      setCurrentTab(normalizedPath.split("/").slice(-1)[0]);
    }
  }, [pathname]);

  return (
    <div>
      <div>
        <div className={`bg-black`}>
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-10">
            <div className="flex flex-col gap-2 md:gap-0 md:flex-row md:justify-between md:items-end text-white">
              <div className="space-y-2">
                <div className="text-3xl font-thin">Self Service</div>
                <p className="font-light">
                  Elevate your plateform experience. Instantly create clusters
                  and optimize your workflow with efficient day-2 operations
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className={`bg-black w-full sticky top-[54px] z-10`}>
        <div className="container flex gap-2">
          {navOptions?.map(
            (navOption, i) =>
              navOption.showInNav && (
                <Link
                  key={i}
                  className={clsx(
                    styles.navLink,
                    currentTab === navOption.name.toLowerCase()
                      ? styles.active
                      : ""
                  )}
                  to={
                    navOption.index ? ADMIN_SELF_SERVICE_PAGE : navOption.path
                  }
                >
                  {navOption.name}
                </Link>
              )
          )}
        </div>
      </nav>
      <div className="container py-8">
        <Outlet />
      </div>
    </div>
  );
};
