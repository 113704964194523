import styles from "./ScoreCardComponent.module.css";
import { Card, CardBody } from "_sredx/_ui";
import { ScoreCardDto } from "_sredx/types";
import { ScoreCardMainInfo } from "../ScoreCardMainInfo";
import { ScoreCardCategories } from "../ScoreCardCategories";
import { ScoreCardLevels } from "../ScoreCardLevels";
import { ScoreCardOverview } from "../ScoreCardOverview";

interface ScoreCardComponentProps {
  scoreCard: ScoreCardDto;
  isFetching?: boolean;
}

export const ScoreCardComponent = ({
  scoreCard,
  isFetching,
}: ScoreCardComponentProps) => {
  return (
    <Card clickable>
      <CardBody>
        <div className={styles.body_wrapper}>
          <ScoreCardMainInfo scoreCard={scoreCard} />
          <ScoreCardCategories scoreCard={scoreCard} />
          <ScoreCardLevels scoreCard={scoreCard} />
          <ScoreCardOverview
            scoreCard={scoreCard}
            isFetching={isFetching}
          />
        </div>
      </CardBody>
    </Card>
  );
};
