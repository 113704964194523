import OwnerServicesScores from "../models/dashborad/OwnerServicesScores";
import { calculateDeclineForRateForService } from "./calculateDeclineRateForService";
import { calculateGrowthFOrRateFromServiceScore } from "./calculateGrowthFOrRateFromServiceScore";

interface OwnerRank {
  owner: string;
  totalServices: number;
  changePercentage: number;
}

export const generateDropedOwnersRanks = (
  ownersServicesScores: OwnerServicesScores[]
): OwnerRank[] => {
  const ownerRank: OwnerRank[] = [];

  ownersServicesScores.forEach((ownerServicesScores) => {
    let changePercentage = 0;
    ownerServicesScores.serviceScores.forEach((serviceScore) => {
      const scores = serviceScore.scores;
      changePercentage += calculateDeclineForRateForService(
        scores[0].score,
        scores[1].score
      );
    });
    const totalServices = ownerServicesScores.serviceScores.length;
    changePercentage = changePercentage / totalServices;
    const owner = ownerServicesScores.owner.name;
    ownerRank.push({ owner, totalServices, changePercentage });
  });
  return ownerRank;
};

export const generateImprovedOwnersRanks = (
  ownersServicesScores: OwnerServicesScores[]
): OwnerRank[] => {
  const ownerRank: OwnerRank[] = [];

  ownersServicesScores.forEach((ownerServicesScores) => {
    let changePercentage = 0;
    ownerServicesScores.serviceScores.forEach((serviceScore) => {
      const scores = serviceScore.scores;
      changePercentage += calculateGrowthFOrRateFromServiceScore(
        scores[0].score,
        scores[1].score
      );
    });
    const totalServices = ownerServicesScores.serviceScores.length;
    changePercentage = changePercentage / totalServices;
    const owner = ownerServicesScores.owner.name;
    ownerRank.push({ owner, totalServices, changePercentage });
  });
  return ownerRank;
};
