import { forwardRef } from "react";
import { IconProps } from "./types";

export const SubnetIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        viewBox="0 0 32 32"
        id="icon"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <defs>
          <style>
            {"\n      .cls-1 {\n        fill: none;\n      }\n    "}
          </style>
        </defs>
        <rect x={18} y={14} width={12} height={2} />
        <rect x={18} y={19} width={8} height={2} />
        <rect x={18} y={9} width={12} height={2} />
        <path
          d="M22,24v4H6V16h8V14H10V8a4.0008,4.0008,0,0,1,7.6675-1.6L19.5005,5.6A6.001,6.001,0,0,0,8,8v6H6a2.0023,2.0023,0,0,0-2,2V28a2.0023,2.0023,0,0,0,2,2H22a2.0023,2.0023,0,0,0,2-2V24Z"
          transform="translate(0 0)"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          className="cls-1"
          width={32}
          height={32}
        />
      </svg>
    );
  }
);
