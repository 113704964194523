import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "_sredx/_ui";
import { ServicesList } from "../ServicesList";
import { TeamCreationService, TeamCreationServicesAction } from "./types";
import styles from "./TeamCreationServices.module.css";

interface TeamCreationServicesProps {
  services: TeamCreationService[];
  onAction: (action: TeamCreationServicesAction) => void;
}

export const TeamCreationServices = ({
  services,
  onAction,
}: TeamCreationServicesProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Team Services</CardTitle>
        <CardSubtitle>Associate services to this team</CardSubtitle>
      </CardHeader>
      <CardBody noPadding>
        <div className={styles.body}>
          <ServicesList
            services={services}
            onAction={onAction}
            emptyMessage={"This team has no service"}
            withAddAction
          />
        </div>
      </CardBody>
    </Card>
  );
};
