export const UserActions = {
  IMPORT_TEAMS_AND_USERS: "IMPORT_TEAMS_AND_USERS",
  IMPORT_TEAMS_ONLY: "IMPORT_TEAMS_ONLY",
  SKIP_IMPORT: "SKIP_IMPORT",
};

export type UserActionsType = (typeof UserActions)[keyof typeof UserActions];

export const UserActionsData : UserAction[] = [
  {
    id: 1,
    title: "Import Teams with Members As Users",
    description:
      "Import Chosen services' teams, and send invitations to their members",
    key: UserActions.IMPORT_TEAMS_AND_USERS,
  },
  {
    id: 2,
    title: "Import Teams Only",
    description: "Import Teams whithout their members",
    key: UserActions.IMPORT_TEAMS_ONLY,
  },
  {
    id: 4,
    title: "Skip importing",
    description: "I only want to import services",
    key: UserActions.SKIP_IMPORT,
  },
];

export interface UserAction{
    id: number;
    title: string;
    description: string,
    key: string;
}
