import React from 'react';
import ImgBox from '../img-box/ImgBox';
import style from "./TeamsStyles.module.css";
import "styles/fonts.css";
import clsx from 'clsx';

interface TeamsProps {
    owners : 
    {id : string,
    name : string,
    avatar : string} []
}


export const Teams = ({owners} : TeamsProps) => {
  return (
    <div className={style.service_body }>
            <span className={clsx("text_sm", "text_start")}>Owners</span>
            <div className={style.service_teams_avatars}>
                {owners.map(team => <ImgBox key={team.id} img={team.avatar}/> )}

            </div>

          </div>
  )
}
