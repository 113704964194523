export const resolveInvitation = (
  invitation: string,
  invitationExp?: string
) => {
  switch (invitation) {
    case "ACCEPTED":
      return "Accepted";
    case "PENDING":
      return "Pending";
    case "EXPIRED":
      return invitationExp
        ? `Expired on ${new Date(Number(invitationExp) * 1000).toUTCString()}`
        : "Expired";
    default:
      return "Unknown";
  }
};
