import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "_sredx/_ui";
import { MembersList } from "../MembersList";
import { TeamCreationMembersAction } from "./types";
import styles from "./TeamMembers.module.css";
import { TeamMember } from "_sredx/types";

interface TeamCreationMembersProps {
  members?: TeamMember[];
  onAction: (action: TeamCreationMembersAction) => void;
}

export const TeamCreationMembers = ({
  members = [],
  onAction,
}: TeamCreationMembersProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Team Members</CardTitle>
        <CardSubtitle>ADD or INVITE Members to this team</CardSubtitle>
      </CardHeader>
      <CardBody noPadding>
        <div className={styles.body}>
          <MembersList
            members={members.map((member) => ({
              email: member.email || "",
              id: member.userId || "",
              firstName: member.firstName || "",
              lastName: member.lastName || "",
              role: member.role,
              status: member.status,
            }))}
            onAction={onAction}
            emptyMessage={"There are no members on this team"}
            withAddAction
          />
        </div>
      </CardBody>
    </Card>
  );
};
