/*
 * Format ISO date string to a human-readable date
 * @param {string} isoDateString - ISO date string
 * @returns {string} - formatted date
 * @example
 * formatISODate('2023-12-15T00:00:00.000Z') // returns 'December 15, 2023'
 */
export const formatISODate = (isoDateString: string | Date) => {
  const date = new Date(isoDateString);

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const formatSeconds = (seconds: number) => {
  if (seconds < 60) {
    return `${Math.floor(seconds)}Seconds`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  if (minutes < 60) {
    return `${minutes}Minutes ${remainingSeconds}Seconds`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);
  if (hours < 24) {
    return `${hours}Hours ${remainingMinutes}Minutes`;
  }
  const days = Math.floor(hours / 24);
  const remainingHours = Math.floor(hours % 24);
  return `${days}Days ${remainingHours}Hours`;
};

export const getTimeDifference = (startDate: Date, endDate: Date) => {
  return endDate.getTime() - startDate.getTime();
};
