import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useScoreCardLastEvaluations } from "hooks/evaluations/useScoreCardLastEvaluations";
import { useSingleScoreCard } from "hooks/scorcards/useSingleScoreCard";
import { getScoreCardOverviewFromEvaluationsHistory } from "services/evaluations/ScoreCardEvaluations.helper";

import {
  Button,
  ButtonIcon,
  ButtonLabel,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Heading,
  Loader,
  Text,
} from "_sredx/_ui";
import { ScoreCardDetails, ScoreCardDetailsOverview } from "_sredx/components";

import { BeakerIcon, PencilIcon } from "@heroicons/react/outline";
import { useModal } from "_sredx/hooks";
import ProgressTooltipComponent from "components/basic/ProgressTooltipComponent/ProgressTooltipComponent";
import { useEvaluateScoreCard } from "hooks/scorcards/useEvaluateScoreCard";
import useSse from "hooks/useSse";
import { ProgressInfo } from "types/ProgressInfo";
import { v4 as uuidv4 } from "uuid";
import styles from "./ScoreCardDetailsContainer.module.css";

export const ScoreCardDetailsContainer = () => {
  // State
  const { scorecardId } = useParams<{ scorecardId: string }>();
  const [showProgressTooltip, setShowProgressTooltip] = useState(false);

  // Hooks
  const connexionId = useMemo(() => uuidv4(), []);
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const {
    sseData: progressInfo,
    connect: connectProgressInfo,
    disconnect: disconnectProgressInfo,
  } = useSse<ProgressInfo>(`/evaluation-progress/${connexionId}`);

  // Services
  const { data: scorecard, isLoading: isLoadingScoreCard } = useSingleScoreCard(
    scorecardId ?? ""
  );

  const {
    data: scorecardLastEvaluation,
    isLoading: isLoadingScorecardLastEvaluation,
  } = useScoreCardLastEvaluations(scorecardId ?? "");

  const { mutateAsync: evaluateScoreCard, isLoading: isEvaluating } =
    useEvaluateScoreCard(scorecardId ?? "", connexionId);

  // Derived variables
  const lastEvaluationOverview = useMemo(() => {
    return getScoreCardOverviewFromEvaluationsHistory(
      scorecardLastEvaluation ?? []
    );
  }, [scorecardLastEvaluation]);

  // Events handlers
  const handleEvaluateScoreCard = async () => {
    setShowProgressTooltip(true);
    connectProgressInfo();
    await evaluateScoreCard();
    disconnectProgressInfo();
    setShowProgressTooltip(false);
  };

  const handleClickEvaluate = () => {
    openModal({
      content: (
        <Card>
          <CardHeader>
            <CardTitle>Are you sure?</CardTitle>
          </CardHeader>
          <CardBody>
            <div style={{ minWidth: "300px", marginBottom: "24px" }}>
              This action might take time.
            </div>
          </CardBody>
          <CardFooter>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "16px",
              }}
            >
              <Button
                type="button"
                data-testid={"close-error-alert-button"}
                onClick={closeModal}
                variant={"neutral"}
                ghost
              >
                Cancel
              </Button>
              <Button
                type="button"
                data-testid={"confirm-error-alert-button"}
                onClick={() => {
                  handleEvaluateScoreCard();
                  closeModal();
                }}
              >
                Confirm
              </Button>
            </div>
          </CardFooter>
        </Card>
      ),
    });
  };
  const handleClickEdit = () => {
    navigate("edit");
  };

  if (
    isLoadingScoreCard
    // || isLoadingScorecardLastEvaluation)
  )
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading...</Text>
        </div>
      </Card>
    );

  if (!scorecard) return null;

  return (
    <>
      <div className={styles.header}>
        <Heading>{scorecard.name}</Heading>
        <div className={styles.actions_buttons}>
          <ProgressTooltipComponent
            progressInfo={progressInfo}
            showProgressTooltip={showProgressTooltip}
          >
            <Button onClick={handleClickEvaluate} isLoading={isEvaluating}>
              <ButtonIcon>
                <BeakerIcon />
              </ButtonIcon>
              <ButtonLabel>Evaluate</ButtonLabel>
            </Button>
          </ProgressTooltipComponent>
          <Button onClick={handleClickEdit}>
            <ButtonIcon>
              <PencilIcon />
            </ButtonIcon>
            <ButtonLabel>Edit</ButtonLabel>
          </Button>
        </div>
      </div>

      <div className={styles.wrapper}>
        <ScoreCardDetails
          scoreCard={scorecard}
          lastEvaluation={lastEvaluationOverview.lastEvaluated}
        />
        <ScoreCardDetailsOverview
          scoreCardOverview={lastEvaluationOverview}
          scoreCard={scorecard}
        />
      </div>
    </>
  );
};
