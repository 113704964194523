import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

interface ListingCardProps {
  link?: string;
}
export const ListingCard = ({
  link,
  children,
}: PropsWithChildren<ListingCardProps>) => {
  return (
    <Link
      className={` ${styles.wrapper} ${!!link ? styles.clickable : ""}`}
      to={!!link ? link : "#"}
    >
      {children}
    </Link>
  );
};
