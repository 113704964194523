import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import Service from "../../../models/service/Service";
import { ElementType, useState } from "react";
import { Tag } from "_sredx/_ui/Tag";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { ADMIN_SERVICES_PAGE } from "routes/paths";
import AverageScoreComponent from "componentsv2/specific/ServiceAverageScore/ServiceAverageScore";
import RulesComponent from "componentsv2/specific/Rules/Rules";
import { ListingCard } from "components/octo-ui/ListingCard/ListingCard";
import { resolveIntegration } from "_sredx/utils";

interface ServiceCardProps {
  service: Service;
  onEdit?: (service: Service) => void;
  onDelete?: (service: Service) => void;
  clickable?: boolean;
  renderFooter?: boolean;
  link?: string;
}

export const ServiceCard = ({
  service,
  onEdit,
  onDelete,
  clickable = false,
  renderFooter = false,
  link,
}: ServiceCardProps) => {
  return (
    <ListingCard
      link={
        link ?? `${ADMIN_SERVICES_PAGE}/${service.id}` //TODO remove when v1 disabled
      }
    >
      {/* Head section */}
      <div>
        <div className={styles.head}>
          <h2 className={styles.title}>{service.name}</h2>
          <div className="flex gap-2 flex-shrink-0">
            {onEdit && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onEdit(service);
                }}
                className="shadow-sm rounded-full hover:shadow transition-all"
              >
                <PencilIcon className="h-4 text-slate-500" />
              </button>
            )}
            {onDelete && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onDelete(service);
                }}
              >
                <TrashIcon className="h-4 text-slate-500" />
              </button>
            )}
          </div>
        </div>
        <div className="flex gap-2 flex-wrap">
          {service.metadata?.tags?.map((tag, i) => (
            <Tag key={i} tag={tag} />
          ))}
          {service.domains?.map((domain, i) => (
            <Tag key={i} tag={domain.name} />
          ))}
        </div>
      </div>
      {/* Body Section */}
      <div className="flex flex-col gap-2">
        <div className={styles.keyValue}>
          <h3 className={styles.subtitle}>Owners</h3>
          <div className="flex items-center gap-2">
            {service.owners?.length >= 1 ? (
              service.owners
                .slice(0, 4)
                .map((owner, i) => (
                  <Thumbnail
                    key={i}
                    name={owner.name}
                    url={`https://api.dicebear.com/7.x/identicon/svg?seed=${owner.name}`}
                    popover
                  />
                ))
            ) : (
              <span className="text-slate-500 text-xs">No owners</span>
            )}

            {service.owners?.length > 4 && (
              <button className={styles.showMore} data-testid="owner">
                <span className="text-slate-500">
                  +{service.owners.length - 4}
                </span>
              </button>
            )}
          </div>
        </div>
        <div className={styles.keyValue}>
          <h3 className={styles.subtitle}>Tools</h3>
          <div className="flex gap-2" data-testid="tools">
            {/* annotations */}
            <div className={"flex gap-2"}>
              {service.tools && service.tools.length > 0 ? (
                service.tools?.slice(0, 4).map((t, i) => (
                  <div
                    key={i}
                    className={
                      "h-8 w-8 border p-1 flex items-center justify-center"
                    }
                    style={{
                      border: "1px solid var(--gray-500)",
                    }}
                  >
                    {resolveIntegration(t.key).icon}
                  </div>
                ))
              ) : (
                <span className="text-slate-500 text-xs">No tools</span>
              )}

              {/* {service.tools?.slice(0, 2).map((t) => (
                <div className={styles.integration_icon}>
                  {resolveIntegration(t.key).icon}
                </div>
              ))}
              {service.tools?.length > 2 && (
                <span className={styles.more_owners}>
                  +{service.tools?.length - 2}
                </span>
              )} */}
            </div>
          </div>
        </div>
      </div>
      {/* Footer Section */}
      {renderFooter && (
        <div className="flex flex-col gap-1 ">
          <AverageScoreComponent
            totalPossibleScore={service.totalPossible}
            totalScore={service.score}
          />
          <RulesComponent
            allRulesCount={service.allRulesCount}
            successfulRulesCount={service.successfulRulesCount}
            failedRulesCount={service.failedRulesCount}
          />
        </div>
      )}
    </ListingCard>
  );
};

export const Thumbnail = ({
  link,
  name,
  url,
  popover = false,
  className,
  size = "medium",
}: {
  link?: string;
  name: string;
  url: string;
  className?: string;
  popover?: boolean;
  size?: "small" | "medium" | "large";
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const sizeValue =
    size === "small" ? "24px" : size === "medium" ? "32px" : "48px";
  return (
    <Link
      to={link ?? "#"}
      style={{ position: "relative" }}
      onMouseEnter={() => setPopoverVisible(popover && true)}
      onMouseLeave={() => setPopoverVisible(popover && false)}
    >
      {popoverVisible && (
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            fontSize: "12px",
            position: "absolute",
            whiteSpace: "nowrap",
            padding: "4px",
            top: "-100%",
          }}
        >
          {name}
        </div>
      )}
      <img
        src={url}
        alt={name}
        style={{
          height: sizeValue,
          width: sizeValue,
          padding: "4px",
          border: "1px solid var(--gray-500)",
        }}
      />
    </Link>
  );
};

export const IconThumbnail = ({
  link,
  name,
  icon,
  popover = false,
  className,
  size = "medium",
}: {
  link?: string;
  name: string;
  icon: ElementType | null;
  className?: string;
  popover?: boolean;
  size?: "small" | "medium" | "large";
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const sizeValue =
    size === "small" ? "24px" : size === "medium" ? "32px" : "48px";
  const Component = icon;
  return (
    <Link
      to={link ?? "#"}
      style={{ position: "relative" }}
      onMouseEnter={() => setPopoverVisible(popover && true)}
      onMouseLeave={() => setPopoverVisible(popover && false)}
    >
      {popoverVisible && (
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            fontSize: "12px",
            position: "absolute",
            whiteSpace: "nowrap",
            padding: "4px",
            top: "-100%",
          }}
        >
          {name}
        </div>
      )}
      {Component && (
        <Component
          style={{
            height: sizeValue,
            width: sizeValue,
            padding: "4px",
            border: "1px solid var(--gray-500)",
          }}
        />
      )}
    </Link>
  );
};
