import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { UserProfileDto } from "_sredx/types";
import { useQuery } from "react-query";
import { ClientApi } from "../clientApi";

interface UseMembersDiscoveryProps {
  teamsIds: string[];
}

export const useMembersDiscovery = ({ teamsIds }: UseMembersDiscoveryProps) => {
  const params = { teamsIds: teamsIds };
  return useQuery<UserProfileDto[]>({
    queryKey: [QUERY_KEYS.usersDiscovery, teamsIds],
    queryFn: () => ClientApi.get(API_ENDPOINTS.importMembers(), { params }),
    enabled: teamsIds && !!teamsIds?.length,
  });
};
