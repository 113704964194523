import React from "react";
import styles from "./SelectInput.module.css";
import Select, { CSSObjectWithLabel } from "react-select";

interface SelectInputProps {
  id: string;
  label?: string;
  name: string;
  options: {
    label: React.ReactNode;
    value: any;
  }[];
  onChange: (value: any) => void;
  disabled?: boolean;
  loading?: boolean;
  error?: string;
  placeholder?: string;
}
export const SelectInput = ({
  options,
  disabled,
  loading,
  onChange,
  name,
  label,
  placeholder,
  error,
  id,
}: SelectInputProps) => {
  return (
    <div className="w-full">
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      <Select
        styles={{
          control: (baseStyle: any) => {
            return {
              ...baseStyle,
              borderColor: "var(--gray-700)",
              boxShadow: "none",
              borderRadius: 0,
              "&:focus-within": {
                borderColor: "var(--cyan-600)",
                boxShadow: "0 0 4px var(--cyan-600)",
              },
            } as CSSObjectWithLabel;
          },
          placeholder: (base: any) => {
            return {
              ...base,
              fontSize: "14px",
              color: "var(--gray-400)",
            } as CSSObjectWithLabel;
          },
          input: (base: any) => {
            return {
              ...base,
              fontSize: "14px",
            } as CSSObjectWithLabel;
          },
          menu: (base: any) => {
            return {
              ...base,
              marginTop: 0,
              borderRadius: 0,
              border: "1px solid var(--gray-700)",
            } as CSSObjectWithLabel;
          },
          option: (base: any, props) => {
            return {
              ...base,
              fontSize: "14px",

              backgroundColor: props.isSelected
                ? "var(--cyan-50)"
                : props.isFocused
                ? "var(--gray-100)"
                : "transparent",
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color: "black",
            } as CSSObjectWithLabel;
          },
          singleValue: (base: any) => {
            return {
              ...base,
              fontSize: "14px",
              display: "flex",
              gap: "8px",
              alignItems: "center",
            } as CSSObjectWithLabel;
          },
        }}
        id={id}
        className={styles.selectInput}
        placeholder={placeholder}
        isDisabled={disabled}
        isLoading={loading}
        isSearchable
        name={name}
        options={options}
        onChange={(value) => onChange(value?.value)}
      />
      {error && <p className="text-xs text-red-800">{error}</p>}
    </div>
  );
};
