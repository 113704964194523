import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";
import { ApiErrorResponse } from "_sredx/types";

export interface ServiceAffectationDto {
  initiativeId: string;
  serviceIds: string[];
}

export const useAddInitiativeServices = () => {
  const queryClient = useQueryClient();

  return useMutation<string, ApiErrorResponse, ServiceAffectationDto>({
    mutationKey: [MUTATION_KEYS.addInitiativeServices],
    mutationFn: (dto: ServiceAffectationDto) => {
      return ClientApi.patch(
        API_ENDPOINTS.addInitiativeServices(dto.initiativeId),
        dto.serviceIds
      );
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.initiatives], {
        exact: false,
      });
    },
  });
};
