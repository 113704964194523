import { useAxios } from "hooks/useAxios";
import { useQuery } from "react-query";
import { CloudAccountDto } from "types/selfService";
import { SELF_SERVICE_PATHS, SELF_SERVICE_QUERY_KEYS } from "./paths";

export const useCloudAccounts = () => {
  const { axios } = useAxios();

  const fetchCloudAccounts = async () => {
    const response = await axios(SELF_SERVICE_PATHS.CLOUD_ACCOUNTS);
    return response.data;
  };

  return useQuery<CloudAccountDto[]>(
    [SELF_SERVICE_QUERY_KEYS.CLOUD_ACCOUNTS],
    fetchCloudAccounts,
    {
      // initialData: cloudAccountsMock,
    }
  );
};
