import * as Accordion from "@radix-ui/react-accordion";
import { Button, ButtonLabel, CircledPlusIcon, Loader, Text } from "_sredx/_ui";
import {
  useCreateInstance,
  useDeleteIntegrationInstance,
  useGetIntegrationInstances,
  useUpdateIntegrationInstance,
} from "_sredx/services";
import {
  ConfigParamValues,
  InstanceCreationDto,
  IntegrationInstanceDto,
  IntegrationInstanceUpdateDto,
} from "_sredx/types";
import { AxiosError } from "axios";
import { ConfigParamsContainer } from "components/IntegrationsConfig/ConfigParamsComponents/ConfigParamsContainer/ConfigParamsContainer";
import { ConfigParamsWrapper } from "components/IntegrationsConfig/ConfigParamsComponents/ConfigParamsWrapper/ConfigParamsWrapper";
import { useToaster } from "hooks/useToaster";
import IntegrationTemplate from "models/integration/IntegrationTemplate";
import { useEffect, useState } from "react";
import styles from "./IntegrationConfigContainer.module.css";
import { cy } from "date-fns/locale";

interface IntegrationConfigContainerProps {
  currentTemplate: IntegrationTemplate;
}

export const IntegrationConfigContainer = ({
  currentTemplate,
}: IntegrationConfigContainerProps) => {
  //state
  const [createdInstances, setCreatedInstances] = useState<
    Map<string, InstanceCreationDto | null>
  >(new Map());
  const [updatedInstances, setUpdatedInstances] = useState<
    Map<string, IntegrationInstanceUpdateDto>
  >(new Map());
  const [deletedInstances, setDeletedInstances] = useState<string[]>([]);
  const [resetTrigger, setResetTrigger] = useState(false);

  //hooks
  const { addToast } = useToaster();
  //services
  const {
    data: integrationInstances,
    isSuccess,
    isLoading,
  } = useGetIntegrationInstances({
    params: {
      integrationKeys: [currentTemplate.key],
    },
  });
  const [shownInstances, setShownInstances] = useState<
    IntegrationInstanceDto[]
  >([]);
  const { mutateAsync: saveInstance, isLoading: isInstanceCreationLoading } =
    useCreateInstance();
  const { mutateAsync: updateInstance, isLoading: isInstanceUpdateLoading } =
    useUpdateIntegrationInstance();
  const { mutateAsync: deleteInstance, isLoading: isInstanceDeleteLoading } =
    useDeleteIntegrationInstance();

  //derived variables
  const isButtonDisabled =
    !updatedInstances.size &&
    !createdInstances.size &&
    !deletedInstances.length;
  const isSaveButtonLoading =
    isInstanceCreationLoading ||
    isInstanceUpdateLoading ||
    isInstanceDeleteLoading;

  useEffect(() => {
    if (integrationInstances) {
      setShownInstances(integrationInstances);
    }
  }, [integrationInstances]);

  //event handlers
  const handleOnSave = () => {
    try {
      createdInstances.forEach((value, key) => {
        if (value) saveInstance(value);
      });

      updatedInstances.forEach((value, key) => {
        updateInstance(value);
      });

      deletedInstances.forEach((value, key) => {
        deleteInstance(value);
      });
      cancelChanges();
    } catch (error) {
      if (error instanceof AxiosError)
        addToast({
          type: "error",
          message: error.message,
        });
    }
  };

  const handleDeleteInstance = (key: string) => {
    setCreatedInstances((prev) => {
      const newMap = new Map(prev);
      newMap.delete(key);
      return newMap;
    });

    const instanceToDelete = shownInstances?.find(
      (integrationInstance) => integrationInstance.id === key
    );
    if (instanceToDelete) {
      setDeletedInstances((prevDeletedInstances) => [
        ...prevDeletedInstances,
        key,
      ]);
    }
    setUpdatedInstances((prev) => {
      const newMap = new Map(prev);
      newMap.delete(key);
      return newMap;
    });
    setShownInstances((prevInstances) =>
      prevInstances.filter((instance) => instance.id !== key)
    );
  };

  const handleOnChange = (
    instanceKey: string,
    newInstance: boolean,
    formData: { instanceName: string;[key: string]: string },
    defaultValues: { instanceName: string;[key: string]: string }
  ) => {
    if (newInstance) {
      const { instanceName, ...config } = formData;
      const creationDto = {
        name: instanceName,
        config: config,
        integrationKey: currentTemplate.key,
      };
      const newMap = new Map(createdInstances);
      newMap.set(instanceKey, creationDto);

      setCreatedInstances(newMap);
    } else {
      const updateDto = updateExistingInstances(
        instanceKey,
        formData,
        defaultValues
      );
      const newMap = new Map(updatedInstances);
      newMap.set(instanceKey, updateDto);
      setUpdatedInstances(newMap);
    }
  };

  const updateExistingInstances = (
    instanceKey: string,
    formData: { instanceName: string;[key: string]: string },
    defaultValues: { instanceName: string;[key: string]: string }
  ) => {
    const { instanceName, ...config } = formData;
    const updateDto: IntegrationInstanceUpdateDto = { id: instanceKey };

    if (instanceName != defaultValues.instanceName) {
      updateDto.name = instanceName;
    }
    const changedConfig = Object.keys(config).reduce(
      (acc: ConfigParamValues, key) => {
        if (formData[key] !== defaultValues[key]) {
          acc[key] = formData[key];
        }
        return acc;
      },
      {}
    );
    updateDto.config = changedConfig;
    return updateDto;
  };

  const handleOnAddInstance = () => {
    const newInstance = "newInstance" + createdInstances.size;

    setCreatedInstances((prev) => {
      const newMap = new Map(prev);
      newMap.set(newInstance, null);
      return newMap;
    });
  };

  const handleOnDiscard = () => {
    cancelChanges();
    setResetTrigger((prev) => !prev);
  };

  const cancelChanges = () => {
    setCreatedInstances(new Map());
    setDeletedInstances([]);
    setUpdatedInstances(new Map());
    setShownInstances(integrationInstances ?? []);
  };

  if (isLoading || isSaveButtonLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>Loading instances...</Text>
      </div>
    );
  }

  if (!isSuccess) {
    return (
      <p className={styles.loader_wrapper}>
        error getting {currentTemplate.name} instances.
      </p>
    );
  }

  return (
    <div className={styles.wrapper}>
      <ConfigParamsWrapper currentTemplate={currentTemplate}>
        {/* existing instances */}
        <Accordion.Root type="multiple">
          {shownInstances.map((instance) => (
            <ConfigParamsContainer
              key={instance.id}
              currentTemplate={currentTemplate}
              integrationInstance={instance}
              onChange={handleOnChange}
              onDeleteInstance={handleDeleteInstance}
              resetTrigger={resetTrigger}
            />
          ))}
        </Accordion.Root>

        {!!createdInstances.size && (
          <>
            <Text className="my-2" size="lg">
              New Instances
            </Text>
            {/* newly created instance  */}
            <Accordion.Root
              type="single"
              collapsible
              defaultValue={Array.from(createdInstances.keys()).pop()}
            >
              {Array.from(createdInstances.entries()).map(
                ([instance, value]) => (
                  <ConfigParamsContainer
                    key={instance}
                    currentTemplate={currentTemplate}
                    onChange={handleOnChange}
                    newInstance={instance}
                    onDeleteInstance={handleDeleteInstance}
                  />
                )
              )}
            </Accordion.Root>
          </>
        )}

        <button className={styles.button_wrapper} onClick={handleOnAddInstance}>
          <CircledPlusIcon height={16} width={16} />
          Add new instance
        </button>
      </ConfigParamsWrapper>
      <div className={styles.buttons_wrapper}>
        <Button
          variant="neutral"
          onClick={handleOnDiscard}
          disabled={isButtonDisabled}
        >
          <ButtonLabel>Discard</ButtonLabel>
        </Button>
        <Button
          onClick={handleOnSave}
          className={styles.button}
          disabled={isButtonDisabled}
          isLoading={isSaveButtonLoading}
        >
          <ButtonLabel>Save</ButtonLabel>
        </Button>
      </div>
    </div>
  );
};
