import styles from "./InitiativeDataTable.module.css";

import { InitiativeDto } from "_sredx/types";
import {
  Card,
  Empty,
  Loader,
  Pagination,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_sredx/_ui";

import { ProgressBar } from "_sredx/_ui/ProgressBar/ProgressBar";

import { InitiativeDataTableRow } from "../InitiativeDataTableRow";
import { INITIATIVE_SEARCH_CRITERIA_DEFAULT } from "_sredx/containers";
import { getHeaderCells } from "./utils";

interface InitiativeDataTableProps {
  initiatives?: InitiativeDto[];
  isLoading: boolean;
  isFetching: boolean;
  totalPages?: number;
  currentPage?: number;
  totalElements?: number;
  onPageChange?: (page: number) => void;
  onAddInitiativeClick?: () => void;
  onRowClick: (initiativeId: string) => void;
  isTeamsSelected?: boolean;
}

export const InitiativeDataTable = ({
  initiatives = [],
  isLoading,
  isFetching,
  onAddInitiativeClick = undefined,
  totalPages = 1,
  currentPage = 0,
  totalElements,
  onPageChange,
  onRowClick,
  isTeamsSelected = false,
}: InitiativeDataTableProps) => {
  // Derived Variables
  const showPagination = totalPages > 1;
  const count =
    (INITIATIVE_SEARCH_CRITERIA_DEFAULT.pageSize || 10) * currentPage +
    initiatives.length;

  // Constants
  const HEADER_CELLS = getHeaderCells(isTeamsSelected);

  // Events handlers
  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };

  if (isLoading) {
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading initiatives...</Text>
        </div>
      </Card>
    );
  }

  if (!isLoading && (!initiatives || initiatives.length === 0)) {
    return (
      <Card>
        <Empty
          message={"No initiative found."}
          actionLabel={"Add new initiative"}
          onActionClick={onAddInitiativeClick}
        />
      </Card>
    );
  }

  return (
    <Table>
      {/* Header*/}
      <TableHeader>
        {HEADER_CELLS.map((cell) => (
          <TableHead className={styles.header_cell} key={cell}>
            {cell}
          </TableHead>
        ))}
      </TableHeader>

      {/* Body */}
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {initiatives.map((initiative) => (
          <InitiativeDataTableRow
            onInitiativeRowClicked={onRowClick}
            key={initiative.id}
            initiative={initiative}
            isTeamsSelected={isTeamsSelected}
          />
        ))}
      </TableBody>

      {/* Footer */}
      <TableFooter>
        <TableFooterCell colSpan={HEADER_CELLS.length}>
          <div className={styles.footer}>
            <span>
              {count} / {totalElements} Initiatives
            </span>
            {showPagination && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handleOnPageChange}
                currentPage={currentPage}
              />
            )}
          </div>
        </TableFooterCell>
      </TableFooter>
    </Table>
  );
};
