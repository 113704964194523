import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { useMutation, useQueryClient } from "react-query";
import { ClientApi } from "../clientApi";

export const useDeleteIntegrationInstance = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.deleteInstance],
    mutationFn: (id: string) => {
      return ClientApi.delete(API_ENDPOINTS.deleteIntegrationInstance(id));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.integrationInstances], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.servicesDiscovery], {
        exact: false,
      });
    },
  });
};
