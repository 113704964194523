import { InitiativeRuleEvaluation } from "./types";

export const calculateTotalSuccessServices = (
  initiativeRuleEvaluation: InitiativeRuleEvaluation
) => {
  return initiativeRuleEvaluation.services.reduce((count, service) => {
    return service.success ? count + 1 : count;
  }, 0);
};

export const resolveProgressColor = (progress: number) => {
  if (progress >= 80) return "#00b341";
  if (progress >= 50) return "#f5a623";
  return "#ff0000";
};
