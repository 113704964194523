import * as SelectPrimitive from "@radix-ui/react-select";
import React from "react";
import { ChevronDownIcon } from "_sredx/_ui/Icon";
import clsx from "clsx";

import styles from "./SelectTrigger.module.css";
import { Variant } from "../types";

interface SelectTriggerProps {
  placeholder?: string;
  variant?: Variant;
}

export const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> &
    SelectTriggerProps
>(({ className, children, variant, placeholder, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={clsx(
      "nemo-select-trigger",
      styles.wrapper,
      variant === "outlined" && styles.outlined,
      variant === "ghost" && styles.ghost,
      variant === "light" && styles.light
    )}
    {...props}
  >
    <SelectPrimitive.Value placeholder={placeholder} />
    <SelectPrimitive.Icon asChild>
      <div className={styles.arrow}>
        <ChevronDownIcon />
      </div>
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
