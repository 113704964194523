import { EditServiceForm } from "components/service/ServiceForm";
import { useModal } from "hooks/useModal";
import Service from "models/service/Service";

export const EditServiceModal = () => {
    const { show, hide } = useModal();
  
    const handleEdit = (service: Service) => {
      show({
        body: (
          <EditServiceForm 
            service={service}
            hide={hide}
          />
        )
      });
    };
  
    return { handleEdit };
};