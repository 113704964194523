import { PropsWithChildren } from "react";
import styles from "./Button.module.css";

interface ButtonProps {
  onClick: () => void;
}
export const Button = ({
  children,
  onClick,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <button className={styles.btn} onClick={onClick} type={"button"}>
      {children}
    </button>
  );
};
