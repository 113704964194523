import { resolveToolUrl } from "../utils";
import clsx from "clsx";
import styles from "./ClusterCardTools.module.css";

interface ClusterCardToolsProps {
  tools: string[];
}

export const ClusterCardTools = ({ tools }: ClusterCardToolsProps) => {
  return (
    <div className={clsx(styles.wrapper)}>
      {tools.map((tool) => 
        {const ToolIcon = resolveToolUrl(tool);
          if(ToolIcon)
          return <ToolIcon key={tool} className={styles.tool_img}/>
      }
      )}
    </div>
  );
};
