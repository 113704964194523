export const InfoCircleIcon = ({
  size = 24,
  color = "currentColor",
}: {
  size: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#a)">
        <path
          fill={color}
          fillRule="evenodd"
          d="M12 2.455a9.545 9.545 0 1 0 0 19.09 9.545 9.545 0 0 0 0-19.09ZM.545 12C.545 5.673 5.673.545 12 .545 18.326.545 23.455 5.673 23.455 12c0 6.326-5.129 11.455-11.455 11.455C5.673 23.455.545 18.326.545 12Z"
          clipRule="evenodd"
        />
        <path
          fill={color}
          d="M11.05 8.185h1.9v-1.91h-1.9v1.91Zm-.96.95h2.865v6.68h.955v1.91h-3.82v-1.91h.955v-4.77h-.955v-1.91Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
