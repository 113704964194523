import { InfiniteData } from "react-query";
import { PagedResponse } from "../types";

export const getFullName = (firstName?: string, lastName?: string) => {
  return `${firstName?.trim()} ${lastName?.trim()}`;
};

export const decodeToBase64 = (token: string | null) => {
  if (!token) return null;
  try {
    return atob(token);
  } catch (error) {
    console.error("Error decoding base64:", error);
    return null;
  }
};

export const mapInfiniteResources = <T>(
  infiniteResources?: InfiniteData<PagedResponse<T>>
) => {
  if (
    !infiniteResources ||
    !infiniteResources.pages ||
    !Array.isArray(infiniteResources.pages) ||
    infiniteResources.pages.length === 0
  ) {
    return [];
  }
  const { pages } = infiniteResources;
  return pages.reduce((result: T[], page) => {
    if (page.content && Array.isArray(page.content)) {
      result.push(...page.content);
    }

    return result;
  }, []);
};
