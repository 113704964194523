import * as yup from "yup";
import { ORGANIZATION_JOB_TITLES, UserProfileFormData } from "./types";

export const schema = yup.object<UserProfileFormData>().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last name is required"),
  jobTitle: yup.string().required("The Role of the user is required"),
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Must reconfirm the password"),
});
export const getJobTitlesOptions = () => {
  return Object.keys(ORGANIZATION_JOB_TITLES).map((key) => ({
    label: ORGANIZATION_JOB_TITLES[key as keyof typeof ORGANIZATION_JOB_TITLES],
    value: key,
  }));
};
