import { InitiativeBaseDto, UpdateInitiativesDto } from "_sredx/types";
import { RoadmapCreationDto } from "_sredx/types/roadmaps";
import { isEmpty } from "_sredx/utils";
import { RoadmapCreationAction, RoadmapCreationFormData } from "./types";

export const mapRoadmapCreationFormDataTORoadmapCreationDto = (
  roadmapCreationFormData: RoadmapCreationFormData
): RoadmapCreationDto => {
  return {
    name: roadmapCreationFormData.generalInfo.name,
    description: roadmapCreationFormData.generalInfo.description,
    startDate: generateStartOfCurrentYearDate(
      roadmapCreationFormData.generalInfo.year
    ),
    endDate: generateEndOfCurrentYearDate(
      roadmapCreationFormData.generalInfo.year
    ),
    tags: [],
  };
};
export const roadmapCreationReducer = (
  state: RoadmapCreationFormData,
  action: RoadmapCreationAction
): RoadmapCreationFormData => {
  switch (action.type) {
    case "SET_GENERAL_INFO":
      return {
        ...state,
        generalInfo: action.payload.generalInfo,
      };
    case "DELETE_INITIATIVE":
      return {
        ...state,
        initiatives: deleteInitiative(
          state.initiatives,
          action.payload.initiative
        ),
      };
    case "SET_INITIATIVES":
      return {
        ...state,
        initiatives: action.payload.initiatives,
      };
    default:
      return state;
  }
};

export const isValidRoadmapCreationGeneralInfo = (
  generalInfo: RoadmapCreationFormData["generalInfo"]
): boolean => {
  if (!generalInfo) return false;
  return (
    !isEmpty(generalInfo.name) &&
    !isEmpty(generalInfo.description) &&
    !isEmpty(generalInfo.year)
  );
};

export const isValidRoadmapCreationInitiatives = (
  initiatives: RoadmapCreationFormData["initiatives"]
): boolean => {
  if (!initiatives) return false;
  return !isEmpty(initiatives);
};

export const isValidRoadmapCreationFormData = (
  formData: RoadmapCreationFormData
): boolean => {
  if (!formData) return false;

  return (
    isValidRoadmapCreationGeneralInfo(formData.generalInfo) &&
    isValidRoadmapCreationInitiatives(formData.initiatives)
  );
};
export const deleteInitiative = (
  initiatives: InitiativeBaseDto[],
  initiative: InitiativeBaseDto
) => {
  return initiatives.filter(({ id }) => id !== initiative.id);
};

export const generateEndOfCurrentYearDate = (year: number): Date => {
  // January is month 0, so December is month 11
  return new Date(year, 11, 31, 23, 59, 59, 999);
};

export const generateStartOfCurrentYearDate = (year: number): Date => {
  return new Date(Date.UTC(year, 0, 1, 0, 0, 0));
};

export const buildInitiativesUpdateDtoList = (
  initiatives: InitiativeBaseDto[],
  roadmapId: string
): UpdateInitiativesDto[] => {
  return initiatives.map((initiative) => ({
    id: initiative.id,
    roadmapId,
  }));
};
