import ParentSize from "@visx/responsive/lib/components/ParentSize";
import styles from "./styles.module.css";

import { useGetWidgetData } from "_sredx/services";
import { DASHBOARD_WIDGETS } from "_sredx/types";
import { useMemo } from "react";
import { Loader } from "_sredx/_ui";
import { BirdEyeData, extractScorecards, mapDataToBins } from "./utils";
import Heatmap from "_sredx/components/Heatmap/BirdEyeHeatmap";

export const ServicesBirdEyeContainer = () => {
  const { data, isLoading } = useGetWidgetData<BirdEyeData>({
    key: DASHBOARD_WIDGETS.SERVICES.SERVICES_BIRD_EYE,
  });
  const binData = useMemo(() => {
    if (!data) return [];
    return mapDataToBins(data);
  }, [data]);

  const scorecards = useMemo(
    () => (data ? extractScorecards(data) : []),
    [data]
  );

  const services = useMemo(() => {
    if (!data) return [];
    return data.services.map((service) => {
      return service.serviceName;
    });
  }, [data]);

  if (isLoading)
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
      </div>
    );
  return (
    <div className={styles.wrapper}>
      <div className={styles.table_head_scorecards}>
        {scorecards.map((_, i) => (
          <div className={styles.scorecard_cell} key={i}>
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: `hsl(${
                  (i * 360) / scorecards.length
                }, 50%, 50%)`,
              }}
            ></div>
            <span>{_.scorecardName}</span>
          </div>
        ))}
      </div>
      <ParentSize>
        {({ width, height }) => {
          return (
            <Heatmap
              width={width}
              height={height}
              binData={binData}
              services={services}
            />
          );
        }}
      </ParentSize>
    </div>
  );
};
