import React from "react";
import Box from "../../../components/octo-ui/Box/Box";
import { Navigate, useParams } from "react-router-dom";
import { useService } from "../../../hooks/useService";
import { NOT_FOUND_PATH } from "../../../routes/paths";
import { LoadingPage } from "../../loading";
import Badge from "../../../components/octo-ui/Badge/Badge";
import ServiceOwnersComponent from "../../../components/service/owners/ServiceOwnersComponent";
import ServiceOverviewComponent from "../../../components/service/ServiceDetails/ServiceOverviewComponent";
import ServiceScoreCardsListComponent from "../../../components/service/ServiceDetails/ServiceScoreCardsListComponent";
import { useServiceLastEvaluations } from "../../../hooks/evaluations/useServiceLastEvaluations";
import { RefreshIcon } from "@heroicons/react/solid";
import { getServiceOverviewFromLastEvaluations } from "../../../services/evaluations/ServiceEvaluations.helper";
import { PageLayout } from "components/Layout";

const AdminServiceDetailPage = () => {
  let { id } = useParams();
  const { data, isSuccess, isLoading } = useService(id!);
  const {
    data: serviceLastEvaluations,
    isSuccess: isLoadingServiceLastEvaluationsSuccessful,
    isLoading: isLoadingServiceLastEvaluations,
  } = useServiceLastEvaluations(id!);

  const canShowEvaluations = isLoadingServiceLastEvaluationsSuccessful;
  // serviceLastEvaluations.every((e) => !!e);

  if (!id) {
    return <Navigate to={NOT_FOUND_PATH} replace={true} />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isSuccess) {
    if (!data) {
      return <Navigate to={NOT_FOUND_PATH} replace={true} />;
    }
    return (
      <PageLayout title={data.name}>
        <Box className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
            <div className="flex flex-col md:flex-row divide-y md:divide-x divide-gray-200 md:items-center">
              <div className="w-full md:w-1/2 px-4 py-5 sm:px-6 flex flex-col items-center">
                <div className="flex gap-1">
                  <h3 className="text-xl leading-6 font-medium text-gray-900">
                    {data.name}
                  </h3>
                  <Badge size="small" theme="gray">
                    {data.tag}
                  </Badge>
                </div>
                <p className="text-sm mt-3">{data.description}</p>
                <div className="sm:col-span-2 mt-4">
                  <ServiceOwnersComponent owners={data.owners} all={true} />
                </div>
                {data.metadata &&
                  data.metadata.tags &&
                  data.metadata.tags.length > 0 && (
                    <div className="sm:col-span-2 mt-4">
                      <Box className="p-1 flex-1">
                        Tags:
                        {data.metadata.tags.map((tag, index) => (
                          <Badge key={tag + index} size="large">
                            {tag}
                          </Badge>
                        ))}
                      </Box>
                    </div>
                  )}
              </div>
              <div className="w-full md:w-1/2 px-4 py-5 sm:px-6 flex flex-col items-center">
                <h3 className="text-xl leading-6 font-medium text-gray-900">
                  Annotations
                </h3>
                {!data.metadata?.annotations ||
                Object.keys(data.metadata.annotations).length === 0 ? (
                  <div className="flex gap-1 items-center mt-5">
                    <p className="text-sm">
                      No annotations configured for this service
                    </p>
                  </div>
                ) : (
                  Object.keys(data.metadata!.annotations!).map((key) => (
                    <div
                      className="grid grid-cols-2 gap-1 justify-items-end mt-3"
                      key={key}
                    >
                      <Badge size="small" theme="gray">
                        {key}
                      </Badge>
                      <p className="text-sm justify-self-start">
                        {data.metadata!.annotations![key]}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </div>
            {isLoadingServiceLastEvaluations && (
              <RefreshIcon
                id={"loading-last-evaluation-spinner"}
                className={"animate-spin h-5 w-5 mx-auto"}
              />
            )}
            <div className="px-4 py-5 sm:p-6 ">
              {canShowEvaluations && (
                <>
                  <ServiceOverviewComponent
                    overviewDetails={getServiceOverviewFromLastEvaluations(
                      serviceLastEvaluations?.filter(
                        (e) => !!e
                      )
                    )}
                  />

                  <ServiceScoreCardsListComponent
                    serviceId={id}
                    serviceLastEvaluations={serviceLastEvaluations?.filter(
                      (e) => !!e
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </Box>
      </PageLayout>
    );
  } else {
    return <Navigate to={NOT_FOUND_PATH} replace={true} />;
  }
};

export default AdminServiceDetailPage;
