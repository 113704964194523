import { useGetInstanceValidity } from "_sredx/services/instances/useGetInstanceValidity";
import React, { useEffect } from "react";
import styles from "./ConfigParamsConnectionCheckContainer.module.css";
import { Button, CheckIcon, Loader, XIcon } from "_sredx/_ui";
import clsx from "clsx";

interface ConfigParamsConnectionCheckContainerProps {
  closeModal: () => void;
  instanceId: string;
}

export const ConfigParamsConnectionCheckContainer = ({
  closeModal,
  instanceId,
}: ConfigParamsConnectionCheckContainerProps) => {
  const { data, isLoading } = useGetInstanceValidity({
    instanceId: instanceId,
    enabled: true,
  });

  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <div className={styles.loader_wrapper}>
          <div>Verifying connection... </div>
          <Loader />
        </div>
      )}
      {data && (
        <>
          <div>
            {data?.valid ? (
              <div className={clsx(styles.check_result, styles.success)}>
                <CheckIcon className={styles.check_icon} />
                Connection established
              </div>
            ) : (
              <div className={clsx(styles.check_result, styles.error)}>
                <XIcon className={styles.x_icon} />
                Something went wrong
              </div>
            )}
          </div>
          {data.errors?.length > 0 && (
            <ul className={styles.error_list}>
              {data?.errors.map((error) => (
                <li className={styles.error_msg}>{error}</li>
              ))}
            </ul>
          )}
        </>
      )}
      <div className={styles.buttons_wrapper}>
        <Button size="small" onClick={closeModal}>
          Close
        </Button>
      </div>
    </div>
  );
};
