import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useReducer } from "react";

import {
  useAddInitiativeRules,
  useAddInitiativeServices,
  useInitiativeCreation,
} from "_sredx/services";
import { useToaster } from "hooks/useToaster";
import { useModal } from "_sredx/hooks";
import { RulesAssignmentContainer } from "../RulesAssignmentContainer";
import { InitiativeService, RuleDto } from "_sredx/types";
import { ServicesAssignmentContainer } from "../ServicesAssignmentContainer";
import { Button, ButtonLabel } from "_sredx/_ui";
import {
  InitiativeCreationInfoForm,
  InitiativeCreationRules,
  InitiativeCreationRulesAction,
  InitiativeCreationSchedulingForm,
  InitiativeCreationServices,
  InitiativeCreationServicesAction,
  InitiativeInfoFormData,
  InitiativeSchedulingFormData,
} from "_sredx/components";

import styles from "./InitiativeCreationContainer.module.css";
import { InitiativeCreationFormData } from "./types";
import {
  initiativeCreationReducer,
  isValidInitiativeCreationFormData,
  mapInitiativeCreationFormDataToInitiativeCreationDto,
  mapInitiativeServicesToServiceBaseDtos,
} from "./utils";
import { AxiosError } from "axios";

const INITIATIVE_CREATION_DEFAULT_FORM_DATA: InitiativeCreationFormData = {
  generalInfo: {
    name: "",
    description: "",
    status: null,
  },
  schedulingInfo: {
    startDate: null,
    endDate: null,
  },
  rules: [],
  services: [],
};

export const InitiativeCreationContainer = () => {
  // Hooks
  const navigate = useNavigate();
  const { addToast } = useToaster();
  const { openModal, closeModal } = useModal();

  const {
    mutateAsync: createInitiative,
    isLoading: isLoadingInitiativeCreation,
  } = useInitiativeCreation();
  const {
    mutateAsync: addInitiativeRules,
    isLoading: isLoadingRulesAssignment,
  } = useAddInitiativeRules();
  const {
    mutateAsync: addInitiativeServices,
    isLoading: isLoadingServicesAssignment,
  } = useAddInitiativeServices();

  // States
  const [initiativeCreationData, dispatch] = useReducer(
    initiativeCreationReducer,
    INITIATIVE_CREATION_DEFAULT_FORM_DATA
  );

  // Derived variables
  const isLoading =
    isLoadingInitiativeCreation ||
    isLoadingRulesAssignment ||
    isLoadingServicesAssignment;

  // Event handlers
  const handleOnInitiativeInfoChange = (
    generalInfo: InitiativeInfoFormData
  ) => {
    dispatch({
      type: "SET_GENERAL_INFO",
      payload: {
        generalInfo,
      },
    });
  };

  const handleInitiativeSchedulingChange = (
    schedulingInfo: InitiativeSchedulingFormData
  ) => {
    dispatch({
      type: "SET_SCHEDULING_INFO",
      payload: {
        schedulingInfo,
      },
    });
  };

  const handleInitiativeRulesAction = (
    action: InitiativeCreationRulesAction
  ) => {
    switch (action.type) {
      case "CLICK_ADD_RULES":
        handleOnAddRules();
        return;
      case "DELETE_RULE":
        dispatch({
          type: "DELETE_RULE",
          payload: {
            rule: action.payload.rule,
          },
        });
        return;
      default:
        return;
    }
  };

  const handleInitiativeServicesAction = (
    action: InitiativeCreationServicesAction
  ) => {
    switch (action.type) {
      case "CLICK_ADD_SERVICES":
        handleOnAddServices();
        return;
      case "DELETE_SERVICE":
        dispatch({
          type: "DELETE_SERVICE",
          payload: {
            service: action.payload.service,
          },
        });
        return;
      default:
        return;
    }
  };

  const handleOnAddServices = () => {
    openModal({
      content: (
        <div className={styles.modal_services_wrapper}>
          <ServicesAssignmentContainer
            onCancel={handleOnCancelAddServices}
            onConfirm={handleOnSubmitServices}
            selectedServices={mapInitiativeServicesToServiceBaseDtos(
              initiativeCreationData.services
            )}
          />
        </div>
      ),
    });
  };
  const handleOnCancelAddRules = () => {
    closeModal();
  };

  const handleOnSubmitServices = (services: InitiativeService[]) => {
    dispatch({
      type: "SET_SERVICES",
      payload: {
        services,
      },
    });
    closeModal();
  };

  const handleOnAddRules = () => {
    openModal({
      content: (
        <div className={styles.modal_rules_wrapper}>
          <RulesAssignmentContainer
            onCancel={handleOnCancelAddRules}
            onConfirm={handleOnSubmitAddRules}
            selectedRules={initiativeCreationData.rules}
          />
        </div>
      ),
    });
  };

  const handleOnCancelAddServices = () => {
    closeModal();
  };

  const handleOnSubmitAddRules = (rules: RuleDto[]) => {
    dispatch({
      type: "SET_RULES",
      payload: {
        rules,
      },
    });
    closeModal();
  };

  const handleCreateInitiative = async () => {
    if (!isValidInitiativeCreationFormData(initiativeCreationData)) return;

    try {
      const initiativeId = await createInitiative(
        mapInitiativeCreationFormDataToInitiativeCreationDto(
          initiativeCreationData
        )
      );

      await addInitiativeRules({
        initiativeId,
        ruleIds: initiativeCreationData.rules.map((r) => r.id),
      });

      await addInitiativeServices({
        initiativeId,
        serviceIds: initiativeCreationData.services.map((s) => s.id),
      });

      navigate("/initiatives");
    } catch (error) {
      if (error instanceof AxiosError)
        addToast({
          type: "error",
          message: error.response?.data.message,
        });
    }
  };

  const handleCancel = () => {
    navigate("/initiatives");
  };

  return (
    <div className={clsx(styles.wrapper)}>
      <InitiativeCreationInfoForm onChange={handleOnInitiativeInfoChange} />
      <InitiativeCreationSchedulingForm
        onChange={handleInitiativeSchedulingChange}
      />
      <InitiativeCreationRules
        rules={initiativeCreationData.rules}
        onAction={handleInitiativeRulesAction}
      />
      <InitiativeCreationServices
        services={initiativeCreationData.services}
        onAction={handleInitiativeServicesAction}
      />
      <div className={styles.action_buttons}>
        <Button variant={"neutral"} ghost onClick={handleCancel}>
          <ButtonLabel>cancel</ButtonLabel>
        </Button>
        <Button
          disabled={!isValidInitiativeCreationFormData(initiativeCreationData)}
          onClick={handleCreateInitiative}
          isLoading={isLoading}
        >
          <ButtonLabel>create</ButtonLabel>
        </Button>
      </div>
    </div>
  );
};
