import { Button, ButtonLabel, Heading, PageContent } from "_sredx/_ui";
import { ResourcesListingContainerr } from "_sredx/containers/ResourcesListingContainer";
import styles from "./ResourcesListingPage.module.css";
import { useNavigate } from "react-router-dom";

export const ResourcesListingPagee = () => {
  const navigate = useNavigate();
  const handleClickAddResource = () => {
    navigate("create");
  };
  return (
    <PageContent>
      <div className={styles.header_wrapper}>
        <div>
          <Heading>Resources</Heading>
          <p>
            Manage and organize your resources to make them easily accessible
          </p>
        </div>
        <div>
          <Button onClick={handleClickAddResource}>
            <ButtonLabel>Add Resources</ButtonLabel>
          </Button>
        </div>
      </div>
      <ResourcesListingContainerr />
    </PageContent>
  );
};
