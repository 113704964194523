export interface PullRequestDto {
  pullRequestId: number;
  pullRequestUrl: string;
  organization: string;
  repository: string;
  createdAt: string;
  closedAt: string;
  mergedAt: string;
  state: string;
  timeToMerge: number;
  timeToReview: number;
  assignees: string[];
  labels: string[];
  requestedReviewers: string[];
  serviceId: string;
  isDraft: boolean;
  nbrComments: number;
  nbrCommits: number;
  nbrReviews: number;
  nbrLineAdded: number;
  nbrLineDeleted: number;
  nbFileChanged: number;
  owner: GitUser;
  participants: GitUser[];
  reviewers: GitUser[];
  title: string;
  number: number;
  firstCommitDate: string;
  lastCommitDate: string;
  firstReview: string;
  lastReview: string;
  prCycleTime: number;
  repositoryName: string;
  reviewApprovedDate: string;
  source: string;
  merged: boolean;
  codingTime: number;
  pickupTime: number;
  reviewTime: number;
  approvalTime: number;
}

interface GitUser {
  userId: number;
  username: string;
}

export interface PullRequestsSearchCriteria {
  filters?: PullRequestFilterObject[];
}

export interface PullRequestFilterObject {
  key: string;
  operator: string;
  value: string;
}

export const PULL_REQUESTS_KEYS = {
  CREATE_AT: "CREATED_AT",
  MERGED_AT: "MERGED_AT",
  CLOSED_AT: "CLOSED_AT",
  OWNER: "OWNER",
  REPOSITORY: "REPOSITORY",
  LABEL: "LABEL",
  REVIEWER: "REVIEWER",
  TITLE: "TITLE",
  STATE: "STATE",
  NUMBER: "NUMBER",
  TIME_TO_MERGE: "TIME_TO_MERGE",
  TIME_TO_REVIEW: "TIME_TO_REVIEW",
  CYCLE_TIME: "CYCLE_TIME",
  CODING_TIME: "CODING_TIME",
  PICKUP_TIME: "PICKUP_TIME",
  REVIEW_TIME: "REVIEW_TIME",
  APPROVAL_TIME: "APPROVAL_TIME",
  NBR_LINE_ADDED: "NBR_LINE_ADDED",
  NBR_LINE_DELETED: "NBR_LINE_DELETED",
  NBR_COMMITS: "NBR_COMMITS",
  NBR_REVIEWS: "NBR_REVIEWS",
  NBR_FILE_CHANGED: "NBR_FILE_CHANGED",
  NBR_COMMENTS: "NBR_COMMENTS",
  SERVICE_ID: "SERVICE_ID",
  NBR_REVIEWERS: "NBR_REVIEWERS",
  NBR_PARTICIPANTS: "NBR_PARTICIPANTS",
  NBR_REQUESTED_REVIEWERS: "NBR_REQUESTED_REVIEWERS",
} as const;

export type PullRequestKeyType =
  (typeof PULL_REQUESTS_KEYS)[keyof typeof PULL_REQUESTS_KEYS];

export interface RepoChangesStats {
  repository: string;
  date: string;
  nbrLineAdded: number;
  nbrLineDeleted: number;
}
