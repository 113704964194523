
import DonutChart from "_sredx/components/DonutChart/DonutChart";
import { DonutData } from "_sredx/types";
import LegenedChart from "../LegenedChart/LegenedChart";
import ContentLoader from "react-content-loader";

interface DonutCardProps {
  data: DonutData[];
  isLoading: boolean;
}

const DonutCard = ({ data, isLoading }: DonutCardProps) => {

  if (isLoading)
    return (
      <ContentLoader
        speed={1}
        width={380}
        height={150}
        viewBox="0 0 400 150"
        backgroundColor="#d4c9c9"
        foregroundColor="#f9f5f5"
      >
        <rect x="220" y="26" rx="3" ry="3" width="180" height="6" />
        <rect x="220" y="46" rx="3" ry="3" width="180" height="6" />
        <rect x="220" y="66" rx="3" ry="3" width="180" height="6" />
        <rect x="220" y="86" rx="3" ry="3" width="180" height="6" />

        <circle cx="90" cy="60" r="60" />
      </ContentLoader>
    );
  return (
    <div
      style={{
        height: 200,
        width: "100%",
        display: "flex",
      }}
    >
      <DonutChart data={data} />

      <LegenedChart data={data} />
    </div>
  );
};

export default DonutCard;
