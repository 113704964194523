import React, { useCallback } from "react";
import { Group } from "@visx/group";
import { Bin, Bins } from "@visx/mock-data/lib/generators/genBins";
import { scaleLinear } from "@visx/scale";
import { HeatmapRect } from "@visx/heatmap";
import { Tooltip, defaultStyles, useTooltip } from "@visx/tooltip";

const cool1 = "#FF7878";
const cool2 = "#92D37A";
export const background = "#28272c";

function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
  return Math.max(...data.map(value));
}

function min<Datum>(data: Datum[], value: (d: Datum) => number): number {
  return Math.min(...data.map(value));
}

export type HeatmapProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  separation?: number;
  events?: boolean;
  minBinWidth?: number;
  binData: Bins[];
  services: string[];
};

// const defaultMargin = { top: 0, left: 0, right: 0, bottom: 0 };
const defaultMargin = { top: 0, left: 10, right: 10, bottom: 110 };

export function Heatmap({
  separation = 2,
  minBinWidth = 72,
  binData,
  services,
}: HeatmapProps) {
  // accessors
  const bins = (d: Bins) => d.bins;
  const count = (d: Bin) => d.count;
  // bounds

  const bucketSizeMax = max(binData, (d) => bins(d).length);
  const colorMax = max(binData, (d) => max(bins(d), count));

  const size = minBinWidth * binData.length + separation * binData.length;
  const xMax = size;
  const yMax = minBinWidth * bucketSizeMax + separation * bucketSizeMax;

  const binWidth =
    xMax / binData.length < minBinWidth ? minBinWidth : xMax / binData.length;
  const binHeight = yMax / bucketSizeMax;

  // scales
  const xScale = scaleLinear<number>({
    domain: [0, binData.length],
  });
  const yScale = scaleLinear<number>({
    domain: [0, bucketSizeMax],
  });
  const rectColorScale = scaleLinear<string>({
    range: [cool1, cool2],
    domain: [0, colorMax],
  });
  const opacityScale = scaleLinear<number>({
    range: [0.1, 1],
    domain: [0, colorMax],
  });

  xScale.range([0, xMax]);
  yScale.range([yMax, 0]);

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{
    scorecardName: string;
    serviceName: string;
    percentage: number;
    totalPossible: number;
    score: number;
  }>();

  const handleMouseEnter = useCallback(
    (event: React.MouseEvent<SVGRectElement>, bin: Bin) => {
      showTooltip({
        //@ts-ignore
        tooltipData: bin?.bin?.metadata,
        //@ts-ignore
        tooltipLeft: bin.x,
        //@ts-ignore
        tooltipTop: bin.y,
      });
    },
    [showTooltip]
  );

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
      }}
    >
      <div className="flex h-[34px] sticky top-0">
        {services.map((val, i) => (
          <div
            key={i}
            style={{
              minWidth: binWidth,
              textAlign: "center",
              width: binWidth,
              fontSize: 12,
              whiteSpace: "wrap",
              textOverflow: "ellipsis",
              backgroundColor: `hsl(${(i * 360) / services.length}, 20%, 80%)`,
            }}
          >
            {val}
          </div>
        ))}
      </div>
      <svg width={xMax} height={yMax}>
        <Group top={-binWidth}>
          <HeatmapRect
            data={binData}
            xScale={(d) => xScale(d) ?? 0}
            yScale={(d) => yScale(d) ?? 0}
            colorScale={rectColorScale}
            binWidth={binWidth}
            binHeight={binWidth}
            gap={2}
          >
            {(heatmap) =>
              heatmap.map((heatmapBins) =>
                heatmapBins.map((bin) => (
                  <>
                    <rect
                      key={`heatmap-rect-${bin.row}-${bin.column}`}
                      className="visx-heatmap-rect"
                      width={bin.width}
                      height={bin.height}
                      y={bin.y}
                      x={bin.x}
                      fill={bin.color}
                      fillOpacity={bin.opacity}
                      onMouseEnter={(e) => handleMouseEnter(e, bin as Bin)}
                      onMouseLeave={hideTooltip}
                    />
                    <text
                      dominant-baseline="middle"
                      text-anchor="middle"
                      pointerEvents="none"
                      x={bin.x + bin.width / 2}
                      y={bin.y + bin.height / 2}
                      style={{
                        fontSize: 24,
                      }}
                      fill="black"
                    >
                      {Math.round(bin.count ?? 0)} %
                    </text>
                  </>
                ))
              )
            }
          </HeatmapRect>
        </Group>
      </svg>
      {tooltipOpen && tooltipData && (
        <Tooltip
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            whiteSpace: "nowrap",
            minWidth: 60,
            backgroundColor: "rgba(0,0,0,0.9)",
            color: "#eeeeee",
          }}
        >
          <div>
            <strong>{tooltipData.scorecardName}</strong>
          </div>
          <div>{tooltipData.percentage.toFixed(2)}%</div>
          <div>
            {tooltipData.score} / {tooltipData.totalPossible} Pts
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default Heatmap;
