import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import { ApiErrorResponse } from "_sredx/types";
import { useMutation, useQueryClient } from "react-query";

export interface InitiativesAffectationDto {
  roadmapId: string;
  initiativesId: string[];
}

export const useAddRoadmapInitiatives = () => {
  const queryClient = useQueryClient();

  return useMutation<string, ApiErrorResponse, InitiativesAffectationDto>({
    mutationKey: [MUTATION_KEYS.addRoadmapInitiatives],
    mutationFn: (dto: InitiativesAffectationDto) =>
      ClientApi.post(
        API_ENDPOINTS.addRoadmapInitiatives(dto.roadmapId),
        dto.initiativesId
      ),
    onSettled: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.roadmaps], {
        exact: false,
      });
    },
  });
};
