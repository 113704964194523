import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import { Text } from "@visx/text";

interface CompositeBarSegmentProps {
  x: number;
  y: number;
  barWidth: number;
  barBgColor: string;
  barTextColor: string;
  barHeight: number;
  barText: string;
  annotationHeight: number;
  annotationStrokeWidth: number;
  annotationStrokeColor: string;
  annotationText: string;
  isLast?: boolean;
  isFirst?: boolean;
  position?: number;
}

/* TODO - rename to CompositeBarChart */
export const CompositeBarSegment = ({
  x,
  y,
  annotationHeight,
  barWidth,
  barBgColor,
  barHeight,
  barText,
  annotationStrokeWidth,
  annotationText,
  barTextColor,
  isLast = false,
  isFirst = false,
  position = 0,
}: CompositeBarSegmentProps) => {
  if (barWidth === 0 && !isLast) {
    return null;
  }
  return (
    <Group top={y} left={x}>
      <rect
        x={0}
        y={annotationHeight}
        fill={barBgColor}
        height={barHeight}
        width={barWidth}
      />
      <Line
        from={{ x: annotationStrokeWidth / 2, y: position % 2 ? 16 : 0 }}
        to={{
          x: annotationStrokeWidth / 2,
          y: annotationHeight + barHeight,
        }}
        stroke="black"
        strokeWidth={1}
      />
      <Text
        fontSize={12}
        x={0}
        y={position % 2 ? 16 : 0}
        dx={4}
        dy={8}
        textAnchor={"start"}
      >
        {annotationText}
      </Text>
      {barWidth && (
        <Text
          fontSize={16}
          fontWeight={700}
          x={0}
          y={annotationHeight + barHeight / 2}
          dx={8}
          dy={8}
          fill={barTextColor}
        >
          {barText}
        </Text>
      )}
    </Group>
  );
};
