import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "./useAxios";
import { ServiceUpdateInput } from "models/service/Service";
import { updateService } from "services/services";
import { QUERY_KEYS } from "_sredx/config";

export const useUpdateService = () => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();

  return useMutation(
    (updatedData: Partial<ServiceUpdateInput>) =>
      updateService(axios, updatedData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.services);
        queryClient.invalidateQueries(QUERY_KEYS.infiniteServices);
        queryClient.invalidateQueries(QUERY_KEYS.infiniteEvaluatedServices);
        queryClient.invalidateQueries(QUERY_KEYS.serviceHealth);
      },
    }
  );
};
