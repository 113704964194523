import { ModalState, useModalStore } from "../state/modal";


export const useModal = ({ consumer = false } = {}) => {
  const { open, body, options, show, hide, updateOptions } = useModalStore(
    (state) =>
      consumer
        ? state
        : ({
            show: state.show,
            hide: state.hide,
            updateOptions: state.updateOptions,
          } as ModalState),
  );

  return { open, body, options, show, hide, updateOptions };
};
