import { PullRequestDto } from "_sredx/types";
import {
  MetricChartStats,
  MetricChartStatsWrapper,
} from "_sredx/components/Metrics";
import React from "react";
import {
  calculatePRAvgAdditions,
  calculatePRAvgDeletions,
} from "_sredx/utils/pullRequests";
import { formatNumber } from "_sredx/utils";

interface PRChangesStatsProps {
  pullRequests: PullRequestDto[];
}

export const PRChangesStats = ({ pullRequests = [] }: PRChangesStatsProps) => {
  const avgAdditions = calculatePRAvgAdditions(pullRequests);
  const avgDeletions = calculatePRAvgDeletions(pullRequests);

  return (
    <MetricChartStatsWrapper>
      <MetricChartStats
        label={"Pull requests"}
        value={pullRequests.length}
        unit={"PRs"}
      />
      <MetricChartStats
        label={"Avg additions"}
        value={formatNumber(avgAdditions) + "LOC"}
      />
      <MetricChartStats
        label={"Avg deletions"}
        value={formatNumber(avgDeletions) + "LOC"}
        unit={"LOC"}
      />
    </MetricChartStatsWrapper>
  );
};
