import { useMutation, useQueryClient } from "react-query";
import { SELF_SERVICE_PATHS, SELF_SERVICE_QUERY_KEYS } from "./paths";
import { useAxios } from "hooks/useAxios";

export const useDeleteCluster = (clusterName: string) => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();
  const deleteCluster = async () => {
    const response = await axios.delete(
      SELF_SERVICE_PATHS.CLUSTER_DETAIL(clusterName)
    );
    return response.data;
  };

  return useMutation(deleteCluster, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [SELF_SERVICE_QUERY_KEYS.CLUSTERS],
      });
    },
  });
};
