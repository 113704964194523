import CloudAccountsListContainer from "./AccountsListContainer/CloudAccountsListContainer";
import styles from "./ConfigurationContainer.module.css";
import GitopsAccountsListContainer from "./GitopsAccountsListContainer/GitopsAccountsListContainer";

const ConfigurationContainer = () => {
  return (
    <section className={styles.root}>
      <h2 className={styles.heading}>Configuration</h2>

      <div className="space-y-4 mt-4">
        <CloudAccountsListContainer />
        <GitopsAccountsListContainer />
      </div>
    </section>
  );
};

export default ConfigurationContainer;
