import ServiceCatalog from "../../components/Landing/features/catalog/ServiceCatalog";
import { ScorecardIllustration } from "components/Landing/features/scorecard/ScorecardIllustration";
import { Gamification } from "components/Landing/features/gamification/Gamification";
import { SurveyIllustration } from "components/Landing/features/survey/SurveyIllustration";
import { FeatureData } from "components/Landing/features/type";


export const FeaturesData : FeatureData[] = [
    {
        title : "Master Your Service Catalog for Peak Architectural Understanding",
        description:"Service catalog integrates with the most popular engineering tools, giving teams an easy way to understand everything about their architecture.",
        illustration: ServiceCatalog,
    },
    {
        title : "Level Up with Scorecards: Quality, Readiness, and Migration",
        description:"Drive improvement with SREDX’s customizable scorecards. Focus on service quality, production readiness standards, or migrations to make your team unstoppable.",
        illustration: ScorecardIllustration,
    },
    {
        title : "Gamification: Fun & Innovation Combined",
        description:"Gamification helps teams innovate in a delightful environment, fostering a sense of ownership and pride as they work on improving service quality. Badges and Leaderboards lie at the heart of this approach.",
        illustration: Gamification,
    },
    {
        title : "Advancing R&D with Surveys: Improving Tooling, Processes, and Culture.",
        description:"SREDX’s Survey is designed to empower your team with valuable insights to enhance your experience and drive continuous improvement. ",
        illustration: SurveyIllustration,
    }
]