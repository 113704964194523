import React from "react";
import styles from "./styles.module.css";
import { FaSpinner } from "react-icons/fa";

interface ButtonProps {
  /** 
   * styling variant of the button. 
   */
  variant?: "primary" | "secondary" | "danger" | "primaryDark" | 'ghost';

  /** 
   * Function to call when the button is clicked. 
   */
  onClick?: (e: any) => void;

  /** 
   * Custom class name for additional styling.
   */
  className?: string;

  /** 
   * If true, disables the hover effect on the button.
   */
  disableHoverEffect?: boolean;

  /** 
   * If true, the button will be disabled and non-interactive.
   */
  disabled?: boolean;

  /** 
   * When true, typically shows a loading spinner.
   */
  isLoading?: boolean;

  /** 
   * Optional icon to display inside the button.
   */
  icon?: React.ReactNode;

  /** 
   * Size of the button. 
   */
  size?: "sm" | "md" | "lg";
}


export const Button = ({
  variant = "primary",
  children,
  onClick,
  className,
  isLoading = false,
  disableHoverEffect = false,
  icon,
  size = "md",
  ...props
}: React.PropsWithChildren<ButtonProps>) => {

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    onClick?.(e);
  };

  const variantStyles = {
    primary: styles.primary,
    secondary: styles.secondary,
    danger: styles.danger,
    primaryDark: styles.primaryDark,
    ghost: styles.ghost
  };

  const sizeStyles = {
    sm: styles.small,
    md: styles.medium,
    lg: styles.large
  };

  const variantClass = variantStyles[variant] || styles.primary;
  const sizeClass = sizeStyles[size] || styles.medium;

  const hoverClass = disableHoverEffect ? styles.noHover : '';


  const IconElement = isLoading ? <FaSpinner className="animate-spin" /> : icon;

  return (
    <button
      onClick={handleOnClick}
      className={`${styles.button} ${variantClass} ${sizeClass} ${hoverClass} ${className}`}
      disabled={isLoading || props.disabled}
      {...props}
    >
      {IconElement && <span className={styles.buttonIcon}>{IconElement}</span>}
      <div>{children}</div>
    </button>

  );
};
