import React from 'react';
import style from "./ScorecardCategoriesSectionComponent.module.css";
import RuleCategory from 'models/scorecard/RuleCategory';
import { Tag } from '_sredx/_ui/Tag/Tag';

interface ScorecardCategoriesSectionComponentProps {
    ruleCategories : RuleCategory[];
}

export const ScorecardCategoriesSectionComponent = ({ruleCategories} : ScorecardCategoriesSectionComponentProps) => {
    const length = ruleCategories.length;
  return (
    <div className={style.wrapper}>
      <p className={style.title}>
        Categories ({length})
      </p>
      <div className={style.content_wrapper}>
        {ruleCategories.slice(0, 3).map((category , index) => (
          <Tag key={index} tag={category.categoryName} />
        ))}
        {length > 3 && <div className={style.more}>+{length - 3}</div>}
      </div>
      
    </div>
  );
}
