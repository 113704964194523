import { EvaluationLevelsResultDto } from "_sredx/types";

export const filterEvaluationLevels = (
  evaluations?: EvaluationLevelsResultDto[]
) => {
  if (!evaluations) return [];
  return evaluations.filter((evaluation) => {
    return evaluation.nextLevel !== null;
  });
};
