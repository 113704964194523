import rulesBarStyles from "./RulesBarStyles.module.css";

interface RulesBarProps {
  succeeded: number;
  failed: number;
}

const RulesBar  = ({ succeeded, failed } : RulesBarProps) => {
  const totalRules = succeeded + failed;

  return (
    <div className={rulesBarStyles.rulesBarContainer}>
      <div
        className={rulesBarStyles.categoryRulesContainer}
        style={{ width: `${(succeeded / totalRules) * 99}%` }}
      >
        <div className={rulesBarStyles.passingRules}></div>
        <span className={rulesBarStyles.passingRulesText}>
          {succeeded} passing Rules
        </span>
      </div>

      <div
        className={rulesBarStyles.categoryRulesContainer}
        style={{ width: `${(failed / totalRules) * 99}%` }}
      >
        <div className={rulesBarStyles.failingRules}></div>
        <span className={rulesBarStyles.failingRulesText}>
          {failed} failing Rules
        </span>
      </div>
    </div>
  );
};

export default RulesBar;
