import { forwardRef } from "react";
import { IconProps } from "./types";

export const CodeIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          d="m10.946 19.6-1.347-.425L13.851 5l1.347.425L10.946 19.6Zm5.457-2.552-1.063-.992 3.473-3.756-3.473-3.756 1.063-.993 4.394 4.749-4.394 4.748Zm-8.08 0L4 12.3l4.323-4.749 1.063.993L5.914 12.3l3.472 3.756-1.063.992Z"
        />
      </svg>
    );
  }
);
