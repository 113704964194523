import { PageLayout } from "components/Layout";
import EvaluateAllContainer from "containers/EvaluateAllcontainer/EvaluateAllContainer";
import RankedServiceListContainer from "containers/RankedServiceListContainer/RankedServiceListContainer";

export const LeaderBoardPage = () => {
  return (
    <PageLayout
      title="Hall of Fame"
      description="A straightforward look at service rankings."
      actions={<EvaluateAllContainer />}
    >
      <RankedServiceListContainer />
    </PageLayout>
  );
};
