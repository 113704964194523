import React, { OlHTMLAttributes, PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./List.module.css";

interface ListProps extends OlHTMLAttributes<HTMLOListElement> {}

export const List = ({ children, ...props }: PropsWithChildren<ListProps>) => {
  return (
    <ol className={clsx("nemo-list", styles.wrapper)} {...props}>
      {children}
    </ol>
  );
};
