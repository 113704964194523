import Overview from "models/scorecard/Overview";

  export const getAllServicesCount = (overview : Overview) => {
    return overview.failing + overview.needsAttention + overview.passing + overview.progressing;
  }


  export const getValidPercentage = (allServicesCount : number ,number: number) => {
    if(allServicesCount == 0) return 0;
    return Math.min(Math.max((number / allServicesCount) * 100, 0), 100)
  }