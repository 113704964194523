import { forwardRef } from "react";
import { IconProps } from "./types";

export const TrashIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.938 7.5H6.688a.562.562 0 1 1 0-1.125h11.25a.563.563 0 0 1 0 1.125Zm-.563 11.25c0 .621-.504 1.125-1.125 1.125H8.375A1.125 1.125 0 0 1 7.25 18.75V8.625h10.125V18.75Zm-6.75-14.063c0-.31.252-.562.563-.562h2.25c.31 0 .562.251.562.563v.562h-3.375v-.563Zm7.875.563h-3.375V4.125C15.125 3.504 14.621 3 14 3h-3.375C10.004 3 9.5 3.504 9.5 4.125V5.25H6.125C5.504 5.25 5 5.754 5 6.375V7.5c0 .621.504 1.125 1.125 1.125V18.75A2.25 2.25 0 0 0 8.375 21h7.875a2.25 2.25 0 0 0 2.25-2.25V8.625c.621 0 1.125-.504 1.125-1.125V6.375c0-.621-.504-1.125-1.125-1.125Zm-6.188 13.5a.562.562 0 0 0 .563-.563v-6.75a.563.563 0 0 0-1.125 0v6.75c0 .312.252.563.563.563Zm-2.812 0c.31 0 .563-.251.563-.563v-6.75a.563.563 0 0 0-1.126 0v6.75c0 .312.253.563.563.563Zm5.625 0c.31 0 .563-.251.563-.563v-6.75a.563.563 0 0 0-1.126 0v6.75c0 .312.253.563.563.563Z"
          fill={color}
        />
      </svg>
    );
  }
);
