import { TextInput } from 'components/octo-ui/Forms/TextInput';
import styles from './GitopsAccountForm.module.css';
import { FormField } from 'components/basic/FormField';


interface GitopsAccountFormProps {
    errors?: any,
}

const GitopsAccountForm = ({errors} : GitopsAccountFormProps) => {
    return (
        <div className={styles.root} >
            <FormField 
                id='name'
                name='name'
                label='name'
                placeholder='Enter gitops account name'
                component={TextInput}
                error={errors?.name?.message}
            />

            <FormField 
                id='owner'
                name='owner'
                label='owner'
                placeholder='Enter owner'
                component={TextInput}
                error={errors?.owner?.message}
            />

            <FormField 
                id='accessToken'
                name='accessToken'
                label='accessToken'
                placeholder='Enter github classic access token'
                component={TextInput}
                type='password'
                error={errors?.accessToken?.message}
            />
    
                
        </div>
      )
}

export default GitopsAccountForm