import { forwardRef } from "react";
import { IconProps } from "./types";

export const LogoutIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 471.2 471.2"
        {...props}
        ref={ref}
      >
        <path d="M228 444H105c-34 0-61-27-61-60V88c0-34 27-61 61-61h125c7 0 13-6 13-13s-6-14-13-14H105C56 0 17 39 17 88v296c0 48 40 87 88 87h123c7 0 13-6 13-13s-6-14-13-14z" />
        <path d="m450 226-86-86c-5-5-14-5-19 0-5 6-5 14 0 19l63 63H134c-7 0-13 6-13 14s6 13 13 13h274l-63 63c-5 5-5 14 0 19 3 3 6 4 10 4s7-1 9-4l86-86c5-5 5-14 0-19z" />
      </svg>
    );
  }
);
