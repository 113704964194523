import { ServiceDatatable } from "_sredx/components/ServicesDatatable";
import { API_PAGE_SIZE_DEFAULT } from "_sredx/constants/api";
import { SearchParams } from "_sredx/containers/ServicesListingContainer/types";
import { useGetEvaluatedServices } from "_sredx/services";
import { ServiceSearchCriteria } from "_sredx/types";
import { parsePageNumber } from "_sredx/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ServiceListingDataTableContainer.module.css";

interface ServiceListingDataTableContainerProps
  extends SearchParams<ServiceSearchCriteria> {}

export const ServiceListingDataTableContainer = ({
  query,
}: ServiceListingDataTableContainerProps) => {
  //state
  const [searchParams, setSearchParams] = useSearchParams();
  //hooks
  const navigate = useNavigate();
  // Derived variables

  const currentPage = parsePageNumber(searchParams.get("page"));

  //data
  const {
    data: services,
    isFetching,
    isLoading,
  } = useGetEvaluatedServices({
    params: {
      ...query,
      pageSize: API_PAGE_SIZE_DEFAULT,
      pageNumber: currentPage,
    },
  });

  //handlers
  const handleClickService = (serviceId: string) => navigate(serviceId);

  const handleOnPageChange = (page: number) => {
    setSearchParams({ page: (page + 1).toString() });
  };

  return (
    <div className={styles.datatable_wrapper}>
      <ServiceDatatable
        services={services && services.content}
        isFetching={isFetching}
        isLoading={isLoading}
        onClickService={handleClickService}
        totalElements={services && services.totalElements}
        totalPages={services && services.totalPages}
        onPageChange={handleOnPageChange}
        currentPage={currentPage}
      />
    </div>
  );
};
