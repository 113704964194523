import { ChangeEvent } from "react";
import { AdvancedFiltersInputValueProps } from "../types";
import inputStyles from "../Input.module.css";

interface InputTextProps extends AdvancedFiltersInputValueProps {}

export const InputText = ({ value, onChange }: InputTextProps) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <input
      value={value}
      onChange={handleOnChange}
      className={inputStyles.input}
      type="text"
      placeholder={"Value"}
    />
  );
};
