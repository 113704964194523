import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from '_sredx/config';
import { ApiErrorResponse, ServiceBaseDto } from '_sredx/types';
import { useMutation, useQueryClient } from 'react-query';
import { ClientApi } from '../clientApi';

export const useCreateServices = () => {
    const queryClient = useQueryClient();
  return useMutation<ServiceBaseDto[], ApiErrorResponse, ServiceBaseDto[]>({
    mutationKey: [MUTATION_KEYS.createServices],
    mutationFn: (services: ServiceBaseDto[]) => {
      return ClientApi.post(
        API_ENDPOINTS.createServices(),
        services
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.services], {
        exact: false,
      });
    },
  }); 
}
