import React from 'react';
import style from "./FeatureSection.module.css";
import clsx from 'clsx';

interface FeatureContainerProps {
    title : string;
    description : string;
    illustration : React.ElementType;
}

const FeatureSection = ({title, description, illustration: IllustrationComponent} : FeatureContainerProps) => {
  return (

    <div className={clsx(style.wrapper)}>
      <div className={style.content_wrapper}>
        <h1 className={style.title}>{title}</h1>
        <p className={style.description}>{description}</p>
      </div>
        <div className={style.illustration}>
            <IllustrationComponent />
        </div>

    </div>
  );
}

export default FeatureSection