import { PagedResponse, TeamBaseDto, TeamSearchCriteria } from "_sredx/types";
import { useInfiniteQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";

interface UseInfiniteGetTeamsProps {
  params: TeamSearchCriteria;
}

export const useInfiniteGetTeams = ({ params }: UseInfiniteGetTeamsProps) => {
  return useInfiniteQuery<PagedResponse<TeamBaseDto>>({
    queryKey: [QUERY_KEYS.infiniteTeams, params],
    queryFn: ({ pageParam = 0 }) => {
      return ClientApi.get(API_ENDPOINTS.getTeams(), {
        params: {
          ...params,
          pageNumber: pageParam,
        },
      });
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.last) {
        return undefined;
      }
      return lastPage.pageNo + 1;
    },
    // staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });
};
