import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./ResourcesCreationContainer.module.css";
import { useModal } from "_sredx/hooks";
import { FormProvider, useForm } from "react-hook-form";
import {
  mapResourceServicesToServiceBaseDtos,
  resourceCreationReducer,
  ResourceService,
  schema,
} from "./utils";
import { ResourceCreationGeneralInfoForm } from "_sredx/components/ResourceCreationForm";
import { ResourceCreationServiceForm } from "_sredx/components/ResourceCreationForm/ResourceCreationServiceForm";
import { ServicesAssignmentContainer } from "../ServicesAssignmentContainer";
import { useEffect, useReducer } from "react";
import { ServicesListAction } from "_sredx/components/ServicesList";
import { Button } from "_sredx/_ui";
import { useNavigate } from "react-router-dom";
import { useCreateResource } from "_sredx/services/resources";
import { ResourceType } from "_sredx/types/resources";

const RESOURCE_CREATION_DEFAULT_FORM_DATA = {
  generalInfo: {
    name: "",
    product: "",
    version: "",
    description: "",
    type: "",
    tags: [],
  },
  services: [],
};

export const ResourcesCreationContainer = () => {
  const navigate = useNavigate();
  const { mutate: createResource, isLoading } = useCreateResource();
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { openModal, closeModal } = useModal();

  const [resourceCreationData, dispatch] = useReducer(
    resourceCreationReducer,
    RESOURCE_CREATION_DEFAULT_FORM_DATA
  );

  useEffect(() => {
    methods.setValue(
      "services",
      resourceCreationData.services.map((service) => ({
        id: service.id,
        name: service.name ?? "",
      }))
    );
  }, [resourceCreationData.services]);

  const handleResourceServicesAction = (action: ServicesListAction) => {
    switch (action.type) {
      case "CLICK_ADD_SERVICES":
        handleOnAddServices();
        return;
      case "DELETE_SERVICE":
        dispatch({
          type: "DELETE_SERVICE",
          payload: {
            service: action.payload.service,
          },
        });
        return;
      default:
        return;
    }
  };
  const handleOnAddServices = () => {
    openModal({
      content: (
        <div className={styles.modal_services_wrapper}>
          <ServicesAssignmentContainer
            onCancel={handleOnCancelAddServices}
            onConfirm={handleOnSubmitServices}
            selectedServices={mapResourceServicesToServiceBaseDtos(
              resourceCreationData.services
            )}
          />
        </div>
      ),
    });
  };

  const handleOnSubmitServices = (services: ResourceService[]) => {
    dispatch({
      type: "SET_SERVICES",
      payload: {
        services,
      },
    });
    closeModal();
  };

  const handleOnCancelAddServices = () => {
    closeModal();
  };
  const handleCancel = () => {
    navigate("/resources");
  };
  const handleSave = () => {
    const values = methods.getValues();

    createResource(
      {
        name: values.generalInfo.name,
        description: values.generalInfo.description,
        tags: values.generalInfo.tags ?? [],
        product: values.generalInfo.product,
        version: values.generalInfo.version,
        type: values.generalInfo.type as ResourceType,
        servicesIds: values.services?.map((service) => service.id) ?? [],
      },
      {
        onSuccess: () => {
          navigate("/resources");
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <FormProvider {...methods}>
        <ResourceCreationGeneralInfoForm />
        <ResourceCreationServiceForm
          services={resourceCreationData.services}
          onAction={handleResourceServicesAction}
        />

        <div className={styles.form_actions}>
          <Button onClick={handleCancel} variant="neutral">
            Cancel
          </Button>
          <Button
            onClick={methods.handleSubmit(handleSave)}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Save
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};
