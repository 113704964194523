import { Card, Loader } from "_sredx/_ui";
import styles from "./OnBoardingCardSpinner.module.css";

export const OnBoardingCardSpinner = () => {
  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.spinner}>
          <Loader />
        </div>
      </div>
    </Card>
  );
};
