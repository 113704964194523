import SidebarComponent from "components/basic/SidebarComponent/SidebarComponent";
import SidebarEvaluationContentComponent from "components/basic/SidebarEvaluationContent/SidebarEvaluationContentComponent";
import { Button, ButtonIcon, ButtonLabel } from "_sredx/_ui";
import { useEvaluateAllScoreCards } from "hooks/scorcards/useEvaluateAllScoreCards";
import useAutoScroll from "hooks/useAutoScroll";
import useSse from "hooks/useSse";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaBolt } from "react-icons/fa";
import { CurrentScoreCard } from "types/ProgressInfo";
import { v4 as uuid } from "uuid";
import { useModal } from "../../_sredx/hooks";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../_sredx/_ui";

const EvaluateAllContainer = () => {
  // Hooks
  const { openModal, closeModal } = useModal();

  const updateScorecards = useCallback(
    (
      scorecardUpdate: CurrentScoreCard,
      statusUpdate: "done" | "inProgress"
    ) => {
      setAllScorecards((prevScorecards) => {
        const updatedPrevScorecards = [...prevScorecards];
        if (updatedPrevScorecards.length > 0) {
          updatedPrevScorecards[updatedPrevScorecards.length - 1].status =
            "done";
        }
        updatedPrevScorecards.push({
          scorecard: scorecardUpdate,
          status: statusUpdate,
        });
        return updatedPrevScorecards;
      });
    },
    []
  );

  const showConfirmationModal = () => {
    openModal({
      content: (
        <Card>
          <CardHeader>
            <CardTitle>Are you sure?</CardTitle>
          </CardHeader>
          <CardBody>
            <div style={{ minWidth: "300px", marginBottom: "24px" }}>
              This action might take time.
            </div>
          </CardBody>
          <CardFooter>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "16px",
              }}
            >
              <Button
                type="button"
                data-testid={"close-error-alert-button"}
                onClick={closeModal}
                variant={"neutral"}
                ghost
              >
                Cancel
              </Button>
              <Button
                type="button"
                data-testid={"confirm-error-alert-button"}
                onClick={() => {
                  handleEvaluateAllScoreCards();
                  closeModal();
                }}
              >
                Confirm
              </Button>
            </div>
          </CardFooter>
        </Card>
      ),
    });
  };

  const connexionId = useMemo(() => uuid(), []);
  const {
    sseData: currentScoreCard,
    connect: connectCurrentScoreCard,
    disconnect: disconnectCurrentScoreCard,
  } = useSse<CurrentScoreCard>(
    `/evaluation-progress/current-scorecard/${connexionId}`
  );

  const [allScorecards, setAllScorecards] = useState<
    { scorecard: CurrentScoreCard; status: "done" | "inProgress" }[]
  >([]);

  const scrollContainer = useRef<HTMLDivElement>(null);

  useAutoScroll(scrollContainer, allScorecards);

  const { mutateAsync: evaluateAllScoreCards, isLoading: isEvaluating } =
    useEvaluateAllScoreCards(connexionId);

  const [evaluationStarted, setEvaluationStarted] = useState(false);

  const handleEvaluateAllScoreCards = useCallback(async () => {
    setEvaluationStarted(true);
    connectCurrentScoreCard();
    setAllScorecards([]);
    await evaluateAllScoreCards();
    disconnectCurrentScoreCard();

    setAllScorecards((prevScorecards) => {
      const updatedPrevScorecards = [...prevScorecards];
      if (updatedPrevScorecards.length > 0) {
        updatedPrevScorecards[updatedPrevScorecards.length - 1].status = "done";
      }
      return updatedPrevScorecards;
    });
  }, [
    connectCurrentScoreCard,
    disconnectCurrentScoreCard,
    evaluateAllScoreCards,
  ]);

  useEffect(() => {
    if (currentScoreCard) {
      updateScorecards(currentScoreCard, "inProgress");
    }
  }, [currentScoreCard, updateScorecards]);

  return (
    <>
      <Button onClick={showConfirmationModal} isLoading={isEvaluating}>
        <ButtonIcon>
          <FaBolt />
        </ButtonIcon>
        <ButtonLabel>Evaluate all</ButtonLabel>
      </Button>

      {evaluationStarted && (
        <SidebarComponent
          hidden={!evaluationStarted}
          className="animate-slideIn text-black"
          onClose={() => {
            setEvaluationStarted(false);
          }}
          scrollContainer={scrollContainer}
          title="Evaluation"
        >
          <SidebarEvaluationContentComponent allScorecards={allScorecards} />
        </SidebarComponent>
      )}
    </>
  );
};

export default EvaluateAllContainer;
