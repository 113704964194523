import React from "react";
import { Navigate } from "react-router-dom";

import { AppLayout } from "_sredx/Layout";
import {
  ClusterDetailsManagedRessourceContainer,
  ClusterDetailsToolsContainer,
  InitiativeDetailsRulesContainer,
  InitiativeDetailsServicesContainer,
  ScoreCardDetailsServicesContainer,
  TeamDetailsMembersContainer,
  TeamDetailsServicesContainer,
} from "_sredx/containers";
import HomePage from "_sredx/Pages/HomePage";
import { ClusterDetailsPage } from "_sredx/Pages/ClusterDetailsPage";
import { ClustersListingPage } from "_sredx/Pages/ClustersListingPage";
import { UserOnBoardingPage } from "_sredx/Pages/UserOnBoardingPage";
import { DashboardsPage } from "_sredx/Pages/DashboardsPage";
import { InitiativeCreationPage } from "_sredx/Pages/InitiativeCreationPage";
import { InitiativeDetailsPage } from "_sredx/Pages/InitiativeDetailsPage";
import { InitiativeListingPage } from "_sredx/Pages/InitiativeListingPage";
import { LeaderboardPage } from "_sredx/Pages/LeaderboardPage";
import { MembersListingPage } from "_sredx/Pages/MembersListingPage";
import { RoadmapCreationPage } from "_sredx/Pages/RoadmapCreationPage";
import { RoadmapListingPage } from "_sredx/Pages/RoadmapListingPage";
import { ScorecardsListingPage } from "_sredx/Pages/ScorecardsListingPage";
import { ServiceDetailsPage } from "_sredx/Pages/ServiceDetailsPage";
import { ServiceDetailsScorcardsContainer } from "_sredx/containers/ServiceDetailsScorcardsContainer";
import { ServicesListingPage } from "_sredx/Pages/ServicesListingPage";
import { TeamDetailPage } from "_sredx/Pages/TeamDetailPage";
import { TeamsCreationPage } from "_sredx/Pages/TeamsCreationPage";
import { TeamsListingPage } from "_sredx/Pages/TeamsListingPage";
import { ServicesCreationPage } from "_sredx/Pages/ServicesCreationPage";
import { ScoreCardCreationPage } from "_sredx/Pages/ScoreCardsCreationPage";
import { ScoreCardDetailsPage } from "_sredx/Pages/ScoreCardDetailsPage";
import { ScoreCardEditPage } from "_sredx/Pages/ScoreCardEditPage";
import { DomainsListingPage } from "_sredx/Pages/DomainsListingPage";
import ConfigurationsPage from "_sredx/Pages/ConfigurationsPage";

import { ROUTE } from "./types";
import { RoadmapDetailPage } from "_sredx/Pages/RoadmapDetailPage";
import { SelfserviceSettingsPage } from "_sredx/Pages/SelfserviceSettingsPage";
import { AdminOnboardingPage } from "_sredx/Pages/AdminOnboardingPage";
import { MetricPullRequestsPage } from "_sredx/Pages/MetricPullRequestsPage";
import { ResourcesListingPagee } from "_sredx/Pages/ResourcesListingPage";
import { ResourcesCreationPage } from "_sredx/Pages/ResourcesCreationPage";

export const ROUTES: ROUTE[] = [
  {
    path: "",
    element: <AppLayout />,
    crumb: "Home",
    isPrivate: true,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "metrics/pull-requests",
        element: <MetricPullRequestsPage />,
      },
      {
        path: "dashboards",
        crumb: "Dashboards",
        element: <DashboardsPage />,
      },
      {
        path: "services",
        crumb: "Services",
        children: [
          {
            path: "",
            element: <ServicesListingPage />,
          },
          {
            path: "create",
            crumb: "Add services",
            element: <ServicesCreationPage />,
          },
          {
            path: ":serviceId",
            element: <ServiceDetailsPage />,
            children: [
              {
                index: true,
                element: <Navigate to={"scorecards"} />,
              },
              {
                path: "scorecards",
                element: <ServiceDetailsScorcardsContainer />,
              },
            ],
          },
        ],
      },
      {
        path: "teams",
        crumb: "Teams",
        children: [
          {
            path: "",
            element: <TeamsListingPage />,
          },
          {
            path: "create",
            crumb: "Create team",
            element: <TeamsCreationPage />,
          },
          {
            path: ":teamId",
            element: <TeamDetailPage />,
            children: [
              {
                index: true,
                element: <Navigate to={"members"} />,
              },
              {
                path: "members",
                element: <TeamDetailsMembersContainer />,
              },
              {
                path: "services",
                element: <TeamDetailsServicesContainer />,
              },
            ],
          },
        ],
      },
      {
        path: "domains",
        crumb: "Domains",
        element: <DomainsListingPage />,
      },
      {
        path: "resources",
        crumb: "Resources",
        children: [
          {
            path: "",
            element: <ResourcesListingPagee />,
          },
          {
            path: "create",
            crumb: "Add resources",
            element: <ResourcesCreationPage />,
          },
        ],
      },
      {
        path: "members",
        crumb: "Members",
        element: <MembersListingPage />,
      },
      {
        path: "scorecards",
        crumb: "Scorecards",
        children: [
          {
            path: "",
            element: <ScorecardsListingPage />,
          },
          {
            path: "create",
            crumb: "Create Scorecard",
            element: <ScoreCardCreationPage />,
          },
          {
            path: ":scorecardId",
            element: <ScoreCardDetailsPage />,
            children: [
              {
                index: true,
                element: <Navigate to={"services"} />,
              },
              {
                path: "services",
                element: <ScoreCardDetailsServicesContainer />,
              },
            ],
          },
          {
            path: ":scorecardId/edit",
            element: <ScoreCardEditPage />,
          },
        ],
      },
      {
        path: "leaderboard",
        crumb: "Leaderboard",
        element: <LeaderboardPage />,
      },
      {
        path: "initiatives",
        crumb: "Initiatives",
        children: [
          {
            path: "",
            element: <InitiativeListingPage />,
          },
          {
            path: "create",
            crumb: "Create initiative",
            element: <InitiativeCreationPage />,
          },
          {
            path: ":initiativeId",
            element: <InitiativeDetailsPage />,
            children: [
              {
                index: true,
                element: <Navigate to={"rules"} />,
              },
              {
                path: "services",
                element: <InitiativeDetailsServicesContainer />,
              },
              {
                path: "rules",
                element: <InitiativeDetailsRulesContainer />,
              },
            ],
          },
        ],
      },
      {
        path: "roadmaps",
        crumb: "Roadmaps",
        children: [
          {
            path: "",
            element: <RoadmapListingPage />,
          },
          {
            path: "create",
            crumb: "Create roadmap",
            element: <RoadmapCreationPage />,
          },
          {
            path: ":roadmapId",
            crumb: "Details",
            element: <RoadmapDetailPage />,
          },
        ],
      },
      {
        path: "/self-service",
        crumb: "Self service",
        children: [
          {
            path: "clusters",
            crumb: "Clusters",
            element: <ClustersListingPage />,
          },
          {
            path: "clusters/:clusterId",
            element: <ClusterDetailsPage />,
            children: [
              {
                index: true,
                element: <Navigate to={"managed-resources"} />,
              },
              {
                path: "managed-resources",
                element: <ClusterDetailsManagedRessourceContainer />,
              },
              {
                path: "foundations",
                element: <ClusterDetailsToolsContainer />,
              },
            ],
          },
          {
            path: "settings",
            crumb: "Settings",
            element: <SelfserviceSettingsPage />,
          },
        ],
      },
      {
        path: "/configurations",
        crumb: "Configurations",
        element: <ConfigurationsPage />,
        isPrivate: true,
      },
      {
        path: "/configurations/:templateKey",
        element: <ConfigurationsPage />,
        isPrivate: true,
      },
    ],
  },

  {
    path: "accept-invitation",
    element: <UserOnBoardingPage />,
    isPrivate: false,
  },

  {
    path: "*",
    element: <div>404</div>,
  },
  {
    path: "/unauthorized",
    element: <div>UnAuthorized</div>,
  },
  {
    path: "admin-onboarding",
    element: <AdminOnboardingPage />,
    isPrivate: true,
  },
];
