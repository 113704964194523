export interface DashboardInfoDto {
  id: string;
  name: string;
  description: string;
  widgets: WidgetInfoDto[];
}
export interface WidgetInfoDto {
  w: number;
  h: number;
  x: number;
  y: number;
  i: string;
}

export const DASHBOARD_WIDGETS = {
  TEAMS: {
    COUNT_TEAMS: "COUNT_TEAMS",
    COUNT_MEMBERS: "COUNT_MEMBERS",
    TEAMS_HEALTH: "TEAMS_HEALTH",
    TEAMS_SCORECARDS: "TEAMS_SCORECARDS",
    BEST_PERFORMING_TEAMS: "BEST_PERFORMING_TEAMS",
    WORST_PERFORMING_TEAMS: "WORST_PERFORMING_TEAMS",
    WORST_DROP_TEAMS: "WORST_DROP_TEAMS",
    BEST_RISE_TEAMS: "BEST_RISE_TEAMS",
  },
  SERVICES: {
    COUNT_SERVICES: "COUNT_SERVICES",
    SERVICES_HEALTH: "SERVICES_HEALTH",
    BEST_PERFORMING_SERVICES: "BEST_PERFORMING_SERVICES",
    WORST_PERFORMING_SERVICES: "WORST_PERFORMING_SERVICES",
    SERVICES_PERFORMANCE: "SERVICES_PERFORMANCE",
    SERVICES_BIRD_EYE: "SERVICES_BIRD_EYE",
    WORST_DROP_SERVICES: "WORST_DROP_SERVICES",
    BEST_RISE_SERVICES: "BEST_RISE_SERVICES",
    
  },
  SCORECARDS: {
    COUNT_SCORECARDS: "COUNT_SCORECARDS",
    SCORECARDS_HEALTH: "SCORECARDS_HEALTH",
    SCORECARDS_HEALTH_PER_LEVELS: "SCORECARDS_HEALTH_PER_LEVELS",
    SCORE_TIMELINE: "SCORE_TIMELINE",
  },
  INITIATIVES: {
    COUNT_INITIATIVES: "COUNT_INITIATIVES",
  },
  CLUSTERS: {
    COUNT_CLUSTERS: "COUNT_CLUSTERS",
  },
  RULES: {
    COUNT_RULES: "COUNT_RULES",
    MOST_PASSING_RULES: "MOST_PASSING_RULES",
    LEAST_PASSING_RULES: "LEAST_PASSING_RULES",
  },
};
const widgetTypes = {
  count: "count",
  donut: "donut",
  radar: "radar",
  bar: "bar",
  stackedBar: "stackedBar",
  line: "line",
  heatmap: "heatmap",
  table: "table",
};
export const resolveWidgetImage = (type: string) => {
  switch (type) {
    case widgetTypes.count:
      return "count.svg";
    case widgetTypes.donut:
      return "donut_chart.svg";
    case widgetTypes.radar:
      return "radar_chart.svg";
    case widgetTypes.bar:
      return "bar_graph.svg";
    case widgetTypes.stackedBar:
      return "stacked_bar_graph.svg";
    case widgetTypes.line:
      return "line_graph.svg";
    case widgetTypes.heatmap:
      return "heat_map.svg";
    case widgetTypes.table:
      return "bullet_chart.svg";
    default:
      return "unknown";
  }
};

export const WIDGET_META = {
  COUNT_TEAMS: {
    title: "Teams Count",
    type: widgetTypes.count,
    description: "The total number of teams in the organization.",
  },
  COUNT_MEMBERS: {
    title: "Members Count",
    type: widgetTypes.count,
    description: "The total number of members in the organization.",
  },
  TEAMS_HEALTH: {
    title: "Teams Health",
    type: widgetTypes.donut,
    description: "The health status of all teams in the organization.",
  },
  TEAMS_SCORECARDS: {
    title: "Teams Scorecards",
    type: widgetTypes.bar,
    description: "The scorecards of all teams in the organization.",
  },
  BEST_PERFORMING_TEAMS: {
    title: "Best Performing Teams",
    type: widgetTypes.table,
    description: "The best performing teams in the organization.",
  },
  WORST_PERFORMING_TEAMS: {
    title: "Worst Performing Teams",
    type: widgetTypes.table,
    description: "The worst performing teams in the organization.",
  },
  COUNT_SERVICES: {
    title: "Services Count",
    type: widgetTypes.count,
    description: "The total number of services in the organization.",
  },
  SERVICES_HEALTH: {
    title: "Services Health",
    type: widgetTypes.donut,
    description: "The health status of all services in the organization.",
  },
  BEST_PERFORMING_SERVICES: {
    title: "Best Performing Services",
    type: widgetTypes.table,
    description: "The best performing services in the organization.",
  },
  WORST_PERFORMING_SERVICES: {
    title: "Worst Performing Services",
    type: widgetTypes.table,
    description: "The worst performing services in the organization.",
  },
  SERVICES_PERFORMANCE: {
    title: "Services Performance",
    type: widgetTypes.radar,
    description: "The performance of all services in the organization.",
  },
  SERVICES_BIRD_EYE: {
    title: "Services Bird Eye",
    type: widgetTypes.heatmap,
    description: "The bird eye view of all services in the organization.",
  },
  COUNT_SCORECARDS: {
    title: "Scorecards Count",
    type: widgetTypes.count,
    description: "The total number of scorecards in the organization.",
  },
  SCORECARDS_HEALTH: {
    title: "Scorecards Health",
    type: widgetTypes.bar,
    description: "The health status of all scorecards in the organization.",
  },
  SCORECARDS_HEALTH_PER_LEVELS: {
    title: "Scorecards Health Per Levels",
    type: widgetTypes.bar,
    description:
      "The health status of all scorecards per levels in the organization.",
  },
  SCORE_TIMELINE: {
    title: "Score Timeline",
    type: widgetTypes.line,
    description: "The timeline of all scores in the organization.",
  },
  COUNT_INITIATIVES: {
    title: "Initiatives Count",
    type: widgetTypes.count,
    description: "The total number of initiatives in the organization.",
  },
  COUNT_CLUSTERS: {
    title: "Clusters Count",
    type: widgetTypes.count,
    description: "The total number of clusters in the organization.",
  },
  COUNT_RULES: {
    title: "Rules Count",
    type: widgetTypes.count,
    description: "The total number of rules in the organization.",
  },
  MOST_PASSING_RULES: {
    title: "Most Passing Rules",
    type: widgetTypes.table,
    description: "The most passing rules in the organization.",
  },
  LEAST_PASSING_RULES: {
    title: "Least Passing Rules",
    type: widgetTypes.table,
    description: "The least passing rules in the organization.",
  },
  WORST_DROP_SERVICES: {
    title: "Worst Drop Services",
    type: widgetTypes.table,
    description: "The worst drop services in the organization.",
  },
  BEST_RISE_SERVICES: {
    title: "Best Rise Services",
    type: widgetTypes.table,
    description: "The best rise services in the organization.",
  },
  WORST_DROP_TEAMS: {
    title: "Worst Drop Teams",
    type: widgetTypes.table,
    description: "The worst drop teams in the organization.",
  },
  BEST_RISE_TEAMS: {
    title: "Best Rise Teams",
    type: widgetTypes.table,
    description: "The best rise teams in the organization.",
  },
  
};

export type DashboardWidgetFilter = {
  service?: string;
  team?: string;
  scorecard?: string;
};
