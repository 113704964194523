import styles from "./TeamSwitcher.module.css";
import { Avatar, Text } from "_sredx/_ui";
import { TeamInfoDto } from "_sredx/types";
import { ChevronDownIcon } from "_sredx/_ui/Icon";
import { capitalizeString } from "_sredx/utils/string";

interface TeamSwitcherTrigger {
  selectedTeam?: TeamInfoDto;
}

export const TeamSwitcherTrigger = ({ selectedTeam }: TeamSwitcherTrigger) => {
  if (!selectedTeam) return null;
  return (
    <div className={styles.trigger_wrapper}>
      <Avatar
        size={20}
        name={selectedTeam.name}
        rectangle
        avatarStyle="identicon"
      />
      <Text className={styles.team_name}>
        {capitalizeString(selectedTeam.name)}
      </Text>
      <div className={styles.arrow}>
        <ChevronDownIcon />
      </div>
    </div>
  );
};
