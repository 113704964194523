import { ConfigParam } from "models/integration/IntegrationTemplate";
import * as yup from "yup";

export const Schema = (configParams: ConfigParam[]) => {
    const fields: any = {};
  
    configParams.forEach((configParam) => {
      if (!configParam.notRequired)
        fields[configParam.key] = yup.string().required();
      else fields[configParam.key] = yup.string();
    });
  
    return yup.object().shape({
      ...fields,
    });
  };
