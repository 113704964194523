import { Avatar, TableCell, TableRow, Text } from "_sredx/_ui";
import { UserDto } from "_sredx/types";
import { formatISODate, resolveStatus } from "_sredx/utils";
import { MouseEventHandler } from "react";
import styles from "./MemberDataTableRow.module.css";

interface MemberDataTableRowProps {
  member: UserDto;
  onRowClick: () => void;
}

export const MemberDataTableRow = ({
  member,
  onRowClick,
}: MemberDataTableRowProps) => {
  const { firstName, lastName, email, createdAt, status, username } = member;

  const fullName = firstName && lastName ? `${firstName} ${lastName}` : (email ?? username);
  const handleOnRowClick: MouseEventHandler<HTMLTableRowElement> = () => {
    onRowClick();
  };
  return (
    <TableRow onClick={handleOnRowClick}>
      <TableCell>
        <div className={styles.col1_wrapper}>
          <Avatar size={20} name={fullName} rectangle avatarStyle="identicon" />
          <Text>{fullName}</Text>
        </div>
      </TableCell>

      <TableCell>
        <Text>{email}</Text>
      </TableCell>
      <TableCell>{resolveStatus(status)}</TableCell>
      <TableCell align={"right"}>
        <Text>{formatISODate(createdAt)}</Text>
      </TableCell>
    </TableRow>
  );
};
