import { forwardRef } from "react";
import { IconProps } from "./types";

export const KubeAppsIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" {...props} ref={ref} viewBox="0 0 112 112">
        <title>{title}</title>
        <path
          fill="#717074"
          d="M57.812 5.005c-28.995 0-52.5 23.505-52.5 52.5s23.505 52.5 52.5 52.5 52.5-23.505 52.5-52.5-23.505-52.5-52.5-52.5zm0 96c-24.024 0-43.5-19.476-43.5-43.5s19.476-43.5 43.5-43.5 43.5 19.476 43.5 43.5h0c0 24.024-19.476 43.5-43.5 43.5z"
        />
        <circle fill="#fff" r="44.284" cy="57.632" cx="58.065" />
        <circle fill="#1d428a" r="28.737" cy="58.035" cx="58.065" />
        <path
          fill="#0091da"
          d="M98.584 56.94l-10.46-3.4c-1.91-13.61-12.79-24.38-26.46-26.1.05-.06.08-.16.05-.26l-3.41-10.46c-.18-.54-.95-.54-1.11 0l-3.41 10.46c-.03.1 0 .21.06.27-5.94.76-11.34 3.24-15.71 6.91l-.09-.06-6.48-3.3c-.36-.18-.74.2-.56.54l3.31 6.51c.01.03.03.05.05.07-3.62 4.3-6.08 9.61-6.89 15.43l-10.43 3.39a.59.59 0 0 0 0 1.13l10.35 3.37c1.64 13.91 12.65 24.99 26.51 26.75l3.29 10.09c.16.56.93.56 1.11 0l3.28-10.08c6.07-.74 11.6-3.28 16.04-7.06l6.17 3.14c.36.18.72-.2.54-.56l-3.14-6.18a30.35 30.35 0 0 0 7.01-16.09l10.38-3.38a.59.59 0 0 0 0-1.13zm-40.78 27.28c-14.57 0-26.41-11.85-26.41-26.41s11.84-26.4 26.41-26.4c14.56 0 26.4 11.84 26.4 26.4s-11.84 26.41-26.4 26.41z"
        />
        <path
          fill="#00c1d5"
          d="M63.618 63.015L30.185 85.089a.67.67 0 0 1-.922-.928l22.278-33.23 33.245-22.255a.67.67 0 0 1 .927.922L63.618 63.015z"
        />
      </svg>
    );
  }
);
