import { endOfDay, format, startOfDay, subDays } from "date-fns";

export const DATE_OPTIONS = {
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  LAST_7DAYS: "Last 7 days",
  LAST_30DAYS: "Last 30 days",
  LAST_90DAYS: "Last 90 days",
  DATE_TO_NOW: "Date to now",
  DATE_RANGE: "Choose a date range",
} as const;

export type DateOption = (typeof DATE_OPTIONS)[keyof typeof DATE_OPTIONS];

export const mapRangeToLabel = (range: [Date, Date]): string => {
  const option = mapRangeToDateOption(range);

  if (option === DATE_OPTIONS.DATE_TO_NOW) {
    return `${format(range[0], "dd/MM/yyyy")} to now`;
  }
  if (option === DATE_OPTIONS.DATE_RANGE) {
    return `${format(range[0], "dd/MM/yyyy")} to ${format(
      range[1],
      "dd/MM/yyyy"
    )}`;
  }

  return option || "Value";
};

export const mapRangeToDateOption = (
  range?: [Date, Date]
): DateOption | null => {
  if (!range) {
    return null;
  }

  const [start, end] = range;
  const now = new Date();
  if (
    startOfDay(now).getTime() === startOfDay(start).getTime() &&
    endOfDay(now).getTime() === endOfDay(end).getTime()
  ) {
    return DATE_OPTIONS.TODAY;
  }
  if (
    startOfDay(subDays(now, 1)).getTime() === startOfDay(start).getTime() &&
    endOfDay(now).getTime() === endOfDay(end).getTime()
  ) {
    return DATE_OPTIONS.YESTERDAY;
  }
  if (
    startOfDay(subDays(now, 7)).getTime() === startOfDay(start).getTime() &&
    endOfDay(now).getTime() === endOfDay(end).getTime()
  ) {
    return DATE_OPTIONS.LAST_7DAYS;
  }
  if (
    startOfDay(subDays(now, 30)).getTime() === startOfDay(start).getTime() &&
    endOfDay(now).getTime() === endOfDay(end).getTime()
  ) {
    return DATE_OPTIONS.LAST_30DAYS;
  }
  if (
    startOfDay(subDays(now, 90)).getTime() === startOfDay(start).getTime() &&
    endOfDay(now).getTime() === endOfDay(end).getTime()
  ) {
    return DATE_OPTIONS.LAST_90DAYS;
  }

  if (endOfDay(now).getTime() === endOfDay(end).getTime()) {
    return DATE_OPTIONS.DATE_TO_NOW;
  }

  return DATE_OPTIONS.DATE_RANGE;
};

export const mapDateOptionToRange = (option: DateOption): [Date, Date] => {
  const now = new Date();
  switch (option) {
    case DATE_OPTIONS.TODAY:
      return [startOfDay(now), now];
    case DATE_OPTIONS.YESTERDAY:
      return [startOfDay(subDays(now, 1)), now];
    case DATE_OPTIONS.LAST_7DAYS:
      return [startOfDay(subDays(now, 7)), now];
    case DATE_OPTIONS.LAST_30DAYS:
      return [startOfDay(subDays(now, 30)), now];
    case DATE_OPTIONS.LAST_90DAYS:
      return [startOfDay(subDays(now, 90)), now];
    default:
      return [now, now];
  }
};
