import { useMutation, useQueryClient } from "react-query";
import { ApiErrorResponse, UpdateInitiativesDto } from "_sredx/types";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";

export const useUpdateInitiative = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, ApiErrorResponse, UpdateInitiativesDto>({
    mutationKey: [MUTATION_KEYS.updateInitiatives],
    mutationFn: (dto) => {
      return ClientApi.patch(API_ENDPOINTS.updateInitiative(dto.id), dto);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.initiatives]);
      await queryClient.invalidateQueries([QUERY_KEYS.initiativeRules]);
      await queryClient.invalidateQueries([QUERY_KEYS.initiativeServices]);
    },
  });
};
