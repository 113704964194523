import { useQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import { ScoreCardEvaluation } from "models/evaluations/ScoreCardEvaluation";

interface useGetLatestEvaluationProps {
  serviceId: string;
}

export const useGetLatestEvaluation = ({
  serviceId,
}: useGetLatestEvaluationProps) => {
  return useQuery<ScoreCardEvaluation[]>({
    queryKey: [QUERY_KEYS.evaluationsLevels, serviceId],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getLatestEvaluation(serviceId));
    },
  });
};
