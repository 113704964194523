import { PullRequestDto } from "_sredx/types";
import {
  MetricChartStats,
  MetricChartStatsWrapper,
} from "_sredx/components/Metrics";
import React from "react";
import { calculatePRWeeklyAvgMergeFrequency } from "_sredx/utils/pullRequests";

interface PRMergeFrequencyStatsProps {
  pullRequests: PullRequestDto[];
}

export const PRMergeFrequencyStats = ({
  pullRequests = [],
}: PRMergeFrequencyStatsProps) => {
  return (
    <MetricChartStatsWrapper>
      <MetricChartStats
        label={"Selected period"}
        value={pullRequests.length}
        unit={"Merged PRs"}
      />
      <MetricChartStats
        label={"Weekly average"}
        value={calculatePRWeeklyAvgMergeFrequency(pullRequests)}
        unit={"Merged PRs"}
      />
    </MetricChartStatsWrapper>
  );
};
