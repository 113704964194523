import FeatureSection from './feature/FeatureSection';
import clsx from "clsx";
import styles from "./FeaturesSection.module.css";
import { FeatureData } from './type';

interface FeaturesSectionProps {
    featureData : FeatureData[];
}

const FeaturesSection = ({featureData}: FeaturesSectionProps) => {
  return (
      <div className={clsx("container", styles.wrapper)}>

      <p className={styles.title}>
        Empower Your Engineering Organization with SREDX,
        Unleash Visibility, Deliver Quality Software!
      </p>

      <div className={styles.content_wrapper}>

      {featureData.map((feature, index) => {
                    return (<FeatureSection
                        key={index}
                        title={feature.title}
                        description={feature.description}
                        illustration={feature.illustration}
                    />)
                })}
      </div>
  </div>
  )
}

export default FeaturesSection