import React from 'react';
import create from 'zustand';

interface ModalProps {
    children?: React.ReactNode;
    actions?: React.ReactNode;
    disableBackdropClick?: boolean;
    title?: string | React.ReactNode;
    onClose?: () => void;
}
interface ModalOptions extends Omit<ModalProps, 'open'> {}

interface OpenModalEvent {
  body: React.ReactNode;
  options?: ModalOptions;
}
interface UpdateOptionsEvent {
  options: ModalOptions;
}

export interface ModalState {
  open: boolean;
  body: React.ReactNode | null;
  options: ModalOptions;
  show: (data: OpenModalEvent) => void;
  updateOptions: (data: UpdateOptionsEvent) => void;
  hide: () => void;
}

export const useModalStore = create<ModalState>((set) => ({
  open: false,
  body: null,
  options: {} as ModalOptions,
  show: ({ body, options }) =>
    set((state) => ({
      open: true,
      body,
      options: { ...state.options, ...options },
    })),
  hide: () => set(() => ({ open: false, body: null, options: {} })),
  updateOptions: ({ options }) =>
    set((state) => ({
      ...state,
      options: {
        ...state.options,
        ...options,
      },
    })),
}));
