interface HashtagIconProps {
  size?: number;
  color?: string;
}
export const HashtagIcon = ({
  size,
  color = "currentColor",
}: HashtagIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.092 1.509a.5.5 0 0 1 .4.583L5.226 3.5H7.71l.299-1.592a.5.5 0 1 1 .982.184L8.727 3.5H10a.5.5 0 0 1 0 1H8.54l-.563 3H9.5a.5.5 0 0 1 0 1H7.79l-.299 1.592a.5.5 0 0 1-.982-.184L6.773 8.5H4.29l-.299 1.592a.5.5 0 0 1-.982-.184L3.273 8.5H2a.5.5 0 0 1 0-1h1.46l.563-3H2.5a.5.5 0 0 1 0-1h1.71l.299-1.592a.5.5 0 0 1 .583-.4ZM5.04 4.5l-.563 3H6.96l.563-3H5.04Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
