import clsx from "clsx";
import { Button, ButtonLabel } from "../Button";
import { Text } from "../Text";
import styles from "./Empty.module.css";

interface EmptyProps {
  message: string;
  actionLabel?: string;
  onActionClick?: () => void;
}

export const Empty = ({ message, onActionClick, actionLabel }: EmptyProps) => {
  return (
    <div className={clsx(styles.wrapper)}>
      <Text>{message}</Text>
      {actionLabel && onActionClick && (
        <Button variant={"neutral"} onClick={onActionClick}>
          <ButtonLabel>{actionLabel}</ButtonLabel>
        </Button>
      )}
    </div>
  );
};
