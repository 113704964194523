import { Button, ButtonLabel, Loader, Text } from "_sredx/_ui";
import {
  OnboardingImportedUsersTable,
  UserChoiceModal,
} from "_sredx/components";
import { useModal } from "_sredx/hooks";
import {
  UserCreationDto,
  useCreateUsers,
  useTeamMember,
  useUpdateUser,
  useMembersDiscovery,
} from "_sredx/services";
import { TeamInfoDto, UserDto, UserProfileDto } from "_sredx/types";
import { AxiosError } from "axios";
import { useToaster } from "hooks/useToaster";
import { useState } from "react";
import { AdminOnboardingStep } from "../AdminOnboardingContainer";
import styles from "./OnboardingUsersImportStepContainer.module.css";
import { getTeamMembers } from "./utils";
import { useNavigate } from "react-router-dom";
import { DuplicatedUsersModal } from "./DuplicatedUsersModal";

interface OnboardingUsersImportStepContainerProps extends AdminOnboardingStep {
  importedTeamsIds: string[];
}

export const OnboardingUsersImportStepContainer = ({
  onPreviousStep,
  importedTeamsIds,
}: OnboardingUsersImportStepContainerProps) => {
  // Hooks
  const { openModal, closeModal } = useModal();
  const { addToast } = useToaster();
  const navigate = useNavigate();

  // States
  const [selectedUsers, setSelectedUsers] = useState<UserProfileDto[]>([]);
  const [invitedUsers, setInvitedUsers] = useState<UserProfileDto[]>([]);

  // Services
  const { data: users, isLoading: isLoadingUsers } = useMembersDiscovery({
    teamsIds: importedTeamsIds,
  });
  const { mutateAsync: saveUsers, isLoading: isLoadingUsersCreation } =
    useCreateUsers();
  const {
    mutateAsync: assignUsersToTeam,
    isLoading: isLoadingMembersAssignment,
  } = useTeamMember();
  const { mutate: updateUser, isLoading: isLoadingProfileUpdate } =
    useUpdateUser();

  // Derived variables
  const selectedUsersEmail = selectedUsers.map((u) => u.email);
  const allTeams = selectedUsers.flatMap((user) => user.teams);
  const teams = Array.from(new Map(allTeams.map((t) => [t.id, t])).values());

  // Event handlers
  const handleOnCheckUser = (checked: boolean, user: UserProfileDto) => {
    if (checked) {
      if (
        selectedUsersEmail.includes(user.email) &&
        !!user.email &&
        !!user.email.length
      ) {
        openModal({
          content: <UserChoiceModal onClose={closeModal} />,
          options: {},
        });
      } else setSelectedUsers([...selectedUsers, user]);
    } else {
      setSelectedUsers(
        selectedUsers.filter(
          (u) => u.email !== user.email || u.username !== user.username
        )
      );
      setInvitedUsers(invitedUsers.filter((u) => u.email !== user.email));
    }
  };

  const handleOnCheckAll = (checked: boolean) => {
    if (checked && users) {
      setSelectedUsers(users);
    } else {
      setSelectedUsers([]);
    }
  };

  const handleOnInviteUser = (user: UserProfileDto, inviteUser: boolean) => {
    if (inviteUser && selectedUsersEmail.includes(user.email)) {
      setInvitedUsers([...invitedUsers, user]);
    } else {
      setInvitedUsers(invitedUsers.filter((u) => u.email !== user.email));
    }
  };

  const saveUsersAndAssignToTeams = async (
    selectedUsers: UserProfileDto[],
    invitedUsers: UserProfileDto[]
  ) => {
    const userCreationDtos = selectedUsers.map((user) => {
      return {
        email: user.email,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        source: user.source,
        inviteUser: invitedUsers.includes(user),
      } as UserCreationDto;
    });

    try {
      await saveUsers(userCreationDtos, {
        onSuccess: (data: any) => {
          assignMembersToTeams(teams, data);
          updateUser(
            { onboarded: true },
            {
              onSuccess: () => {
                navigate("/");
              },
            }
          );
        },
      });
    } catch (error) {
      if (error instanceof AxiosError)
        addToast({
          type: "error",
          message: error.response?.data.message,
        });
    }
  };

  const assignMembersToTeams = (
    teams: TeamInfoDto[],
    savedUsers: UserDto[]
  ) => {
    teams.forEach((team) => {
      const registeredMembersDtos = getTeamMembers(
        selectedUsers,
        team,
        savedUsers
      );
      const membersAffectationDto = {
        id: team.id,
        members: registeredMembersDtos,
      };
      assignUsersToTeam(membersAffectationDto);
    });
  };

  const handleOnNextStep = async () => {
    //verify if there are duplicates in emails :
    const uniqueEmails = new Set<string>();
    const duplicates: string[] = [];

    selectedUsers
      .filter((user) => user.email && !!user.email.length)
      .forEach((user) => {
        if (uniqueEmails.has(user.email)) {
          duplicates.push(user.email);
        } else {
          uniqueEmails.add(user.email);
        }
      });
    if (duplicates.length) {
      openModal({
        content: (
          <DuplicatedUsersModal
            onClose={closeModal}
            duplicatedUsersEmails={duplicates}
          />
        ),
        options: {},
      });
    } else await saveUsersAndAssignToTeams(selectedUsers, invitedUsers);
  };

  const handleOnPreviousStep = () => {
    if (onPreviousStep) {
      onPreviousStep();
    }
  };

  if (isLoadingUsers) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>
          Loading Members... <br /> Please wait, this might take a moment.
        </Text>
      </div>
    );
  }

  if (isLoadingUsersCreation || isLoadingMembersAssignment) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>
          Creating and assigning members... <br /> Please wait, this might take
          a moment.
        </Text>
      </div>
    );
  }

  if (isLoadingProfileUpdate) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>
          Updating profile... <br /> Please wait, this might take a moment.
        </Text>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <OnboardingImportedUsersTable
        importedUsers={users ?? []}
        selectedUsers={selectedUsers}
        invitedUsers={invitedUsers}
        handleOnCheckUser={handleOnCheckUser}
        handleOnInviteUser={handleOnInviteUser}
        onCheckAll={handleOnCheckAll}
      />

      <div className={styles.actions_wrapper}>
        <Button onClick={handleOnPreviousStep} variant="neutral">
          <ButtonLabel>Previous</ButtonLabel>
        </Button>

        <Button onClick={handleOnNextStep}>
          <ButtonLabel>Complete</ButtonLabel>
        </Button>
      </div>
    </div>
  );
};
