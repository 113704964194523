import { ClusterFoundationsList } from "_sredx/components";
import { useGetClusterFoundations } from "_sredx/services";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import styles from "./ClusterDetailsToolsContainer.module.css";

export const ClusterDetailsToolsContainer = () => {
  const { clusterId } = useParams<{ clusterId: string }>();
  const { data: clusterFoundations, isLoading: isLoadingClusterFoundations } =
    useGetClusterFoundations({ clusterId });
  return (
    <div className={clsx(styles.wrapper)}>
      <ClusterFoundationsList
        clusterFoundations={clusterFoundations}
        isLoading={isLoadingClusterFoundations}
      />
    </div>
  );
};
