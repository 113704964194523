import React, { forwardRef } from "react";
import clsx from "clsx";
import styles from "./TableFooterCell.module.css";

export const TableFooterCell = forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, children, align, ...props }, ref) => {
  return (
    <td
      ref={ref}
      align={align}
      className={clsx("nemo-table-footer-cell", styles.wrapper, className)}
      {...props}
    >
      {children}
    </td>
  );
});
