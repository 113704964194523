import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { Label, Text } from "_sredx/_ui";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "_sredx/_ui/Select";
import { FormFieldProps } from "./types";
import styles from "./FormFieldSelect.module.css";
import clsx from "clsx";

export const FormFieldSelect = <FieldsType extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  options,
  variant = "default",
  errorMessage,
  onChange,
  fullWidth = false,
}: FormFieldProps<FieldsType>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <div className={clsx(styles.wrapper, fullWidth && styles.full_width)}>
            {label && <Label>{label}</Label>}
            <Select
              defaultValue={field.value}
              onChange={(value) => {
                field.onChange(value);
                onChange && onChange(value);
              }}
            >
              <SelectTrigger placeholder={placeholder} variant={variant} />
              <SelectContent>
                {options.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {errorMessage && (
              <Text className={styles.error_message}>{errorMessage}</Text>
            )}
          </div>
        );
      }}
    />
  );
};
