import * as Accordion from "@radix-ui/react-accordion";
import { Button, EditIcon, Text, TrashIcon } from "_sredx/_ui";
import { IntegrationInstanceDto } from "_sredx/types";
import IntegrationTemplate from "models/integration/IntegrationTemplate";
import { useEffect, useMemo } from "react";
import styles from "./ConfigParamsContainer.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { IntegrationConfigFormInputs } from "_sredx/components";
import { FormFieldInput } from "_sredx/components/Form";
import { Resolver, useForm } from "react-hook-form";
import { createYupSchema } from "./utils";
import { useModal } from "_sredx/hooks";
import { ConfigParamsConnectionCheckContainer } from "../ConfigParamsConnectionCheckContainer/";

interface ConfigParamsComponentProps {
  currentTemplate: IntegrationTemplate;
  integrationInstance?: IntegrationInstanceDto;
  onChange: (
    instanceKey: string,
    newInstance: boolean,
    formData: { instanceName: string; [key: string]: string },
    defaultValues: { instanceName: string; [key: string]: string }
  ) => void;
  newInstance?: string;
  onDeleteInstance: (key: string) => void;
  resetTrigger?: boolean;
}

export const ConfigParamsContainer = ({
  currentTemplate,
  integrationInstance,
  onChange,
  newInstance,
  onDeleteInstance,
  resetTrigger,
}: ConfigParamsComponentProps) => {
  //derived varibales
  const formDefaultValues = useMemo(() => {
    if (integrationInstance) {
      const formValues: { instanceName: string; [key: string]: string } = {
        ...integrationInstance.config,
        instanceName: integrationInstance.name,
      };
      currentTemplate.configParams?.forEach((configParam) => {
        if (configParam.secret && formValues[configParam.key])
          formValues[configParam.key] =
            "**********" + formValues[configParam.key];

        if (!formValues[configParam.key]) formValues[configParam.key] = "";
      });
      formValues["instanceName"] = integrationInstance.name;
      return formValues;
    } else return { instanceName: "" };
  }, [currentTemplate, integrationInstance]);

  const key = integrationInstance ? integrationInstance.id : newInstance;

  //hooks
  const { control, formState, getValues, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      createYupSchema(currentTemplate.configParams ?? [])
    ) as Resolver<any, any>,
    defaultValues: formDefaultValues,
  });

  const { openModal, closeModal } = useModal();

  //event handlers
  const handleOnChange = () => {
    if (integrationInstance) {
      onChange(integrationInstance.id, false, getValues(), formDefaultValues);
    } else {
      onChange(newInstance!, true, getValues(), { instanceName: "" });
    }
  };

  const handleClickCheckValidation = () => {
    openModal({
      content: (
        <ConfigParamsConnectionCheckContainer
          closeModal={closeModal}
          instanceId={integrationInstance?.id!}
        />
      ),
    });
  };

  useEffect(() => {
    reset(formDefaultValues);
  }, [resetTrigger, formDefaultValues, reset]);

  return (
    <div>
      <Accordion.Item
        className={styles.item}
        value={integrationInstance ? integrationInstance.name! : newInstance!}
      >
        <div className={styles.accordion_header}>
          <Accordion.Trigger className={styles.trigger}>
            <Text className="font-semibold">
              {integrationInstance ? integrationInstance.name : "New Instance"}
            </Text>
            <EditIcon width={16} />
          </Accordion.Trigger>

          <button onClick={() => onDeleteInstance(key!)}>
            <TrashIcon width={24} height={24} />
          </button>
        </div>

        <Accordion.Content className={styles.form_wrapper}>
          <form onChange={handleOnChange} className={styles.form_content}>
            {/* todo : extract this form cause its repeated */}
            <div className={styles.input_wrapper}>
              <Text size="sm">Instance name</Text>
              <FormFieldInput
                className={styles.field}
                control={control}
                name={"instanceName"}
                fullWidth
                errorMessage={
                  formState.errors["instanceName"]?.[
                    "message"
                  ] as unknown as string
                }
              />
            </div>
            <IntegrationConfigFormInputs
              control={control}
              configParams={currentTemplate.configParams ?? []}
              errors={formState.errors}
              onChange={handleOnChange}
            />
          </form>

          {integrationInstance?.id && (
            <div className={styles.actions}>
              <Button onClick={handleClickCheckValidation}>
                Check connection
              </Button>
            </div>
          )}
        </Accordion.Content>
      </Accordion.Item>
    </div>
  );
};
