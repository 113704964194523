import styles from "./InitiativeCreationServices.module.css";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "_sredx/_ui";
import { ServicesList } from "../ServicesList";
import {
  InitiativeCreationService,
  InitiativeCreationServicesAction,
} from "./type";

interface InitiativeCreationServicesProps {
  services: InitiativeCreationService[];
  onAction: (action: InitiativeCreationServicesAction) => void;
}

export const InitiativeCreationServices = ({
  services,
  onAction,
}: InitiativeCreationServicesProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Initiative Services</CardTitle>
        <CardSubtitle>Associate services to this initiative</CardSubtitle>
      </CardHeader>
      <CardBody noPadding>
        <div className={styles.body_wrapper}>
          <ServicesList
            services={services}
            onAction={onAction}
            emptyMessage={"This initiative has no service"}
            withAddAction
          />
        </div>
      </CardBody>
    </Card>
  );
};
