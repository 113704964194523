import {
  CloudAccountsListContainer,
  GitopsAccountsListContainer,
} from "containers/SelfService";

export const SelfserviceSettingsContainer = () => {
  return (
    <div>
      <div className="space-y-4 mt-4">
        <CloudAccountsListContainer />
        <GitopsAccountsListContainer />
      </div>
    </div>
  );
};
