import clsx from "clsx";
import { TextInput } from "components/octo-ui/Forms/TextInput";
import { ClusterCard } from "components/self-service";
import ClusterCardSkeleton from "components/self-service/ClusterCard/ClusterCardSkeleton";
import { useModal } from "hooks/useModal";
import { useToaster } from "hooks/useToaster";
import React, { useEffect, useState } from "react";
import { PiCubeTransparentThin } from "react-icons/pi";
import { useClusters } from "services/self-service/useClusters";
import { useKubeConfig } from "services/self-service/useKubeConfig";
import { Cluster } from "types/selfService";
import { ClusterCreateContainer } from "./ClusterCreateContainer/ClusterCreateContainer";
import styles from "./ClusterListContainer.module.css";

export const ClustersListContainer = () => {
  const [displayedClusters, setDisplayedClusters] = useState<Cluster[]>([]);
  const { data, isFetching, isError } = useClusters();
  const { isClusterDownloading, handleDownload } = useKubeConfig();
  const { addToast } = useToaster();

  useEffect(() => {
    if (data) setDisplayedClusters(data);
    if (isError) {
      addToast({ message: "Error while fetching clusters", type: "error" });
    }
  }, [addToast, data, isError]);

  const { hide, show } = useModal();
  const onClickAddCluster = () => {
    show({
      body: <ClusterCreateContainer onCancel={hide} />,
    });
  };

  const onSearch = (value: string) => {
    if (value.length < 2) {
      setDisplayedClusters(data ?? []);
    } else {
      setDisplayedClusters(
        data?.filter((cluster) => cluster.name.includes(value)) ?? []
      );
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h2 className={styles.title}>Clusters</h2>
        <TextInput
          placeholder="Search"
          onChange={(e: any) => onSearch(e.target.value)}
          className={styles.textInput}
        />
      </div>
      <div
        className={clsx(
          styles.listContainer,
          displayedClusters?.length === 0 && !isFetching ? "" : styles.columns
        )}
      >
        <button
          onClick={onClickAddCluster}
          className={styles.newListItemButton}
        >
          <PiCubeTransparentThin className={styles.icon} />
          Create new cluster
        </button>
        {isFetching && (
          <>
            <ClusterCardSkeleton />
            <ClusterCardSkeleton />
          </>
        )}
        {displayedClusters?.map((cluster, index) => (
          <ClusterCard
            key={index}
            cluster={cluster}
            isDownloading={isClusterDownloading(cluster.name)}
            onDownload={handleDownload}
          />
        ))}
      </div>
    </div>
  );
};
