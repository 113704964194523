import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "./useAxios";
import { SERVICES_QUERY } from "./useServices";
import { ServiceInput } from "../models/service/Service";
import { QUERY_KEYS } from "_sredx/config";

export const useAddServices = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (service: ServiceInput[]) => {
      const { data } = await axios.post(`/services/bulk`, service);
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(SERVICES_QUERY);
      queryClient.invalidateQueries(QUERY_KEYS.services);
    },
  });
};
