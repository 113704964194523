import { forwardRef } from "react";
import { IconProps } from "./types";

export const RouteTableIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill="currentColor"
          d="M19 15v-2H5v2zm0-4V9H5v2zm0-4V5H5v2zM5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21zm14-2v-2H5v2z"
        />
      </svg>
    );
  }
);
