import styles from "./NextLevelsActions.module.css";
import { Card, CardBody } from "_sredx/_ui";
import { CheckIcon } from "_sredx/_ui/Icon";
import { Text } from "_sredx/_ui/Text";
import clsx from "clsx";
import { EvaluationLevelsResultDto, EvaluationRuleDto } from "_sredx/types";
import { convertToElement } from "../Achievements/utils";

interface NextLevelProps {
  evaluation: EvaluationLevelsResultDto;
  onClick?: (serviceId: string) => void;
}

export const NextLevel = ({ evaluation, onClick }: NextLevelProps) => {
  if (!evaluation.nextLevel) {
    return null;
  }

  // Temp --------
  const badgesList = ["/Speed/L2.svg", "/Growth/L2.svg", "/SuperStar/L2.svg"];
  const levelImage =
    "/images/" +
    convertToElement(evaluation.scorecard.scoreCardName, badgesList);
  // --------------

  // Events handlers
  const handleClickNextLevel = () => {
    onClick && onClick(evaluation.service.serviceId);
  };

  return (
    <div onClick={handleClickNextLevel}>
      <Card clickable={!!onClick}>
        <CardBody>
          <div className={styles.body_wrapper}>
            <figure className={styles.level_image}>
              <img src={levelImage} alt="level" />
            </figure>
            <div className={styles.actions_wrapper}>
              <Text className={styles.header}>
                Advance to{" "}
                <span className={styles.level_name}>
                  {evaluation.nextLevel.levelName}
                </span>{" "}
                for{" "}
                <span className={styles.service_name}>
                  {evaluation.service.serviceName}
                </span>
              </Text>
              <RulesList rules={evaluation.nextLevel.rules} />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const RulesList = ({ rules }: { rules: EvaluationRuleDto[] }) => {
  return (
    <div className={styles.actions}>
      {rules.map((rule) => {
        const checkIconClassName = clsx(
          styles.action_icon,
          rule.success && styles.action_icon_success
        );

        return (
          <div className={styles.action} key={rule.id}>
            <div className={checkIconClassName}>
              <CheckIcon />
            </div>
            <Text size={"md"} className={styles.action_text}>
              {rule.ruleName}
            </Text>
          </div>
        );
      })}
    </div>
  );
};
