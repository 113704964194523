import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import { ClusterDetailsFoundation } from "_sredx/types";
import { isUndefined } from "_sredx/utils";
import { useQuery } from "react-query";

interface UseGetClusterFoundationsParams {
  clusterId?: string;
}

export const useGetClusterFoundations = ({
  clusterId,
}: UseGetClusterFoundationsParams) => {
  return useQuery<ClusterDetailsFoundation[], Error>({
    queryKey: [QUERY_KEYS.cluster_foundations, clusterId],
    queryFn: async () =>
      await ClientApi.get(API_ENDPOINTS.getClusterFoundations(clusterId!)),
    enabled: !!clusterId && !isUndefined(clusterId),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
