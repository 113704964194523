import { Button, ButtonLabel, Loader, Text } from "_sredx/_ui";
import { OnboardingActionsCards } from "_sredx/components";
import { useTeamsDiscovery, useCreateServices } from "_sredx/services";
import { ServiceBaseDto, TeamBaseDto } from "_sredx/types";
import { useToaster } from "hooks/useToaster";
import { useState } from "react";
import { AdminOnboardingStep } from "../AdminOnboardingContainer";
import styles from "./OnboardingTeamsImportStepContainer.module.css";
import { UserActions, UserActionsData, UserActionsType } from "./utils";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useUpdateUser } from "_sredx/services";

interface OnboardingTeamsImportStepContainerProps extends AdminOnboardingStep {
  selectedServices: ServiceBaseDto[];
  setImportedTeamsIds: (teamsIds: string[]) => void;
  setNextStep: (stepKey: string) => void;
}

export const OnboardingTeamsImportStepContainer = ({
  onPreviousStep,
  selectedServices,
  setImportedTeamsIds,
  setNextStep,
}: OnboardingTeamsImportStepContainerProps) => {
  // Hooks
  const { addToast } = useToaster();
  const navigate = useNavigate();

  // States
  const [selectedAction, setSelectedAction] = useState<UserActionsType>(
    UserActions.IMPORT_TEAMS_AND_USERS
  );

  // Services
  const { mutateAsync: saveServices, isLoading: isLoadingServiceCreation } =
    useCreateServices();
  const { mutateAsync: importTeams, isLoading: isLoadingTeams } =
    useTeamsDiscovery();
  const { mutate: updateUser, isLoading: isLoadingProfileUpdate } =
    useUpdateUser();

  // Event handlers
  const handleOnPreviousStep = () => {
    if (onPreviousStep) {
      onPreviousStep();
    }
  };

  const handleOnAction = async () => {
    let savedServicesIds: string[] = [];
    try {
      const savedServices = await saveServices(selectedServices);
      savedServicesIds = savedServices.map((service) => service.id);

      switch (selectedAction) {
        case UserActions.IMPORT_TEAMS_AND_USERS:
          await importTeams(
            {
              servicesIds: savedServicesIds,
            },
            {
              onSuccess: (data: TeamBaseDto[]) => {
                const importedTeamsIds = data.map((team) => team.id);
                setImportedTeamsIds(importedTeamsIds);
                setNextStep("USERS_IMPORT_STEP");
              },
            }
          );
          return;
        case UserActions.IMPORT_TEAMS_ONLY:
          await importTeams(
            { servicesIds: savedServicesIds },
            {
              onSuccess: () => {
                updateUser(
                  { onboarded: true },
                  {
                    onSuccess: () => {
                      navigate("/");
                    },
                  }
                );
              },
            }
          );

          return;
        case UserActions.SKIP_IMPORT:
          updateUser(
            { onboarded: true },
            {
              onSuccess: () => {
                navigate("/");
              },
            }
          );
          return;
        default:
          return;
      }
    } catch (error) {
      if (error instanceof AxiosError)
        addToast({
          type: "error",
          message: error.message,
        });
    }
  };

  const handleOnChange = (action: string) => {
    setSelectedAction(action);
  };

  if (isLoadingServiceCreation) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>
          Saving Services... <br /> Please wait, this might take a moment.
        </Text>
      </div>
    );
  }

  if (isLoadingTeams) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>
          Loading Teams... <br /> Please wait, this might take a moment.
        </Text>
      </div>
    );
  }

  if (isLoadingProfileUpdate) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>
          Updating profile... <br /> Please wait, this might take a moment.
        </Text>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <OnboardingActionsCards
        selectedAction={selectedAction}
        onChange={handleOnChange}
        userActions={UserActionsData}
      />

      <div className={styles.actions_wrapper}>
        <Button onClick={handleOnPreviousStep} variant="neutral">
          <ButtonLabel>Previous</ButtonLabel>
        </Button>

        <Button onClick={handleOnAction}>
          <ButtonLabel>Next</ButtonLabel>
        </Button>
      </div>
    </div>
  );
};
