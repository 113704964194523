import { ScoreCardEvaluation } from "../../models/evaluations/ScoreCardEvaluation";
import ServiceScoreCardStats, {
  RulesStatusByCategory,
  RuleStatus,
  ServiceOverviewData,
} from "../../models/evaluations/ServiceStatsByScoreCard";
import ServiceOverview from "../../models/service/ServiceOverview";

// Takes a list of non empty last evaluations made by each scorecard to a single service (1 evaluation per scorecard)
export const getServiceOverviewFromLastEvaluations = (
  lastEvaluationsList?: ScoreCardEvaluation[]
): ServiceOverview => {
  const initialValue: ServiceOverview = {
    passingRules: 0,
    totalEvaluatedRules: 0,
    totalScore: 0,
    possibleScore: 0,
  };
  if (!lastEvaluationsList) return initialValue;

  return lastEvaluationsList.reduce((accumulator, evaluation) => {
    if (!evaluation) return accumulator;
    const { evaluationResult } = evaluation;

    return {
      passingRules:
        accumulator.passingRules +
        evaluationResult.ruleEvaluationResults.filter((re) => re.success)
          .length,
      totalEvaluatedRules:
        accumulator.totalEvaluatedRules +
        evaluationResult.ruleEvaluationResults.length,
      totalScore: accumulator.totalScore + evaluationResult.score,
      possibleScore: accumulator.possibleScore + evaluationResult.totalPossible,
    };
  }, initialValue);
};
export const getServiceStatsFromScoreCardLastEvaluation = (
  serviceLastEvaluation: ScoreCardEvaluation
): ServiceScoreCardStats => {
  return {
    overview: getScoreCardOverviewFromServiceLastEvaluation(
      serviceLastEvaluation
    ),
    rulesStatus: getScoreCardRulesLatestStatusFromServiceLastEvaluation(
      serviceLastEvaluation
    ),
  };
};

/**
 * Get rank and score of a service in a list of evaluations
 * @param scoreCardLastEvaluation list of all services evaluations (evaluation per service)
 * @param serviceId id of one of the given services in scoreCardLastEvaluation
 */
export const getScoreCardOverviewFromServiceLastEvaluation = (
  serviceLastEvaluation: ScoreCardEvaluation
): ServiceOverviewData => {
  const serviceScore = serviceLastEvaluation.evaluationResult.score;

  const totalRules =
    serviceLastEvaluation.evaluationResult.ruleEvaluationResults.length;
  const passingRules =
    serviceLastEvaluation.evaluationResult.ruleEvaluationResults.filter(
      (r) => r.success
    ).length;
  return {
    scoreOnScoreCard: Math.round(
      (100 * serviceScore) /
        serviceLastEvaluation.evaluationResult.totalPossible
    ),
    passingRules: passingRules,
    totalEvaluatedRules: totalRules,
  };
};

//TODO implement getServiceTotalEvaluatedRulesFromScoreCardLastEvaluation
export const getScoreCardRulesLatestStatusFromServiceLastEvaluation = (
  serviceLastEvaluation: ScoreCardEvaluation
): RulesStatusByCategory => {
  const rulesStatusByCategory: RulesStatusByCategory = {};
  serviceLastEvaluation.evaluationResult.ruleEvaluationResults.forEach(
    (result) => {
      const ruleCategory = result.rule.ruleCategory?.categoryName!;
      const ruleStatus: RuleStatus = {
        ruleId: result.rule.id!,
        ruleScore: result.rule.score!,
        ruleExpression: result.rule.ruleExpression ?? result.rule.key!,
        ruleName: result.rule.name!,
        isRuleEvaluationSuccessful: result.success,
        evaluationErrors: result.errors,
        actualValue: result.actualValue?.value,
      };

      if (!rulesStatusByCategory[ruleCategory]) {
        rulesStatusByCategory[ruleCategory] = [ruleStatus];
      } else {
        rulesStatusByCategory[ruleCategory].push(ruleStatus);
      }
    }
  );

  return rulesStatusByCategory;
};
