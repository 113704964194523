import { useCallback } from "react";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Card, CardBody, CardHeader, CardTitle } from "_sredx/_ui";
import { FormFieldInput } from "../Form";
import { schema } from "./utils";
import { InitiativeSchedulingFormData } from "./types";
import styles from "./InitiativeCreationSchedulingForm.module.css";

interface InitiativeCreationSchedulingFormProps {
  defaultValues?: InitiativeSchedulingFormData;
  onChange: (data: InitiativeSchedulingFormData) => void;
}

export const InitiativeCreationSchedulingForm = ({
  defaultValues,
  onChange,
}: InitiativeCreationSchedulingFormProps) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm<InitiativeSchedulingFormData>({
    mode: "onChange",
    resolver: yupResolver(schema) as Resolver<InitiativeSchedulingFormData>,
    defaultValues,
  });

  const handleOnChange = useCallback(() => {
    onChange(getValues());
  }, [onChange, getValues]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>scheduling</CardTitle>
      </CardHeader>

      <CardBody>
        <div className={styles.body_wrapper}>
          <form onChange={handleOnChange}>
            <FormFieldInput
              name={"startDate"}
              control={control}
              label={"start date"}
              placeholder={"Start date"}
              errorMessage={errors.startDate?.message}
              fullWidth
              type={"date"}
            />
            <FormFieldInput
              name={"endDate"}
              control={control}
              label={"end date"}
              placeholder={"End date"}
              errorMessage={errors.endDate?.message}
              fullWidth
              type={"date"}
            />
          </form>
        </div>
      </CardBody>
    </Card>
  );
};
