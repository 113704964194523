import { ReactElement } from "react";
import { useInView } from "react-intersection-observer";
import { Loader } from "_sredx/_ui";
import { SelectPopoverLoader } from "../SelectPopoverLoader";
import { SelectPopoverItem } from "./SelectPopoverItem";
import styles from "./SelectPopoverItems.module.css";

interface SelectPopoverItemsProps<T> {
  items?: T[];
  renderItem: (item: T) => ReactElement;
  onItemClick?: (item: T) => void;
  loadingCaption?: string;
  isLoading?: boolean;
  isFetching?: boolean;
  loadMore?: () => void;
}
export const SelectPopoverItems = <T,>({
  items = [],
  renderItem,
  onItemClick,
  isLoading,
  loadingCaption,
  isFetching,
  loadMore,
}: SelectPopoverItemsProps<T>) => {
  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (inView && loadMore) {
        loadMore();
      }
    },
  });
  const handleOnItemClick = (item: T) => {
    if (onItemClick) {
      onItemClick(item);
    }
  };

  if (isLoading) {
    return <SelectPopoverLoader caption={loadingCaption} />;
  }
  return (
    <div className={styles.wrapper}>
      <ul className={styles.items}>
        {items.map((item, index) => (
          <SelectPopoverItem
            key={index}
            onClick={() => handleOnItemClick(item)}
          >
            {renderItem(item)}
          </SelectPopoverItem>
        ))}
        {isFetching && (
          <div className={styles.fetching_wrapper}>
            <Loader size={"small"} />
          </div>
        )}
        <div ref={ref}></div>
      </ul>
    </div>
  );
};
