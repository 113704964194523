import { useCallback } from "react";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
// import { max } from "d3-array";
import { Point } from "@visx/point";
import { useTooltip, Tooltip, defaultStyles } from "@visx/tooltip";
import { Text } from "@visx/text";
import { Line, LineRadial } from "@visx/shape";
// import useDataFetch from "./utils/useDataFetch";
// import Selector from "./Selector";

const silver = "#d9d9d9";
const degrees = 360;

const y = (d: { key: string; value: number }) => d.value;

const genAngles = (length: number) =>
  [...new Array(length + 1)].map((_, i) => ({
    angle: i * (degrees / length),
  }));

const genPoints = (length: number, radius: number) => {
  const step = (Math.PI * 2) / length;
  return [...new Array(length)].map((_, i) => ({
    x: radius * Math.sin(i * step),
    y: radius * Math.cos(i * step),
  }));
};

function genPolygonPoints<Datum>(
  dataArray: Datum[],
  scale: (n: number) => number,
  getValue: (d: Datum) => number
) {
  const step = (Math.PI * 2) / dataArray.length;
  const points: { x: number; y: number }[] = new Array(dataArray.length).fill({
    x: 0,
    y: 0,
  });
  const pointString: string = new Array(dataArray.length)
    .fill("")
    .reduce((res, _, i) => {
      if (i > dataArray.length) return res;
      const xVal = scale(getValue(dataArray[i])) * Math.sin(i * step);
      const yVal = scale(getValue(dataArray[i])) * Math.cos(i * step);
      points[i] = { x: xVal, y: yVal };
      res += `${xVal},${yVal} `;
      return res;
    }, []);

  return { points, pointString };
}

const defaultMargin = { top: 50, left: 36, right: 16, bottom: 0 };

export type RadarProps = {
  data: { key: string; value: number; metadata?: {} }[];
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  levels?: number;
};

export default ({
  data,
  width,
  height,
  levels = 5,
  margin = defaultMargin,
}: RadarProps) => {
  const polygonColor = "#50BFCE";
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const handleMouseOver = useCallback(
    (coords: any, datum: any) => {
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: datum,
      });
    },
    [showTooltip]
  );

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: "rgba(53,71,125,0.8)",
    color: "white",
    padding: 12,
  };

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const radius = Math.min(xMax, yMax) / 2;

  const radialScale = scaleLinear<number>({
    range: [0, Math.PI * 2],
    domain: [degrees, 0],
  });

  const yScale = scaleLinear<number>({
    range: [0, radius],
    domain: [0, Math.max(...data.map(y))],
  });

  const webs = genAngles(data.length);
  const points = genPoints(data.length, radius);
  const polygonPoints = genPolygonPoints(data, (d) => yScale(d) ?? 0, y);

  const zeroPoint = new Point({ x: 0, y: 0 });
  return width < 10 ? null : (
    <>
      <div className="relative">
        <svg width={width} height={height}>
          <rect fill={"#fff"} width={width} height={height} rx={14} />
          <Group top={height / 2} left={width / 2}>
            {[...new Array(levels)].map((_, i) => (
              <LineRadial
                className="rotate-180"
                key={`web-${i}`}
                data={webs}
                angle={(d) => radialScale(d.angle)}
                // angle={Math.PI}
                radius={((i + 1) * radius) / levels}
                fill="none"
                stroke={silver}
                strokeWidth={2}
                strokeOpacity={0.8}
                strokeLinecap="round"
              />
            ))}
            {data.map((_, i) => (
              <>
                <Line
                  key={`radar-line-${i}`}
                  from={zeroPoint}
                  to={points[i]}
                  stroke={silver}
                />
                <Text
                  textAnchor="middle"
                  verticalAnchor="middle"
                  dx={points[i].x}
                  dy={points[i].y}
                >
                  {data[i].key}
                </Text>
              </>
            ))}
            <polygon
              points={polygonPoints.pointString}
              fill={polygonColor}
              fillOpacity={0.3}
              stroke={polygonColor}
              strokeWidth={3}
            />
            {polygonPoints.points.map((point, i) => {
              return (
                <circle
                  key={`radar-point-${i}`}
                  cx={point.x}
                  cy={point.y}
                  r={4}
                  fill={polygonColor}
                  onMouseOver={() => {
                    handleMouseOver(point, `${data[i].key}: ${data[i].value}%`);
                  }}
                  onMouseOut={hideTooltip}
                />
              );
            })}
          </Group>
        </svg>
        {tooltipOpen && (
          <Tooltip
            key={Math.random()}
            top={tooltipTop! + height / 2}
            left={tooltipLeft! + width / 2}
            style={tooltipStyles}
          >
            <strong>{JSON.stringify(tooltipData)}</strong>
          </Tooltip>
        )}
      </div>
    </>
  );
};
