import clsx from "clsx";
import styles from "./PlannerBodyGrid.module.css";
import { PropsWithChildren } from "react";

interface PlannerBodyGridProps {
  columns: number;
}

export const PlannerBodyGrid = ({
  columns,
  children,
}: PropsWithChildren<PlannerBodyGridProps>) => {
  return (
    <div className={clsx(styles.wrapper, "nemo_planner_grid_wrapper r")}>
      <div
        className={styles.grid_columns}
        style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}
      >
        {Array.from({ length: 12 }, (_, i) => i + 1).map((col) => {
          return <div key={col}></div>;
        })}
      </div>
      <div
        className={styles.grid}
        style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}
      >
        {children}
      </div>
    </div>
  );
};
