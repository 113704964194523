import { Member } from "_sredx/types";
import { isEmail } from "_sredx/utils";

export const isAlreadyAdded = (selectedItems?: Member[], email?: string) => {
  if (!selectedItems) return false;
  return selectedItems.find((item) => item.email === email);
};

export const getNewSelectedMembers = ({
  selectedMembers,
  newSelectedMember,
  inputValue,
}: {
  selectedMembers: Member[];
  newSelectedMember: Member | undefined | null;
  inputValue?: string;
}) => {
  if (newSelectedMember) {
    return [...selectedMembers, newSelectedMember];
  } else {
    if (!isEmail(inputValue) || isAlreadyAdded(selectedMembers, inputValue)) {
      return selectedMembers;
    }
    return [
      ...selectedMembers,
      {
        id: "",
        email: `${inputValue}`,
        firstName: "",
        lastName: "",
      } as Member,
    ];
  }
};
