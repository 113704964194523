import { Input } from "_sredx/_ui";
import { ClusterManagedResourcesDataTable } from "_sredx/components";
import { useGetClusterManagedRessources } from "_sredx/services";
import clsx from "clsx";
import { ChangeEventHandler, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./ClusterDetailsManagedRessourceContainer.module.css";

export const ClusterDetailsManagedRessourceContainer = () => {
  const { clusterId } = useParams<{ clusterId: string }>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { data: managedRessources, isLoading: isLoadingManagedRessources } =
    useGetClusterManagedRessources({ clusterId });

  const displayedResources = useMemo(() => {
    if (!managedRessources) {
      return [];
    }
    return managedRessources.filter(
      (resource) =>
        resource.type.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  }, [searchQuery, managedRessources]);

  const handleOnSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <div className={clsx(styles.wrapper)}>
      <div className={styles.header}>
        <Input
          placeholder="Search resource type"
          value={searchQuery}
          onChange={handleOnSearch}
        />
      </div>
      <ClusterManagedResourcesDataTable
        managedResources={displayedResources}
        isLoading={isLoadingManagedRessources}
      />
    </div>
  );
};
