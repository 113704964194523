import { RulesInsightList } from "_sredx/components/RulesInsightsList";
import { useGetWidgetData } from "_sredx/services";
import { DASHBOARD_WIDGETS } from "_sredx/types";
import React, { useMemo } from "react";
import { RuleInsight } from "../MostPassingRulesContainer/types";

export const LeastPassingRulesContainer = () => {
  const { data, isLoading, isError } = useGetWidgetData<RuleInsight>({
    key: DASHBOARD_WIDGETS.RULES.MOST_PASSING_RULES,
  });

  const sortedRules = useMemo(() => {
    if (!data) return [];
    return data?.rulesInsight.sort(
      (a, b) => a.successRatePercentage - b.successRatePercentage
    );
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <RulesInsightList rules={sortedRules} />;
};
