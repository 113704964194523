import {
  BeakerIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { Text } from "_sredx/_ui";
import { ChevronDownIcon } from "_sredx/_ui/Icon";
import { EvaluationLevelDto1, ScoreCardDto } from "_sredx/types";
import clsx from "clsx";
import Tooltip from "components/octo-ui/Tootlip/Tooltip";
import { useMemo } from "react";
import { convertToElement } from "../Achievements/utils";
import { ServiceScore } from "../ServicesDatatable/ServiceScore";
import styles from "./ScorecardRowAccordionHeader.module.css";

interface ScorecardRowAccordionHeaderProps {
  scorecard: ScoreCardDto;
  evaluation: any;
  isOpen?: boolean;
  level?: EvaluationLevelDto1 | null;
  onEvaluate: (scorecardId: string) => void;
}

export const ScorecardRowAccordionHeader = ({
  scorecard,
  evaluation,
  level,
  isOpen,
  onEvaluate,
}: ScorecardRowAccordionHeaderProps) => {
  const handleEvaluate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    onEvaluate(scorecard.id);
  };

  let rules = { success: 0, fail: 0 };
  const rulesCounts = useMemo(() => {
    if (!evaluation) return rules;
    evaluation?.evaluationResult?.ruleEvaluationResults.forEach((rule: any) => {
      if (rule.success) {
        rules.success++;
      } else {
        rules.fail++;
      }
    });
    return rules;
  }, [evaluation]);

  if (!evaluation) return null;
  return (
    <div className={styles.wrapper}>
      <div className={styles.scorecard_name}>{scorecard.name}</div>
      <div className={styles.score}>
        <ServiceScore
          score={evaluation.evaluationResult.score}
          total={evaluation.evaluationResult.totalPossible}
        />
      </div>
      <div className={styles.key_value}>
        <CheckCircleIcon className={clsx(styles.icon, styles.success)} />
        <Text size="sm" className={styles.key}>
          Passing rules
        </Text>
        <Text>{rulesCounts.success}</Text>
      </div>
      <div className={styles.key_value}>
        <XCircleIcon className={clsx(styles.icon, styles.fail)} />
        <Text size="sm" className={styles.key}>
          Failing rules
        </Text>

        <Text>{rulesCounts.fail}</Text>
      </div>
      <div className={styles.current_level}>
        <div className={styles.key}>Current Level</div>

        {level?.levelName ? (
          <LevelBadge
            className={styles.level}
            level={level.levelName}
            scorecard={scorecard.name}
          />
        ) : (
          "No level"
        )}
      </div>
      <div className={styles.evaluate_btn}>
        <Tooltip content={`Evaluate`}>
          <button onClick={handleEvaluate} className={styles.evaluate_btn_outlined}>
            <div className={styles.evaluate_btn_content}>
              <BeakerIcon className={styles.icon} />
              <Text size="sm">Evaluate</Text>
            </div>
          </button>
        </Tooltip>
      </div>
      <div>
        <ChevronDownIcon
          className={clsx(styles.icon, isOpen ? styles.flip : "")}
        />
      </div>
    </div>
  );
};

const LevelBadge = ({
  scorecard,
  level,
  className,
}: {
  scorecard: string;
  level: string;
  className?: string;
}) => {
  const badgesList = ["/Speed/L1.svg", "/Growth/L1.svg", "/SuperStar/L1.svg"];

  return (
    <div className={className}>
      <img
        src={"/images/" + convertToElement(scorecard, badgesList)}
        alt={level}
      />
      <Text size="sm">{level}</Text>
    </div>
  );
};
