import { useParams } from "react-router-dom";

import { useScoreCardLastEvaluations } from "hooks/evaluations/useScoreCardLastEvaluations";
import { ScoreCardDetailsServices } from "_sredx/components";

export const ScoreCardDetailsServicesContainer = () => {
  // State
  const { scorecardId } = useParams<{ scorecardId: string }>();

  // Services
  const { data: scorecardLastEvaluations, isLoading } =
    useScoreCardLastEvaluations(scorecardId ?? "");

  return (
    <ScoreCardDetailsServices
      isLoading={isLoading}
      scorecardId={scorecardId!}
      scoreCardLastEvaluations={scorecardLastEvaluations ?? []}
    />
  );
};
