import IntegrationTemplate from 'models/integration/IntegrationTemplate';
import { PropsWithChildren } from 'react';
import styles from './ConfigParamsWrapper.module.css';
import { Text } from '_sredx/_ui';

interface ConfigParamsWrapperProps extends PropsWithChildren{
    currentTemplate : IntegrationTemplate;
}

export const ConfigParamsWrapper = ({currentTemplate ,children } : ConfigParamsWrapperProps) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{currentTemplate.name}</h2>
      <p className={styles.description}>{currentTemplate.description}</p>

      <div className={styles.content_wrapper}>
        <Text size='lg'>Integration instances</Text>
      </div>

      {children}
    </div>
  );
}
