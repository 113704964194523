import styles from "./AccountDeleteModal.module.css";

interface AccountDeleteModalProps {
  id: string;
}
const AccountDeleteModal = ({
  id,
}: AccountDeleteModalProps) => {
  return (
    <div>
      <h1 className={styles.title}>Delete {id} account</h1>
      <p>Are you sure you want to proceed ?</p>
    </div>
  );
};

export default AccountDeleteModal;
