import { useCreateDomain } from "_sredx/services";
import { DomainInfoForm, DomainInfoFormData } from "_sredx/components";

interface DomainCreationContainerProps {
  closeModal?: () => void;
}

export const DomainCreationContainer = ({
  closeModal,
}: DomainCreationContainerProps) => {
  // services
  const { mutate: createDomain, isLoading } = useCreateDomain();

  // event handlers
  const handleOnSubmit = (data: DomainInfoFormData) => {
    createDomain({
      name: data.name,
      description: data.description,
    });
    closeModal && closeModal();
  };

  const handleOnCancel = () => {
    closeModal && closeModal();
  };

  return (
    <DomainInfoForm
      onSubmit={handleOnSubmit}
      isLoading={isLoading}
      action={"CREATION"}
      onCancel={handleOnCancel}
    />
  );
};
