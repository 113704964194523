import { format } from "date-fns";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";
import { TooltipChart } from "_sredx/_ui/Tooltip";
import { PRCycleTimeSimpleBar } from "../PRCycleTimeSimpleBar";
import { PRDiffStats } from "../PRDiffStats";
import { PRState } from "../PRState";
import styles from "./PRTooltipChart.module.css";
import { PullRequestDto } from "_sredx/types";

interface PRTooltipChartProps {
  pullRequest: PullRequestDto;
}
export const PRTooltipChart = ({ pullRequest }: PRTooltipChartProps) => {
  return (
    <TooltipChart className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.repo}>{pullRequest.repository}</div>
        <PRDiffStats
          deleted={pullRequest.nbrLineDeleted}
          added={pullRequest.nbrLineAdded}
        />
      </div>
      <div className={styles.title_wrapper}>
        <div className={styles.title}>{pullRequest.title}</div>
        <div className={styles.number}>#{pullRequest.number}</div>
      </div>
      <div className={styles.state_wrapper}>
        <PRState pullRequest={pullRequest} />
      </div>
      <div className={styles.dates_wrapper}>
        <div>
          Opened at:{" "}
          <strong>
            {format(new Date(pullRequest.createdAt), "dd/LL/yyyy HH:mm")}
          </strong>
        </div>
        <div>
          Merged at:{" "}
          <strong>
            {pullRequest.mergedAt &&
              format(new Date(pullRequest.mergedAt), "dd/LL/yyyy HH:mm")}
          </strong>
        </div>
      </div>

      <div className={styles.participants_wrapper}>
        <div className={styles.owner_wrapper}>
          <div className={styles.participant_label}>Owner</div>
          <div className={styles.participant}>
            <img
              className={styles.avatar}
              src={createAvatar(identicon, {
                seed: pullRequest.owner.username,
              }).toDataUriSync()}
              alt="avatar"
            />
            <div className={styles.participant_name}>
              {pullRequest.owner.username}
            </div>
          </div>
        </div>
        <div className={styles.reviewers_wrapper}>
          <div className={styles.participant_label}>Reviewers</div>
          {pullRequest.reviewers.map((reviewer) => (
            <div key={reviewer.username} className={styles.participant}>
              <img
                className={styles.avatar}
                src={createAvatar(identicon, {
                  seed: reviewer.username,
                }).toDataUriSync()}
                alt="avatar"
              />
              <div className={styles.participant_name}>{reviewer.username}</div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.cycleTime_wrapper}>
        <PRCycleTimeSimpleBar pullRequest={pullRequest} />
      </div>
    </TooltipChart>
  );
};
