import { forwardRef } from "react";
import { IconProps } from "./types";

export const RedisIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        viewBox="0 0 32 32"
        {...props}
      >
        <title>{title}</title>
        <g transform="matrix(.848327 0 0 .848327 -7.883573 -9.449691)">
          <use xlinkHref="#B" fill="#a41e11" />
          <path
            d="M45.536 34.95c-2.013 1.05-12.44 5.337-14.66 6.494s-3.453 1.146-5.207.308-12.85-5.32-14.85-6.276-2.04-1.613-.077-2.382l15.332-5.936c2.332-.836 3.14-.867 5.126-.14S43.55 31.87 45.51 32.6s2.037 1.31.024 2.36z"
            fill="#d82c20"
          />
          <use xlinkHref="#B" y={-6.218} fill="#a41e11" />
          <use xlinkHref="#C" fill="#d82c20" />
          <path
            d="M45.536 26.098c-2.013 1.05-12.44 5.337-14.66 6.495s-3.453 1.146-5.207.308-12.85-5.32-14.85-6.276c-1-.478-1.524-.88-1.524-1.26V21.55s14.447-3.145 16.78-3.982 3.14-.867 5.126-.14 13.853 2.868 15.814 3.587v3.76c0 .377-.452.8-1.477 1.324z"
            fill="#a41e11"
          />
          <use xlinkHref="#C" y={-6.449} fill="#d82c20" />
          <g fill="#fff">
            <path d="M29.096 20.712l-1.182-1.965-3.774-.34 2.816-1.016-.845-1.56 2.636 1.03 2.486-.814-.672 1.612 2.534.95-3.268.34zM22.8 24.624l8.74-1.342-2.64 3.872z" />
            <ellipse cx={20.444} rx={4.672} ry={1.811} cy={21.402} />
          </g>
          <path d="M42.132 21.138l-5.17 2.042-.004-4.087z" fill="#7a0c00" />
          <path
            d="M36.963 23.18l-.56.22-5.166-2.042 5.723-2.264z"
            fill="#ad2115"
          />
        </g>
        <defs>
          <path
            id="B"
            d="M45.536 38.764c-2.013 1.05-12.44 5.337-14.66 6.494s-3.453 1.146-5.207.308-12.85-5.32-14.85-6.276c-1-.478-1.524-.88-1.524-1.26v-3.813s14.447-3.145 16.78-3.982 3.14-.867 5.126-.14 13.853 2.868 15.814 3.587v3.76c0 .377-.452.8-1.477 1.324z"
          />
          <path
            id="C"
            d="M45.536 28.733c-2.013 1.05-12.44 5.337-14.66 6.494s-3.453 1.146-5.207.308-12.85-5.32-14.85-6.276-2.04-1.613-.077-2.382l15.332-5.935c2.332-.837 3.14-.867 5.126-.14s12.35 4.853 14.312 5.57 2.037 1.31.024 2.36z"
          />
        </defs>
      </svg>
    );
  }
);
