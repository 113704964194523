import clsx from "clsx";

import styles from "./ProgressBar.module.css";
import { resolveProgressColor } from "./utils";

interface ProgressBarProps {
  percent?: number;
  infinite?: boolean;
  position?: "absolute" | "relative";
  rounded?: boolean;
  color?: string;
  label?: string;
  labelPosition?: "top" | "right";
}

export const ProgressBar = ({
  infinite = true,
  color,
  percent = 0,
  position = "relative",
  rounded = false,
  label,
  labelPosition = "top",
}: ProgressBarProps) => {
  // Derived Variables
  const progressColor = resolveProgressColor(percent);

  return (
    <div
      className={clsx(
        "nemo-progress-bar-wrapper",
        styles.wrapper,
        position === "absolute" && styles.absolute,
        label && labelPosition === "right" && styles.label_right
      )}
    >
      {label && (
        <span
          className={clsx("nemo-progress-bar-label", styles.label)}
          style={{
            color: color || progressColor,
            order: labelPosition === "right" ? 1 : 0,
          }}
        >
          {label}
        </span>
      )}

      <div
        className={clsx(
          "nemo-progress-bar",
          styles.progress_bar,
          infinite && styles.infinite,
          rounded && styles.rounded,
          label && styles.space_top
        )}
      >
        <div
          className={clsx(
            "nemo-progress-bar-content",
            styles.content,
            rounded && styles.rounded
          )}
          style={{
            width: infinite ? "100%" : `${percent}%`,
            backgroundColor: !infinite && !color ? progressColor : color,
          }}
        ></div>
      </div>
    </div>
  );
};
