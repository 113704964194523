import { useQuery } from "react-query";
import { notify } from "utils/toastUtils";
import { useAxios } from "../useAxios";
import { QUERY_KEYS } from "_sredx/config";
import Service from "../../models/service/Service";

interface ServicesFetchingResult {
  data: Service[];
  failedInstances: string[];
}

export const useServiceDiscovery = ({
  externalServiceProviderKey,
}: {
  externalServiceProviderKey: string;
}) => {
  const { axios } = useAxios();
  return useQuery({
    queryFn: () =>
      axios.get(`/services/discovery/${externalServiceProviderKey}`),
    queryKey: [QUERY_KEYS.servicesDiscovery, externalServiceProviderKey],
    enabled: !!externalServiceProviderKey,
    select: (data) => {
      return data.data as ServicesFetchingResult;
    },
    onError: () => {
      notify();
    },
  });
};
