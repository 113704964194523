import { useQuery } from "react-query";
import { UserProfileDto } from "_sredx/types";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";

export const useMe = () => {
  return useQuery<UserProfileDto>({
    queryKey: [QUERY_KEYS.me],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getProfile());
    },
  });
};
