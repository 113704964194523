import clsx from "clsx";
import styles from "./ListItem.module.css";
import { LiHTMLAttributes, PropsWithChildren } from "react";

interface ListItemProps extends LiHTMLAttributes<HTMLLIElement> {
  className?: string;
}

export const ListItem = ({
  children,
  className,
  ...props
}: PropsWithChildren<ListItemProps>) => {
  return (
    <li
      className={clsx("nemo-list-item", styles.wrapper, className)}
      {...props}
    >
      {children}
    </li>
  );
};
