import React from 'react';
import ImgBox from '../img-box/ImgBox';
import { toolsData } from './ToolsData';
import style from "./ToolsStyles.module.css";
import "styles/fonts.css"
import clsx from 'clsx';

export const Tools = ()  => {
  return (
    <div className={style.service_body}>
          <span className={clsx("text_sm", "text_start") }>Tools</span>
          <div className={style.service_teams }>
            {toolsData.map(tool => <ImgBox key={tool} img={tool}/>)}
              <div className={style.service_team }>
                <span  className={"text_sm"}  >+5</span>
              </div>
            </div>
          </div>
  )
}
