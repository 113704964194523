import { PropsWithChildren } from "react";
import styles from "./SelectPopoverSelectedItems.module.css";

export const SelectPopoverSelectedItems = ({ children }: PropsWithChildren) => {
  return <ul className={styles.wrapper}>{children}</ul>;
};

interface SelectPopoverSelectedItemProps {
  onRemove?: () => void;
}
export const SelectPopoverSelectedItem = ({
  onRemove,
  children,
}: PropsWithChildren<SelectPopoverSelectedItemProps>) => {
  return (
    <li className={styles.item}>
      {children}
      {onRemove && (
        <button className={styles.remove_btn} onClick={onRemove}>
          x
        </button>
      )}
    </li>
  );
};
