import { Badge } from "_sredx/_ui";
import { ResourceDto, RESOURCE_TYPES, ResourceMinDto } from "_sredx/types/resources";
import {
  DTrack,
  Datadog,
  Dynatrace,
  Github,
  Gitlab,
  Harbor,
  Jenkins,
  Jira,
  Pivotal,
  SonarQube,
} from "components/IntegrationsConfig/Integrations/IntegrationsLogos";
import { FaDatabase } from "react-icons/fa";
import { GiFlatPlatform } from "react-icons/gi";
import { SiMongodb } from "react-icons/si";

// import { AiFillPri } from "react-icons/ai";
import { PiPuzzlePieceFill } from "react-icons/pi";

import {
  SiBackstage,
  SiFramework,
  // SiOpensuse,
  SiPagerduty,
} from "react-icons/si";

export const resolveStatus = (status?: string) => {
  switch (status) {
    case "ACTIVE":
      return <Badge color="SUCCESS">Active</Badge>;
    case "INACTIVE":
      return <Badge>Inactive</Badge>;
    case "DELETED":
      return <Badge color="DANGER">Deleted</Badge>;
    default:
      return <Badge>Unknown</Badge>;
  }
};

export const resolveIntegration = (integration: string) => {
  switch (integration.toLowerCase()) {
    case INTEGRATIONS_KEYS.GITHUB:
      return {
        id: 1,
        name: "GitHub",
        icon: <Github />,
        key: INTEGRATIONS_KEYS.GITHUB,
      };
    case INTEGRATIONS_KEYS.GITLAB:
      return {
        id: 2,
        name: "GitLab",
        icon: <Gitlab />,
        key: INTEGRATIONS_KEYS.GITLAB,
      };
    case INTEGRATIONS_KEYS.JIRA:
      return {
        id: 3,
        name: "Jira",
        icon: <Jira />,
        key: INTEGRATIONS_KEYS.JIRA,
      };
    case INTEGRATIONS_KEYS.SONAR:
      return {
        id: 4,
        name: "SonarQube",
        icon: <SonarQube />,
        key: INTEGRATIONS_KEYS.SONAR,
      };
    case INTEGRATIONS_KEYS.HARBOR:
      return {
        id: 5,
        name: "Harbor",
        icon: <Harbor />,
        key: INTEGRATIONS_KEYS.HARBOR,
      };

    case INTEGRATIONS_KEYS.DATADOG:
      return {
        id: 6,
        name: "Datadog",
        icon: <Datadog />,
        key: INTEGRATIONS_KEYS.DATADOG,
      };

    case INTEGRATIONS_KEYS.DYNATRACE:
      return {
        id: 7,
        name: "Dynatrace",
        icon: <Dynatrace />,
        key: INTEGRATIONS_KEYS.DYNATRACE,
      };
    case INTEGRATIONS_KEYS.PAGERDUTY:
      return {
        id: 8,
        name: "PagerDuty",
        icon: <SiPagerduty />,
        key: INTEGRATIONS_KEYS.PAGERDUTY,
      };
    case INTEGRATIONS_KEYS.PIVOTAL:
      return {
        id: 9,
        name: "Pivotal",
        icon: <Pivotal />,
        key: INTEGRATIONS_KEYS.PIVOTAL,
      };
    case INTEGRATIONS_KEYS.DEPENDENCYTRACK:
      return {
        id: 10,
        name: "DependencyTrack",
        icon: <DTrack />,
        key: INTEGRATIONS_KEYS.DEPENDENCYTRACK,
      };
    case INTEGRATIONS_KEYS.BACKSTAGE:
      return {
        id: 11,
        name: "Backstage",
        icon: <SiBackstage />,
        key: INTEGRATIONS_KEYS.BACKSTAGE,
      };
    case INTEGRATIONS_KEYS.JENKINS:
      return {
        id: 12,
        name: "Jenkins",
        icon: <Jenkins />,
        key: INTEGRATIONS_KEYS.JENKINS,
      };
    default:
      return {
        id: 0,
        name: "Unknown",
        icon: null,
        key: "",
      };
  }
};

export const INTEGRATIONS_KEYS = {
  GITHUB: "github",
  GITLAB: "gitlab",
  JIRA: "jira",
  SONAR: "sonar",
  HARBOR: "harbor",
  DATADOG: "datadog",
  DYNATRACE: "dynatrace",
  PAGERDUTY: "pagerduty",
  PIVOTAL: "pivotal",
  DEPENDENCYTRACK: "dependency-track",
  BACKSTAGE: "backstage",
  JENKINS: "jenkins",
} as const;
export const resolveInitiativeStatus = (status?: string | null) => {
  const Element = (
    <span style={{ paddingBottom: "2px", display: "inline-block" }}>
      {status && status.toLowerCase()}
    </span>
  );

  switch (status) {
    case "COMPLETED":
      return <Badge color="SUCCESS">{Element}</Badge>;
    case "PLANNED":
      return <Badge color="INFO">{Element}</Badge>;
    case "EXPIRED":
      return <Badge color="DANGER">{Element}</Badge>;
    case "STARTED":
      return <Badge color="WARNING">{Element}</Badge>;
    default:
      return <Badge>unknown</Badge>;
  }
};

export const resolveResourceIcon = (resource: ResourceDto| ResourceMinDto) => {
  switch (resource.product) {
    //TODO add eol products icons
    case "mongodb":
      return <SiMongodb />;
  }

  switch (resource.type) {
    case RESOURCE_TYPES.DATABASE:
      return <FaDatabase />;
    case RESOURCE_TYPES.FRAMEWORK:
      return <SiFramework />;
    case RESOURCE_TYPES.PLATFORM:
      return <GiFlatPlatform />;

    default:
      return <PiPuzzlePieceFill />;
  }
};
