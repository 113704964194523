import { useGetWidgetData } from "_sredx/services";
import { DASHBOARD_WIDGETS, ServicesPerformancesDto } from "_sredx/types";
import { ServicesPerformanceList } from "_sredx/components";

export const WorstPerformingServicesContainer = () => {
  const { data, isLoading, isError } =
    useGetWidgetData<ServicesPerformancesDto>({
      key: DASHBOARD_WIDGETS.SERVICES.WORST_PERFORMING_SERVICES,
    });

  if (!data) {
    return null;
  }

  return (
    <ServicesPerformanceList
      servicesPerformances={data.servicesPerformances}
      isLoading={isLoading}
    />
  );
};
