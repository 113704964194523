import { AdvancedFiltersInputValueProps } from "../types";
import inputStyles from "../Input.module.css";
import { FILTER_OPERATORS } from "../../types";

interface InputNumberProps extends AdvancedFiltersInputValueProps {}

export const InputNumber = ({
  operator,
  value,
  onChange,
}: InputNumberProps) => {
  const handleOnChangeMinMax = (val: [string, string]) => {
    onChange(val);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  if (operator === FILTER_OPERATORS.BETWEEN) {
    const [min, max] = value || ["", ""];

    return (
      <div className={inputStyles.wrapper}>
        <input
          className={inputStyles.input}
          value={min}
          onChange={(e) => handleOnChangeMinMax([e.target.value, max])}
          type="text"
          placeholder={"Min"}
        />
        <span className={inputStyles.to}>and</span>
        <input
          className={inputStyles.input}
          value={max}
          onChange={(e) => handleOnChangeMinMax([min, e.target.value])}
          type="text"
          placeholder={"Max"}
        />
      </div>
    );
  }
  return (
    <>
      <input
        value={typeof value === "string" ? value : ""}
        onChange={handleOnChange}
        className={inputStyles.input}
        type="text"
        placeholder={"Value"}
      />
    </>
  );
};
