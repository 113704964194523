import clsx from "clsx";
import {
  DialogProps as DialogPrimitiveProps,
  Root as DialogPrimitive,
} from "@radix-ui/react-dialog";

export const Dialog = ({ children, ...props }: DialogPrimitiveProps) => {
  return (
    <div className={clsx("nemo-dialog")}>
      <DialogPrimitive {...props}>{children}</DialogPrimitive>
    </div>
  );
};
