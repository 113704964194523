import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ButtonLabel, Card, Heading, LogoSREDXBlack } from "_sredx/_ui";
import { FormFieldInput, FormFieldSelect } from "_sredx/components/Form";
import { InvitationDto } from "_sredx/types";
import { useEffect } from "react";
import { Resolver, useForm } from "react-hook-form";
import { OnBoardingStep, OnBoardingSteps } from "../types";
import { UserProfileFormData } from "./types";
import styles from "./UserProfileStepCard.module.css";
import { getJobTitlesOptions, schema } from "./utils";

interface UserProfileStepCardProps extends OnBoardingStep {
  invitation?: InvitationDto;
  onSubmit: (data: UserProfileFormData) => void;
}

export const UserProfileStepCard = ({
  currentStep,
  onSubmit,
  invitation,
}: UserProfileStepCardProps) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<UserProfileFormData>({
    mode: "onChange",
    resolver: yupResolver(schema) as Resolver<UserProfileFormData>,
    defaultValues: {
      email: invitation?.recipientEmail,
    },
  });

  const handleOnSubmit = (data: UserProfileFormData) => {
    onSubmit(data);
  };
  useEffect(() => {
    if (invitation) {
      reset({ email: invitation.recipientEmail });
    }
  }, [invitation, reset]);
  if (currentStep !== OnBoardingSteps.PROFILE_STEP) {
    return null;
  }

  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.wrapper_header}>
          <LogoSREDXBlack />
        </div>
        <div className={styles.wrapper_content}>
          <Heading level={3}>Set up your profile!</Heading>
          <span>Introduce yourself to the community</span>
        </div>
        <div className={styles.wrapper_body}>
          <form className={styles.form_wrapper}>
            <div className={styles.form_fullname}>
              <div className={styles.form_fullname_wrapper}>
                <FormFieldInput
                  name={"firstName"}
                  control={control}
                  label={"First name"}
                  fullWidth
                  errorMessage={errors.firstName?.message}
                />
              </div>
              <div className={styles.form_fullname_wrapper}>
                <FormFieldInput
                  name={"lastName"}
                  control={control}
                  label={"Last name"}
                  fullWidth
                  errorMessage={errors.lastName?.message}
                />
              </div>
            </div>
            <FormFieldInput
              name={"email"}
              control={control}
              label={"Email"}
              fullWidth
              errorMessage={errors.email?.message}
              disabled={true}
            />
            {/*Form Field Select*/}
            <FormFieldSelect
              label={"Job title"}
              control={control}
              name={"jobTitle"}
              options={getJobTitlesOptions()}
              errorMessage={errors?.jobTitle?.message}
              placeholder={"Select a job Title"}
              variant={"outlined"}
            />
            <FormFieldInput
              name={"password"}
              control={control}
              label={"password"}
              type={"password"}
              fullWidth
              errorMessage={errors.password?.message}
            />
            <FormFieldInput
              name={"passwordConfirmation"}
              control={control}
              label={"Confirm password"}
              fullWidth
              type={"password"}
              errorMessage={errors.passwordConfirmation?.message}
            />
          </form>
        </div>
        <div className={styles.action_wrapper}>
          <Button
            className={styles.action_button}
            size={"large"}
            disabled={!isValid}
            onClick={handleSubmit(handleOnSubmit)}
          >
            <ButtonLabel>Submit</ButtonLabel>
          </Button>
        </div>
      </div>
    </Card>
  );
};
