import clsx from "clsx";
import styles from "./ScoreCardOverview.module.css";
import {
  Loader,
  StatusBar,
  StatusBarLegends,
  StatusBarWrapper,
  Text,
} from "_sredx/_ui";
import { ScoreCardDto } from "_sredx/types";
import { resolveStatuses } from "./utils";
import { StatusBarLoading } from "_sredx/_ui/StatusBar/StatusBarLoading/StatusBarLoading";

interface ScoreCardOverviewProps {
  scoreCard: ScoreCardDto;
  isFetching?: boolean;
}

export const ScoreCardOverview = ({
  scoreCard,
  isFetching,
}: ScoreCardOverviewProps) => {
  const statuses = resolveStatuses(scoreCard.overview);


  if (isFetching) {
    return (
      <div className={styles.loader_wrapper}>
        <StatusBarLoading />
        <Text>Loading evaluations...</Text>
      </div>
    );
  }

  if (!statuses) return null;


  return (
    <div className={clsx(styles.wrapper)}>
      <StatusBarWrapper>
        <StatusBar statuses={statuses} />
        <StatusBarLegends statuses={statuses} />
      </StatusBarWrapper>
    </div>
  );
};
