import React from "react";
import { generateDeclineServicesRank } from "../../../utils/generateServicesForDropedImprovedRank";
import DataTable from "./DataTable";
import NoDataMessage from "./NoDataMessage";
import ServiceScores from "../../../models/leaderboard/ServiceScores";

type DroppedServicesProps = {
  dataServices: ServiceScores[];
};

const DroppedServicesDashboard: React.FC<DroppedServicesProps> = ({
  dataServices,
}) => {
  const rankServices = generateDeclineServicesRank(dataServices)
    .sort((a, b) => b.changePercentage - a.changePercentage)
    .slice(0, 6);

  const headerColumns = ["Name", "Owner", "First & Last Score", "Decline Rate"];
  const dataRows = rankServices.map((data) => [
    data.service.name,
    data.service.owners[0]?.name ?? "-",
    <span
      className="inline-block rounded-full px-4 text-sm font-semibold bg-blue-200 text-blue-700"
      key={data.service.name}
    >
      {data.points[0]}-{data.points[1]}
    </span>,
    <span
      className={`inline-block rounded-full px-4 text-sm font-semibold ${
        data.changePercentage >= 0 ? "bg-green-200 text-green-700" : "bg-red-200 text-red-700"
      }`}
      key={data.service.name + "changePercentage"}
    >
      {data.changePercentage}%
    </span>,
  ]);

  return (
    <div data-testid="droped-services-dashboard" className="flex flex-col">
      {rankServices.length > 0 ? (
        <DataTable headerColumns={headerColumns} dataRows={dataRows} />
      ) : (
        <div className="flex justify-center items-center mt-20">
        <NoDataMessage />
      </div>
      )}
    </div>
  );
};

export default DroppedServicesDashboard;
