import {
  OnBoardingCardSpinner,
  OnBoardingSteps,
  OnBoardingStepsType,
  UserExpiredTokenStep,
  UserLoadingCardStep,
  UserProfileFormData,
  UserProfileStepCard,
  UserWelcomeStep,
} from "_sredx/components";
import { useGetInvitation } from "_sredx/services";
import { useCreateAccount } from "_sredx/services/users";
import { INVITATION_STATUS } from "_sredx/types";
import { decodeToBase64 } from "_sredx/utils/helpers";
import { toAccountCreationDto } from "_sredx/utils/mappers";
import { useToaster } from "hooks/useToaster";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface UserOnBoardingContainerProps {
  token: string | null;
}

export const UserOnBoardingContainer = ({
  token,
}: UserOnBoardingContainerProps) => {
  //state
  const navigate = useNavigate();
  const { addToast } = useToaster();
  const [step, setStep] = useState<OnBoardingStepsType>(
    OnBoardingSteps.WELCOME_STEP
  );
  //query and mutation
  const { data: invitation, isLoading: isLoadingInvitation } = useGetInvitation(
    {
      token: decodeToBase64(token),
    }
  );
  const { mutate: createUser, isLoading: isLoadingUserCreation } =
    useCreateAccount();

  // handlers

  const handleOnNextStep = (nextStep: OnBoardingStepsType) => {
    setStep(nextStep);
  };
  const handleProfileSubmit = (data: UserProfileFormData) => {
    //perform mutation and data resolving
    if (invitation && invitation.id) {
      const userCreationDto = toAccountCreationDto(data, invitation.id);
      createUser(userCreationDto, {
        onSuccess: () => {
          //navigate to the user overview / account page
          navigate("/");
        },
        onError: (e: any) => {
          addToast({
            type: "error",
            message:
              e?.response?.data?.message ||
              e?.message ||
              "Something went wrong",
          });
        },
      });
    }
  };

  //effects

  // water fall

  if (isLoadingUserCreation || isLoadingInvitation) {
    return <OnBoardingCardSpinner />;
  }

  if (invitation && invitation.invitationStatus === INVITATION_STATUS.EXPIRED) {
    return <UserExpiredTokenStep />;
  }

  return (
    <>
      <UserWelcomeStep
        invitation={invitation}
        currentStep={step}
        onNextStep={() => handleOnNextStep(OnBoardingSteps.LOADING_STEP)}
      />
      {/* loading step intermediate step*/}
      <UserLoadingCardStep
        currentStep={step}
        onNextStep={() => handleOnNextStep(OnBoardingSteps.PROFILE_STEP)}
      />
      {/* Profile step ? */}
      <UserProfileStepCard
        invitation={invitation}
        onSubmit={handleProfileSubmit}
        currentStep={step}
      />
    </>
  );
};
