import { useModal } from "../../../hooks/useModal";
import { Modal } from "./Modal";


export const ModalContainer = () => {
  const { open, hide, body, options } = useModal({ consumer: true });
  const { onClose, ...restOptions } = options;
  return (
    <Modal open={open} onClose={onClose || hide} {...restOptions}>
      {body}
    </Modal>
  );
};
