import { useMutation, useQueryClient } from "react-query";
import { CreateGitopsAccountDTO } from "types/selfService";
import { SELF_SERVICE_PATHS, SELF_SERVICE_QUERY_KEYS } from "./paths";
import { useAxios } from "hooks/useAxios";

export const useCreateGitopsAccount = () => {
    const queryClient = useQueryClient();
    const { axios } = useAxios();

    const createGitopsAccount = async (dto: CreateGitopsAccountDTO) => {
        // console.log(JSON.stringify(dto, null, 2)); 
        // await new Promise(resolve => setTimeout(resolve, 1000));  
        // return { data: "Simulated response" };  // Simulated response
        return await axios.post(SELF_SERVICE_PATHS.GITOPS_ACCOUNTS, dto);
    };
    
    return useMutation(createGitopsAccount, {
        onSuccess: () => {
            queryClient.invalidateQueries(SELF_SERVICE_QUERY_KEYS.GITOPS_ACCOUNTS);
        },
    });
};