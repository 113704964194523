import {
  AdvancedFilterConfig,
  AdvancedFilterValue,
  FILTER_OPERATORS,
  FILTER_PROPERTY_TYPES,
  OptionsByKeyResult,
} from "_sredx/components/AdvancedFilters/types";
import {
  ChartPeriodType,
  OPERATORS,
  PULL_REQUESTS_KEYS,
  PullRequestFilterObject,
} from "_sredx/types";
import { useFilterOptionsServices } from "_sredx/hooks";
import { subDays } from "date-fns";
import { getTimeDifference } from "_sredx/utils";

export const FILTER_CATEGORY = {
  PR_METRICS: "Pull request metrics",
  PR_STATS: "Pull request statistics",
  PR_PROPERTIES: "Pull request properties",
  SERVICES: "Services properties",
};
export const FILTER_CONFIG: AdvancedFilterConfig[] = [
  {
    key: PULL_REQUESTS_KEYS.SERVICE_ID,
    label: "Service name",
    description: "The name of the service.",
    type: FILTER_PROPERTY_TYPES.LIST,
    category: FILTER_CATEGORY.SERVICES,
    icon: "pull-request",
  },
  // Pull request properties
  {
    key: PULL_REQUESTS_KEYS.REPOSITORY,
    label: "Repository name",
    description: "The name of repository where the pull request is created.",
    type: FILTER_PROPERTY_TYPES.STRING,
    icon: "pull-request",
    category: FILTER_CATEGORY.PR_PROPERTIES,
  },
  {
    key: PULL_REQUESTS_KEYS.TITLE,
    label: "Title",
    description: "The title of the pull request.",
    type: FILTER_PROPERTY_TYPES.STRING,
    icon: "pull-request",
    category: FILTER_CATEGORY.PR_PROPERTIES,
  },
  {
    key: PULL_REQUESTS_KEYS.STATE,
    label: "State",
    description:
      "The current status of the pull request (e.g., open, closed, merged)",
    type: FILTER_PROPERTY_TYPES.ENUM,
    icon: "pull-request",
    category: FILTER_CATEGORY.PR_PROPERTIES,
  },
  {
    key: PULL_REQUESTS_KEYS.NUMBER,
    label: "Pull request number",
    description: "The number of a pull request",
    type: FILTER_PROPERTY_TYPES.NUMBER,
    icon: "pull-request",
    category: FILTER_CATEGORY.PR_PROPERTIES,
  },
  {
    key: PULL_REQUESTS_KEYS.LABEL,
    label: "Label",
    description: "The tags assigned to the pull request for categorization",
    type: FILTER_PROPERTY_TYPES.STRING,
    icon: "pull-request",
    category: FILTER_CATEGORY.PR_PROPERTIES,
  },
  {
    key: PULL_REQUESTS_KEYS.CREATE_AT,
    label: "Open date",
    description: "The date when a pull request is opened",
    type: FILTER_PROPERTY_TYPES.DATE,
    icon: "calendar",
    category: FILTER_CATEGORY.PR_PROPERTIES,
  },
  {
    key: PULL_REQUESTS_KEYS.MERGED_AT,
    label: "Merge date",
    description: "The date when a pull request is merged",
    type: FILTER_PROPERTY_TYPES.DATE,
    icon: "calendar",
    category: FILTER_CATEGORY.PR_PROPERTIES,
  },
  {
    key: PULL_REQUESTS_KEYS.CLOSED_AT,
    label: "Close date",
    description: "The date when a pull request is closed",
    type: FILTER_PROPERTY_TYPES.DATE,
    icon: "calendar",
    category: FILTER_CATEGORY.PR_PROPERTIES,
  },
  {
    key: PULL_REQUESTS_KEYS.OWNER,
    label: "Owner username",
    description: "The username of the person who created the pull request.",
    type: FILTER_PROPERTY_TYPES.STRING,
    icon: "user",
    category: FILTER_CATEGORY.PR_PROPERTIES,
  },
  {
    key: PULL_REQUESTS_KEYS.REVIEWER,
    label: "Reviewer username",
    description: "The username of the person who reviewed the pull request.",
    type: FILTER_PROPERTY_TYPES.STRING,
    icon: "user",
    category: FILTER_CATEGORY.PR_PROPERTIES,
  },
  // Pull request metrics
  {
    key: PULL_REQUESTS_KEYS.CYCLE_TIME,
    label: "Cycle time",
    description: "The total time from work start to pull request merge.",
    type: FILTER_PROPERTY_TYPES.DURATION,
    icon: "clock",
    category: FILTER_CATEGORY.PR_METRICS,
  },
  {
    key: PULL_REQUESTS_KEYS.TIME_TO_MERGE,
    label: "Time to merge",
    description: "The time from pull request creation to merge.",
    type: FILTER_PROPERTY_TYPES.DURATION,
    icon: "clock",
    category: FILTER_CATEGORY.PR_METRICS,
  },
  {
    key: PULL_REQUESTS_KEYS.TIME_TO_REVIEW,
    label: "Time to review",
    description: "The time from pull request creation to first review.",
    type: FILTER_PROPERTY_TYPES.DURATION,
    icon: "clock",
    category: FILTER_CATEGORY.PR_METRICS,
  },
  {
    key: PULL_REQUESTS_KEYS.CODING_TIME,
    label: "Coding time",
    description:
      "The time spent writing code before the pull request is created.",
    type: FILTER_PROPERTY_TYPES.DURATION,
    icon: "clock",
    category: FILTER_CATEGORY.PR_METRICS,
  },
  {
    key: PULL_REQUESTS_KEYS.PICKUP_TIME,
    label: "Pickup time",
    description:
      "The time from pull request creation to the start of the review.",
    type: FILTER_PROPERTY_TYPES.DURATION,
    icon: "clock",
    category: FILTER_CATEGORY.PR_METRICS,
  },
  {
    key: PULL_REQUESTS_KEYS.REVIEW_TIME,
    label: "Review time",
    description: "The time from the first review comment to approval or merge.",
    type: FILTER_PROPERTY_TYPES.DURATION,
    icon: "clock",
    category: FILTER_CATEGORY.PR_METRICS,
  },
  {
    key: PULL_REQUESTS_KEYS.APPROVAL_TIME,
    label: "Approval time",
    description:
      "The time from the last review comment to pull request approval or merge.",
    type: FILTER_PROPERTY_TYPES.DURATION,
    icon: "clock",
    category: FILTER_CATEGORY.PR_METRICS,
  },

  // Pull request statistics
  {
    key: PULL_REQUESTS_KEYS.NBR_LINE_ADDED,
    label: "Number of added lines",
    description: "The count of lines added in the pull request.",
    type: FILTER_PROPERTY_TYPES.NUMBER,
    icon: "hashtag",
    category: FILTER_CATEGORY.PR_STATS,
  },
  {
    key: PULL_REQUESTS_KEYS.NBR_LINE_DELETED,
    label: "Number of deleted lines",
    description: "The count of lines deleted in the pull request.",
    type: FILTER_PROPERTY_TYPES.NUMBER,
    icon: "hashtag",
    category: FILTER_CATEGORY.PR_STATS,
  },
  {
    key: PULL_REQUESTS_KEYS.NBR_FILE_CHANGED,
    label: "Number of files changed",
    description: "The count of files modified in the pull request.",
    type: FILTER_PROPERTY_TYPES.NUMBER,
    icon: "hashtag",
    category: FILTER_CATEGORY.PR_STATS,
  },
  {
    key: PULL_REQUESTS_KEYS.NBR_COMMITS,
    label: "Number of commits",
    description: "The number of commits in the pull request.",
    type: FILTER_PROPERTY_TYPES.NUMBER,
    icon: "hashtag",
    category: FILTER_CATEGORY.PR_STATS,
  },
  {
    key: PULL_REQUESTS_KEYS.NBR_REVIEWS,
    label: "Number of reviews",
    description: "The number of reviews in the pull request.",
    type: FILTER_PROPERTY_TYPES.NUMBER,
    icon: "hashtag",
    category: FILTER_CATEGORY.PR_STATS,
  },
  {
    key: PULL_REQUESTS_KEYS.NBR_COMMENTS,
    label: "Number of comments",
    description: "The number of comments in the pull request.",
    type: FILTER_PROPERTY_TYPES.NUMBER,
    icon: "hashtag",
    category: FILTER_CATEGORY.PR_STATS,
  },
  {
    key: PULL_REQUESTS_KEYS.NBR_REVIEWERS,
    label: "Number of reviewers",
    description: "The number of reviewers in the pull request.",
    type: FILTER_PROPERTY_TYPES.NUMBER,
    icon: "hashtag",
    category: FILTER_CATEGORY.PR_STATS,
  },
  {
    key: PULL_REQUESTS_KEYS.NBR_PARTICIPANTS,
    label: "Number of participants",
    description: "The number of participants in the pull request.",
    type: FILTER_PROPERTY_TYPES.NUMBER,
    icon: "hashtag",
    category: FILTER_CATEGORY.PR_STATS,
  },
  {
    key: PULL_REQUESTS_KEYS.NBR_REQUESTED_REVIEWERS,
    label: "Number of requested reviewers",
    description: "The number of requested reviewers in the pull request.",
    type: FILTER_PROPERTY_TYPES.NUMBER,
    icon: "hashtag",
    category: FILTER_CATEGORY.PR_STATS,
  },
];

export const getOptionsByKey = (
  key?: string
): ((params?: any) => OptionsByKeyResult) => {
  switch (key) {
    case PULL_REQUESTS_KEYS.SERVICE_ID:
      return useFilterOptionsServices;

    case PULL_REQUESTS_KEYS.STATE:
      return () => ({
        isLoading: false,
        isFetching: false,
        isError: false,
        data: [
          {
            label: "Open",
            value: "open",
          },
          {
            label: "Closed",
            value: "closed",
          },
          {
            label: "Merged",
            value: "merged",
          },
        ],
      });

    default: {
      return () => ({
        isLoading: false,
        isFetching: false,
        isError: false,
        data: [],
      });
    }
  }
};

export const mapFilterValueToPRFilterDto = (
  filters: AdvancedFilterValue[]
): PullRequestFilterObject[] => {
  const prFilters: PullRequestFilterObject[] = [];
  filters.forEach((filter) => {
    if (FILTER_OPERATORS.BETWEEN === filter.operator) {
      const [min, max] = filter.value as [number, number];

      prFilters.push(
        {
          key: filter.key,
          operator: OPERATORS.GTE,
          value: min.toString(),
        },
        {
          key: filter.key,
          operator: OPERATORS.LTE,
          value: max.toString(),
        }
      );
    } else if (FILTER_OPERATORS.FROM === filter.operator) {
      const [from, to] = filter.value as [Date, Date];
      prFilters.push(
        {
          key: filter.key,
          operator: OPERATORS.GTE,
          value: from.toISOString(),
        },
        {
          key: filter.key,
          operator: OPERATORS.LTE,
          value: to.toISOString(),
        }
      );
    } else if (
      FILTER_OPERATORS.IN === filter.operator ||
      FILTER_OPERATORS.NOT_IN === filter.operator
    ) {
      prFilters.push({
        key: filter.key,
        operator: filter.operator,
        value: filter.value
          ? filter.value
              .map((v: { value: string; label: string }) => v.value)
              .join(",")
          : "",
      });
    } else if (FILTER_OPERATORS.AFTER === filter.operator) {
      prFilters.push({
        key: filter.key,
        operator: OPERATORS.GTE,
        value: filter.value,
      });
    } else if (FILTER_OPERATORS.BEFORE === filter.operator) {
      prFilters.push({
        key: filter.key,
        operator: OPERATORS.LTE,
        value: filter.value,
      });
      prFilters.push({
        key: filter.key,
        operator: OPERATORS.GTE,
        value: subDays(filter.value as Date, 90).toISOString(),
      });
    } else if (filter.operator) {
      prFilters.push({
        key: filter.key,
        operator: filter.operator,
        value: filter.value,
      });
    }
  });

  return prFilters;
};

export const filterValueValidator = (
  filters: AdvancedFilterValue[]
): boolean => {
  if (!filters || filters.length === 0) {
    return false;
  }

  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i];
    if (!filter.key || !filter.operator || !filter.value) {
      return false;
    }
    if (
      filter.operator === FILTER_OPERATORS.IN ||
      filter.operator === FILTER_OPERATORS.NOT_IN
    ) {
      if (!filter.value || filter.value.length === 0) {
        return false;
      }
    }
    if (
      filter.operator === FILTER_OPERATORS.BETWEEN ||
      filter.operator === FILTER_OPERATORS.FROM
    ) {
      if (
        !filter.value ||
        filter.value.length !== 2 ||
        !filter.value[0] ||
        !filter.value[1]
      ) {
        return false;
      }
    }
  }

  return true;
};

export const getPeriodFromFilters = (
  filters: AdvancedFilterValue[]
): ChartPeriodType => {
  const periods = filters
    .filter(
      (f) =>
        f.key === PULL_REQUESTS_KEYS.CREATE_AT ||
        f.key === PULL_REQUESTS_KEYS.MERGED_AT ||
        f.key === PULL_REQUESTS_KEYS.CLOSED_AT
    )
    .reduce<{ startDate: Date; endDate: Date }[]>((acc, filter) => {
      if (filter.value) {
        switch (filter.operator) {
          case FILTER_OPERATORS.AFTER:
            acc.push({
              startDate: filter.value as Date,
              endDate: new Date(),
            });
            break;
          case FILTER_OPERATORS.BEFORE:
            acc.push({
              startDate: subDays(filter.value as Date, 90),
              endDate: filter.value as Date,
            });
            break;
          default:
            const [from, to] = filter.value as [Date, Date];
            acc.push({
              startDate: from,
              endDate: to,
            });
        }
      }
      return acc;
    }, []);

  if (periods.length === 0) {
    return {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
    };
  }

  return periods.reduce((minPeriod, currentPeriod) => {
    const diffMin = getTimeDifference(minPeriod.startDate, minPeriod.endDate);
    const diffCurrent = getTimeDifference(
      currentPeriod.startDate,
      currentPeriod.endDate
    );
    return diffCurrent < diffMin ? currentPeriod : minPeriod;
  });
};

export const getFilterByDateKey = (
  filters: AdvancedFilterValue[]
): "createdAt" | "mergedAt" | "closedAt" => {
  const filter = filters.find(
    (f) =>
      f.key === PULL_REQUESTS_KEYS.CREATE_AT ||
      f.key === PULL_REQUESTS_KEYS.MERGED_AT ||
      f.key === PULL_REQUESTS_KEYS.CLOSED_AT
  );
  if (filter && filter.key === PULL_REQUESTS_KEYS.CREATE_AT) {
    return "createdAt";
  }
  if (filter && filter.key === PULL_REQUESTS_KEYS.MERGED_AT) {
    return "mergedAt";
  }
  if (filter && filter.key === PULL_REQUESTS_KEYS.CLOSED_AT) {
    return "closedAt";
  }
  return "createdAt";
};
