import AccountsHeader from "components/self-service/Configuration/AccountsHeader/AccountsHeader";
import ConfigurationAccountsSkeleton from "components/self-service/Configuration/ConfigurationAccountsSkeleton";
import ConfigurationGitopsAccounts from "components/self-service/Configuration/ConfigurationGitopsAccounts/ConfigurationGitopsAccounts";

import styles from "./GitopsAccountsListContainer.module.css";
import { useGitopsAccounts } from "services/self-service/useGitopsAccounts";
import { useModal } from "hooks/useModal";
import GitopsAccountCreateContainer from "../GitopsAccountCreateContainer/GitopsAccountCreateContainer";
import AccountDeleteModal from "../../../../components/self-service/Configuration/AccountDeleteModal/AccountDeleteModal";
import { Button } from "components/octo-ui/Button/Button";
import { useDeleteGitopsAccount } from "services/self-service/useDeleteGitopsAccount";

export const GitopsAccountsListContainer = () => {
  const { data: accounts = [], isLoading } = useGitopsAccounts();
  const deleteMutation = useDeleteGitopsAccount();

  const { show, hide } = useModal();

  const handleAddCloudAccount = () => {
    show({
      body: <GitopsAccountCreateContainer onCancel={hide} />,
    });
  };
  const handleDeleteGitopsAccount = (id: string) => {
    show({
      body: <AccountDeleteModal id={id} />,
      options: {
        actions: (
          <div className="flex gap-4">
            <button onClick={hide}>Cancel</button>
            <Button
              onClick={() => deleteMutation.mutate(id, { onSuccess: hide })}
              variant="danger"
            >
              Delete
            </Button>
          </div>
        ),
      },
    });
  };

  const handleEditGitopsAccount = (id: string) => {
    // later
  };

  if (isLoading) return <ConfigurationAccountsSkeleton />;

  return (
    <div className={styles.root}>
      <AccountsHeader
        title="Gitops accounts"
        description="Manage your github GitOps accounts"
        ctaLabel="add gitops account"
        onCtaClick={handleAddCloudAccount}
      />
      <ConfigurationGitopsAccounts
        onEdit={handleEditGitopsAccount}
        onDelete={handleDeleteGitopsAccount}
        accounts={accounts}
      />
    </div>
  );
};

export default GitopsAccountsListContainer;
