import { PropsWithChildren } from "react";
import clsx from "clsx";

import styles from "./LayoutBody.module.css";

export const LayoutBody = ({ children }: PropsWithChildren) => {
  return (
    <div className={clsx(styles.wrapper)}>
      <div className={clsx("nemo-layout-body", styles.body)}>{children}</div>
    </div>
  );
};
