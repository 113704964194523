import {
  HEADER_USER_ACTIONS_TYPE,
  HeaderUserAction,
  LogoutAction,
} from "_sredx/_ui";

export const HEADER_USER_ACTIONS: HeaderUserAction[] = [
  {
    type: HEADER_USER_ACTIONS_TYPE.LOGOUT,
    component: <LogoutAction />,
  },
];
