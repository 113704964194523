import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import { Cluster } from "_sredx/types";
import { useQuery } from "react-query";

export const useGetClusters = () => {
  return useQuery<Cluster[]>({
    queryKey: [QUERY_KEYS.clusters],
    queryFn: async () => await ClientApi.get(API_ENDPOINTS.getClusters()),
    retry: 5,
    retryDelay: 1000,
  });
};
