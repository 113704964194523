import packageInfo from "../../../../package.json"

const AdminAppFooter = () => {
    const currentYear = new Date().getFullYear();
    const { version } = packageInfo

  return (
    <footer className="flex justify-between bg-white py-4 px-4 sm:px-6 lg:px-8 text-black items center border-t" >
        <div className="text-base" >
            &copy; {currentYear} sredx - <span>v</span>{version} 
        </div>
        <div>
            support@sredx.io
        </div>
    </footer>
  )
}

export default AdminAppFooter