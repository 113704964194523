import { TableCell, TableRow, Text } from "_sredx/_ui";
import { InitiativeBaseDto } from "_sredx/types";
import { formatISODate, resolveInitiativeStatus } from "_sredx/utils";
import styles from "./InitiativesAssignmentTable.module.css";

interface InitiativesAssignmentRowsProps {
  initiatives?: InitiativeBaseDto[];
  onCheckChange: (checked: boolean, initiativeId: string) => void;
  checkedInitiatives?: InitiativeBaseDto[];
}

export const InitiativesAssignmentRow = ({
  initiatives = [],
  onCheckChange,

  checkedInitiatives = [],
}: InitiativesAssignmentRowsProps) => {
  return (
    <>
      {initiatives.map((initiative) => {
        const checked = checkedInitiatives.includes(initiative);
        return (
          <TableRow key={initiative.id}>
            <TableCell>
              <label className={styles.checkbox_wrapper}>
                <input
                  type={"checkbox"}
                  checked={checked}
                  onChange={(e) =>
                    onCheckChange(e.target.checked, initiative.id)
                  }
                />
                <Text>{initiative.name}</Text>
              </label>
            </TableCell>
            <TableCell>
              <Text>{formatISODate(initiative.startDate)}</Text>
            </TableCell>
            <TableCell>
              <Text>{formatISODate(initiative.endDate)}</Text>
            </TableCell>
            <TableCell align={"right"}>
              {resolveInitiativeStatus(initiative.status)}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
