import { StatusType } from "types/selfService";
import {
  ScalewayIcon,
  AwsIcon,
  GcpIcon,
  AzureIcon,
  FluxIcon,
  CertManagerIcon,
  ArgoIcon,
  HelmIcon,
  OpstraceIcon,
  PromotheusIcon,
  GrafanaIcon,
  RedisIcon,
  EnvoyIcon,
  HarborIcon,
  VaultIcon,
  LitmusIcon,
  KubeAppsIcon,
  SonarIcon,
  GithubActionsIcon,
  IstioIcon,
  VeleroIcon,
  KyvernoIcon,
  SealedSecretsIcon,
  KeycloakIcon,
  FeatureFlagIcon,
  OpenCostIcon,
  PostgresqlIcon,
  NginxIcon,
  EksIcon,
  VpcIcon,
  SubnetIcon,
  InternetGatewayIcon,
  SecurityGroupIcon,
  RouteTableIcon,
  KoordinatorIcon,
  ClustersIcon,
  PoolsIcon,
  GithubIcon,
  KustomizeIcon,
} from "_sredx/_ui/Icon";

export const resolveToolUrl = (tool: string) => {
  if (!tool) return null;

  switch (tool.toLowerCase()) {
    case "scaleway":
      return ScalewayIcon;
    case "aws":
      return AwsIcon;
    case "gcp":
      return GcpIcon;
    case "azure":
      return AzureIcon;
    case "flux":
      return FluxIcon;
    case "argo":
      return ArgoIcon;
    case "helm":
      return HelmIcon;
    case "helm-repositories":
      return HelmIcon;
    case "cert-manager":
      return CertManagerIcon;
    case "opstrace":
      return OpstraceIcon;
    case "prometheus":
      return PromotheusIcon;
    case "grafana":
      return GrafanaIcon;
    case "redis":
      return RedisIcon;
    case "envoy":
      return EnvoyIcon;
    case "harbor":
      return HarborIcon;
    case "vault":
      return VaultIcon;
    case "litmus":
      return LitmusIcon;
    case "kubeapps":
      return KubeAppsIcon;
    case "sonar":
      return SonarIcon;
    case "github-actions":
      return GithubActionsIcon;
    case "istio":
      return IstioIcon;
    case "velero":
      return VeleroIcon;
    case "kyverno":
      return KyvernoIcon;
    case "kyverno-reporter":
      return KyvernoIcon;
    case "sealed-secrets":
      return SealedSecretsIcon;
    case "keycloak":
      return KeycloakIcon;
    case "feature-flag":
      return FeatureFlagIcon;
    case "opencost":
      return OpenCostIcon;
    case "postgresql":
      return PostgresqlIcon;
    case "nginx-ingress":
      return NginxIcon;

    // // managed resources
    case "eks":
      return EksIcon;

    case "vpc":
      return VpcIcon;

    case "subnet":
      return SubnetIcon;

    case "internetgateway":
      return InternetGatewayIcon;

    case "securitygroup":
      return SecurityGroupIcon;

    case "routetable":
      return RouteTableIcon;
    case "koordinator":
      return KoordinatorIcon;

    case "clusters":
      return ClustersIcon;

    case "pools":
      return PoolsIcon;

    // // git providers

    case "github":
      return GithubIcon;
    default:
      return KustomizeIcon;
  }
};

export function mapClusterPhaseToStatus(phase: StatusType) {
  switch (phase) {
    case "RUNNING":
      return "success";
    case "PENDING":
      return "warning";
    case "DELETING":
      return "warning";
    case "ERROR":
      return "danger";
    default:
      return "unknown";
  }
}
