import { Heading, PageContent } from "_sredx/_ui";
import { DashboardProvider } from "_sredx/context/DashboardContext";
import { DashboardContainer } from "_sredx/containers/DashboardContainer";
import { useFlags } from "@unleash/proxy-client-react";
import DashboardPage from "pages/admin/dashboard/DashboardPage";

export const DashboardsPage = () => {
  const flags = useFlags();
  if (flags && flags.length > 0) {
    const flag = flags.find((flag) => flag.name === "reporting");
    if (flag && flag.enabled) {
      return (
        <PageContent>
          <DashboardProvider>
            <DashboardContainer />
          </DashboardProvider>
        </PageContent>
      );
    }
  }
  return (
    <PageContent>
      <Heading>Dashboards</Heading>
      <DashboardPage />
    </PageContent>
  );
};
