import ProgressBarSkeleton from "../../shared/ProgressBar/ProgressBarSkeleton";
import SquaredIconSkeleton from "../../shared/SquaredIcon/SquaredIconSkeleton";
import TagLabelSkeleton from "../../shared/TagLabel/TagLabelSkeleton";

const ServiceCardSkeleton:React.FC = () => {
        
    return (
        <div
            className="border-2 border-gray-300" >

        <div className="flex items-center">
            <div className="p-4">
                <div className="h-12 w-12 bg-gray-300 animate-pulse" />
            </div>
            <div className="flex flex-col space-y-1">
                <div className="py-2 h-15 animate-pulse bg-gray-300" />
                <div className="flex gap-1 items-center flex-wrap" >
                    <TagLabelSkeleton />
                    <TagLabelSkeleton />
                    <TagLabelSkeleton />
                </div>
            </div>
        </div>

        <div className="space-y-4 p-4" >
            {/* owners */}
            <div className="space-y-1">
                <div className="py-2 h-15 w-[5rem] animate-pulse bg-gray-300" />
                <div>
                    <SquaredIconSkeleton />
                </div>
            </div>

            {/* tools */}
            <div className="space-y-1" >
                <div className="py-2 h-15 w-[5rem] animate-pulse bg-gray-300" />
                <div className="flex gap-2" >
                
                    <SquaredIconSkeleton />
                    <SquaredIconSkeleton />
                    <SquaredIconSkeleton />
                    <SquaredIconSkeleton />

                </div>
            </div>

            {/* evaluations */}

            {/* average score */}

                <div className="space-y-1" >
                    <div className="flex justify-between" >
                        <div className="py-2 h-15 w-[10rem] animate-pulse bg-gray-300" />
                        <div className="h-2 w-10 animate-pulse" />
                    </div>
                    <div>
                        <ProgressBarSkeleton />
                    </div>
                </div>
            {/* end average score */}

            {/* rules */}

            <div className="space-y-1" >
                <div className="flex justify-between" >
                    <div className="py-2 h-15 w-[10rem] animate-pulse bg-gray-300" />
                    <div className="h-2 w-10 animate-pulse" />
                </div>
                <div className="flex gap-1" >
                    <ProgressBarSkeleton />
                    <ProgressBarSkeleton />
                </div>
                <div className="flex justify-between" >
                    <div className="h-2 w-50 animate-pulse" />
                    <div className="h-2 w-50 animate-pulse" />
                </div>
            </div>
        </div>
        

    </div>
    )
}


export default ServiceCardSkeleton;