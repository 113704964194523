import { Button, ButtonLabel, Card, Heading } from "_sredx/_ui";
import styles from "./OnboardingUsersImportStepContainer.module.css";

interface UserChoiceModalProps {
  onClose: () => void;
  duplicatedUsersEmails: string[];
}

export const DuplicatedUsersModal = ({
  onClose,
  duplicatedUsersEmails,
}: UserChoiceModalProps) => {
  return (
    <Card>
      <div className={styles.modal_wrapper}>
        <Heading level={4}> Duplicated users </Heading>
        <hr />
        <p>
          It seems some users are imported more than once. The duplicated emails
          are :
          {duplicatedUsersEmails.map((email) => (
            <span className={styles.email}>{email}</span>
          ))}
          <br />
          There cannot be two users with the same email so choose one of them
          for each duplication.
        </p>

        <Button className={styles.button} variant="neutral" onClick={onClose}>
          <ButtonLabel>Ok</ButtonLabel>
        </Button>
      </div>
    </Card>
  );
};
