import { Heading, PageContent } from "_sredx/_ui";
import styles from "./ResourcesCreationPage.module.css";
import { ResourcesCreationContainer } from "_sredx/containers/ResourcesCreationContainer";

export const ResourcesCreationPage = () => {
  return (
    <PageContent align={"flex-start"} maxWidth={800} className={styles.wrapper}>
      <Heading>Create resource</Heading>
      
      <ResourcesCreationContainer />
    </PageContent>
  );
};
