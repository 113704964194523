import { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./MetricTabs.module.css";
import { InfoCircleIcon } from "_sredx/_ui/Icon";

interface MetricTabsProps {
  active?: boolean;
  title: string;
  tooltip?: string;
  onClick: () => void;
}

export const MetricTab = ({
  active,
  title,
  tooltip,
  onClick,
}: MetricTabsProps) => {
  return (
    <div
      className={clsx(styles.tab, active && styles.tab_active)}
      onClick={onClick}
    >
      <div className={styles.tab_title}>{title}</div>
      {tooltip && (
        <div className={styles.tab_tooltip}>
          <InfoCircleIcon size={12} />
        </div>
      )}
    </div>
  );
};
export const MetricTabs = ({ children }: PropsWithChildren) => {
  return <div className={styles.tabs_wrapper}>{children}</div>;
};
