import ServiceScores from "models/leaderboard/ServiceScores";
import { ServiceRank } from "./types";

export const generateServicesRanks = (
    servicesScores: ServiceScores[],
    scorecardIds: string[]
  ): ServiceRank[] => {
    const servicesRanks: ServiceRank[] = [];

    servicesScores.forEach((serviceScore) => {
      const service = serviceScore.service;
      const scores = serviceScore.scores.filter(
        (score) =>
          scorecardIds.length === 0 || scorecardIds.includes(score.scorecardId)
      );
      const points = scores.reduce((acc, score) => acc + score.score, 0);
      const percentage = Math.round(
        (100 * points) /
          scores.reduce((acc, score) => acc + score.totalPossible, 0)
      );
      servicesRanks.push({ service, points, percentage });
    });

    // Sort by points and give appropriate rank
    servicesRanks.sort((a, b) => b.points - a.points);

    return servicesRanks;
};