import React from "react";

import styles from "./NextLevelActionsContainer.module.css";
import { useGetEvaluationsLevels } from "_sredx/services";
import { NextLevelsActions } from "_sredx/components";
import { Heading } from "_sredx/_ui";
import { filterEvaluationLevels } from "./utils";
import { useNavigate } from "react-router-dom";
import { useTeamContext } from "_sredx/context";

export const NextLevelActionsContainer = () => {
  // Hooks
  const navigate = useNavigate();
  const { selectedTeam } = useTeamContext();

  const teamId = selectedTeam ? selectedTeam.id : "";

  const { data: evaluationLevelResults } = useGetEvaluationsLevels({
    params: {
      teamIds: [teamId],
    },
  });

  // Derived Variables
  const nextLevelsActions = filterEvaluationLevels(evaluationLevelResults);

  if (nextLevelsActions.length === 0) {
    return null;
  }

  const handleOnNextLevelClicked = (serviceId: string) => {
    navigate(`/services/${serviceId}`);
  };

  return (
    <div className={styles.wrapper}>
      <Heading level={4}>Actions to unlock next levels </Heading>
      <NextLevelsActions
        evaluations={nextLevelsActions}
        onClick={handleOnNextLevelClicked}
      />
    </div>
  );
};
