import {forwardRef} from "react";
import {IconProps} from "./types";

export const PlaylistIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
          <path fill={color} fillRule="evenodd" d="M5.65 5.3 7.916 7 5.65 8.7V5.3ZM4.35 5a1.15 1.15 0 0 1 1.84-.92l2.666 2a1.15 1.15 0 0 1 0 1.84l-2.666 2A1.15 1.15 0 0 1 4.35 9V5Zm7 2a.65.65 0 0 1 .65-.65h7a.65.65 0 1 1 0 1.3h-7a.65.65 0 0 1-.65-.65Zm-7 6a.65.65 0 0 1 .65-.65h14a.65.65 0 1 1 0 1.3H5a.65.65 0 0 1-.65-.65Zm0 6a.65.65 0 0 1 .65-.65h14a.65.65 0 1 1 0 1.3H5a.65.65 0 0 1-.65-.65Z" clipRule="evenodd"/>
      </svg>
    );
  }
);
