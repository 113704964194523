import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import {
  IntegrationInstanceDto,
  IntegrationInstanceSearchingCriteria,
} from "_sredx/types";
import { ConnectivityValidation } from "models/connectivity-test/ConnectivityValidation";
import { useQuery } from "react-query";

interface useGetInstanceValidityProps {
  instanceId: string;
  enabled: boolean;
}
export const useGetInstanceValidity = ({
  instanceId,
  enabled,
}: useGetInstanceValidityProps) => {
  return useQuery<ConnectivityValidation>({
    queryKey: [QUERY_KEYS.connectionTest, instanceId],
    queryFn: async () => {
      return ClientApi.get(
        API_ENDPOINTS.getInstanceValidity(instanceId)
      );
    },
    enabled: enabled,
  });
};
