import { PageContent } from "_sredx/_ui";
import { DomainsListingContainer } from "_sredx/containers";

export const DomainsListingPage = () => {
  return (
    <PageContent>
      <DomainsListingContainer />
    </PageContent>
  );
};
