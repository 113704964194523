import {
  Button,
  Input,
  Loader,
  Pagination,
  ProgressBar,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  Text,
} from "_sredx/_ui";
import styles from "./TeamDetailsServicesContainer.module.css";
import {
  TeamDetailsServiceListHeader,
  TeamDetailsServiceListRow,
} from "_sredx/components/TeamDetailsServices";
import { useModal } from "_sredx/hooks";
import { ServicesAssignmentContainer } from "../ServicesAssignmentContainer";
import { useAssignServices, useGetServices } from "_sredx/services";
import { useParams, useSearchParams } from "react-router-dom";
import { ServiceBaseDto, ServiceSearchCriteria } from "_sredx/types";
import { API_PAGE_SIZE_DEFAULT } from "_sredx/constants";
import { parsePageNumber } from "_sredx/utils";
import { ChangeEventHandler, useMemo, useState } from "react";

const SERVICE_SEARCH_CRITERIA_DEFAULT: ServiceSearchCriteria = {
  pageNumber: 1,
  pageSize: API_PAGE_SIZE_DEFAULT,
};
export const TeamDetailsServicesContainer = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: services,
    isLoading,
    isFetching,
    error,
  } = useGetServices({
    params: {
      ...SERVICE_SEARCH_CRITERIA_DEFAULT,
      teams: teamId ? [teamId] : undefined,
      pageNumber: parsePageNumber(searchParams.get("page")),
      name: searchQuery,
    },
  });

  const displayedServices = useMemo(() => {
    return services?.content ?? [];
  }
  , [services]);

  const { mutate: assignServices } = useAssignServices();

  //loading simulation
  const { openModal, closeModal } = useModal();

  // Derived variables
  const showPagination = services && services.totalPages > 1;

  const handleConfirmAddServices = (services: ServiceBaseDto[]) => {
    assignServices(
      {
        id: teamId ?? "",
        services: services.map((s) => ({
          id: s.id,
        })),
      },
      {
        onSuccess: () => {
          closeModal();
        },
      }
    );
    closeModal();
  };

  const handleAddServices = () => {
    openModal({
      content: (
        <div className={styles.modal_services_wrapper}>
          <ServicesAssignmentContainer
            onCancel={closeModal}
            onConfirm={handleConfirmAddServices}
            selectedServices={services?.content ?? []}
          />
        </div>
      ),
    });
  };
  const handlePageChange = (page: number) => {
    setSearchParams({
      page: (page + 1).toString(),
    });
  };

  const handleOnSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  if (isLoading)
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text>Loading Services...</Text>
      </div>
    );
  if (!services || error)
    return (
      <div className={styles.loader_wrapper}>
        <Text>Oops, an error has occurred while fetching services:</Text>
        {/* <Text>{error.message}</Text> */}
      </div>
    );
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Input
          placeholder="Search services"
          value={searchQuery}
          onChange={handleOnSearch}
        />
        <Button onClick={handleAddServices}>Add services</Button>
      </div>
      <div className={styles.table_wrapper}>
        <Table>
          <TeamDetailsServiceListHeader />
          <TableBody>
            {isFetching && <ProgressBar position={"absolute"} />}

            {displayedServices.map((service) => (
              <TeamDetailsServiceListRow service={service} />
            ))}
          </TableBody>
          <TableFooter>
            <TableFooterCell colSpan={5}>
              <div className={styles.footer}>
                <Text>{services?.totalElements} Services</Text>
                {showPagination && (
                  <Pagination
                    totalPages={services?.totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </TableFooterCell>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
};
