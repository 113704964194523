import clsx from "clsx";
import styles from "./InitiativeDetailsOverview.module.css";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Heading,
  Text,
} from "_sredx/_ui";
import { InitiativeDto } from "_sredx/types";

interface InitiativeDetailsOverviewProps {
  initiative: InitiativeDto;
}

export const InitiativeDetailsOverview = ({
  initiative,
}: InitiativeDetailsOverviewProps) => {
  // Derived variables
  const rulesCount = initiative.ruleIds ? initiative.ruleIds.length : 0;
  const servicesCount = initiative.serviceIds
    ? initiative.serviceIds.length
    : 0;

  return (
    <div className={clsx(styles.wrapper)}>
      <Card>
        <CardHeader>
          <CardTitle>progress</CardTitle>
          <CardSubtitle>overall Initiative progress</CardSubtitle>
        </CardHeader>
        <CardBody>
          <div className={styles.body_wrapper}>
            <Heading>
              {initiative.progress != null ? `${initiative.progress}%` : "-"}
            </Heading>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>rules</CardTitle>
          <CardSubtitle>Total Number of associated rules</CardSubtitle>
        </CardHeader>
        <CardBody>
          <div className={styles.body_wrapper}>
            <Heading>{rulesCount}</Heading>
            <Text>rules</Text>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>services</CardTitle>
          <CardSubtitle>Total Number of Associated services</CardSubtitle>
        </CardHeader>
        <CardBody>
          <div className={styles.body_wrapper}>
            <Heading>{servicesCount}</Heading>
            <Text>services</Text>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
