import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ServiceBaseDto } from "_sredx/types";
import { AxiosError } from "axios";
import { useToaster } from "hooks/useToaster";
import { useQueries } from "react-query";
import { ClientApi } from "../clientApi";

interface useBatchServiceDiscoveryProps {
  externalServiceProviderKeys: string[];
}

interface useBatchServiceDiscoveryReturn {
  data: ServiceBaseDto[];
  failedInstances: string[];
}

export const useBatchServiceDiscovery = ({
  externalServiceProviderKeys,
}: useBatchServiceDiscoveryProps) => {
  const { addToast } = useToaster();

  return useQueries<useBatchServiceDiscoveryReturn[]>(
    externalServiceProviderKeys.map((key) => {
      return {
        queryFn: async () =>
          ClientApi.get(API_ENDPOINTS.getImportedServices(key)),
        queryKey: [QUERY_KEYS.servicesDiscovery, key],
        onError: (error) => {
          if (error instanceof AxiosError) {
            addToast({
              type: "error",
              message: `Error with provider ${key}, ${
                error.response?.data.message ?? ""
              }`,
            });
          }
        },
      };
    })
  );
};
