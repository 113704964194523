import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CloudAccountFormData, mapFormDataToApiData, schema } from './utils'
import CloudAccountForm from 'components/self-service/Configuration/CloudAccountForm/CloudAccountForm'
import { CloudProvider } from 'types/selfService'
import styles from './CloudAccountCreateContainer.module.css';
import { useCreateCloudAccount } from 'services/self-service/useCreateCloudAccount'
import axios from 'axios'
import { Button } from 'components/octo-ui/Button/Button'

interface CloudAccountCreateContainerProps {
    onCancel: () => void
}

const CloudAccountCreateContainer = ({onCancel} : CloudAccountCreateContainerProps) => {
  

    let errorMessage = null;
    const [selectedProvider, setSelectedProvider] = useState<CloudProvider | null>(null);
    const createCloudAccountMutation = useCreateCloudAccount();

    const handleFormSubmit = async (data: CloudAccountFormData) => {
        const apiData = mapFormDataToApiData(data);
        await createCloudAccountMutation.mutateAsync(apiData)
            .then(onCancel)  
            .catch(error => {
                console.error('Error creating cloud account:', error);
            });
    };

    if (createCloudAccountMutation.error && axios.isAxiosError(createCloudAccountMutation.error)) {
        const statusCode = createCloudAccountMutation.error.response?.status;
        errorMessage = `Failed to create cloud account. Server responded with status code ${statusCode}.`;
    }
    
    const handleClickCreate = () => {
        methods.handleSubmit(handleFormSubmit)();
    };

    const methods = useForm({
        mode: "onChange",
        resolver: yupResolver(schema)
    })
  
    return (
    <div className={styles.root} >
        <h1>Create new cloud accound</h1>
        <FormProvider {...methods}>
            <CloudAccountForm
                errors={methods.formState.errors}
                selectedProvider={selectedProvider}
                setSelectedProvider={setSelectedProvider}
            />
        </FormProvider>
        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
        <div className='flex justify-end gap-4 mt-6' >
            <button onClick={onCancel}>Cancel</button>
            <Button onClick={handleClickCreate}>Save</Button>
        </div>
  </div>
  )
}

export default CloudAccountCreateContainer;
