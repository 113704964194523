import React from 'react'
import styles from './DynamicTable.module.css'

  
interface DynamicTableProps {
    /** 
     * Array of column headers for the table.
     * Each string in the array represents a header title for the table's columns.
     */
    columns: string[];

    /** 
     * Array of rows, each row being an array of JSX.Elements representing cells.
     * The outer array represents each row in the table, while the inner array
     * contains JSX.Elements for the contents of each cell in the row.
     */
    data: JSX.Element[][];

    /** 
     * Optional variant for styling the table. 
     * Variants include 'ghost' and 'dark', which could apply different 
     * styles or themes to the table.
     */
    variant?: 'ghost' | 'dark';
}


const DynamicTable = ({data, columns, variant = 'ghost'} : DynamicTableProps ) => {
  return (
    <div className={styles.tableContainer} >
        <table className={styles[variant]} >
            <thead>
                <tr>
                    {columns.map((header, index) => (
                        <th key={index} >{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody className={styles.body_wrapper}>
                {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                    ))}
                </tr>
                ))}
            </tbody>
        </table>
    </div>
  )
}

export default DynamicTable