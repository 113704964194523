import { forwardRef } from "react";
import { IconProps } from "./types";

export const ChevronDownIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          d="M12 14.538 19.538 7 21 8.462l-9 9-9-9L4.462 7 12 14.538Z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
);
