import { EvaluationLevelsResultDto } from "_sredx/types";
import { AchievementDto } from "_sredx/components";

export const getAchievements = (
  evaluationLevelResults?: EvaluationLevelsResultDto[]
): AchievementDto[] => {
  if (!evaluationLevelResults) return [];

  return evaluationLevelResults
    .filter((evaluationLevelDto) => evaluationLevelDto.currentLevel)
    .map((evaluationLevelDto) => {
      return {
        levelId: evaluationLevelDto.currentLevel!.levelId,
        scoreCardName: evaluationLevelDto.scorecard.scoreCardName,
        scoreCardId: evaluationLevelDto.scorecard.scoreCardId,
        serviceName: evaluationLevelDto.service.serviceName,
        levelName: evaluationLevelDto.currentLevel!.levelName,
      };
    });
};
