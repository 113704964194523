import React from 'react';
import style from "./ScorecardSkeletonComponent.module.css";

export const ScorecardSkeletonComponent = () => {
  return (
    <div className={style.wrapper}>
        <div className={style.small_bar}></div>
        <div className={style.bar}></div>
        <div className={style.medium_bar}></div>

        <div className={style.title_section_bar}></div>
        <div className={style.content_wrapper}>
            <div className={style.small_bar}></div>
            <div className={style.xs_bar}></div>
            <div className={style.small_bar}></div>
        </div>

        <div className={style.title_section_bar}></div>
        <div className={style.content_wrapper}>
            <div className={style.circle}></div>
            <div className={style.circle}></div>
            <div className={style.circle}></div>
        </div>

        <div className={style.title_section_bar}></div>
        <div className={style.content_wrapper}>
            <div className={style.metric_wrapper}>
                <div className={style.medium_bar}></div>
                <div className={style.xs_bar}></div>
            </div>
            <div className={style.metric_wrapper}>
                <div className={style.medium_bar}></div>
                <div className={style.xs_bar}></div>
            </div>
            <div className={style.metric_wrapper}>
                <div className={style.medium_bar}></div>
                <div className={style.xs_bar}></div>
            </div>
        </div>
        <span className="sr-only">Loading...</span>

    </div>
  )
}
