import { Thumbnail } from "components/service/ServiceCard/ServiceCard";
import ProgressBar from "componentsv2/shared/ProgressBar/ProgressBar";
import { FC } from "react";
import { getOrdinalSuffixOfPosition } from "utils/formating";
import { getChessIconWithColorsByRank } from "./chessIconUtils";
import { ServiceRank } from "containers/RankedServiceListContainer/types";
import { Link } from "react-router-dom";

type ServiceRowProps = {
  serviceRank: ServiceRank;
  position: number;
};

const ServiceRow: FC<ServiceRowProps> = ({ serviceRank, position }) => {
  const {
    icon,
    bgColor,
    color,
    borderClass = "",
  } = getChessIconWithColorsByRank(position);

  return (
    <Link to={`/services/${serviceRank.service.id}`}>
      <div className="flex items-center justify-between flex-wrap md:flex-nowrap">
        <div className="flex gap-2 w-full md:w-auto">
          {/* chess */}
          <span
            style={{ backgroundColor: bgColor, color: color }}
            className={` h-16 w-16 text-xs flex flex-col justify-center items-center ${borderClass}`}
          >
            {icon}
            {getOrdinalSuffixOfPosition(position)}
          </span>
          <div className="flex flex-col justify-between w-full md:w-auto">
            <div
              role="heading"
              aria-level={2}
              className="font-bold text-sm uppercase"
            >
              {serviceRank.service.name}
            </div>
            {/* teams */}
            <div className="flex items-center gap-2 flex-wrap">
              {serviceRank.service.owners?.length >= 1 ? (
                serviceRank.service.owners
                  .slice(0, 4)
                  .map((owner, i) => (
                    <Thumbnail
                      key={i}
                      name={owner.name}
                      url={`https://api.dicebear.com/7.x/identicon/svg?seed=${owner.name}`}
                      popover
                    />
                  ))
              ) : (
                <span className="text-slate-500 text-xs">No owners</span>
              )}
            </div>
            {/* end teams */}
          </div>
        </div>
        {/* right */}
        <div className="w-full md:w-1/4 mt-2 md:mt-0">
          <div className="flex items-center border px-2 justify-between">
            <div className="w-1/2 bg-slate-200">
              <ProgressBar
                statusTheme={serviceRank.percentage < 50 ? "danger" : "success"}
                percentage={serviceRank.percentage || 0}
              />
            </div>
            <span className="text-gray-700 font-light">
              {serviceRank.percentage || 0} %
            </span>
            <div className="text-gray-300">|</div>
            <div className="text-sm font-light text-gray-700">
              {serviceRank.points} pts
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ServiceRow;
