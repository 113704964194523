import ScoreCardsProgressWidget from "./ScoreCardsProgressWidget/ScoreCardsProgressWidget";
import { LeaderServicesWidget } from "./LeaderServicesWidget/LeaderServicesWidget";
import { useScoreCards } from "hooks/scorcards/useScoreCards";
import { useServices } from "hooks/useServices";
import { useServicesScores } from "hooks/useServicesScores";
import { OverviewComponent } from "components/dashboard/overview/OverviewComponent";
import { useEffect, useRef, useState } from "react";
import { Heatmap } from "components/dashboard/heatmap/Heatmap";
import ServicesPerformanceWidget from "pages/admin/dashboard/ServicesPerformanceWidget/ServicesPerformanceWidget";
import ServicesByRulesMatrix from "./ServicesByRulesMatrix/ServicesByRulesMatrix";
import { DashboardLayout } from "components/Layout/DashboardLayout/DashboardLayout";
import { Card } from "componentsv2/shared/Card/Card";

const DashboardPage = () => {
  const { isLoading: isLoadingScoreCards, data: scoreCardsData } =
    useScoreCards(false);
  const { isLoading: isLoadingServices, data: servicesData } = useServices();
  const { isLoading: isLoadingServicesScores,data: servicesScoresData, isError : servicesScoresError } =
    useServicesScores();

  const scoreCards = scoreCardsData ?? [];
  const services = servicesData ?? [];
  const servicesScores = servicesScoresData ?? [];

  const ref = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>(1200);

  const getCurrentWidth = () => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  };


  useEffect(() => {
    getCurrentWidth();
    window.addEventListener("resize", getCurrentWidth);
    return () => {
      window.removeEventListener("resize", getCurrentWidth);
    }
  }, []);



  return (

    <DashboardLayout >
      <OverviewComponent/>
      <div className="mx-auto py-1">
        <div className="py-2 space-y-2">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-2">
            <Card className="col-span-2">
              <div
                ref={ref}
                className="mt-2 flex items-center justify-center m-4 "
              >
                <Heatmap
                  width={width - 10}
                  servicesScores={servicesScores}
                  isLoadingServicesScores={isLoadingServicesScores}
                  isErrorServicesScores={servicesScoresError}
                />
              </div>
            </Card>
            <Card>
              <LeaderServicesWidget
                servicesScores={servicesScores}
                isLoadingServicesScores={isLoadingServicesScores}
              />
            </Card>
          </div>
          <Card>
            <ServicesByRulesMatrix
              scoreCards={scoreCards}
              services={services}
              isLoadingScoreCards={isLoadingScoreCards}
              isLoadingServices={isLoadingServices}
            />
          </Card>
          <Card>
            <ServicesPerformanceWidget
              scoreCards={scoreCards}
              isLoadingScoreCards={isLoadingScoreCards}
            />
          </Card>
          <Card>
            <ScoreCardsProgressWidget
              scoreCards={scoreCards}
              services={services}
              isLoadingScoreCards={isLoadingScoreCards}
              isLoadingServices={isLoadingServices}
            />
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashboardPage;
