import {
  Card,
  Empty,
  Loader,
  Pagination,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_sredx/_ui";
import { TeamBaseDto } from "_sredx/types";
import { TeamDataTableRow } from "../TeamDataTableRow";
import styles from "./TeamDataTable.module.css";
import { ProgressBar } from "_sredx/_ui/ProgressBar/ProgressBar";

interface TeamDataTableProps {
  teams?: TeamBaseDto[];
  isLoading: boolean;
  isFetching: boolean;
  totalPages?: number;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  onAddTeamClick: () => void;
  onTeamRowClick: (teamId: string) => void;
}

// TODO rename const
const headerCells = ["Team", "Creation date ", "Members"];

export const TeamDataTable = ({
  teams = [],
  isLoading,
  isFetching,
  onAddTeamClick,
  totalPages = 1,
  currentPage = 0,
  onPageChange,
  onTeamRowClick,
}: TeamDataTableProps) => {
  // Events handlers
  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };

  const showPagination = totalPages > 1;

  if (isLoading) {
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading teams...</Text>
        </div>
      </Card>
    );
  }
  if (!isLoading && teams && teams.length === 0) {
    return (
      <Card>
        <Empty
          message={"No teams found for your organization."}
          actionLabel={"Add new team"}
          onActionClick={onAddTeamClick}
        />
      </Card>
    );
  }
  return (
    <Table>
      {/* Header*/}
      <TableHeader>
        {headerCells.map((cell) => (
          <TableHead className={styles.header_cell} key={cell}>
            {cell}
          </TableHead>
        ))}
      </TableHeader>

      {/* Body */}
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {teams.map((team) => (
          <TeamDataTableRow
            onTeamRowClick={onTeamRowClick}
            key={team.id}
            team={team}
          />
        ))}
      </TableBody>

      {/* Footer */}
      <TableFooter>
        <TableFooterCell colSpan={headerCells.length}>
          <div className={styles.footer}>
            <Text>{teams.length} Teams</Text>
            {showPagination && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handleOnPageChange}
                currentPage={currentPage}
              />
            )}
          </div>
        </TableFooterCell>
      </TableFooter>
    </Table>
  );
};
