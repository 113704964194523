import { statusColors } from "../../../colors";

import { statusTheme } from "../TagLabel/TagLabelTypes";

export interface ProgressBarProps {
  statusTheme: statusTheme;
  percentage: number;
  className?: string;
}


const ProgressBar: React.FC<ProgressBarProps> = ({
  statusTheme,
  percentage,
  className = "",
}) => {
  return (
    <div
      role="progressbar"
      className={`${className} h-2.5`}
      style={{
        width: `${percentage}%`,
        background: `${statusColors[statusTheme]}`,
      }}
      aria-label={statusTheme}
    ></div>
  );
};

export default ProgressBar;
