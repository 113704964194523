import { forwardRef } from "react";
import { IconProps } from "./types";

export const ClustersIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        {...props}
        ref={ref}
      >
        <title>Clusters</title>
        <path
          fill="currentColor"
          d="m21.3 4.9l-4.5-2.7c-.3-.1-.5-.2-.8-.2s-.5.1-.7.2l-4.5 2.7c-.5.3-.8.8-.8 1.3v5.6c0 .5.3 1 .7 1.3l4.5 2.7c.2.1.5.2.7.2s.5-.1.7-.2l4.5-2.7c.4-.3.7-.7.7-1.3V6.2c.2-.5-.1-1-.5-1.3M20 11.5l-4 2.4l-4-2.4v-5l4-2.4l4 2.4zm9.3 7.4l-4.5-2.7c-.3-.1-.5-.2-.8-.2s-.5.1-.7.2l-4.5 2.7c-.4.3-.7.7-.7 1.3v5.6c0 .5.3 1 .7 1.3l4.5 2.7c.2.1.5.2.7.2s.5-.1.7-.2l4.5-2.7c.4-.3.7-.7.7-1.3v-5.6c.1-.5-.2-1-.6-1.3M28 25.5l-4 2.4l-4-2.4v-5l4-2.4l4 2.4zm-14.7-6.6l-4.5-2.7c-.3-.1-.5-.2-.8-.2s-.5.1-.7.2l-4.5 2.7c-.5.3-.8.8-.8 1.3v5.6c0 .5.3 1 .7 1.3l4.5 2.7c.3.1.5.2.8.2s.5-.1.7-.2l4.5-2.7c.4-.3.7-.7.7-1.3v-5.6c.1-.5-.2-1-.6-1.3M12 25.5l-4 2.4l-4-2.4v-5l4-2.4l4 2.4z"
        />
      </svg>
    );
  }
);
