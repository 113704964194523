import { DefaultSwitcher } from "_sredx/components/Switcher";
import { DashboardInfoDto } from "_sredx/types/dashboards";
import { capitalizeString } from "_sredx/utils/string";

interface DashboardSwitcherProps {
  selectedDashboard: DashboardInfoDto;
  dashboards: DashboardInfoDto[];
  onSelectDashboard: (option: DashboardInfoDto) => void;
}
export const DashboardSwitcher = ({
  selectedDashboard,
  dashboards,
  onSelectDashboard,
}: DashboardSwitcherProps) => {
  const options = dashboards.map((dashboard) => ({
    label: capitalizeString(dashboard.name),
    value: dashboard.id,
  }));
  const handleSelect = (option: { label: string; value: string }) => {
    const dashboard = dashboards.find((d) => d.id === option.value);
    if (dashboard) {
      onSelectDashboard(dashboard);
    }
  };

  return (
    <DefaultSwitcher
      selected={{
        label: selectedDashboard?.name,
        value: selectedDashboard?.id,
      }}
      options={options}
      onSelect={handleSelect}
    />
  );
};
