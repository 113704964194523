import { RoadmapCalendar } from "_sredx/components/RoadmapCalendar";
import { Navigate, useParams } from "react-router-dom";
import {
  useGetInitiatives,
  useGetRoadmap,
  useUpdateInitiatives,
} from "_sredx/services";
import { useModal } from "_sredx/hooks";
import { RoadmapInitiativesAssignmentContainer } from "_sredx/containers";
import { InitiativeBaseDto } from "_sredx/types";
import styles from "./RoadmapDetailContainer.module.css";
import { Loader } from "_sredx/_ui";
import { buildInitiativesUpdateDtoList } from "../RoadmapCreationContainer/utils";

export const RoadmapDetailContainer = () => {
  // Hooks
  const { roadmapId } = useParams<{ roadmapId: string }>();
  const { openModal, closeModal } = useModal();

  // Data fetching

  const { data: roadmapData, isLoading: isRoadmapLoading } = useGetRoadmap({
    roadmapId,
  });

  const {
    data: initiativesData,
    isLoading: isInitiativesLoading,
    isFetching: isInitiativesFetching,
  } = useGetInitiatives({
    params: {
      roadmapIds: roadmapId ? [roadmapId] : undefined,
      evaluated: true,
    },
  });

  const { mutate: updateInitiativesRoadmap } = useUpdateInitiatives();

  // Derived data
  const initiatives = initiativesData && initiativesData.content;

  // Event handlers

  const handleOnCancelAddInitiatives = () => {
    closeModal();
  };
  const HandleOnSubmitAddInitiatives = (initiatives: InitiativeBaseDto[]) => {
    roadmapId &&
      updateInitiativesRoadmap(
        buildInitiativesUpdateDtoList(initiatives, roadmapId)
      );
    closeModal();
  };
  const handleOnAddInitiative = () => {
    openModal({
      content: (
        <div className={styles.modal_initiative_wrapper}>
          <RoadmapInitiativesAssignmentContainer
            onCancel={handleOnCancelAddInitiatives}
            onConfirm={HandleOnSubmitAddInitiatives}
            selectedInitiatives={[]}
          />
        </div>
      ),
    });
  };

  if (isRoadmapLoading) {
    return (
      <div className={styles.loading_wrapper}>
        <Loader />
      </div>
    );
  }

  if (!isRoadmapLoading && !roadmapData) {
    return <Navigate to="/roadmaps" />;
  }

  return (
    <RoadmapCalendar
      roadmap={roadmapData}
      initiatives={initiatives}
      isLoading={isInitiativesLoading || isInitiativesFetching}
      onAddInitiative={handleOnAddInitiative}
    />
  );
};
