import DonutCard from "_sredx/components/DonutCard/DonutCard";
import { useGetWidgetData } from "_sredx/services";
import { DonutData } from "_sredx/types";
import { DASHBOARD_WIDGETS } from "_sredx/types/dashboards";

const TeamHealthContainer = () => {
  const { data, isLoading, isError } = useGetWidgetData<{ data: DonutData[] }>({
    key: DASHBOARD_WIDGETS.TEAMS.TEAMS_HEALTH,
  });

  if (isError) return null;
  if (!data) return null;
  return <DonutCard data={data.data} isLoading={isLoading} />;
};

export default TeamHealthContainer;
