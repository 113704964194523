import { Loader } from "_sredx/_ui";
import { useGetWidgetData } from "_sredx/services";
import { useEffect, useState } from "react";

interface CountContainerProps {
  widgetKey: string;
  title: string;
}

export const CountContainer = ({ widgetKey, title }: CountContainerProps) => {
  const { data, isLoading } = useGetWidgetData<{ count: string }>({
    key: widgetKey,
  });
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!data) return;
    setCount(parseInt(data.count));
    return;
  }, [data]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        containerType: "size",
      }}
    >
      {!!count ? (
        <>
          <h1
            style={{
              fontSize: "40cqh",
              fontWeight: "bold",
              margin: "0",
            }}
          >
            {count}
          </h1>
          <h1
            style={{
              fontSize: "15cqw",
              fontWeight: "bold",
              margin: "0",
            }}
          >
            {title}
          </h1>
          {/* <Heading level={3}>{title}</Heading> */}
        </>
      ) : (
        <Loader size="small" />
      )}
    </div>
  );
};
