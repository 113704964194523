import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ApiErrorResponse } from "_sredx/types";
import { ResourceCreationDto } from "_sredx/types/resources";
import { useMutation, useQueryClient } from "react-query";
import { ClientApi } from "../clientApi";

export const useCreateResource = () => {
  const queryClient = useQueryClient();
  return useMutation<
    ResourceCreationDto,
    ApiErrorResponse,
    ResourceCreationDto
  >({
    mutationKey: [MUTATION_KEYS.createResource],
    mutationFn: (resource: ResourceCreationDto) => {
      return ClientApi.post(API_ENDPOINTS.createResource(), resource);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.resources], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.services], {
        exact: false,
      });
    },
  });
};
