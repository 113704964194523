import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import styles from "./ScorecardAccordionRuleRow.module.css";
import { Accordion, AccordionContent, AccordionHeader, Text } from "_sredx/_ui";
import clsx from "clsx";
import { ChevronDownIcon } from "_sredx/_ui/Icon";

export const ScorecardAccordionRuleRow = ({ rule }: any) => {
  return (
    <Accordion>
      {({ isOpen, toggle }) => (
        <>
          <AccordionHeader toggle={toggle}>
            <div className={styles.wrapper}>
              <div className={styles.rule_name}>
                {rule.success ? (
                  <CheckCircleIcon
                    className={clsx(styles.icon, styles.success)}
                  />
                ) : (
                  <XCircleIcon className={clsx(styles.icon, styles.fail)} />
                )}
                {rule.rule.name}
              </div>
              <div className={styles.end_col}>
                <div className={styles.points}>
                  <Text size="sm">{rule.rule.score} pts</Text>
                </div>

                <div className={styles.toggle}>
                  <ChevronDownIcon
                    className={clsx(styles.icon, isOpen && styles.open)}
                  />
                </div>
              </div>
            </div>
          </AccordionHeader>
          <AccordionContent isOpen={isOpen} className={styles.content_wrapper}>
            {rule.errors && rule.errors.length > 0 && (
              <div className={styles.errors_section}>
                <ul>
                  {rule.errors.map((error: string, i: number) => (
                    <li key={i}>
                      <Text size="xs">{error}</Text>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div>
              <div className={styles.description}>
                <Text>{rule.rule.description}</Text>
              </div>
            </div>
            <div className={styles.expressions_wrapper}>
              <div className={styles.left_section}>
                <Text size="sm">Rule expression</Text>
                <div className={styles.code_block}>
                  <Text size="xs">{rule.rule.ruleExpression}</Text>
                </div>
              </div>
              <div className={styles.right_section}>
                <Text size="sm">Actual value</Text>
                <div className={styles.code_block}>
                  <Text size="xs">{rule.actualValue?.value ?? "N/A"}</Text>
                </div>
              </div>
            </div>
          </AccordionContent>
        </>
      )}
    </Accordion>
  );
};
