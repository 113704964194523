import { PropsWithChildren } from "react";
import clsx from "clsx";
import { Text } from "_sredx/_ui";
import styles from "./MenuItemLabel.module.css";

export const MenuItemLabel = ({ children }: PropsWithChildren) => {
  return (
    <Text className={clsx("menu-item-label", styles.wrapper)}>{children}</Text>
  );
};
