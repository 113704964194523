export const getHeaderCells = (isTeamsSelected: boolean) => {
  const baseHeaders = [
    "Initiative",
    "Status",
    "Rules",
    "Start date",
    "End date",
  ];
  const teamsHeaders = [
    "Team Progress",
    "Global Progress",
    "Team Services",
    "All Services",
  ];
  const nonTeamsHeaders = ["Progress", "Services"];

  return isTeamsSelected
    ? [...baseHeaders.slice(0, 2), ...teamsHeaders, ...baseHeaders.slice(2)]
    : [...baseHeaders.slice(0, 2), ...nonTeamsHeaders, ...baseHeaders.slice(2)];
};
