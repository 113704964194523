import Level from "models/scorecard/Level";


export type ExtendedLevel = Level & { imageFile: File }

export const extractLevelsMedia = (levels?: ExtendedLevel[]): FormData[] => {
    if (!levels) {
        return []
    }
    return levels.filter((level) => level.imageFile && level.id)
        .map((level) => {
            const formData = new FormData();
            formData.append('ownerId', level.id!);
            formData.append('image', level.imageFile as File);
            formData.append('ownerType', 'level');
            return formData;
        });
};

export const extractLevelsId = (levels?: Level[]): string[] => {
    if (!levels) {
        return []
    }
    return levels.map(({id}) => id!)
}
