import {
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
  Checkbox,
} from "_sredx/_ui";
import { UserProfileDto } from "_sredx/types";
import { OnboardingImportedUserRow } from "../OnboardingImportedUserRow";
import styles from "./OnboardingImportedUsersTable.module.css";
import { useEffect, useRef } from "react";

interface OnboardingImportedUsersTableProps {
  importedUsers: UserProfileDto[];
  selectedUsers: UserProfileDto[];
  invitedUsers: UserProfileDto[];
  handleOnCheckUser: (checked: boolean, user: UserProfileDto) => void;
  handleOnInviteUser: (user: UserProfileDto, inviteUser: boolean) => void;
  onCheckAll: (checkAll: boolean) => void;
}

export const OnboardingImportedUsersTable = ({
  importedUsers,
  selectedUsers,
  invitedUsers,
  handleOnCheckUser,
  handleOnInviteUser,
  onCheckAll,
}: OnboardingImportedUsersTableProps) => {
  const cRef = useRef<HTMLInputElement>(null);

  const checkAll = selectedUsers.length === importedUsers.length;
  const checkNone = !selectedUsers.length;

  const indeterminate = !checkAll && !checkNone;

  useEffect(() => {
    if (cRef.current) cRef.current.indeterminate = indeterminate;
  }, [cRef, indeterminate]);

  return (
    <div className={styles.wrapper}>
      <Table>
        <TableHeader>
          <TableHead>
            <Checkbox
              ref={cRef}
              checked={checkAll}
              onCheck={onCheckAll}
              label={"User"}
            />
          </TableHead>
          <TableHead>
            <Text>Email</Text>
          </TableHead>

          <TableHead align={"left"}>
            <Text>Teams</Text>
          </TableHead>

          <TableHead align={"right"}>
            <Text>Invited</Text>
          </TableHead>
        </TableHeader>

        <TableBody>
          {importedUsers.map((user) => (
            <OnboardingImportedUserRow
              key={user.email ?? user.username}
              user={user}
              selectedUsers={selectedUsers}
              onCheckChange={handleOnCheckUser}
              onInviteUser={handleOnInviteUser}
              invitedUsers={invitedUsers}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableFooterCell colSpan={4}>
            <div className={styles.footer}>
              <Text>
                {selectedUsers.length} Selected - {importedUsers.length}{" "}
                Users
              </Text>
            </div>
          </TableFooterCell>
        </TableFooter>
      </Table>
    </div>
  );
};
