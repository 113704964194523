import { useQuery } from "react-query";
import { RoadmapBaseDto } from "_sredx/types";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";

interface UseGetRoadmapProps {
  roadmapId?: string;
}
export const useGetRoadmap = ({ roadmapId }: UseGetRoadmapProps) => {
  return useQuery<RoadmapBaseDto>({
    queryKey: [QUERY_KEYS.roadmap, roadmapId],
    queryFn: () => {
      if (roadmapId) {
        return ClientApi.get(API_ENDPOINTS.getRoadmap(roadmapId));
      }
      return Promise.reject();
    },
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: roadmapId !== undefined,
  });
};
