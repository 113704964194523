import { Heading, Loader } from "_sredx/_ui";
import { TeamsDropRiseList } from "_sredx/components/ServicesDropRiseList/TeamsDropRiseList";
import { useGetWidgetData } from "_sredx/services";
import { DASHBOARD_WIDGETS } from "_sredx/types";
import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "_sredx/_ui/Select";

interface WorstDropTeamsContainerProps {
  sort?: "DESC" | "ASC";
}

export const WorstDropTeamsContainer = ({
  sort = "DESC",
}: WorstDropTeamsContainerProps) => {
  const [period, setPeriod] = useState<string>("WEEK");
  const { data, isLoading, isError } = useGetWidgetData<TeamsDropRiseData>({
    key: DASHBOARD_WIDGETS.TEAMS.WORST_DROP_TEAMS,
    filters: { period },
  });

  return (
    <div>
      <div className="sticky top-0 bg-white flex items-center justify-between p-4">
        <Heading level={3}>
          {sort == "DESC" ? "Worst Drop" : "Best Rise"} Teams
        </Heading>

        <Select onChange={setPeriod} defaultValue={"WEEK"}>
          <SelectTrigger placeholder={"Select period"} />
          <SelectContent>
            <SelectItem value={"DAY"}>Last 24 hours</SelectItem>
            <SelectItem value={"WEEK"}>Last week</SelectItem>
            <SelectItem value={"MONTH"}>Last month</SelectItem>
            <SelectItem value={"QUARTER"}>Last 3 months</SelectItem>
            <SelectItem value={"YTD"}>Year to date</SelectItem>
            <SelectItem value={"YEAR"}>Last Year</SelectItem>
            <SelectItem value={"ALL"}>All time</SelectItem>
          </SelectContent>
        </Select>
      </div>
      {isError && <div>Error</div>}
      {isLoading && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
      {data && !isLoading && (
        <TeamsDropRiseList sort={sort} data={data?.result || []} />
      )}
    </div>
  );
};

export interface TeamsDropRiseData {
  result: {
    totalPercentageDifference: number;
    teamName: string;
  }[];
}
