import { create } from "zustand";

interface AppV1State {
  isV1Enabled: boolean;
  toggleV1: () => void;
}

const getInitialV1State = () => {
  return localStorage.getItem("isV1Enabled") == "true";
};

export const useAppV1Store = create<AppV1State>((set) => ({
  isV1Enabled: getInitialV1State(),
  toggleV1: () =>
    set((state) => {
      localStorage.setItem("isV1Enabled", String(!state.isV1Enabled));
      return { isV1Enabled: !state.isV1Enabled };
    }),
}));
