import { Card, CardBody, Empty, Loader, Searchbar, Text } from "_sredx/_ui";
import { EdgeClusterIcon } from "_sredx/_ui/Icon";
import { ClusterCardContainer } from "_sredx/containers/ClusterCardContainer";
import { useModal } from "_sredx/hooks";
import { useGetClusters } from "_sredx/services";
import { ClusterCreateContainer } from "containers/SelfService/ClustersListContainer/ClusterCreateContainer/ClusterCreateContainer";
import { isArray } from "lodash";
import React, { useMemo, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import styles from "./ClusterListingContainer.module.css";

export const ClusterListingContainer = () => {
  // hooks
  const { openModal, closeModal } = useModal();

  //query
  const { data: clusters, isLoading } = useGetClusters();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const displayedClusters = useMemo(() => {
    if (!clusters || !isArray(clusters)) {
      return [];
    }
    return clusters.filter(
      (cluster) =>
        cluster.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  }, [clusters, searchQuery]);

  //handlers
  const handleOnSearch = (value: string) => {
    setSearchQuery(value);
  };
  const getPath = (clusterName: string) => {
    return generatePath(":name", { name: clusterName });
  };

  const handleOnCreateCluster = () => {
    openModal({
      content: (
        <Card>
          <CardBody>
            <div
              style={{
                minWidth: "800px",
                padding: "24px 8px",
              }}
            >
              <ClusterCreateContainer onCancel={closeModal} />
            </div>
          </CardBody>
        </Card>
      ),
      options: {
        width: "900px",
      },
    });
  };

  //ui waterfall
  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text>Loading clusters...</Text>
      </div>
    );
  }

  if (!displayedClusters || displayedClusters.length === 0) {
    return (
      <>
        <div className={styles.search_filter_wrapper}>
          <div className={styles.searchbar_wrapper}>
            <Searchbar
              value={searchQuery}
              onChange={handleOnSearch}
              placeholder="Search clusters by name"
            />
          </div>
        </div>
        <Card>
          <Empty
            message={"No clusters were to be found ..."}
            onActionClick={handleOnCreateCluster}
            actionLabel={"Create new cluster"}
          />
        </Card>
      </>
    );
  }
  return (
    <>
      <div className={styles.search_filter_wrapper}>
        <div className={styles.searchbar_wrapper}>
          <Searchbar
            value={searchQuery}
            onChange={handleOnSearch}
            placeholder="Search clusters by name"
          />
        </div>
      </div>
      <div className={styles.content_wrapper}>
        <button
          className={styles.new_cluster_btn}
          onClick={handleOnCreateCluster}
        >
          <div className={styles.new_cluster_icon}>
            <EdgeClusterIcon />
          </div>
          <div>Create new cluster</div>
        </button>
        {displayedClusters.map((cluster) => (
          <Link key={cluster.name} to={getPath(cluster.name)}>
            <ClusterCardContainer cluster={cluster} />
          </Link>
        ))}
      </div>
    </>
  );
};
