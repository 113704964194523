import React from 'react'

export const Pivotal = () => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 32 32"
    >
      <path d="M16.005 0c-8.823 0-16 7.177-16 15.995 0 8.828 7.177 16.005 16 16.005 8.818 0 15.995-7.177 15.995-16.005 0-8.818-7.177-15.995-15.995-15.995zM16.005 3.24c7.042 0 12.755 5.714 12.755 12.755 0 7.052-5.714 12.766-12.755 12.766-7.047 0-12.76-5.714-12.76-12.766 0-7.042 5.714-12.755 12.76-12.755zM15.823 4.984l-4.063 2.344v0.005l3.578 6.188c0.563-0.151 1.182-0.109 1.745 0.156l1.88-3.255zM21.38 7.995l-3.573 6.193c0.438 0.432 0.698 0.995 0.745 1.594h3.755l3.141-5.438zM6.375 10.646v4.693h7.151c0.151-0.583 0.51-1.094 1.005-1.438l-1.88-3.255zM9.693 16.219l-3.146 5.432 4.068 2.354 3.578-6.198c-0.427-0.422-0.693-0.984-0.745-1.589zM18.479 16.661c-0.156 0.583-0.516 1.094-1.010 1.438l1.88 3.255h6.276v-4.693h-7.151zM14.917 18.318l-1.88 3.26 3.141 5.432 4.063-2.349-3.573-6.182c-0.568 0.151-1.188 0.104-1.75-0.161z" />
    </svg>
  );
}
