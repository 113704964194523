import clsx from "clsx";
import styles from './LayoutSidebarCollapseButton.module.css';
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from "react-icons/tb";

interface LayoutSidebarCollapseButtonProps {
  isCollapsed?: boolean;
  toggleCollapse: () => void;
}

export const LayoutSidebarCollapseButton = ({
  isCollapsed,
  toggleCollapse,
}: LayoutSidebarCollapseButtonProps) => {
  return (
    <button
      className={clsx(styles.collapseBtn, isCollapsed ? "" : styles.expanded)}
      onClick={toggleCollapse}
    >
      {isCollapsed ? (
        <TbLayoutSidebarLeftExpand />
      ) : (
        <TbLayoutSidebarLeftCollapse />
      )}
    </button>
  );
};
