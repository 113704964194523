import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
} from "_sredx/_ui";
import styles from "./ResourceDeleteWarningModal.module.css";

interface ResourceDeleteWarningModalProps {
  resource: any;
  closeModal: () => void;
  onDelete: (id: string) => void;
}

export const ResourceDeleteWarningModal = ({
  resource,
  closeModal,
  onDelete,
}: ResourceDeleteWarningModalProps) => {
  const handleDelete = () => {
    onDelete(resource.id);
  };

  return (
    <Card>
      <CardHeader>
        <Heading level={4}>Warning</Heading>
      </CardHeader>
      <CardBody>
        <Text>
          Are you sure you want to delete <b>{resource.name}</b>
        </Text>
      </CardBody>
      <CardFooter>
        <div className={styles.footer}>
          <Button variant="neutral" onClick={closeModal}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};
