import { DomainBaseDto } from "_sredx/types";
import { DOMAINS_DATA_TABLE_HEADER_ITEMS } from "./constant";
import {
  Card,
  Empty,
  Loader,
  Pagination,
  ProgressBar,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_sredx/_ui";

import styles from "./DomainDataTable.module.css";
import { DOMAIN_SEARCH_CRITERIA_DEFAULT } from "_sredx/containers";
import { DomainDataTableRow, DomainRowActionType } from "../DomainDataTableRow";

interface DomainDataTableProps {
  domains?: DomainBaseDto[];
  isLoading: boolean;
  isFetching: boolean;
  totalPages?: number;
  currentPage?: number;
  totalElements?: number;
  onPageChange?: (page: number) => void;
  onAddDomains?: () => void;
  onRowClicked?: (domain: DomainBaseDto) => void;
  onAction: (domain: DomainBaseDto, actionType: DomainRowActionType) => void;
}

export const DomainDataTable = ({
  domains = [],
  isLoading,
  totalPages = 1,
  currentPage = 0,
  totalElements = 1,
  onPageChange,
  isFetching,
  onAddDomains,
  onAction,
}: DomainDataTableProps) => {
  const headerCells = Object.values(DOMAINS_DATA_TABLE_HEADER_ITEMS);
  // Derived variables
  const showPagination = totalPages > 1;
  const count =
    (DOMAIN_SEARCH_CRITERIA_DEFAULT.pageSize || 10) * currentPage +
    domains.length;

  // Event handlers
  const handleOnPageChange = (page: number) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const handleOnAction = (
    domain: DomainBaseDto,
    actionType: DomainRowActionType
  ) => {
    onAction(domain, actionType);
  };

  if (isLoading) {
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading domains...</Text>
        </div>
      </Card>
    );
  }

  if (!isLoading && domains && domains.length === 0) {
    return (
      <Card>
        <Empty
          message={"No domain found in your organization."}
          actionLabel={"Add new domain"}
          onActionClick={onAddDomains}
        />
      </Card>
    );
  }

  return (
    <Table>
      {/*Header*/}
      <TableHeader>
        {headerCells.map((cell) => (
          <TableHead className={styles.header_cell} key={cell}>
            <Text>{cell}</Text>
          </TableHead>
        ))}
      </TableHeader>
      {/*body*/}
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {domains.map((domain) => (
          <DomainDataTableRow
            key={domain.id}
            domain={domain}
            onAction={handleOnAction}
          />
        ))}
      </TableBody>
      {/*footer*/}
      <TableFooter>
        <TableFooterCell colSpan={headerCells.length}>
          <div className={styles.footer}>
            <span>
              {count} / {totalElements} Domains
            </span>
            {showPagination && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handleOnPageChange}
                currentPage={currentPage}
              />
            )}
          </div>
        </TableFooterCell>
      </TableFooter>
    </Table>
  );
};
