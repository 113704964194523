import {
  differenceInWeeks,
  endOfWeek,
  format as formatDate,
  secondsToHours,
  startOfWeek,
} from "date-fns";
import { PullRequestDto } from "_sredx/types";

export interface PRCycleTimeBreakdownType {
  startDate: string;
  endDate: string;
  cycleTime: number;
  codingTime: number;
  pickupTime: number;
  reviewTime: number;
  approvalTime: number;
  timeToMerge: number;
  timeToReview: number;
  nbOfPRs: number;
}

export const PR_CYCLE_TIME_BREAKDOWN_KEYS: (keyof PRCycleTimeBreakdownType)[] =
  ["codingTime", "pickupTime", "reviewTime", "approvalTime"];

export const prCycleTimeBreakdownKeyLabelMapper = (
  key: keyof PRCycleTimeBreakdownType
) => {
  switch (key) {
    case "codingTime":
      return "Coding Time";
    case "pickupTime":
      return "Pickup Time";
    case "reviewTime":
      return "Review Time";
    case "approvalTime":
      return "Approval Time";
    default:
      return "";
  }
};
export const prCycleTimeColorMapper = (key: keyof PRCycleTimeBreakdownType) => {
  switch (key) {
    case "codingTime":
      return "#98D1F9";
    case "pickupTime":
      return "#5FBDFF";
    case "reviewTime":
      return "#8E8FFA";
    case "approvalTime":
      return "#7F27FF";
    default:
      return "#000";
  }
};

export const getPRCycleTimeBreakdown = (
  pullRequests: PullRequestDto[],
  startDate: string,
  endDate: string
) => {
  const map = new Map<string, PRCycleTimeBreakdownType>();

  const diffInWeeks = differenceInWeeks(
    startOfWeek(new Date(endDate), {
      weekStartsOn: 1,
    }),
    startOfWeek(new Date(startDate), {
      weekStartsOn: 1,
    })
  );

  for (let i = 0; i < diffInWeeks; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() + i * 7);

    const startOfWeekDate = startOfWeek(currentDate, {
      weekStartsOn: 1,
    });
    const endOfWeekDate = endOfWeek(currentDate, {
      weekStartsOn: 1,
    });

    const key = formatDate(startOfWeekDate, "yyyy-MM-dd");

    map.set(key, {
      startDate: formatDate(startOfWeekDate, "yyyy-MM-dd"),
      endDate: formatDate(endOfWeekDate, "yyyy-MM-dd"),
      cycleTime: 0,
      codingTime: 0,
      pickupTime: 0,
      reviewTime: 0,
      approvalTime: 0,
      timeToMerge: 0,
      timeToReview: 0,
      nbOfPRs: 0,
    });
  }

  pullRequests.forEach((pr) => {
    const {
      codingTime,
      pickupTime,
      reviewTime,
      approvalTime,
      prCycleTime,
      timeToReview,
      timeToMerge,
      mergedAt,
    } = pr;

    const startWeekOfMergedAt = startOfWeek(new Date(mergedAt), {
      weekStartsOn: 1,
    });

    const key = formatDate(startWeekOfMergedAt, "yyyy-MM-dd");

    if (map.has(key)) {
      const current = map.get(key);
      if (current) {
        current.cycleTime += secondsToHours(prCycleTime);
        current.codingTime += secondsToHours(codingTime);
        current.pickupTime += secondsToHours(pickupTime);
        current.reviewTime += secondsToHours(reviewTime);
        current.approvalTime += secondsToHours(approvalTime);
        current.timeToMerge += secondsToHours(timeToMerge);
        current.timeToReview += secondsToHours(timeToReview || 0);
        current.nbOfPRs += 1;
        map.set(key, current);
      }
    }
  });

  return Array.from(map.values()).sort((a, b) => {
    return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
  });
};
