import { useEffect, useState } from "react";
import { getDaysInCurrentMonth } from "./utils";
import styles from "./PlannerTodayIndicator.module.css";

export const PlannerTodayIndicator = () => {
  const [linePosition, setLinePosition] = useState({ x: 0, y: 0 });

  const updateLinePosition = () => {
    const currentMonthContainer = document.querySelector(
      ".nemo-planner-header-current-month"
    );
    const headerContainer = document.querySelector(".nemo-planner-header");
    if (currentMonthContainer && headerContainer) {
      const dayFractionOfMonth = new Date().getDate() / getDaysInCurrentMonth();
      const { left: currentMonthX, width: currentMonthWidth } =
        currentMonthContainer.getBoundingClientRect();
      const { left: headerX, height: headerHeight } =
        headerContainer.getBoundingClientRect();
      setLinePosition({
        x: currentMonthX - headerX + currentMonthWidth * dayFractionOfMonth,
        y: headerHeight,
      });
    }
  };

  useEffect(() => {
    updateLinePosition();
    window.addEventListener("resize", updateLinePosition);
    return () => {
      window.removeEventListener("resize", updateLinePosition);
    };
  }, []);

  return (
    <div
      data-testid="planner-indicator"
      className={styles.wrapper}
      style={{
        left: `${linePosition.x}px`,
        top: `${linePosition.y}px`,
        height: `calc(100% - ${linePosition.y}px)`,
      }}
    ></div>
  );
};
