import { useIntersectionObserver } from "usehooks-ts";
import { InputListItem } from "./InputListItem";
import { OptionsData } from "../../types";

import inputStyles from "../Input.module.css";
import { ForwardedRef, forwardRef } from "react";

interface SelectedListProps {
  options: OptionsData[];
  onRemove: (value: OptionsData) => void;
}

const SelectedList = ({ options, onRemove }: SelectedListProps) => {
  return (
    <div className={inputStyles.list_w}>
      {options.map(({ label, value }) => {
        return (
          <InputListItem
            key={value}
            onClick={() => onRemove({ label, value })}
            selected={true}
          >
            {label}
          </InputListItem>
        );
      })}
    </div>
  );
};

interface OptionsListProps {
  options: OptionsData[];
  onAdd: (value: OptionsData) => void;
  isFetching?: boolean;
  isLoading?: boolean;
}
const OptionsList = forwardRef(
  (
    { options, onAdd, isFetching, isLoading }: OptionsListProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    if (isLoading) {
      return (
        <div className={inputStyles.loader_wrapper}>
          <div className={inputStyles.loader} />
        </div>
      );
    }

    if (!isLoading && options.length === 0) {
      return <div className={inputStyles.list_w}>No data</div>;
    }

    return (
      <div className={inputStyles.list_w}>
        {options.map(({ label, value }) => {
          return (
            <InputListItem
              key={value}
              onClick={() => onAdd({ label, value })}
              selected={false}
            >
              {label}
            </InputListItem>
          );
        })}
        {isFetching ? (
          <div className={inputStyles.loader_wrapper}>
            <div className={inputStyles.loader} />
          </div>
        ) : (
          <div className={inputStyles.intersection} ref={ref} />
        )}
      </div>
    );
  }
);

interface InputListContentProps {
  options: OptionsData[];
  isLoading?: boolean;
  isFetching?: boolean;
  selectedOptions: OptionsData[];
  onAdd: (value: OptionsData) => void;
  onRemove: (value: OptionsData) => void;
  onIntersection?: () => void;
}

export const InputListContent = ({
  options,
  selectedOptions,
  isFetching,
  isLoading,
  onRemove,
  onAdd,
  onIntersection,
}: InputListContentProps) => {
  const { ref } = useIntersectionObserver({
    threshold: 0.5,
    onChange: (isIntersecting) => {
      if (isIntersecting && onIntersection) {
        onIntersection();
      }
    },
  });

  return (
    <>
      <SelectedList onRemove={onRemove} options={selectedOptions} />
      <OptionsList
        options={options}
        onAdd={onAdd}
        isFetching={isFetching}
        isLoading={isLoading}
        ref={ref}
      />
    </>
  );
};
