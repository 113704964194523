import React from 'react';
import style from "./IntegrationTemplatesMenuComponent.module.css";

export const IntegrationTemplatesMenuSkeleton = () => {
  return (
    <div className={style.wrapper}>
        <div className={style.content_wrapper}>
            <div className={style.item_skeleton}>
                <div className={style.icon_skeleton}></div>
                <div className={style.text_skeleton}></div>
            </div>

            <div className={style.item_skeleton}>
                <div className={style.icon_skeleton}></div>
                <div className={style.text_skeleton}></div>
            </div>

            <div className={style.item_skeleton}>
                <div className={style.icon_skeleton}></div>
                <div className={style.text_skeleton}></div>
            </div>

            <div className={style.item_skeleton}>
                <div className={style.icon_skeleton}></div>
                <div className={style.text_skeleton}></div>
            </div>

            <div className={style.item_skeleton}>
                <div className={style.icon_skeleton}></div>
                <div className={style.text_skeleton}></div>
            </div>

        </div>

    </div>
  )
}
