import React from 'react';
import style from "./Footer.module.css";

export const Footer = () => {
  return (
    <footer className={style.wrapper}>
      <div className="container">
        <p>© {new Date().getFullYear()} SREDX. All rights reserved.</p>
      </div>
    </footer>
  );
}
