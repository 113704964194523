import { Status } from "./types";

export const getTotalCount = (statuses: Status[]) => {
  return (
    statuses.reduce((acc, status) => {
      return acc + status.value;
    }, 0) || 0
  );
};

export const getValidPercentage = (total: number, StatusValue: number) => {
  if (total == 0) return 0;
  return Math.min(Math.max((StatusValue / total) * 100, 0), 100);
};
