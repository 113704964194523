import { DownloadIcon } from '@heroicons/react/solid';
import styles from './clusterDetails.module.css'
import { Button } from 'components/octo-ui/Button/Button';

interface ClusterDetailsHeaderBarProps {
    heading: string;
    onDownload?: () => void;
    isKubeDownloadReady: boolean
    isDownloading?: boolean;
}

const ClusterDetailsHeaderBar = ({heading, onDownload, isKubeDownloadReady, isDownloading}: ClusterDetailsHeaderBarProps) => {
  
  
  return (
    <div className={styles.header} >
        <div role="heading" aria-level={2} className={styles.mainHeading} >
        {heading}
        </div>
        {onDownload && (
          <Button 
            variant="primary" 
            onClick={onDownload} 
            isLoading={isDownloading}
            icon={<DownloadIcon className='h-4' />}
            size='sm'
            disableHoverEffect
            disabled={!isKubeDownloadReady}
          >
            Download Kubeconfig
          </Button>
        )}
  </div>
  )
}

export default ClusterDetailsHeaderBar