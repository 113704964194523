import {Avatar, TableCell, TableRow, Text} from "_sredx/_ui";
import {TeamBaseDto} from "_sredx/types";
import {formatISODate} from "_sredx/utils";
import {TeamDataTableRowMembers} from "../TeamDataTableRowMembers";
import styles from "./TeamDataTableRow.module.css";

interface TeamDataTableRowProps {
  team: TeamBaseDto;
  onTeamRowClick: (teamId: string) => void;
}

export const TeamDataTableRow = ({
  team,
  onTeamRowClick,
}: TeamDataTableRowProps) => {
  const { name, createdAt, members } = team;

  const handleTeamRowClicked = () => {
    onTeamRowClick(team.id);
  };

  return (
    <TableRow className={styles.team_row} onClick={handleTeamRowClicked}>
      <TableCell>
        <div className={styles.col1_wrapper}>
          <Avatar size={20} name={name} rectangle avatarStyle="identicon" />
          <Text>{name}</Text>
        </div>
      </TableCell>
      <TableCell>
        <Text>{formatISODate(createdAt)}</Text>
      </TableCell>
      <TableCell align={"right"}>
        <TeamDataTableRowMembers members={members} />
      </TableCell>
    </TableRow>
  );
};
