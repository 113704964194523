import IntegrationTemplate, {
  IntegrationTemplateChild,
} from "models/integration/IntegrationTemplate";
import React, { FC } from "react";
import { ADMIN_CONFIG_PAGE_PREFIX } from "routes/paths";
import clsx from "clsx";
import { Link } from "react-router-dom";
import styles from "./IntegrationTemplateMenuItem.module.css";

interface IntegrationTemplateMenuItemProps {
  template: IntegrationTemplate | IntegrationTemplateChild;
  parentKey?: string;
  currentTemplateKey: string;
  logo? : React.ElementType
}

export const CHILD_SEPARATOR = "~";

const IntegrationTemplateMenuItem: FC<IntegrationTemplateMenuItemProps> = ({
  template,
  currentTemplateKey,
  parentKey,
  logo: LogoComponent,
}) => {
  let link = ADMIN_CONFIG_PAGE_PREFIX + "/";
  if (parentKey) link += parentKey + CHILD_SEPARATOR;
  link += template.key;
  return (
    <Link
      key={template.key}
      to={link}
      className={clsx(
        template.key === currentTemplateKey && styles.current,
        styles.item
      )}
      aria-current={template.key === currentTemplateKey ? "page" : undefined}
    >
      <div className={styles.content_wrapper}>
        <div className={styles.logo_wrapper}>
          {LogoComponent && <LogoComponent />}
        </div>
        <span className={styles.template_name}>{template.name}</span>
      </div>
    </Link>
  );
};

export default IntegrationTemplateMenuItem;
