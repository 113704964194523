import { MENU } from "./utils";
import {
  HeaderLogo,
  MenuItem,
  MenuItemIcon,
  MenuItemLabel,
  MenuItemLink,
  MenuList,
  MenuTitle,
} from "_sredx/_ui";

import styles from "./AppSideMenu.module.css";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { AppShortcutDropDown } from "_sredx/components/AppShortcutDropDown";

interface AppSideMenuProps {
  isCollapsed?: boolean;
}
export const AppSideMenu = ({ isCollapsed = false }: AppSideMenuProps) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(
          styles.logo_wrapper,
          isCollapsed ? styles.truncate : ""
        )}
      >
        <HeaderLogo variant={"white"} />
      </div>
      <div className={styles.content}>
        {MENU.map((menu, i) => {
          return (
            <MenuList key={i}>
              {menu.title &&
                (isCollapsed ? (
                  <MenuTitle>----</MenuTitle>
                ) : (
                  <MenuTitle>{menu.title}</MenuTitle>
                ))}
              {menu.items.map((item) => {
                return (
                  <MenuItem key={item.path}>
                    <NavLink
                      to={item.path}
                      key={item.path}
                      className={styles.menu_item}
                    >
                      {({ isActive }) => (
                        <MenuItemLink active={isActive}>
                          <MenuItemIcon>
                            <item.icon />
                          </MenuItemIcon>
                          {!isCollapsed && (
                            <MenuItemLabel>{item.title}</MenuItemLabel>
                          )}
                        </MenuItemLink>
                      )}
                    </NavLink>
                  </MenuItem>
                );
              })}
            </MenuList>
          );
        })}

        <AppShortcutDropDown isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};
