import * as RadixPopover from "@radix-ui/react-popover";
import styles from "./TeamSelectContainer.module.css";
import { ChevronDownIcon } from "_sredx/_ui/Icon";
import { Avatar, Searchbar } from "_sredx/_ui";
import {
  SelectPopoverContent,
  SelectPopoverSelectedItem,
  SelectPopoverSelectedItems,
  SelectPopoverTitle,
} from "_sredx/_ui/SelectPopover";
import { SelectPopoverItems } from "_sredx/_ui/SelectPopover/SelectPopoverItems";
import { useInfiniteGetTeams } from "_sredx/services";
import { useEffect, useMemo, useState } from "react";
import { TeamBaseDto } from "_sredx/types";
import { mapInfiniteTeams } from "./utils";

const SelectedTeam = ({ team }: { team: { name: string; id: string } }) => {
  return (
    <>
      <Avatar rectangle size={16} name={team.name} avatarStyle={"identicon"} />
      <span className={styles.selected_item_label}>{team.name}</span>
    </>
  );
};
const TeamOption = ({ team }: { team: { name: string; id: string } }) => {
  return (
    <>
      <Avatar rectangle size={16} name={team.name} avatarStyle={"identicon"} />
      <span className={styles.item_label}>{team.name}</span>
    </>
  );
};

interface TeamSelectContainerProps {
  onChange?: (teams: TeamBaseDto[]) => void;
  countIndicator?: boolean;
  initialValue?: {
    name: string;
    id: string;
    [key: string]: any;
  }[];
}

export const TeamSelectContainer = ({
  onChange,
  countIndicator = true,
  initialValue,
}: TeamSelectContainerProps) => {
  // State
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedTeams, setSelectedTeams] = useState<TeamBaseDto[]>([]);

  // Services
  const {
    data: infiniteTeams,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
  } = useInfiniteGetTeams({
    params: {
      name: searchQuery,
    },
  });

  //Side effects
  useEffect(() => {
    if (initialValue) {
      setSelectedTeams(
        initialValue.map((team) => ({
          name: team.name,
          id: team.id,
          description: "",
          createdAt: "",
          members: [],
          services: [],
        }))
      );
    }
  }, [initialValue]);
  // Derived data
  const teams = useMemo(() => {
    return mapInfiniteTeams(infiniteTeams);
  }, [infiniteTeams]);

  const displayedTeams = useMemo(() => {
    return teams.filter((team) => {
      const isSelected = selectedTeams.some(
        (selectedTeam) => selectedTeam.id === team.id
      );
      return !isSelected;
    });
  }, [teams, selectedTeams]);

  // Event handlers
  const handleOnSearch = (value: string) => {
    // [TODO] add debounce
    setSearchQuery(value);
  };
  const handleOnAddTeam = (team: TeamBaseDto) => {
    setSelectedTeams((prev) => [...prev, team]);
    onChange?.([...selectedTeams, team]);
  };
  const handleOnClear = () => {
    setSelectedTeams([]);
    onChange?.([]);
  };
  const handleOnRemoveTeam = (team: TeamBaseDto) => {
    setSelectedTeams((prev) => prev.filter((t) => t.id !== team.id));
    onChange?.(selectedTeams.filter((t) => t.id !== team.id));
  };
  const handleOnLoadMore = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  return (
    <RadixPopover.Root>
      <RadixPopover.Trigger asChild>
        <button className={styles.trigger_btn}>
          {(countIndicator || selectedTeams.length == 0) && (
            <span className={styles.trigger_label}>Teams</span>
          )}

          {selectedTeams.length > 0 && (
            <div className="flex gap-2">
              {countIndicator ? (
                selectedTeams.length
              ) : (
                <>
                  {selectedTeams.slice(0, 2).map((team) => (
                    <SelectPopoverSelectedItem key={team.id}>
                      <SelectedTeam team={team} />
                    </SelectPopoverSelectedItem>
                  ))}
                  {selectedTeams.length > 2 && `+${selectedTeams.length - 2}`}
                </>
              )}
            </div>
          )}
          <div className={styles.trigger_icon}>
            <ChevronDownIcon />
          </div>
        </button>
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          className={styles.content}
          align={"start"}
          sideOffset={8}
        >
          <SelectPopoverContent>
            <SelectPopoverTitle title={"Teams"} onClear={handleOnClear} />
            <Searchbar
              placeholder={"Search teams by name"}
              size={"small"}
              autoFocus
              onChange={handleOnSearch}
              value={searchQuery}
            />
            <SelectPopoverSelectedItems>
              {selectedTeams.map((team) => (
                <SelectPopoverSelectedItem
                  key={team.id}
                  onRemove={() => handleOnRemoveTeam(team)}
                >
                  <SelectedTeam team={team} />
                </SelectPopoverSelectedItem>
              ))}
            </SelectPopoverSelectedItems>

            <SelectPopoverItems
              items={displayedTeams}
              renderItem={(team) => <TeamOption team={team} />}
              onItemClick={handleOnAddTeam}
              isLoading={isLoading}
              isFetching={isFetching}
              loadMore={handleOnLoadMore}
              loadingCaption={"Loading teams..."}
            />
          </SelectPopoverContent>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};
