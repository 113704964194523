import {createLevelMedia} from "services/media/Media.service";
import {LEVELS_MEDIA_QUERY} from "hooks/media/useLevelsMedia";
import {useAxios} from "hooks/useAxios";
import {useMutation, useQueryClient} from "react-query";




export const useCreateLevelMedia = () => {
    const { axios } = useAxios({ headers:{
            'Content-Type':'multipart/form-data'
        }})
    const queryClient = useQueryClient();

    return useMutation(async (mediaDto : FormData) => {
        return await createLevelMedia(axios,mediaDto)
    },{
        onSuccess : async () => {
            await queryClient.invalidateQueries([LEVELS_MEDIA_QUERY])
        },
        onError : async (error) => {
            console.log("## error",error)
        }

    })
}