import { CSSObjectWithLabel, StylesConfig } from "react-select";

export const customStyles: StylesConfig<any, false> = {
  control: (provided: any, { isFocused, menuIsOpen }) => {
    return {
      ...provided,
      border: "1px solid var(--gray-700)",
      boxShadow: isFocused ? "0 0 3px var(--cyan-600) " : "none",
      fontSize: "14px",
      borderRadius: 0,
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:focus": {
        border: "1px solid var(--cyan-600)",
        "--tw-ring-shadow": "none",
        boxShadow: "0 0 3px var(--cyan-600) ",
      },
      "&:hover": {
        border: "1px solid var(--gray-700)",
        boxShadow: "0 0 3px var(--gray-700) ",
      }, // Add transition for smooth changes
    } as CSSObjectWithLabel;
  },
  option: (provided: any, { isFocused }) => {
    return {
      ...provided,
      backgroundColor: isFocused ? "var(--cyan-600)" : "white",
      color: isFocused ? "white" : "black",
    } as CSSObjectWithLabel;
  },
  menu: (provided: any) => {
    return {
      ...provided,
      borderRadius: 0,
      margin: 0,
    } as CSSObjectWithLabel;
  },
};
