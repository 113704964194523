import React from 'react';
import style from "./ScorecardLevelsSectionComponent.module.css";
import Level from 'models/scorecard/Level';
import { ScorecardLevelsSectionWrapper } from './ScorecardLevelsSectionWrapper';
import { ScorecardLevelImageComponent } from '../ScorecardLevelImageComponent/ScorecardLevelImageComponent';
import Tooltip from 'components/octo-ui/Tootlip/Tooltip';
import { useMediaQuery } from 'hooks/useMediaQuery';

interface ScorecardLevelsSectionComponentProps {
    levels : Level[];
}

export const ScorecardLevelsSectionComponent = ({levels} : ScorecardLevelsSectionComponentProps) => {

  const matchesMin = useMediaQuery('(min-width: 765px)') ;
  const matchesMax = useMediaQuery('(max-width: 992px)') ;
  const matchesSmallScreen  = useMediaQuery('(max-width: 400px)');
  const matches = (matchesMin && matchesMax) || matchesSmallScreen;
  const renderedLevels = matches ? 2 : 3;
  

  const leftLevels = levels.length > renderedLevels ? levels.slice(renderedLevels).map(level => level.name!) : [];

    if(levels.length === 0){
        return (
          <ScorecardLevelsSectionWrapper levelsCount={0}>
            <p className={style.placeholder_text}>No Levels Yet</p>
          </ScorecardLevelsSectionWrapper>
        );
    }


  return (
    
    <ScorecardLevelsSectionWrapper levelsCount={levels.length}>
      <div className={style.content_wrapper}>
        {levels.slice(0, renderedLevels).map((level, index) => (
          <div key={level.id} className={style.level_wrapper}>
            <Tooltip content={level.name} direction='bottom'>
              <ScorecardLevelImageComponent key={index} image={level.image}  />
            </Tooltip>
            <span className={style.level_name}>{level.name}</span>
          </div>
        ))}
        {levels.length > renderedLevels && (
          <div className={style.level_wrapper}>
            <Tooltip content={"remaining levels are: " + leftLevels} direction='right'>
              <div className={style.image_wrapper}>
              <p className={style.more}>{`+${levels.length - renderedLevels}`}</p>
            </div>
            </Tooltip>
            <span className={style.level_name}>more</span>
          </div>
        )}
      </div>
    </ScorecardLevelsSectionWrapper>
  );
}
