import {
  InitiativeRuleEvaluation,
  InitiativeRuleOnServiceEvaluation,
} from "./types";
import { TableCell, TableRow, Text } from "_sredx/_ui";
import { EvaluationStatus } from "../EvaluationStatus";
import styles from "./InitiativeDetailsRules.module.css";
import { calculateTotalSuccessServices, resolveProgressColor } from "./utils";
import { ProgressBar } from "_sredx/_ui/ProgressBar/ProgressBar";
import { useState } from "react";
import clsx from "clsx";
import { ChevronDownIcon } from "_sredx/_ui/Icon";

interface InitiativeDetailsRulesRowsProps {
  initiativeRuleEvaluation: InitiativeRuleEvaluation;
}

export const InitiativeDetailsRulesRows = ({
  initiativeRuleEvaluation,
}: InitiativeDetailsRulesRowsProps) => {
  // State
  const [foldRowOpen, setFoldRowOpen] = useState(false);

  // Derived variables
  const services = initiativeRuleEvaluation.services ?? [];
  const totalSuccessServices = calculateTotalSuccessServices(
    initiativeRuleEvaluation
  );
  const progress = (totalSuccessServices / services.length) * 100;

  const handleClickRow = () => {
    setFoldRowOpen(!foldRowOpen);
  };

  return (
    <>
      <TableRow
        onClick={handleClickRow}
        className={clsx(styles.rule_row, foldRowOpen && styles.open)}
      >
        <TableCell>{initiativeRuleEvaluation.ruleName}</TableCell>
        <TableCell>
          {initiativeRuleEvaluation.success != null ? (
            <EvaluationStatus success={initiativeRuleEvaluation.success} />
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell>{initiativeRuleEvaluation.scoreCardName}</TableCell>

        <TableCell>{services.length}</TableCell>

        <TableCell>
          {services.length ? (
            <div className={styles.progress}>
              <Text>
                {totalSuccessServices}/{services.length}
              </Text>

              <ProgressBar
                infinite={false}
                percent={progress}
                rounded
                color={resolveProgressColor(progress)}
              />
            </div>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="right">
          <div
            className={clsx(
              styles.chevron_down_icon,
              foldRowOpen && styles.open
            )}
          >
            <ChevronDownIcon />
          </div>
        </TableCell>
      </TableRow>
      <ServicesRow services={services} foldRowOpen={foldRowOpen} />
    </>
  );
};

const ServicesRow = ({
  services,
  foldRowOpen,
}: {
  services: InitiativeRuleOnServiceEvaluation[];
  foldRowOpen: boolean;
}) => {
  return (
    <TableRow className={clsx(styles.fold_row, foldRowOpen && styles.open)}>
      {services.length ? (
        <>
          <TableCell>
            <div className={styles.services_wrapper}>
              <Text className={styles.services_title}>services</Text>
              {services.map((service) => (
                <div className={styles.service_wrapper}>
                  <Text>{service.serviceName}</Text>
                </div>
              ))}
            </div>
          </TableCell>

          <TableCell colSpan={5}>
            <div className={styles.services_wrapper}>
              <span style={{ visibility: "hidden" }}>services</span>
              {services.map((service) => (
                <EvaluationStatus success={service.success} />
              ))}
            </div>
          </TableCell>
        </>
      ) : (
        <TableCell colSpan={6}>
          <div className={styles.empty_services_wrapper}>
            <Text>No services found for this rule</Text>
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};
