import React, { forwardRef } from "react";
import clsx from "clsx";
import styles from "./Table.module.css";

export const Table = forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => {
  return (
    <table
      ref={ref}
      className={clsx("nemo-table-root", styles.wrapper, className)}
      {...props}
    />
  );
});
