import { useMe } from "_sredx/services";
import { DashboardInfoDto } from "_sredx/types/dashboards";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

interface DashboardContextType {
  dashboards: DashboardInfoDto[];
  selectedDashboard: DashboardInfoDto | null;
  setSelectedDashboard: (dashboard: DashboardInfoDto | null) => void;
  isLoading: boolean;
  isSuccess: boolean;
}

const DASHBOARD_CONTEXT_DEFAULT_VALUE: DashboardContextType = {
  dashboards: [],
  selectedDashboard: null,
  setSelectedDashboard: () => {},
  isLoading: true,
  isSuccess: false,
};

const DashboardContext = React.createContext(DASHBOARD_CONTEXT_DEFAULT_VALUE);

export const DashboardProvider = ({ children }: PropsWithChildren) => {
  // State
  const [selectedDashboard, setSelectedDashboard] =
    useState<DashboardInfoDto | null>(null);

  // Services
  const { data: currentUser, isLoading, isSuccess } = useMe();

  // Derived variables
  const dashboards = currentUser?.dashboards || [];

  // Side effects
  useEffect(() => {
    if (!selectedDashboard && dashboards.length)
      setSelectedDashboard(dashboards[0]);
  }, [currentUser]);

  return (
    <DashboardContext.Provider
      value={{
        dashboards,
        selectedDashboard,
        setSelectedDashboard,
        isLoading,
        isSuccess,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => useContext(DashboardContext);
