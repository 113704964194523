const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-white text-gray-400 shadow p-1 text-left rounded border-1 border-cyan-1">
                <div className="my-1 mx-2">
                    <p className="font-bold">{payload[0].payload.evaluationTime}</p>
                    <p><span className="text-cyan-600">Score:</span> {payload[0].payload.evaluationScore}%</p>
                </div>
            </div>
        );
    }

    return null;
};

export default CustomTooltip;