import React from 'react';
import * as RadioGroup from "@radix-ui/react-radio-group";
import { UserAction } from '_sredx/containers/OnboardingTeamsImportStepContainer';
import { CheckIcon } from '_sredx/_ui/Icon';
import styles from "./OnboardingActionsCards.module.css";
import clsx from 'clsx';
import { Heading, Text } from '_sredx/_ui';

interface OnboardingActionsCardsProps{
    userActions: UserAction[];
    onChange: (value: string) => void;
    selectedAction: string
}

export const OnboardingActionsCards = ({userActions, selectedAction, onChange} : OnboardingActionsCardsProps) => {
  return (
    <form>
        <RadioGroup.Root
          defaultValue={userActions[0].key}
          onValueChange={onChange}
        >
          <div className={styles.grid}>
            {userActions.map((action) => (
              
                  <div key={action.key} className={clsx(styles.card_wrapper, selectedAction === action.key && styles.active_card)}>
                    <div>
                      <RadioGroup.Item
                         className={styles.card_header}
                        value={action.key}
                      >
                        <div className={clsx(selectedAction != action.key && styles.radio_group_item )}>
                          <RadioGroup.Indicator>
                            <CheckIcon className={styles.icon}/>
                          </RadioGroup.Indicator>
                        </div>

                        <label className={styles.button_label}>
                            <Heading level={6}>{action.title}</Heading>
                        </label>
                      </RadioGroup.Item>
                    </div>
                    <Text>{action.description}</Text>
                  </div>
           
            ))}
          </div>
        </RadioGroup.Root>
      </form>

  )
}
