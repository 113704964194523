import { Rule, RuleCategory } from "_sredx/types";

export const getListOfUniqueCategoriesFromListOfRules = (
  rules?: Rule[]
): RuleCategory[] => {
  if (!rules) return [];

  return rules
    .filter((r) => !!r.ruleCategory)
    .map((r) => r.ruleCategory!)
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.categoryKey === value.categoryKey &&
            t.categoryName === value.categoryName
        )
    );
};
