import { Button } from "components/octo-ui/Button/Button";
import styles from "./AccountsHeader.module.css";
import { PlusIcon } from "@heroicons/react/outline";

interface AccountsHeaderProps {
  title: string;
  description: string;
  ctaLabel?: string;
  onCtaClick?: () => void;
}

const AccountsHeader = ({
  title,
  description,
  ctaLabel,
  onCtaClick,
}: AccountsHeaderProps) => {
  const handleClick = () => {
    if (onCtaClick) onCtaClick();
  };

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.heading} role="heading" aria-level={3}>
          {title}
        </div>
        <p>{description}</p>
      </div>
      {ctaLabel && (
        <div>
          <Button
            variant="primary"
            size="sm"
            icon={<PlusIcon className="h-4" />}
            onClick={handleClick}>
            {ctaLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AccountsHeader;
