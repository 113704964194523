import { useQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { PagedResponse, TeamBaseDto, TeamSearchCriteria } from "_sredx/types";
import { ClientApi } from "_sredx/services/clientApi";

interface UseGetTeamsProps {
  params: TeamSearchCriteria;
}

export const useGetTeams = ({ params }: UseGetTeamsProps) => {
  return useQuery<PagedResponse<TeamBaseDto>>({
    queryKey: [QUERY_KEYS.teams, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getTeams(), {
        params,
      });
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
