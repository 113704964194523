import { PaginationCriteria } from "./api";
import { EvaluationRuleDto } from "./evaluations";

export const INITIATIVE_STATUSES = {
  PLANNED: "PLANNED",
  STARTED: "STARTED",
  COMPLETED: "COMPLETED",
  EXPIRED: "EXPIRED",
} as const;

export type InitiativeStatus =
  (typeof INITIATIVE_STATUSES)[keyof typeof INITIATIVE_STATUSES];

export interface InitiativeCreationDto {
  name: string;
  description: string;
  status: InitiativeStatus | null;
  startDate: Date | null;
  endDate: Date | null;
}

export interface InitiativeService {
  id: string;
  name?: string;
}

export interface InitiativeSearchCriteria extends PaginationCriteria {
  name?: string;
  tags?: string[];
  startDate?: string;
  endDate?: string;
  roadmapIds?: string[];
  evaluated?: boolean;
  teamIds?: string[];
  serviceIds?: string[];
}

export interface InitiativeRoadmapDto {
  id: string;
  name: string;
}

export interface InitiativeBaseDto {
  id: string;
  name: string;
  description: string;
  status: InitiativeStatus;
  startDate: Date;
  endDate: Date;
  tags: string[];
  serviceIds: string[];
  ruleIds: string[];
  roadmap: InitiativeRoadmapDto | null;
}

export interface InitiativeDto extends InitiativeBaseDto {
  globalProgress: number;
  progress: number;
  ruleEvaluationResults: InitiativeRuleEvaluationResult[];
  serviceEvaluationResults: InitiativeServiceEvaluationResult[];
}

export interface InitiativeRuleEvaluationResult {
  ruleId: string;
  success: boolean;
  passingServices: string[];
  failingServices: string[];
}

export interface InitiativeServiceEvaluationResult {
  serviceId: string;
  success: boolean;
  passingRules: string[];
  failingRules: string[];
}

export interface InitiativeEvaluationResultDto {
  ruleEvaluationResult: EvaluationRuleDto;
  service: InitiativeServiceDto;
}

interface InitiativeServiceDto {
  serviceId: string;
  serviceName: string;
}

export interface UpdateInitiativesDto {
  id: string;
  roadmapId?: string;
  name?: string;
  description?: string;
  status?: InitiativeStatus;
  startDate?: Date | null;
  endDate?: Date | null;
  ruleIds?: string[];
  serviceIds?: string[];
  tags?: string[];
}
