import { Input, Label, Text } from "_sredx/_ui";
import clsx from "clsx";
import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import styles from "./FormFieldInput.module.css";

import { FormFieldProps } from "./types";

export const FormFieldInput = <FieldsType extends FieldValues>({
  label,
  control,
  name,
  fullWidth,
  errorMessage,
  ...props
}: FormFieldProps<FieldsType>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <div
            className={clsx(styles.wrapper, props.disabled && styles.disabled, fullWidth && styles.full_width, props.className)}
          >
            {label && <Label>{label}</Label>}
            <Input
              value={field.value}
              id={field.name}
              onChange={field.onChange}
              name={field.name}
              ref={field.ref}
              onBlur={field.onBlur}
              fullWidth={fullWidth}
              {...props}
            />
            {errorMessage && (
              <Text className={styles.error_message}>{errorMessage}</Text>
            )}
          </div>
        );
      }}
    />
  );
};
