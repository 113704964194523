import { AdvancedFilterConfig } from "../types";
import styles from "./SelectProperty.module.css";
import { useMemo, useState } from "react";
import clsx from "clsx";
import { groupPropertiesByCategory, searchProperties } from "./utils";
import { PropertyIcon } from "../Icons";

interface SelectPropertyProps {
  options: AdvancedFilterConfig[];
  value?: AdvancedFilterConfig["key"];
  disabledOptions?: AdvancedFilterConfig["key"][];
  onChange: (key: AdvancedFilterConfig["key"]) => void;
}

export const SelectProperty = ({
  options,
  value,
  disabledOptions = [],
  onChange,
}: SelectPropertyProps) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredProperties = useMemo(() => {
    if (!searchQuery) return options;
    return searchProperties(options, searchQuery);
  }, [options, searchQuery]);

  const groupedProperties = useMemo(() => {
    return groupPropertiesByCategory(filteredProperties);
  }, [filteredProperties]);

  const handleOnClick = (key: AdvancedFilterConfig["key"]) => {
    if (disabledOptions.includes(key)) return;
    onChange(key);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.search_bar_w}>
        <input
          className={styles.search_bar_i}
          type={"text"}
          placeholder={"search"}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      {Object.entries(groupedProperties).map(([category, properties]) => (
        <div key={category} className={styles.group_w}>
          <div className={styles.group_h}>
            <div className={styles.group_hi}>+</div>
            <div className={styles.group_t}>{category}</div>
          </div>
          <div className={styles.group_b}>
            {properties.map((property) => (
              <div
                key={property.key}
                className={clsx(
                  styles.property_w,
                  property.key === value && styles.selected,
                  disabledOptions.includes(property.key) && styles.disabled
                )}
                onClick={() => handleOnClick(property.key)}
              >
                <div className={styles.property_i}>
                  <PropertyIcon iconName={property.icon} />
                </div>
                <div className={styles.property_l}>{property.label}</div>
                <div className={styles.property_d}>{property.description}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
