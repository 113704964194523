import {PropsWithChildren} from "react";
import styles from './Stepper.module.css'

export const StepperHeaderWrapper = ({children}:PropsWithChildren) => {



    return <div className={styles.StepperHeader_wrapper}>
        {children}
    </div>
}