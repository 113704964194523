import React from "react";

interface PageLayoutProps extends React.PropsWithChildren {
  title: string;
  description?: string;
  actions?: React.ReactNode;
  extended?: boolean;
}

export const PageLayout = ({
  title,
  description,
  actions,
  children,
  extended,
}: PageLayoutProps) => {
  return (
    <div>
      <div className={`bg-black py-1 ${extended ? "mb-[-128px] pb-32" : ""}`}>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-10">
          <div className="flex flex-col gap-2 md:gap-0 md:flex-row md:justify-between md:items-end text-white">
            <div className="space-y-2">
              <div className="text-3xl font-thin">{title}</div>
              <p className="font-light">{description}</p>
            </div>
            {actions && <div className="flex gap-4">{actions}</div>}
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-10">
        {children}
      </div>
    </div>
  );
};