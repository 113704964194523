import React from "react";
import { ServiceInput } from "models/service/Service";
import {
  ConfirmationStepContainer,
  ExternalServiceProviderStepContainer,
  ServiceCreationStepContainer,
} from "containers/ServiceAddContainer";
import { PageLayout } from "components/Layout";
import { Step, StepperProvider } from "componentsv2/shared/StepperComponent";
import { useStepper } from "hooks/stepper/useStepper";
import { StepType } from "types/Stepper";

const STEPS: StepType[] = [
  {
    stepKey: "SELECT_PROVIDER",
    order: 1,
    description: "Select a provider",
  },
  {
    stepKey: "SERVICE_IMPORT",
    order: 2,
    description: "Import your service",
  },
  {
    stepKey: "CONFIRMATION",
    order: 3,
    description: "Confirm your service import",
  },
];

export const AdminServiceAddPage = () => {
  const stepperMethods = useStepper({
    steps: STEPS,
    defaultStep: STEPS[0].stepKey,
  });
  const [services, setServices] = React.useState<ServiceInput[]>([]);

  return (
    <PageLayout
      title="Service Import"
      description="Integrate, create and manage new services with ease"
      extended
    >
      <div className="border-2 border-black">
        <StepperProvider {...stepperMethods}>
          <Step stepKey={STEPS[0].stepKey}>
            <ExternalServiceProviderStepContainer
              skipLabel={"I want to do it myself"}
              onSkip={() => {
                stepperMethods.goNextStep();
                setServices([]);
              }}
              onNextStep={(services) => {
                stepperMethods.goNextStep();
                setServices(services);
              }}
            />
          </Step>
          <Step stepKey={STEPS[1].stepKey}>
            <ServiceCreationStepContainer
              importedServices={services}
              onNextStep={(services) => {
                stepperMethods.goNextStep();
                setServices(services);
              }}
              onPreviousStep={() => stepperMethods.goPrevStep()}
            />
          </Step>
          <Step stepKey={STEPS[2].stepKey}>
            <ConfirmationStepContainer
              services={services}
              onPreviousStep={() => stepperMethods.goPrevStep()}
            />
          </Step>
        </StepperProvider>
      </div>
    </PageLayout>
  );
};
