import { Checkbox, TableCell, TableRow, Tag } from "_sredx/_ui";
import { ServiceBaseDto } from "_sredx/types";
import { resolveIntegration } from "_sredx/utils";
import { ServiceProvider } from "types/discovery";
import styles from "./OnboardingProviderImportedServicesRows.module.css";

interface OnboardingProviderImportedServicesRowsProps {
  serviceProvider: ServiceProvider;
  onCheckChange: (checked: boolean, service: ServiceBaseDto) => void;
  importedServices: ServiceBaseDto[];
  checkedServices?: ServiceBaseDto[];
}

export const OnboardingProviderImportedServicesRows = ({
  serviceProvider,
  onCheckChange,
  importedServices=[],
  checkedServices = [],
}: OnboardingProviderImportedServicesRowsProps) => {
  const ProviderIcon = serviceProvider.iconComponent;
  return (
    <>
      {importedServices.map((service: ServiceBaseDto) => {
        const checked = checkedServices.includes(service);
        return (
          <TableRow key={service.name}>
            <TableCell>
              <Checkbox
                checked={checked}
                onCheck={(e) => onCheckChange(e, service)}
                label={service.name}
              >
                <div className={styles.icon_wrapper}>
                  {ProviderIcon && <ProviderIcon />}
                </div>
              </Checkbox>
            </TableCell>
            <TableCell align={"left"}>
              <div className={styles.tags_wrapper}>
                {service.metadata?.tags?.map((tag, index) => (
                  <Tag key={index} tag={tag} />
                ))}
              </div>
            </TableCell>
            <TableCell align={"right"}>
              <div className={styles.tools_wrapper}>
                {service.tools.map((tool) => (
                  <div key={tool.key} className={styles.integration_icon}>
                    {resolveIntegration(tool.key).icon}
                  </div>
                ))}
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
