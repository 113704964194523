import { useState } from "react";
import {  useQuery, useQueryClient } from "react-query";
import { KubeConfigResponseDto, triggerDownload } from "./kube-config/KubeConfig.service";
import { useAxios } from "hooks/useAxios";
import { SELF_SERVICE_PATHS } from "./paths";


export function useKubeConfig() {
    const [downloadingCluster, setDownloadingCluster] = useState<string | null>(null);

    const queryClient = useQueryClient();

    const { axios } = useAxios();

    const fetchKubeConfig = async (clusterName: string): Promise<KubeConfigResponseDto> => {
        const response = await axios.get<KubeConfigResponseDto>(SELF_SERVICE_PATHS.CLUSTER_DETAIL_KUBECONFIG(clusterName));
        return response.data;
    };


    const { isFetching } = useQuery(
        ['kubeConfig', downloadingCluster],
        () => fetchKubeConfig(downloadingCluster as string),
        {
            enabled: !!downloadingCluster,
            onSuccess: (data) => {
                if (downloadingCluster) {
                    const decodedKubeConfig = atob(data?.kubeconfig || "");
                    triggerDownload(decodedKubeConfig, `kubeconfig_${downloadingCluster}.yaml`);
                    setDownloadingCluster(null);
                }
            },
            onError: (error) => {
                console.error("Error downloading kubeconfig", error);
                setDownloadingCluster(null);
            },
            retry: false,
        }
    );

    const handleDownload = (clusterId: string) => {
        setDownloadingCluster(clusterId);
        queryClient.invalidateQueries(['kubeConfig', clusterId]);
    };

    const isClusterDownloading = (clusterId: string) => {
        return downloadingCluster === clusterId && isFetching;
    };

    return {
        isClusterDownloading,
        handleDownload
    };
}
