import { useMemo } from "react";
import { ScorecardAccordionCategoryRow } from "../ScorecardAccordionCategoryRow";
import { ScorecardAccordionRuleRow } from "../ScorecardAccordionRuleRow";
import styles from "./ScorecardRulesByCategory.module.css";
import { Rule, ValueObject } from "_sredx/types";

interface ScorecardRulesByCategoryProps {
  rules?: {
    rule: Rule;
    errors: string[];
    success: boolean;
    actualValue: ValueObject;
  }[];
}

export const ScorecardRulesByCategory = ({
  rules,
}: ScorecardRulesByCategoryProps) => {
  const clusterRulesByCategory = (
    rules: {
      rule: Rule;
      errors: string[];
      success: boolean;
      actualValue: ValueObject;
    }[]
  ) => {
    const categories = rules.map(
      (rule: {
        rule: Rule;
        errors: string[];
        success: boolean;
        actualValue: ValueObject;
      }) => rule?.rule?.ruleCategory?.categoryName || ""
    );
    const uniqueCategories = categories.filter(
      (category: string, index: number) => {
        if (category === "" || category === undefined) return false;
        return categories.indexOf(category) === index;
      }
    );
    const groupedRules = uniqueCategories.map((category) => {
      return {
        category,
        rules: rules.filter(
          (rule) => rule.rule.ruleCategory?.categoryName === category
        ),
      };
    });
    return groupedRules;
  };
  const rulesByCategory = useMemo(
    () => clusterRulesByCategory(rules ?? []),
    [rules]
  );

  return (
    <div>
      {rulesByCategory.map((category, i) => (
        <div key={i}>
          <ScorecardAccordionCategoryRow category={category} />
          <div className={styles.rules_wrapper}>
            {category.rules.map((rule, i) => (
              <ScorecardAccordionRuleRow rule={rule} key={i} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
