import { TableHead, TableHeader } from "_sredx/_ui";

export const InitiativeDetailsRulesHeader = () => {
  return (
    <TableHeader>
      <TableHead>rule name</TableHead>
      <TableHead>status</TableHead>
      <TableHead>scorecard name</TableHead>
      <TableHead>services</TableHead>
      <TableHead>progress</TableHead>
      <TableHead></TableHead>
    </TableHeader>
  );
};
