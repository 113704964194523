import {
  Empty,
  Loader,
  Pagination,
  ProgressBar,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_sredx/_ui";
import { InitiativesAssignmentRow } from "_sredx/components/InitiativesAssignmentTable/InitiativesAssignmentRow";
import { InitiativeBaseDto } from "_sredx/types";
import styles from "./InitiativesAssignmentTable.module.css";

interface InitiativesAssignmentTableProps {
  initiatives: InitiativeBaseDto[];
  checkedInitiatives: InitiativeBaseDto[];
  isLoading?: boolean;
  isFetching?: boolean;
  currentPage?: number;
  totalPages?: number;
  totalInitiatives?: number;
  onPageChange?: (page: number) => void;
  onCheckChange: (checked: boolean, initiativeId: string) => void;
}

export const InitiativesAssignmentTable = ({
  initiatives,
  checkedInitiatives,
  isLoading = false,
  isFetching = false,
  currentPage = 0,
  totalPages = 1,
  totalInitiatives = 0,
  onCheckChange,
  onPageChange,
}: InitiativesAssignmentTableProps) => {
  const headerCells = ["name", "startDate", "endDate", "status"];
  const showPagination = totalPages > 1;

  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };
  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"sm"}>Loading rules...</Text>
      </div>
    );
  }
  if (!isLoading && initiatives.length === 0) {
    return <Empty message={"No initiatives found"} />;
  }

  return (
    <Table>
      <TableHeader>
        {headerCells.map((cell) => (
          <TableHead key={cell} className={styles.table_head}>
            <Text>{cell}</Text>
          </TableHead>
        ))}
      </TableHeader>
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}

        <InitiativesAssignmentRow
          onCheckChange={onCheckChange}
          initiatives={initiatives}
          checkedInitiatives={checkedInitiatives}
        />
      </TableBody>
      <TableFooter>
        <TableFooterCell colSpan={4}>
          <div className={styles.footer}>
            <Text>
              {checkedInitiatives.length} selected - {totalInitiatives}{" "}
              Initiatives
            </Text>
            {showPagination && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handleOnPageChange}
                currentPage={currentPage}
              />
            )}
          </div>
        </TableFooterCell>
      </TableFooter>
    </Table>
  );
};
