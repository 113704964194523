import React from "react";
import Overview from "models/scorecard/Overview";
import style from "./ScorecardOverviewComponent.module.css";
import clsx from "clsx";
import { getAllServicesCount , getValidPercentage } from "./utils";

interface ScorecardOverviewSectionComponentProps {
  overview: Overview;
}

export const ScorecardOverviewSectionComponent = ({
  overview,
}: ScorecardOverviewSectionComponentProps) => {
  
  const allServicesCount = getAllServicesCount(overview);

  return (
    <div>
      <div className={style.title}>Services overview </div>
      <div className={style.status_bar_wrapper}>
        <div
          className={clsx(style.status_bar, style.failing)}
          style={{ width: `${getValidPercentage(allServicesCount , overview.failing)}%` }}
        ></div>
        <div
          className={clsx(style.status_bar, style.needs_attention)}
          style={{ width: `${getValidPercentage(allServicesCount , overview.needsAttention)}%` }}
        ></div>
        <div
          className={clsx(style.status_bar, style.progressing)}
          style={{ width: `${getValidPercentage(allServicesCount , overview.progressing)}%` }}
        ></div>
        <div
          className={clsx(style.status_bar, style.passing)}
          style={{ width: `${getValidPercentage(allServicesCount , overview.passing)}%` }}
        ></div>
       { allServicesCount == 0 && <div
          className={clsx(style.status_bar, style.no_services)}
        ></div>}
      </div>

      <div>
        <div className={style.legend_wrapper}>
          <div className={style.legend}>
            <div className={clsx(style.dot, style.failing)}> </div>
            <span>failing : {overview.failing}</span>
          </div>
          <div className={style.legend}>
            <div className={clsx(style.dot, style.needs_attention)}> </div>
            <span>needs attention : {overview.needsAttention}</span>
          </div>

          <div className={style.legend}>
            <div className={clsx(style.dot, style.progressing)}> </div>
            <span>progressing : {overview.progressing}</span>
          </div>
          <div className={style.legend}>
            <div className={clsx(style.dot, style.passing)}> </div>
            <span>passing : {overview.passing}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
