import React, { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import styles from "./Breadcrumbs.module.css";
import { insertSeparators } from "./utils";
import { Text } from "../Text";

interface BreadcrumbsProps {
  separator?: ReactNode;
}

export const Breadcrumbs = ({
  separator = <Text>/</Text>,
  children,
}: PropsWithChildren<BreadcrumbsProps>) => {
  const allItems: ReactNode[] = React.Children.toArray(children)
    .filter((item) => React.isValidElement(item))
    .map((child, index) => (
      <li
        className={clsx("nemo-breadcrumbs-item", styles.item)}
        key={`child-${index}`}
      >
        {child}
      </li>
    ));

  return (
    <nav
      className={clsx("nemo-breadcrumbs", styles.wrapper)}
      aria-label="breadcrumb"
    >
      <ol className={clsx("nemo-breadcrumbs-list", styles.list)}>
        {insertSeparators(
          allItems,
          clsx("nemo-breadcrumbs-separator", styles.separator),
          separator
        )}
      </ol>
    </nav>
  );
};
