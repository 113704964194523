import { useMutation, useQueryClient } from "react-query";
import {useAxios} from "./useAxios";

import { deleteServiceById } from "services/services";
import Service from "models/service/Service";
import { QUERY_KEYS } from "_sredx/config";

export const useDeleteService = () => {
    const queryClient = useQueryClient();
    const { axios } = useAxios();
  
    return useMutation(
      (id: string) => deleteServiceById(axios, id),
      {
        onMutate: async (id: string) => {
            await queryClient.cancelQueries('services');
            
            const previousServices = queryClient.getQueryData<Service[]>('services');
            
            queryClient.setQueryData<Service[]>(
              'services', 
              (old: Service[] | undefined) => old?.filter(service => service.id !== id) || []
            );
          
            return { previousServices };
          },
          
        onError: (err, id, context) => {
          if (context?.previousServices) {
            queryClient.setQueryData('services', context.previousServices);
          }
        },
        onSuccess: () => {
          queryClient.invalidateQueries(QUERY_KEYS.services);
        },
      }
    );
};
  
  