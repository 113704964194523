import { LayoutBody, LayoutMain, LayoutSidebar, PageContent } from "_sredx/_ui";
import { LayoutSidebarCollapseButton } from "_sredx/_ui/Layout/LayoutSidebarCollapseButton";
import { useMe } from "_sredx/services";
import clsx from "clsx";
import { REACT_APP_USE_V2 } from "config/config";
import { LoadingPage } from "pages/loading";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppV1Store } from "state/appv2";
import { AppHeader } from "../AppHeader";
import { AppSideMenu } from "../AppSideMenu";
import styles from "./AppLayout.module.css";

const ShowV2App = () => {
  const [isV2defined] = useState(REACT_APP_USE_V2 !== undefined);
  const [show, setShow] = useState(true);
  const { isV1Enabled, toggleV1 } = useAppV1Store();

  const navigate = useNavigate();

  const handleOnToggle = () => {
    toggleV1();
    navigate("/");
  };

  if (!show || !isV2defined) {
    return null;
  }
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        textAlign: "center",
        zIndex: 9999,
        padding: "1rem 2rem",
        color: "#fff",
        background: "#000",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <button onClick={handleOnToggle}>
        Use {!isV1Enabled ? "version 1" : "version 2"} ? Yes
      </button>
      <button onClick={() => setShow(false)}>close</button>
    </div>
  );
};

export const AppLayout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const { data: currentUser, isLoading, error } = useMe();
  const navigate = useNavigate();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error || !currentUser) {
    return null;
  }

  if (currentUser.role == "ADMIN" && !currentUser.onboarded) {
    navigate("/admin-onboarding");
    return null;
  }

  const displayName =
    currentUser.firstName && currentUser.lastName
      ? `${currentUser.firstName} ${currentUser.lastName}`
      : ( currentUser.username ?? currentUser.email);

  return (
    <>
      <div className={clsx("nemo-app-layout", styles.wrapper)}>
        <LayoutBody>
          <LayoutSidebar isCollapsed={isCollapsed}>
            <AppSideMenu isCollapsed={isCollapsed} />
          </LayoutSidebar>
          <LayoutSidebarCollapseButton
            isCollapsed={isCollapsed}
            toggleCollapse={toggleCollapse}
          />
          <LayoutMain>
            <PageContent>
              <AppHeader username={displayName} />
            </PageContent>
            <Outlet />
          </LayoutMain>
        </LayoutBody>
      </div>
      <ShowV2App />
    </>
  );
};
