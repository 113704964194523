export const QUERY_KEYS = {
  // Teams
  teams: "TEAMS",
  team: "TEAM",
  infiniteTeams: "INFINITE_TEAMS",
  teams_rankings: "TEAMS_RANKINGS",
  teamHealth: "TEAM_HEALTH",

  //dashboard
  dashboard_widget_data: "DASHBOARD_WIDGET_DATA",

  // Users
  users: "USERS",
  me: "ME",
  search_members: "SEARCH_MEMBERS",
  members: "MEMBERS",
  infiniteUsers: "INFINITE_USERS",

  // ScoreCards
  scoreCards: "SCORE_CARDS",
  rules: "RULES",

  // Evaluations
  evaluationsLevels: "EVALUATIONS_LEVELS",

  // services
  services: "SERVICES",
  infiniteServices: "INFINITE_SERVICES",
  infiniteEvaluatedServices: "INFINITE_EVALUATED_SERVICES",
  serviceHealth: "SERVICE_HEALTH",

  // Initiatives
  initiatives: "INITIATIVES",
  evaluatedInitiatives: "EVALUATED_INITIATIVES",
  initiativeRules: "INITIATIVE_RULES",
  initiativeServices: "INITIATIVE_SERVICES",

  // Roadmaps
  roadmaps: "ROADMAPS",
  roadmap: "ROADMAP",

  // services scores
  services_scores: "SERVICES_SCORES",

  //self-service
  clusters: "CLUSTERS",
  kubConfig: "KUBE_CONFIG",
  cluster_foundations: "CLUSTER_FOUNDATION",
  cluster_managedResources: "CLUSTER_MANAGED_RESOURCES",

  //invitations
  invitations: "INVITATIONS",

  //dashboard
  dashboardWidget: "DASHBOARD_WIDGET",
  //configurations
  configurations: "CONFIGURATIONS",
  connectionTest: "CONNECTION_TEST",
  integration_instances: "INTEGRATION_INSTANCES",

  //discovery
  servicesDiscovery: "SERVICE_DISCOVERY",
  usersDiscovery: "USERS_DISCOVERY",

  // metrics
  pullRequests: "PULL_REQUESTS",

  // domains
  domains: "DOMAINS",
  infiniteDomains: "INFINITE_DOMAINS",

  //integration instance
  integrationInstances: "INTEGRATION_INSTANCES",

  // platforms
  resources: "RESOURCES",
  infiniteResources: "INFINITE_RESOURCES",

  // integrations
  integrations: "INTEGRATIONS",
};

export const MUTATION_KEYS = {
  // Teams
  createTeam: "CREATE_TEAM",
  affectMember: "AFFECT_MEMBER",
  assignServices: "ASSIGN_SERVICES",
  importTeams: "IMPORT_TEAMS",
  updateTeam: "UPDATE_TEAM",
  deleteTeam: "DELETE_TEAM",

  // Initiatives
  createInitiative: "CREATE_INITIATIVE",
  addInitiativeRules: "AFFECT_INITIATIVE_RULES",
  addInitiativeServices: "AFFECT_INITIATIVE_SERVICES",
  updateInitiativeRules: "UPDATE_INITIATIVE_RULES",
  updateInitiativeServices: "UPDATE_INITIATIVE_SERVICES",
  updateInitiatives: "UPDATE_INITIATIVES",

  //Self-service
  deleteCluster: "DELETE_CLUSTER",

  //onBoarding
  createAccount: "CREATE_ACCOUNT",

  //Roadmaps
  createRoadmap: "CREATE_ROADMAP",
  addRoadmapInitiatives: "ADD_ROADMAP_INITIATIVES",

  //services
  createServices: "CREATE_SERVICES",

  //config
  updateConfig: "UPDATE_CONFIG",

  //users
  updateProfile: "UPDATE_PROFILE",

  //evaluations
  evaluateService: "EVALUATE_SERVICE",

  // domains
  createDomain: "CREATE_DOMAIN",
  updateDomain: "UPDATE_DOMAIN",
  deleteDomain: "DELETE_DOMAIN",

  //instances
  createInstance: "CREATE_INSTANCE",
  updateInstance: "UPDATE_INSTANCE",
  deleteInstance: "DELETE_INSTANCE",
  //resources
  createResource: "CREATE_RESOURCE",
  updateResource: "UPDATE_RESOURCE",
  deleteResource: "DELETE_RESOURCE",
};
