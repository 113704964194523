import { TableCell, TableRow, Text } from "_sredx/_ui";
import { ServiceBaseDto } from "_sredx/types";
import styles from "./ServicesAssignementTable.module.css";

interface ServicesAssignmentTableRowsProps {
  services?: ServiceBaseDto[];
  onCheckChange: (checked: boolean, serviceId: string) => void;
  checkedServices?: string[];
}

export const ServicesAssignmentTableRows = ({
  services = [],
  onCheckChange,
  checkedServices = [],
}: ServicesAssignmentTableRowsProps) => {
  return (
    <>
      {services.map((service) => {
        const checked = checkedServices.includes(service.id);
        return (
          <TableRow>
            <TableCell>
              <label className={styles.checkbox_wrapper}>
                <input
                  type={"checkbox"}
                  checked={checked}
                  onChange={(e) => onCheckChange(e.target.checked, service.id)}
                />
                <Text>{service.name}</Text>
              </label>
            </TableCell>
            <TableCell align={"right"}>
              <Text>{service.owners.length}</Text>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
