import {
  Button,
  ButtonLabel,
  Card,
  Heading,
  LogoSREDXBlack,
  Text,
} from "_sredx/_ui";
import { InvitationDto } from "_sredx/types";
import { getFullName } from "_sredx/utils/helpers";
import { OnBoardingStep, OnBoardingSteps } from "../types";
import { TextHighlighter } from "./TextHighlighter";
import styles from "./UserWelcomeStep.module.css";

interface UserWelcomeStepProps extends OnBoardingStep {
  invitation?: InvitationDto;
}

export const UserWelcomeStep = ({
  onNextStep,
  currentStep,
  invitation,
}: UserWelcomeStepProps) => {
  const handleOnClick = () => {
    if (onNextStep) {
      onNextStep();
    }
  };
  const senderFullName = getFullName(
    invitation?.inviterFirstName,
    invitation?.inviterLastName
  );
  if (currentStep !== OnBoardingSteps.WELCOME_STEP) {
    return null;
  }
  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.wrapper_header}>
          <LogoSREDXBlack />
        </div>
        <Heading level={3}>Welcome,</Heading>
        <div className={styles.wrapper_body}>
          <Text>
            You have been invited by {TextHighlighter(senderFullName)} to join{" "}
            {TextHighlighter("SRE-DX platform")}
          </Text>
        </div>
        <div className={styles.action_wrapper}>
          <Button
            className={styles.action_button}
            size={"medium"}
            onClick={handleOnClick}
          >
            <ButtonLabel>Get Started</ButtonLabel>
          </Button>
        </div>
      </div>
    </Card>
  );
};
