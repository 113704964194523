import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";

export const useDeleteDomain = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEYS.deleteDomain],
    mutationFn: (domainId: string) => {
      return ClientApi.delete(API_ENDPOINTS.deleteDomain(domainId));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.domains], {
        exact: false,
      });
    },
  });
};
