import React from "react";
import styles from "./ServiceDeleteModal.module.css";
import { Button, Card, Heading } from "_sredx/_ui";
import { UseMutationResult } from "react-query";
import { Service } from "../ServicesList";

interface ServiceDeleteModalProps {
  service: {
    id: string;
    name: string;
  };
  deleteMutation: UseMutationResult<
    void,
    unknown,
    string,
    {
      previousServices: Service[] | undefined;
    }
  >;
  onClose: () => void;
}

export const ServiceDeleteModal = ({
  service,
  deleteMutation,
  onClose,
}: ServiceDeleteModalProps) => {
  const { mutate, isLoading } = deleteMutation;

  const handleDeleteService = () => {
    mutate(service.id, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Heading level={4}>Confirm Deletion</Heading>
          <p>
            Are you sure you want to delete <strong>{service.name}</strong>?
          </p>
        </div>
        <div className={styles.actions}>
          <Button
            variant="danger"
            onClick={handleDeleteService}
            disabled={isLoading}
            className={styles.deleteButton}
          >
            Delete
          </Button>
          <Button ghost onClick={onClose} className={styles.cancelButton}>
            Cancel
          </Button>
        </div>
      </div>
    </Card>
  );
};
