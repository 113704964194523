import ServiceScoreCardStats from "models/evaluations/ServiceStatsByScoreCard";
import avatar from "./avatar.svg";

export const serviceStatsData : ServiceScoreCardStats = {
    overview : {
        rankOnScoreCard : 5,
        scoreOnScoreCard : 2,
        passingRules : 7,
        totalEvaluatedRules : 27
    },
    rulesStatus : {}
}

export const serviceMainData = {
    id : "1",
    name : "SREDX BACKEND SERVICE",
    tags : [{
        name : "API",
        color : "34 ,153, 171"
    },{
        name : "API", 
        color : "26 ,138, 64"
    }],
    owners : [
        {id : "1",
        name : "team1",
        avatar : avatar
    }

    ]
}

export const serviceScorecardEval = {
    serviceId: "1",
    scoreCardId : "1",
    evaluationResult : {
        score : 211,
        totalPossible: 259
    }

}


