import { forwardRef } from "react";
import { IconProps } from "./types";

export const BitbucketIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>bitbucket</title>
        <path
          fill="#2684FF"
          d="M1.714 2a.702.702 0 0 0-.543.247.718.718 0 0 0-.162.58L4.001 21.19a.962.962 0 0 0 .939.81h14.355a.708.708 0 0 0 .705-.599l2.992-18.57a.718.718 0 0 0-.162-.58.702.702 0 0 0-.543-.247L1.714 2Zm12.6 13.273H9.732L8.492 8.72h6.932l-1.11 6.553Z"
        />
        <path
          fill="url(#bitBucketA)"
          fillRule="evenodd"
          d="M22.04 8.72h-6.616l-1.11 6.553H9.732l-5.41 6.492a.95.95 0 0 0 .617.235h14.359a.707.707 0 0 0 .705-.599l2.037-12.68Z"
          clipRule="evenodd"
        />
        <defs>
          <linearGradient
            id="bitBucketA"
            x1="23.569"
            x2="15.816"
            y1="10.555"
            y2="21.445"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".18" stopColor="#0052CC" />
            <stop offset="1" stopColor="#2684FF" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
);
