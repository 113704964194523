import clsx from "clsx";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";
import styles from "./ClusterCardProperties.module.css";
import { ReactNode } from "react";

type KeyValueProperties = {
  key: string;
  value: string | JSX.Element | ReactNode;
  icon: IconType;
  link?: string;
};

interface ClusterCardPropertiesProps {
  properties: KeyValueProperties[];
}

export const ClusterCardProperties = ({
  properties,
}: ClusterCardPropertiesProps) => {
  return (
    <div className={clsx(styles.wrapper)}>
      {properties.map((property, i) => (
        <>
          <div className={styles.key}>
            <span>
              <property.icon />
            </span>
            {property.key}
          </div>
          {property.link ? (
            <Link to={property.link} target="_blank" className={styles.link}>
              {property.value}
            </Link>
          ) : (
            <div className={styles.value}>{property.value}</div>
          )}
        </>
      ))}
    </div>
  );
};
