import { CreateGitopsAccountDTO } from 'types/selfService';
import * as yup from 'yup';

export interface GitopsAccountFormData {
    name: string;
    owner: string;
    accessToken: string;
}


export const schema = yup.object<GitopsAccountFormData>().shape({
    name: yup.string().required('Name is required.'),
    owner: yup.string().required('Owner is required.'),
    accessToken: yup.string().required('AccessToken is required.')
});


export function mapFormDataToApiData(formData: GitopsAccountFormData): CreateGitopsAccountDTO {
    // in case api will change later
    return {
        name: formData.name,
        owner: formData.owner,
        accessToken: formData.accessToken,
    };
}