import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ApiErrorResponse, InitiativeCreationDto } from "_sredx/types";
import { ClientApi } from "_sredx/services/clientApi";

export const useInitiativeCreation = () => {
  const queryClient = useQueryClient();
  return useMutation<string, ApiErrorResponse, InitiativeCreationDto>({
    mutationKey: [MUTATION_KEYS.createInitiative],
    mutationFn: (initiativeCreationDto: InitiativeCreationDto) => {
      return ClientApi.post(
        API_ENDPOINTS.createInitiative(),
        initiativeCreationDto
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.initiatives], {
        exact: false,
      });
    },
  });
};
