import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import { ManagedResource } from "_sredx/types";
import { isUndefined } from "_sredx/utils";
import { useQuery } from "react-query";

interface UseGetClusterManagedRessources {
  clusterId?: string;
}

export const useGetClusterManagedRessources = ({
  clusterId,
}: UseGetClusterManagedRessources) => {
  return useQuery<ManagedResource[]>({
    queryKey: [QUERY_KEYS.cluster_managedResources, clusterId],
    queryFn: async () =>
      await ClientApi.get(
        API_ENDPOINTS.getClusterManagedRessources(clusterId!)
      ),
    enabled: !!clusterId && !isUndefined(clusterId),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
