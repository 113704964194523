import { Loader } from "_sredx/_ui";
import DonutCard from "_sredx/components/DonutCard/DonutCard";
import { useGetWidgetData } from "_sredx/services";
import { DonutData } from "_sredx/types";
import { DASHBOARD_WIDGETS } from "_sredx/types/dashboards";

const ServiceHealthContainer = () => {
  const { data, isLoading } = useGetWidgetData<{ data: DonutData[] }>({
    key: DASHBOARD_WIDGETS.SERVICES.SERVICES_HEALTH,
  });

  if (isLoading) return <Loader />;
  if (!data) return <div>Something went wrong</div>;
  return <DonutCard data={data.data} isLoading={isLoading} />;
};

export default ServiceHealthContainer;
