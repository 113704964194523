import React, { useEffect, useRef } from "react";
import Hero, { HeroProps } from "./Hero";
import styles from "./NewHero.module.css";
import { canvasStarProcedure } from "./utils";

export const NewHero = ({ onGetStarted }: HeroProps) => {
  const sceneRef = useRef(null);
  //stars canvas animation
  useEffect(() => {
    const canvas = sceneRef.current as unknown as HTMLCanvasElement;
    const c = canvas.getContext("2d");
    canvasStarProcedure(canvas, c);
  }, []);

  return (
    <div className={styles.hero}>
      <div className={styles.gridMask}></div>
      <div className={styles.grid}></div>
      <canvas
        style={{
          width: "100%",
          height: "100%",
          padding: 0,
          margin: 0,
          position: "absolute",
          zIndex: 3,
        }}
        className={styles.scene}
        ref={sceneRef}
      ></canvas>

      <div className={`${styles.grid}  ${styles.grid2}`}></div>
      <Hero onGetStarted={onGetStarted} />
    </div>
  );
};
