import styles from "./ScoreCardDetails.module.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  KeyValue,
  Tag,
} from "_sredx/_ui";
import React from "react";
import ScoreCard from "models/scorecard/ScoreCard";

interface ScoreCardDetailsProps {
  scoreCard: ScoreCard;
  lastEvaluation: Date;
}

export const ScoreCardDetails = ({
  scoreCard,
  lastEvaluation,
}: ScoreCardDetailsProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>About</CardTitle>
      </CardHeader>

      <CardBody>
        <div className={styles.body}>
          <KeyValue label={"Scorecard Name"} value={scoreCard.name} />
          <KeyValue
            className={styles.description}
            label={"Scorecard Description"}
            value={scoreCard.description}
          />
          <KeyValue label={"Tag"} value={<Tag tag={scoreCard.tag} />} />
          <KeyValue
            label={"Last Evaluation"}
            value={lastEvaluation.toLocaleString()}
          />
        </div>
      </CardBody>
      <CardFooter />
    </Card>
  );
};
