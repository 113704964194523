import clsx from "clsx";
import {LogoSREDXBlack, LogoSREDXWhite} from "_sredx/_ui/Logo";
import styles from "./HeaderLogo.module.css";

interface HeaderLogoProps {
    variant?: "black" | "white";
}
export const HeaderLogo = ({variant = "black"}: HeaderLogoProps) => {
  return (
    <div className={clsx("nemo-header-logo", styles.logo)}>
        {variant === 'black' ? <LogoSREDXBlack/> : <LogoSREDXWhite /> }
    </div>
  );
};
