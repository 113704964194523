import { TextInput } from "components/octo-ui/Forms/TextInput";
import { customStyles } from "components/scorecard/ScoreCardForm/EditRuleForm/styles";
import React, { ChangeEvent, FC } from "react";
import { Control, Controller } from "react-hook-form";
import Select from "react-select";
import BinaryOperator from "../../../models/scorecard/BinaryOperator";
import Rule from "../../../models/scorecard/Rule";
import ValueObject from "../../../models/scorecard/ValueObject";
import { FormErrorMessageComponent } from "../FormErroMessage/FormErrorMessageComponent";

interface GenericRuleOperandFieldComponentProps {
  returnType: "BOOLEAN" | "STRING" | "DOUBLE";
  binaryOperatorInitialValue?: BinaryOperator;
  targetOperandInitialValue?: ValueObject;
  formControl: Control<Rule, any>;
}

const GenericRuleOperandFieldComponent: FC<
  GenericRuleOperandFieldComponentProps
> = ({
  returnType,
  formControl,
  binaryOperatorInitialValue,
  targetOperandInitialValue,
}) => {
  const shouldRenderBinaryOperatorSelect = returnType === "DOUBLE";
  const binaryOperatorOptions = Object.keys(BinaryOperator).map((key) => {
    return {
      key,
      // @ts-ignore
      value: BinaryOperator[key],
    };
  });
  // @ts-ignore
  const initialValueObject = binaryOperatorOptions.find(
    (o) => o.value === binaryOperatorInitialValue
  );

  const shouldRenderBooleanSelect = returnType === "BOOLEAN";
  const booleanSelectOptions = [
    {
      key: "Positive",
      valueObject: {
        value: "TRUE",
        type: "BOOLEAN",
      },
    },
    {
      key: "Negative",
      valueObject: {
        value: "FALSE",
        type: "BOOLEAN",
      },
    },
  ];
  const initialBooleanSelectValue = booleanSelectOptions.find(
    (option) => option.valueObject.value === targetOperandInitialValue?.value
  );

  const shouldRenderTargetOperandTextField =
    returnType === "STRING" || returnType === "DOUBLE";
  const getInputFieldProps = () => {
    switch (returnType) {
      case "DOUBLE":
        return {
          type: "number" as "text" | "number" | "textarea",
          step: "any",
        };
      default:
        return { type: "text" as "text" | "number" | "textarea" };
    }
  };
  // @ts-ignore
  // @ts-ignore
  return (
    <>
      {shouldRenderBinaryOperatorSelect && (
        <div className="sm:col-span-6">
          <label
            htmlFor={`operation.binaryOperator`}
            className="block text-sm font-medium text-gray-700"
          >
            Choose a binary operator
          </label>
          <Controller
            control={formControl}
            rules={{
              required: {
                value: true,
                message: `BinaryOperator is a required parameter for this rule.`,
              },
            }}
            name={`operation.binaryOperator`}
            render={({ field: { onChange }, formState: { errors } }) => (
              <>
                <Select
                  styles={customStyles}
                  id={"binary-operator-select-field"}
                  classNamePrefix={"rule_binary_operator_field"}
                  options={binaryOperatorOptions}
                  defaultValue={initialValueObject}
                  onChange={(newValue) => {
                    onChange(newValue?.value);
                  }}
                  getOptionLabel={(option) => option.key!}
                  getOptionValue={(option) => option.value!}
                />

                <FormErrorMessageComponent
                  errors={errors}
                  fieldName={`operation.binaryOperator`}
                />
              </>
            )}
          />
        </div>
      )}
      {shouldRenderTargetOperandTextField && (
        <div className="sm:col-span-6">
          <label
            htmlFor={`operation.targetOperand`}
            className="block text-sm font-medium text-gray-700"
          >
            Expected result:
          </label>
          <div className="mt-1">
            <Controller
              control={formControl}
              rules={{
                required: {
                  value: true,
                  message: `Expected result is a required parameter for this rule.`,
                },
              }}
              name={`operation.targetOperand`}
              render={({ field: { onChange, ref }, formState: { errors } }) => (
                <>
                  <TextInput
                    id={`target-operand-input-field`}
                    defaultValue={targetOperandInitialValue?.value}
                    ref={ref}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onChange({
                        value: e.target.value,
                        type: returnType,
                      });
                    }}
                    {...getInputFieldProps()}
                  />

                  <FormErrorMessageComponent
                    errors={errors}
                    fieldName={`operation.targetOperand`}
                  />
                </>
              )}
            />
          </div>
        </div>
      )}
      {shouldRenderBooleanSelect && (
        <div className="sm:col-span-6">
          <label
            htmlFor={`operation.targetOperand`}
            className="block text-sm font-medium text-gray-700"
          >
            Expected result
          </label>
          <Controller
            control={formControl}
            rules={{
              required: {
                value: true,
                message: `Expected result is a required parameter for this rule.`,
              },
            }}
            name={`operation.targetOperand`}
            render={({ field: { onChange }, formState: { errors } }) => (
              <>
                <Select
                  styles={customStyles}
                  classNamePrefix={"rule_target_operand_field"}
                  id={`target-operand-input-field`}
                  options={booleanSelectOptions}
                  defaultValue={initialBooleanSelectValue}
                  onChange={(newValue) => {
                    onChange(newValue?.valueObject);
                  }}
                  getOptionLabel={(option) => option.key!}
                  getOptionValue={(option) => option.valueObject!.value}
                />

                <FormErrorMessageComponent
                  errors={errors}
                  fieldName={`operation.targetOperand`}
                />
              </>
            )}
          />
        </div>
      )}
    </>
  );
};

export default GenericRuleOperandFieldComponent;
