import styles from "./RoadmapCalendarInitiative.module.css";
import { InitiativeDto } from "_sredx/types";
import { InitiativeProgressBar } from "_sredx/components/InitiativeListing";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

interface RoadmapCalendarInitiativeProps {
  initiative: InitiativeDto;
}

export const RoadmapCalendarInitiative = ({
  initiative,
}: RoadmapCalendarInitiativeProps) => {
  const initiativeNameParentRef = useRef<HTMLDivElement | null>(null);
  const [isEllipsisActive, setisEllipsisActive] = useState(false);
  useEffect(() => {
    if (initiativeNameParentRef.current) {
      setisEllipsisActive(
        initiativeNameParentRef.current?.offsetWidth ===
          initiativeNameParentRef.current?.scrollWidth
      );
    }
  }, [initiativeNameParentRef.current]);
  return (
    <div
      className={clsx(
        styles.wrapper,
        isEllipsisActive && styles.cancel_animation
      )}
    >
      <div ref={initiativeNameParentRef} className={styles.name}>
        <span>{initiative.name}</span>
      </div>
      <span className={clsx(styles.status, styles[initiative.status])}>
        {initiative.status}
      </span>
      <div className={styles.progress}>
        <InitiativeProgressBar progress={initiative.progress} />
      </div>
    </div>
  );
};
