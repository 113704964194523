import { Overview } from "_sredx/types";
import styles from "./ScoreCardOverview.module.css";

export const resolveStatuses = (overview?: Overview) => {
  if (!overview) {
    return null;
  }

  return [
    {
      label: "failing",
      value: overview.failing,
      color: styles.failing,
    },
    {
      label: "needs attention",
      value: overview.needsAttention,
      color: styles.needs_attention,
    },

    {
      label: "progressing",
      value: overview.progressing,
      color: styles.progressing,
    },
    {
      label: "passing",
      value: overview.passing,
      color: styles.passing,
    },
  ];
};
