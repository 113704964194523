import { XIcon } from "@heroicons/react/outline";
import { Button, ButtonIcon, Card, Heading } from "_sredx/_ui";
import { useForm } from "react-hook-form";
import { FormFieldInput } from "../Form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SCHEMA } from "./utils";

interface ShortcutModalProps {
  onClose: () => void;
  onSave: (shortcut: { title: string; url: string }) => void;
  onDelete?: (shortcut: { title: string; url: string; id: number }) => void;
  shortcut?: {
    title: string;
    url: string;
    id: number;
  };
}
export const ShortcutModal = ({
  onClose,
  onSave,
  onDelete,
  shortcut,
}: ShortcutModalProps) => {
  const handleCurrentPage = () => {
    methods.setValue("url", window.location.pathname);
  };

  const methods = useForm({
    defaultValues: shortcut,
    resolver: yupResolver(SCHEMA),
  });

  const handleSave = () => {
    onSave(methods.getValues());
    onClose();
  };

  const handleDelete = () => {
    if (onDelete && shortcut) onDelete(shortcut);
    onClose();
  };

  return (
    <Card>
      <form {...methods}>
        <div className="flex flex-col gap-4 p-4 min-w-[400px]">
          <div className="flex items-center gap-2">
            <Heading level={3}>{onDelete ? "Edit" : "Add a"} Shortcut</Heading>

            <Button
              className="ml-auto"
              onClick={onClose}
              ghost
              variant="neutral"
            >
              <ButtonIcon>
                <XIcon height={16} />
              </ButtonIcon>
            </Button>
          </div>

          <div className="flex flex-col gap-2">
            <FormFieldInput
              name={"title"}
              control={methods.control}
              placeholder={"Title"}
              errorMessage={methods.formState.errors.title?.message}
            />
            <FormFieldInput
              name={"url"}
              control={methods.control}
              placeholder={"Path"}
              errorMessage={methods.formState.errors.url?.message}
            />
            <Button onClick={handleCurrentPage} ghost variant="neutral">
              Use current page
            </Button>
          </div>
          <div className="flex justify-end gap-2">
            {onDelete && (
              <Button variant="danger" onClick={handleDelete}>
                Delete
              </Button>
            )}
            <Button onClick={methods.handleSubmit(handleSave)}>Save</Button>
          </div>
        </div>
      </form>
    </Card>
  );
};
