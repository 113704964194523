import { TooltipChart } from "_sredx/_ui";
import styles from "./PRMergeFrequencyChart.module.css";

interface PRMergeFrequencyTooltipChartProps {
  data: { key: string; value: number; color: string };
}

export const PRMergeFrequencyTooltipChart = ({
  data,
}: PRMergeFrequencyTooltipChartProps) => {
  return (
    <TooltipChart className={styles.tooltip_wrapper}>
      <div
        className={styles.tooltip_legend_color}
        style={{ background: data.color }}
      ></div>
      <div>{data.key}</div>
      <div>
        <strong>{data.value}</strong> Pull requests
      </div>
    </TooltipChart>
  );
};
