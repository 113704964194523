import { XIcon } from "@heroicons/react/outline";
import { useToaster } from "hooks/useToaster";
import { useEffect, useMemo } from "react";
import { PiCheckCircleBold, PiInfo, PiWarningCircleBold } from "react-icons/pi";
import styles from "./styles.module.css";
import clsx from "clsx";

interface ToastProps {
  id: number;
  message: string;
  type: "success" | "error" | "warning" | "info";
}
export const Toast = ({ id, message, type }: ToastProps) => {
  const removeToast = useToaster((state) => state.removeToast);

  useEffect(() => {
    const timeout = setTimeout(() => {
      removeToast(id);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [id, removeToast]);

  const resolveType = (type: "success" | "error" | "warning" | "info") => {
    switch (type) {
      case "success":
        return { style: styles.success, icon: <PiCheckCircleBold size={20} /> };
      case "error":
        return { style: styles.error, icon: <PiWarningCircleBold size={20} /> };
      case "warning":
        return {
          style: styles.warning,
          icon: <PiWarningCircleBold size={20} />,
        };
      case "info":
        return {
          style: styles.info,
          icon: <PiInfo size={20} />,
        };
    }
  };
  const resolvedType = useMemo(() => resolveType(type), [type]);
  
  //animation implementation


  return (
    <div className={clsx(styles.toast, resolvedType.style,styles["fade-in-out"] )}>
      {resolvedType.icon}
      {message}
      <button className={styles.closeBtn} onClick={() => removeToast(id)}>
        <XIcon className="h-4" />
      </button>
    </div>
  );
};
