import { ChartPeriodType, PullRequestDto } from "_sredx/types";
import { addWeeks, differenceInWeeks, endOfWeek, startOfWeek } from "date-fns";

export const calculatePRAvgCycleTime = (pullRequests: PullRequestDto[]) => {
  const totalCycleTime = pullRequests.reduce((acc, pr) => {
    const cycleTime = pr.prCycleTime;
    return acc + cycleTime;
  }, 0);
  return totalCycleTime / pullRequests.length;
};

export const calculatePRAvgTimeToReview = (pullRequests: PullRequestDto[]) => {
  const totalReviewTime = pullRequests.reduce((acc, pr) => {
    const reviewTime = pr.timeToReview || 0;
    return acc + reviewTime;
  }, 0);

  return totalReviewTime / pullRequests.length;
};

export const calculatePRAvgTimeToMerge = (pullRequests: PullRequestDto[]) => {
  const totalMergeTime = pullRequests.reduce((acc, pr) => {
    const mergeTime = pr.timeToMerge || 0;
    return acc + mergeTime;
  }, 0);
  return totalMergeTime / pullRequests.length;
};

export const calculatePRAvgCodingTime = (pullRequests: PullRequestDto[]) => {
  const totalCodingTime = pullRequests.reduce((acc, pr) => {
    const codingTime = pr.codingTime;
    return acc + codingTime;
  }, 0);
  return totalCodingTime / pullRequests.length;
};

export const calculatePRAvgPickupTime = (pullRequests: PullRequestDto[]) => {
  const totalCodingTime = pullRequests.reduce((acc, pr) => {
    const pickupTime = pr.pickupTime;
    return acc + pickupTime;
  }, 0);
  return totalCodingTime / pullRequests.length;
};

export const calculatePRAvgReviewTime = (pullRequests: PullRequestDto[]) => {
  const totalReviewTime = pullRequests.reduce((acc, pr) => {
    const reviewTime = pr.reviewTime || 0;
    return acc + reviewTime;
  }, 0);

  return totalReviewTime / pullRequests.length;
};

export const calculatePRAvgApprovalTime = (pullRequests: PullRequestDto[]) => {
  const totalApprovalTime = pullRequests.reduce((acc, pr) => {
    const approvalTime = pr.approvalTime || 0;
    return acc + approvalTime;
  }, 0);

  return totalApprovalTime / pullRequests.length;
};

export const calculatePRAvgAdditions = (pullRequests: PullRequestDto[]) => {
  const totalAdditions = pullRequests.reduce((acc, pr) => {
    const additions = pr.nbrLineAdded || 0;
    return acc + additions;
  }, 0);
  return totalAdditions / pullRequests.length;
};

export const calculatePRAvgDeletions = (pullRequests: PullRequestDto[]) => {
  const totalDeletions = pullRequests.reduce((acc, pr) => {
    const deletions = pr.nbrLineDeleted || 0;
    return acc + deletions;
  }, 0);
  return totalDeletions / pullRequests.length;
};

export const isPRMerged = (pullRequest: PullRequestDto) => {
  return pullRequest.merged && pullRequest.mergedAt !== null;
};
export const getMergedPRs = (
  pullRequests: PullRequestDto[]
): PullRequestDto[] => {
  return pullRequests.filter(isPRMerged);
};

export const sortPRsByMergedAt = (pullRequests: PullRequestDto[]) => {
  return pullRequests.sort((a, b) => {
    if (a.mergedAt && b.mergedAt) {
      return new Date(a.mergedAt).getTime() - new Date(b.mergedAt).getTime();
    }
    return 0;
  });
};
export const calculatePRWeeklyAvgMergeFrequency = (
  pullRequests: PullRequestDto[]
) => {
  if (pullRequests.length === 0) return 0;
  const mergedPRs = getMergedPRs(pullRequests);
  const sortedPRs = sortPRsByMergedAt(mergedPRs);

  const firstPR = sortedPRs[0];
  const lastPR = sortedPRs[sortedPRs.length - 1];

  const totalWeeks = differenceInWeeks(
    new Date(lastPR.mergedAt),
    new Date(firstPR.mergedAt)
  );

  return totalWeeks > 0
    ? Number(Number(mergedPRs.length / totalWeeks).toFixed(2))
    : mergedPRs.length;
};

export const getPRRepository = (pullRequest: PullRequestDto) => {
  return pullRequest.repository;
};
export const getRepositories = (pullRequests: PullRequestDto[]) => {
  return Array.from(new Set(pullRequests.map(getPRRepository)));
};

export const getPRMergeFrequencyByWeek = (
  pullRequests: PullRequestDto[],
  period: ChartPeriodType
): Record<string, any>[] => {
  const firstWeek = startOfWeek(period.startDate, {
    weekStartsOn: 1,
  });

  const lastWeek = endOfWeek(period.endDate, {
    weekStartsOn: 1,
  });

  const totalWeeks = differenceInWeeks(lastWeek, firstWeek);

  const prsByWeek: Map<string, any> = new Map();

  const repositories = getRepositories(pullRequests);

  for (let i = 0; i <= totalWeeks; i++) {
    const startDate = addWeeks(firstWeek, i);
    const endDate = endOfWeek(startDate);
    prsByWeek.set(startDate.toISOString(), {
      startDate,
      endDate,
      ...repositories.reduce((acc: any, name) => {
        acc[name] = 0;
        return acc;
      }, {}),
    });
  }

  pullRequests.forEach((pr) => {
    if (pr.mergedAt) {
      const week = startOfWeek(new Date(pr.mergedAt), {
        weekStartsOn: 1,
      });

      const weekData = prsByWeek.get(week.toISOString());
      if (weekData) {
        prsByWeek.set(week.toISOString(), {
          ...weekData,
          [pr.repository]: weekData[pr.repository]
            ? weekData[pr.repository] + 1
            : 1,
        });
      }
    }
  });

  return Array.from(prsByWeek.values());
};
