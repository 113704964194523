import React, { PropsWithChildren } from "react";
import styles from "./ScoreCardLevels.module.css";
import { Text } from "_sredx/_ui";

interface ScoreCardLevelsWrapperProps {
  levelsCount: number;
}

export const ScoreCardLevelsWrapper = ({
  levelsCount,
  children,
}: PropsWithChildren<ScoreCardLevelsWrapperProps>) => {
  return (
    <div className={styles.wrapper}>
      <Text block size={"sm"} className={styles.title}>
        Levels ({levelsCount})
      </Text>
      {children}
    </div>
  );
};
