import { AdvancedFiltersInputValueProps } from "../types";
import { useMemo, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverItem,
  PopoverTrigger,
} from "../../Popover";
import inputStyles from "../Input.module.css";
import clsx from "clsx";
import { GetOptionsByKeyFn } from "../../types";

interface InputEnumProps extends AdvancedFiltersInputValueProps {
  getOptionsByKey: GetOptionsByKeyFn;
}

interface KeyValueTmp {
  value: string;
  label: string;
}
export const InputEnum = ({
  value,
  onChange,
  propertyKey,
  getOptionsByKey,
}: InputEnumProps) => {
  const [selectedValues, setSelectedValues] = useState<KeyValueTmp[]>(
    value || []
  );
  const [popoverOpen, setPopoverOpen] = useState(false);

  const useFetchOptions = getOptionsByKey!(propertyKey);

  const result = useFetchOptions();
  const options = useMemo(() => {
    if (result) {
      return result && result.data ? result.data : [];
    }
    return [result];
  }, [getOptionsByKey]);
  const handleOnClick = (_value: KeyValueTmp) => {
    setSelectedValues((oldValues) => {
      if (oldValues.find((v) => v.value === _value.value)) {
        return oldValues.filter((v) => v !== _value);
      }
      return [...oldValues, _value];
    });
  };

  const handleOnClose = (open: boolean) => {
    setPopoverOpen(open);
    if (!open) {
      onChange(selectedValues);
    }
  };
  return (
    <Popover onOpenChange={handleOnClose} open={popoverOpen}>
      <PopoverTrigger>
        <div className={clsx(inputStyles.list_label_w)}>
          {selectedValues.length ? (
            selectedValues.map((item) => {
              return (
                <span className={inputStyles.list_label_i}>{item?.label}</span>
              );
            })
          ) : (
            <span className={inputStyles.trigger_label}>Value</span>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        {options.map(({ label, value }) => (
          <PopoverItem
            key={value}
            onClick={() => handleOnClick({ label, value })}
            selected={!!selectedValues.find((v) => v.value === value)}
          >
            {label}
          </PopoverItem>
        ))}
      </PopoverContent>
    </Popover>
  );
};
