import { ScoreCardDto } from "_sredx/types";
import { ScoreCardEvaluation } from "models/evaluations/ScoreCardEvaluation";

export const extractRules = (evals: ScoreCardEvaluation[], scorecard: ScoreCardDto) => {
  if (!evals || !evals.length) return [];
  return evals?.find((e: ScoreCardEvaluation) => {
    if (!e?.scoreCardId) return false;
    return e?.scoreCardId === scorecard?.id;
  })?.evaluationResult?.ruleEvaluationResults;
};
