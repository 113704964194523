import { Heading, PageContent } from "_sredx/_ui";
import { ClusterListingContainer } from "_sredx/containers/ClusterListingContainer";
import React from "react";
import styles from "./ClustersListingPage.module.css";

export const ClustersListingPage = () => {
  return (
    <PageContent>
      <div className={styles.wrapper}>
        <div className={styles.header_wrapper}>
          <div>
            <Heading>Clusters</Heading>
            <p>
              Elevate your plateform experience. Instantly create clusters and
              optimize your workflow with efficient day-2 operations
            </p>
          </div>
          <div></div>
        </div>
        <ClusterListingContainer />
      </div>
    </PageContent>
  );
};
