import React from 'react';
import Media from 'models/scorecard/Media';
import placeholder from "./image.svg";
import style from "./ScorecardLevelImageComponent.module.css";

interface ScorecardLevelImageComponentProps {
  image ? : Media;
}


export const ScorecardLevelImageComponent = ({image }: ScorecardLevelImageComponentProps ) => {
    if (!image) {
        return (
          <div className={style.image_wrapper}>
            <img src={placeholder} alt='scorecard-level'/>
          </div>
        );
    }

  return (
    <div className={style.image_wrapper}>
      <img src={`data:image/jpeg;charset=utf-8;base64,${image.mediaData}`} alt=''/>
    </div>
  );
}
