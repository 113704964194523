import {
  BoxIcon,
  ChartIcon,
  CogIcon,
  EdgeClusterIcon,
  FlagIcon,
  HomeIcon,
  NetworkIcon,
  PeopleIcon,
  PersonIcon,
  PlaylistIcon,
  TrophyIcon,
} from "_sredx/_ui/Icon";
import { PiPuzzlePiece } from "react-icons/pi";

export const MENU = [
  {
    items: [
      {
        title: "Home",
        icon: HomeIcon,
        path: "",
      },
      {
        title: "Dashboards",
        icon: ChartIcon,
        path: "/dashboards",
      },
    ],
  },
  {
    title: "Discover",
    items: [
      {
        title: "Services",
        icon: BoxIcon,
        path: "/services",
      },
      {
        title: "Teams",
        icon: PeopleIcon,
        path: "/teams",
      },
      {
        title: "Domains",
        icon: NetworkIcon,
        path: "/domains",
      },
      {
        title: "Members",
        icon: PersonIcon,
        path: "/members",
      },
      {
        title: "Resources",
        icon: PiPuzzlePiece,
        path: "/resources",
      },
    ],
  },
  {
    title: "Mesure",
    items: [
      {
        title: "Scorecards",
        icon: PlaylistIcon,
        path: "/scorecards",
      },
      {
        title: "Leaderboard",
        icon: TrophyIcon,
        path: "/leaderboard",
      },
    ],
  },
  {
    title: "Plan",
    items: [
      {
        title: "Initiatives",
        icon: ChartIcon, // TODO: replace with initiative icon
        path: "/initiatives",
      },
      {
        title: "Roadmaps",
        icon: FlagIcon,
        path: "/roadmaps",
      },
    ],
  },
  {
    title: "Self service",
    items: [
      {
        title: "Clusters",
        icon: EdgeClusterIcon,
        path: "/self-service/clusters",
      },
      {
        title: "Settings",
        icon: CogIcon,
        path: "/self-service/settings",
      },
    ],
  },
];
