import { ServiceRichDto } from "_sredx/types";
import { ResourceCreationDto, ResourceType } from "_sredx/types/resources";
import * as yup from "yup";

export const schema = yup.object().shape({
  generalInfo: yup.object().shape({
    name: yup.string().required("Name is required"),
    product: yup.string().required("Product is required"),
    version: yup
      .string()
      .matches(
        /^(\d+\.)?(\d+\.)?(\*|\d+)$/,
        "Invalid version format. Example: 1.0.0"
      )
      .required("Version is required"),

    description: yup.string().required("Description is required"),
    type: yup.string().required("Type is required"),
    tags: yup.array(),
  }),
  services: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      name: yup.string().required(),
    })
  ),
});

export type ResourceService = {
  id: string;
  name?: string;
};

export type ResourceInfoFormData = {
  name: string;
  product: string;
  version: string;
  description: string;
  type: string;
  tags?: string[];
};

export interface ResourceCreationFormData {
  generalInfo: ResourceInfoFormData;
  services: ResourceService[];
}

export type ResourceCreationAction =
  | {
      type: "SET_GENERAL_INFO";
      payload: {
        generalInfo: ResourceInfoFormData;
      };
    }
  | {
      type: "SET_SERVICES";
      payload: {
        services: ResourceService[];
      };
    }
  | {
      type: "DELETE_SERVICE";
      payload: {
        service: ResourceService;
      };
    };

export const resourceCreationReducer = (
  state: ResourceCreationFormData,
  action: ResourceCreationAction
): ResourceCreationFormData => {
  switch (action.type) {
    case "SET_GENERAL_INFO":
      return {
        ...state,
        generalInfo: action.payload.generalInfo,
      };
    case "SET_SERVICES":
      return {
        ...state,
        services: action.payload.services,
      };
    case "DELETE_SERVICE":
      return {
        ...state,
        services: deleteService(state.services, action.payload.service),
      };
    default:
      return state;
  }
};

const deleteService = (
  services: ResourceService[],
  service: ResourceService
) => {
  return services.filter((s) => s.id != service.id);
};

export const mapResourceServicesToServiceBaseDtos = (
  services: ResourceService[]
): ServiceRichDto[] => {
  return services.map((s) => ({
    id: s.id,
    name: s.name ?? "",
    owners: [],
    domains: [],
    tools: [],
    source: "",
    allRulesCount: 0,
    activeRulesCount: 0,
    description: "",
    failedPercentage: 0,
    failedRulesCount: 0,
    score: 0,
    scorePercentage: 0,
    successfulRulesCount: 0,
    tags: [],
    successPercentage: 0,
    tag: "",
    totalPossible: 0,
  }));
};

export const mapResourceFormToResourceCreationDto = (
  data: ResourceCreationFormData
): ResourceCreationDto => {
  return {
    name: data.generalInfo.name,
    product: data.generalInfo.product,
    version: data.generalInfo.version,
    description: data.generalInfo.description,
    type: data.generalInfo.type as ResourceType,
    tags: data.generalInfo.tags ?? [],
    servicesIds: data.services.map((s) => s.id),
  };
};
