import { FILTER_PROPERTY_TYPES, GetOptionsByKeyFn } from "../types";
import { InputText } from "./InputText";
import { InputNumber } from "./InputNumber";
import { InputEnum } from "./InputEnum";
import { InputDuration } from "./InputDuration";
import { InputDate } from "./InputDate";
import { InputBoolean } from "./InputBoolean";
import { InputList } from "./InputList";
import { AdvancedFiltersInputValueProps } from "./types";
import inputStyles from "./Input.module.css";

interface AdvancedFiltersValueProps extends AdvancedFiltersInputValueProps {
  getOptionsByKey?: GetOptionsByKeyFn;
}

export const AdvancedFiltersValue = ({
  operator,
  value,
  type,
  propertyKey,
  onChange,
  getOptionsByKey,
}: AdvancedFiltersValueProps) => {
  if (type === FILTER_PROPERTY_TYPES.STRING) {
    return (
      <InputText operator={operator} value={value?.value} onChange={onChange} />
    );
  }
  if (type === FILTER_PROPERTY_TYPES.NUMBER) {
    return (
      <InputNumber operator={operator} value={value} onChange={onChange} />
    );
  }
  if (type === FILTER_PROPERTY_TYPES.ENUM) {
    return (
      <InputEnum
        propertyKey={propertyKey}
        getOptionsByKey={getOptionsByKey!}
        operator={operator}
        value={value}
        onChange={onChange}
      />
    );
  }
  if (type === FILTER_PROPERTY_TYPES.DURATION) {
    return (
      <InputDuration operator={operator} value={value} onChange={onChange} />
    );
  }
  if (type === FILTER_PROPERTY_TYPES.DATE) {
    return <InputDate operator={operator} value={value} onChange={onChange} />;
  }
  if (type === FILTER_PROPERTY_TYPES.BOOLEAN) {
    return (
      <InputBoolean operator={operator} value={value} onChange={onChange} />
    );
  }
  if (type === FILTER_PROPERTY_TYPES.LIST) {
    return (
      <InputList
        getOptionsByKey={getOptionsByKey!}
        operator={operator}
        value={value}
        onChange={onChange}
        propertyKey={propertyKey}
      />
    );
  }

  return <div className={inputStyles.default}>Value</div>;
};
