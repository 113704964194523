import styles from "./CloudAccountsListContainer.module.css";
import { useCloudAccounts } from "services/self-service/useAccounts";
import AccountsHeader from "components/self-service/Configuration/AccountsHeader/AccountsHeader";
import ConfigurationCloudAccounts from "components/self-service/Configuration/ConfigurationCloudAccounts/ConfigurationCloudAccounts";
import ConfigurationAccountsSkeleton from "components/self-service/Configuration/ConfigurationAccountsSkeleton";
import { useModal } from "hooks/useModal";
import CloudAccountCreateContainer from "../CloudAccountCreateCreateContainer/CloudAccountCreateContainer";
import AccountDeleteModal from "../../../../components/self-service/Configuration/AccountDeleteModal/AccountDeleteModal";
import { useDeleteCloudAccount } from "services/self-service/useDeleteCloudAccount";
import { Button } from "components/octo-ui/Button/Button";

export const CloudAccountsListContainer = () => {
  const { data: accounts = [], isLoading } = useCloudAccounts();
  const { hide, show } = useModal();
  const deleteAccount = useDeleteCloudAccount();

  const handleAddCloudAccount = () => {
    show({
      body: <CloudAccountCreateContainer onCancel={hide} />,
    });
  };
  const handleDeleteCloudAccount = (id: string) => {
    show({
      body: <AccountDeleteModal id={id} />,
      options: {
        actions: (
          <div className="flex gap-4">
            <button onClick={hide}>Cancel</button>
            <Button
              onClick={() => deleteAccount.mutate(id, { onSuccess: hide })}
              variant="danger"
            >
              Delete
            </Button>
          </div>
        ),
      },
    });
  };

  const handleEditCloudAccount = (id: string) => {
    // later
  };

  if (isLoading) return <ConfigurationAccountsSkeleton />;

  return (
    <div className={styles.root}>
      <AccountsHeader
        title="Cloud accounts"
        description="Manage your cloud accounts from Scaleway, AWS, and Azure all in one place."
        ctaLabel="add cloud account"
        onCtaClick={handleAddCloudAccount}
      />
      <ConfigurationCloudAccounts
        onEdit={handleEditCloudAccount}
        onDelete={handleDeleteCloudAccount}
        accounts={accounts}
      />
    </div>
  );
};

export default CloudAccountsListContainer;
