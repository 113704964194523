import { useInfiniteQuery } from "react-query";
import { PagedResponse, UserDto, UserSearchCriteria } from "_sredx/types";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";

interface UseGetUsersProps {
  params: UserSearchCriteria;
}

export const useInfiniteGetUsers = ({ params }: UseGetUsersProps) => {
  return useInfiniteQuery<PagedResponse<UserDto>>({
    queryKey: [QUERY_KEYS.infiniteUsers, params],
    queryFn: ({ pageParam = 0 }) => {
      return ClientApi.get(API_ENDPOINTS.getUsers(), {
        params: {
          ...params,
          pageNumber: pageParam,
        },
      });
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.last) {
        return undefined;
      }
      return lastPage.pageNo + 1;
    },
    refetchOnWindowFocus: false,
  });
};
