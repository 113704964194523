import React from 'react';
import Box from 'components/octo-ui/Box/Box';
import { OverviewCardSekeleton } from 'components/dashboard/overview-content/OverviewCardSkeleton';

export const OverviewLoadingComponent = () => {
  return (
    <>
        <Box className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
          <OverviewCardSekeleton />
          <OverviewCardSekeleton />
          <OverviewCardSekeleton />
        </Box>
      </>
  )
}
