import React from 'react';

export const Jenkins = () => {
    return (
      <svg viewBox="0 0 400 400">
        <path
          d="M205 110c-29-7-43 12-52 31-7-2-4-12-2-17 5-15 26-33 42-31 8 1 17 8 12 17m139 33h2c6 13 12 27 20 39-6 13-42 24-41 1 8-3 21 0 28-5-4-11-10-20-9-35m-124 0c6 11 8 23 16 31 4 4 12 9 8 20-1 2-7 8-11 9-13 4-45 1-34-16 11 0 26 7 34-1-6-11-18-31-13-43m119 114c-20 13-43 28-77 24-7-6-10-20-3-29 4 6 2 18 12 19 19 4 41-11 55-16 8-15-1-20-9-29-15-19-36-43-36-71 7-5 7 7 8 9 8 19 29 44 44 60 4 4 10 8 10 11 2 8-5 17-4 22m13 14c-9 18-23 38-51 38v-18c22-2 35-13 51-20m-134 11c18 10 51 11 75 10l1 18c-31 2-68-6-76-28m-3 18c12 31 54 27 90 26-1 5-5 9-9 11-11 5-43 8-59 0-10-6-17-18-22-25-3-3-16-12 0-12m95-274c17 13 44 40 53 60 26 51 24 137 6 200-14 50-44 86-55 91-78 36-102 17-120 8-20-11-62-51-62-77 23 32 43 60 85 74 32 11 84-7 101-28 49-62 56-120 53-172-1-22-1-44-8-59-8-16-35-30-50-16-3-15 13-24 31-19-13-17-27-37-45-48-33-18-88-32-123-15-28 14-65 37-78 67 12 28-4 54-5 82 0 16 7 29 8 45-4 7-17 8-26 8-2-15-8-32-23-33-21-3-37 15-38 34-1 21 17 57 42 55 10-1 12-11 23-11 6 12-9 15-10 24a196 196 0 0 0 27 72l7 34c-3-3-22-28-25-27-5-3-24-63-25-73-42-5-69-73-42-113-9-49 5-85 26-94 9-37 43-50 74-68 77-42 161-29 199-1zm-65 426c0 8 1 10 3 19 25 8 21-35-3-19zm-28-9c-11-6-27-13-41-15-18-4-16 24-16 40 1 13 8 26 11 35 1 4 1 8 5 9 6 1 26-7 31-10 12-6 22-17 32-24v-10c-6-3-13-5-22-5 6-4 15-4 21-7v-4c-10-1-14-6-21-9zm110 16c0-10-3-31-9-35-14-7-37 16-47 20 1 3 3 5 3 9 6-1 13 0 18 2-6 1-13 1-16 4-2 4 0 9-1 15 14 4 30 6 48 7 3-5 5-13 4-22z"
          fill="currentColor"
        />
      </svg>
    );
}
