import {
  PlannerBody,
  PlannerBodyGrid,
  PlannerBodyGridItem,
  PlannerHeader,
  PlannerHeaderMonths,
  PlannerTodayIndicator,
  PlannerWrapper,
  ProgressBar,
} from "_sredx/_ui";
import { RoadmapCalendarInitiative } from "./RoadmapCalendarInitiative";
import { useMemo } from "react";
import { getRoadmapCalendarRowOptions } from "./utils";
import { InitiativeDto, RoadmapBaseDto } from "_sredx/types";
import { RoadmapCalendarHeader } from "./RoadmapCalendarHeader";
import { Link } from "react-router-dom";

interface RoadmapCalendarProps {
  initiatives?: InitiativeDto[];
  roadmap: RoadmapBaseDto;
  isLoading?: boolean;
  onAddInitiative: () => void;
}

export const RoadmapCalendar = ({
  initiatives = [],
  isLoading = false,
  roadmap,
  onAddInitiative,
}: RoadmapCalendarProps) => {
  const displayedInitiatives = useMemo(() => {
    return getRoadmapCalendarRowOptions(initiatives);
  }, [initiatives]);

  return (
    <PlannerWrapper>
      <PlannerHeader>
        <RoadmapCalendarHeader
          roadmap={roadmap}
          onAddInitiative={onAddInitiative}
        />
        <PlannerHeaderMonths
          isLoading={false}
          period={{
            startDate: new Date(roadmap.startDate),
            endDate: new Date(roadmap.endDate),
          }}
        />
      </PlannerHeader>
      {isLoading && <ProgressBar />}
      <PlannerBody>
        <PlannerBodyGrid columns={12}>
          {displayedInitiatives.map(({ initiative, position }) => (
            <PlannerBodyGridItem
              row={position.row}
              startColumn={position.startColumn}
              endColumn={position.endColumn}
            >
              <Link to={"/initiatives/" + initiative.id}>
                <RoadmapCalendarInitiative initiative={initiative} />
              </Link>
            </PlannerBodyGridItem>
          ))}
        </PlannerBodyGrid>
      </PlannerBody>
      <PlannerTodayIndicator />
    </PlannerWrapper>
  );
};
