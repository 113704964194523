import React from "react";
import { LogoutIcon } from "_sredx/_ui/Icon";
import { Text } from "_sredx/_ui";

import styles from "./HeaderUserActions.module.css";

export const LogoutAction = () => {
  return (
    <div className={styles.wrapper}>
      <Text size={"md"}>Logout</Text>
      <LogoutIcon className={styles.icon} />
    </div>
  );
};

// 👇 The rest of the actions go down here 👇
