import React, { useMemo, useState } from "react";
import {
  MetricChartHeader,
  MetricChartWrapper,
} from "_sredx/components/Metrics";
import { ChartPeriodType, PullRequestDto } from "_sredx/types";
import { PRCycleTimeDotTimeChart } from "../PRCycleTimeDotTimeChart";
import { PRTimeToReviewDotTimeChart } from "../PRTimeToReviewDotTimeChart";
import { PRTimeToMergeDotTimeChart } from "../PRTimeToMergeDotTimeChart";
import { PRCycleTimeBreakdownChart } from "../PRCycleTimeBreakdownChart";
import { PRCycleTimeStats } from "./PRCycleTimeStats";
import { PRCycleTimeTabs } from "./PRCycleTimeTabs";
import { PR_COLORS, PR_CYCLE_TIME_TABS, PrCycleTimeTabType } from "./utils";
import { ParentSize } from "@visx/responsive";
import { PRCycleTimeCompositeBarChart } from "../PRCycleTimeCompositeBarChart";
import { ChartLegend, ChartLegendWrapper } from "_sredx/components/Charts";
import { Loader } from "_sredx/_ui";

interface PRCycleTimeWidgetProps {
  pullRequests?: PullRequestDto[];
  period: ChartPeriodType;
  isLoading?: boolean;
  filterByDateKey?: "createdAt" | "mergedAt" | "closedAt";
}
export const PRCycleTimeWidget = ({
  pullRequests = [],
  period,
  isLoading = true,
  filterByDateKey = "createdAt",
}: PRCycleTimeWidgetProps) => {
  const [activeTab, setActiveTab] = useState<PrCycleTimeTabType>(
    PR_CYCLE_TIME_TABS.PULL_REQUESTS
  );
  const dotColorsByRepo = useMemo(() => {
    const colors = new Map<string, string>();
    let i = 0;
    pullRequests.forEach((pr) => {
      if (!colors.has(pr.repository)) {
        colors.set(pr.repository, PR_COLORS[i % PR_COLORS.length]);
        i++;
      }
    });
    return colors;
  }, [pullRequests]);

  if (isLoading) {
    return (
      <MetricChartWrapper>
        <MetricChartHeader title="Pull request cycle time" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            height: "500px",
          }}
        >
          <Loader />
          <span>Loading ...</span>
        </div>
      </MetricChartWrapper>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
      }}
    >
      {pullRequests && pullRequests.length > 0 && (
        <MetricChartWrapper>
          <MetricChartHeader title="Pull request cycle time" />
          <PRCycleTimeStats pullRequests={pullRequests} />

          <div>
            <ParentSize>
              {({ width }) => (
                <PRCycleTimeCompositeBarChart
                  width={width}
                  pullRequests={pullRequests}
                />
              )}
            </ParentSize>
          </div>

          <PRCycleTimeTabs
            activeTab={activeTab}
            onTabChange={(tab) => setActiveTab(tab)}
          />

          <div>
            {activeTab === PR_CYCLE_TIME_TABS.PULL_REQUESTS && (
              <PRCycleTimeDotTimeChart
                period={period}
                pullRequests={pullRequests}
                xAxisKey={filterByDateKey}
                colors={dotColorsByRepo}
              />
            )}
            {activeTab === PR_CYCLE_TIME_TABS.CYCLE_TIME_BREAKDOWN && (
              <PRCycleTimeBreakdownChart
                period={period}
                pullRequests={pullRequests}
              />
            )}
            {activeTab === PR_CYCLE_TIME_TABS.TIME_TO_REVIEW && (
              <PRTimeToReviewDotTimeChart
                period={period}
                pullRequests={pullRequests}
                xAxisKey={filterByDateKey}
                colors={dotColorsByRepo}
              />
            )}
            {activeTab === PR_CYCLE_TIME_TABS.TIME_TO_MERGE && (
              <PRTimeToMergeDotTimeChart
                period={period}
                pullRequests={pullRequests}
                xAxisKey={filterByDateKey}
                colors={dotColorsByRepo}
              />
            )}
          </div>
          <ChartLegendWrapper>
            {Array.from(dotColorsByRepo).map(([key, value]) => (
              <ChartLegend key={key} color={value} label={key} />
            ))}
          </ChartLegendWrapper>
        </MetricChartWrapper>
      )}
    </div>
  );
};
