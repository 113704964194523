import {InteractionData} from "types/heatmap";
import styles from "./Tooltip.module.css";

type TooltipProps = {
  interactionData: InteractionData | null;
  width: number;
  height: number;
};

export const Tooltip = ({ interactionData, width , height }: TooltipProps) => {
  if (!interactionData) {
    return null;
  }

  return (
    <div
    className=""
      style={{
        width,
        height,
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
      }}
    >
      <div
        className={styles.tooltip}
        style={{
          position: "absolute",
          left: interactionData.xPos,
          top: interactionData.yPos,
        }}
      >
        <TooltipRow label={"service"} value={interactionData.xLabel} />
        <TooltipRow label={"scorecard"} value={interactionData.yLabel} />
        <TooltipRow label={"percentage"} value={interactionData.value != 'null' ? interactionData.value + '%' : 'NA'} />
      </div>
    </div>
  );
};

type TooltipRowProps = {
  label: string;
  value: string;
};

const TooltipRow = ({ label, value }: TooltipRowProps) => (
  <div>
    <b>{label}</b>
    <span>: </span>
    <span>{value}</span>
  </div>
);
