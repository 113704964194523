export const SELF_SERVICE_PATHS = {
  CLUSTERS: "/self-service/clusters",
  CLUSTER_DETAIL: (id: string) => `/self-service/clusters/${id}`,
  CLUSTER_DETAIL_MANAGED_RESOURCES: (id: string) =>
    `/self-service/clusters/${id}/managed-resources`,
  CLUSTER_DETAIL_FOUNDATIONS: (id: string) =>
    `/self-service/clusters/${id}/foundations`,
  CLUSTER_DETAIL_KUBECONFIG: (id: string) =>
    `/self-service/clusters/${id}/kubeconfig`,
  CLUSTER_AVAILABLE_OPTIONS: ()=> 'self-service/configuration',
  CLOUD_ACCOUNTS: "/self-service/accounts",
  CLOUD_ACCOUNT: (id: string) => `/self-service/accounts/${id}`,
  GITOPS_ACCOUNTS: "/self-service/gitops",
  GITOPS_ACCOUNT: (id: string) => `/self-service/gitops/${id}`,
};

export const SELF_SERVICE_QUERY_KEYS = {
  CLUSTERS: "CLUSTERS",
  CLUSTER_DETAIL: "CLUSTER_DETAIL",
  CLUSTER_AVAILABLE_OPTIONS: "CLUSTER_AVAILABLE_OPTIONS",
  CLUSTER_DETAIL_MANAGED_RESOURCES: "CLUSTER_DETAIL_MANAGED_RESOURCES",
  CLUSTER_DETAIL_FOUNDATIONS: "CLUSTER_DETAIL_FOUNDATIONS",
  CLUSTER_DETAIL_KUBECONFIG: "CLUSTER_DETAIL_KUBECONFIG",
  GITOPS_ACCOUNTS: "GITOPS_ACCOUNTS",
  CLOUD_ACCOUNTS: "CLOUD_ACCOUNTS",
};
