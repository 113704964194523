import { useDashboardContext } from "_sredx/context/DashboardContext";
import { DashboardInfoDto } from "_sredx/types/dashboards";
import { useEffect, useState } from "react";
import styles from "./DashboardContainer.module.css";
import { Button, Text } from "_sredx/_ui";
import { useModal } from "_sredx/hooks";
import { DashboardSwitcher } from "_sredx/components/Dashboard/DashboardSwitcher";
import { DashboardWidgetsModal } from "_sredx/components/Dashboard/DashboardWidgetsModal";
import { DashboardAddModal } from "_sredx/components/Dashboard/DashboardAddModal";
import { useCreateDashboard } from "_sredx/services/dashboards/useCreateDashboard";
import { useDeleteDashboard } from "_sredx/services";
import { useEditDashboard } from "_sredx/services/dashboards/useEditDashboard";
import { FormProvider, useForm } from "react-hook-form";
import { FormFieldInput, FormFieldTextArea } from "_sredx/components/Form";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "_sredx/config";
import { DashboardEditorNeo } from "_sredx/components/Dashboard";

export const DashboardContainer = () => {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const { dashboards, selectedDashboard, setSelectedDashboard, isLoading } =
    useDashboardContext();

  const { openModal, closeModal } = useModal();
  const { mutate } = useCreateDashboard();
  const { mutate: deleteMutation } = useDeleteDashboard();
  const { mutate: editMutation } = useEditDashboard();
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      id: selectedDashboard?.id ?? "",
      name: selectedDashboard?.name ?? "",
      description: selectedDashboard?.description ?? "",
      widgets: selectedDashboard?.widgets ?? [],
    },
  });
  useEffect(() => {
    if (!selectedDashboard) {
      setSelectedDashboard(dashboards[0]);
    }
  }, [dashboards]);

  useEffect(() => {
    methods.reset({
      id: selectedDashboard?.id ?? "",
      name: selectedDashboard?.name ?? "",
      description: selectedDashboard?.description ?? "",
      widgets: selectedDashboard?.widgets ?? [],
    });
  }, [selectedDashboard]);

  // Event handlers
  const handleOnSelect = (dashboard: DashboardInfoDto) => {
    setSelectedDashboard(dashboard);
    setIsEditing(false);
  };

  const handleAddDashboard = () => {
    openModal({
      content: (
        <DashboardAddModal
          onSave={(data) => {
            mutate(
              { ...data, widgets: [] },
              {
                onSuccess: (res: string) => {
                  closeModal();
                  setSelectedDashboard({
                    id: res,
                    name: data.name,
                    description: data.description,
                    widgets: [],
                  });
                },
              }
            );
          }}
          onClose={closeModal}
        />
      ),
    });
  };

  const handleDeleteDashboard = () => {
    //TODO: add confirmation dialog
    if (!selectedDashboard) return;
    deleteMutation(selectedDashboard?.id, {
      onSuccess: () => {
        setSelectedDashboard(dashboards[0]);
      },
    });
  };

  const handleEditDashboard = () => {
    const data = methods.getValues();

    if (!selectedDashboard) return;
    editMutation(data, {
      onSettled: () => {
        setSelectedDashboard({
          ...selectedDashboard,
          name: data.name,
          description: data.description,
        });
        setIsEditing(false);
      },
    });
  };

  const handleAddWidget = () => {
    openModal({
      content: (
        <DashboardWidgetsModal
          defaultSelection={selectedDashboard?.widgets}
          onClose={closeModal}
          onSave={(widgets) => {
            methods.setValue("widgets", widgets);
            editMutation(
              {
                id: selectedDashboard?.id ?? "",
                name: selectedDashboard?.name ?? "",
                description: selectedDashboard?.description ?? "",
                widgets,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries(QUERY_KEYS.me);

                  setSelectedDashboard({
                    ...selectedDashboard!,
                    widgets,
                  });
                  closeModal();
                },
              }
            );
          }}
        />
      ),
      options: {
        showCloseButton: true,
      },
    });
  };

  // UI waterfall
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormProvider {...methods}>
      <div className={styles.header}>
        {isEditing ? (
          <div className="flex gap-2 items-center">
            <FormFieldInput name="name" control={methods.control} />
            <Button onClick={handleAddWidget}>Add widgets</Button>
          </div>
        ) : (
          <DashboardSwitcher
            selectedDashboard={selectedDashboard!}
            dashboards={dashboards}
            onSelectDashboard={handleOnSelect}
          />
        )}
        {!isEditing ? (
          <div className="flex gap-2">
            <Button variant="neutral" onClick={() => setIsEditing(true)}>
              Edit
            </Button>
            <Button variant="neutral" onClick={handleDeleteDashboard}>
              Delete
            </Button>
            <Button onClick={handleAddDashboard}>New Dashboard</Button>
          </div>
        ) : (
          <div className="flex gap-2">
            <Button variant="neutral" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <Button onClick={handleEditDashboard}>Save</Button>
          </div>
        )}
      </div>
      <div className={styles.description}>
        {isEditing ? (
          <div className={styles.description_area}>
            <FormFieldTextArea
              name="description"
              control={methods.control}
              placeholder="Dashboard description"
            />
          </div>
        ) : (
          <Text>{selectedDashboard?.description}</Text>
        )}
      </div>
      <DashboardEditorNeo
        widgets={selectedDashboard?.widgets ?? []}
        onEditWidgets={(state) => {
          setSelectedDashboard({
            ...selectedDashboard!,
            widgets: state,
          });
          methods.setValue("widgets", state);
          // handleEditWidgets
        }}
        isEditing={isEditing}
      />
    </FormProvider>
  );
};
