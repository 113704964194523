import React, { PropsWithChildren, forwardRef, useMemo } from "react";
// @ts-ignore
import GridLayout from "react-grid-layout";
import { mapWidgetComponents } from "_sredx/containers/DashboardContainer/utils";
import styles from "./DashboardEditorNeo.module.css";
import { Card, CardBody, CardHeader, CardTitle } from "_sredx/_ui";
import { WidgetInfoDto } from "_sredx/types";

export type DashboardEditorProps = {
  widgets: WidgetInfoDto[];
  isEditing: boolean;
  onEditWidgets: (widgets: WidgetInfoDto[]) => void;
};

export const DashboardEditorNeo = ({
  onEditWidgets,
  widgets,
  isEditing,
}: // widgets,
DashboardEditorProps) => {
  const mappedWidgets = useMemo(() => {
    return mapWidgetComponents(widgets.map((w) => w.i));
  }, [widgets, isEditing]);

  const layout = useMemo(
    () =>
      widgets.map((widget, index) => ({
        ...widget,

        title: mappedWidgets[index]?.title,
        component: mappedWidgets[index]?.component,
        props: mappedWidgets[index]?.props,
        noPadding: mappedWidgets[index]?.noPadding,
        static: !isEditing,
      })),
    [mappedWidgets]
  );
  return (
    <GridLayout
      className="layout"
      layout={layout}
      cols={12}
      rowHeight={48}
      width={1400}
      onLayoutChange={(e: WidgetInfoDto[]) => {
        onEditWidgets(e);
      }}
    >
      {layout.map((l, i) => (
        <div key={l.i} className="overflow-auto">
          <DraggableDashboardWidget title={l.title} noPadding={l.noPadding}>
            {l.component ? (
              <l.component
                widgetKey={l.props?.key}
                title={l.props?.title}
                {...l.props}
              />
            ) : (
              <></>
            )}
          </DraggableDashboardWidget>
        </div>
      ))}
    </GridLayout>
  );
};

const DraggableDashboardWidget = forwardRef<
  HTMLDivElement,
  DashboardWidgetProps
>(
  (
    {
      cols = 1,
      rows = 1,
      title,
      noPadding,
      children,
      ...props
    }: PropsWithChildren<DashboardWidgetProps>,
    ref
  ) => {
    return (
      <div
        ref={ref}
        style={{ height: "100%", position: "relative" }}
        {...props}
      >
        <Card className="overflow-hidden">
          {title && (
            <CardHeader>
              <CardTitle>{title}</CardTitle>
            </CardHeader>
          )}
          <CardBody className={styles.widgetBody} noPadding={noPadding}>
            {children}
          </CardBody>
        </Card>
      </div>
    );
  }
);

interface DashboardWidgetProps extends React.HTMLAttributes<HTMLDivElement> {
  cols?: number;
  rows?: number;
  title?: string;
  noPadding?: boolean;
}
