import { Avatar, TableCell, TableRow, Text } from "_sredx/_ui";
import { DomainBaseDto } from "_sredx/types";
import { formatISODate } from "_sredx/utils";
import styles from "./DomainDataTableRow.module.css";
import { DomainRowActions } from "./DomainRowActions";
import { DomainRowActionType } from "./types";

interface DomainDataTableRowProps {
  domain: DomainBaseDto;
  onAction: (domain: DomainBaseDto, actionType: DomainRowActionType) => void;
}

export const DomainDataTableRow = ({
  domain,
  onAction,
}: DomainDataTableRowProps) => {
  const { id, name, description, createdAt, services } = domain;

  const handleOnAction = (actionType: DomainRowActionType) => {
    onAction(domain, actionType);
  };

  return (
    <TableRow>
      <TableCell>
        <div className={styles.col1_wrapper}>
          <Avatar size={20} name={name} rectangle avatarStyle="initials" />
          <Text>{name}</Text>
        </div>
      </TableCell>

      <TableCell>
        <Text>{description}</Text>
      </TableCell>
      <TableCell>
        <Text>{services.length}</Text>
      </TableCell>
      <TableCell>
        <Text>{formatISODate(createdAt)}</Text>
      </TableCell>
      <TableCell align={"right"}>
        <DomainRowActions onAction={handleOnAction} />
      </TableCell>
    </TableRow>
  );
};
