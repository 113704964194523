import { AxisBottom } from "@visx/axis";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { BarStack } from "@visx/shape";
import { BarGroupBar } from "@visx/shape/lib/types";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { KeysType } from "_sredx/components/GroupedBarChart/types";
import { StackedBarToolTipData } from "_sredx/components/StackedBarChart/types";
import {
  BarChartData,
  ScorecardsLevelsHealthsKeys,
} from "_sredx/containers/DashboardContainer/ScoreCardsHealthPerLevelsContainer/types";
import clsx from "clsx";
import { SeriesPoint } from "d3";
import React, { useCallback } from "react";
import styles from "./StackedBarChart.module.css";

interface StackedBarChartProps<K extends KeysType> {
  data: BarChartData<ScorecardsLevelsHealthsKeys>[];
  levelsNames?: { key: string; levelsNames: string[] }[];
  keys: K[];
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
}

const COLORS = {
  "0": "#FF7878",
  "1": "#FFD978",
  "2": "#7DDFD9",
  "3": "#92D37A",
};
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "#eeeeee",
};
const axisColor = "#000000";
const axisBottomTickLabelProps = {
  fontFamily: "",
  fontSize: 9,
  textAnchor: "middle" as const,
  fill: axisColor,
};

const DEFAULT_MARGIN = { top: 40, right: 0, bottom: 40, left: 20 };
export const StackedBarChart = <K extends KeysType>({
  data,
  keys,
  width,
  levelsNames,
  height,
  margin = DEFAULT_MARGIN,
}: StackedBarChartProps<K>) => {
  //hooks
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<StackedBarToolTipData<any>>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  //
  let tooltipTimeout: number;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  //
  const countTotals = data.reduce((allCounts, currentScoreCard) => {
    const totalCount = keys.reduce((levelTotal, k) => {
      if (!currentScoreCard[k] || currentScoreCard[k] == null) return 0;
      levelTotal += Number(currentScoreCard[k]);
      return levelTotal;
    }, 0);
    allCounts.push(totalCount);
    return allCounts;
  }, [] as number[]);
  const xScale = scaleBand<string>({
    domain: data.map((d) => d.key),
    padding: 0.2,
  }).rangeRound([0, xMax]);

  const countScale = scaleLinear<number>({
    domain: [0, Math.max(...countTotals)],
    nice: true,
  }).range([yMax, 0]);
  const colorScale = scaleOrdinal<K, string>({
    domain: keys,
    //range based on keys length
    range: [COLORS["0"], COLORS["1"], COLORS["2"], COLORS["3"]],
  });

  //handlers

  // handlers
  const handleTooltipTimeout = () => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  };
  const handleTooltip = useCallback(
    (
      bar: Omit<BarGroupBar<K>, "key" | "value"> & {
        bar: SeriesPoint<BarChartData<ScorecardsLevelsHealthsKeys>>;
        key: K;
      }
    ) => {
      return (
        event:
          | React.TouchEvent<SVGRectElement>
          | React.MouseEvent<SVGRectElement>
      ) => {
        const { x, y } = localPoint(event) || { x: 0, y: 0 };
        showTooltip({
          tooltipData: bar,
          tooltipLeft: x,
          tooltipTop: y,
        });
      };
    },
    [showTooltip, localPoint]
  );
  if (width < 10) return null;

  return (
    <div className={clsx(styles.wrapper)}>
      <svg ref={containerRef} width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          rx={14}
          fill="transparent"
        />
        <Group top={margin.top} left={margin.left}>
          <BarStack
            data={data}
            keys={keys}
            x={(d) => d.key}
            xScale={xScale}
            yScale={countScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => {
                  return (
                    <rect
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      height={bar.height}
                      width={bar.width / 2}
                      fill={bar.color}
                      onMouseLeave={handleTooltipTimeout}
                      onMouseMove={handleTooltip(bar)}
                    />
                  );
                })
              )
            }
          </BarStack>
        </Group>
        <AxisBottom
          top={yMax + margin.top}
          left={margin.left - 13}
          scale={xScale}
          tickFormat={(d) => d}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={axisBottomTickLabelProps}
        />
      </svg>
      <div
        className={styles.tooltip_wrapper}
        style={{
          top: margin.top / 2 - 10,
        }}
      >
        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <>
              <div>
                <strong>{tooltipData.bar.data.key}</strong>
              </div>
              <div>
                <div>
                  <strong style={{ color: colorScale(tooltipData.key) }}>
                    {
                      levelsNames?.find(
                        (l) => l.key === tooltipData.bar.data.key
                      )?.levelsNames[Number(tooltipData.key)]
                    }
                  </strong>
                </div>
                value: {tooltipData.bar.data[tooltipData.key]}
              </div>
              {/*<div>*/}
              {/*  <small>{tooltipData.bar.data.key}</small>*/}
              {/*</div>*/}
            </>
          </TooltipInPortal>
        )}
      </div>
    </div>
  );
};
