import { PropsWithChildren } from "react";
import clsx from "clsx";
import inputStyles from "../Input.module.css";
import { CloseIcon } from "../../Icons";

interface InputListItemProps {
  onClick: () => void;
  selected?: boolean;
}
export const InputListItem = ({
  children,
  selected = false,
  onClick,
}: PropsWithChildren<InputListItemProps>) => {
  return (
    <div
      className={clsx(
        inputStyles.list_option_i,
        selected && inputStyles.list_selected_i,
      )}
      onClick={onClick}
    >
      {children}
      {selected && <CloseIcon size={16} />}
    </div>
  );
};
