import React from "react";
import { DomainInfoForm, DomainInfoFormData } from "_sredx/components";
import { useUpdateDomain } from "_sredx/services";
import { DomainBaseDto } from "_sredx/types";

interface DomainUpdateContainerProps {
  domain: DomainBaseDto;
  closeModal?: () => void;
}

export const DomainUpdateContainer = ({
  domain,
  closeModal,
}: DomainUpdateContainerProps) => {
  // services
  const { mutate: updateDomain, isLoading } = useUpdateDomain();

  // event handlers
  const handleOnSubmit = (data: DomainInfoFormData) => {
    updateDomain({
      id: domain.id,
      name: data.name,
      description: data.description,
    });
    closeModal && closeModal();
  };

  const handleOnCancel = () => {
    closeModal && closeModal();
  };

  return (
    <DomainInfoForm
      onSubmit={handleOnSubmit}
      isLoading={isLoading}
      action={"UPDATE"}
      onCancel={handleOnCancel}
      defaultValues={{
        name: domain.name,
        description: domain.description,
      }}
    />
  );
};
