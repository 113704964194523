import { forwardRef } from "react";
import { IconProps } from "./types";

export const LeanIxIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>leanIx</title>
        <path
          fill="#166BFF"
          d="M15.734 19.176c4.013 0 7.266-3.571 7.266-7.976 0-4.406-3.253-7.977-7.266-7.977-4.012 0-7.265 3.571-7.265 7.977 0 4.405 3.253 7.976 7.265 7.976Z"
        />
        <path
          fill="#039"
          d="m10.856 21 7.475-8.206-7.475-8.206-7.474 8.206L10.856 21Z"
        />
        <path
          fill="#166BFF"
          d="M1 11.2 8.469 3l7.468 8.2-7.468 8.199L1 11.199Z"
        />
        <path
          fill="#fff"
          d="M10.664 16.897c-1.408-1.493-2.195-3.55-2.195-5.697 0-2.227.835-4.257 2.195-5.698l5.273 5.698-5.273 5.697Z"
        />
      </svg>
    );
  }
);
