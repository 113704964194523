import Table, { Column, TableRow, CellValue } from "../../octo-ui/Table/Table";


type DataTableProps = {
  headerColumns: string[];
  dataRows: any[];
};

const DataTable: React.FC<DataTableProps> = ({ headerColumns, dataRows }) => {
  const columns: Column[] = headerColumns.map((column, index) => ({
    title: column,
    dataIndex: String(index),
    // className: index === 0 ? 'text-md text-center font-medium text-gray-700 sticky left-0 bg-gray-50 border-none' : '',
  }));

  const dataSource: TableRow[] = dataRows.map((row, rowIndex) => {
    let data: TableRow = {};
    row.forEach((value: CellValue, valueIndex: number) => {
      data[String(valueIndex)] = value;
    });
    return data;
  });

  return (
    <Table columns={columns} dataSource={dataSource} data-testid="data-table"/>
  );
};

export default DataTable;
