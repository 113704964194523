import { QUERY_KEYS } from "_sredx/config";
import { useGetKubeConfig } from "_sredx/services";

import { triggerDownload } from "_sredx/utils/download";
import { useState } from "react";
import { useQueryClient } from "react-query";

export const useKubeConfig = () => {
  const [downloadingCluster, setDownloadingCluster] = useState<string | null>(
    null
  );
  const queryClient = useQueryClient();
  const { isFetching, isLoading, data, isSuccess, isError, error } =
    useGetKubeConfig({
      clusterName: downloadingCluster,
    });

  if (isSuccess && downloadingCluster) {
    const decodedKubeConfig = atob(data?.kubeconfig || "");
    triggerDownload(decodedKubeConfig, `kubeconfig_${downloadingCluster}.yaml`);
    setDownloadingCluster(null);
  }
  if (isError) {
    console.error("Error while downloading kubeConfig: ", error);
    setDownloadingCluster(null);
  }

  const handleDownload = (clusterId: string) => {
    setDownloadingCluster(clusterId);
    queryClient.invalidateQueries([QUERY_KEYS.kubConfig, clusterId]);
  };
  const isClusterDownloading = (clusterId: string) => {
    return downloadingCluster === clusterId && (isFetching || isLoading);
  };

  return {
    isClusterDownloading,
    handleDownload,
  };
};
