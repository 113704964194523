import { InfiniteData } from "react-query";
import { PagedResponse, DomainBaseDto } from "_sredx/types";

export const mapInfiniteDomains = (
  infiniteDomains?: InfiniteData<PagedResponse<DomainBaseDto>>
) => {
  if (
    !infiniteDomains ||
    !infiniteDomains.pages ||
    !Array.isArray(infiniteDomains.pages) ||
    infiniteDomains.pages.length === 0
  ) {
    return [];
  }
  const { pages } = infiniteDomains;
  return pages.reduce((result: DomainBaseDto[], page) => {
    if (page.content && Array.isArray(page.content)) {
      result.push(...page.content);
    }

    return result;
  }, []);
};
