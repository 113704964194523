import { TextInput } from "components/octo-ui/Forms/TextInput";
import { useState } from "react";
import { resolveToolUrl } from "../ClusterCard/utils";
import { useFormContext } from "react-hook-form";

interface ClusterFoundationsFormProps {
  display: boolean;
}
export const ClusterFoundationsForm = ({
  display,
}: ClusterFoundationsFormProps) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [displayedTools, setDisplayedTools] = useState<any[]>(mockData);
  const methods = useFormContext();

  const handleSearch = (value: string) => {
    if (value.length < 2) {
      setDisplayedTools(mockData);
    } else {
      setDisplayedTools(
        mockData.filter((tool) =>
          tool.toLocaleLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleSelect = (value: string) => {
    if (selected.includes(value)) {
      setSelected(selected.filter((item: any) => item !== value));
      methods.setValue(
        "tools",
        selected.filter((item: any) => item !== value)
      );
      return;
    }
    setSelected([...selected, value]);
    methods.setValue("tools", [...selected, value]);
    return;
  };

  return (
    <div className={`flex flex-col gap-4 ${display ? "" : "hidden"}`}>
      <div>
        Foundations
        <TextInput
          placeholder="Search"
          onChange={(e: any) => handleSearch(e.target.value)}
        />
      </div>
      {/* yeah */}
      <div className="h-96 overflow-auto">
        <div className="grid grid-cols-2 gap-4">
          {displayedTools.map((item, i) => {
            const ToolImg = resolveToolUrl(item);
            if (ToolImg)
              return (
                <div
                  className={`border flex px-4 p-2 items-center gap-4 cursor-pointer ${
                    selected.includes(item) && " border-black bg-gray-50"
                  }`}
                  onClick={() => handleSelect(item)}
                >
                  <ToolImg className="h-10" />
                  <div>{item}</div>
                </div>
              );
          })}
        </div>
      </div>
    </div>
  );
};

const mockData = [
  "harbor",
  "sonar",
  "gitHub-actions",
  "kubeapps",
  "prometheus",
  "grafana",
  "postgresql",
  "feature-flag",
  "opencost",
  "keycloak",
  "sops",
  "nginx-ingress",
  "velero",
  "istio",
  "litmus",
  "kyverno",
  "kyverno-reporter",
  "sealed-secrets",
  "cert-manager",
  "vault",
];
// 1. Development and CI/CD:

// Sonar
// GitHub Actions
// Kubeapps
// 2. Infrastructure and Operations:

// Istio
// Velero
// Cert-Manager
// Vault
// Sealed Secrets
// 3. Monitoring and Observability:

// Prometheus
// Grafana
// 4. Database:
// PostgreSQL
// 5. Management and Security:

// Feature Flag
// Kyverno
// Kyverno Reporter
// Keycloak
// SOPS (Secrets Management)
// 6. Operations and Reliability:

// Litmus
// 7. Cost Management:

// Opencost
