import styles from "./InitiativeDetailsRules.module.css";
import {
  Button,
  Empty,
  Input,
  Loader,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  Text,
} from "_sredx/_ui";
import React from "react";
import { InitiativeDetailsRulesRows } from "./InitiativeDetailsRulesRows";
import { InitiativeDetailsRulesHeader } from "./InitiativeDetailsRulesHeader";
import { InitiativeRuleEvaluation } from "./types";

interface InitiativeDetailsRulesProps {
  isLoading: boolean;
  initiativeRules: InitiativeRuleEvaluation[];
  onUpdateRules: () => void;
}

export const InitiativeDetailsRules = ({
  isLoading,
  initiativeRules,
  onUpdateRules,
}: InitiativeDetailsRulesProps) => {
  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>Loading rules...</Text>
      </div>
    );
  }

  if (!isLoading && initiativeRules && initiativeRules.length === 0) {
    return (
      <Empty
        message={"No rules found in this initiative."}
        actionLabel={"Add new rules"}
        onActionClick={onUpdateRules}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Input placeholder="Search rules" />
        <Button onClick={onUpdateRules}>Edit rules</Button>
      </div>
      <div className={styles.table_wrapper}>
        <Table>
          <InitiativeDetailsRulesHeader />
          <TableBody>
            {initiativeRules.map((rule) => (
              <InitiativeDetailsRulesRows initiativeRuleEvaluation={rule} />
            ))}
          </TableBody>
          <TableFooter>
            <TableFooterCell colSpan={6}>
              <Text>{initiativeRules.length} Rules</Text>
            </TableFooterCell>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
};
