import { HEADER_USER_ACTIONS_TYPE, Header, HeaderAction, HeaderActions, HeaderLogo, HeaderSpaceBetween, HeaderUser, HeaderUserActionType } from '_sredx/_ui';
import React from 'react'
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { CogIcon } from "_sredx/_ui/Icon";
import { HEADER_USER_ACTIONS } from '../AppHeader/constants';

interface OnboardingPageHeaderProps {
    username: string;
  }

export const OnboardingPageHeader = ({username}: OnboardingPageHeaderProps) => {
    const navigate = useNavigate();
    const { signoutRedirect } = useAuth();
  
    // Event handlers
    const handleClickConfig = () => navigate("/configurations");
  
    const handleOnAction = (actionType: HeaderUserActionType) => {
      switch (actionType) {
        case HEADER_USER_ACTIONS_TYPE.LOGOUT:
          void signoutRedirect();
          break;
      }
    };
  
    return (
      <Header>
        <div>
        <HeaderLogo />
        </div>
        <HeaderSpaceBetween />
        <HeaderActions>
          <HeaderAction onClick={handleClickConfig}>
            <CogIcon title={"configuration"} />
          </HeaderAction>
        </HeaderActions>
        <HeaderUser
          name={username}
          actions={HEADER_USER_ACTIONS}
          onAction={handleOnAction}
        />
      </Header>
    );
}
