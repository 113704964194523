import React, { useMemo } from "react";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { format } from "date-fns";

import { PRChangesStats } from "./PRChangesStats";
import {
  ChartLegend,
  ChartLegendWrapper,
  LineChart,
} from "_sredx/components/Charts";
import {
  MetricChartHeader,
  MetricChartWrapper,
} from "_sredx/components/Metrics";
import {
  ChartPeriodType,
  PullRequestDto,
  RepoChangesStats,
} from "_sredx/types";

import {
  getLineColorsByRepo,
  groupPRsByRepo,
  groupPullRequestsByDate,
} from "./utils";

interface PRChangesWidgetProps {
  pullRequests?: PullRequestDto[];
  period: ChartPeriodType;
  isLoading?: boolean;
  filterByDateKey?: "createdAt" | "mergedAt" | "closedAt";
}

export const PRChangesWidget = ({
  pullRequests = [],
  period,
}: PRChangesWidgetProps) => {
  const groupedPRs: RepoChangesStats[][] = useMemo(() => {
    return Object.values(groupPRsByRepo(pullRequests)).map((prs) =>
      groupPullRequestsByDate(prs)
    );
  }, [pullRequests]);

  const lineColorsByRepo = useMemo(() => {
    return getLineColorsByRepo(pullRequests);
  }, [pullRequests]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
      }}
    >
      {pullRequests && pullRequests.length > 0 && (
        <MetricChartWrapper>
          <MetricChartHeader title="Pull request changes" />
          <PRChangesStats pullRequests={pullRequests} />
          <div style={{ width: "100%", height: "500px" }}>
            <ParentSize>
              {({ width, height }) => (
                <LineChart<RepoChangesStats>
                  data={groupedPRs}
                  xMapper={(d) => new Date(d.date)}
                  yMapper={(d) => d.nbrLineAdded + d.nbrLineDeleted}
                  xLabelMapper={(val) => format(val, "dd MMM yyyy")}
                  lineColorMapper={(d) =>
                    lineColorsByRepo.get(d.repository) ?? "#5FBDFF"
                  }
                  width={width}
                  height={height}
                  startDate={period.startDate}
                  endDate={period.endDate}
                />
              )}
            </ParentSize>
          </div>
          <ChartLegendWrapper>
            {Array.from(lineColorsByRepo).map(([key, value]) => (
              <ChartLegend key={key} color={value} label={key} />
            ))}
          </ChartLegendWrapper>
        </MetricChartWrapper>
      )}
    </div>
  );
};
