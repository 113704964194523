import React from "react";
import clsx from "clsx";
import style from "./OverviewCardContent.module.css";

interface OverviewCardProps extends React.PropsWithChildren {
  name: string;
  value: number;
}

export const OverviewCardContent = ({
  name,
  value,
  children,
}: OverviewCardProps) => {
  return (
    <div className="flex items-center justify-center px-4 space-x-4">
      <div className="flex items-center">
        <div className="relative">
          <svg
            fill="#d3f6fc"
            width="50px"
            height="50px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#d3f6fc"
            overflow="visible"
            className="relative"
          >
            <g
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="00000"
              strokeWidth="3.168"
            >
              <path d="M21.9,11.5l-4.5-7.8c-0.2-0.3-0.5-0.5-0.9-0.5h-9c-0.4,0-0.7,0.2-0.9,0.5l-4.5,7.8c-0.2,0.3-0.2,0.7,0,1l4.5,7.8c0.2,0.3,0.5,0.5,0.9,0.5h9c0.4,0,0.7-0.2,0.9-0.5l4.5-7.8C22,12.2,22,11.8,21.9,11.5z"></path>
            </g>
            <g>
              <path d="M21.9,11.5l-4.5-7.8c-0.2-0.3-0.5-0.5-0.9-0.5h-9c-0.4,0-0.7,0.2-0.9,0.5l-4.5,7.8c-0.2,0.3-0.2,0.7,0,1l4.5,7.8c0.2,0.3,0.5,0.5,0.9,0.5h9c0.4,0,0.7-0.2,0.9-0.5l4.5-7.8C22,12.2,22,11.8,21.9,11.5z"></path>
            </g>
          </svg>

          <div className={style.icon_wrapper}>
            {children}
          </div>
        </div>
      </div>
      <div className="font-bold text-black">
        <p className={clsx(style.highlight)}>
          <>{value}</>
        </p>
        <p className="font-semibold text-gray-700">{name}</p>
      </div>
    </div>
  );
};
