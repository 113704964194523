import { INTEGRATIONS_KEYS } from "_sredx/utils";
import Service, { ServiceInput } from "models/service/Service";
import * as yup from "yup";
import { IntegrationInstanceDto } from "_sredx/types";

export const Schema = yup.object().shape({
  name: yup.string().required(),
  tag: yup.string(),
  description: yup.string().required(),
  owners: yup
    .array()
    .of(yup.object().shape({ name: yup.string(), id: yup.string() })),
  metadata: yup.object().shape({
    tags: yup.array().of(yup.string()),
    annotations: yup.object(),
  }),
  domains: yup
    .array()
    .of(yup.object().shape({ name: yup.string(), id: yup.string() })),
  resources: yup
    .array()
    .of(
      yup
        .object()
        .shape({ name: yup.string(), id: yup.string(), type: yup.string() })
    ),
  toolsInput: yup.object(),
});

export const mapFormDataToService = (formData: any): any => {
  return {
    name: formData.name,
    tag: formData.tag,
    description: formData.description,
    owners: formData.owners ?? [],
    domains: formData.domains ?? [],
    resources: formData.resources ?? [],
    metadata: {
      tags: formData.metadata?.tags,
      annotations: formData.metadata?.annotations,
    },
    tools: Object.entries(formData.toolsInput || {})
      .map(([key, url]) => {
        if (url)
          return {
            key,
            url,
          };
      })
      .filter(Boolean), // remove undefined
    source: formData.source,
  };
};

export const mapServiceToFormData = (service: Service | ServiceInput): any => {
  const toolsInput = Object.fromEntries(
    service.tools?.map((tool: { key: string; url: string }) => {
      return [tool.key.toLowerCase(), tool.url];
    }) ?? []
  );

  return {
    name: service.name,
    tag: service.tag,
    description: service.description,
    owners: service.owners,
    domains: service.domains,
    metadata: {
      tags: service.metadata?.tags ?? [],
      annotations: service.metadata?.annotations,
    },
    tools: service.tools || [],
    source: service.source || "",
    resources: service.resources ?? [],
    toolsInput,
  };
};

export const getIntegrationInstancesOptions = ({
  integrationKey,
  integrationInstances,
}: {
  integrationKey: string;
  integrationInstances?: IntegrationInstanceDto[];
}) => {
  if (!integrationInstances) {
    return [];
  }

  return integrationInstances
    .filter(
      (integrationInstance) =>
        integrationInstance.integrationKey.toLowerCase() ==
        integrationKey.toLowerCase()
    )
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }));
};

export const resolveAnnotationKeys = (annotation: string) => {
  switch (annotation) {
    case INTEGRATIONS_KEYS.GITHUB:
      return [
        {
          type: "text",
          key: "@sredx/git-repo",
          value: "",
          label: "Github Repository",
          placeholder: "owner/repo",
        },
        { key: "@sredx/git-provider", value: "Github" },
        {
          type: "select",
          key: "@sredx/github-instance",
          value: "",
          label: "Github instance",
          placeholder: "instance name",
        },
      ];
    case INTEGRATIONS_KEYS.GITLAB:
      return [
        {
          type: "text",
          key: "@sredx/git-repo",
          value: "",
          label: "Gitlab Repository",
          placeholder: "owner/repo",
        },
        { key: "@sredx/git-provider", value: "Gitlab" },
        {
          type: "select",
          key: "@sredx/gitlab-instance",
          value: "",
          label: "Gitlab instance",
          placeholder: "instance name",
        },
      ];
    case INTEGRATIONS_KEYS.BACKSTAGE:
      return [
        {
          type: "text",
          key: "@sredx/backstage-entity",
          value: "",
          label: "Backstage entity ID",
          placeholder: "entity-id",
        },
        {
          type: "select",
          key: "@sredx/backstage-instance",
          value: "",
          label: "Backstage instance",
          placeholder: "instance name",
        },
      ];
    case INTEGRATIONS_KEYS.PAGERDUTY:
      return [
        {
          type: "text",
          key: "@sredx/pagerduty-service-id",
          value: "",
          label: "Pagerduty Service ID",
          placeholder: "service-id",
        },
        {
          type: "select",
          key: "@sredx/pagerduty-instance",
          value: "",
          label: "Pagerduty instance",
          placeholder: "instance",
        },
      ];
    case INTEGRATIONS_KEYS.SONAR:
      return [
        {
          type: "text",
          key: "@sredx/sonar-project-key",
          value: "",
          label: "Sonar Project Key",
          placeholder: "project-key",
        },
        {
          type: "select",
          key: "@sredx/sonar-instance",
          value: "",
          label: "Sonar Instance",
          placeholder: "instance name",
        },
      ];
    case INTEGRATIONS_KEYS.JENKINS:
      return [
        {
          type: "text",
          key: "@sredx/jenkins-job",
          value: "",
          label: "Jenkins Job Name",
          placeholder: "job-name",
        },
        {
          type: "select",
          key: "@sredx/jenkins-instance",
          value: "",
          label: "Jenkins Instance",
          placeholder: "Instance name",
        },
      ];
    case INTEGRATIONS_KEYS.JIRA:
      return [
        {
          type: "text",
          key: "@sredx/jira-project-key",
          value: "",
          label: "Jira Project key",
          placeholder: "Project-key",
        },
        {
          type: "select",
          key: "@sredx/jira-instance",
          value: "",
          label: "Jira Instance",
          placeholder: "Instance name",
        },
      ];
    case INTEGRATIONS_KEYS.DEPENDENCYTRACK:
      return [
        {
          type: "text",
          key: "@sredx/dependencytrack-project-uuid",
          value: "",
          label: "DependencyTrack project uuid",
          placeholder: "project-uuid",
        },
        {
          type: "select",
          key: "@sredx/dependencytrack-instance",
          value: "",
          label: "DependencyTrack instance",
          placeholder: "instance name",
        },
      ];
    case INTEGRATIONS_KEYS.DATADOG:
      return [
        {
          type: "text",
          key: "@sredx/datadog-tag",
          value: "",
          label: "Datadog tag",
          placeholder: "tag",
        },
        {
          type: "select",
          key: "@sredx/datadog-instance",
          value: "",
          label: "Datadog Instance",
          placeholder: "datadog instance",
        },
      ];
    case INTEGRATIONS_KEYS.HARBOR:
      return [
        {
          type: "text",
          key: "@sredx/harbor-project",
          value: "",
          label: "Harbor poject",
          placeholder: "Harbor project",
        },
        {
          type: "text",
          key: "@sredx/harbor-image",
          value: "",
          label: "Harbor image",
          placeholder: "image",
        },
        {
          type: "text",
          key: "@sredx/harbor-image-tag",
          value: "",
          label: "Harbor image tag",
          placeholder: "image-tag",
        },
        {
          type: "select",
          key: "@sredx/harbor-instance",
          value: "",
          label: "Harbor Instance",
          placeholder: "instance name",
        },
      ];
    case INTEGRATIONS_KEYS.PIVOTAL:
      return [
        {
          type: "text",
          key: "@sredx/pivotal-project-id",
          value: "",
          label: "pivotal project id",
          placeholder: "project id",
        },
        {
          type: "select",
          key: "@sredx/pivotal-instance",
          value: "",
          label: "Pivotal Instance",
          placeholder: "instance name",
        },
      ];
    case INTEGRATIONS_KEYS.DYNATRACE:
      return [
        {
          type: "text",
          key: "@sredx/dynatrace-management-zone-id",
          value: "",
          label: "dynatrace management zone ",
          placeholder: "dynatrace management zone id",
        },
        {
          type: "select",
          key: "@sredx/dynatrace-instance",
          value: "",
          label: "dynatrace instance",
          placeholder: "instance name",
        },
      ];
  }
};

export const resolveLinkedIntegrations = (annotations: {
  [key: string]: string;
}) => {
  const integrations = [];
  if (
    annotations?.["@sredx/git-provider"]?.toLowerCase() ===
    INTEGRATIONS_KEYS.GITHUB
  ) {
    integrations.push(INTEGRATIONS_KEYS.GITHUB);
  }
  if (
    annotations?.["@sredx/git-provider"]?.toLowerCase() ===
    INTEGRATIONS_KEYS.GITLAB
  ) {
    integrations.push(INTEGRATIONS_KEYS.GITLAB);
  }
  if (annotations?.["@sredx/backstage-entity"]) {
    integrations.push(INTEGRATIONS_KEYS.BACKSTAGE);
  }
  if (annotations?.["@sredx/pagerduty-service-id"]) {
    integrations.push(INTEGRATIONS_KEYS.PAGERDUTY);
  }
  if (annotations?.["@sredx/sonar-project-key"]) {
    integrations.push(INTEGRATIONS_KEYS.SONAR);
  }
  if (annotations?.["@sredx/jenkins-job"]) {
    integrations.push(INTEGRATIONS_KEYS.JENKINS);
  }
  if (annotations?.["@sredx/jira-project-key"]) {
    integrations.push(INTEGRATIONS_KEYS.JIRA);
  }
  if (annotations?.["@sredx/dependencytrack-project-uuid"]) {
    integrations.push(INTEGRATIONS_KEYS.DEPENDENCYTRACK);
  }
  if (annotations?.["@sredx/datadog-tag"]) {
    integrations.push(INTEGRATIONS_KEYS.DATADOG);
  }
  if (annotations?.["@sredx/pivotal-project-id"]) {
    integrations.push(INTEGRATIONS_KEYS.PIVOTAL);
  }
  if (annotations?.["@sredx/dynatrace-management-zone-id"]) {
    integrations.push(INTEGRATIONS_KEYS.DYNATRACE);
  }
  if (
    annotations?.["@sredx/harbor-project"] ||
    annotations?.["@sredx/harbor-image"] ||
    annotations?.["@sredx/harbor-image-tag"]
  ) {
    integrations.push(INTEGRATIONS_KEYS.HARBOR);
  }
  return integrations;
};
