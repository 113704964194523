import React from "react";

import { ScoreCardEditContainer } from "_sredx/containers";
import { PageContent } from "_sredx/_ui";

import styles from "./ScoreCardEditPage.module.css";

export const ScoreCardEditPage = () => {
  return (
    <PageContent className={styles.wrapper}>
      <ScoreCardEditContainer />
    </PageContent>
  );
};
