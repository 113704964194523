import { useQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";
import { InitiativeDto } from "_sredx/types";

interface UseGetInitiativeProps {
  id: string;
}

export const useGetInitiative = ({ id }: UseGetInitiativeProps) => {
  return useQuery<InitiativeDto>({
    queryKey: [QUERY_KEYS.initiatives, id],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getInitiativeById(id));
    },
  });
};
