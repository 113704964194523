import ReactCalendar from "react-calendar";
import styles from "./Calendar.module.css";
import { Value } from "react-calendar/src/shared/types";

interface CalendarProps {
  onChange: (value: [Date, Date]) => void;
  range?: boolean;
}

export const Calendar = ({ range = false, onChange }: CalendarProps) => {
  const handleOnChange = (value: Value) => {
    const [sDate, eDate] = range
      ? (value as Date[])
      : [value as Date, value as Date];
    onChange([sDate, eDate]);
  };
  return (
    <div className={styles.wrapper}>
      <ReactCalendar
        selectRange={range}
        minDetail={"decade"}
        minDate={new Date("2023-01-01")}
        maxDate={new Date()}
        onChange={handleOnChange}
      />
    </div>
  );
};
