import Service from "models/service/Service";
import styles from "./styles.module.css";
import { Tag } from "_sredx/_ui/Tag";

interface DiscoveredServiceCardProps {
  service: Service;
  onClick: () => void;
  selected?: boolean;
  alreadyImported?: boolean;
}
export const DiscoveredServiceCard = ({
  service,
  onClick,
  selected,
  alreadyImported,
}: DiscoveredServiceCardProps) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.wrapper} ${selected ? styles.selected : ""}`}
    >
      <div className={styles.leftSection}>
        {service.name}
        <div className={styles.tags}>
          {service?.metadata?.tags?.map((tag) => <Tag tag={tag} />)}
        </div>
      </div>

      <div className={styles.rightSection}>
        {alreadyImported && <div className={styles.imported}>Imported</div>}
      </div>
    </div>
  );
};
