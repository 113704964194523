import IntegrationTemplate, {
  ConfigParam,
  IntegrationTemplateChild,
} from "models/integration/IntegrationTemplate";
import Service from "models/service/Service";
import { CHILD_SEPARATOR } from "components/IntegrationsConfig/IntegrationTemplateMenuItem/IntegrationTemplateMenuItem";

export const parseConfigParams = (
  templates: IntegrationTemplate[],
  serviceProvider: string
) => {
  let configParamsKeys: ConfigParam[] = [];
  let currentTemplate: string = "";
  const pushParamsToArray = (
    template: IntegrationTemplate | IntegrationTemplateChild
  ) => {
    template.configParams?.forEach((configParam) => {
      configParamsKeys.push(configParam);
    });
  };
  templates.forEach((template: IntegrationTemplate) => {
    if (template) {
      if (template.children && template.children.length > 0) {
        template.children.forEach((child) => {
          if (child.key === serviceProvider) {
            pushParamsToArray(child);
            currentTemplate = template.key + CHILD_SEPARATOR + child.key;
          }
        });
      } else {
        if (template.key === serviceProvider) {
          pushParamsToArray(template);
          currentTemplate = template.key;
        }
      }
    }
  });
  return {
    configParamsKeys: configParamsKeys,
    currentTemplate: currentTemplate,
  };
};
export const checkIfServiceIsSelected = (
  selectedServices: Service[],
  service: Service
) => {
  return selectedServices.some((s) => s.tag === service.tag);
};

export const checkIfServiceAlreadyImported = (
  service: Service,
  existingServices: Service[]
) => {
  return existingServices?.some((s) => s.tag === service.tag);
};
