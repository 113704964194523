import {
  Button,
  Card,
  Empty,
  Pagination,
  ProgressBar,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  TableRow,
  Tag,
  TrashIcon,
} from "_sredx/_ui";
import { ResourceDto } from "_sredx/types/resources";
import styles from "./ResourcesDataTable.module.css";
import { INITIATIVE_SEARCH_CRITERIA_DEFAULT } from "_sredx/containers";
import { resolveResourceIcon } from "_sredx/utils";

interface ResourcesDataTableProps {
  resources?: ResourceDto[];
  isLoading: boolean;
  isFetching: boolean;
  onPageChange: (page: number) => void;
  totalPages?: number;
  currentPage: number;
  totalElements?: number;
  onDeleteResource: (resource: ResourceDto) => void;
  onClickResource: (resourceId: string) => void;
  onAddResourceClick: () => void;
}
const RESOURCES_TABLE_HEADER = [
  "Resource",
  "Version",
  "Tags",
  "Services",
  "Actions",
];

export const ResourcesDataTable = ({
  resources = [],
  totalElements = 0,
  totalPages = 0,
  isLoading,
  isFetching,
  onPageChange,
  currentPage,
  onDeleteResource,
  onClickResource,
  onAddResourceClick,
}: ResourcesDataTableProps) => {
  const showPagination = totalPages > 1;

  const count =
    (INITIATIVE_SEARCH_CRITERIA_DEFAULT.pageSize || 10) * currentPage +
    resources.length;

  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };

  if (!isLoading && resources && resources.length === 0) {
    return (
      <Card>
        <Empty
          message={"No Resources found for your organization."}
          actionLabel={"Add new resource"}
          onActionClick={onAddResourceClick}
        />
      </Card>
    );
  }

  return (
    <Table>
      <TableHeader>
        {RESOURCES_TABLE_HEADER.map((header) => (
          <TableHead key={header}>{header}</TableHead>
        ))}
      </TableHeader>

      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {resources.map((resource, i) => (
          <TableRow key={i} className={styles.table_row}>
            <TableCell>
              {resolveResourceIcon(resource)}
              <strong style={{ fontWeight: 700, marginLeft: "12px" }}>
                {resource.name}
              </strong>
            </TableCell>
            <TableCell>{resource.version}</TableCell>
            <TableCell>
              <div className={styles.tags_container}>
                {resource.tags.slice(0, 3).map((tag, i) => (
                  <Tag key={i} tag={tag} />
                ))}
                {resource.tags.length > 3 && `+${resource.tags.length - 3}`}
              </div>
            </TableCell>

            <TableCell align="center">{resource.services.length}</TableCell>
            <TableCell align="right">
              <Button
                ghost
                variant="neutral"
                size="small"
                onClick={() => onDeleteResource(resource)}
              >
                <TrashIcon width={16} color="var(--red-400)" />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableFooterCell colSpan={RESOURCES_TABLE_HEADER.length}>
          <div className={styles.footer}>
            <span>
              {count} / {totalElements} services
            </span>
            {showPagination && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handleOnPageChange}
                currentPage={currentPage}
              />
            )}
          </div>
        </TableFooterCell>
      </TableFooter>
    </Table>
  );
};
