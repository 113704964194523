import { ChartMargins } from "./types";

export const DEFAULT_CHART_MARGIN: ChartMargins = {
  top: 40,
  left: 70,
  right: 20,
  bottom: 40,
};

export const MIN_CHART_WIDTH = 200;

export const CHART_GRID_COLOR = "#C4C3CA";

export const axisDefaultLabelMapper = (value: any): string => value;
