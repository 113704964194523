import React from 'react'

export const PagerDuty = () => {
  return (
    <svg
      version="1.2"
      viewBox="0 0 1071 1554"
     width="24"
      height="24"
      fill="currentColor"
    >
      <g id="Layer">
        <path id="Layer" d="m0 1139.5h227.5v413.6h-227.5z" />
        <path
          id="Layer"
          fillRule="evenodd"
          d="m1070.5 464.8c0 162.5-66 303.4-194 388.1-117.2 77.8-250.1 88.6-428.4 88.6h-448.1v-941.5h450.1c199.9 0 284.6 10.8 406.7 75.8 132 71.9 213.7 212.8 213.7 389zm-234.4 2c0-187.1-117.2-269.8-351.6-266.9l-257 2v542.6h272.8c191.1 0 335.8-76.8 335.8-277.7z"
        />
      </g>
    </svg>
  );
}
