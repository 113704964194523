import { RuleDto } from "_sredx/types";
import { TableCell, TableRow, Text } from "_sredx/_ui";
import styles from "./RulesAssignmentTable.module.css";
import { capitalizeFirstLetter } from "_sredx/utils/string";

interface RuleAssignmentRowsProps {
  rules?: RuleDto[];
  onCheckChange: (checked: boolean, ruleId: string) => void;
  checkedRules?: RuleDto[];
}

export const RulesAssignmentRows = ({
  rules = [],
  onCheckChange,
  checkedRules = [],
}: RuleAssignmentRowsProps) => {
  return (
    <>
      {rules.map((rule) => {
        const checked = checkedRules.map((rule) => rule.id).includes(rule.id);
        return (
          <TableRow>
            <TableCell>
              <label className={styles.checkbox_wrapper}>
                <input
                  type={"checkbox"}
                  checked={checked}
                  onChange={(e) => onCheckChange(e.target.checked, rule.id)}
                />
                <Text>{rule.ruleName}</Text>
              </label>
            </TableCell>
            <TableCell>
              <Text>{rule.scoreCardName}</Text>
            </TableCell>
            <TableCell>
              <Text>
                {rule.ruleCategory
                  ? capitalizeFirstLetter(rule.ruleCategory.categoryName)
                  : "N/A"}
              </Text>
            </TableCell>
            <TableCell align={"right"}>
              <Text>{rule.score}</Text>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
