import * as yup from "yup";
import { InitiativeInfoFormData } from "./types";
import { INITIATIVE_STATUSES } from "_sredx/types";

export const schema = yup.object<InitiativeInfoFormData>().shape({
  name: yup.string().required("Name is required."),
  description: yup.string().required("Description is required."),
  status: yup.string().required("Status is required."),
});

export const getInitiativeStatusOptions = () => {
  return Object.entries(INITIATIVE_STATUSES).map(([key, value]) => ({
    label: value.toLowerCase(),
    value: key,
  }));
};
