import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "_sredx/_ui";
import { InitiativeBaseDto } from "_sredx/types";
import { RoadmapCreationInitiativesList } from "../RoadmapCreationInitiativesList";
import styles from "./RoadmapCreationInitiatives.module.css";
import { RoadmapCreationInitiativesActions } from "./types";

interface RoadmapCreationInitiativesProps {
  initiatives: InitiativeBaseDto[];
  onAction: (action: RoadmapCreationInitiativesActions) => void;
}

export const RoadmapCreationInitiatives = ({
  initiatives,
  onAction,
}: RoadmapCreationInitiativesProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Roadmap Initiatives</CardTitle>
        <CardSubtitle>
          Define roadmaps goals by associating initiatives
        </CardSubtitle>
      </CardHeader>
      <CardBody noPadding>
        <div className={styles.body_wrapper}>
          <RoadmapCreationInitiativesList
            initiatives={initiatives}
            onAction={onAction}
            emptyMessage={"This roadmap has no initiatives"}
            withAddAction
          />
        </div>
      </CardBody>
    </Card>
  );
};
