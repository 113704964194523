import React, { FC, PropsWithChildren, ReactNode } from 'react'

type LeaderBoardPanelProps = PropsWithChildren<{
    heading: string;
    filter?: ReactNode;
}>

const LeaderBoardPanel: FC<LeaderBoardPanelProps> = ({heading, filter, children}) => {
  return (
    <div className='border border-black' >
        <div className='flex justify-between bg-black p-2 items-center' >
            <div className='w-1/2 text-white font-bold text-lg' role='heading' aria-level={1} >{heading}</div>
            {filter && <div className='w-1/2 text-black bg-white' >
                {filter}
            </div>}
        </div>
        <div className='p-2' >
            {children}
        </div>
    </div>
  )
}

export default LeaderBoardPanel