import { ReactNode, useMemo } from "react";
import { scaleLinear, scaleTime } from "@visx/scale";
import { GridRows } from "@visx/grid";
import { Circle } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { Group } from "@visx/group";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { ChartProps } from "../types";
import {
  axisDefaultLabelMapper,
  CHART_GRID_COLOR,
  DEFAULT_CHART_MARGIN,
  MIN_CHART_WIDTH,
} from "../config";
import styles from "./DotChart.module.css";

let tooltipTimeout: number;
const tooltipStyles = {
  ...defaultStyles,
  padding: 0,
  margin: 0,
};

interface DotTimeChartProps<T> extends ChartProps {
  data: T[];
  yMapper: (d: T) => number;
  xMapper: (d: T) => Date;
  yLabelMapper?: (val: any) => string;
  xLabelMapper?: (val: any) => string;
  dotColorMapper?: (d: T) => string;
  dotSizeMapper?: (d: T) => number;
  dotOutlineColorMapper?: (d: T) => string;
  onClick?: (d: T) => void;
  startDate: Date;
  endDate: Date;
  renderTooltip?: (d: T) => ReactNode;
}

export const DotTimeChart = <T,>({
  width,
  height,
  data,
  dotColorMapper,
  dotSizeMapper,
  yMapper,
  xMapper,
  yLabelMapper = axisDefaultLabelMapper,
  xLabelMapper = axisDefaultLabelMapper,
  startDate,
  endDate,
  bgColor = "transparent",
  margin = DEFAULT_CHART_MARGIN,
  renderTooltip,
  dotOutlineColorMapper = () => "transparent",
}: DotTimeChartProps<T>) => {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<T>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = useMemo(() => {
    return scaleTime<number>({
      range: [0, xMax],
      domain: [startDate, endDate],
    });
  }, [xMax, startDate, endDate]);

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map(yMapper))],
      }),
    [yMax]
  );

  if (width < MIN_CHART_WIDTH) {
    return null;
  }

  return (
    <div>
      <svg width={width} height={height} ref={containerRef}>
        <rect x={0} y={0} width={width} height={height} fill={bgColor} />
        <GridRows
          scale={yScale}
          width={xMax}
          height={yMax}
          top={margin.top}
          left={margin.left}
          stroke={CHART_GRID_COLOR}
        />
        <Group top={margin.top} left={margin.left}>
          {data.map((d, i) => {
            const cx = xScale(xMapper(d));
            const cy = yScale(yMapper(d));
            return (
              <Circle
                key={`point-${cx}-${cy}-${i}`}
                className={styles.dot}
                cx={cx}
                cy={cy}
                r={dotSizeMapper ? dotSizeMapper(d) : 4}
                fill={dotColorMapper ? dotColorMapper(d) : "#03001C"}
                opacity={0.8}
                stroke={
                  dotOutlineColorMapper
                    ? dotOutlineColorMapper(d)
                    : "transparent"
                }
                onMouseOver={(event) => {
                  if (!renderTooltip) return;
                  if (tooltipTimeout) clearTimeout(tooltipTimeout);
                  const eventSvgCoords = localPoint(event);
                  showTooltip({
                    tooltipData: d,
                    tooltipTop: eventSvgCoords?.y,
                    tooltipLeft: eventSvgCoords?.x,
                  });
                }}
                onMouseOut={() => {
                  tooltipTimeout = window.setTimeout(() => {
                    hideTooltip();
                  }, 300);
                }}
              />
            );
          })}
          <AxisLeft
            hideAxisLine
            left={0}
            hideTicks
            scale={yScale}
            tickFormat={yLabelMapper}
            tickLabelProps={{
              fill: "#03001C",
              fontSize: 11,
              textAnchor: "end",
              dy: "0.33em",
            }}
          />
          {xScale && (
            <AxisBottom
              top={yMax}
              scale={xScale}
              stroke={"#03001C"}
              tickStroke={"#03001C"}
              tickFormat={xLabelMapper}
            />
          )}
        </Group>
      </svg>
      {tooltipOpen && tooltipData && renderTooltip && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          {renderTooltip(tooltipData)}
        </TooltipInPortal>
      )}
    </div>
  );
};
