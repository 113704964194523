import React, {PropsWithChildren} from 'react';
import styles from "./ScorecardLevelsSectionComponent.module.css";

interface ScorecardLevelsSectionWrapperProps extends PropsWithChildren {
    levelsCount : number;
}

export const ScorecardLevelsSectionWrapper = ({ levelsCount ,children } : ScorecardLevelsSectionWrapperProps) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Levels ({levelsCount})</p>
      {children}
      </div>
  )
}
