import clsx from "clsx";
import styles from "./ServicesCreationContainer.module.css";
import React, { useState } from "react";
import {
  ConfirmationStepContainer,
  ExternalServiceProviderStepContainer,
  ServiceCreationStepContainer,
} from "containers/ServiceAddContainer";
import { ServiceInput } from "models/service/Service";
import { Step, StepperProvider } from "componentsv2/shared/StepperComponent";
import { useStepper } from "hooks/stepper/useStepper";
import { StepType } from "types/Stepper";

const STEPS: StepType[] = [
  {
    stepKey: "SELECT_PROVIDER",
    order: 1,
    description: "Service discovery",
  },
  {
    stepKey: "SERVICE_IMPORT",
    order: 2,
    description: "Select services to import",
  },
  {
    stepKey: "CONFIRMATION",
    order: 3,
    description: "Confirm your service import",
  },
];

export const ServicesCreationContainer = () => {
  // State
  const [services, setServices] = useState<ServiceInput[]>([]);

  const stepperMethods = useStepper({
    steps: STEPS,
    defaultStep: STEPS[0].stepKey,
    displaySteps: false,
  });

  return (
    <div className={clsx(styles.wrapper)}>
      <StepperProvider {...stepperMethods}>
        <Step stepKey={STEPS[0].stepKey}>
          <ExternalServiceProviderStepContainer
            skipLabel={"I want to do it myself"}
            onSkip={() => {
              stepperMethods.goNextStep();
              setServices([]);
            }}
            onNextStep={(services) => {
              stepperMethods.goNextStep();
              setServices(services);
            }}
          />
        </Step>
        <Step stepKey={STEPS[1].stepKey}>
          <ServiceCreationStepContainer
            importedServices={services}
            onNextStep={(services) => {
              stepperMethods.goNextStep();
              setServices(services);
            }}
            onPreviousStep={() => stepperMethods.goPrevStep()}
          />
        </Step>
        <Step stepKey={STEPS[2].stepKey}>
          <ConfirmationStepContainer
            services={services}
            onPreviousStep={() => stepperMethods.goPrevStep()}
          />
        </Step>
      </StepperProvider>
    </div>
  );
};
