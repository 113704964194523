import github from "./github.svg";
import dataDog from "./dataDog.svg";
import jira from "./jira.svg";
import sonarqube from "./sonarqube.svg";



export const toolsData = [
    github,
    dataDog,
    jira,
    sonarqube 
]