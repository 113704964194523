import ScoreCardsHealthContainer from "_sredx/containers/DashboardContainer/ScoreCardsHealthContainer/ScoreCardsHealthContainer";
import { ScoreCardsHealthPerLevelsContainer } from "_sredx/containers/DashboardContainer/ScoreCardsHealthPerLevelsContainer";
import { DASHBOARD_WIDGETS } from "_sredx/types/dashboards";
import ServiceHealthContainer from "./ServiceHealthContainer/ServiceHealthContainer";
import TeamHealthContainer from "./TeamHealthContainer/TeamHealthContainer";
import { Temp } from "./temp";
import { ServicePerformanceContainer } from "./ServicePerformanceContainer";
import { BestPerformingServicesContainer } from "./BestPerformingServicesContainer";
import { WorstPerformingServicesContainer } from "./WorstPerformingServicesContainer";
import { BestPerformingTeamsContainer } from "./BestPerformingTeamsContainer";
import { WorstPerformingTeamsContainer } from "./WorstPerformingTeamsContainer";
import { CountContainer } from "./CountContainer";
import { ScoreTimelineContainer } from "./ScoreTimelineContainer";
import { ServicesBirdEyeContainer } from "./ServicesBirdEyeContainer";
import { MostPassingRulesContainer } from "./MostPassingRulesContainer";
import { LeastPassingRulesContainer } from "./LeastPassingRulesContainer";
import { WorstDropServicesContainer } from "./WorstDropServicesContainer";
import { WorstDropTeamsContainer } from "./WorstDropTeamsContainer";

type WidgetComponent = {
  id: string;
  component: React.ComponentType<any>;
  dimensions: { cols: number; rows: number };

  title?: string;
  noPadding?: boolean;
  props?: any;
} | null;

export const mapWidgetComponents = (widgets: string[]): WidgetComponent[] =>
  widgets.map((widget) => {
    switch (widget) {
      //TEAMS
      case DASHBOARD_WIDGETS.TEAMS.TEAMS_HEALTH:
        return {
          id: DASHBOARD_WIDGETS.TEAMS.TEAMS_HEALTH,
          component: TeamHealthContainer,
          title: "Team Health",
          dimensions: { cols: 2, rows: 2 },
        };
      case DASHBOARD_WIDGETS.TEAMS.BEST_PERFORMING_TEAMS:
        return {
          id: DASHBOARD_WIDGETS.TEAMS.BEST_PERFORMING_TEAMS,
          component: BestPerformingTeamsContainer,
          title: "Best Performing Teams",
          noPadding: true,
          dimensions: { cols: 2, rows: 2 },
        };
      case DASHBOARD_WIDGETS.TEAMS.WORST_PERFORMING_TEAMS:
        return {
          id: DASHBOARD_WIDGETS.TEAMS.WORST_PERFORMING_TEAMS,
          component: WorstPerformingTeamsContainer,
          title: "Worst Performing Teams",
          noPadding: true,
          dimensions: { cols: 2, rows: 2 },
        };
      case DASHBOARD_WIDGETS.TEAMS.TEAMS_SCORECARDS:
        return {
          id: DASHBOARD_WIDGETS.TEAMS.TEAMS_SCORECARDS,
          component: Temp,
          title: "Teams Scorecards",
          dimensions: { cols: 2, rows: 2 },
        };
      case DASHBOARD_WIDGETS.TEAMS.COUNT_TEAMS:
        return {
          id: DASHBOARD_WIDGETS.TEAMS.COUNT_TEAMS,
          component: CountContainer,
          props: { key: DASHBOARD_WIDGETS.TEAMS.COUNT_TEAMS, title: "Teams" },
          dimensions: { cols: 1, rows: 1 },
        };
      case DASHBOARD_WIDGETS.TEAMS.COUNT_MEMBERS:
        return {
          id: DASHBOARD_WIDGETS.TEAMS.COUNT_MEMBERS,
          component: CountContainer,
          props: {
            key: DASHBOARD_WIDGETS.TEAMS.COUNT_MEMBERS,
            title: "Members",
          },
          dimensions: { cols: 1, rows: 1 },
        };
      case DASHBOARD_WIDGETS.TEAMS.WORST_DROP_TEAMS:
        return {
          id: DASHBOARD_WIDGETS.TEAMS.WORST_DROP_TEAMS,
          component: WorstDropTeamsContainer,
          props: { sort: "DESC" },
          noPadding: true,
          dimensions: { cols: 2, rows: 2 },
        };
      case DASHBOARD_WIDGETS.TEAMS.BEST_RISE_TEAMS:
        return {
          id: DASHBOARD_WIDGETS.TEAMS.BEST_RISE_TEAMS,
          component: WorstDropTeamsContainer,
          noPadding: true,
          props: { sort: "ASC" },

          dimensions: { cols: 2, rows: 2 },
        };

      //SERVICES
      case DASHBOARD_WIDGETS.SERVICES.SERVICES_HEALTH:
        return {
          id: DASHBOARD_WIDGETS.SERVICES.SERVICES_HEALTH,
          component: ServiceHealthContainer,
          title: "Service Health",
          dimensions: { cols: 2, rows: 2 },
        };
      case DASHBOARD_WIDGETS.SERVICES.COUNT_SERVICES:
        return {
          id: DASHBOARD_WIDGETS.SERVICES.COUNT_SERVICES,
          component: CountContainer,
          props: {
            key: DASHBOARD_WIDGETS.SERVICES.COUNT_SERVICES,
            title: "Services",
          },
          dimensions: { cols: 1, rows: 1 },
        };
      case DASHBOARD_WIDGETS.SERVICES.SERVICES_PERFORMANCE:
        return {
          id: DASHBOARD_WIDGETS.SERVICES.SERVICES_PERFORMANCE,
          component: ServicePerformanceContainer,
          // title: "Services Performance",
          noPadding: true,
          dimensions: { cols: 3, rows: 2 },
        };
      case DASHBOARD_WIDGETS.SERVICES.SERVICES_BIRD_EYE:
        return {
          id: DASHBOARD_WIDGETS.SERVICES.SERVICES_BIRD_EYE,
          component: ServicesBirdEyeContainer,
          title: "Services Bird Eye",
          noPadding: true,
          dimensions: { cols: 6, rows: 3 },
        };
      case DASHBOARD_WIDGETS.SERVICES.BEST_PERFORMING_SERVICES:
        return {
          id: DASHBOARD_WIDGETS.SERVICES.BEST_PERFORMING_SERVICES,
          component: BestPerformingServicesContainer,
          title: "Best Performing Services",
          noPadding: true,
          dimensions: { cols: 2, rows: 2 },
        };
      case DASHBOARD_WIDGETS.SERVICES.WORST_PERFORMING_SERVICES:
        return {
          id: DASHBOARD_WIDGETS.SERVICES.WORST_PERFORMING_SERVICES,
          component: WorstPerformingServicesContainer,
          title: "Worst Performing Services",
          noPadding: true,
          dimensions: { cols: 2, rows: 2 },
        };
      case DASHBOARD_WIDGETS.SERVICES.WORST_DROP_SERVICES:
        return {
          id: DASHBOARD_WIDGETS.SERVICES.WORST_DROP_SERVICES,
          component: WorstDropServicesContainer,
          noPadding: true,
          props: { sort: "DESC" },
          dimensions: { cols: 2, rows: 2 },
        };
      case DASHBOARD_WIDGETS.SERVICES.BEST_RISE_SERVICES:
        return {
          id: DASHBOARD_WIDGETS.SERVICES.BEST_RISE_SERVICES,
          component: WorstDropServicesContainer,
          props: { sort: "ASC" },
          noPadding: true,
          dimensions: { cols: 2, rows: 2 },
        };

      //SCORECARDS
      case DASHBOARD_WIDGETS.SCORECARDS.SCORECARDS_HEALTH:
        return {
          id: DASHBOARD_WIDGETS.SCORECARDS.SCORECARDS_HEALTH,
          component: ScoreCardsHealthContainer,
          title: "ScoreCards Health",
          dimensions: { cols: 3, rows: 2 },
        };
      case DASHBOARD_WIDGETS.SCORECARDS.SCORECARDS_HEALTH_PER_LEVELS:
        return {
          id: DASHBOARD_WIDGETS.SCORECARDS.SCORECARDS_HEALTH_PER_LEVELS,
          component: ScoreCardsHealthPerLevelsContainer,
          title: "ScoreCards Health per levels",
          dimensions: { cols: 3, rows: 2 },
        };
      case DASHBOARD_WIDGETS.SCORECARDS.COUNT_SCORECARDS:
        return {
          id: DASHBOARD_WIDGETS.SCORECARDS.COUNT_SCORECARDS,
          component: CountContainer,
          props: {
            key: DASHBOARD_WIDGETS.SCORECARDS.COUNT_SCORECARDS,
            title: "Scorecards",
          },
          dimensions: { cols: 1, rows: 1 },
        };
      case DASHBOARD_WIDGETS.SCORECARDS.SCORE_TIMELINE:
        return {
          id: DASHBOARD_WIDGETS.SCORECARDS.SCORE_TIMELINE,
          component: ScoreTimelineContainer,
          dimensions: { cols: 3, rows: 2 },
          noPadding: true,
        };

      //INITIATIVES
      case DASHBOARD_WIDGETS.INITIATIVES.COUNT_INITIATIVES:
        return {
          id: DASHBOARD_WIDGETS.INITIATIVES.COUNT_INITIATIVES,
          props: {
            key: DASHBOARD_WIDGETS.INITIATIVES.COUNT_INITIATIVES,
            title: "Initiatives",
          },
          component: CountContainer,
          dimensions: { cols: 1, rows: 1 },
        };

      //CLUSTERS
      case DASHBOARD_WIDGETS.CLUSTERS.COUNT_CLUSTERS:
        return {
          id: DASHBOARD_WIDGETS.CLUSTERS.COUNT_CLUSTERS,
          component: CountContainer,
          props: {
            key: DASHBOARD_WIDGETS.CLUSTERS.COUNT_CLUSTERS,
            title: "Clusters",
          },
          dimensions: { cols: 1, rows: 1 },
        };

      //RULES
      case DASHBOARD_WIDGETS.RULES.COUNT_RULES:
        return {
          id: DASHBOARD_WIDGETS.RULES.COUNT_RULES,
          component: CountContainer,
          props: { key: DASHBOARD_WIDGETS.RULES.COUNT_RULES, title: "Rules" },
          dimensions: { cols: 1, rows: 1 },
        };
      case DASHBOARD_WIDGETS.RULES.MOST_PASSING_RULES:
        return {
          id: DASHBOARD_WIDGETS.RULES.MOST_PASSING_RULES,
          component: MostPassingRulesContainer,
          title: "Most Passing Rules",
          noPadding: true,
          dimensions: { cols: 2, rows: 2 },
        };

      case DASHBOARD_WIDGETS.RULES.LEAST_PASSING_RULES:
        return {
          id: DASHBOARD_WIDGETS.RULES.LEAST_PASSING_RULES,
          component: LeastPassingRulesContainer,
          title: "Least Passing Rules",
          noPadding: true,
          dimensions: { cols: 2, rows: 2 },
        };

      default:
        return {
          id: "UNKNOWN",
          component: Temp,
          title: "Unknown",
          dimensions: { cols: 1, rows: 1 },
        };
    }
  });

enum Filters {
  PERIOD,
  SERVICE,
  TEAM,
  SCORECARD,
}
