import styles from "./AdvancedFilters.module.css";
import {
  AdvancedFilterConfig,
  AdvancedFilterValue,
  GetOptionsByKeyFn,
} from "./types";
import { AdvancedFiltersPill } from "./AdvancedFiltersPill";
import { memo, useEffect, useState } from "react";
import { Button } from "./Button";
import { PlusCircleIcon } from "./Icons";

interface AdvancedFiltersProps {
  config: AdvancedFilterConfig[];
  onChange?: (value: AdvancedFilterValue[]) => void;
  initialValue?: AdvancedFilterValue[];
  getOptionsByKey?: GetOptionsByKeyFn;
}

export const AdvancedFilters = memo(
  ({
    config,
    getOptionsByKey,
    onChange,
    initialValue = [],
  }: AdvancedFiltersProps) => {
    const [filters, setFilters] = useState<AdvancedFilterValue[]>(initialValue);

    useEffect(() => {
      if (onChange) {
        onChange(filters);
      }
    }, [onChange, filters]);

    const handleFilterChange = (index: number, value: AdvancedFilterValue) => {
      const newFilters = [...filters];
      newFilters[index] = value;
      setFilters(newFilters);
    };

    const handleAddFilter = () => {
      setFilters([...filters, { key: "" }]);
    };

    const clearFilters = () => {
      setFilters(initialValue);
    };

    const handleDeleteFilter = (index: number) => {
      setFilters((oldFilters) => oldFilters.filter((_, i) => i !== index));
    };

    return (
      <div className={styles.filters}>
        {filters.map((filter, index) => (
          <AdvancedFiltersPill
            key={`${index}-${filter.key}`}
            config={config}
            value={filter}
            selectedKeys={filters.map((f) => f.key)}
            onChange={(val) => handleFilterChange(index, val)}
            getOptionsByKey={getOptionsByKey}
            deleteButtonVisible={index > 0}
            onDelete={() => handleDeleteFilter(index)}
          />
        ))}
        <Button onClick={handleAddFilter}>
          <PlusCircleIcon size={18} />
          <span>Add filter</span>
        </Button>
        {filters.length > 1 && (
          <Button onClick={clearFilters}>
            <span>Clear filters</span>
          </Button>
        )}
      </div>
    );
  }
);
