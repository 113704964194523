import {forwardRef} from "react";
import {IconProps} from "./types";

export const PeopleIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
          {/*<path fill={color} fillRule="evenodd" d="M9.11 4.65a2.906 2.906 0 1 0 0 5.81 2.906 2.906 0 0 0 0-5.81ZM4.906 7.555a4.206 4.206 0 1 1 8.411 0 4.206 4.206 0 0 1-8.41 0ZM13.794 4a.65.65 0 0 1 .65-.65 4.206 4.206 0 0 1 0 8.41.65.65 0 1 1 0-1.3 2.906 2.906 0 0 0 0-5.81.65.65 0 0 1-.65-.65Zm-4.797 9.572h.228c.912-.001 2.696-.003 4.283.442.832.232 1.667.602 2.301 1.207.654.624 1.063 1.468 1.063 2.556a2.872 2.872 0 0 1-2.873 2.873H4.223a2.872 2.872 0 0 1-2.872-2.873c0-1.088.408-1.932 1.062-2.556.635-.605 1.47-.975 2.301-1.207 1.588-.445 3.37-.443 4.284-.442Zm-5.688 2.59c-.401.383-.66.89-.66 1.615 0 .869.705 1.573 1.573 1.573h9.777c.869 0 1.573-.704 1.573-1.573 0-.725-.258-1.232-.66-1.615-.421-.402-1.03-.694-1.754-.897-1.41-.394-3.037-.394-3.961-.393h-.172c-.924 0-2.552-.001-3.961.393-.724.203-1.334.495-1.755.897Zm14.053-1.808a.65.65 0 0 1 .764-.51c.955.19 1.959.523 2.736 1.134.805.634 1.343 1.55 1.343 2.8a2.872 2.872 0 0 1-2.872 2.872.65.65 0 0 1 0-1.3c.868 0 1.572-.704 1.572-1.573 0-.82-.33-1.37-.847-1.777-.545-.43-1.32-.71-2.185-.881a.65.65 0 0 1-.511-.765Z" clipRule="evenodd"/>*/}
          <path fill={color} fillRule="evenodd" d="M5.85 8.5a3.15 3.15 0 1 1 6.3 0 3.15 3.15 0 0 1-6.3 0ZM9 6.65a1.85 1.85 0 1 0 0 3.7 1.85 1.85 0 0 0 0-3.7ZM14.35 6a.65.65 0 0 1 .65-.65 3.15 3.15 0 1 1 0 6.3.65.65 0 1 1 0-1.3 1.85 1.85 0 0 0 0-3.7.65.65 0 0 1-.65-.65ZM9 14.65A5.35 5.35 0 0 0 3.65 20a.65.65 0 1 1-1.3 0 6.65 6.65 0 1 1 13.3 0 .65.65 0 1 1-1.3 0A5.35 5.35 0 0 0 9 14.65Zm6.496-.665a.65.65 0 0 1 .76-.517A6.652 6.652 0 0 1 21.65 20a.65.65 0 1 1-1.3 0 5.352 5.352 0 0 0-4.338-5.255.65.65 0 0 1-.516-.76Z" clipRule="evenodd"/>

      </svg>
    );
  }
);
