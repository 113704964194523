import { PropsWithChildren } from "react";
import { Heading } from "_sredx/_ui";

interface CardTitleProps {
  className?: string;
}

export const CardTitle = ({ children }: PropsWithChildren<CardTitleProps>) => {
  return <Heading level={3}>{children}</Heading>;
};
