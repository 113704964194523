import { useQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { PullRequestDto, PullRequestFilterObject } from "_sredx/types";
import { ClientApi } from "_sredx/services/clientApi";

interface UseGetFilteredPullRequestsProps {
  filters: PullRequestFilterObject[];
}

export const useGetFilteredPullRequests = ({
  filters,
}: UseGetFilteredPullRequestsProps) => {
  return useQuery<PullRequestDto[]>({
    queryKey: [QUERY_KEYS.pullRequests, filters],
    queryFn: () => {
      return ClientApi.post(API_ENDPOINTS.getFilteredPullRequests(), {
        filters,
      });
    },
  });
};
