import { StateCreator } from "zustand";
import { ReactNode } from "react";

export interface ModalOptions {
  noPadding?: boolean;
  fullScreenOnMobile?: boolean;
  showCloseButton?: boolean;
  width?: string;
}
export interface ModalSlice {
  options: ModalOptions;
  openModal: ({
    content,
    options,
  }: {
    content: ReactNode;
    options?: ModalOptions;
  }) => void;
  closeModal: () => void;
  toggleModal: (open: boolean) => void;
  isModalOpen: boolean;
  modalContent: ReactNode | null;
}

export const createModalSlice: StateCreator<ModalSlice, [], [], ModalSlice> = (
  set
) => ({
  options: {
    noPadding: false,
    fullScreenOnMobile: false,
    showCloseButton: true,
  },
  isModalOpen: false,
  modalContent: null,
  openModal: ({ content, options }) =>
    set((state) => ({
      ...state,
      options: {
        ...state.options,
        ...options,
      },
      isModalOpen: true,
      modalContent: content,
    })),
  closeModal: () =>
    set((state) => ({
      ...state,
      isModalOpen: false,
      modalContent: null,
    })),
  toggleModal: (open) =>
    set((state) => ({
      ...state,
      isModalOpen: open,
    })),
});

type State = ModalSlice;

export const ModalSelector = (state: State) => state;
