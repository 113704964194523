import React from 'react'

export const Dynatrace = () => {
  return (
    <svg
      version="1.2"
      viewBox="0 0 1572 1591"
    >
      <title>Dynatrace_Logo_color_positive_horizontal-svg</title>
      <style>
        {
          ".s0 { fill: #1496ff } .s1 { fill: #1284ea } .s2 { fill: #b4dc00 } .s3 { fill: #6f2da8 } .s4 { fill: #591f91 } .s5 { fill: #73be28 } "
        }
      </style>
      <g id="Variants">
        <g id="Layer">
          <g id="Layer">
            <path
              id="Layer"
              className="s0"
              d="m530.8 133.5c-20.4 107.9-45.4 268-59 430.4-23.8 286.1-9.1 478-9.1 478l-403 382.6c0 0-30.6-214.6-46.5-456.4-9.1-149.9-12.5-281.6-12.5-361.1 0-4.5 2.2-9.1 2.2-13.6 0-5.7 6.9-59.1 59.1-109 56.7-54.5 475.7-382.7 468.8-350.9z"
            />
            <path
              id="Layer"
              className="s1"
              d="m530.8 133.5c-20.4 107.9-45.4 268-59 430.4 0 0-446.1-53.4-471.1 54.5 0-5.7 7.9-71.6 60.1-121.5 56.8-54.5 476.9-395.2 470-363.4z"
            />
            <path
              id="Layer"
              className="s2"
              d="m48.4 482.1c115.8-110.1 404.1-383.8 460.9-438.3 52.2-49.9 107.8-43.1 113.5-43.1 78.3 4.5 216.8 14.7 374.6 34 240.7 28.4 451.9 68.2 451.9 68.2l-403.1 382.6c0 0-189.5-23.8-476.8-14.7-162.3 4.5-323.5 21.5-432.5 36.3-21.6 2.3-74.9 13.6-107.8 55.6-15.9 21.6-23.9 35.2-28.4 54.5 0-9.1 0-17 0-24.9 0-4.6-4.6-60.2 47.7-110.2z"
            />
            <path
              id="Layer"
              className="s3"
              d="m1066.7 1524.5c-52.2 49.9-107.9 60.1-113.5 60.1-5.7 0-14.8 4.6-20.5 4.6-79.4-4.6-210-13.6-357.6-30.7-240.7-27.2-454.1-69.2-454.1-69.2l403-383.8c0 0 190.7 23.8 478 14.7 162.3-5.6 323.5-23.8 432.5-38.6 31.8-3.4-311.1 388.4-367.8 442.9z"
            />
            <path
              id="Layer"
              className="s4"
              d="m1056.5 1534.7c-52.3 49.9-126.1 53.4-131.7 53.4 107.8-19.4 77.2-467.9 77.2-467.9 162.3-5.6 323.5-23.8 432.5-38.6 31.8-3.4-321.3 398.6-378 453.1z"
            />
            <path
              id="Layer"
              className="s5"
              d="m948.6 1590.3c-7.9 0-15.9-1.1-25-1.1 20.5-3.4 34.1-10.2 55.7-26.1 43.1-30.7 56.7-84.1 61.3-105.6 19.3-107.9 45.4-268 57.9-430.4 22.7-286.1 9-476.9 9-476.9l403.1-383.7c0 0 29.5 213.4 46.5 455.3 10.2 157.8 13.6 297.5 14.8 374.7 0 5.7 4.5 61.3-47.7 111.3-56.8 54.5-345.1 329.2-459.8 439.4-54.5 49.9-110.1 43.1-115.8 43.1z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
