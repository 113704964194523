import {IToggle} from "@unleash/proxy-client-react";
import {AppFlags} from "types/featureToggle";
import {isDefined} from "utils/typeCheck";


export const isSelfServiceEnabled = (appFlags: IToggle[]) => {
    const selfServiceFlag = appFlags.filter((value) => value.name === AppFlags.SELF_SERVICE)
    if (!isDefined(selfServiceFlag[0])) {
        return true
    }
    return !selfServiceFlag[0].enabled
}