import { AppReturnButton } from "_sredx/Layout";
import { TeamDetailsOverviewContainer } from "_sredx/containers";
import { Button, Card, PageContent, TabItem, Tabs } from "_sredx/_ui";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import styles from "./TeamDetailPage.module.css";
import { useState } from "react";

export const TeamDetailPage = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  if (error)
    return (
      <PageContent className={styles.error_wrapper}>
        <h1>{error}</h1>
        <Button onClick={() => navigate("/teams")}>Return to teams</Button>
      </PageContent>
    );
  return (
    <PageContent className={styles.wrapper}>
      <AppReturnButton to={"/teams"} label={"Back to teams"} />
      <TeamDetailsOverviewContainer setError={setError} />

      <Card>
        <Tabs>
          <NavLink to="members">
            {({ isActive }) => <TabItem label={"Members"} active={isActive} />}
          </NavLink>
          <NavLink to="services">
            {({ isActive }) => <TabItem label={"Services"} active={isActive} />}
          </NavLink>
        </Tabs>
        <Outlet />
      </Card>
    </PageContent>
  );
};
