import { DownloadIcon } from "@heroicons/react/outline";
import {
  Button,
  ButtonIcon,
  ButtonLabel,
  Card,
  CardBody,
  Heading,
  Text,
} from "_sredx/_ui";
import { ClusterCardBadges } from "_sredx/components/ClusterCard/ClusterCardBadges";
import { ClusterCardProperties } from "_sredx/components/ClusterCard/ClusterCardProperties";
import { ClusterCardStatusIndicator } from "_sredx/components/ClusterCard/ClusterCardStatusIndicator";
import { ClusterCardTools } from "_sredx/components/ClusterCard/ClusterCardTools";
import {
  resolveClusterBadges,
  resolveClusterProperties,
} from "_sredx/components/ClusterCard/helpers";
import { mapClusterPhaseToStatus } from "_sredx/components/ClusterCard/utils";
import { Cluster } from "_sredx/types";
import { MouseEventHandler } from "react";
import styles from "./ClusterCardComponent.module.css";

interface ClusterCardComponentProps {
  cluster: Cluster;
  onDownload?: (clusterName: string) => void;
  isButtonLoading: boolean;
  isDownloadEnabled: boolean;
}

export const ClusterCardComponent = ({
  cluster,
  onDownload,
  isButtonLoading,
  isDownloadEnabled,
}: ClusterCardComponentProps) => {
  const status = mapClusterPhaseToStatus(cluster.phase);
  const clusterProperties = resolveClusterProperties(cluster);
  const clusterBadges = resolveClusterBadges(cluster);

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (onDownload) {
      onDownload(cluster.name);
    }
  };
  return (
    <Card clickable>
      <CardBody>
        <div className={styles.wrapper}>
          <div className={styles.head}>
            <Heading level={3}>{cluster.name}</Heading>
            {isDownloadEnabled && (
              <Button
                variant={"neutral"}
                ghost
                isLoading={isButtonLoading}
                onClick={handleOnClick}
                style={{
                  color: "#0b6cfe",
                }}
              >
                <ButtonIcon>
                  <DownloadIcon />
                </ButtonIcon>
                <ButtonLabel>
                  <Text>KubeConfig</Text>
                </ButtonLabel>
              </Button>
            )}
          </div>
          <ClusterCardStatusIndicator status={status} label={cluster.phase} />
          <ClusterCardProperties properties={clusterProperties} />
          <ClusterCardBadges badges={clusterBadges} />
          <ClusterCardTools tools={cluster.tools} />
        </div>
      </CardBody>
    </Card>
  );
};
