import React, {PropsWithChildren} from 'react';
import style from "./Card.module.css"; 
import clsx from "clsx";

interface CardProps extends PropsWithChildren {
    className? : string;
}

export const Card = ({className , children} : CardProps) => {
  return (
    <div className={clsx(style.wrapper, className)}>{children}</div>
  )
}
