export const isNull = (value: any): boolean => value === null;
export const isUndefined = (value: any): boolean => value === undefined;
export const isNullOrUndefined = (value: any): boolean =>
  isNull(value) || isUndefined(value);

export const isEmpty = (value: any): boolean => {
  if (isNullOrUndefined(value)) return true;
  if (isString(value)) return value.trim().length === 0;
  if (isNumber(value)) return value === 0;
  if (isBoolean(value)) return value === false;
  if (isList(value)) return value.length === 0;
  return false;
};
export const isString = (value: any): boolean => typeof value === "string";
export const isNumber = (value: any): boolean => typeof value === "number";
export const isBoolean = (value: any): boolean => typeof value === "boolean";
export const isList = (value: any): boolean => Array.isArray(value);

export const isEmail = (email?: string) => {
  if (!email) return false;
  const rEmail = new RegExp(
    /^(?!\.)(?!.*\.\.)([A-Z0-9_+-.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i
  );
  return rEmail.test(email);
};
