export interface BirdEyeData {
  services: {
    serviceId: string;
    serviceName: string;
    scorecardsEvals: {
      scorecardId: string;
      scorecardName: string;
      score: number;
      percentage: number;
      totalPossible: number;
    }[];
  }[];
}

export interface BinType {
  bin: number;
  bins: Bin[];
}
export type Bin = {
  bin: number;
  count: number;
  metadata?: any;
};

export const mapDataToBins = (data: BirdEyeData): BinType[] => {
  return data.services.map((service) => {
    return {
      bin: Math.random() * 100,
      bins: service.scorecardsEvals.map((scorecard) => {
        return {
          bin: Math.random() * 100,
          count: scorecard.percentage,
          metadata: {
            scorecardId: scorecard.scorecardId,
            scorecardName: scorecard.scorecardName,
            score: scorecard.score,
            percentage: scorecard.percentage,
            totalPossible: scorecard.totalPossible,
          },
        };
      }),
    };
  });
};

export const extractScorecards = (
  data: BirdEyeData
): { scorecardId: string; scorecardName: string }[] => {
  const scorecards: {
    scorecardId: string;
    scorecardName: string;
  }[] = [];
  data.services.forEach((service) => {
    service.scorecardsEvals.forEach((scorecard) => {
      if (!scorecards.some((s) => s.scorecardId === scorecard.scorecardId)) {
        scorecards.push({
          scorecardId: scorecard.scorecardId,
          scorecardName: scorecard.scorecardName,
        });
      }
    });
  });
  scorecards.reverse();
  return scorecards;
};
