import { ReactNode, useMemo } from "react";
import { scaleBand, scaleTime } from "@visx/scale";
import { Group } from "@visx/group";
import { AxisBottom } from "@visx/axis";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { Circle } from "@visx/shape";
import { ChartProps } from "../types";
import { axisDefaultLabelMapper, DEFAULT_CHART_MARGIN } from "../config";

let tooltipTimeout: number;

const tooltipStyles = {
  ...defaultStyles,
  padding: 0,
  margin: 0,
};
interface RangeHorizontalLineChartProps<T> extends ChartProps {
  data: T[];
  yMapper: (d: T) => string;
  xMapper: (d: T) => [Date, Date];
  xLabelMapper?: (val: any) => string;
  renderTooltip?: (d: T) => ReactNode;
}

const getMinMax = (data: any[], mapper: (d: any) => [Date, Date]) => {
  let min = mapper(data[0])[0];
  let max = mapper(data[0])[1];
  data.forEach((d) => {
    const [start, end] = mapper(d);
    if (start < min) min = start;
    if (end > max) max = end;
  });
  return [min, max];
};

export const RangeHorizontalLineChart = <T,>({
  data,
  width,
  yMapper,
  xMapper,
  bgColor = "transparent",

  xLabelMapper = axisDefaultLabelMapper,
  margin = DEFAULT_CHART_MARGIN,
  renderTooltip,
}: RangeHorizontalLineChartProps<T>) => {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<T>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const xMax = width - margin.left - margin.right;
  const yMax = data.length * 10;
  const height = yMax + margin.top + margin.bottom;

  const xScale = useMemo(() => {
    return scaleTime<number>({
      range: [0, xMax],
      domain: getMinMax(data, xMapper),
      nice: true,
      round: true,
      clamp: true,
    });
  }, [xMax, xMapper, data]);

  const yScale = useMemo(() => {
    return scaleBand({
      range: [0, yMax],
      domain: data.map(yMapper),
      padding: 0.6,
    });
  }, [yMax, data, yMapper]);

  return (
    <div>
      <svg width={width} height={height} ref={containerRef}>
        <rect x={0} y={0} width={width} height={height} fill={bgColor} />
        <Group top={margin.top} left={margin.left}>
          {data.map((d) => {
            const x0 = xScale(xMapper(d)[0]);
            const x1 = xScale(xMapper(d)[1]);
            const y = yScale(yMapper(d));
            const bandHeight = yScale.bandwidth();

            return (
              <Group
                key={y}
                top={y}
                left={x0}
                style={{ cursor: "pointer" }}
                onMouseOver={(event) => {
                  if (!renderTooltip) return;
                  if (tooltipTimeout) clearTimeout(tooltipTimeout);
                  const eventSvgCoords = localPoint(event);
                  showTooltip({
                    tooltipData: d,
                    tooltipTop: eventSvgCoords?.y,
                    tooltipLeft: eventSvgCoords?.x,
                  });
                }}
                onMouseOut={() => {
                  tooltipTimeout = window.setTimeout(() => {
                    hideTooltip();
                  }, 300);
                }}
              >
                {/*<Line*/}
                {/*  x1={0}*/}
                {/*  x2={0}*/}
                {/*  y1={0}*/}
                {/*  y2={bandHeight}*/}
                {/*  stroke={"black"}*/}
                {/*  strokeWidth={2}*/}
                {/*/>*/}
                <line
                  x1={0}
                  x2={x1 - x0}
                  y1={bandHeight / 2}
                  y2={bandHeight / 2}
                  stroke={"#03001C"}
                  strokeWidth={1}
                />
                <Circle cx={0} cy={bandHeight / 2} r={3} fill={"#5FBDFF"} />
                <Circle
                  cx={x1 - x0}
                  cy={bandHeight / 2}
                  r={3}
                  fill={"#5FBDFF"}
                />
                {/*<Line*/}
                {/*  x1={x1 - x0}*/}
                {/*  x2={x1 - x0}*/}
                {/*  y1={0}*/}
                {/*  y2={bandHeight}*/}
                {/*  stroke={"black"}*/}
                {/*  strokeWidth={2}*/}
                {/*/>*/}
              </Group>
            );
          })}
          {/*<AxisLeft*/}
          {/*  hideAxisLine*/}
          {/*  left={0}*/}
          {/*  hideTicks*/}
          {/*  scale={yScale}*/}
          {/*  tickFormat={yLabelMapper}*/}
          {/*  tickLabelProps={{*/}
          {/*    fill: "#03001C",*/}
          {/*    fontSize: 11,*/}
          {/*    textAnchor: "end",*/}
          {/*    dy: "0.33em",*/}
          {/*  }}*/}
          {/*/>*/}
          {xScale && (
            <AxisBottom
              top={yMax}
              scale={xScale}
              stroke={"#03001C"}
              tickStroke={"#03001C"}
              tickFormat={xLabelMapper}
            />
          )}
        </Group>
      </svg>
      {tooltipOpen && tooltipData && renderTooltip && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          {renderTooltip(tooltipData)}
        </TooltipInPortal>
      )}
    </div>
  );
};
