import React, { useEffect, useState } from "react";

import { ScoreCardsGrid } from "_sredx/components";
import { useGetScoreCards } from "_sredx/services";
import { Button, ButtonLabel, Heading } from "_sredx/_ui";

import styles from "./ScoreCardsListingContainer.module.css";
import { useNavigate } from "react-router-dom";

export const ScoreCardsListingContainer = () => {
  const [overview, setOverview] = useState(false);
  // Hooks
  const {
    data: scoreCards,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetScoreCards({
    includeOverview: overview,
  });

  useEffect(() => {
    if (isSuccess && !overview) {
      setOverview(true);
    }
  }, [scoreCards, isLoading]);

  const navigate = useNavigate();

  // Event handlers
  const handleClickAddNewScoreCard = (): void => {
    navigate("/scorecards/create");
  };

  return (
    <div className={styles.wrapper}>
      {/*  Header */}
      <div className={styles.header_wrapper}>
        <div>
          <Heading>Scorecards</Heading>
          <p>Level Up with Scorecards: Quality, Readiness, and Migration</p>
        </div>
        <div>
          <Button onClick={handleClickAddNewScoreCard}>
            <ButtonLabel>New scorecard</ButtonLabel>
          </Button>
        </div>
      </div>

      {/*  ScoreCards  */}
      <ScoreCardsGrid
        scoreCards={scoreCards}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </div>
  );
};
