import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ButtonLabel, Text } from "_sredx/_ui";
import { ConfigParamValues } from "_sredx/types";
import { ConfigParam } from "models/integration/IntegrationTemplate";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormFieldInput } from "../Form";
import styles from "./OnboardingProviderConfigForm.module.css";
import { Schema } from "./utils";
import { IntegrationConfigFormInputs } from "./IntegrationConfigFormInputs";

interface OnboardingProviderConfigFormProps {
  serviceProviderName: string;
  configParams: ConfigParam[];
  isLoadingButton: boolean;
  defaultValues: ConfigParamValues;
  handleProviderConfigSubmit: (formData: ConfigParamValues) => void;
}

export const OnboardingProviderConfigForm = ({
  serviceProviderName,
  configParams,
  isLoadingButton,
  defaultValues,
  handleProviderConfigSubmit,
}: OnboardingProviderConfigFormProps) => {
  const { control, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(Schema(configParams)),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) =>
      setValue(key, defaultValues[key])
    );
  }, [defaultValues]);

  let isSaveAllowed = !formState.isSubmitting && formState.isValid;

  return (
    <>
      <div className={styles.title}>
        Configure your {serviceProviderName} account
      </div>
      <form>
        <IntegrationConfigFormInputs
          control={control}
          configParams={configParams}
          errors={formState.errors}
        />
      </form>
      <div className={styles.actions_wrapper}>
        <Button
          disabled={!isSaveAllowed || isLoadingButton}
          isLoading={isLoadingButton}
          onClick={handleSubmit(handleProviderConfigSubmit)}
        >
          <ButtonLabel>Save</ButtonLabel>
        </Button>
      </div>
    </>
  );
};
