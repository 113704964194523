import { useEffect, useMemo, useState } from "react";
import {InteractionData} from "types/heatmap";
import ServiceScores from "../../../models/leaderboard/ServiceScores";
import styles from "./Renderer.module.css";
import * as d3 from "d3";
import { isHeatMapScore } from "utils/guards";

const MARGIN = { top: 10, right: 70, bottom: 10, left: 150 };
const yLabelsHeight = 100;

type RendererProps = {
  width: number;
  height: number;
  data: ServiceScores[];
  setHeight: (height: number) => void;
  setHoveredCell: (hoveredCell: InteractionData | null) => void;
  colorScale: d3.ScaleLinear<string, string, never>;
};

export const Renderer = ({
  width,
  height,
  data,
  setHeight,
  setHoveredCell,
  colorScale,
}: RendererProps) => {
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  const [scrollPos, setScrollPos] = useState<number>(0);

  const allYGroups = useMemo(
    () => [...data.map((d) => d.service.name)],
    [data]
  );
  const allXGroups = useMemo(
    () => [
      ...data[0].scores.map((score) =>
        isHeatMapScore(score) ? score.scorecardName : ""
      ),
    ],
    [data]
  );

  useEffect(() => {
    if (boundsHeight / allYGroups.length < 14) {
      setHeight(allYGroups.length * 14 + MARGIN.top + MARGIN.bottom + 10);
    }
  }, [allYGroups]);

  const xScale = useMemo(() => {
    return d3
      .scaleBand()
      .range([0, boundsWidth])
      .domain(allXGroups)
      .padding(0.01);
  }, [data, width, allXGroups]);

  const yScale = useMemo(() => {
    return d3
      .scaleBand()
      .range([boundsHeight, 0])
      .domain(allYGroups)
      .padding(0.01);
  }, [data, height, allYGroups]);

  const allShapes = data.map((services, i) =>
    services.scores.map((score, j) => {
      const x = isHeatMapScore(score) ? xScale(score.scorecardName) : 0;
      const y = yScale(services.service.name);
      if (!x || !y) {
        return null;
      }
      return (
        <rect
          key={i + "," + j}
          r={4}
          x={isHeatMapScore(score) ? xScale(score.scorecardName) : 0}
          y={yScale(services.service.name)}
          width={xScale.bandwidth()}
          height={yScale.bandwidth()}
          opacity={1}
          fill={
            score.scorePercentage != null
              ? colorScale(score.scorePercentage)
              : "#E5E7EB"
          }
          rx={5}
          stroke={"white"}
          onMouseEnter={(e) => {
            setHoveredCell({
              xLabel: services.service.name,
              yLabel: isHeatMapScore(score) ? score.scorecardName : "",
              xPos: x + xScale.bandwidth() + MARGIN.left,
              yPos: y + yScale.bandwidth() / 2 + yLabelsHeight,
              value:
                score.scorePercentage != null
                  ? String(Math.round(score.scorePercentage * 100) / 100)
                  : "null",
            });
          }}
          onMouseLeave={() => setHoveredCell(null)}
          cursor="pointer"
        />
      );
    })
  );

  const xLabels = allXGroups.map((name, i) => {
    const x = xScale(name);
    if (!x) {
      return null;
    }
    return (
      <foreignObject
        width={yLabelsHeight}
        x={x - 50 + xScale.bandwidth() / 2}
        y={50}
        height={30}
        className="break-words flex items-center align-top text-xs overflow-visible"
        key={i}
      >
        <div
          style={{
            fontSize: "12px",
            lineHeight: "0.85rem",
          }}
          className={styles.truncatex}
        >
          {name}
        </div>
      </foreignObject>
    );
  });

  const yLabels = allYGroups.map((name, i) => {
    const y = yScale(name);

    return (
      <g key={i}>
        <rect
          x={-135}
          y={y}
          width={128}
          height={`${yScale.bandwidth()}`}
          r={4}
          rx={5}
          stroke="white"
          strokeWidth={2}
          fillOpacity={0.05}
          fill="blue"
        ></rect>

        <foreignObject
          x={-130}
          y={y}
          width="120"
          height={`${yScale.bandwidth()}`}
          className="flex items-center"
        >
          <div
            style={{ fontSize: "12px", lineHeight: "0.85rem" }}
            className="break-words flex items-center  text-xs h-full"
          >
            <div className={styles.truncate}>{name}</div>
          </div>
        </foreignObject>
      </g>
    );
  });

  return (
    <div
      className="max-h-[550px] overflow-y-hidden hover:overflow-y-auto"
      onScroll={(e) => {
        setScrollPos((e.target as HTMLElement).scrollTop);
      }}
    >
      <div
        style={{
          width: `${boundsWidth}px`,
          transform: `translate(${MARGIN.left}px,0)`,
        }}
        className="sticky top-0 bg-white/90 border-b border-slate-200 float:left"
      >
        <svg height={`${yLabelsHeight}`} width={boundsWidth}>
          {xLabels}
        </svg>
      </div>
    
    <svg width={width} height={height}>
      <g
        width={boundsWidth}
        height={boundsHeight}
        transform={`translate(${[MARGIN.left,0].join(",")})`}
      >
        {allShapes}
        {yLabels}
      </g>
    </svg>
    </div>
  );
};
