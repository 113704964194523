import { resolveInitiativeStatus } from "_sredx/utils";
import { InitiativeDto } from "_sredx/types";
import { TableCell, TableRow, Text } from "_sredx/_ui";

import styles from "./InitiativeDataTableRow.module.css";
import { InitiativeProgressBar } from "./InitiativeProgressBar";

interface InitiativeDataTableRowProps {
  initiative: InitiativeDto;
  onInitiativeRowClicked: (initiativeId: string) => void;
  isTeamsSelected?: boolean;
}

export const InitiativeDataTableRow = ({
  initiative,
  onInitiativeRowClicked,
  isTeamsSelected = false,
}: InitiativeDataTableRowProps) => {
  const { name, startDate, endDate, status, ruleIds, serviceIds } = initiative;

  // Derived variables
  const rulesCount = ruleIds ? ruleIds.length : 0;
  const servicesCount = serviceIds ? serviceIds.length : 0;
  const formatedStartDate = startDate.toString().replaceAll("-", "/");
  const formatedEndDate = endDate.toString().replaceAll("-", "/");
  const teamsServiceCount = initiative.serviceEvaluationResults.length;

  const handleInitiativeRowClicked = () => {
    onInitiativeRowClicked(initiative.id);
  };

  return (
    <TableRow
      className={styles.initiative_row}
      onClick={handleInitiativeRowClicked}
    >
      <TableCell>
        <div className={styles.col1_wrapper}>
          <Text>{name}</Text>
        </div>
      </TableCell>
      <TableCell>
        <Text>{resolveInitiativeStatus(status)}</Text>
      </TableCell>
      {isTeamsSelected && (
        <TableCell>
          <InitiativeProgressBar progress={initiative.progress} />
        </TableCell>
      )}
      <TableCell>
        <InitiativeProgressBar progress={initiative.globalProgress} />
      </TableCell>

      {isTeamsSelected && <TableCell>{teamsServiceCount}</TableCell>}
      <TableCell>
        <Text>{servicesCount}</Text>
      </TableCell>
      <TableCell>
        <Text>{rulesCount}</Text>
      </TableCell>
      <TableCell>{formatedStartDate}</TableCell>
      <TableCell>{formatedEndDate}</TableCell>
    </TableRow>
  );
};
