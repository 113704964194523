import { ServiceProviderKeyType } from "containers/ServiceAddContainer";
import { ServiceProvider } from "types/discovery";
import { ExternalServiceProviderCard } from "./ExternalServiceProviderCard/ExternalServiceProviderCard";

interface ExternalServiceListProps {
  selectedServiceProvider?: ServiceProviderKeyType;
  serviceProviders: ServiceProvider[];
  handleSelectServiceProvider: (serviceProvider: ServiceProvider) => void;
}

export const ExternalServicesList = ({
  selectedServiceProvider,
  serviceProviders,
  handleSelectServiceProvider,
}: ExternalServiceListProps) => {
  return (
    <>
      <div className="mb-4">
        <h1 className="text-2xl mb-0">Service Discovery</h1>
        <p className="text-sm text-gray-500">
          Import services from external developer platfroms
        </p>
      </div>
      <div className="grid grid-cols-3 my-2 gap-2">
        {serviceProviders
          .sort((extService) => {
            return extService.configured ? -1 : 1;
          })
          .map((serviceProvider: ServiceProvider) => (
            <ExternalServiceProviderCard
              onClick={() => handleSelectServiceProvider(serviceProvider)}
              serviceProvider={serviceProvider}
              selectedServiceProvider={selectedServiceProvider}
            />
          ))}
      </div>
    </>
  );
};
