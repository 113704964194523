import { Heading, PageContent, Text } from "_sredx/_ui";
import styles from "./LeaderboardPage.module.css";
import RankedServiceListContainer from "containers/RankedServiceListContainer/RankedServiceListContainer";
import EvaluateAllContainer from "containers/EvaluateAllcontainer/EvaluateAllContainer";

export const LeaderboardPage = () => {
  return (
    <>
      <PageContent align={"center"}>
        <div className={styles.header}>
          <div className={styles.title}>
            <Heading>Leaderboard</Heading>
            <Text size={"lg"}>A straightforward look at service rankings.</Text>
          </div>

          <EvaluateAllContainer />
        </div>
        <RankedServiceListContainer />
      </PageContent>
    </>
  );
};
