import clsx from "clsx";
import styles from "./Button.module.css";
import { PropsWithChildren } from "react";

export const ButtonIcon = ({ children }: PropsWithChildren) => {
  return (
    <div className={clsx("nemo-button-icon", styles.icon_wrapper)}>
      {children}
    </div>
  );
};
