import styles from "./RulesAssignementContainer.module.css";
import {
  Button,
  ButtonLabel,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Input,
} from "_sredx/_ui";
import { RuleDto } from "_sredx/types";
import React, { useState } from "react";
import { useGetRules } from "_sredx/services";
import { RulesAssignmentTable } from "_sredx/components";
import { useDebounce } from "_sredx/hooks";

interface RulesAssignmentContainerProps {
  onCancel: () => void;
  onConfirm: (rules: RuleDto[]) => void;
  selectedRules?: RuleDto[];
}

export const RulesAssignmentContainer = ({
  onCancel,
  onConfirm,
  selectedRules = [],
}: RulesAssignmentContainerProps) => {
  // State
  const [query, setQuery] = useState<string>("");
  const [checkedRules, setCheckedRules] = useState<RuleDto[]>(selectedRules);
  const [currentPage, setCurrentPage] = useState<number>(0);

  // hooks
  const debouncedQuery = useDebounce(query, 400);

  // Data fetching
  const {
    data: rulesData,
    isLoading: isRulesLoading,
    isFetching: isRulesFetching,
  } = useGetRules({
    params: {
      ruleName: debouncedQuery,
      pageNumber: currentPage,
    },
  });

  // Derived Variables
  const rules = (rulesData && rulesData.content) || [];

  // Event handlers
  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
  };

  const handleCheckAll = (checkAll: boolean) => {
    if (checkAll && rulesData?.pageNo == currentPage) {
      setCheckedRules((prev) => [...prev, ...rules]);
    } else if (!checkAll && rulesData?.pageNo == currentPage) {
      setCheckedRules((prev) =>
        prev.filter((service) => !rules.find((s) => s.id == service.id))
      );
    }
  };

  const handleOnCheckChange = (checked: boolean, ruleId: string) => {
    if (checked) {
      const rule = rules.find((r) => r.id === ruleId);
      if (rule) {
        setCheckedRules([...checkedRules, rule]);
      }
    } else {
      setCheckedRules(checkedRules.filter((r) => r.id !== ruleId));
    }
  };

  const handleOnConfirm = () => {
    onConfirm(checkedRules);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Add rules</CardTitle>
        <CardSubtitle>search and add rules</CardSubtitle>
      </CardHeader>
      <CardBody noPadding>
        <div className={styles.search_wrapper}>
          <Input
            placeholder={"Search rules"}
            value={query}
            onChange={handleOnSearch}
            fullWidth
          />
        </div>
        <div className={styles.table_wrapper}>
          <RulesAssignmentTable
            rules={rules}
            checkedRules={checkedRules}
            onCheckChange={handleOnCheckChange}
            isLoading={isRulesLoading}
            isFetching={isRulesFetching}
            currentPage={currentPage}
            totalPages={rulesData && rulesData.totalPages}
            onPageChange={setCurrentPage}
            totalRules={rulesData && rulesData.totalElements}
            onCheckAll={handleCheckAll}
          />
        </div>
        <div className={styles.button_wrapper}>
          <Button onClick={onCancel} variant={"neutral"} ghost>
            <ButtonLabel>Cancel</ButtonLabel>
          </Button>
          <Button onClick={handleOnConfirm}>
            <ButtonLabel>Confirm</ButtonLabel>
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};
