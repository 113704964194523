import { IToggle } from "@unleash/proxy-client-react";

export interface Flippable {
  isFeatureEnabled?: (appFlags: IToggle[]) => boolean;
}

export const AppFlags = {
  SELF_SERVICE: "self-service",
  SERVICE_DETAILS: "service-details-neo",
};

export type AppFlagsType = (typeof AppFlags)[keyof typeof AppFlags];
