import clsx from "clsx";
import styles from "./ScoreCardsGrid.module.css";
import { ScoreCardDto } from "_sredx/types";
import { generatePath, Link } from "react-router-dom";
import { ScoreCardComponent } from "../ScoreCard";
import React from "react";
import { Card, Loader, Text } from "_sredx/_ui";

interface ScoreCardsGridProps {
  isLoading?: boolean;
  isFetching?: boolean;
  scoreCards?: ScoreCardDto[];
}

export const ScoreCardsGrid = ({
  isLoading,
  scoreCards,
  isFetching,
}: ScoreCardsGridProps) => {
  // Event handlers
  const getPath = (scorecardId: string) => {
    return generatePath("/scorecards/:id", { id: scorecardId });
  };

  if (isLoading) {
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading Scorecards...</Text>
        </div>
      </Card>
    );
  }

  if (!scoreCards || scoreCards.length == 0)
    return <div>No scorecards found</div>;

  return (
    <div className={clsx(styles.wrapper)}>
      {scoreCards.map((scoreCard) => (
        <Link key={scoreCard.id} to={getPath(scoreCard.id)}>
          <ScoreCardComponent scoreCard={scoreCard} isFetching={isFetching} />
        </Link>
      ))}
    </div>
  );
};
