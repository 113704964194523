import { useParams } from "react-router-dom";
import {
  useGetInitiative,
  useGetInitiativeRules,
  useGetInitiativeServices,
} from "_sredx/services";
import { InitiativeDetailsRules } from "_sredx/components";
import { mapInitiativeRulesWithEvaluation } from "./utils";
import { RulesAssignmentContainer } from "../RulesAssignmentContainer";
import { useToaster } from "hooks/useToaster";
import { useModal } from "_sredx/hooks";
import { RuleDto } from "_sredx/types";

import styles from "./InitiativeDetailsRulesContainer.module.css";
import { useUpdateInitiative } from "_sredx/services";

export const InitiativeDetailsRulesContainer = () => {
  // State
  const { initiativeId = "" } = useParams<{ initiativeId: string }>();

  // Hooks
  const { addToast } = useToaster();
  const { openModal, closeModal } = useModal();

  const { mutate: updateInitiative } = useUpdateInitiative();

  const { data: initiativeRulesData, isLoading: isLoadingRules } =
    useGetInitiativeRules({
      id: initiativeId,
    });

  const { data: initiativeServicesData, isLoading: isLoadingServices } =
    useGetInitiativeServices({
      id: initiativeId,
    });

  const { data: initiative, isLoading: isLoadingInitiative } = useGetInitiative(
    {
      id: initiativeId,
    }
  );

  // Derived variables
  const isLoading = isLoadingInitiative || isLoadingRules || isLoadingServices;

  const initiativeEvaluatedRules = mapInitiativeRulesWithEvaluation(
    initiative,
    initiativeRulesData,
    initiativeServicesData
  );

  // Events handlers
  const handleOnUpdateRules = () => {
    openModal({
      content: (
        <div className={styles.modal_rules_wrapper}>
          <RulesAssignmentContainer
            onCancel={closeModal}
            onConfirm={handleOnSubmitUpdateRules}
            selectedRules={initiativeRulesData}
          />
        </div>
      ),
    });
  };

  const handleOnSubmitUpdateRules = (rules: RuleDto[]) => {
    closeModal();
    updateInitiative(
      {
        id: initiativeId ?? "",
        ruleIds: rules.map((r) => r.id),
      },
      {
        onError: (error) => {
          addToast({
            type: "error",
            message: error.message,
          });
        },
      }
    );
  };

  return (
    <InitiativeDetailsRules
      isLoading={isLoading}
      initiativeRules={initiativeEvaluatedRules}
      onUpdateRules={handleOnUpdateRules}
    />
  );
};
