import { forwardRef } from "react";
import { IconProps } from "./types";

export const PagerDutyIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>PagerDuty</title>
        <path
          fill="currentColor"
          d="M16.803 2.082C14.984 1.15 13.71 1 10.726 1H4v13.338h6.7c2.653 0 4.643-.15 6.394-1.256C19.004 11.887 20 9.888 20 7.575c0-2.49-1.22-4.487-3.197-5.493Zm-5.32 9.465H7.41V3.854l3.847-.025c3.502-.026 5.253 1.131 5.253 3.784 0 2.853-2.175 3.934-5.028 3.934ZM4 17.142h3.41V23H4v-5.858Z"
        />
      </svg>
    );
  }
);
