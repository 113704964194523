import ServiceOwnersComponent from "../../service/owners/ServiceOwnersComponent";
import Service from "../../../models/service/Service";
import RankingDisplay from "../RankingDisplay/RankingDisplay";

interface ServiceRank {
  service: Service;
  points: number;
  percentage: number;
}

interface LeaderServiceCardProps {
  serviceRank: ServiceRank;
  index: number;
}

const LeaderServiceCard: React.FC<LeaderServiceCardProps> = ({
  serviceRank,
  index,
}) => {
  const position = index + 1;

  return (
  <div
    key={serviceRank.service.id}
    className="grid grid-cols-8 bg-white shadow-sm rounded-md overflow-hidden mb-4"
  >
  <div className="col-span-1 p-4">
    <RankingDisplay position={position} />
  </div>
  <div className="col-span-4 p-4">
    <h3 className="text-lg sm:text-xl font-semibold text-gray-800">
      {serviceRank.service.name}
    </h3>
    <div className="text-xs sm:text-sm text-gray-600">
      <ServiceOwnersComponent
        owners={serviceRank.service.owners}
        limit={1}
      />
    </div>
  </div>
  <div className="col-span-3 flex flex-col lg:flex-row items-center justify-end text-center gap-1 p-4 mt-2">
    <span className="inline-block flex-shrink-0 bg-blue-200 text-blue-700 rounded px-2 py-1 text-xs sm:text-sm font-medium">
      {serviceRank.points} Pts
    </span>
    <span
      className={`inline-block rounded flex gap-1 px-2 py-1 text-center text-xs sm:text-sm font-medium flex-shrink-0 ${
        serviceRank.percentage < 50
          ? "bg-red-200 text-red-700"
          : "bg-green-200 text-green-700"
      }`}
    >
      {serviceRank.percentage}%
    </span>
  </div>
</div>

  );
};

export default LeaderServiceCard;
