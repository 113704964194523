import { LabelHTMLAttributes, PropsWithChildren } from "react";
import styles from "./Label.module.css";
import clsx from "clsx";

export const Label = ({
  children,
  ...props
}: PropsWithChildren<LabelHTMLAttributes<HTMLLabelElement>>) => {
  return (
    <label className={clsx("nemo-label", styles.label)} {...props}>
      {children}
    </label>
  );
};
