import React from "react";

export const CodeCov = () => {
  return (
    <svg
      viewBox="0 0 256 281"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M218.6 37.4A127 127 0 0 0 128 0C57.5 0 .1 57.5.1 128.1L0 149.8l16.9-11.5a68 68 0 0 1 84 6c2.2-4.7 4.6-9.3 7.6-13.6a88 88 0 0 1 3.8-5.1 87.5 87.5 0 0 0-41-19.3 90.3 90.3 0 0 0-16-1.4 88.4 88.4 0 0 0-32.3 6 106.8 106.8 0 0 1 105.2-89.4c28.4 0 55 11 75.1 31.1 16 16 26.3 36 29.8 58.2a89.6 89.6 0 0 0-32.4-6.2h-1.5a91 91 0 0 0-3.4.1l-5.9.5-1.7.2-4 .7c-.7 0-1.3.2-1.9.3a90.5 90.5 0 0 0-4.1 1l-1.5.3a90.2 90.2 0 0 0-5 1.6h-.4a87.5 87.5 0 0 0-11 4.7l-.3.2a86.7 86.7 0 0 0-5 2.7l-.3.2a86 86 0 0 0-22.3 19.3l-.8 1a88.5 88.5 0 0 0-4 5.3 91.4 91.4 0 0 0-3.7 5.9 90.2 90.2 0 0 0-2.5 4.6v.3a88.8 88.8 0 0 0-4.7 11.3 88 88 0 0 0-4.5 29.3v3.5a98.7 98.7 0 0 0 .4 3.8l.3 2.5c0 .5 0 1 .2 1.5a90.1 90.1 0 0 0 .6 4c4.3 23 17.3 43 36.4 56.3l2.4 1.6a88.6 88.6 0 0 0 36.3 13.4l1.7.2 13-20.8-4.6-.1a67 67 0 0 1-2.2-133.7l2.2-.2a68.6 68.6 0 0 1 40.3 12l16.8 11.5V128a127 127 0 0 0-37.4-90.6"
        fill="#E0225C"
      />
    </svg>
  );
};
