import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { TeamInfoDto } from "../types";
import { useMe } from "../services";

interface TeamContextType {
  teams: TeamInfoDto[];
  selectedTeam: TeamInfoDto | null;
  setSelectedTeam: (teamId: TeamInfoDto | null) => void;
  isLoading: boolean;
  isSuccess: boolean;
}

const TEAM_CONTEXT_DEFAULT_VALUE: TeamContextType = {
  teams: [],
  selectedTeam: null,
  setSelectedTeam: () => {},
  isLoading: true,
  isSuccess: false,
};

const TeamContext = createContext<TeamContextType>(TEAM_CONTEXT_DEFAULT_VALUE);

export const TeamProvider = ({ children }: PropsWithChildren) => {
  // State
  const [selectedTeam, setSelectedTeam] = useState<TeamInfoDto | null>(null);

  // Services
  const { data: currentUser, isLoading, isSuccess } = useMe();

  // Derived variables
  const { teams = [] } = currentUser ?? {};

  // Side effects
  useEffect(() => {
    if (!selectedTeam && teams.length) setSelectedTeam(teams[0]);
  }, [teams, selectedTeam]);

  return (
    <TeamContext.Provider
      value={{ teams, selectedTeam, setSelectedTeam, isLoading, isSuccess }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeamContext = () => useContext(TeamContext);
