import React from "react";
import { ScoreCardEvaluation } from "../../../models/evaluations/ScoreCardEvaluation";
import { generateFailingRuleRankList } from "../../../utils/extractRuleResults";
import DataTable from "./DataTable";
import NoDataMessage from "./NoDataMessage";


type RulesProps = {
  scorecardEvaluationData: ScoreCardEvaluation[];
};

const RulesAtRiskDashboard: React.FC<RulesProps> = ({
  scorecardEvaluationData,
}) => {
  const rulesData = generateFailingRuleRankList(scorecardEvaluationData).sort(
    (a, b) => b.percentage - a.percentage
  ).slice(0,6);
 

  const headerColumns = ["Rule", "Failing percentage"];
  const dataRows = rulesData.map((data) => [
    data.ruleName,
    <span
      className="inline-block rounded-full px-4 text-sm font-semibold bg-red-200 text-red-700"
      key={data.ruleName}
    >
      {data.percentage}%
    </span>,
  ]);

  return (
    <div data-testid="rules-at-risk-dashboard" className="flex flex-col">
      {rulesData.length > 0 ? (
        <DataTable   headerColumns={headerColumns} dataRows={dataRows} />
      ) : (
        <div className="flex justify-center items-center mt-20">
        <NoDataMessage />
      </div>
       )}
    </div>
  );
};

export default RulesAtRiskDashboard;
