import { PropsWithChildren } from "react";
import clsx from "clsx";
import { Heading } from "_sredx/_ui";
import styles from "./ListTitle.module.css";

export const ListTitle = ({ children }: PropsWithChildren) => {
  return (
    <li className={clsx("nemo-list-title", styles.wrapper)}>
      <Heading level={4}>{children}</Heading>
    </li>
  );
};
