import { useQuery } from "react-query";
import { useAxios } from "hooks/useAxios";
import { SELF_SERVICE_PATHS, SELF_SERVICE_QUERY_KEYS } from "./paths";


export const useCreateClusterConfig = () => {
  const { axios } = useAxios();
  const fetchOptions = async () => {
    const response = await axios.get(
      SELF_SERVICE_PATHS.CLUSTER_AVAILABLE_OPTIONS()
    );
    return response.data;
  };

  return useQuery(
    [SELF_SERVICE_QUERY_KEYS.CLUSTER_AVAILABLE_OPTIONS],
    fetchOptions);
};
