import Service, { ServiceInput, ServiceSummary } from "../models/service/Service";
import {AxiosInstance} from "axios";

export const getAllServices = async (axios: AxiosInstance): Promise<Service[]> => {
    const response = await axios.get<Service[]>("/services");
    return response.data;
};

export const getServicesSummaries = async (axios: AxiosInstance): Promise<ServiceSummary[]> => {
    const response = await axios.get<ServiceSummary[]>("/services/summary");
    return response.data;
};

export const getServiceById = async (axios: AxiosInstance, id: string): Promise<Service | undefined> => {
    const response = await axios.get<Service>(`/services/${id}`);
    return response.data;
};

export const deleteServiceById = async (axios: AxiosInstance, id: string): Promise<void> => {
    await axios.delete(`/services/${id}`);
};

export const updateService = async (
    axios: AxiosInstance,
    updatedData: Partial<ServiceInput>
  ): Promise<Service> => {
    const response = await axios.put<Service>(`/services`, updatedData);
    return response.data;
};
  