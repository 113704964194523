import { useCallback } from "react";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CardBody, CardHeader, CardTitle } from "_sredx/_ui";
import { FormFieldInput, FormFieldTextArea } from "_sredx/components/Form";
import { schema } from "./utils";
import { TeamInfoFormData } from "./types";
import styles from "./TeamInfoForm.module.css";

interface TeamInfoFormProps {
  defaultValues?: TeamInfoFormData;
  onChange: (data: TeamInfoFormData) => void;
}

export const TeamInfoForm = ({
  defaultValues,
  onChange,
}: TeamInfoFormProps) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm<TeamInfoFormData>({
    mode: "onChange",
    resolver: yupResolver(schema) as Resolver<TeamInfoFormData>,
    defaultValues,
  });

  const handleOnChange = useCallback(() => {
    onChange(getValues());
  }, [getValues, onChange]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>general information</CardTitle>
      </CardHeader>

      <CardBody>
        <div className={styles.body}>
          <form onChange={handleOnChange}>
            <FormFieldInput
              name={"name"}
              control={control}
              label={"name"}
              placeholder={"team name"}
              errorMessage={errors.name && errors.name.message}
              fullWidth
            />
            <FormFieldTextArea
              name={"description"}
              control={control}
              label={"description"}
              placeholder={"what is the team all about"}
              errorMessage={errors.description && errors.description.message}
              fullWidth
              rows={3}
            />
          </form>
        </div>
      </CardBody>
    </Card>
  );
};
