import styles from "./clusterDetails.module.css";
import { Cluster } from "types/selfService";
import { LinkIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import clsx from "clsx";
import ClusterDetailsHeaderBar from "./ClusterDetailsHeaderBar";
import { isKubeConfigDownloadEnabled, mapClusterPhaseToStatus, resolveLogoText } from "../ClusterCard/utils";
import StatusIndicator from "components/octo-ui/StatusIndicator/StatusIndicator";
  

export interface ClusterDetailsInfoProps {
    cluster: Cluster;
    onDownload?: () => void;
    isDownloading?: boolean;
}


const ClusterDetailsInfo = ({ cluster, onDownload = () => {}, isDownloading = false }: ClusterDetailsInfoProps) => {
  
  const status = mapClusterPhaseToStatus(cluster.phase)
  const isKubeDownloadReady = isKubeConfigDownloadEnabled(cluster)
  
  return (
    <div className={styles.wrapper} >
      <ClusterDetailsHeaderBar
        isKubeDownloadReady={isKubeDownloadReady}
        isDownloading={isDownloading} 
        heading={cluster.name} 
        onDownload={onDownload} />
      <div className={styles.innerWrapper}>
          <div role="heading" aria-level={3} className={styles.innerHeading}>
              Cluster Details
          </div>
          <div className={styles.body} >
              <div className={styles.keyValueWrapper}>
                  <div className={styles.key}>Cluster name</div>
                  <div className={styles.value}>{cluster.name}</div>
              </div>
              <div className={styles.keyValueWrapper}>
                  <div className={styles.key}>Status</div>
                  <div className={styles.value}>
                    <StatusIndicator label={cluster.phase} status={status} />
                  </div>
              </div>

              <div className={styles.keyValueWrapper}>
                  <div className={styles.key}>Age</div>
                  <div className={styles.value}>{cluster.age}</div>
              </div>

              <div className={styles.keyValueWrapper}>
                  <div className={styles.key}>Cloud provider</div>
                  <div className={styles.value}>{cluster.cloudProvider}</div>
              </div>

              <div className={styles.keyValueWrapper}>
                  <div className={styles.key}>Account</div>
                  <div className={styles.value}>{cluster.accountName}</div>
              </div>

              <div className={styles.key_value_wrapper}>
                <div className={styles.key}>Gitops</div>
                  <Link to={cluster.repoUrl} target="_blank" className={clsx(styles.value, styles.link)}>
                        {cluster.repoName}
                        <LinkIcon className="h-4 w-4" />
                  </Link>
              </div>
              
              <div className={styles.keyValueWrapper}>
                  <div className={styles.key}>Gitops tool</div>
                  <div className={styles.value}>{resolveLogoText(cluster.gitOpsTool)}</div>
              </div>

              <div className={styles.keyValueWrapper}>
                <div className={styles.key}>Kubernetes version</div>
                <div className={styles.value}>{cluster.version}</div>
              </div>

              <div className={styles.keyValueWrapper}>
                  <div className={styles.key}>Pool size</div>
                  <div className={styles.value}>{cluster.size}</div>
              </div>

              <div className={styles.keyValueWrapper}>
                  <div className={styles.key}>CNI type</div>
                  <div className={styles.value}>{cluster.cni}</div>
              </div>

              <div className={styles.keyValueWrapper}>
                  <div className={styles.key}>Node type</div>
                  <div className={styles.value}>{cluster.nodeType}</div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default ClusterDetailsInfo;
