import {forwardRef} from "react";
import {IconProps} from "./types";

export const PersonIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
          <path fill={color} fillRule="evenodd" d="M8.5 7.15a3.15 3.15 0 1 1 6.3 0 3.15 3.15 0 0 1-6.3 0Zm3.15-1.85a1.85 1.85 0 1 0 0 3.7 1.85 1.85 0 0 0 0-3.7Zm0 8a5.35 5.35 0 0 0-5.35 5.35.65.65 0 1 1-1.3 0 6.65 6.65 0 1 1 13.3 0 .65.65 0 1 1-1.3 0 5.35 5.35 0 0 0-5.35-5.35Z" clipRule="evenodd"/>
      </svg>
    );
  }
);
