import clsx from "clsx";
import React from "react";
import styles from "./StatusBarLoading.module.css";

export const StatusBarLoading = () => {
  return (
    <div className={clsx("nemo-status-bar", styles.wrapper)}>
      <div className={clsx("animate-pulse", styles.box)} ></div>
    </div>
  );
};
