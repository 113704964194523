import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import { KubeConfigResponseDto } from "_sredx/types";
import { useQuery } from "react-query";

interface UseGetKubeConfigParams {
  clusterName: string | null;
}

export const useGetKubeConfig = ({ clusterName }: UseGetKubeConfigParams) => {
  return useQuery<KubeConfigResponseDto>({
    queryKey: [QUERY_KEYS.kubConfig, clusterName],
    queryFn: async () =>
      await ClientApi.get(API_ENDPOINTS.getKubeConfig(clusterName!)),
    enabled: !!clusterName,
    retry: false,
  });
};
