import React, { FC, useEffect, useState } from "react";
import Service from "../../../../models/service/Service";
import ServiceOverviewPerScoreCardComponent from "./ServiceOverviewPerScoreCard/ServiceOverviewPerScoreCardComponent";
import ServiceScoreProgressPerScoreCardComponent from "./ServiceOverviewPerScoreCard/ServiceScoreProgressPerScoreCardComponent";
import ServiceRulesListPerScoreCardComponent from "./ServiceOverviewPerScoreCard/ServiceRulesListPerScoreCard";
import ServiceScoreCardStats from "../../../../models/evaluations/ServiceStatsByScoreCard";
import { getServiceStatsFromScoreCardListOfLastEvaluations } from "services/evaluations/ScoreCardEvaluations.helper";
import { ScoreCardEvaluation } from "models/evaluations/ScoreCardEvaluation";
import { useServices } from "hooks/useServices";
import ServiceLevelsPerScoreCardComponent from "./ServiceOverviewPerScoreCard/ServiceLevelsPerScorecardComponent";
import { useSingleScoreCard } from "hooks/scorcards/useSingleScoreCard";
import { TextInput } from "components/octo-ui/Forms/TextInput";
import { SearchIcon } from "@heroicons/react/outline";
import { Loader, Text } from "_sredx/_ui";
import styles from "./ScoreCardServicesListComponent.module.css";

interface ScoreCardServicesListComponentProps {
  scoreCardId: string;
  scoreCardLastEvaluations: ScoreCardEvaluation[];
}

const TABS = {
  OVERVIEW: "Overview",
  RULES: "Rules",
  LEVELS: "Levels",
  SCORE: "Score",
};

const ScoreCardServicesListComponent: FC<
  ScoreCardServicesListComponentProps
> = ({ scoreCardId, scoreCardLastEvaluations }) => {
  const evaluatedServicesIdsList: string[] = scoreCardLastEvaluations.map(
    (e) => e.serviceId
  );
  const {
    data: servicesData,
    isSuccess: isServicesDataLoaded,
    isFetching: isFetchingServicesData
  } = useServices();
  const {
    data: currentScoreCard,
    isSuccess: isScoreCardDataLoaded,
    isFetching: isFetchingScoreCardData
  } = useSingleScoreCard(scoreCardId);

  const [shownServicesData, setShownServicesData] = useState<
    Service[] | undefined
  >(servicesData?.filter((s) => evaluatedServicesIdsList.includes(s.id)) ?? []);
  const [selectedService, setSelectedService] = useState<Service | undefined>();
  const [selectServiceStats, setSelectedServiceStats] = useState<
    ServiceScoreCardStats | undefined
  >();

  const [activeTab, setActiveTab] = useState(TABS.OVERVIEW);

  useEffect(() => {
    if (selectedService) {
      setSelectedServiceStats(
        getServiceStatsFromScoreCardListOfLastEvaluations(
          selectedService.id,
          scoreCardLastEvaluations
        )
      );
    }
  }, [selectedService, scoreCardLastEvaluations]);

  useEffect(() => {
    setShownServicesData(
      servicesData?.filter((s) => evaluatedServicesIdsList.includes(s.id)) ?? []
    );
  }, [servicesData]);

  const handleSearchServices = (value: string) => {
    setShownServicesData(
      servicesData?.filter(
        (s) =>
          evaluatedServicesIdsList.includes(s.id) &&
          s.name.toLowerCase().includes(value.toLowerCase())
      ) ?? []
    );
  };

  useEffect(() => {
    setSelectedService(shownServicesData?.at(0));
  }, [shownServicesData]);

  const resolveTabContent = () => {
    switch (activeTab) {
      case TABS.OVERVIEW:
        if (selectServiceStats)
          return (
            <ServiceOverviewPerScoreCardComponent
              overViewData={selectServiceStats.overview}
            />
          );
        return <></>;

      case TABS.LEVELS:
        if (currentScoreCard && selectServiceStats)
          return (
            <ServiceLevelsPerScoreCardComponent
              rules={selectServiceStats.rulesStatus}
              levels={currentScoreCard.levels}
            />
          );
        return <></>;

      case TABS.RULES:
        if (selectServiceStats)
          return (
            <ServiceRulesListPerScoreCardComponent
              rulesStatusByCategory={selectServiceStats.rulesStatus}
            />
          );
        return <></>;

      case TABS.SCORE:
        if (selectedService)
          return (
            <ServiceScoreProgressPerScoreCardComponent
              scoreCardId={scoreCardId}
              serviceId={selectedService.id}
            />
          );
        return <></>;

      default:
        return <></>;
    }
  };

  if(isFetchingServicesData || isFetchingScoreCardData ){
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>Loading results...</Text>
      </div>
    );
  }

  return (
    <div
      className="h-full w-full"
      id="scorecard-overview-container"
      //   className="mx-auto sm:px-6 lg:px-8"
    >
      {evaluatedServicesIdsList.length === 0 && (
        <div className={"text-center"}>
          No services were evaluated by this scorecard.
        </div>
      )}
      {isServicesDataLoaded &&
        isScoreCardDataLoaded &&
        evaluatedServicesIdsList.length > 0 && (
          <div className="lg:grid lg:grid-cols-4 h-full min-h-0">
            <div className="flex h-full justify-center lg:justify-start lg:flex-col border-r border-t border-black">
              <div className="p-4">
                <TextInput
                  endIcon={<SearchIcon className="h-5 text-gray-400" />}
                  placeholder="Service"
                  onChange={(e: any) => handleSearchServices(e.target.value)}
                />
              </div>
              <div>
                {shownServicesData?.map((item, index) => (
                  <div
                    key={item.id}
                    id={`services-list-item-${index}`}
                    onClick={() => {
                      setSelectedService(item);
                    }}
                    className={`flex-shrink ${
                      item.id === selectedService?.id
                        ? "bg-black text-white"
                        : "text-gray-600 hover:bg-cyan-50 hover:text-gray-900"
                    }
                    'group flex items-center px-3 py-2 text-sm font-medium border-b border-black cursor-pointer last:mb-[-1px] first:border-t`}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-3 border-t border-black flex flex-col">
              <div className="flex gap-6 py-6 px-8">
                {Object.values(TABS).map((tab, i) => (
                  <button
                    key={i}
                    className={`px-4 py-2 ${
                      activeTab === tab
                        ? "border-b-2 border-black font-semibold"
                        : ""
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </button>
                ))}
              </div>
              <div className={"flex-1 overflow-y-auto"}>
                {resolveTabContent()}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default React.memo(ScoreCardServicesListComponent);
