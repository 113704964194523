import React, {PropsWithChildren} from 'react';
import style from "./ConfigParamsWrapper.module.css";
import clsx from 'clsx';


export const ConfigParamsWrapperSkeleton = ({children} : PropsWithChildren) => {
  return (
    <div className={style.wrapper}>
    <div className={clsx(style.title,style.skeleton)}></div>
    <div className={clsx(style.description, style.skeleton)}></div>

    <div className={style.content_wrapper}>
    <div className={clsx(style.description, style.skeleton)}></div>
    </div>

    <hr className={style.divide} />
    {children}
  </div>
);
}
