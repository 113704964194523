interface Props extends React.PropsWithChildren {
  hidden: boolean;
  className?: string;
  onClose: () => void;
  scrollContainer: React.RefObject<HTMLDivElement>;
  title: string;
}

const SidebarComponent: React.FC<Props> = ({
  hidden,
  className,
  onClose,
  children,
  scrollContainer,
  title,
}) => {


  return (
    <aside
      className={`${className} h-screen md:h-[calc(100%-4rem)] w-full md:w-[22rem] lg:w-[28rem] z-20 bg-white shadow-lg fixed right-0 top-0 md:top-16 transition-all duration-300 ${
        hidden ? "transform translate-x-full" : ""
      }`}
    >
      <div className="flex justify-between items-center p-2 py-3 mt-1 shadow">
        <button onClick={onClose} className="flex-shrink-0 bg-gray-200 p-2 rounded">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h1 className="text-3xl font-bold text-gray-700 flex-grow text-center">
          {title}
        </h1>
      <div className="w-6 h-6"></div>
      </div>
      <div
        className="max-h-[calc(100%-4rem)] overflow-y-auto p-1"
        ref={scrollContainer}
      >
        {children}
      </div>
    </aside>
  );
};

export default SidebarComponent;
