import { TableHead, TableHeader } from "_sredx/_ui";

export const TeamDetailsServiceListHeader = () => (
    <TableHeader>
      <TableHead>Service Name</TableHead>
      <TableHead>Tag</TableHead>
      <TableHead>Tags</TableHead>
      <TableHead>Tools</TableHead>
      <TableHead align="right">Actions</TableHead>
    </TableHeader>
  );
  