import {ErrorMessage} from "@hookform/error-message";
import React, {FC} from "react";


interface FormErrorMessageComponentProps {
    errors:  any,
    fieldName: string
}

export const FormErrorMessageComponent: FC<FormErrorMessageComponentProps> = ({
  errors,
  fieldName,
}) => {
  return (
    <ErrorMessage
      errors={errors}
      name={fieldName}
      render={({ message }) => (
        <span className="flex font-sans items-center font-medium tracking-wide text-red-900 text-xs mt-1 ml-1">
          {message}
        </span>
      )}
    />
  );
};