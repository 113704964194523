import {
  PR_CYCLE_TIME_BREAKDOWN_KEYS,
  prCycleTimeBreakdownKeyLabelMapper,
  PRCycleTimeBreakdownType,
  prCycleTimeColorMapper,
} from "./utils";
import styles from "./PRCycleTimeBreakdownTooltip.module.css";
import { TooltipChart } from "_sredx/_ui/Tooltip";

interface PRCycleTimeBreakdownTooltipProps {
  data: PRCycleTimeBreakdownType;
}
export const PRCycleTimeBreakdownTooltip = ({
  data,
}: PRCycleTimeBreakdownTooltipProps) => {
  return (
    <TooltipChart className={styles.wrapper}>
      <div className={styles.title}>Cycle time breakdown</div>
      <div className={styles.desc}>
        <strong>{data.nbOfPRs}</strong> Pull requests merged between
        <strong> {data.startDate} </strong> and
        <strong> {data.endDate} </strong>
      </div>
      <div className={styles.legends}>
        {PR_CYCLE_TIME_BREAKDOWN_KEYS.map((key) => (
          <div key={key} className={styles.legend}>
            <div
              className={styles.color}
              style={{ backgroundColor: prCycleTimeColorMapper(key) }}
            />
            <div className={styles.label}>
              Avg. {prCycleTimeBreakdownKeyLabelMapper(key)}:{" "}
              <strong>{data[key]}</strong> hours
            </div>
          </div>
        ))}
      </div>
    </TooltipChart>
  );
};
