import React from "react";

import { PageContent } from "_sredx/_ui";
import { HomeContainer } from "_sredx/containers";
import { TeamProvider } from "_sredx/context";

export const HomePage = () => {
  return (
    <PageContent>
      <TeamProvider>
        <HomeContainer />
      </TeamProvider>
    </PageContent>
  );
};
