import { useQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import {
  EvaluationLevelsResultDto,
  EvaluationSearchCriteriaDto,
} from "_sredx/types";
import { ClientApi } from "_sredx/services/clientApi";

interface UseGetEvaluationsLevelsProps {
  params?: EvaluationSearchCriteriaDto;
}

export const useGetEvaluationsLevels = ({
  params,
}: UseGetEvaluationsLevelsProps) => {
  return useQuery<EvaluationLevelsResultDto[]>({
    queryKey: [QUERY_KEYS.evaluationsLevels, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getEvaluationsLevels(), {
        params,
      });
    },
    enabled:
      params && (!!params.teamIds?.length || !!params.serviceIds?.length),
  });
};
