import React, { useState } from "react";

interface AccordionProps {
  children: ({
    isOpen,
    toggle,
  }: {
    isOpen: boolean;
    toggle: () => void;
  }) => React.ReactElement;
}

export const Accordion = ({ children }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return children({ isOpen, toggle });
};

export const AccordionHeader = ({
  toggle,
  children,
  className,
}: {
  toggle: () => void;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={className} onClick={toggle}>
      {children}
    </div>
  );
};

export const AccordionContent = ({
  isOpen,
  children,
  className,
}: React.PropsWithChildren<{ isOpen: boolean; className?: string }>) => {
  if (isOpen) return <div className={className}>{children}</div>;
  return null;
};
