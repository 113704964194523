import { forwardRef } from "react";
import { IconProps } from "./types";

export const JiraIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        viewBox="0 0 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>Jira</title>
        <path
          fill="#2684FF"
          d="M22.165 2h-9.631a4.347 4.347 0 0 0 4.347 4.348h1.774V8.06A4.348 4.348 0 0 0 23 12.405v-9.57A.836.836 0 0 0 22.165 2Z"
        />
        <path
          fill="url(#jiraA)"
          d="M17.4 6.799H7.767a4.348 4.348 0 0 0 4.345 4.345h1.774v1.718a4.347 4.347 0 0 0 4.348 4.342v-9.57a.835.835 0 0 0-.836-.835Z"
        />
        <path
          fill="url(#b)"
          d="M12.631 11.595H3a4.348 4.348 0 0 0 4.348 4.347h1.78v1.713A4.348 4.348 0 0 0 13.465 22v-9.57a.835.835 0 0 0-.835-.835Z"
        />
        <defs>
          <linearGradient
            id="jiraA"
            x1="1963.62"
            x2="1180.75"
            y1="5.212"
            y2="817.311"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".18" stopColor="#0052CC" />
            <stop offset="1" stopColor="#2684FF" />
          </linearGradient>
          <linearGradient
            id="b"
            x1="1056.61"
            x2="585.584"
            y1="16.329"
            y2="479.754"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".18" stopColor="#0052CC" />
            <stop offset="1" stopColor="#2684FF" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
);
