import { identicon, initials } from "@dicebear/collection";

export const styleResolver = (style: string) => {
  switch (style) {
    case "initials":
      return initials;
    case "identicon":
      return identicon;
    default:
      return initials;
  }
};
