import styles from "./InitiativeCreationInfoForm.module.css";
import { Card, CardBody, CardHeader, CardTitle } from "_sredx/_ui";
import { FormFieldInput, FormFieldSelect, FormFieldTextArea } from "../Form";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InitiativeInfoFormData } from "./types";
import { getInitiativeStatusOptions, schema } from "./utils";

interface InitiativeCreationInfoFormProps {
  defaultValues?: InitiativeInfoFormData;
  onChange: (data: InitiativeInfoFormData) => void;
}

export const InitiativeCreationInfoForm = ({
  defaultValues,
  onChange,
}: InitiativeCreationInfoFormProps) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm<InitiativeInfoFormData>({
    mode: "onChange",
    resolver: yupResolver(schema) as Resolver<InitiativeInfoFormData>,
    defaultValues,
  });

  const handleSchedulingInfoChange = () => {
    onChange(getValues());
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>general information</CardTitle>
      </CardHeader>

      <CardBody>
        <div className={styles.body_wrapper}>
          <form onChange={handleSchedulingInfoChange}>
            <FormFieldInput
              name={"name"}
              control={control}
              label={"name"}
              placeholder={"Initiative name"}
              errorMessage={errors.name?.message}
              fullWidth
            />
            <FormFieldTextArea
              name={"description"}
              control={control}
              label={"description"}
              placeholder={"Description of the initiative."}
              errorMessage={errors.description?.message}
              rows={3}
              fullWidth
            />
            <FormFieldSelect
              name={"status"}
              control={control}
              label={"status"}
              placeholder={"Select status"}
              errorMessage={errors.status?.message}
              options={getInitiativeStatusOptions()}
              variant={"outlined"}
              onChange={handleSchedulingInfoChange}
            />
          </form>
        </div>
      </CardBody>
    </Card>
  );
};
