import { Heading, PageContent } from "_sredx/_ui";
import { ScoreCardCreationContainer } from "_sredx/containers/ScoreCardCreationContainer";
import styles from "./ScoreCardCreation.module.css";

export const ScoreCardCreationPage = () => {
  return (
    <PageContent className={styles.wrapper}>
      <Heading>Create scorecard</Heading>
      <ScoreCardCreationContainer />
    </PageContent>
  );
};
