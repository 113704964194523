import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { Achievements } from "_sredx/components";
import { useGetEvaluationsLevels } from "_sredx/services";
import { Card, Heading, Loader, Text } from "_sredx/_ui";

import { getAchievements } from "./utils";
import styles from "./AchievementsContainer.module.css";
import { useTeamContext } from "_sredx/context";

export const AchievementsContainer = () => {
  // Hooks
  const navigate = useNavigate();
  const { selectedTeam } = useTeamContext();

  const teamId = selectedTeam ? selectedTeam.id : "";

  // Services
  const { data: evaluationLevelResults, isLoading } = useGetEvaluationsLevels({
    params: {
      teamIds: [teamId],
    },
  });

  // Derived Variables
  const achievements = getAchievements(evaluationLevelResults);

  // Event handlers
  const handleOnAchievementClicked = (scoreCardId: string) => {
    navigate(`/scorecards/${scoreCardId}`);
  };

  if (isLoading) {
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading Achievements...</Text>
        </div>
      </Card>
    );
  }

  if (achievements.length === 0) {
    return null;
  }

  return (
    <div className={clsx(styles.wrapper)}>
      <Heading level={4}>Achievements</Heading>
      <Achievements
        achievements={achievements}
        onClick={handleOnAchievementClicked}
      />
    </div>
  );
};
