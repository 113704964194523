import { useStepperContext } from "hooks/stepper/useStepperContext";
import { PropsWithChildren } from "react";

interface StepProps {
  stepKey: string;
}

export const Step = ({ children, stepKey }: PropsWithChildren<StepProps>) => {
  const { isCurrentStep } = useStepperContext();

  if (!isCurrentStep(stepKey)) {
    return null;
  }

  return <>{children}</>;
};
