import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./Button.module.css";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  size?: "small" | "medium" | "large";
  variant?: "primary" | "neutral" | "danger";
  ghost?: boolean;
}

export const Button = ({
  children,
  isLoading = false,
  type = "button",
  size = "medium",
  variant = "primary",
  ghost = false,
  className,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  const classNames = clsx(
    "nemo-button",
    styles.button,
    size === "small" && styles.small,
    size === "medium" && styles.medium,
    size === "large" && styles.large,
    variant === "primary" && styles.primary,
    variant === "neutral" && styles.neutral,
    variant === "danger" && styles.danger,
    ghost && styles.ghost,
    className
  );
  return (
    <button type={type} className={classNames} {...props}>
      {isLoading ? "Loading..." : children}
    </button>
  );
};
