import styles from "./TagsInput.module.css";
import { useState } from "react";
import { Tag } from "../Tag";
import { Label } from "../Label";
import clsx from "clsx";

type TagsInputProps = {
  label: string;
  name: string;
  placeholder: string;
  onChange: (event: { target: { value: string[]; name: string } }) => void;
  colored?: boolean;
  value?: string[];
  fullWidth?: boolean;
};

export const TagsInput = ({
  label,
  name,
  placeholder,
  onChange,
  colored = false,
  value = [],
  fullWidth,
}: TagsInputProps) => {
  const [tags, setTags] = useState<string[]>(value);

  const effectiveTags = value.length === 0 ? value : tags;

  const handleKeyDown = (e: any) => {
    if (e.target.value === "" && e.key === "Backspace") {
      const newTags = effectiveTags.slice(0, effectiveTags.length - 1);
      onChange({ target: { value: newTags, name } });
      setTags(newTags);
      return;
    }
    if (
      (e.key === "," ||
        e.key === " " ||
        e.key === ";" ||
        e.key === "Enter" ||
        e.key === "Tab") &&
      e.target.value !== ""
    ) {
      e.preventDefault();
      const newTags = [...effectiveTags, e.target.value];

      onChange({ target: { value: newTags, name } });
      setTags(newTags);
      e.target.value = "";
      return;
    }
  };

  const removeTag = (indexToRemove: number) => {
    const newTags = effectiveTags.filter((_, index) => index !== indexToRemove);
    onChange({ target: { value: newTags, name } });
    setTags(newTags);
  };

  return (
    <div className={clsx(styles.wrapper, fullWidth ? styles.fullwidth : "")}>
      {label && <Label>{label}</Label>}
      <div
        className={clsx(
          styles.tagsInputContainer,
          fullWidth ? styles.fullwidth : ""
        )}
      >
        {tags.map((tag, index) => (
          <Tag
            tag={tag}
            colored={label.toLowerCase() === "tags" || colored}
            onClick={() => removeTag(index)}
          />
        ))}
        <input
          id={"tagsInput"}
          type="text"
          className={styles.input}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
