import { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./CardBody.module.css";

interface CardBodyProps {
  noPadding?: boolean;
  className?: string;
}

export const CardBody = ({
  children,
  noPadding = false,
  className,
}: PropsWithChildren<CardBodyProps>) => {
  const classNames = clsx(
    "nemo-card-body",
    styles.wrapper,
    noPadding && styles.no_padding,
    className
  );

  return <div className={classNames}>{children}</div>;
};
