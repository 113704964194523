import { OnboardingProviderConfigForm } from "_sredx/components/OnboardingProviderConfigForm";
import { OnboardingProviderConfigFormHeader } from "_sredx/components/OnboardingProviderConfigFormHeader";
import { QUERY_KEYS } from "_sredx/config";
import { useGetConnectionValidity } from "_sredx/services/configurations";
import {
  useCreateInstance,
  useUpdateIntegrationInstance,
} from "_sredx/services";
import { ConfigParamValues } from "_sredx/types/configurations";
import { useToaster } from "hooks/useToaster";
import { ConfigParam } from "models/integration/IntegrationTemplate";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { ServiceProvider } from "types/discovery";
import styles from "./ProviderConfigurationCreationContainer.module.css";
import { useGetInstanceValidity } from "_sredx/services/instances/useGetInstanceValidity";

interface ProviderConfigurationFormProps {
  serviceProvider: ServiceProvider;
  configParams: ConfigParam[];
  providerValidationResult: Record<string, boolean>;
  onAddProviderValidationResult: (provider: string, result: boolean) => void;
  currentConfig: ConfigParamValues;
  isOpen: boolean;
  onClick: () => void;
}

export const ProviderConfigurationCreationContainer = ({
  configParams,
  serviceProvider,
  onAddProviderValidationResult,
  providerValidationResult,
  currentConfig,
  isOpen,
  onClick,
}: ProviderConfigurationFormProps) => {
  //state
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  //hooks
  const contentHeight = useRef<HTMLDivElement | null>(null);
  const { addToast } = useToaster();
  const queryClient = useQueryClient();

  //services
  const { isConfigured, ...currentConfigValues } = currentConfig;
  const isCurrentConfigExist = isConfigured == "true";

  const { data, isLoading: isTestingConnectivity } = useGetInstanceValidity({
    instanceId: currentConfig?.id ?? "",
    enabled: isCurrentConfigExist || isEnabled,
  });

  const {
    mutate: saveIntegrationInstance,
    isLoading: isSavingIntegrationInstance,
  } = useCreateInstance();
  const {
    mutate: updateIntegrationInstance,
    isLoading: isUpdatingIntegrationInstance,
  } = useUpdateIntegrationInstance();

  //derived variables
  const formDefaultValues = useMemo(() => {
    const formValues = { ...currentConfigValues };
    configParams.forEach((configParam) => {
      if (configParam.secret && formValues[configParam.key])
        formValues[configParam.key] =
          "**********" + formValues[configParam.key];
      //initializing defaultValues when no currentConfig exists because input is controlled
      if (!formValues[configParam.key]) formValues[configParam.key] = "";
    });

    //if all required fields are filled, enable the button
    const isAllRequiredFilled = configParams.every((configParam) => {
      if (configParam.notRequired) return true;
      return formValues[configParam.key] !== "";
    });
    if (isAllRequiredFilled) setIsEnabled(true);
    return formValues;
  }, [currentConfig, configParams]);

  useEffect(() => {
    if (!data) return;
    onAddProviderValidationResult(serviceProvider.key, data.valid);
  }, [data]);

  //Event handlers
  const updateExistingConfig = (formData: ConfigParamValues) => {
    const changedConfig = Object.keys(formData).reduce(
      (acc: ConfigParamValues, key) => {
        if (formData[key] !== formDefaultValues[key]) {
          acc[key] = formData[key];
        }
        return acc;
      },
      {}
    );
    updateIntegrationInstance(
      { config: changedConfig, id: currentConfig?.id },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries(
            [QUERY_KEYS.connectionTest, serviceProvider.key],
            {
              exact: false,
            }
          );
          setIsEnabled(true);
        },
        onError: (error: any) => {
          addToast({
            type: "error",
            message: error.response?.data.message,
          });
        },
      }
    );
  };

  const saveNewConfig = (formData: ConfigParamValues) => {
    saveIntegrationInstance(
      {
        integrationKey: serviceProvider.key,
        name:
          serviceProvider.name +
          "-instance-" +
          Math.random().toString(36).substring(2, 6),
        description: "Integration instance for " + serviceProvider.name,
        config: formData,
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries(
            [QUERY_KEYS.connectionTest, serviceProvider.key],
            {
              exact: false,
            }
          );
          setIsEnabled(true);
        },
        onError: (error: any) => {
          addToast({
            type: "error",
            message: error.response?.data.message,
          });
        },
      }
    );
  };

  const handleFormSubmit = async (formData: ConfigParamValues) => {
    if (currentConfig?.id) {
      updateExistingConfig(formData);
    } else {
      const { id, ...rest } = formData;

      saveNewConfig(rest);
    }
  };
  return (
    <div className={styles.wrapper}>
      <OnboardingProviderConfigFormHeader
        handleClick={onClick}
        serviceProvider={serviceProvider}
        validationResult={providerValidationResult[serviceProvider.key]}
      />

      <div
        ref={contentHeight}
        className={styles.form_wrapper}
        style={
          isOpen
            ? { height: contentHeight.current?.scrollHeight }
            : { height: "0px" }
        }
      >
        <OnboardingProviderConfigForm
          serviceProviderName={serviceProvider.name}
          configParams={configParams}
          isLoadingButton={
            isSavingIntegrationInstance ||
            isTestingConnectivity ||
            isUpdatingIntegrationInstance
          }
          defaultValues={formDefaultValues}
          handleProviderConfigSubmit={handleFormSubmit}
        />
      </div>
    </div>
  );
};
