import React, {FC} from "react";
import AdminAppHeader from "./AdminAppHeader";
import AdminAppFooter from "./AdminAppFooter";

export interface Props {
    children: React.ReactNode;
}

export const AdminAppLayout: FC<Props> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-1" >
        <AdminAppHeader />
        <div className="mt-10 md:mt-[52px]">{children}</div>
      </div>
      <AdminAppFooter />
    </div>
  );
};
