import React from "react";

interface ServiceAddBodyProp {
  actions?: React.ReactNode;
}
export const ServiceAddBody = ({
  children,
  actions,
}: React.PropsWithChildren<ServiceAddBodyProp>) => {
  return (
    <div
      className={`bg-white relative mb-5 px-5 py-5 overflow-hidden transition-all ease-in-out`}
    >
      <div>{children}</div>
      <div className="flex justify-end mt-4">{actions}</div>
    </div>
  );
};
