import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import { ROUTES } from "_sredx/routes/routes";
import { ROUTE } from "./types";
import { PrivateRoute } from "./PrivateRoute";
import { IToggle, useFlags } from "@unleash/proxy-client-react";

const renderRoutes = (routes: ROUTE[], flags: IToggle[]) => {
  return routes.map((route, i) => {

    if (route.isFeatureEnabled && !route.isFeatureEnabled(flags)) {
      return null;
    }


    const { path, element, children, isPrivate } = route;
    const firstChild = children && children.length > 0 && children[0];

    const Element = isPrivate ? <PrivateRoute component={element} /> : element;

    return (
      <Route key={`${route.path}-${i}`} path={path} element={Element}>
        {firstChild && (
          <Route
            key={firstChild.path}
            index={true}
            element={
              firstChild.element || <Navigate to={firstChild.path || ""} />
            }
          />
        )}
        {children && renderRoutes(children, flags)}
      </Route>
    );
  });
};

export const SredxAppRoutes = () => {
  const flags: IToggle[] = useFlags();
  return (
    <BrowserRouter>
      <Routes>{renderRoutes(ROUTES, flags)}</Routes>
    </BrowserRouter>
  );
};
