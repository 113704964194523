import React from "react";
import { useIntegrationsTemplates } from "hooks/integrations/templates/useIntegrationsTemplates";
import { Navigate, useParams } from "react-router-dom";
import { NOT_FOUND_PATH } from "routes/paths";
import { Toaster } from "react-hot-toast";
import { CHILD_SEPARATOR } from "components/IntegrationsConfig/IntegrationTemplateMenuItem/IntegrationTemplateMenuItem";
import { IntegrationsConfigWrapper } from "components/IntegrationsConfig/IntegrationsConfigComponents/IntegrationsConfigWrapper/IntegrationsConfigWrapper";
import { IntegrationTemplatesMenuComponent } from "components/IntegrationsConfig/IntegrationsConfigComponents/IntegrationTemplatesMenu/IntegrationTemplatesMenuComponent";
import { IntegrationConfigContainer } from "components/IntegrationsConfig/IntegrationsConfigComponents/IntegrationConfigContainer/IntegrationConfigContainer";
import { Heading, Loader, PageContent, Text } from "_sredx/_ui";
import styles from "./ConfigurationsPage.module.css";

export const ConfigurationsPage = () => {
  const { data, isSuccess, isLoading } = useIntegrationsTemplates();
  let { templateKey } = useParams();

  let currentTemplate: any = undefined;

  if (isLoading) {
    return (
      <PageContent>
        <div className={styles.wrapper}>
          <div className={styles.header_wrapper}>
            <div>
              <Heading>Integrations configuration</Heading>
              <p>Manage your essential integrations configuration details</p>
            </div>
          </div>
          <div className={styles.loader_wrapper}>
            <Loader />
            <Text size={"lg"}>Loading integrations...</Text>
          </div>
        </div>
      </PageContent>
    );
  }

  if (!isSuccess || !data) {
    return <Navigate to={NOT_FOUND_PATH} replace={true} />;
  }

  if (!templateKey) {
    let link = "";
    if (data[0]?.children && data[0]?.children?.length > 0)
      link += data[0].key + CHILD_SEPARATOR + data[0].children[0].key;
    else link += data[0].key;
    return <Navigate to={link} replace={true} />;
  }

  currentTemplate = data.find(
    (template) => template.key === templateKey!.split(CHILD_SEPARATOR)[0]
  );

  if (templateKey.includes(CHILD_SEPARATOR)) {
    currentTemplate = currentTemplate.children!.find(
      (child: any) => child.key === templateKey!.split(CHILD_SEPARATOR)[1]
    );
  }

  return (
    <PageContent>
      <div className={styles.wrapper}>
        <div className={styles.header_wrapper}>
          <div>
            <Heading>Integrations configuration</Heading>
            <p>Manage your essential integrations configuration details</p>
          </div>
        </div>
        <IntegrationsConfigWrapper>
          <IntegrationTemplatesMenuComponent
            integrationTemplates={data}
            currentTemplate={currentTemplate}
            templateKey={templateKey}
          />
          <IntegrationConfigContainer
            currentTemplate={currentTemplate}
          />
        </IntegrationsConfigWrapper>
        <Toaster />
      </div>
    </PageContent>
  );
};
