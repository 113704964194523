import { Heading, Input } from "_sredx/_ui";
import { MemberDataTable } from "_sredx/components/MemberListing";
import { API_FIRST_PAGE, API_PAGE_SIZE_DEFAULT } from "_sredx/constants/api";
import { UserSearchCriteria } from "_sredx/types";
import { debounce } from "lodash";
import { ChangeEventHandler, useMemo, useState } from "react";
import styles from "./MembersListingContainer.module.css";
import { useGetUsers } from "_sredx/services/users";

const MEMBER_SEARCH_CRITERIA_DEFAULT: UserSearchCriteria = {
  pageNumber: 0,
  pageSize: API_PAGE_SIZE_DEFAULT,
  sortBy: "createdAt",
  sortDir: "ASC",
};

export const MembersListingContainer = () => {
  //State
  const [searchCriteria, setSearchCriteria] = useState<UserSearchCriteria>(
    MEMBER_SEARCH_CRITERIA_DEFAULT
  );

  //query
  const { data, isLoading, isFetching, error } = useGetUsers({
    params: {
      ...searchCriteria,
    },
  });

  const { content: members, totalPages } = data ?? {};

  //handlers
  const handleOnCriteriaChange = (criteria: Partial<UserSearchCriteria>) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      ...criteria,
    }));
  };
  const onCriteriaChange = useMemo(
    () => debounce(handleOnCriteriaChange, 300),
    []
  );
  const handleOnSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const query = e.target.value;
    onCriteriaChange({
      email: query,
      pageNumber: API_FIRST_PAGE,
    });
  };

  const handleOnPageChange = (pageNo: number) => {
    handleOnCriteriaChange({
      pageNumber: pageNo,
    });
  };

  if (!members || error) {
    return <div>Something went wrong</div>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title_wrapper}>
        <Heading level={2}>Members</Heading>
      </div>
      <div className={styles.search_wrapper}>
        <Input
          placeholder={"Search by email"}
          value={searchCriteria.email}
          onChange={handleOnSearch}
        />
      </div>

      <div className={styles.table_wrapper}>
        <MemberDataTable
          members={members || []}
          isLoading={isLoading}
          isFetching={isFetching}
          totalPages={totalPages}
          onPageChange={handleOnPageChange}
        />
      </div>
    </div>
  );
};
