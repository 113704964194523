import styles from "./SelectPopoverTitle.module.css";

interface SelectPopoverTitleProps {
  title: string;
  onClear: () => void;
}
export const SelectPopoverTitle = ({
  title,
  onClear,
}: SelectPopoverTitleProps) => {
  return (
    <div className={styles.header}>
      <h4 className={styles.title}>{title}</h4>
      {/*<button className={styles.clear_btn} onClick={onClear}>*/}
      {/*  Clear*/}
      {/*</button>*/}
    </div>
  );
};
