export const PRMergeIcon = ({
  size = 24,
  color = "currentColor",
}: {
  size: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M2 5.5a3.5 3.5 0 1 1 4.613 3.32A3.001 3.001 0 0 0 9.5 11h5.645A3.502 3.502 0 0 1 22 12a3.5 3.5 0 0 1-6.855 1H9.5a4.978 4.978 0 0 1-3-1v3.145A3.502 3.502 0 0 1 5.5 22a3.5 3.5 0 0 1-1-6.855v-6.29A3.502 3.502 0 0 1 2 5.5ZM5.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm13 6.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM5.5 17a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
