import { PageContent } from "_sredx/_ui";
import { AppReturnButton } from "_sredx/Layout";

import { useParams } from "react-router-dom";
import styles from "./ClusterDetailsPage.module.css";
import ClusterManagedResourcesContainer from "containers/SelfService/ClusterManagedResourcesContainer/ClusterManagedResourcesContainer";
import { ClusterDetailsToolsContainer } from "containers/SelfService/ClusterDetailsContainer/ClusterDetailsToolsContainer";
import { ClusterDetailsDeleteContainer } from "containers/SelfService/ClusterDetailsContainer/ClusterDetailsDeleteContainer";
import ClusterDetailsInfoFetchContainer from "containers/SelfService/ClusterDetailsFetchContainer/ClusterDetailsInfoFetchContainer";

export const ClusterDetailsPage = () => {
  const { clusterId } = useParams<{ clusterId: string }>();
  return (
    <PageContent className={styles.wrapper}>
      <AppReturnButton
        to={"/self-service/clusters"}
        label={"Back to clusters"}
      />

      <div className="flex flex-col gap-6">
        <ClusterDetailsInfoFetchContainer clusterId={clusterId ?? ""} />
        <ClusterManagedResourcesContainer name={clusterId ?? ""} />
        <ClusterDetailsToolsContainer id={clusterId ?? ""} />
        <ClusterDetailsDeleteContainer id={clusterId ?? ""} />
      </div>
    </PageContent>
  );
};
