import styles from "./ChartLegend.module.css";

interface ChartLegendProps {
  color: string;
  label: string;
}

export const ChartLegend = ({ color, label }: ChartLegendProps) => {
  return (
    <div className={styles.legend_w}>
      <div
        className={styles.legend_color}
        style={{ backgroundColor: color }}
      ></div>
      <div className={styles.legend_label}>{label}</div>
    </div>
  );
};
