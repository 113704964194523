import { useAxios } from "hooks/useAxios";
import { useMutation, useQueryClient } from "react-query";
import { SELF_SERVICE_PATHS, SELF_SERVICE_QUERY_KEYS } from "./paths";

export const useDeleteCloudAccount = () => {
    const queryClient = useQueryClient();
    const { axios } = useAxios();
    const deleteCloudAccount = async (id:string) => {
      const response = await axios.delete(
          SELF_SERVICE_PATHS.CLOUD_ACCOUNT(id)
      );
      return response.data;
    };
  
    return useMutation(deleteCloudAccount, {
      onSuccess: () => {
        queryClient.invalidateQueries(SELF_SERVICE_QUERY_KEYS.CLOUD_ACCOUNTS);
      },
    });
};