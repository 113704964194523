import { FormField } from 'components/basic/FormField'
import { SelectInput } from 'components/basic/SelectInput'
import { TextInput } from 'components/octo-ui/Forms/TextInput'
import { resolveLogoText } from 'components/self-service/ClusterCard/utils'
import { providerFieldsConfig } from 'containers/SelfService/ConfigurationContainer/CloudAccountCreateCreateContainer/utils'
import { Dispatch } from 'react'
import { CloudProvider } from 'types/selfService'
import styles from './CloudAccountForm.module.css';

interface CloudAccountFormProps {
    errors?: any,
    selectedProvider: CloudProvider | null,
    setSelectedProvider: Dispatch<React.SetStateAction<CloudProvider | null>>;
}

const CloudAccountForm = ({selectedProvider, setSelectedProvider, errors} : CloudAccountFormProps) => {

    const fieldsToRender = selectedProvider ? providerFieldsConfig[selectedProvider] : [];

    const handleProviderChange = (selectedValue: any) => {
        setSelectedProvider(selectedValue);
    };


  return (
    <div className={styles.root} >

        {/* common fields */}
        <FormField 
            id='name'
            name='name'
            label='name'
            placeholder='Enter cloud account name'
            component={TextInput}
            error={errors?.name?.message}
        />
        <FormField 
            id='provider'
            name='provider'
            label='provider'
            component={SelectInput}
            error={errors?.provider?.message}
            onChange={handleProviderChange}
            options={options}
        />

        {/* cloud provider dependent fields */}
        {fieldsToRender.map((field: any, key: string) => (
            <FormField
                key={field.id}
                id={field.id}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                component={TextInput}
                error={errors?.[field.name]?.message}
                type={field.type}
            />
        ))}
                
    </div>
  )
}

export default CloudAccountForm;


const options = [
    {
        label: <>
            {resolveLogoText('scaleway')}
        </>,
        value: 'scaleway'
    },
    {
        label: <>
            {resolveLogoText('aws')}
        </>,
        value: 'aws'
    },
    {
        label: <>
            {resolveLogoText('azure')}
        </>,
        value: 'azure'
    },
    {
        label: <>
            {resolveLogoText('gcp')}
        </>,
        value: 'gcp'
    },
]