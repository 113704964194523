import IntegrationTemplate from "models/integration/IntegrationTemplate";
import IntegrationTemplateMenuItem from "components/IntegrationsConfig/IntegrationTemplateMenuItem/IntegrationTemplateMenuItem";
import React, { useMemo } from "react";
import styles from "./IntegrationTemplatesMenuComponent.module.css";
import { resolveIntegrationTemplates } from "components/IntegrationsConfig/utils/utils";

interface IntegrationTemplatesMenuComponentProps {
  integrationTemplates: IntegrationTemplate[];
  currentTemplate: IntegrationTemplate;
  templateKey: string;
}

export const IntegrationTemplatesMenuComponent = ({
  integrationTemplates,
  currentTemplate,
  templateKey,
}: IntegrationTemplatesMenuComponentProps) => {
  const resolveIntegrations = useMemo(
    () => resolveIntegrationTemplates(integrationTemplates),
    [integrationTemplates]
  );

  return (
    <aside className={styles.wrapper}>
      <nav className={styles.content_wrapper}>
        {integrationTemplates.map((item) => {
          if (item.children && item.children.length > 0) {
            return item.children.map((child) => {
              if (child.configParams && child.configParams?.length > 0) {
                return (
                  <IntegrationTemplateMenuItem
                    key={child.key}
                    parentKey={item.key}
                    template={child}
                    logo={resolveIntegrations[child.key]}
                    currentTemplateKey={currentTemplate.key}
                  />
                );
              }
            });
          }
          if (item.configParams && item.configParams?.length > 0)
            return (
              <IntegrationTemplateMenuItem
                key={item.key}
                logo={resolveIntegrations[item.key]}
                template={item}
                currentTemplateKey={templateKey!}
              />
            );
        })}
      </nav>
    </aside>
  );
};
