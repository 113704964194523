import styles from "./ScoreCardLevels.module.css";
import Tooltip from "components/octo-ui/Tootlip/Tooltip";
import React from "react";
import { ScoreCardDto } from "_sredx/types";
import { useMediaQuery } from "@uidotdev/usehooks";
import { ScoreCardLevelsImage } from "./ScoreCardLevelsImage";
import { Text } from "_sredx/_ui";
import { ScoreCardLevelsWrapper } from "./ScoreCardLevelsWrapper";

interface ScoreCardLevelsProps {
  scoreCard: ScoreCardDto;
}

export const ScoreCardLevels = ({ scoreCard }: ScoreCardLevelsProps) => {
  // Hooks
  const matchesMin = useMediaQuery("(min-width: 765px)");
  const matchesMax = useMediaQuery("(max-width: 992px)");
  const matchesSmallScreen = useMediaQuery("(max-width: 400px)");

  // Derived Variables
  const levels = scoreCard.levels || [];
  const matches = (matchesMin && matchesMax) || matchesSmallScreen;
  const renderedLevels = matches ? 2 : 3;

  const leftLevels =
    levels.length > renderedLevels
      ? levels.slice(renderedLevels).map((level) => level.name!)
      : [];

  if (levels.length === 0) {
    return (
      <ScoreCardLevelsWrapper levelsCount={0}>
        <Text size={"xs"} className={styles.placeholder_text}>
          No Levels Yet
        </Text>
      </ScoreCardLevelsWrapper>
    );
  }

  return (
    <ScoreCardLevelsWrapper levelsCount={levels.length}>
      <div className={styles.content_wrapper}>
        {levels.slice(0, renderedLevels).map((level, index) => (
          <div key={level.id} className={styles.level_wrapper}>
            <Tooltip content={level.name} direction="bottom">
              <ScoreCardLevelsImage key={index} image={level.image} />
            </Tooltip>
            <Text size={"sm"} className={styles.level_name}>
              {level.name}
            </Text>
          </div>
        ))}
        {levels.length > renderedLevels && (
          <div className={styles.level_wrapper}>
            <Tooltip
              content={"remaining levels are: " + leftLevels}
              direction="left"
            >
              <div className={styles.image_wrapper}>
                <Text size={"xs"} className={styles.more}>{`+${
                  levels.length - renderedLevels
                }`}</Text>
              </div>
            </Tooltip>
            <Text size={"sm"} className={styles.level_name}>
              more
            </Text>
          </div>
        )}
      </div>
    </ScoreCardLevelsWrapper>
  );
};
