import clsx from "clsx";
import styles from "./LayoutSidebar.module.css";
import { PropsWithChildren } from "react";

interface LayoutSidebarProps {
  isCollapsed?: boolean;
}

export const LayoutSidebar = ({
  children,
  isCollapsed,
}: PropsWithChildren<LayoutSidebarProps>) => {
  return (
    <div
      className={clsx(
        "nemo-layout-sidebar",
        styles.wrapper,
        isCollapsed ? styles.hide : styles.show
      )}
    >
      <div
        className={clsx(
          styles.content,
          isCollapsed ? styles.hide : styles.show
        )}
      >
        {children}
      </div>
    </div>
  );
};
