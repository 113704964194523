import {useMutation, useQueryClient} from "react-query";
import {useAxios} from "../useAxios";
import {evaluateScoreCard} from "services/scorecards/ScoreCards.service";
import {SERVICES_SCORES_QUERY} from "../useServicesScores";
import {EVALUATIONS_QUERY} from "../evaluations/useServiceEvaluationsHistoryByScoreCard";
import { SCORE_CARDS_QUERY } from "./useScoreCards";
import { SERVICES_QUERY } from "hooks/useServices";

export const useEvaluateScoreCard = (scoreCardId: string, connexionId: string) => {
    const {axios} = useAxios();
    const queryClient = useQueryClient();
    return useMutation(["scorecards", scoreCardId, "evaluations"], async () => {
        return await evaluateScoreCard(axios, scoreCardId, connexionId);
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([SERVICES_SCORES_QUERY]);
            await queryClient.invalidateQueries([EVALUATIONS_QUERY]);
            await queryClient.invalidateQueries([SERVICES_QUERY]);
        }
    });
}
