import {useQuery} from "react-query";
import ServiceScores from "../../models/leaderboard/ServiceScores";
import { getAllServicesScorefirstAndLastByTimeRange } from "services/services-scores";
import { useAxios } from "../useAxios";


export const SERVICES_SCORES_QUERY = "growth_decline_services_scores";

export const useServiceScoresImporovedDropedDashboard = (timeRange:string,scorcardId:string) => {
    const {axios} = useAxios();
    const query= useQuery<ServiceScores[], Error>([SERVICES_SCORES_QUERY], () => getAllServicesScorefirstAndLastByTimeRange(axios,timeRange,scorcardId));
    const { refetch } = query;

  return { ...query, refetch };
}