import { InfoCircleIcon } from "_sredx/_ui/Icon";
import { TrendIndicator } from "_sredx/_ui";
import styles from "./MetricChartStats.module.css";
import { Fragment } from "react";

const MetricChartStatsValue = ({
  value,
  unit,
}: {
  value: string | number;
  unit?: string;
}) => {
  if (typeof value === "number") {
    return (
      <div className={styles.value_wrapper}>
        <span className={styles.value}>{value}</span>
        {unit && <span className={styles.unit}>{unit}</span>}
      </div>
    );
  }
  const valueUnits = value.match(/(\d+(\.\d+)?\s*\D+)/g) || [];

  return (
    <div className={styles.value_wrapper}>
      {valueUnits.map((unit, index) => {
        const valueMatch = unit.match(/\d+(\.\d+)?\s*/);
        const value = valueMatch ? valueMatch[0] : "";
        const unitLabelMatch = unit.match(/[A-Za-z%]+/);
        const unitLabel = unitLabelMatch ? unitLabelMatch[0] : "";

        return (
          <Fragment key={index}>
            <span className={styles.value}>{value}</span>
            <span className={styles.unit}>{unitLabel}</span>
          </Fragment>
        );
      })}
    </div>
  );
};

interface MetricChartStatsProps {
  value: number | string;
  unit?: string;
  label: string;
  tooltip?: string;
  diffLastPeriod?: number;
  diffLastPeriodStatus?: "up" | "down";
}
export const MetricChartStats = ({
  value,
  unit,
  label,
  tooltip,
  diffLastPeriod,
  diffLastPeriodStatus,
}: MetricChartStatsProps) => {
  return (
    <div className={styles.stat_wrapper}>
      <MetricChartStatsValue value={value} unit={unit} />
      <div className={styles.bottom_wrapper}>
        <div className={styles.label_wrapper}>
          <span className={styles.label}>{label}</span>
          {tooltip && <InfoCircleIcon size={10} />}
        </div>
        <TrendIndicator
          status={diffLastPeriodStatus}
          value={diffLastPeriod}
          color="success"
          unit="%"
        />
      </div>
    </div>
  );
};
