import { forwardRef } from "react";
import { IconProps } from "./types";

export const KoordinatorIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
        <svg
        x="0px"
        y="0px"
        viewBox="0 0 600 600"
        enableBackground={"new 0 0 600 600"}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>Koordinator</title>
        <style type="text/css">
          {
            "\n\t.st0{fill:#0064FF;}\n\t.st1{fill:#FFFFFF;}\n\t.st2{fill:#003296;}\n"
          }
        </style>
        <g
          transform="matrix(1.892883, 0, 0, 1.818217, -266.999878, -239.411942)"
        >
          <g>
            <polygon
              className="st0"
              points="299.33,210.45 176.16,257.4 299.33,300.2 &#9;&#9;&#9;"
            />
            <polygon
              className="st2"
              points="299.33,210.45 422.49,257.4 299.33,300.2 &#9;&#9;&#9;"
            />
            <ellipse
              transform="matrix(0.0782 -0.9969 0.9969 0.0782 -123.2125 566.9557)"
              className="st1"
              cx={244.97}
              cy={350.1}
              rx={77.8}
              ry={77.8}
            />
            <ellipse
              transform="matrix(0.3783 -0.9257 0.9257 0.3783 -51.6551 519.4788)"
              className="st1"
              cx={360.91}
              cy={298.2}
              rx={37.97}
              ry={37.97}
            />
            <circle className="st1" cx={299.33} cy={249.41} r={21.84} />
            <ellipse
              transform="matrix(0.3511 -0.9363 0.9363 0.3511 39.9325 387.2138)"
              className="st0"
              cx={299.33}
              cy={164.8}
              rx={21.95}
              ry={21.95}
            />
            <ellipse
              transform="matrix(0.3147 -0.9492 0.9492 0.3147 -62.3363 383.922)"
              className="st2"
              cx={234.7}
              cy={235.13}
              rx={27.33}
              ry={27.33}
            />
            <ellipse
              transform="matrix(0.4191 -0.9079 0.9079 0.4191 15.0711 455.1955)"
              className="st0"
              cx={363.26}
              cy={215.82}
              rx={29.07}
              ry={29.07}
            />
            <path
              className="st2"
              d="M167.16,267.41V385.5c0,14.81,9.33,28.02,23.28,32.98l108.88,38.68V300.2l-118.82-42.21 C174,255.68,167.16,260.5,167.16,267.41z"
            />
            <path
              className="st0"
              d="M431.49,267.41V385.5c0,14.81-9.33,28.02-23.28,32.98l-108.88,38.68V300.2l118.82-42.21 C424.65,255.68,431.49,260.5,431.49,267.41z"
            />
          </g>
        </g>
      </svg>
    );
  }
);

