import styles from "./DialogContent.module.css";
import * as RadixDialog from "@radix-ui/react-dialog";
import clsx from "clsx";

export const DialogContent = ({
  children,
  ...props
}: RadixDialog.DialogContentProps) => {
  return (
    <div className={clsx("nemo-dialog-content")}>
      <RadixDialog.DialogPortal>
        <RadixDialog.Overlay className={styles.dialog_overlay} />
        <RadixDialog.Content className={styles.dialog_content} {...props}>
          {children}
        </RadixDialog.Content>
      </RadixDialog.DialogPortal>
    </div>
  );
};
