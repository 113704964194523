import { Tag } from "_sredx/_ui/Tag";
import React, { FC, useEffect, useState } from "react";
import Select, {
  CSSObjectWithLabel,
  MultiValueProps,
  StylesConfig,
} from "react-select";
import Badge from "../../../octo-ui/Badge/Badge";

interface SelectSearchInputPropos {
  options: any[];
  onChange: (value: any[]) => void;
  componentName: string;
  team?: boolean;
  Icon?: FC<any>;
  originalValues: any[];
  selectedRulesIds: string[];
  setSelectedRulesIds(ruleids: string[]): void;
}

const SelectSearchInput: FC<SelectSearchInputPropos> = ({
  options,
  onChange,
  componentName,
  team,
  Icon,
  originalValues,
  selectedRulesIds,
  setSelectedRulesIds,
}) => {
  const [values, setValues] = useState(originalValues);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setValues(originalValues);
    setChanged(false);
  }, [originalValues]);

  useEffect(() => {
    if (!changed) setValues(originalValues);
  }, [changed, originalValues]);

  const colourStyles: StylesConfig<any[], true> = {
    control: (provided: any, { isFocused, menuIsOpen }) => {
      return {
        ...provided,
        border: "1px solid var(--gray-700)",
        boxShadow: isFocused ? "0 0 3px var(--cyan-600) " : "none",
        fontSize: "14px",
        borderRadius: 0,
        transition: "border-color 0.2s, box-shadow 0.2s",
        "&:focus": {
          border: "1px solid var(--cyan-600)",
          boxShadow: "0 0 3px var(--cyan-600) ",
        },
        "&:hover": {
          border: "1px solid var(--gray-700)",
          boxShadow: "0 0 3px var(--gray-700) ",
        }, // Add transition for smooth changes
      } as CSSObjectWithLabel;
    },
    option: (styles: any, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected || isFocused ? "#E1E7FD" : "white",
        color: "black",
      } as CSSObjectWithLabel;
    },
    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: "white",
        padding: 0,
        margin: 0,
      } as CSSObjectWithLabel;
    },
  };

  return (
    <div className="sm:px-0 px-1 flex md:flex-1 items-center">
      <div className="flex-1 flex justify-center lg:justify-end">
        <div className="w-full">
          <label htmlFor="rule-ids" className="sr-only">
            Choose {componentName}
          </label>
          <div className="relative text-gray-400 focus-within:text-gray-400">
            <Select
              id="rule-ids"
              isMulti
              isSearchable
              options={options}
              openMenuOnClick={true}
              closeMenuOnSelect={false}
              formatOptionLabel={(option: any) => (
                <Badge size={"small"} team={team} Icon={Icon}>
                  {option.name}
                </Badge>
              )}
              components={{
                MultiValue: (props) => <SelectMultiValueLabel {...props} />,
              }}
              placeholder={
                <span className="text-gray-400 sm:text-sm">{`Choose by ${componentName} names`}</span>
              }
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              styles={colourStyles}
              onChange={(value) => {
                setChanged(true);
                const deletedRulesFromLevel = values.filter(
                  (rule) => !value.includes(rule)
                );
                setSelectedRulesIds([
                  ...selectedRulesIds.filter(
                    (rule) =>
                      !deletedRulesFromLevel
                        .map((rule) => rule.id)
                        .includes(rule)
                  ),
                  ...value.map((rule) => rule.id),
                ]);
                setValues(value as any[]);
                onChange(value.map((rule: any) => rule.id));
              }}
              value={values}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectSearchInput;

const SelectMultiValueLabel = (
  props: Omit<MultiValueProps<any>, "children">
) => {
  return (
    <Tag
      tag={props.data.name}
      colored={false}
      onClick={props.removeProps.onClick as any}
    />
  );
};
