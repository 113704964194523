import { Member, PagedResponse, TeamMember, UserDto } from "_sredx/types";
import { InfiniteData } from "react-query";

export const getFilteredMembers = ({
  organizationMembers = [],
  selectedSearchMembers,
  addedMembers,
}: {
  organizationMembers?: UserDto[];
  selectedSearchMembers: Member[];
  addedMembers: TeamMember[];
}) => {
  const ignoredMembersEmails = [
    ...addedMembers.map((member) => member.email),
    ...selectedSearchMembers.map((member) => member.email),
  ];
  return organizationMembers
    .filter(
      (organizationMember) =>
        !ignoredMembersEmails.find(
          (email) => email === organizationMember.email
        )
    )
    .map((organizationMember) => {
      return {
        id: organizationMember.id,
        email: organizationMember.email,
        firstName: organizationMember.firstName,
        lastName: organizationMember.lastName,
        status: organizationMember.status,
      } as Member;
    });
};

export const getNewMembersList = ({
  oldMembers,
  selectedSearchMembers,
}: {
  oldMembers: TeamMember[];
  selectedSearchMembers: Member[];
}) => {
  return [
    ...oldMembers,
    ...selectedSearchMembers
      .filter((member) => !oldMembers.find((m) => m.email === member.email))
      .map((member) => {
        return {
          id: member.id,
          email: member.email,
          role: "MEMBER",
          firstName: member.firstName,
          lastName: member.lastName,
          status: member.status,
        } as TeamMember;
      }),
  ];
};

export const mapInfiniteUsers = (
  infiniteUsers?: InfiniteData<PagedResponse<UserDto>>
) => {
  if (
    !infiniteUsers ||
    !infiniteUsers.pages ||
    !Array.isArray(infiniteUsers.pages) ||
    infiniteUsers.pages.length === 0
  ) {
    return [];
  }
  const { pages } = infiniteUsers;
  return pages.reduce((result: UserDto[], page) => {
    if (page.content && Array.isArray(page.content)) {
      result.push(...page.content);
    }

    return result;
  }, []);
};
