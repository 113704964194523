import { pluralize } from "../../../utils/formatting";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

interface RulesProps {
  allRulesCount: number;
  successfulRulesCount: number;
  failedRulesCount: number;
}

const RulesComponent: React.FC<RulesProps> = ({
  allRulesCount,
  successfulRulesCount,
  failedRulesCount,
}) => {
  const successPercentage = (successfulRulesCount / allRulesCount) * 100;
  const failedPercentage = (failedRulesCount / allRulesCount) * 100;

  return (
    <div>
      <div className="flex justify-between">
        <div className="font-light text-sm">Rules</div>
        <div className="font-bold text-sm">{allRulesCount}</div>
      </div>
      <div className="flex gap-1">
        {allRulesCount === 0 ? (
          <>
            <ProgressBar statusTheme={"neutral"} percentage={100} />
          </>
        ) : (
          <>
            {successPercentage > 0 && (
              <ProgressBar
                statusTheme={"success"}
                percentage={successPercentage}
              />
            )}
            {failedPercentage > 0 && (
              <ProgressBar
                statusTheme={"danger"}
                percentage={failedPercentage}
              />
            )}
          </>
        )}
      </div>
      {(successfulRulesCount > 0 || failedRulesCount > 0) && (
        <div className="flex justify-between">
          <div className="text-green-600 font-light text-sm">
            {successfulRulesCount} Passing{" "}
            {pluralize(successfulRulesCount, "rule")}
          </div>
          <div className="text-pink-500 font-light text-sm">
            {failedRulesCount} Failing {pluralize(failedRulesCount, "rule")}
          </div>
        </div>
      )}
    </div>
  );
};

export default RulesComponent;
