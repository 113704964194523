import React from 'react';
import champion from "./champion.svg";
import styles from "./ServiceCatalogStyles.module.css";
import { Tools } from './tools/Tools';
import { Teams } from './teams/Teams';
import { AverageScore } from './average-score/AverageScore';
import { Rules } from './rules/Rules';
import { Title } from './service/Title';
import clsx from 'clsx';
import { serviceMainData, serviceScorecardEval, serviceStatsData } from './service-data/ServiceData';

const ServiceCatalog = () => {
  return (
    <div className={clsx(styles.containerRow, styles.gap)}>
      {/* left border  */}
      <div className={styles.containerRow}>
      <div className={styles.dotted}></div>
      <div className={styles.dashed}></div>
      <div className={styles.solidLeft}></div>
      </div>

      <div className={styles.serviceContainer}>
          <Title name={serviceMainData.name} tags={serviceMainData.tags}/>
          <Teams owners={serviceMainData.owners}/>
          <Tools/>
          <AverageScore score={serviceScorecardEval.evaluationResult.score} totalScore={serviceScorecardEval.evaluationResult.totalPossible} />
          <Rules passing={serviceStatsData.overview.passingRules!} totalRules = {serviceStatsData.overview.totalEvaluatedRules} />
      <img src={champion} alt="champion prize"  className={styles.champion}/>
      </div>

    {/* right border  */}
    <div className={styles.containerRow}>
      <div className={styles.solidRight}></div>
      <div className={styles.dashed}></div>
      <div className={styles.dotted}></div>
      </div>
    </div>
  )
}

export default ServiceCatalog