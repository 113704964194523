import { Text } from "_sredx/_ui";
import { ConfigParam } from "models/integration/IntegrationTemplate";
import { Control, DeepRequired, FieldErrorsImpl } from "react-hook-form";
import { FormFieldInput, FormFieldSelect } from "../Form";
import styles from "./OnboardingProviderConfigForm.module.css";

interface IntegrationConfigFormProps {
  configParams: ConfigParam[];
  control: Control;
  errors: FieldErrorsImpl<DeepRequired<any>>;
  onChange?: () => void;
}

export const IntegrationConfigFormInputs = ({
  configParams,
  control,
  errors,
  onChange,
}: IntegrationConfigFormProps) => {
  const getOptions = (configParam: ConfigParam) => {
    return Object.entries(configParam.values).map(
      ([key, value]) => ({ value: key, label: value } as any)
    );
  };

  return (
    <>
      {configParams.map((configParam, index) => {
        return (
          <div key={configParam.key} className={styles.input_wrapper}>
            <Text size="sm">{configParam.name}</Text>

            {configParam.type === "LIST" ? (
              <FormFieldSelect
                name={configParam.key}
                control={control}
                placeholder={"Select an option"}
                errorMessage={
                  errors[configParam.key]?.["message"] as unknown as string
                }
                options={getOptions(configParam)}
                variant={"light"}
                onChange={onChange}
                fullWidth
              />
            ) : (

              <FormFieldInput
              className={styles.field}
                key={index}
                control={control}
                name={configParam.key}
                fullWidth
                errorMessage={
                  errors[configParam.key]?.["message"] as unknown as string
                }
              />
            )}
          </div>
        );
      })}
    </>
  );
};
