import { useQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import {
  DomainBaseDto,
  DomainSearchCriteria,
  PagedResponse,
} from "_sredx/types";
import { ClientApi } from "_sredx/services/clientApi";

interface UseGetDomainsProps {
  params: DomainSearchCriteria;
}

export const useGetDomains = ({ params }: UseGetDomainsProps) => {
  return useQuery<PagedResponse<DomainBaseDto>>({
    queryKey: [QUERY_KEYS.domains, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getDomains(), {
        params,
      });
    },
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
