import { OnboardingPageHeader } from "_sredx/Layout/OnboardingPageHeader";
import { LayoutBody, LayoutMain, PageContent } from "_sredx/_ui";
import { AdminOnboardingContainer } from "_sredx/containers";
import { useMe } from "_sredx/services";
import { LoadingPage } from "pages/loading";
import { useNavigate } from "react-router-dom";

export const AdminOnboardingPage = () => {
  // States
  const navigate = useNavigate();

  // Services
  const { data: currentUser, isLoading, error } = useMe();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error || !currentUser) {
    return null;
  }

  if (currentUser.role !== "ADMIN" || !!currentUser.onboarded ) {
    navigate("/");
    return null;
  }

  // Derived variables
  const displayName = `${currentUser.firstName} ${currentUser.lastName}`;

  return (
    <>
      <div>
        <LayoutBody>
          <LayoutMain>
            <PageContent maxWidth={1700}>
              <OnboardingPageHeader username={displayName} />
            </PageContent>
            <PageContent maxWidth={900}>
              <AdminOnboardingContainer />
            </PageContent>
          </LayoutMain>
        </LayoutBody>
      </div>
    </>
  );
};
