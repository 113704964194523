import {forwardRef} from "react";
import {IconProps} from "./types";

export const HomeIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
          {/*<path fill={color} fillRule="evenodd" d="M21.45 10.275 12 3.187l-9.45 7.088.45 1.35h.75v8.625h16.5v-8.625H21l.45-1.35ZM5.25 18.75v-8.625L12 5.062l6.75 5.063v8.625H15v-4.417l-.75-.75h-4.5l-.75.75v4.417H5.25Zm5.25 0h3v-3.667h-3v3.667Z" clipRule="evenodd"/>*/}

          <path fill={color} fillRule="evenodd" d="M5 9.35a.65.65 0 0 1 .65.65v9c0 .193.156.35.35.35h12a.35.35 0 0 0 .35-.35v-9a.65.65 0 0 1 1.3 0v9A1.65 1.65 0 0 1 18 20.65H6A1.65 1.65 0 0 1 4.35 19v-9A.65.65 0 0 1 5 9.35Z" clipRule="evenodd"/>
          <path fill={color} fillRule="evenodd" d="M11.294 3.726a1.15 1.15 0 0 1 1.412 0l8.693 6.76a.65.65 0 1 1-.798 1.027L12 4.823l-8.601 6.69a.65.65 0 1 1-.798-1.026l8.693-6.761Z" clipRule="evenodd"/>

      </svg>
    );
  }
);
