interface PullRequestIconProps {
  size?: number;
  color?: string;
}
export const PullRequestIcon = ({
  size,
  color = "currentColor",
}: PullRequestIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M2.75 1a1.75 1.75 0 0 0-.5 3.428v3.144A1.751 1.751 0 0 0 2.75 11a1.75 1.75 0 0 0 .5-3.428V4.428A1.75 1.75 0 0 0 2.75 1ZM2 2.75a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm0 6.5a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0Zm7.25-5.4a.5.5 0 0 1 .5.5v3.223a1.75 1.75 0 1 1-1.646 3.001 1.75 1.75 0 0 1 .646-3.002V4.35a.5.5 0 0 1 .5-.5Zm0 4.65a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM7.603 1.146a.5.5 0 0 1 0 .708l-.396.396h.443a.5.5 0 0 1 0 1h-.443l.396.397a.5.5 0 0 1-.707.707l-1.25-1.25a.5.5 0 0 1 0-.708l1.25-1.25a.5.5 0 0 1 .707 0Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.347 2.265c.198.016.395.052.584.148a.5.5 0 0 1-.454.892.572.572 0 0 0-.211-.043c-.141-.012-.328-.012-.616-.012a.5.5 0 0 1 0-1h.019c.264 0 .49 0 .678.015Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.258 2.632a.5.5 0 0 1 .673-.219 1.5 1.5 0 0 1 .655.656.5.5 0 1 1-.89.454.5.5 0 0 0-.22-.219.5.5 0 0 1-.218-.672Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.914 2.85a.5.5 0 0 1 .672.219c.097.189.132.386.148.583.016.188.016.415.016.678v.02a.5.5 0 1 1-1 0c0-.288 0-.474-.012-.616a.569.569 0 0 0-.043-.211.5.5 0 0 1 .219-.673Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
