import { PropsWithChildren } from "react";
import clsx from "clsx";
import { Text } from "_sredx/_ui";
import styles from "./MenuTitle.module.css";

export const MenuTitle = ({ children }: PropsWithChildren) => {
  return (
    <li className={clsx(styles.wrapper)}>
      <Text className={clsx("menu-title")}>{children}</Text>
    </li>
  );
};
