import { Button, Heading } from "_sredx/_ui";
import styles from "./DashboardAddModal.module.css";
import { FormProvider, useForm } from "react-hook-form";
import { SCHEMA } from "./utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormFieldInput, FormFieldTextArea } from "_sredx/components/Form";

interface DashboardAddModalProps {
  onSave: (data: {
    name: string;
    description: string;
  }) => void;
  onClose: () => void;
}
export const DashboardAddModal = ({
  onSave,
  onClose,
}: DashboardAddModalProps) => {
  const methods = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: yupResolver(SCHEMA),
    reValidateMode: "onChange",
  });

  const handleSubmit = methods.handleSubmit((data) => {
    onSave(data);
  });
  return (
    <FormProvider {...methods}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Heading level={2}>Add Dashboard</Heading>
          <p>
            Create a new dashboard to organize your widgets and get insights
          </p>
        </div>
        <div className={styles.form}>
          <FormFieldInput
            control={methods.control}
            name="name"
            placeholder="Dashboard name"
            errorMessage={methods.formState.errors.name?.message}
          />
          <FormFieldTextArea
            control={methods.control}
            name="description"
            placeholder="Description of the dashboard"
            errorMessage={methods.formState.errors.description?.message}
          />
        </div>

        <div className={styles.footer}>
          <Button variant="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Save</Button>
        </div>
      </div>
    </FormProvider>
  );
};
