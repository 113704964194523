import {
  Empty,
  Loader,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  Text,
} from "_sredx/_ui";
import { ManagedResource } from "_sredx/types";
import styles from "./ClusterManagedResourcesDataTable.module.css";
import { ManagedResourcesDataTableRow } from "./ManagedResourcesDataTableRow";

interface ClusterManagedResourcesDataTableProps {
  managedResources?: ManagedResource[];
  isLoading?: boolean;
}

export const ClusterManagedResourcesDataTable = ({
  managedResources = [],
  isLoading = false,
}: ClusterManagedResourcesDataTableProps) => {
  const headerCells = ["Resource", "", "Ready", "Sync", "Age"];

  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>Loading cluster managed ressources...</Text>
      </div>
    );
  }
  if (!isLoading && managedResources && managedResources.length === 0) {
    return <Empty message={"No managed resources for cluster was found ..."} />;
  }
  return (
    <Table>
      <TableHeader>
        {headerCells.map((cell) => (
          <TableHead className={styles.header_cell}>{cell}</TableHead>
        ))}
      </TableHeader>
      <TableBody>
        {managedResources?.map((managedResource, index) => (
          <ManagedResourcesDataTableRow
            managedResource={managedResource}
            key={index}
          />
        ))}
      </TableBody>
    </Table>
  );
};
