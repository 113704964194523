import {
  IntegrationConfigurationDto,
  IntegrationInstanceDto,
} from "_sredx/types";
import IntegrationTemplate, {
  ConfigParam,
} from "models/integration/IntegrationTemplate";

const resolveConfigParams = (
  integrations: IntegrationTemplate[],
  providersKeys: string[]
) => {
  return integrations.reduce(
    (resolvedConfigParams: Record<string, ConfigParam[]>, integration) => {
      if (integration.children && integration.children.length > 0) {
        integration.children.forEach((integrationChild) => {
          const key =
            // integration.key.toLowerCase() +
            // "~" +
            integrationChild.key;
          if (providersKeys.includes(key)) {
            resolvedConfigParams[key] = [
              ...(integration.configParams ?? []),
              ...(integrationChild.configParams ?? []),
            ];
          }
        });
      } else {
        resolvedConfigParams[integration.key] =
          integration.configParams ?? [];
      }
      return resolvedConfigParams;
    },
    {}
  );
};

export const resolveIntegrationConfig = (
  integrationTemplates: IntegrationTemplate[] | undefined,
  providersKeys: string[]
) => {
  if (!integrationTemplates) return {};

  const relatedIntegrations = integrationTemplates!.filter((integration) => {
    if (integration.children && integration.children.length > 0) {
      return !!integration.children.find((child) =>
        providersKeys.includes(child.key)
      );
    }
    return providersKeys.includes(integration.key);
  });
  return resolveConfigParams(relatedIntegrations, providersKeys);
};

export const resolveIntegrationInstanceConfig = (
  integrationTemplates: IntegrationTemplate[] | undefined,
  integrationKeys: string[]
) => {
  if (!integrationTemplates) return {};
  const relatedIntegrations = integrationTemplates!.filter((integration) =>
    integrationKeys.includes(integration.key)
  );
  return resolveConfigParams(relatedIntegrations, integrationKeys);
};

export const getCurrentIntegrationInstanceConfig = (
  key: String,
  integrationsConfig?: IntegrationInstanceDto[]
) => {
  if (!integrationsConfig) return {};
  const result =
    integrationsConfig.find(
      (int: IntegrationConfigurationDto) => int.integrationKey == key
    )?.config ?? {};

  result.id =
    integrationsConfig.find(
      (int: IntegrationConfigurationDto) => int.integrationKey == key
    )?.id ?? "";

  return result;
};
