import { useMemo } from "react";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import {
  CompositeBarSegment,
  CompositeBarSegmentLine,
} from "_sredx/components/Charts";
import { PullRequestDto } from "_sredx/types";
import {
  cycleTimeValueMapper,
  getCumulativeValue,
  getCycleTimeSegments,
  getPullRequestCycleTime,
  getTimeToMergeOffset,
  getTimeToMergeWidth,
  getTimeToReviewOffset,
  getTimeToReviewWidth,
  getTotal,
} from "./utils";
import styles from "./PRCycleTimeCompositeBarChart.module.css";

const height = 160;
const barHeight = 40;
const annotationHeight = 32;
const groupBarHeight = barHeight + annotationHeight;

const margin = { top: 10, right: 60, bottom: 0, left: 0 };

interface PRCycleTimeCompositeBarChartProps {
  width: number;
  pullRequests: PullRequestDto[];
  bgColor?: string;
}

export const PRCycleTimeCompositeBarChart = ({
  width,
  pullRequests,
  bgColor = "transparent",
}: PRCycleTimeCompositeBarChartProps) => {
  const xMax = width - margin.left - margin.right;

  const cycleTime = getPullRequestCycleTime(pullRequests);
  const cycleTimeSegments = getCycleTimeSegments(cycleTime);

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        domain: [0, getTotal(cycleTimeSegments)],
        round: true,
      }),
    [xMax]
  );

  const timeToMergeOffset = xScale(getTimeToMergeOffset(cycleTime));
  const timeToMergeWidth = xScale(getTimeToMergeWidth(cycleTime));
  const timeToReviewOffset = xScale(getTimeToReviewOffset(cycleTime));
  const timeToReviewWidth = xScale(getTimeToReviewWidth(cycleTime));

  return (
    <div className={styles.wrapper}>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill={bgColor} />
        <Group top={margin.top} left={margin.left}>
          {cycleTimeSegments.map((item, index) => {
            return (
              <CompositeBarSegment
                x={xScale(getCumulativeValue(cycleTimeSegments, index))}
                y={0}
                barWidth={xScale(item.value)}
                barBgColor={item.bgColor}
                barHeight={barHeight}
                barText={cycleTimeValueMapper(item)}
                annotationHeight={annotationHeight}
                annotationStrokeWidth={1}
                annotationStrokeColor="black"
                annotationText={item.name}
                barTextColor={item.textColor}
                position={index}
                isLast={index === cycleTimeSegments.length - 1}
                isFirst={index === 0}
              />
            );
          })}
        </Group>
        <Group top={margin.top + groupBarHeight + 10} left={margin.left}>
          {timeToReviewWidth && (
            <CompositeBarSegmentLine
              x={timeToReviewOffset}
              y={0}
              label={"Time To Review"}
              height={20}
              width={timeToReviewWidth}
            />
          )}
          {timeToMergeOffset && (
            <CompositeBarSegmentLine
              x={timeToMergeOffset}
              y={0}
              height={timeToReviewWidth ? 20 * 2 : 20}
              label={"Time To Merge"}
              width={timeToMergeWidth}
            />
          )}
        </Group>
      </svg>
    </div>
  );
};
