import { InitiativeDto, RuleDto, ServiceInfoDto } from "_sredx/types";
import {
  InitiativeServiceEvaluation,
  InitiativeServiceOnRuleEvaluation,
} from "_sredx/components";

export const mapInitiativeServicesWithEvaluation = (
  initiativeDto?: InitiativeDto,
  services?: ServiceInfoDto[],
  rules?: RuleDto[]
): InitiativeServiceEvaluation[] => {
  if (!services?.length || !initiativeDto) return [];

  const data = services.map((service) => {
    const serviceEvaluationResult = initiativeDto.serviceEvaluationResults.find(
      (evaluationResult) => {
        return evaluationResult.serviceId == service.id;
      }
    );

    if (!serviceEvaluationResult)
      return {
        ...service,
        success: null,
        rules: [],
      };

    const InitiativeRules: InitiativeServiceOnRuleEvaluation[] = [];

    if (rules && rules.length) {
      const mapRules = (ruleIds: string[], success: boolean) => {
        ruleIds.forEach((ruleId) => {
          const ruleDto = rules.find((r) => r.id == ruleId);
          if (!ruleDto) return;
          InitiativeRules.push({
            ruleId,
            ruleName: ruleDto.ruleName,
            success: success,
          });
        });
      };

      mapRules(serviceEvaluationResult.passingRules, true);
      mapRules(serviceEvaluationResult.failingRules, false);
    }

    return {
      ...service,
      success: serviceEvaluationResult.success,
      rules: InitiativeRules,
    };
  });

  return data.sort((a, b) => {
    if (a.success == false || (a.success && b.success == null)) return -1;
    return 0;
  });
};
