import React, { useMemo } from "react";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import { ParentSize } from "@visx/responsive";
import { DonutData } from "_sredx/types";
import { COLORS } from "../DonutChart/constants";

interface DonutChartProps {
  data: DonutData[];
}

const LegenedChart: React.FC<DonutChartProps> = ({ data }) => {
  // Derived  variables

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: data.map((d) => d.status),
        range: COLORS,
      }),
    [data]
  );
  return (
    <ParentSize>
      {({ width, height }) => {
        return (
          <svg width={width} height={height}>
            <Group
              top={height / 2 - ((data.length - 1) * 30 + 5) / 2}
              left={40}
            >
              {data.map((datum, index) => (
                <React.Fragment key={datum.status}>
                  <line
                    x1={0}
                    y1={index * 30}
                    x2={30}
                    y2={index * 30}
                    stroke={colorScale(datum.status)}
                    strokeWidth={3}
                  />
                  <text x={40} y={index * 30 + 5} fontSize={14} fill="black">
                    {datum.count + " " + datum.status}
                  </text>
                </React.Fragment>
              ))}
            </Group>
          </svg>
        );
      }}
    </ParentSize>
  );
};
export default LegenedChart;
