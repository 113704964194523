import React, { useState, useEffect, PropsWithChildren, useRef } from 'react';
import styles from './Tooltip.module.css';

interface TooltipProps extends PropsWithChildren<any> {
  delay?: number;
  direction?: 'top' | 'right' | 'bottom' | 'left';
  content: React.ReactNode;
  variant?: 'dark' | 'primary';
  size?: 'sm' | 'md' | 'lg'; 
}

const Tooltip: React.FC<TooltipProps> = ({
  delay = 100,
  direction = 'top',
  content,
  variant = "dark",
  children,
  size = "sm",
}) => {
  const [show, setShow] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const showTooltip = () => {
    timeoutRef.current = setTimeout(() => {
      setShow(true);
    }, delay);
  };

  const hideTooltip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setShow(false);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div 
      className={styles.tooltipContainer} 
      onMouseEnter={showTooltip} 
      onMouseLeave={hideTooltip}
    >
      {children}
      {show && (
        <div className={`${styles.tooltipBubble} ${styles[direction]} ${styles[variant]} ${styles[size]}`}>
          <div className={styles.tooltipMessage}>{content}</div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
