import React, { forwardRef } from "react";
import clsx from "clsx";
import styles from "./TableBody.module.css";

export const TableBody = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => {
  return (
    <tbody
      ref={ref}
      className={clsx("nemo-table-body", styles.wrapper, className)}
      {...props}
    />
  );
});
