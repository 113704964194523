import React from 'react';
import style from "./IntegrationConfigContainer.module.css";
import { ConfigParamsWrapperSkeleton } from 'components/IntegrationsConfig/ConfigParamsComponents/ConfigParamsWrapper/ConfigParamsWrapperSkeleton';
import { ConfigParamsSkeleton } from 'components/IntegrationsConfig/ConfigParamsComponents/ConfigParamsContainer/ConfigParamsSkeleton';
import clsx from 'clsx';

export const IntegrationConfigContainerSkeleton = () => {
  return <div className={clsx(style.wrapper, style.skeleton)}>
    <ConfigParamsWrapperSkeleton>
        <ConfigParamsSkeleton/>
    </ConfigParamsWrapperSkeleton>
  </div>;
}
