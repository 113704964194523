import { PullRequestCycleTimeKey, PullRequestCycleTimeType } from "./types";
import { PullRequestDto } from "_sredx/types";
import {
  calculatePRAvgCodingTime,
  calculatePRAvgPickupTime,
  calculatePRAvgReviewTime,
  calculatePRAvgTimeToMerge,
  calculatePRAvgTimeToReview,
} from "_sredx/utils/pullRequests";

export const getTotal = (data: PullRequestCycleTimeType[]) => {
  return data.reduce((acc, item) => acc + item.value, 0);
};

export const getCumulativeValue = (
  data: PullRequestCycleTimeType[],
  index: number
) => {
  return data.slice(0, index).reduce((acc, item) => acc + item.value, 0);
};

export const CYCLE_TIME_SEGMENTS: PullRequestCycleTimeKey[] = [
  "CODING_TIME",
  "PICKUP_TIME",
  "REVIEW_TIME",
  "DEPLOY_TIME",
];

export const getTimeToReviewOffset = (
  cycleTime: PullRequestCycleTimeType[]
) => {
  return cycleTime.find((item) => item.key === "CODING_TIME")?.value || 0;
};
export const getTimeToMergeOffset = (cycleTime: PullRequestCycleTimeType[]) => {
  return cycleTime.find((item) => item.key === "CODING_TIME")?.value || 0;
};
export const getTimeToReviewWidth = (cycleTime: PullRequestCycleTimeType[]) => {
  return cycleTime.find((item) => item.key === "TIME_TO_REVIEW")?.value || 0;
};
export const getTimeToMergeWidth = (cycleTime: PullRequestCycleTimeType[]) => {
  return cycleTime.find((item) => item.key === "TIME_TO_MERGE")?.value || 0;
};

export const getCycleTimeSegments = (cycleTime: PullRequestCycleTimeType[]) => {
  return cycleTime.filter((item) =>
    CYCLE_TIME_SEGMENTS.includes(item.key as PullRequestCycleTimeKey)
  );
};

/* TODO - it should accept unit as a parameter and return the value with the unit */
export const cycleTimeValueMapper = (item: PullRequestCycleTimeType) => {
  const seconds = item.value;
  if (seconds < 60) {
    return `${Math.floor(seconds)}s`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  if (minutes < 60) {
    return `${minutes}m ${remainingSeconds ? remainingSeconds + "s" : ""}`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);
  if (hours < 24) {
    return `${hours}h ${remainingMinutes ? remainingMinutes + "m" : ""}`;
  }
  const days = Math.floor(hours / 24);
  const remainingHours = Math.floor(hours % 24);
  return `${days}d ${remainingHours ? remainingHours + "h" : ""}`;
};

export const getPullRequestCycleTime = (
  pullRequests: PullRequestDto[]
): PullRequestCycleTimeType[] => {
  return [
    {
      key: "CODING_TIME",
      name: "1st commit",
      value: calculatePRAvgCodingTime(pullRequests),
      bgColor: "#98D1F9",
      textColor: "#03001C",
    },
    {
      key: "PICKUP_TIME",
      name: "pr open",
      value: calculatePRAvgPickupTime(pullRequests),
      bgColor: "#5FBDFF",
      textColor: "#03001C",
    },
    {
      key: "REVIEW_TIME",
      name: "1st review",
      value: calculatePRAvgReviewTime(pullRequests),
      bgColor: "#8E8FFA",
      textColor: "#ffffff",
    },
    {
      key: "DEPLOY_TIME",
      name: "merge",
      value: 0,
      bgColor: "transparent",
      textColor: "transparent",
    },
    {
      key: "TIME_TO_REVIEW",
      name: "Time to review",
      value: calculatePRAvgTimeToReview(pullRequests),
      bgColor: "#FFD700",
      textColor: "#03001C",
    },
    {
      key: "TIME_TO_MERGE",
      name: "Time to merge",
      value: calculatePRAvgTimeToMerge(pullRequests),
      bgColor: "#FF8C00",
      textColor: "#03001C",
    },
  ];
};
