import { PageContent } from "_sredx/_ui";
import { InitiativeListingContainer } from "_sredx/containers";

export const InitiativeListingPage = () => {
  return (
    <PageContent>
      <InitiativeListingContainer />
    </PageContent>
  );
};
