import { ParentSize } from "@visx/responsive";
import { GroupedBarChart } from "_sredx/components";
import { useGetWidgetData } from "_sredx/services";
import { DASHBOARD_WIDGETS } from "_sredx/types/dashboards";
import { useMemo } from "react";
import { Keys, ScoreCardsHealthDto } from "./types";
import { transformScoreCardsToGroupedBarData } from "./utils";

const ScoreCardsHealthContainer = () => {
  const {
    data: scoreCardHealths,
    isLoading: _isLoading,
    isError,
  } = useGetWidgetData<{ data: ScoreCardsHealthDto[] }>({
    key: DASHBOARD_WIDGETS.SCORECARDS.SCORECARDS_HEALTH,
  });
  // accessors and filtred data
  const mappedBarChartData = useMemo(() => {
    if (!scoreCardHealths) return [];
    return transformScoreCardsToGroupedBarData(scoreCardHealths.data);
  }, [scoreCardHealths]);

  if (isError) return null;
  // loading state

  return (
    <ParentSize>
      {({ width, height }) => (
        <GroupedBarChart
          data={mappedBarChartData}
          width={width}
          height={height}
          keys={Object.values(Keys)}
        />
      )}
    </ParentSize>
  );
};
export default ScoreCardsHealthContainer;
