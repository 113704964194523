import { useMutation, useQueryClient } from "react-query";
import { ClientApi } from "../clientApi";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";

export const useCreateDashboard = () => {
  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    {
      name: string;
      description: string;
      widgets: string[];
    }
  >({
    mutationFn: (data: {
      name: string;
      description: string;
      widgets: string[];
    }) => ClientApi.post(API_ENDPOINTS.createDashboard(), data),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.me]);
    },
  });
};
