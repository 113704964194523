import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { InvitationDto } from "_sredx/types";
import { isEmpty } from "_sredx/utils";
import { useQuery } from "react-query";
import { ClientApi } from "../clientApi";

interface UseGetInvitationParams {
  token: string | null;
}

export const useGetInvitation = ({ token }: UseGetInvitationParams) => {
  return useQuery<InvitationDto>({
    queryKey: [QUERY_KEYS.invitations],
    queryFn: async () => ClientApi.get(API_ENDPOINTS.getInvitations(), {
        params: {
          token: token,
        }
    }),
    enabled: !!token && !isEmpty(token),
  });
};
