import * as yup from "yup";

// const tst = {
//     accountName: "account-saad",
//     cni: "kilo",
//     gitOpsName: "gitops-saad",
//     gitOpsTool: "argo",
//     name: "testCluster",
//     nodeType: "PRO2-S",
//     repoName: "testCluster",
//     size: 1,
//     tools: ["vault", "harbor", "cert-manager"],
//     version: "1.27.4",
//   };

export interface ClusterForm {
  accountName: string;
  cni: string;
  gitOpsName: string;
  gitOpsTool: string;
  name: string;
  nodeType: string;
  repoName: string;
  size: number;
  tools: string[];
  version: string;
}
export const schema = yup.object().shape({
  accountName: yup.string().required(),
  cni: yup.string().required(),
  gitOpsName: yup.string().required(),
  gitOpsTool: yup.string().required(),
  name: yup.string().required(),
  nodeType: yup.string().required(),
  repoName: yup.string().required(),
  size: yup.number().required(),
  tools: yup.array().required(),
  version: yup.string().required(),
});
