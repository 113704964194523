import { useQuery } from "react-query";
import { RuleDto } from "_sredx/types";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";

interface UseGetInitiativeRulesProps {
  id: string;
}

export const useGetInitiativeRules = ({ id }: UseGetInitiativeRulesProps) => {
  return useQuery<RuleDto[]>({
    queryKey: [QUERY_KEYS.initiativeRules, id],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getInitiativeRules(id));
    },
  });
};
