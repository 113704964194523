import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { useMutation, useQueryClient } from "react-query";
import { ClientApi } from "../clientApi";

export const useDeleteTeam = ({ id }: { id: string }) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationKey: [MUTATION_KEYS.deleteTeam],
      mutationFn: () => {
        return ClientApi.delete(API_ENDPOINTS.deleteTeam(id));
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QUERY_KEYS.teams], {
          exact: false,
        });
        
        await queryClient.invalidateQueries([QUERY_KEYS.users], {
          exact: false,
        });
      },
    });
  };