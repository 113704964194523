import { TagIcon } from "@heroicons/react/outline";
import { NavItem } from "components/octo-ui/NavItem/NavItem";
import React from "react";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { formatDate } from "utils/dateUtils";

interface DetailPageLayoutProps extends React.PropsWithChildren {
  title: string;
  description: string;
  tag?: string;
  rightSection: React.ReactNode;
  tabs?: String[];
  lastEvaluation?: Date;
}
export const ScorecardDetailPageLayout = ({
  children,
  title,
  lastEvaluation,
  tag,
  description,
  rightSection,
}: DetailPageLayoutProps) => {
  const tabs = [{ name: "Services", path: "#", icon: undefined }];
  return (
    <div className="flex flex-col h-[calc(100vh-109px)]">
      <div className={`bg-black pt-6`}>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-10">
          <div className="flex flex-col gap-2 md:gap-0 md:flex-row md:justify-between md:items-end text-white">
            <div className="space-y-2 flex-1">
              <div className=" flex items-center gap-4">
                <h1 className="text-3xl mb-0">{title}</h1>
                {tag && (
                  <div className="px-2 py-1 bg-slate-500 text-sm flex items-center gap-2">
                    <TagIcon className="h-4" />
                    {tag}
                  </div>
                )}
              </div>
              <p className="font-light">{description}</p>

              {lastEvaluation && (
                <p className="text-xs">
                  Last Evaluation: {formatDate(lastEvaluation)}
                </p>
              )}
            </div>
            {rightSection}
          </div>
        </div>
        <div className="container flex">
          <ul>
            {tabs.map((item) => (
              <NavItem
                key={item.name}
                current={true}
                Component={Link}
                to={item.path}
              >
                <HiOutlineSquare3Stack3D
                  className="w-5 h-5 mr-2"
                  aria-hidden="true"
                />
                {item.name}
              </NavItem>
            ))}
          </ul>
        </div>
      </div>
      {/* container taking remaining height of page */}
      <div className="container min-h-0 flex-1">{children}</div>
    </div>
  );
};


