import React from "react";
import { InitiativeCreationContainer } from "_sredx/containers";
import { Heading, PageContent } from "_sredx/_ui";
import styles from "./InitiativeCreationPage.module.css";

export const InitiativeCreationPage = () => {
  return (
    <PageContent align={"flex-start"} maxWidth={800} className={styles.wrapper}>
      <Heading>Create initiative</Heading>
      <InitiativeCreationContainer />
    </PageContent>
  );
};
