import { SortOption } from "components/basic/SortFilterComponent/SortFilterComponent";
import Owner from "models/service/Owner";
import Service from "models/service/Service";

export const SERVICE_FILTER_OPTION: SortOption[] = [
    {
      label: "Name",
      order: "asc",
    },
    {
      label: "Name",
      order: "desc",
    },
  ];

export const getTeamList = (services: Service[] = []) => {
    const teams: string[] = [];
    services.forEach((service) => {
        service.owners.forEach((owner) => {
            if(!teams.includes(owner.name)) {
                teams.push(owner.name);
            }
        })
    })
    return teams.map((teamName, index) => ({name: teamName, id: index}));
}



export const filterServiceByName = (service: Service, name: string) => {
    return name ? service.name.toLowerCase().includes(name.toLowerCase()) : true;
}

export const filterServiceByTeam = (service: Service, teams: Owner[] = []) => {
    if(teams.length === 0) {
        return true;
    }
    return service.owners.some((owner) => {
        return teams.some((selectedTeam) => selectedTeam.name === owner.name)
    })
}

export const filterServices = (services: Service[] = [], searchValue: string = '', selectedTeams: Owner[] | undefined) => {
    return services.filter((service) => {
        const shouldFilterByName = filterServiceByName(service, searchValue);
        const shouldFilterByTeam = filterServiceByTeam(service, selectedTeams);
        return shouldFilterByName && shouldFilterByTeam;
    });
}

export const sortServices = (services: Service[] = [], sortOption: SortOption | undefined) => {
    if(!sortOption) {
        return services;
    }
    const {order} = sortOption;
    return services.sort((a, b) => {
        if(order === 'asc') {
            return a.name.localeCompare(b.name);
        }
        return b.name.localeCompare(a.name);
    })
}
