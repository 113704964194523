import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useServices } from "hooks/useServices";
import Service from "models/service/Service";
import { ChangeEvent, useEffect, useState } from "react";
import { useServiceDiscovery } from "hooks/serviceDiscovery/useServiceDiscovery";
import { DiscoveredServiceCard } from "components/service/DiscoveredServiceCard";
import {
  checkIfServiceAlreadyImported,
  checkIfServiceIsSelected,
} from "./utils";
import { ServiceProviderKeyType } from "..";
import { TextInput } from "components/octo-ui/Forms/TextInput";

interface ExternalServiceFetcherProps {
  selectedServiceProvider: ServiceProviderKeyType;
  onReturn: () => void;
  selectedService: Service[];
  setSelectedService: (repos: Service[]) => void;
}

export const ExternalServiceFetcherContainer = ({
  selectedServiceProvider,
  onReturn,
  selectedService,
  setSelectedService,
}: ExternalServiceFetcherProps) => {
  // State
  const [services, setServices] = useState<Service[]>([]);

  // Services
  const { data: existingServices } = useServices();
  const {
    data: servicesFetchingResult,
    isLoading,
    isError,
  } = useServiceDiscovery({
    externalServiceProviderKey: selectedServiceProvider,
  });

  // Side effects
  useEffect(() => {
    if (servicesFetchingResult) setServices(servicesFetchingResult.data ?? []);
  }, [servicesFetchingResult]);

  // Event handlers
  const handleSearchRepos = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    if (search.length < 3)
      return setServices(servicesFetchingResult?.data ?? []);
    const filteredRepos =
      servicesFetchingResult?.data.filter((repo: Service) =>
        repo.name.toLowerCase().includes(search.toLowerCase())
      ) ?? [];
    setServices(filteredRepos);
  };

  const handleServiceCardClicked = (service: Service) => {
    if (checkIfServiceIsSelected(selectedService, service))
      return setSelectedService(
        selectedService.filter((r) => r.tag !== service.tag)
      );
    setSelectedService(
      selectedService ? [...selectedService, service] : [service]
    );
  };

  // UI Waterfall
  return (
    <div>
      <div className="mb-4">
        <button onClick={onReturn} className="flex gap-2 items-center mb-2">
          <ChevronLeftIcon className="h-4" /> Return
        </button>
        <div>
          <TextInput
            onChange={handleSearchRepos}
            placeholder="Search repositories"
          />
        </div>
      </div>

      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>Error</div>
      ) : (
        <div className="grid grid-cols-2 pr-1 gap-2 items-center justify-center">
          {services?.map((service: Service, i: number) => (
            <DiscoveredServiceCard
              key={i}
              service={service}
              alreadyImported={checkIfServiceAlreadyImported(
                service,
                existingServices ?? []
              )}
              selected={checkIfServiceIsSelected(selectedService, service)}
              onClick={() => handleServiceCardClicked(service)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
