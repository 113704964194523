import { Heading } from "_sredx/_ui";
import { ServiceDatatable } from "_sredx/components/ServicesDatatable";
import { API_PAGE_SIZE_DEFAULT } from "_sredx/constants/api";
import { useTeamContext } from "_sredx/context";
import { useGetEvaluatedServices } from "_sredx/services";
import { parsePageNumber } from "_sredx/utils";
import clsx from "clsx";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./TeamServicesContainer.module.css";

export const TeamServicesContainer = () => {
  //hooks
  const { selectedTeam } = useTeamContext();
  const team = selectedTeam ? selectedTeam.name : "";
  const [searchParams, setSearchParams] = useSearchParams();
  //hooks
  const navigate = useNavigate();
  // Derived variables

  const currentPage = parsePageNumber(searchParams.get("page"));

  const {
    data: services,
    isFetching,
    isLoading,
  } = useGetEvaluatedServices({
    params: {
      teams: [team],
      pageNumber: currentPage,
      pageSize: API_PAGE_SIZE_DEFAULT,
    },
  });
  //handlers
  const handleClickService = (serviceId: string) =>
    navigate("/services/" + serviceId);

  const handleOnPageChange = (page: number) => {
    setSearchParams({ page: (page + 1).toString() });
  };
  return (
    <div className={clsx(styles.wrapper)}>
      <Heading level={4}>My team Services</Heading>
      <ServiceDatatable
        services={services && services.content}
        isFetching={isFetching}
        isLoading={isLoading}
        onClickService={handleClickService}
        totalElements={services && services.totalElements}
        totalPages={services && services.totalPages}
        onPageChange={handleOnPageChange}
        currentPage={currentPage}
      />
    </div>
  );
};
