import { useQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";

interface UseGetWidgetDataProps {
  key: string;
  filters?: {
    [key: string]: string;
  };
  enabled?: boolean;
}

export const useGetWidgetData = <T>({
  key,
  filters,
  enabled = true,
}: UseGetWidgetDataProps) => {
  return useQuery<T>({
    queryKey: [`${QUERY_KEYS.dashboard_widget_data}.${key}`, key, filters],
    queryFn: () =>
      ClientApi.get(API_ENDPOINTS.getWidgetData(key), {
        params: {
          ...filters,
        },
      }),
    enabled: !!key && enabled,
  });
};
