import React from "react";
import Box from "../../../components/octo-ui/Box/Box";
import { OverviewCardContent } from "../overview-content/OverviewCardContent";
import { Link } from "react-router-dom";
import { useOverviewData } from "../../../hooks/dashboard/useOverview";
import { OverviewLoadingComponent } from "./overview-loading/OverviewLoadingComponent";
import style from "./OverviewComponent.module.css";
import clsx from "clsx";
import { BoxIcon, PlaylistIcon, PeopleIcon } from "_sredx/_ui/Icon";

export const OverviewComponent = () => {
   
    const {
      data: overviewData,
      isLoading: isLoadingOverviewData,
      isError 
    } = useOverviewData();

    if(isLoadingOverviewData){
      return <OverviewLoadingComponent/>
    }

    if(isError){
      return <p className="text-center text-gray-500">Something went wrong...</p>;
    }
    
    return  (
        <Box className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-2">
          <div className={clsx(style.card, style.clickable )}>
            <Link
              to="/services"
              className="focus:outline-none"
            >
              <OverviewCardContent
                name="Services"
                value={overviewData?.servicesCount ?? 0}
              >
                <BoxIcon className={style.overview_icon}/>
              </OverviewCardContent>
            </Link>
          </div>
  
          <div className={clsx(style.card, style.clickable )}>
          <Link
              to="/teams"
              className="focus:outline-none"
            >
            <OverviewCardContent
              name="Teams"
              value={overviewData?.teamsCount ?? 0}
            >
              <PeopleIcon className={style.overview_icon}/>
            </OverviewCardContent>
            </Link>
          </div>
  
          <div className={clsx(style.card, style.clickable )}>
          <Link
              to="/scorecards"
              className="focus:outline-none"
            >
            <OverviewCardContent
              name="Scorecards"
              value={overviewData?.scorecardsCount ?? 0}
            >
              <PlaylistIcon className={style.overview_icon}/>
            </OverviewCardContent>
            </Link>
          </div>
        </Box>
      );
};