import { Button, ButtonLabel, Heading, Searchbar } from "_sredx/_ui";
import { RoadmapDataTable } from "_sredx/components";
import { API_FIRST_PAGE, API_PAGE_SIZE_DEFAULT } from "_sredx/constants/api";
import { useDebounce } from "_sredx/hooks";
import { useGetRoadmaps } from "_sredx/services/roadmaps/useGetRoadmaps";
import { RoadmapSearchingCriteria } from "_sredx/types";
import { parsePageNumber } from "_sredx/utils";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./RoadmapListingContainer.module.css";

export const ROADMAP_DEFAULT_SEARCHING_CRITERIA: RoadmapSearchingCriteria = {
  pageNumber: 1,
  pageSize: API_PAGE_SIZE_DEFAULT,
};
export const RoadmapListingContainer = () => {
  //state
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  // hooks
  const navigate = useNavigate();
  const debouncedQuery = useDebounce(searchQuery, 400);

  // Derived variables
  const currentPage = parsePageNumber(searchParams.get("page"));
  //query
  const {
    data: roadmapsData,
    isLoading: isLoadingRoadmaps,
    isFetching: isFetchingRoadmaps,
  } = useGetRoadmaps({
    params: {
      ...ROADMAP_DEFAULT_SEARCHING_CRITERIA,
      name: debouncedQuery,
      pageNumber: currentPage,
    },
  });

  const roadmaps = roadmapsData ? roadmapsData.content : [];
  const totalPages = roadmapsData ? roadmapsData.totalPages : 0;
  const totalElements = roadmapsData ? roadmapsData.totalElements : 0;
  // event handlers
  const handleOnSearch = (value: string) => {
    setSearchParams({ page: API_FIRST_PAGE.toString() });
    setSearchQuery(value);
  };
  const handleCreateRoadMap = () => {
    navigate("/roadmaps/create");
  };
  const handleOnPageChange = (page: number) => {
    setSearchParams({ page: (page + 1).toString() });
  };
  const handleOnRowClick = (roadmapId: string) => {
    navigate(`${roadmapId}`);
  };

  return (
    <div className={styles.wrapper}>
      {/*  Header */}
      <div className={styles.header_wrapper}>
        <div>
          <Heading>Roadmaps</Heading>
          <p>
            navigate and understand the planned sequence of initiatives. Stay on
            track and visualize your tech strategy effortlessly with Roadmaps.
          </p>
        </div>
        <div>
          <Button onClick={handleCreateRoadMap}>
            <ButtonLabel>Create roadmap</ButtonLabel>
          </Button>
        </div>
      </div>
      <div className={styles.filters_wrapper}>
        <div className={styles.search_filters_wrapper}>
          <Searchbar
            value={searchQuery}
            onChange={handleOnSearch}
            placeholder={"Search roadmaps by name"}
          />
        </div>
      </div>
      <div className={styles.table_wrapper}>
        <RoadmapDataTable
          isLoading={isLoadingRoadmaps}
          isFetching={isFetchingRoadmaps}
          roadmaps={roadmaps}
          totalPages={totalPages}
          totalElements={totalElements}
          onAddRoadmapClick={handleCreateRoadMap}
          onPageChange={handleOnPageChange}
          currentPage={currentPage}
          onRowClick={handleOnRowClick}
        />
      </div>
    </div>
  );
};
