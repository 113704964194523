import React, { FC } from "react";
import { ServiceOverviewData } from "../../../../../models/evaluations/ServiceStatsByScoreCard";
import { getOrdinalSuffixOfPosition } from "../../../../../utils/formating";

interface ServiceOverviewPerScoreCardComponentProps {
  overViewData?: ServiceOverviewData;
}

const ServiceOverviewPerScoreCardComponent: FC<
  ServiceOverviewPerScoreCardComponentProps
> = ({ overViewData }) => {
  return (
    <div id="service-scorecard-overview-container" className={"space-y-4"}>
      <div className="mx-auto p-4 sm:px-6 lg:px-8">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          Service Overview
        </h2>
        <div
          className={`mt-2 grid grid-cols-1 gap-5 sm:grid-cols-1 ${
            overViewData?.rankOnScoreCard ? "lg:grid-cols-3" : "lg:grid-cols-2"
          }`}
        >
          {overViewData?.rankOnScoreCard && (
            <ServiceScorecardOverviewCardComponent
              id={"service-scorecard-rank"}
              mainText={getOrdinalSuffixOfPosition(
                overViewData?.rankOnScoreCard!
              )}
              subText={"Rank Based on this scorecard."}
            />
          )}
          <ServiceScorecardOverviewCardComponent
            id={"service-scorecard-score"}
            mainText={`${overViewData?.scoreOnScoreCard}%`}
            subText={"Score Based on this scorecard."}
          />
          <ServiceScorecardOverviewCardComponent
            id="service-scorecard-passing-rules"
            mainText={`${overViewData?.passingRules} / ${overViewData?.totalEvaluatedRules}`}
            subText={"Passing rules."}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceOverviewPerScoreCardComponent;

const ServiceScorecardOverviewCardComponent = ({
  id,
  mainText,
  subText,
}: {
  id: string;
  mainText: string;
  subText: string;
}) => {
  return (
    <div
      id={id}
      className="bg-white hover:translate-x-[2px] hover:translate-y-[-2px] hover:shadow-br transition overflow-hidden border border-black flex flex-col justify-center p-5 "
    >
      <div className="text-center text-xl font-bold text-gray-900">
        {mainText}
      </div>
      <div className="text-center text-sm font-medium text-gray-500">
        {subText}
      </div>
    </div>
  );
};
// cursor: pointer;
// transition: all 0.2s ease-in-out;

// &:hover {
//   transform: translate(2px, -2px);
//   box-shadow: -4px 4px 0 black;
// }