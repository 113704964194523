import { useGetWidgetData } from "_sredx/services";
import { DASHBOARD_WIDGETS, TeamsPerformancesDto } from "_sredx/types";
import { TeamsPerformanceList } from "_sredx/components";

export const BestPerformingTeamsContainer = () => {
  const { data, isLoading } = useGetWidgetData<TeamsPerformancesDto>({
    key: DASHBOARD_WIDGETS.TEAMS.BEST_PERFORMING_TEAMS,
  });

  if (!data) return null;

  return (
    <TeamsPerformanceList
      teamsPerformances={data.teamsPerformances}
      isLoading={isLoading}
    />
  );
};
