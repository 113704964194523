import { forwardRef } from "react";
import { IconProps } from "./types";

export const OpstraceIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (

    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32.2 44"
    ref={ref}
    {...props}
  >
    <title>{title}</title>
    <style type="text/css">
      {
        "\n  .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#F54773;}\n\t.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#091570;}\n\t.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}\n\t.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#E6E6E6;}\n\t.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#D42D57;}\n "
      }
    </style>
    <g>
      <path
        className="st0"
        d="M16.2,31.1c0,0,0.1,0,0.4,0l0.2,9.4c0,1.5,1.3,2.6,2.7,2.6c1.5,0,2.6-1.3,2.6-2.7L22,31v0l0-0.6 c0.5-0.2,0.9-0.3,1.4-0.5c0.3,3.1,0.4,6.3,0.2,7.8c-0.2,1.5,0.9,2.8,2.4,3c1.5,0.2,2.8-0.9,3-2.4c0.6-5.4-1.5-9.9,0.4-14.4 c1.2-2.9,2-5.9,2-9c0-2-0.5-4-1.4-5.7c-2.6-5.1-8.2-8-13.9-8c-5.7,0-11.2,2.9-13.9,8c-0.9,1.8-1.4,3.7-1.4,5.7c0,3.1,0.8,6.2,2,9 c1.9,4.5-0.2,9,0.4,14.4c0.2,1.5,1.5,2.5,3,2.4c1.5-0.2,2.5-1.5,2.4-3c-0.2-1.5,0-4.7,0.2-7.8c0.5,0.2,0.9,0.4,1.4,0.5l0,0.7v0 L10,40.4c0,1.5,1.1,2.7,2.6,2.7c1.5,0,2.7-1.1,2.7-2.6l0.2-9.4C16,31.1,16.2,31.1,16.2,31.1z"
      />
      <path
        className="st1"
        d="M16.2,31.1c0,0,0.2,0,0.4,0l0.2,9.4c0,1.5,1.3,2.6,2.7,2.6c1.5,0,2.6-1.3,2.6-2.7L22,30.3 c0.5-0.2,0.9-0.3,1.4-0.5c0.3,3.1,0.4,6.3,0.2,7.8c-0.2,1.5,0.9,2.8,2.4,3c1.5,0.2,2.8-0.9,3-2.4c0.6-5.4-1.5-9.9,0.4-14.4 c1.2-2.9,2-5.9,2-9c0-2-0.5-4-1.4-5.7c-2.6-5.1-8.2-8-13.9-8c-5.7,0-11.2,2.9-13.9,8c-0.9,1.8-1.4,3.7-1.4,5.7c0,3.1,0.8,6.2,2,9 c1.9,4.5-0.2,9,0.4,14.4c0.2,1.5,1.5,2.5,3,2.4c1.5-0.2,2.5-1.5,2.4-3c-0.2-1.5,0-4.7,0.2-7.8c0.5,0.2,0.9,0.4,1.4,0.5L10,40.4 c0,1.5,1.1,2.7,2.6,2.7c1.5,0,2.7-1.1,2.7-2.6l0.2-9.4C16,31.1,16.2,31.1,16.2,31.1z M17.1,43c-0.5-0.5-0.9-1.1-1-1.8 c-0.2,0.7-0.5,1.3-1,1.8c-0.6,0.6-1.5,1-2.5,1l-0.1,0v0h0c-1,0-1.9-0.4-2.5-1.1c-0.6-0.6-1-1.5-1-2.5l0-0.1h0l0-1 c-0.1,0.2-0.3,0.5-0.4,0.7c-0.6,0.7-1.4,1.2-2.3,1.3c0,0-0.1,0-0.1,0c-1,0.1-1.9-0.2-2.5-0.8C3,40,2.5,39.2,2.4,38.3 c0,0,0-0.1,0-0.1c-0.2-2.3,0-4.3,0.2-6.3C2.8,29.1,3.1,26.5,2,24c-0.6-1.5-1.2-3.1-1.5-4.8C0.2,17.8,0,16.2,0,14.7 c0-1.1,0.1-2.1,0.4-3.1c0.2-1,0.6-2,1.1-3c1.4-2.7,3.5-4.8,5.9-6.2C10,0.8,13.1,0,16.1,0c3.1,0,6.1,0.8,8.7,2.3 c2.5,1.4,4.6,3.5,5.9,6.2c0.5,1,0.9,2,1.1,3c0.2,1,0.4,2.1,0.4,3.1c0,1.6-0.2,3.1-0.5,4.6c-0.4,1.6-0.9,3.2-1.5,4.8 c-1,2.5-0.8,5.1-0.5,7.8c0.2,2,0.4,4.1,0.2,6.4c-0.1,1-0.6,1.8-1.3,2.4c-0.7,0.6-1.6,0.9-2.6,0.8c-1-0.1-1.8-0.6-2.4-1.3 c-0.2-0.2-0.3-0.4-0.4-0.7l0,0.9c0,0,0,0.1,0,0.1h0c0,1-0.4,1.9-1,2.5c-0.6,0.6-1.4,1.1-2.4,1.1c0,0-0.1,0-0.1,0v0 C18.7,44,17.8,43.6,17.1,43z"
      />
      <path
        className="st1"
        d="M13.5,23.3h5.2c0.3,0,0.5,0.2,0.5,0.5c0,0.8-0.3,1.6-0.9,2.2l0,0c-0.6,0.6-1.3,0.9-2.2,0.9 c-0.8,0-1.6-0.3-2.2-0.9l0,0c-0.6-0.6-0.9-1.3-0.9-2.2C13,23.5,13.2,23.3,13.5,23.3z"
      />
      <path
        className="st2"
        d="M16.1,22c4.5,0,8.2-3.7,8.2-8.2c0-4.5-3.7-8.2-8.2-8.2c-4.5,0-8.2,3.7-8.2,8.2C7.9,18.3,11.6,22,16.1,22z"
      />
      <path
        className="st1"
        d="M16.1,16.9c1.8,0,3.2-1.4,3.2-3.2c0-1.8-1.4-3.2-3.2-3.2c-1.8,0-3.2,1.4-3.2,3.2 C12.9,15.5,14.4,16.9,16.1,16.9z M15.5,14.7c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5 C13.9,14,14.6,14.7,15.5,14.7z"
      />
      <path
        className="st3"
        d="M20.8,7c-1.3-0.9-2.9-1.5-4.7-1.5c-4.5,0-8.2,3.7-8.2,8.2c0,3.7,2.5,6.9,6,7.9c-2.2-1.5-3.6-4-3.6-6.8 c0-4.5,3.7-8.2,8.2-8.2C19.3,6.6,20.1,6.8,20.8,7z"
      />
      <path
        className="st4"
        d="M23.9,2.8c-2.4-1.3-5-1.9-7.7-1.9c-5.7,0-11.2,2.9-13.9,8c-0.9,1.8-1.4,3.7-1.4,5.7c0,3.1,0.8,6.2,2,9 c1.9,4.5-0.2,9,0.4,14.4c0.2,1.5,1.5,2.5,3,2.4l0,0c-0.3-0.4-0.5-0.8-0.5-1.3c-0.6-5.4,1.5-9.9-0.4-14.4c-1.2-2.9-2-5.9-2-9 c0-2,0.5-4,1.4-5.7c2.6-5.1,8.2-8,13.9-8C20.4,1.9,22.2,2.2,23.9,2.8z"
      />
      <path
        className="st1"
        d="M16.1,21.6c2.1,0,4.1-0.9,5.5-2.3c1.4-1.4,2.3-3.4,2.3-5.5c0-2.1-0.9-4.1-2.3-5.5c-1.4-1.4-3.4-2.3-5.5-2.3 c-2.1,0-4.1,0.9-5.5,2.3c-1.4,1.4-2.3,3.4-2.3,5.5c0,2.1,0.9,4.1,2.3,5.5C12,20.7,14,21.6,16.1,21.6z M22.3,19.9 c-1.6,1.6-3.7,2.6-6.1,2.6s-4.6-1-6.1-2.5c-1.6-1.6-2.6-3.7-2.6-6.1s1-4.6,2.6-6.1c1.6-1.6,3.7-2.5,6.1-2.5c2.4,0,4.6,1,6.1,2.5 c1.6,1.6,2.5,3.7,2.5,6.1C24.8,16.1,23.8,18.3,22.3,19.9z"
      />
      <path
        className="st4"
        d="M16.1,25.9c0.3,0,0.7-0.1,0.9-0.2c-0.2-0.6-0.5-1-1-1.4H14c0.1,0.4,0.3,0.7,0.6,1C15,25.7,15.5,25.9,16.1,25.9 z"
      />
    </g>
  </svg>
    );
  }
);




