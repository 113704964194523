import { useQuery } from "react-query";
import { ServiceInfoDto } from "_sredx/types";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";

interface UseGetInitiativeServicesProps {
  id: string;
}

export const useGetInitiativeServices = ({
  id,
}: UseGetInitiativeServicesProps) => {
  return useQuery<ServiceInfoDto[]>({
    queryKey: [QUERY_KEYS.initiativeServices, id],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getInitiativeServices(id));
    },
  });
};
