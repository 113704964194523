export const OnBoardingSteps = {
  WELCOME_STEP: "WELCOME_STEP",
  LOADING_STEP: "LOADING_STEP",
  PROFILE_STEP: "PROFILE_STEP",
};
export type OnBoardingStepsType =
  (typeof OnBoardingSteps)[keyof typeof OnBoardingSteps];

export interface OnBoardingStep {
  currentStep: OnBoardingStepsType;
  onNextStep?: () => void;
}
