export const PROpenIcon = ({
  size = 24,
  color = "currentColor",
}: {
  size: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.5 2a3.5 3.5 0 0 0-1 6.855v6.29A3.502 3.502 0 0 0 5.5 22a3.5 3.5 0 0 0 1-6.855v-6.29A3.502 3.502 0 0 0 5.5 2ZM4 5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 13a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM18.5 7.7a1 1 0 0 1 1 1v6.445a3.502 3.502 0 0 1-1 6.855 3.5 3.5 0 0 1-1-6.855V8.7a1 1 0 0 1 1-1Zm0 9.3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM15.207 2.293a1 1 0 0 1 0 1.414l-.793.793h.886a1 1 0 1 1 0 2h-.886l.793.793a1 1 0 0 1-1.414 1.414l-2.5-2.5a1 1 0 0 1 0-1.414l2.5-2.5a1 1 0 0 1 1.414 0Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M16.695 4.53c.395.033.789.104 1.167.297a1 1 0 0 1-.908 1.782c-.05-.025-.15-.063-.422-.085C16.25 6.5 15.877 6.5 15.3 6.5a1 1 0 1 1 0-2h.038c.528 0 .982 0 1.357.03Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M16.517 5.264a1 1 0 0 1 1.345-.437 3 3 0 0 1 1.311 1.311 1 1 0 1 1-1.782.908 1 1 0 0 0-.437-.437 1 1 0 0 1-.437-1.345Z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M17.828 5.701a1 1 0 0 1 1.345.437c.193.378.264.772.296 1.167.031.375.031.83.031 1.356V8.7a1 1 0 1 1-2 0c0-.577 0-.949-.024-1.232-.022-.272-.06-.373-.085-.422a1 1 0 0 1 .437-1.345Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
