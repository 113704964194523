import { PiCards, PiTable } from "react-icons/pi";
import { Button } from "../Button";

interface ViewTogglerProps {
  onToggle: () => void;
  value: "card" | "datatable";
}
export const ViewToggler = ({ value, onToggle }: ViewTogglerProps) => {
  return (
    <div>
      <Button
        variant={value == "card" ? "primary" : "neutral"}
        onClick={onToggle}
      >
        <PiCards />
      </Button>
      <Button
        variant={value == "datatable" ? "primary" : "neutral"}
        onClick={onToggle}
      >
        <PiTable />
      </Button>
    </div>
  );
};
