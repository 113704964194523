import clsx from "clsx";
import { ProgressBar, Text } from "_sredx/_ui";
import { ServicePerformance } from "_sredx/types";

import styles from "./ServicesPerformanceList.module.css";

interface ServicesPerformanceListProps {
  servicesPerformances?: ServicePerformance[];
  isLoading?: boolean;
}

export const ServicesPerformanceList = ({
  servicesPerformances = [],
  isLoading = true,
}: ServicesPerformanceListProps) => {
  if (isLoading) {
    return null;
  }
  return (
    <div className={clsx(styles.wrapper)}>
      {servicesPerformances.slice(0, 5).map((service) => {
        return (
          <div key={service.serviceId} className={styles.service_row}>
            <Text className={styles.service_name}>{service.serviceName}</Text>
            <Text size={"sm"}>{service.score} Pts</Text>
            <div className={styles.progress_wrapper}>
              <ProgressBar
                label={`${service.scorePercentage.toFixed(1)}%`}
                labelPosition={"right"}
                infinite={false}
                percent={service.scorePercentage}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
