import {useScoreCards} from "../../../../hooks/scorcards/useScoreCards";
import React, {FC} from "react";
import SelectSearchInput from "../../../../components/basic/SelectSearchInput/SelectSearchInput";
import {ChipIcon} from "@heroicons/react/solid";

interface ScorecardsFilterProps {
    onChange: (value: any[]) => void;
}

const ScorecardsFilterComponent : FC<ScorecardsFilterProps> = ({onChange}) => {
    const {isLoading, isSuccess, data: scorecards} = useScoreCards(false);

    if(isLoading) return <p className="text-center text-gray-500">Loading...</p>;

    let view;

    if(isSuccess){
        if(!scorecards || scorecards.length === 0) {
            view = <p className="text-center text-gray-500">No scores available for the moment</p>
        }
        else {
            view = <SelectSearchInput
                Icon={ChipIcon}
                componentName={"Scorecards"}
                options={scorecards as any}
                onChange={onChange} />
        }
    }
    else {
        view = (
            <p className="text-center text-gray-500">
                Something went wrong...
            </p>
        );
    }

    return view;
}

export default ScorecardsFilterComponent;