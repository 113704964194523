import styles from "./InfiniteScrollList.module.css";
import { Loader } from "_sredx/_ui";
import { ServiceTeamType } from "_sredx/containers/DashboardContainer/ServiceTeamListSelectorContainer";
import { ServiceBaseDto, TeamBaseDto } from "_sredx/types";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";

interface InfiniteScrollListProps {
  items: ServiceBaseDto[] | TeamBaseDto[];
  selectedValue?: ServiceTeamType;
  onItemClick?: (item: any) => void;
  loadingCaption?: string;
  isLoading?: boolean;
  isFetching?: boolean;
  loadMore?: () => void;
}

export const InfiniteScrollList = ({
  items,
  selectedValue,
  onItemClick,
  loadingCaption,
  isLoading,
  isFetching,
  loadMore,
}: InfiniteScrollListProps) => {
  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (inView && loadMore) {
        loadMore();
      }
    },
  });

  return (
    <div className={styles.wrapper}>
      {items.map((item) => (
        <InfiniteScrollItem
          key={item.id}
          item={item}
          selectedValue={selectedValue}
          onItemClick={onItemClick}
        />
        // <div
        //   key={item.id}
        //   onClick={onItemClick}
        //   className={clsx(
        //     styles.item,
        //     selectedValue?.id == item.id ? styles.selected : null
        //   )}
        // >
        //   {item.name}
        // </div>
      ))}
      {isFetching && (
        <div>
          <Loader size={"small"} />
        </div>
      )}
      <div ref={ref}></div>
    </div>
  );
};

interface InfiniteScrollItemProps {
  item: { id?: string; name?: string };
  selectedValue?: { id?: string; name?: string };
  onItemClick?: (item: any) => void;
}

const InfiniteScrollItem = ({
  item,
  selectedValue,
  onItemClick,
}: InfiniteScrollItemProps) => {
  const handleClickItem = () => {
    onItemClick?.(item);
  };

  return (
    <div
      onClick={handleClickItem}
      className={clsx(
        styles.item,
        selectedValue?.id == item?.id ? styles.selected : null
      )}
    >
      {item.name}
    </div>
  );
};
