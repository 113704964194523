export const PR_CYCLE_TIME_TABS = {
  PULL_REQUESTS: "PULL_REQUESTS",
  CYCLE_TIME_BREAKDOWN: "CYCLE_TIME_BREAKDOWN",
  TIME_TO_REVIEW: "TIME_TO_REVIEW",
  TIME_TO_MERGE: "TIME_TO_MERGE",
} as const;

export type PrCycleTimeTabType =
  (typeof PR_CYCLE_TIME_TABS)[keyof typeof PR_CYCLE_TIME_TABS];

export const PR_COLORS = [
  "#8c61ff",
  "#44c2fd",
  "#553772",
  "#58b368",
  "#3e4491",
  "#8a00d4",
  "#679186",
  "#95adbe",
  "#c7417b",
  "#5f59f7",
  "#facd60",
  "#bae8e8",
  "#54d2d2",
  "#6592fd",
  "#f9b4ab",
  "#f782c2",
  "#edd2cb",
  "#454d66",
  "#bbd4ce",
  "#2c698d",
  "#58b368",
  "#dad873",
  "#e74645",
];
