import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { TeamHeaderCreationDto } from "_sredx/types";
import { ClientApi } from "_sredx/services/clientApi";

export const useUpdateTeam = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.deleteTeam],
    mutationFn: (teamCreationDto: TeamHeaderCreationDto) => {
      return ClientApi.post(API_ENDPOINTS.updateTeam(id), teamCreationDto);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.teams], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.users], {
        exact: false,
      });
    },
  });
};
