import React from 'react'

const ServiceRowSkeleton = () => {
  return (
      <div className="flex mb-2 items-center justify-between animate-pulse">
      <div className="flex gap-2">
        {/* Chess Skeleton */}
        <div className="bg-gray-200 h-16 w-16"></div>

        <div className="flex flex-col justify-between my-1">
          {/* Service Name Skeleton */}
          <div className="bg-gray-200 h-4 w-64 mb-2"></div>

          {/* Teams Skeleton */}
          <div className="flex items-center gap-2">
            <div className="bg-gray-200 h-6 w-6"></div>
            <div className="bg-gray-200 h-6 w-6"></div>
            <div className="bg-gray-200 h-6 w-6"></div>
            <div className="bg-gray-200 h-6 w-6"></div>
          </div>
        </div>
      </div>

      {/* Right Skeleton */}
      <div className="w-1/4">
        <div className="flex items-center border px-3 justify-between">
          <div className="bg-gray-200 h-4 w-24"></div>
          <div className="bg-gray-200 h-4 w-8 ml-2"></div>
          <div className="text-gray-300 mx-2">|</div>
          <div className="bg-gray-200 h-4 w-16"></div>
        </div>
      </div>
    </div>

  )
}

export default ServiceRowSkeleton