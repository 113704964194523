import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ClusterForm, schema } from "./utils";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ClusterFoundationsForm,
  ClusterInfoForm,
} from "components/self-service/ClusterCreate";
import { useCreateCluster } from "services/self-service/useCreateCluster";
import { useCloudAccounts } from "services/self-service/useCloudAccounts";
import { useGitopsAccounts } from "services/self-service/useGitopsAccounts";
import { LoadingOverlay } from "components/octo-ui/LoadingOverlay/LoadingOverlay";
import { useToaster } from "hooks/useToaster";
import { useCreateClusterConfig } from "services/self-service/useCreateClusterConfig";
import { CloudAccountDto, GitopsAccountDto } from "types/selfService";
import { Button } from "components/octo-ui/Button/Button";

const formSteps = {
  CLUSTER_INFO: "CLUSTER_INFO",
  FOUNDATIONS: "FOUNDATIONS",
};

interface ClusterCreateContainerProps {
  onCancel: () => void;
}
export const ClusterCreateContainer = ({
  onCancel,
}: ClusterCreateContainerProps) => {
  const [formStep, setFormStep] = useState(formSteps.CLUSTER_INFO);
  const { mutate, isLoading } = useCreateCluster();
  const { addToast } = useToaster();
  const { data: accounts = [] } = useCloudAccounts();
  const { data: gitops = [] } = useGitopsAccounts();
  const { data: options } = useCreateClusterConfig();

  const runningCloudAccounts = accounts.filter(
    (account: CloudAccountDto) => account.phase === "RUNNING"
  );
  const runningGitopsAccounts = gitops.filter(
    (account: GitopsAccountDto) => account.phase === "RUNNING"
  );

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleStepToggle = () => {
    if (formStep === formSteps.CLUSTER_INFO) {
      setFormStep(formSteps.FOUNDATIONS);
    }
    if (formStep === formSteps.FOUNDATIONS) {
      setFormStep(formSteps.CLUSTER_INFO);
    }
  };

  const handleClickCreate = () => {
    const createCluster = (data: ClusterForm) => {
      mutate(data, {
        onSuccess: () => {
          addToast({
            message: `Cluster ${data.name} created successfully.`,
            type: "success",
          });
          onCancel();
        },
        onError: (error: any) => {
          addToast({
            message: `Error occured while creating cluster ${data.name}. ${error.message}`,
            type: "error",
          });
        },
      });
    };
    methods.handleSubmit((data) => createCluster(data))();

    if (!methods.formState.isValid) {
      setFormStep(formSteps.CLUSTER_INFO);
      return;
    }
  };

  const renderFormActions = () => {
    return (
      <div className="flex justify-end gap-4 mt-6">
        {formStep === formSteps.CLUSTER_INFO && (
          <>
            <button onClick={onCancel}>Cancel</button>
            <Button variant="primaryDark" onClick={handleStepToggle}>
              Next
            </Button>
          </>
        )}
        {formStep === formSteps.FOUNDATIONS && (
          <>
            <button className="btn btn-secondary" onClick={handleStepToggle}>
              Back
            </button>
            <Button variant="primaryDark" onClick={handleClickCreate}>
              Confirm
            </Button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="relative">
      {isLoading && <LoadingOverlay message="Creating Cluster" />}
      <h1>Create new cluster</h1>
      <FormProvider {...methods}>
        <ClusterInfoForm
          cloudAccounts={runningCloudAccounts}
          gitopsAccounts={runningGitopsAccounts}
          k8sVersions={options?.scalewayK8sVersion ?? []}
          nodeTypes={options?.scalewayNodeTypes ?? []}
          poolSizeMax={options?.scalewayPoolSizeMax ?? 3}
          display={formStep === formSteps.CLUSTER_INFO}
          errors={methods.formState.errors}
        />
        <ClusterFoundationsForm display={formStep === formSteps.FOUNDATIONS} />
      </FormProvider>
      {renderFormActions()}
    </div>
  );
};
