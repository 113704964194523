import { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./HeaderActions.module.css";

interface HeaderActionProps {
  onClick: () => void;
}

export const HeaderAction = ({
  children,
  onClick
}: PropsWithChildren<HeaderActionProps>) => {
  return (
    <button
      className={clsx("nemo-header-action", styles.action_item)}
      type={"button"}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
