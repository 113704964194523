import React, { useMemo, useState } from "react";
import {
  SERVICE_PROVIDERS,
  ServiceProviderKeyType,
} from "../../../components/service/ExternalServiceList/utils";
import { ExternalServiceFetcherContainer } from "./ExternalServices";
import { ExternalServicesList } from "../../../components/service/ExternalServiceList";
import Service from "models/service/Service";
import { ServiceProvider } from "types/discovery";
import { ServiceAddBody } from "../../../components/service/ServiceAddBody";
import { useGetIntegrationsStatus } from "_sredx/services/integrations";
import { IntegrationStatus } from "_sredx/types";

interface ServiceImportTypeStepProps {
  skipLabel: string;
  onNextStep: (services: Service[]) => void;
  onSkip: () => void;
}

export const ExternalServiceProviderStepContainer = ({
  skipLabel,
  onNextStep,
  onSkip,
}: ServiceImportTypeStepProps) => {
  // State
  const [subStep, setSubStep] = React.useState<"connect" | "fetch">("connect");
  const [selectedServiceProvider, setSelectedServiceProvider] =
    useState<ServiceProviderKeyType>();
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);

  // Services
  const { data: integrationsStatus } = useGetIntegrationsStatus();

  // Derived variables
  const serviceProviders = useMemo(
    () =>
      SERVICE_PROVIDERS.map((serviceProvider: ServiceProvider) => {
        if (integrationsStatus) {
          const integrationStatus = integrationsStatus.find(
            (integrationStatus: IntegrationStatus) =>
              integrationStatus.key === serviceProvider.key
          );
          if (!serviceProvider.configured) {
            serviceProvider.configured = !!integrationStatus?.configured;
          }
        }
        return serviceProvider;
      }),
    [integrationsStatus]
  );

  // Event handlers
  const handleNext = () => {
    if (!!selectedServiceProvider && selectedServices.length)
      return onNextStep(selectedServices);
    if (!selectedServiceProvider) return;
    setSubStep("fetch");
  };

  const Actions = ({ nextAllowed }: { nextAllowed?: boolean }) => {
    return (
      <div>
        <button
          className="text-black transition underline hover:text-cyan-500 font-bold py-2 px-4 rounded"
          onClick={onSkip}
        >
          {skipLabel}
        </button>
        <button
          onClick={handleNext}
          disabled={!nextAllowed}
          className={`text-white font-bold py-2 px-8  ${
            nextAllowed
              ? "cursor:pointer bg-black transition hover:bg-cyan-500 "
              : "cursor:not-allowed bg-gray-300"
          } `}
        >
          Next
        </button>
      </div>
    );
  };
  const handleSelectServiceProvider = (serviceProvider: ServiceProvider) => {
    if (!serviceProvider.supported || !serviceProvider.configured) return;
    if (selectedServiceProvider === serviceProvider.key)
      return setSelectedServiceProvider(undefined);
    setSelectedServiceProvider(serviceProvider.key);
  };

  return (
    <ServiceAddBody
      actions={
        <Actions
          nextAllowed={
            (subStep === "connect" &&
              !!SERVICE_PROVIDERS.find(
                (s: ServiceProvider) =>
                  s.configured && s.key === selectedServiceProvider
              )) ||
            (subStep === "fetch" &&
              !!selectedServices &&
              !!selectedServices.length)
          }
        />
      }
    >
      {subStep === "connect" && (
        <ExternalServicesList
          selectedServiceProvider={selectedServiceProvider}
          serviceProviders={serviceProviders}
          handleSelectServiceProvider={handleSelectServiceProvider}
        />
      )}
      {subStep === "fetch" && (
        <ExternalServiceFetcherContainer
          selectedService={selectedServices}
          setSelectedService={(services: Service[]) =>
            setSelectedServices(services ?? [])
          }
          selectedServiceProvider={selectedServiceProvider ?? ""}
          onReturn={() => {
            setSelectedServices([]);
            setSubStep("connect");
          }}
        />
      )}
    </ServiceAddBody>
  );
};
