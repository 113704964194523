import clsx from "clsx";
import { ProgressBar, Text } from "_sredx/_ui";
import { TeamPerformance } from "_sredx/types";

import styles from "./TeamsPerformanceList.module.css";

interface ServicesPerformanceListProps {
  teamsPerformances?: TeamPerformance[];
  isLoading?: boolean;
}

export const TeamsPerformanceList = ({
  teamsPerformances = [],
  isLoading = true,
}: ServicesPerformanceListProps) => {
  if (isLoading) {
    return null;
  }
  return (
    <div className={clsx(styles.wrapper)}>
      {teamsPerformances.slice(0, 5).map((team) => {
        return (
          <div key={team.teamId} className={styles.team_row}>
            <Text className={styles.team_name}>{team.teamName}</Text>
            {/*<Text size={"sm"}>{team.score} Pts</Text>*/}
            <div className={styles.progress_wrapper}>
              <ProgressBar
                label={`${team.scorePercentage.toFixed(1)}%`}
                labelPosition={"right"}
                infinite={false}
                percent={team.scorePercentage}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
