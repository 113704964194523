import { PageContent } from "_sredx/_ui";
import styles from "./RoadmapDetailPage.module.css";
import { RoadmapDetailContainer } from "_sredx/containers/RoadmapDetailContainer";

export const RoadmapDetailPage = () => {
  return (
    <PageContent noPadding className={styles.wrapper}>
      <RoadmapDetailContainer />
    </PageContent>
  );
};
