import {
  Checkbox,
  Empty,
  Loader,
  Pagination,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_sredx/_ui";
import { ServiceBaseDto } from "_sredx/types";
import { ServicesAssignmentTableRows } from "./ServicesAssignmentTableRows";
import styles from "./ServicesAssignementTable.module.css";
import { ProgressBar } from "_sredx/_ui/ProgressBar/ProgressBar";
import { useEffect, useRef } from "react";

interface ServicesAssignmentTableProps {
  services: ServiceBaseDto[];
  checkedServices: string[];
  isLoading?: boolean;
  isFetching?: boolean;
  currentPage?: number;
  totalPages?: number;
  totalServices?: number;
  onPageChange?: (page: number) => void;
  onCheckChange: (checked: boolean, serviceId: string) => void;
  onCheckAll: (checkAll: boolean) => void;
}

export const ServicesAssignmentTable = ({
  services,
  checkedServices,
  onCheckChange,
  isLoading,
  isFetching,
  currentPage = 0,
  totalPages = 1,
  totalServices = 0,
  onPageChange,
  onCheckAll,
}: ServicesAssignmentTableProps) => {
  // Derived Variables
  const showPagination = totalPages > 1;
  const cRef = useRef<HTMLInputElement>(null);

  const checkAll =
    checkedServices.filter((service) => services.find((s) => s.id === service))
      .length === services.length;
  const checkNone = !checkedServices.length;

  const indeterminate = !(checkAll || checkNone);

  useEffect(() => {
    if (cRef.current) cRef.current.indeterminate = indeterminate;
  }, [cRef, indeterminate, services, currentPage]);

  // Events handlers
  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };

  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"sm"}>Loading services...</Text>
      </div>
    );
  }
  if (!isLoading && services.length === 0) {
    return <Empty message={"No services found"} />;
  }
  return (
    <Table>
      <TableHeader>
        <TableHead>
          <Checkbox
            ref={cRef}
            checked={checkAll}
            onCheck={onCheckAll}
            label={"Services"}
          />
          {/* <Text>Services</Text> */}
        </TableHead>
        <TableHead align={"right"}>
          <Text>Teams</Text>
        </TableHead>
      </TableHeader>
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        <ServicesAssignmentTableRows
          services={services}
          checkedServices={checkedServices}
          onCheckChange={onCheckChange}
        />
      </TableBody>
      <TableFooter>
        <TableFooterCell colSpan={2}>
          <div className={styles.footer}>
            <Text>
              {checkedServices.length} Selected - {totalServices} Services
            </Text>
            {showPagination && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handleOnPageChange}
                currentPage={currentPage}
              />
            )}
          </div>
        </TableFooterCell>
      </TableFooter>
    </Table>
  );
};
