import { ManagedResource } from 'types/selfService'
import { resolveLogoText } from 'components/self-service/ClusterCard/utils';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import DynamicTable from 'components/octo-ui/DynamicTable/DynamicTable';
import StatusIndicator from 'components/octo-ui/StatusIndicator/StatusIndicator';

interface ManagedResourcesProps {
    managedResources: ManagedResource[]
}

const ManagedResources = ({ managedResources }: ManagedResourcesProps) => {
    const columns = ['Resource', '', 'Ready', 'Sync', 'Age']; 

    const tableData = managedResources.map(resource => {
      
      const readinessState = resource.ready === "True" ? "success" : "danger"
      const syncState = resource.synced === "True" ? "success" : "danger"
  
      const readinessLabel = resource.ready === "True" ? "Ready" : "Not Ready"
      const syncLabel = resource.synced === "True" ? "Synced" : "Not synced"

      
      return [
        <span>{resolveLogoText(resource.type)}</span>, 
        <Link to={resource.link} target='_blank'>
          {resource.id}
          <ExternalLinkIcon className="h-4 w-4" />
        </Link>, 
        <StatusIndicator label={readinessLabel} status={readinessState} />, 
        <StatusIndicator label={syncLabel} status={syncState} />,
        <span>{resource.age}</span> 
    ]});
  
    return <DynamicTable columns={columns} data={tableData} />;
}

export default ManagedResources