import clsx from "clsx";
import React, { forwardRef } from "react";
import styles from "./Input.module.css";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ fullWidth, disabled, ...props }, ref) => {
    return (
      <input
        className={clsx(
          "nemo-input",
          styles.wrapper,
          fullWidth && styles.fullWidth
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
