import {TrashIcon, XIcon} from "@heroicons/react/solid";
import {PageLayout} from "components/Layout";
import {ScoreCardFormComponent} from "components/scorecard/ScoreCardForm/ScoreCardFormComponent/ScoreCardFormComponent";
import {useLevelsMediaByOwnerTypeAndIds} from "hooks/media/useLevelsMediaByOwnerTypeAndIds";
import {useDeleteScoreCard} from "hooks/scorcards/useDeleteScoreCard";
import {useSingleScoreCard} from "hooks/scorcards/useSingleScoreCard";
import React, {useState} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {ADMIN_SCORECARDS_PAGE, NOT_FOUND_PATH} from "routes/paths";
import {getScorecardWithMedia} from "services/media/media.helper";
import {extractLevelsId} from "utils/scoreCard";
import ConfirmModalComponent from "../../../components/basic/ConfirmModalComponent/ConfirmModalComponent";
import {LoadingPage} from "../../loading";
import { Button } from "components/octo-ui/Button/Button";

export const EditScoreCardPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    let extractedScoreCardLevelIds;
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const {
        data: scorecard,
        isSuccess,
        isLoading: isLoadingScoreCard,
    } = useSingleScoreCard(id!);
    extractedScoreCardLevelIds = extractLevelsId(scorecard?.levels);
    const {data: levelsMedia, isLoading: isLoadingLevelsMedia} = useLevelsMediaByOwnerTypeAndIds({
        levelsId: extractedScoreCardLevelIds,
        ownerType: 'level'
    })
    const {mutateAsync: deleteScoreCard} =
        useDeleteScoreCard(id!, () => {
            navigate(ADMIN_SCORECARDS_PAGE);
        });

    const handleOnDelete = () => {
        setShowConfirmDeleteModal(true);
    };
    const handleOnCancel = async () => {
        navigate(`/admin/scorecards/${id}`);
    };

    const Actions = () => {
        return (
            <>
                <Button
                    variant="primaryDark"
                    onClick={handleOnCancel}
                    icon={<XIcon className="h-5"/>}
                >
                    
                    Cancel
                </Button>
                <Button
                    onClick={handleOnDelete}
                    variant="primaryDark"
                    icon={<TrashIcon className="h-5"/>}
                >
                    Delete
                </Button>
            </>
        );
    };

    if (!id) {
        return <Navigate to={NOT_FOUND_PATH} replace={true}/>;
    }

    if (isLoadingScoreCard || isLoadingLevelsMedia) {
        return <LoadingPage/>;
    }

    if (isSuccess) {
        if (!scorecard) {
            return <Navigate to={NOT_FOUND_PATH} replace={true}/>;
        }
        return (
            <>
                <PageLayout
                    title={`${scorecard.name}`}
                    extended={true}
                    actions={<Actions/>}
                >
                    <ScoreCardFormComponent scoreCard={getScorecardWithMedia(scorecard, levelsMedia ?? [])}/>
                </PageLayout>
                <ConfirmModalComponent
                    title={"Are you sure?"}
                    message={
                        "This will delete all data related to this scorecard (Rules, History of evaluations ...). This data can not be restored after confirming."
                    }
                    callBack={deleteScoreCard}
                    isOpen={showConfirmDeleteModal}
                    setIsOpen={setShowConfirmDeleteModal}
                />
            </>
        );
    } else {
        return <Navigate to={NOT_FOUND_PATH} replace={true}/>;
    }
};
