import { ChartProps } from "../types";
import {
  axisDefaultLabelMapper,
  CHART_GRID_COLOR,
  DEFAULT_CHART_MARGIN,
} from "../config";
import { ReactNode, useMemo } from "react";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { GridRows } from "@visx/grid";
import { BarGroup } from "@visx/shape";
import { Group } from "@visx/group";
import { DatumObject } from "@visx/shape/lib/types/base";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { localPoint } from "@visx/event";

let tooltipTimeout: number;
const tooltipStyles = {
  ...defaultStyles,
  padding: 0,
  margin: 0,
};
interface BarGroupChartProps<T> extends ChartProps {
  data: T[];
  xMapper: (d: T) => string;
  onClick?: (d: T) => void;
  yLabelMapper?: (val: any) => string;
  xLabelMapper?: (val: any) => string;
  x1Colors?: string[];
  x1keys: string[];
  renderTooltip?: (d: {
    key: string;
    value: number;
    color: string;
  }) => ReactNode;
}

export const BarGroupChart = <T extends DatumObject>({
  data,
  xMapper,
  x1Colors,
  yLabelMapper = axisDefaultLabelMapper,
  xLabelMapper = axisDefaultLabelMapper,
  x1keys,
  width,
  height,
  renderTooltip,
  bgColor = "transparent",
  margin = DEFAULT_CHART_MARGIN,
}: BarGroupChartProps<T>) => {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{ key: string; value: number; color: string }>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = useMemo(() => {
    return scaleBand<string>({
      range: [0, xMax],
      round: true,
      domain: data.map(xMapper),
      paddingInner: 0.4,
      paddingOuter: 0.2,
    });
  }, [data, xMax]);

  const x1Scale = useMemo(() => {
    return scaleBand<string>({
      range: [0, xScale.bandwidth()],
      round: true,
      domain: x1keys,
      padding: 0.05,
    });
  }, [xScale, data, x1keys]);

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [
          0,
          Math.max(
            ...data.map((d) => Math.max(...x1keys.map((key) => Number(d[key]))))
          ),
        ],
      }),
    [yMax, data, x1keys]
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal<string, string>({
        domain: x1keys,
        range: x1Colors,
      }),
    [x1keys, x1Colors]
  );

  // TODO - move min width to a prop or global chart config
  if (width < 10) {
    return null;
  }

  return (
    <div>
      <svg width={width} height={height} ref={containerRef}>
        <rect x={0} y={0} width={width} height={height} fill={bgColor} />
        <GridRows
          scale={yScale}
          width={xMax}
          height={yMax}
          top={margin.top}
          left={margin.left}
          stroke={CHART_GRID_COLOR}
        />
        <Group top={margin.top} left={margin.left}>
          <BarGroup
            data={data}
            keys={x1keys}
            height={yMax}
            x0={xMapper}
            x0Scale={xScale}
            x1Scale={x1Scale}
            yScale={yScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                >
                  {barGroup.bars.map((bar) => (
                    <rect
                      key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                      onMouseOver={(event) => {
                        if (!renderTooltip) return;
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        const eventSvgCoords = localPoint(event);

                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: eventSvgCoords?.y,
                          tooltipLeft: eventSvgCoords?.x,
                        });
                      }}
                      onMouseOut={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                    />
                  ))}
                </Group>
              ))
            }
          </BarGroup>
          <AxisLeft
            hideAxisLine
            left={0}
            hideTicks
            scale={yScale}
            tickFormat={yLabelMapper}
            tickLabelProps={{
              fill: "#03001C",
              fontSize: 11,
              textAnchor: "end",
              dy: "0.33em",
            }}
          />
          {xScale && (
            <AxisBottom
              top={yMax}
              scale={xScale}
              stroke={"#03001C"}
              tickStroke={"#03001C"}
              tickFormat={xLabelMapper}
            />
          )}
        </Group>
      </svg>
      {tooltipOpen && tooltipData && renderTooltip && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          {renderTooltip(tooltipData)}
        </TooltipInPortal>
      )}
    </div>
  );
};
