import {Heading, PageContent} from "_sredx/_ui";
import {TeamCreationContainer} from "_sredx/containers";
import styles from "./TeamsCreationPage.module.css";


export const TeamsCreationPage = () => {
    return (
        <PageContent align={"flex-start"} maxWidth={800} className={styles.wrapper}>
            <Heading>Create team</Heading>
            <TeamCreationContainer />
        </PageContent>
    );
}