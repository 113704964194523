import { TeamListingContainer } from "_sredx/containers";
import { PageContent } from "_sredx/_ui";

export const TeamsListingPage = () => {
  return (
    <PageContent>
      <TeamListingContainer />
    </PageContent>
  );
};
