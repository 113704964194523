import React from "react";
import clsx from "clsx";

import styles from "./StatusBar.module.css";
import { Status } from "../types";
import { getTotalCount, getValidPercentage } from "../utils";

interface StatusBarProps {
  statuses: Status[];
}

export const StatusBar = ({ statuses }: StatusBarProps) => {
  // Derived Variables
  const total = getTotalCount(statuses);

  return (
    <div className={clsx("nemo-status-bar", styles.wrapper)}>
      {statuses.map((status, index) => {
        return (
          <div
            key={index}
            className={clsx(status.color)}
            style={{
              width: `${getValidPercentage(total, status.value)}%`,
            }}
          ></div>
        );
      })}
    </div>
  );
};
