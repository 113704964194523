import { useQuery } from "react-query";
import { PagedResponse, RuleDto, RuleSearchCriteria } from "_sredx/types";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";

export interface UseGetRulesProps {
  params: RuleSearchCriteria;
}

export const useGetRules = ({ params }: UseGetRulesProps) => {
  return useQuery<PagedResponse<RuleDto>>({
    queryKey: [QUERY_KEYS.rules, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getRules(), {
        params,
      });
    },
  });
};
