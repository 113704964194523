import {
  Button,
  ButtonLabel,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "_sredx/_ui";
import {
  MembersList,
  MembersSelectAutoComplete,
  TeamCreationMembersAction,
} from "_sredx/components";
import { Member, TeamMember, TeamMemberRoleType } from "_sredx/types";
import { useMemo, useState } from "react";
import styles from "./TeamInvitationContainer.module.css";
import {
  getFilteredMembers,
  getNewMembersList,
  mapInfiniteUsers,
} from "./utils";
import { useInfiniteGetUsers } from "_sredx/services/users";
import { useDebounce } from "_sredx/hooks";

interface TeamInvitationContainerProps {
  members: TeamMember[];
  onSubmit: (members: TeamMember[]) => void;
  hide?: () => void;
  teamId?: string;
}

export const TeamInvitationContainer = ({
  members: defaultMembers,
  onSubmit,
  hide,
  teamId,
}: TeamInvitationContainerProps) => {
  // States
  const [members, setMembers] = useState<TeamMember[]>(defaultMembers);
  const [searchValue, setSearchValue] = useState("");
  const [selectedSearchMembers, setSelectedSearchMembers] = useState<Member[]>(
    []
  );

  // Hooks
  const q = useDebounce(searchValue, 400);

  // Services
  const {
    data: infiniteUsers,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
  } = useInfiniteGetUsers({
    params: {
      q,
      excludeTeamId: teamId,
    },
  });

  // Derived Variables
  const users = useMemo(() => {
    return mapInfiniteUsers(infiniteUsers);
  }, [infiniteUsers]);

  // Event Handlers
  const handleOnLoadMore = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  const handleSubmit = () => {
    onSubmit(members);
    hide && hide();
  };

  const handleChangeRole = (memberEmail: string, role: TeamMemberRoleType) => {
    setMembers((oldMembers) =>
      oldMembers.map((member) => {
        if (member.email === memberEmail) {
          return { ...member, role };
        }
        return member;
      })
    );
  };

  const handleDeleteMember = (memberEmail: string) => {
    setMembers((oldMembers) =>
      oldMembers.filter((member) => member.email !== memberEmail)
    );
  };

  const handleAction = (action: TeamCreationMembersAction) => {
    switch (action.type) {
      case "DELETE_MEMBER":
        handleDeleteMember(action.payload.memberEmail);
        return;
      case "CHANGE_ROLE":
        handleChangeRole(action.payload.memberEmail, action.payload.role);
        return;
    }
  };

  const handleOnAddClicked = () => {
    setMembers((oldMembers) => {
      return getNewMembersList({
        oldMembers,
        selectedSearchMembers,
      });
    });
    setSelectedSearchMembers([]);
  };

  const membersOptions = getFilteredMembers({
    organizationMembers: users,
    addedMembers: members,
    selectedSearchMembers,
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Invite members</CardTitle>
        <CardSubtitle>search and invite members</CardSubtitle>
      </CardHeader>
      <CardBody noPadding>
        <div className={styles.wrapper}>
          <div className={styles.members_search_wrapper}>
            <MembersSelectAutoComplete
              memberOptions={membersOptions}
              onSearch={setSearchValue}
              onSelect={setSelectedSearchMembers}
              selectedMembers={selectedSearchMembers}
              isLoading={isLoading}
              isFetching={isFetching}
              loadMore={handleOnLoadMore}
              loadingCaption={"Loading members..."}
            />

            <Button
              disabled={!selectedSearchMembers.length}
              onClick={handleOnAddClicked}
              variant={"neutral"}
            >
              <ButtonLabel>Add</ButtonLabel>
            </Button>
          </div>

          <div className={styles.members_list_wrapper}>
            <MembersList
              members={members.map((member) => ({
                email: member.email || "",
                id: member.userId || "",
                firstName: member.firstName || "",
                lastName: member.lastName || "",
                role: member.role,
                status: member.status,
              }))}
              onAction={handleAction}
              emptyMessage={"There are no members on this team"}
            />
          </div>

          <div className={styles.buttons_actions_wrapper}>
            <Button ghost variant="neutral" onClick={hide}>
              <ButtonLabel>cancel</ButtonLabel>
            </Button>
            <Button disabled={members.length === 0} onClick={handleSubmit}>
              <ButtonLabel>confirm</ButtonLabel>
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
