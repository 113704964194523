import { useEffect, useMemo, useState } from "react";
import { useSingleScoreCard } from "../../../hooks/scorcards/useSingleScoreCard";
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEvaluateScoreCard } from "../../../hooks/scorcards/useEvaluateScoreCard";
import {
  ADMIN_EDIT_SINGLE_SCORECARD_PAGE,
  NOT_FOUND_PATH,
} from "../../../routes/paths";
import { LoadingPage } from "../../loading";
import {
  BeakerIcon,
  PencilIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/outline";
import ScoreCardServicesListComponent from "../../../components/scorecard/ScoreCardDetails/ScoreCardServicesList/ScoreCardServicesListComponent";
import { RefreshIcon } from "@heroicons/react/solid";
import { getScoreCardOverviewFromEvaluationsHistory } from "../../../services/evaluations/ScoreCardEvaluations.helper";
import { useScoreCardLastEvaluations } from "../../../hooks/evaluations/useScoreCardLastEvaluations";
import { v4 as uuidv4 } from "uuid";
import ProgressTooltipComponent from "../../../components/basic/ProgressTooltipComponent/ProgressTooltipComponent";
import { ProgressInfo } from "../../../types/ProgressInfo";
import useSse from "../../../hooks/useSse";
import { useModal } from "hooks/useModal";
import { ScorecardDetailPageLayout } from "components/Layout/ScoreCardDetailPageLayout";
import { Button } from "components/octo-ui/Button/Button";

export const ScoreCardDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { show, hide } = useModal();

  const connexionId = useMemo(() => uuidv4(), []);

  const {
    sseData: progressInfo,
    connect: connectProgressInfo,
    disconnect: disconnectProgressInfo,
  } = useSse<ProgressInfo>(`/evaluation-progress/${connexionId}`);

  const [showProgressTooltip, setShowProgressTooltip] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const {
    data: scorecard,
    isSuccess,
    isLoading: isLoadingScoreCard,
  } = useSingleScoreCard(id!);
  const { mutateAsync: evaluateScoreCard, isLoading: isEvaluating } =
    useEvaluateScoreCard(id!, connexionId);
  const {
    data: scorecardLastEvaluation,
    isSuccess: isLoadingScorecardLastEvaluationSuccessful,
    isLoading: isLoadingScorecardLastEvaluation,
  } = useScoreCardLastEvaluations(id!);

  const handleEvaluateScoreCard = async () => {
    setShowProgressTooltip(true);
    connectProgressInfo();
    await evaluateScoreCard();
    disconnectProgressInfo();
    setShowProgressTooltip(false);
  };

  useEffect(() => {
    if (!isEvaluating) {
      setShouldRefresh(true);
      setTimeout(() => {
        setShouldRefresh(false);
      }, 100);
    }
  }, [isEvaluating]);

  const handleClickEvaluate = () => {
    show({
      body: "This action might take time.",
      options: {
        title: "Are you sure?",
        actions: (
          <>
            <button
              type="button"
              data-testid={"confirm-error-alert-button"}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-sky-500 text-base font-medium text-white hover:bg-sky-800 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                handleEvaluateScoreCard();
                hide();
              }}
            >
              Confirm
            </button>
            <button
              type="button"
              data-testid={"close-error-alert-button"}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={hide}
            >
              Cancel
            </button>
          </>
        ),
      },
    });
  };
  const handleClickEdit = () => {
    navigate(
      generatePath(ADMIN_EDIT_SINGLE_SCORECARD_PAGE, { id: scorecard?.id! })
    );
  };

  const lastEvaluationOverview = useMemo(() => {
    return getScoreCardOverviewFromEvaluationsHistory(
      scorecardLastEvaluation ?? []
    );
  }, [scorecardLastEvaluation]);

  const Actions = () => (
    <div className="flex gap-12">
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center">
          <PresentationChartLineIcon className="h-5" />
          Average Score:
          <div>{lastEvaluationOverview.averageScore.toFixed(2)}</div>
        </div>
        <div className="flex gap-2 items-center">
          <BeakerIcon className="h-5" />
          Passing Services: {lastEvaluationOverview.passingServices}/
          {lastEvaluationOverview.totalServices}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <ProgressTooltipComponent
          progressInfo={progressInfo}
          showProgressTooltip={showProgressTooltip}
        >
          <Button
            onClick={handleClickEvaluate}
            icon={<BeakerIcon className="h-5" />}
            variant="primaryDark"
            isLoading={isEvaluating}
          >
            Evaluate
          </Button>
        </ProgressTooltipComponent>
        <Button
          data-testid="edit-scorecard-button"
          onClick={handleClickEdit}
          variant="primaryDark"
          icon={<PencilIcon className="h-5" />}
        >
          Edit
        </Button>
      </div>
    </div>
  );

  if (!id) {
    return <Navigate to={NOT_FOUND_PATH} replace={true} />;
  }

  if (isLoadingScoreCard) {
    return <LoadingPage />;
  }

  if (isSuccess) {
    if (!scorecard) {
      return <Navigate to={NOT_FOUND_PATH} replace={true} />;
    }
    return (
      <ScorecardDetailPageLayout
        title={scorecard.name}
        lastEvaluation={lastEvaluationOverview.lastEvaluated}
        tag={scorecard.tag}
        description={scorecard.description}
        rightSection={<Actions />}
      >
        {isLoadingScorecardLastEvaluation && (
          <RefreshIcon
            id={"loading-last-evaluation-spinner"}
            className={"animate-spin h-5 w-5 mx-auto"}
          />
        )}
        {isLoadingScorecardLastEvaluationSuccessful && !shouldRefresh && (
          <ScoreCardServicesListComponent
            scoreCardId={id}
            scoreCardLastEvaluations={scorecardLastEvaluation}
          />
        )}
      </ScorecardDetailPageLayout>
    );
  } else {
    return <Navigate to={NOT_FOUND_PATH} replace={true} />;
  }
};
