import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { RegistredMemberDto } from "_sredx/types";
import { ClientApi } from "_sredx/services/clientApi";

export interface MemberAffectationDto {
  id: string;
  members: RegistredMemberDto[];
}
export const useTeamMember = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEYS.affectMember],
    mutationFn: (data: MemberAffectationDto) => {
      return ClientApi.post(API_ENDPOINTS.createTeamMember(data.id), data.members);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.teams], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.users], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.team], {
        exact: false,
      });
    },
  });
};
