import { StepperHeaderItem } from "componentsv2/shared/StepperComponent/StepperHeaderItem";
import { StepperHeaderWrapper } from "componentsv2/shared/StepperComponent/StepperHeaderWrapper";
import { useStepperContext } from "hooks/stepper/useStepperContext";

export const StepperHeader = () => {
  const { steps } = useStepperContext();
  return (
    <StepperHeaderWrapper>
      {steps.map((step) => (
        <StepperHeaderItem key={step.stepKey} step={step} />
      ))}
    </StepperHeaderWrapper>
  );
};
