import React from "react";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { format, secondsToHours } from "date-fns";
import { DotTimeChart } from "_sredx/components/Charts";
import { PRTooltipChart } from "../PRTooltipChart";
import { hoursToAxisLabel } from "_sredx/utils/charts";
import { ChartPeriodType, PullRequestDto } from "_sredx/types";

interface PRTimeToMergeDotTimeChartProps {
  pullRequests: PullRequestDto[];
  period: ChartPeriodType;
  xAxisKey?: "createdAt" | "mergedAt" | "closedAt";
  colors?: Map<string, string>;
}

export const PRTimeToMergeDotTimeChart = ({
  pullRequests,
  period,
  xAxisKey = "createdAt",
  colors = new Map(),
}: PRTimeToMergeDotTimeChartProps) => {
  const dotColorMapper = (pr: PullRequestDto) => {
    const color = colors.get(pr.repository);
    return color ? color : "#5FBDFF";
  };

  const dotOutlineColorMapper = (pr: PullRequestDto) => {
    const color = colors.get(pr.repository);
    return color ? color : "#5FBDFF";
  };
  return (
    <div style={{ width: "100%", height: "500px" }}>
      <ParentSize>
        {({ width, height }) => (
          <DotTimeChart
            width={width}
            height={height}
            data={pullRequests}
            yMapper={(d) => secondsToHours(d.timeToMerge || 0)}
            xMapper={(d) => new Date(d[xAxisKey])}
            dotColorMapper={dotColorMapper}
            dotSizeMapper={() => 5}
            dotOutlineColorMapper={dotOutlineColorMapper}
            yLabelMapper={(val) => hoursToAxisLabel(val)}
            xLabelMapper={(val) => format(val, "dd MMM yyyy")}
            endDate={period.endDate}
            startDate={period.startDate}
            renderTooltip={(pr) => <PRTooltipChart pullRequest={pr} />}
          />
        )}
      </ParentSize>
    </div>
  );
};
