import clsx from "clsx";
import styles from "./MenuItem.module.css";
import {PropsWithChildren} from "react";


export const MenuItem = ({
  children,
}: PropsWithChildren) => {
  const classNames = clsx(
    "nemo-menu-item",
    styles.wrapper
  );
  return <li className={classNames}>{children}</li>;
};
