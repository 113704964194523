import { ChartPeriodType, PullRequestDto } from "_sredx/types";
import {
  MetricChartHeader,
  MetricChartWrapper,
} from "_sredx/components/Metrics";
import React, { useMemo } from "react";
import { PRMergeFrequencyStats } from "./PRMergeFrequencyStats";
import { getMergedPRs } from "_sredx/utils/pullRequests";
import { PRMergeFrequencyChart } from "_sredx/components/PullRequest/PRMergeFrequencyChart";
import { Loader } from "_sredx/_ui";

interface PRMergeFrequencyWidgetProps {
  pullRequests?: PullRequestDto[];
  period: ChartPeriodType;
  isLoading?: boolean;
  filterByDateKey?: "createdAt" | "mergedAt" | "closedAt";
}

export const PRMergeFrequencyWidget = ({
  pullRequests = [],
  period,
  isLoading = true,
  filterByDateKey = "createdAt",
}: PRMergeFrequencyWidgetProps) => {
  const mergedPullRequests = useMemo(
    () => getMergedPRs(pullRequests),
    [pullRequests]
  );

  if (isLoading) {
    return (
      <MetricChartWrapper>
        <MetricChartHeader title="Pull request merge frequency" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            height: "500px",
          }}
        >
          <Loader />
          <span>Loading ...</span>
        </div>
      </MetricChartWrapper>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
      }}
    >
      <MetricChartWrapper>
        <MetricChartHeader title="Pull request merge frequency" />
        <PRMergeFrequencyStats pullRequests={mergedPullRequests} />
        <div>
          <PRMergeFrequencyChart
            pullRequests={mergedPullRequests}
            period={period}
          />
        </div>
      </MetricChartWrapper>
    </div>
  );
};
