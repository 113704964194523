import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CardBody, CardHeader, CardTitle } from "_sredx/_ui";
import { FormFieldInput } from "_sredx/components/Form";
import { Resolver, useForm } from "react-hook-form";
import styles from "./RoadmapCreationInfoForm.module.css";
import { RoadmapInfoFormData } from "./types";
import { schema } from "./utils";

interface RoadMapCreationInfoFormProps {
  defaultValues?: RoadmapInfoFormData;
  onChange: (data: RoadmapInfoFormData) => void;
}

export const RoadmapCreationInfoForm = ({
  defaultValues,
  onChange,
}: RoadMapCreationInfoFormProps) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm<RoadmapInfoFormData>({
    mode: "onChange",
    resolver: yupResolver(schema) as Resolver<RoadmapInfoFormData>,
    defaultValues,
  });

  const handleSubmitInfoChange = () => {
    onChange(getValues());
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle>general information</CardTitle>
      </CardHeader>
      <CardBody>
        <div className={styles.body_wrapper}>
          <form onChange={handleSubmitInfoChange}>
            <FormFieldInput
              name={"name"}
              control={control}
              label={"Name"}
              placeholder={"Roadmap name"}
              errorMessage={errors.name?.message}
            />
            <FormFieldInput
              name={"description"}
              control={control}
              label={"Description"}
              placeholder={"Description of the roadmap"}
              errorMessage={errors.description?.message}
            />
            <FormFieldInput
              name={"year"}
              control={control}
              label={"Year"}
              type={"number"}
              placeholder={"Year"}
              errorMessage={errors.year?.message}
            />
          </form>
        </div>
      </CardBody>
    </Card>
  );
};
