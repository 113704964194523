import { forwardRef } from "react";
import { IconProps } from "./types";

export const GitlabIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        viewBox="0 0 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <title>gitlab</title>
        <path
          fill="#FC6D26"
          d="m22.561 13.928-1.177-3.53-2.328-7.006a.402.402 0 0 0-.383-.27.402.402 0 0 0-.38.27l-2.329 7H8.232l-2.328-7a.4.4 0 0 0-.381-.269.4.4 0 0 0-.381.27L1.64 13.928a.772.772 0 0 0 .29.875L12.1 22.02l10.166-7.218a.774.774 0 0 0 .294-.875Z"
        />
        <path
          fill="#E24329"
          d="m12.099 22.016 3.867-11.625H8.233L12.1 22.016Z"
        />
        <path
          fill="#FC6D26"
          d="M12.099 22.016 8.23 10.391H2.818l9.28 11.625Z"
        />
        <path
          fill="#FCA326"
          d="m2.814 10.396-1.176 3.53a.772.772 0 0 0 .29.875l10.17 7.221-9.284-11.625v-.001Z"
        />
        <path
          fill="#E24329"
          d="M2.817 10.396h5.418l-2.331-7a.402.402 0 0 0-.381-.27.402.402 0 0 0-.383.27l-2.323 7Z"
        />
        <path
          fill="#FC6D26"
          d="m12.099 22.016 3.867-11.625h5.42L12.1 22.016Z"
        />
        <path
          fill="#FCA326"
          d="m21.384 10.396 1.174 3.53a.77.77 0 0 1-.29.875l-10.17 7.215 9.283-11.62h.003Z"
        />
        <path
          fill="#E24329"
          d="M21.384 10.396h-5.415l2.326-7a.4.4 0 0 1 .383-.27c.172 0 .325.109.38.27l2.329 7h-.003Z"
        />
      </svg>
    );
  }
);
