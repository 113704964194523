export const PRCloseIcon = ({
  size = 24,
  color = "currentColor",
}: {
  size: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M3 5.5a3.5 3.5 0 1 1 4.5 3.355v6.29A3.502 3.502 0 0 1 6.5 22a3.5 3.5 0 0 1-1-6.855v-6.29A3.502 3.502 0 0 1 3 5.5ZM6.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm7.793-1.707a1 1 0 0 1 1.414 0L17.5 4.086l1.793-1.793a1 1 0 1 1 1.414 1.414L18.914 5.5l1.793 1.793a1 1 0 0 1-1.414 1.414L17.5 6.914l-1.793 1.793a1 1 0 1 1-1.414-1.414L16.086 5.5l-1.793-1.793a1 1 0 0 1 0-1.414ZM17.5 11a1 1 0 0 1 1 1v3.145a3.502 3.502 0 0 1-1 6.855 3.5 3.5 0 0 1-1-6.855V12a1 1 0 0 1 1-1Zm-11 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm11 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
