import { Link, useParams } from "react-router-dom";
import { ADMIN_SELF_SERVICE_PAGE } from "routes/paths";
import { ClusterDetailsDeleteContainer } from "./ClusterDetailsDeleteContainer/ClusterDetailsDeleteContainer";
import { ClusterDetailsToolsContainer } from "./ClusterDetailsToolsContainer";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import ClusterManagedResourcesContainer from "../ClusterManagedResourcesContainer/ClusterManagedResourcesContainer";
import ClusterDetailsInfoFetchContainer from "../ClusterDetailsFetchContainer/ClusterDetailsInfoFetchContainer";

export const ClusterDetailsContainer = () => {
  const { id } = useParams();
  return (
    <>
      <Link
        to={ADMIN_SELF_SERVICE_PAGE}
        className="text-gray-500 flex gap-2 items-center"
      >
        <ChevronLeftIcon className="h-4" /> Back to clusters
      </Link>
      <div className="flex flex-col gap-6">
        <ClusterDetailsInfoFetchContainer clusterId={id ?? ""} />
        <ClusterManagedResourcesContainer name={id ?? ""} />
        <ClusterDetailsToolsContainer id={id ?? ""} />
        <ClusterDetailsDeleteContainer id={id ?? ""} />
      </div>
    </>
  );
};
