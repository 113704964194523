import { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./MenuItemIcon.module.css";

export const MenuItemIcon = ({ children }: PropsWithChildren) => {
  return (
    <div className={clsx("nemo-menu-item-icon", styles.wrapper)}>
      {children}
    </div>
  );
};
