import { UserCreationDto } from "_sredx/services";
import {
  MemberDetails,
  TeamMember,
  UserDto,
  Member,
  MemberRoleType,
  RegistredMemberDto,
} from "_sredx/types";

export const mapMembersToTeamMembers = (
  users: UserDto[],
  teamMemberData: TeamMember[]
): MemberDetails[] => {
  if (!teamMemberData || !users) {
    return [];
  }
  const result: MemberDetails[] = [];
  users.forEach((user) => {
    teamMemberData.forEach((member) => {
      if (user.id === member.userId) {
        result.push({
          ...user,
          role: member.role,
          invitation: "ACCEPTED", //TODO
          source: user.source ?? "SREDX",
          email: user.email,
        });
      }
    });
  });
  return result;
};

export const extractRegisteredMembers = (
  members: Member[]
): { role: MemberRoleType; userId: string }[] =>
  members
    .map((member) => {
      if (member.role && member.id)
        return { role: member.role, userId: member.id };
      else return undefined;
    })
    .filter((e) => e !== undefined) as {
    role: MemberRoleType;
    userId: string;
  }[];

export const extractUnregisteredMembers = (
  members: Member[]
): {
  email: string;
}[] =>
  members
    .map((member) => {
      if (member.email && !member.id) return { email: member.email };
      else return undefined;
    })
    .filter((e) => e !== undefined) as {
    email: string;
  }[];

export const preparePayloadFromUserCreationResponse = (
  members: Member[],
  response: UserDto[]
) => {
  return members
    .map((member) => {
      const user = response.find((user) => user.email === member.email);
      if (user) {
        return { role: member.role, userId: user.id };
      } else return undefined;
    })
    .filter((e) => e !== undefined);
};

interface ChainTeamMembersCreationMutationsProps {
  teamId: string;
  members: Member[];
  mutateNewUsers: (
    users: UserCreationDto[],
    options?: { onSuccess: (data: any) => void }
  ) => Promise<{ data: UserDto[] }>;
  assignMembers: (data: {
    id: string;
    members: RegistredMemberDto[];
  }) => Promise<{ data: any }>;
}

export const chainTeamMembersCreationMutations = async ({
  teamId,
  members,
  mutateNewUsers,
  assignMembers,
}: ChainTeamMembersCreationMutationsProps) => {
  //Split members between registred and unregistred

  const registredUsers = extractRegisteredMembers(members);
  const unregistredUsers = extractUnregisteredMembers(members);

  if (!unregistredUsers && !registredUsers) return;

  //Register new users and add them to team assign payload
  if (unregistredUsers.length > 0) {
    await mutateNewUsers(unregistredUsers as UserCreationDto[], {
      onSuccess: (data: any) => {
        const newMembers = preparePayloadFromUserCreationResponse(
          members,
          data
        );
        registredUsers.push(...(newMembers as RegistredMemberDto[]));
      },
    });
  }
  //Assign members to team
  if (registredUsers.length > 0) {
    await assignMembers({
      id: teamId,
      members: registredUsers,
    });
  }
};
