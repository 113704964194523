const ManagedResourcesSkeleton = () => {
  return (
    <div className="bg-gray-100 w-full">
    <div className="flex justify-between items-center p-6 mb-6">
        <div className="h-6 w-32 bg-gray-300 animate-pulse"></div>
        <div className="relative">
        <div className="h-6 w-40 bg-gray-300 animate-pulse"></div>
        </div>
    </div>
    
    <div className="border-t border-gray-200">
        <div className="flex justify-between items-center py-3 px-6">
        <div className="flex items-center">
            <div className="h-6 w-6 bg-gray-300 animate-pulse mr-4"></div>
            <div className="h-6 w-40 bg-gray-300 animate-pulse"></div>
        </div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        </div>
    </div>
    
    <div className="border-t border-gray-200">
        <div className="flex justify-between items-center py-3 px-6">
        <div className="flex items-center">
            <div className="h-6 w-6 bg-gray-300 animate-pulse mr-4"></div>
            <div className="h-6 w-40 bg-gray-300 animate-pulse"></div>
        </div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        </div>
    </div>
    
    <div className="border-t border-gray-200">
        <div className="flex justify-between items-center py-3 px-6">
        <div className="flex items-center">
            <div className="h-6 w-6 bg-gray-300 animate-pulse mr-4"></div>
            <div className="h-6 w-40 bg-gray-300 animate-pulse"></div>
        </div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        </div>
    </div>
    
    <div className="border-t border-gray-200">
        <div className="flex justify-between items-center py-3 px-6">
        <div className="flex items-center">
            <div className="h-6 w-6 bg-gray-300 animate-pulse mr-4"></div>
            <div className="h-6 w-40 bg-gray-300 animate-pulse"></div>
        </div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        </div>
    </div>
    
    <div className="border-t border-gray-200">
        <div className="flex justify-between items-center py-3 px-6">
        <div className="flex items-center">
            <div className="h-6 w-6 bg-gray-300 animate-pulse mr-4"></div>
            <div className="h-6 w-40 bg-gray-300 animate-pulse"></div>
        </div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        </div>
    </div>
    
    <div className="border-t border-gray-200">
        <div className="flex justify-between items-center py-3 px-6">
        <div className="flex items-center">
            <div className="h-6 w-6 bg-gray-300 animate-pulse mr-4"></div>
            <div className="h-6 w-40 bg-gray-300 animate-pulse"></div>
        </div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        <div className="h-6 w-20 bg-gray-300 animate-pulse"></div>
        </div>
    </div>
    
    </div>

    
  )
}

export default ManagedResourcesSkeleton