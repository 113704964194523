import { InitiativeDto, RuleDto, ServiceInfoDto } from "_sredx/types";
import {
  InitiativeRuleEvaluation,
  InitiativeRuleOnServiceEvaluation,
} from "_sredx/components";

export const mapInitiativeRulesWithEvaluation = (
  initiativeDto?: InitiativeDto | null,
  rules?: RuleDto[],
  services?: ServiceInfoDto[]
): InitiativeRuleEvaluation[] => {
  if (!rules?.length || !initiativeDto) {
    return [];
  }

  const data: InitiativeRuleEvaluation[] = rules.map((rule) => {
    const ruleEvaluationResult = initiativeDto.ruleEvaluationResults.find(
      (evaluationResult) => {
        return evaluationResult.ruleId == rule.id;
      }
    );

    if (!ruleEvaluationResult)
      return {
        ...rule,
        success: null,
        services: [],
      };

    const InitiativeServices: InitiativeRuleOnServiceEvaluation[] = [];

    if (services && services.length) {
      const mapServices = (serviceIds: string[], success: boolean) => {
        serviceIds.forEach((serviceId) => {
          const serviceInfo = services.find((s) => s.id === serviceId);
          if (!serviceInfo) return;
          InitiativeServices.push({
            serviceId: serviceId,
            serviceName: serviceInfo.name,
            success: success,
          });
        });
      };

      mapServices(ruleEvaluationResult.passingServices, true);
      mapServices(ruleEvaluationResult.failingServices, false);
    }

    return {
      ...rule,
      success: ruleEvaluationResult.success,
      services: InitiativeServices,
    };
  });

  return data.sort((a, b) => {
    if (a.success == false || (a.success && b.success == null)) return -1;
    return 0;
  });
};
