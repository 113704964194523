export interface KubeConfigResponseDto {
  kubeconfig: string;
}

export const triggerDownload = (content: string, filename: string) => {
  const element = document.createElement("a");
  const file = new Blob([content], { type: "application/x-yaml" });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
