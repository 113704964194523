import {
  InitiativeDto,
  InitiativeSearchCriteria,
  PagedResponse,
} from "_sredx/types";
import { useQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";

interface UseGetInitiativesProps {
  params: InitiativeSearchCriteria;
}

export const useGetInitiatives = ({ params }: UseGetInitiativesProps) => {
  return useQuery<PagedResponse<InitiativeDto>>({
    queryKey: [QUERY_KEYS.initiatives, params],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getInitiatives(), {
        params,
      });
    },
    retry: 0,
    keepPreviousData: true,
  });
};
