import React, { FC } from "react";
import Select, {
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  InputProps,
  MultiValueProps,
  StylesConfig,
} from "react-select";
import { Tag } from "_sredx/_ui/Tag";
import { Thumbnail } from "components/service/ServiceCard/ServiceCard";
import styles from "./style.module.css";
import { ChevronDownIcon } from "@heroicons/react/outline";

interface SelectSearchInputPropos {
  options: any[];
  onChange: (value: any[]) => void;
  componentName: string;
  team?: boolean;
  Icon?: FC<any>;
}

const SelectSearchInput: FC<SelectSearchInputPropos> = ({
  options,
  onChange,
  componentName,
  team,
  Icon,
  ...props
}) => {
  const colourStyles: StylesConfig<any[], true> = {
    indicatorsContainer: (styles: any) => {
      return {
        ...styles,
        padding: "0",
        ">*": {
          padding: "0 !important",
        },
      } as CSSObjectWithLabel;
    },
  };

  return (
    <div className=" flex-1 min-w-0">
      <Select
        components={{
          ValueContainer: (props) => <SelectValueContainer {...props} />,
          Input: (props) => (
            <SelectInput
              {...props}
              placeholder={`Filter by ${componentName}`}
            />
          ),
          Option: (props: any) => (
            <SelectOption {...props} team={team} option={props.data} />
          ),
          MultiValue: (props) => <SelectMultiValueLabel {...props} />,
          Control: (props) => (
            <div className={styles.valueContainer} {...props}>
              {props.children}
            </div>
          ),
          IndicatorSeparator: () => null,
          DropdownIndicator: (props) => <SelectIndicatorDown {...props} />,
        }}
        isMulti
        isSearchable
        options={options}
        openMenuOnClick={false}
        placeholder={null}
        //   formatOptionLabel={(option: any) => (
        //     <SelectOption option={option} />
        //   )}
        closeMenuOnSelect={true}
        getOptionLabel={(option: any) => option.name}
        getOptionValue={(option: any) => option.name}
        styles={colourStyles}
        onChange={(value) => onChange(value as any[])}
        {...props}
      />
    </div>
  );
};

export default SelectSearchInput;

const SelectInput = (props: InputProps<any>) => {
  return <input {...props} className={`focus:ring-0 ${styles.input}`} />;
};
const SelectOption = ({ option, team, ...props }: any) => {
  return (
    <div
      {...props.innerProps}
      className="flex gap-2 px-4 py-2 items-center hover:bg-cyan-50 cursor-pointer"
    >
      {team && (
        <Thumbnail
          size="small"
          name={option.name}
          className="w-2 h-2"
          url={`https://api.dicebear.com/7.x/identicon/svg?seed=${option.name}`}
        />
      )}{" "}
      {option.name}{" "}
    </div>
  );
};

const SelectMultiValueLabel = (props: MultiValueProps<any>) => {
  return (
    <Tag
      tag={props.data.name}
      colored={false}
      onClick={props.removeProps.onClick as any}
    />
  );
};

const SelectValueContainer = ({ children, ...props }: any) => {
  return (
    <div className="flex-1 flex gap-1" {...props}>
      {children}
    </div>
  );
};
const SelectIndicatorDown = (props: DropdownIndicatorProps<any>) => {
  return (
    <div {...props.innerProps}>
      <ChevronDownIcon className=" p-0 h-4 text-slate-500" />
    </div>
  );
};
