import {Menu} from "@headlessui/react";
import {ChevronDownIcon, SortAscendingIcon, SortDescendingIcon} from "@heroicons/react/solid";
import clsx from "clsx";
import React, {FC} from "react";

export interface SortOption {
    label: string;
    order: "asc" | "desc";
}

interface FilterComponentProps {
    sortOptions: SortOption[];
    selectedSort: SortOption | undefined;
    onChange: (filterOption: SortOption | undefined) => void;
}

const SortFilterComponent: FC<FilterComponentProps> = ({sortOptions, selectedSort, onChange}) => {
    let selectedFilterView;

    if (!selectedSort) {
        selectedFilterView = (
            <>
                <SortAscendingIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true"/>
                Sort
                <ChevronDownIcon className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
            </>
        );
    } else {
        selectedFilterView = (
            <>
                {selectedSort.order === "asc" ? (
                    <SortAscendingIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true"/>
                ) : (
                    <SortDescendingIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true"/>
                )}
                {selectedSort.label}
            </>
        );
    }

    return (
        <div className="text-xl font-sans min-w-[140px]">
            <Menu as="div" className="relative h-full">
                <Menu.Button
                    data-testid="sort-filter-selected-button"
                    className="w-full h-full bg-white border border-gray-700  px-4 flex items-center justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 ">
                        {selectedFilterView}
                </Menu.Button>
                <Menu.Items
                    className="origin-top-right z-10 absolute right-0 w-full md:w-56 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({active}) => (
                                <button
                                    data-testid="sort-filter-default-option"
                                    onClick={() => {
                                        onChange(undefined);
                                    }}
                                    className={clsx(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'flex justify-between gap-4 px-5 md:px-4 py-2 text-sm w-full'
                                    )}
                                >
                                    <span className="">Default</span>
                                </button>
                            )}
                        </Menu.Item>
                        {sortOptions.map((filterOption) => (
                            <Menu.Item key={`${filterOption.label}-${filterOption.order}`}>
                                {({active}) => (
                                    <button
                                        onClick={() => {
                                            onChange(filterOption)
                                        }}
                                        data-testid={`sort-${filterOption.label}-${filterOption.order}`}
                                        className={clsx(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'flex justify-between gap-4 px-5 md:px-4 py-2 text-sm w-full'
                                        )}
                                    >
                                        <span className="">{filterOption.label}</span>
                                        {filterOption.order === "asc" ?
                                            <SortAscendingIcon className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                                                               aria-hidden="true"/>
                                            : <SortDescendingIcon
                                                className="text-right ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                                                aria-hidden="true"/>}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Menu>
        </div>
    );
};

export default SortFilterComponent;