import { Button, TableCell, TableRow } from "_sredx/_ui";
import { ServiceBaseDto } from "_sredx/types";
import { Tag } from "_sredx/_ui/Tag";
import { useNavigate } from "react-router-dom";

interface TeamDetailServiceListRowProps {
  service: ServiceBaseDto;
}

export const TeamDetailsServiceListRow = ({
  service,
}: TeamDetailServiceListRowProps) => {
  const navigate = useNavigate();
  const handleClickView = () => {
    navigate(`/services/${service.id}`);
  };

  return (
    <TableRow>
      <TableCell>{service.name}</TableCell>
      <TableCell>{service.tag}</TableCell>

      <TableCell>
        <div className="flex gap-2">
          {service.metadata &&
            service.metadata.tags
              ?.slice(0, 2)
              .map((tag: string) => <Tag tag={tag} />)}
          {service.metadata?.tags && service.metadata.tags?.length > 2 && (
            <Tag
              colored={false}
              tag={`+ ${service.metadata.tags?.length - 2}`}
            />
          )}
        </div>
      </TableCell>

      {service.tools && (
        <TableCell>
          {service.tools.length}
        </TableCell>
      )}
      <TableCell align="right">
        <Button size="small" variant="neutral" onClick={handleClickView}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};
