import clsx from "clsx";
import { Text } from "_sredx/_ui/Text";
import { Avatar } from "_sredx/_ui/Avatar";
import { ChevronDownIcon } from "_sredx/_ui/Icon";
import styles from "./HeaderUser.module.css";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import { HeaderUserAction, HeaderUserActionType } from "./types";

interface HeaderUserProps {
  name: string;
  caption?: string;
  hideName?: boolean;
  onAction: (actionType: HeaderUserActionType) => void;
  actions: HeaderUserAction[];
}

export const HeaderUser = ({
  name,
  caption,
  hideName = false,
  onAction,
  actions,
}: HeaderUserProps) => {
  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger className={styles.dropdown_trigger}>
        <div className={clsx("nemo-header-user", styles.wrapper)}>
          <Avatar name={name} size={28} />
          {!hideName && (
            <div
              className={clsx(
                "nemo-header-user-info-wrapper",
                styles.info_wrapper
              )}
            >
              <Text
                className={clsx("nemo-header-user-name", styles.name)}
                size={"md"}
              >
                {name}
              </Text>
              {caption && (
                <Text
                  className={clsx("nemo-header-user-caption", styles.caption)}
                  size={"sm"}
                >
                  {caption}
                </Text>
              )}
            </div>
          )}

          <div className={clsx("nemo-header-user-arrow", styles.arrow)}>
            <ChevronDownIcon />
          </div>
        </div>
      </RadixDropdownMenu.Trigger>

      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content className={styles.dropdown_menu} align="end">
          {actions.map((action, index) => {
            return (
              <RadixDropdownMenu.Item
              key={index}
                className={styles.dropdown_menu_item}
                onSelect={() => onAction(action.type)}
              >
                {action.component}
              </RadixDropdownMenu.Item>
            );
          })}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};
