import * as RadixPopover from "@radix-ui/react-popover";
import { ChevronDownIcon } from "_sredx/_ui/Icon";
import {
  Avatar,
  Searchbar,
  SelectPopoverContent,
  SelectPopoverItems,
  SelectPopoverSelectedItem,
  SelectPopoverSelectedItems,
  SelectPopoverTitle,
} from "_sredx/_ui";
import { useInfiniteGetDomains } from "_sredx/services";
import { useEffect, useMemo, useState } from "react";
import { DomainBaseDto } from "_sredx/types";
import { mapInfiniteDomains } from "./utils";

import styles from "./DomainSelectContainer.module.css";

interface DomainSelectContainerProps {
  onChange?: (domains: DomainBaseDto[]) => void;
  countIndicator?: boolean;
  initialValue?: {
    name: string;
    id: string;
    [key: string]: any;
  }[];
}

export const DomainSelectContainer = ({
  onChange,
  countIndicator = true,
  initialValue,
}: DomainSelectContainerProps) => {
  // State
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedDomains, setSelectedDomains] = useState<DomainBaseDto[]>([]);

  // Services
  const {
    data: infiniteDomains,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
  } = useInfiniteGetDomains({
    params: {
      name: searchQuery,
    },
  });

  //Side effects
  useEffect(() => {
    if (initialValue) {
      setSelectedDomains(
        initialValue.map((domain) => ({
          name: domain.name,
          id: domain.id,
          description: "",
          services: [],
          createdAt: "",
        }))
      );
    }
  }, [initialValue]);
  // Derived data
  const domains = useMemo(() => {
    return mapInfiniteDomains(infiniteDomains);
  }, [infiniteDomains]);

  const displayedDomains = useMemo(() => {
    return domains.filter((domain) => {
      const isSelected = selectedDomains.some(
        (selectedDomain) => selectedDomain.id === domain.id
      );
      return !isSelected;
    });
  }, [domains, selectedDomains]);

  // Event handlers
  const handleOnSearch = (value: string) => {
    // [TODO] add debounce
    setSearchQuery(value);
  };
  const handleOnAddDomain = (domain: DomainBaseDto) => {
    setSelectedDomains((prev) => [...prev, domain]);
    onChange?.([...selectedDomains, domain]);
  };
  const handleOnClear = () => {
    setSelectedDomains([]);
    onChange?.([]);
  };
  const handleOnRemoveDomain = (domain: DomainBaseDto) => {
    setSelectedDomains((prev) => prev.filter((t) => t.id !== domain.id));
    onChange?.(selectedDomains.filter((t) => t.id !== domain.id));
  };
  const handleOnLoadMore = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  return (
    <RadixPopover.Root>
      <RadixPopover.Trigger asChild>
        <button className={styles.trigger_btn}>
          {(countIndicator || selectedDomains.length == 0) && (
            <span className={styles.trigger_label}>Domains</span>
          )}

          {selectedDomains.length > 0 && (
            <div className="flex gap-2">
              {countIndicator ? (
                selectedDomains.length
              ) : (
                <>
                  {selectedDomains.slice(0, 2).map((domain) => (
                    <SelectPopoverSelectedItem key={domain.id}>
                      <SelectedDomain domain={domain} />
                    </SelectPopoverSelectedItem>
                  ))}
                  {selectedDomains.length > 2 &&
                    `+${selectedDomains.length - 2}`}
                </>
              )}
            </div>
          )}
          <div className={styles.trigger_icon}>
            <ChevronDownIcon />
          </div>
        </button>
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          className={styles.content}
          align={"start"}
          sideOffset={8}
        >
          <SelectPopoverContent>
            <SelectPopoverTitle title={"Domains"} onClear={handleOnClear} />
            <Searchbar
              placeholder={"Search domains by name"}
              size={"small"}
              autoFocus
              onChange={handleOnSearch}
              value={searchQuery}
            />
            <SelectPopoverSelectedItems>
              {selectedDomains.map((domain) => (
                <SelectPopoverSelectedItem
                  key={domain.id}
                  onRemove={() => handleOnRemoveDomain(domain)}
                >
                  <SelectedDomain domain={domain} />
                </SelectPopoverSelectedItem>
              ))}
            </SelectPopoverSelectedItems>

            <SelectPopoverItems
              items={displayedDomains}
              renderItem={(domain) => <DomainOption domain={domain} />}
              onItemClick={handleOnAddDomain}
              isLoading={isLoading}
              isFetching={isFetching}
              loadMore={handleOnLoadMore}
              loadingCaption={"Loading domains..."}
            />
          </SelectPopoverContent>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

const SelectedDomain = ({
  domain,
}: {
  domain: { name: string; id: string };
}) => {
  return (
    <>
      <Avatar rectangle size={16} name={domain.name} avatarStyle={"initials"} />
      <span className={styles.selected_item_label}>{domain.name}</span>
    </>
  );
};
const DomainOption = ({ domain }: { domain: { name: string; id: string } }) => {
  return (
    <>
      <Avatar rectangle size={16} name={domain.name} avatarStyle={"initials"} />
      <span className={styles.item_label}>{domain.name}</span>
    </>
  );
};
