import { Fragment } from "react";
import styles from "./KeyValueProperties.module.css";
import { Link } from "react-router-dom";

export const KeyValueProperties = ({
  values,
}: {
  values: {
    key: string;
    value: string | JSX.Element;
    icon?: JSX.Element;
    link?: string;
  }[];
}) => (
  <div className={styles.properties}>
    {values.map((value, i) => (
      <Fragment key={i}>
        <div className={styles.key}>
          {value.icon ?? <svg />} {value.key}
        </div>
        {value.link ? (
          <Link to={value.link} target="_blank" className={styles.link}>
            {value.value}
          </Link>
        ) : (
          <div className={styles.value}>{value.value}</div>
        )}
      </Fragment>
    ))}
  </div>
);
