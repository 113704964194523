interface IconProps {
  size?: number;
  color?: string;
}
export const PlusCircleIcon = ({ size, color = "currentColor" }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12.85 7h-1.7v4.15H7v1.7h4.15V17h1.7v-4.15H17v-1.7h-4.15V7Z"
      />
      <path
        fill={color}
        d="M12 2.005c-2.568-.083-5.062.922-6.936 2.796C3.19 6.674 2.088 9.263 2 12c.088 2.737 1.19 5.326 3.064 7.2 1.874 1.873 4.368 2.878 6.936 2.795 2.568.083 5.062-.922 6.936-2.796C20.81 17.326 21.912 14.737 22 12c-.088-2.737-1.19-5.326-3.064-7.2C17.062 2.928 14.568 1.923 12 2.006Zm0 18.324c-2.154.083-4.25-.746-5.831-2.307C4.587 16.46 3.65 14.296 3.563 12c.087-2.296 1.024-4.46 2.606-6.022 1.58-1.56 3.677-2.39 5.831-2.307 2.153-.083 4.25.746 5.831 2.307C19.413 7.54 20.35 9.704 20.438 12c-.088 2.296-1.025 4.46-2.607 6.022-1.58 1.56-3.678 2.39-5.831 2.307Z"
      />
    </svg>
  );
};
