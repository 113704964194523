import {REACT_APP_KEYCLOAK_CLIENT_ID, REACT_APP_KEYCLOAK_REALM, REACT_APP_KEYCLOAK_URL} from "config/config";
import Keycloak from "keycloak-js";



const keycloak = Keycloak({
    url: REACT_APP_KEYCLOAK_URL,
    realm: (REACT_APP_KEYCLOAK_REALM)!,
    clientId: (REACT_APP_KEYCLOAK_CLIENT_ID)!,
});

export default keycloak;