
import { PagedResponse, TeamBaseDto } from "_sredx/types";
import { InfiniteData } from "react-query";

export const mapInfiniteTeams = (
    infiniteTeams?: InfiniteData<PagedResponse<TeamBaseDto>>
  ) => {
    if (
      !infiniteTeams ||
      !infiniteTeams.pages ||
      !Array.isArray(infiniteTeams.pages) ||
      infiniteTeams.pages.length === 0
    ) {
      return [];
    }
    const { pages } = infiniteTeams;
    return pages.reduce((result: TeamBaseDto[], page) => {
      if (page.content && Array.isArray(page.content)) {
        result.push(...page.content);
      }
  
      return result;
    }, []);
  };