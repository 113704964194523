import { ClusterForm } from "containers/SelfService/ClustersListContainer/ClusterCreateContainer/utils";
import { useAxios } from "hooks/useAxios";
import { useMutation, useQueryClient } from "react-query";
import { SELF_SERVICE_PATHS, SELF_SERVICE_QUERY_KEYS } from "./paths";

export const useCreateCluster = () => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();
  const createCluster = async (data: ClusterForm) => {
    return await axios.post(SELF_SERVICE_PATHS.CLUSTERS, data);
  };

  return useMutation(createCluster, {
    onSuccess: () => {
      queryClient.invalidateQueries(SELF_SERVICE_QUERY_KEYS.CLUSTERS);
    },
  });
};
