const ProgressBarSkeleton: React.FC = () => {
    return (
      <div
        className="h-3 bg-gray-300 animate-pulse"
        style={{ width: "100%" }}
        role="progressbar"
      ></div>
    );
  };
  
export default ProgressBarSkeleton;
  