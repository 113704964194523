import styles from "./ServiceScore.module.css";
import { round } from "lodash";
import { useLayoutEffect, useRef, useState } from "react";

const getScorePercentage = (score: number, total: number) => {
  if (total == 0 || score == 0) return 0;
  return round((score / total) * 100, 1);
};

const getScoreColor = (score: number, total: number) => {
  const scorePercentage = getScorePercentage(score, total);
  if (scorePercentage >= 80) return "#00b341";
  if (scorePercentage >= 50) return "#f5a623";
  return "#ff0000";
};

interface ServiceScoreProps {
  score?: number;
  total?: number;
}

export const ServiceScore = ({ score = 0, total = 0 }: ServiceScoreProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [percentageWidth, setPercentageWidth] = useState(0);
  const roundedScorePercentage = getScorePercentage(score, total);
  useLayoutEffect(() => {
    setPercentageWidth(
      (roundedScorePercentage / 100) * (ref.current?.offsetWidth ?? 1)
    );
  }, [score, total, ref.current]);

  const scoreColor = getScoreColor(score, total);

  return (
    <div className={styles.wrapper}>
      <div className={styles.value}>
        <div style={{ color: scoreColor }}>{roundedScorePercentage}%</div>
        <div>
          {score}/{total}
        </div>
      </div>

      <div className={styles.progress_wrapper} ref={ref}>
        <div
          className={styles.progress}
          style={{
            width: percentageWidth,
            backgroundColor: scoreColor,
          }}
        />
      </div>
    </div>
  );
};
