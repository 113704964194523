import React, { forwardRef } from "react";
import clsx from "clsx";
import styles from "./TableCell.module.css";

const mapAlign = (align: "left" | "center" | "right" | "justify" | "char") => {
  switch (align) {
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    case "center":
      return "center";
    default:
      return "flex-start";
  }
};

interface TableCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  noPadding?: boolean;
}
export const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  (
    { className, children, align = "left", noPadding = false, ...props },
    ref
  ) => {
    return (
      <td
        ref={ref}
        align={align}
        className={clsx("nemo-table-cell", styles.wrapper, className)}
        {...props}
      >
        <div
          style={{
            justifyContent: mapAlign(align),
            padding: noPadding ? 0 : undefined,
          }}
        >
          {children}
        </div>
      </td>
    );
  }
);
