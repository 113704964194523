import React, { useEffect, useState } from "react";
import Select from "react-select";
import ScoreCard from "../../../../models/scorecard/ScoreCard";
import { subYears } from "date-fns";
import Service from "../../../../models/service/Service";
import useFilteredEvaluations from "../../../../hooks/evaluations/useFilteredEvaluations";
import CustomDatePicker from "../../../../components/basic/CustomDatePicker/CustomDatePicker";
import { LoaderIcon } from "react-hot-toast";
import { ScoreCardAreaChart } from "../../../../components/basic/ScoreCardAreaChart/ScoreCardAreaChart";
import ScoreCardsProgressWidgetSkeleton, {
  SelectSkeleton,
} from "./ScoreCardsProgressWidgetSkeleton";

interface ScoreCardsProgressWidgetProps {
  scoreCards: ScoreCard[];
  services: Service[];
  isLoadingScoreCards: boolean;
  isLoadingServices: boolean;
}

const ScoreCardsProgressWidget: React.FC<ScoreCardsProgressWidgetProps> = ({
  scoreCards,
  services,
  isLoadingScoreCards,
  isLoadingServices,
}) => {
  const [selectedScoreCard, setSelectedScoreCard] = useState<ScoreCard>();
  const [selectedService, setSelectedService] = useState<Service>();
  const [fromDate, setFromDate] = useState(subYears(new Date(), 1));
  const [toDate, setToDate] = useState(new Date());
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  useEffect(() => {
    if (scoreCards?.length) {
      setSelectedScoreCard(scoreCards[0]);
    }
    if (services?.length) {
      setSelectedService(services[0]);
    }
  }, [scoreCards, services]);

  const {
    isLoading: isLoadingEvaluations,
    data: evaluations,
    refetch: refetchEvaluations,
  } = useFilteredEvaluations(
    selectedScoreCard?.id ?? "",
    selectedService?.id ?? "",
    Math.floor(fromDate.getTime() / 1000),
    Math.floor(toDate.getTime() / 1000)
  );

  const isLoading =
    isLoadingServices || isLoadingScoreCards || isLoadingEvaluations;
  useEffect(() => {
    if (selectedScoreCard && selectedService && fromDate && toDate) {
      refetchEvaluations().then(() => {
        if (!hasLoadedOnce) {
          setHasLoadedOnce(true);
        }
      });
    }
  }, [
    selectedScoreCard,
    selectedService,
    fromDate,
    toDate,
    refetchEvaluations,
    hasLoadedOnce,
  ]);

  const chartData = evaluations
    ? evaluations.map((evaluation) => ({
        evaluationTime: evaluation.evaluationTime,
        score: evaluation.evaluationResult.scorePercentage,
      }))
    : [];

  const scoreCardOptions = scoreCards
    ? scoreCards.map((scoreCard) => ({
        value: scoreCard.id,
        label: scoreCard.name,
      }))
    : [];

  const serviceOptions = services
    ? services.map((service) => ({
        value: service.id,
        label: service.name,
      }))
    : [];

  const widget = (
    <div>
      <div className="flex p-5 justify-between items-center rounded">
        <div className="font-bold uppercase">Scorecard progress</div>
        <div className="font-light flex flex-col xl:flex-row gap-4 items-center">
          <div className="relative flex-grow">
            {isLoadingScoreCards ? (
              <SelectSkeleton />
            ) : (
              <Select
                value={scoreCardOptions.find(
                  (option) => option.value === selectedScoreCard?.id
                )}
                onChange={(option) => {
                  const newSelectedScoreCard = scoreCards?.find(
                    (scoreCard) => scoreCard.id === option?.value
                  );
                  setSelectedScoreCard(newSelectedScoreCard);
                }}
                options={scoreCardOptions}
              />
            )}
          </div>
          <div className="relative flex-grow">
            {isLoadingServices ? (
              <SelectSkeleton />
            ) : (
              <Select
                value={serviceOptions.find(
                  (option) => option.value === selectedService?.id
                )}
                onChange={(option) => {
                  const newSelectedService = services?.find(
                    (service) => service.id === option?.value
                  );
                  setSelectedService(newSelectedService);
                }}
                options={serviceOptions}
              />
            )}
          </div>
          <div className="flex border rounded-md text-sm text-gray-500 ml-4">
            <CustomDatePicker
              selectedDate={fromDate}
              onDateChange={(date: Date) => setFromDate(date)}
            />
          </div>
          <div className="flex border rounded-md text-sm text-gray-500 ml-4">
            <CustomDatePicker
              selectedDate={toDate}
              onDateChange={(date: Date) => setToDate(date)}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="px-4 flex justify-center">
        {isLoadingEvaluations && hasLoadedOnce ? (
          <div className="min-h-[400px] my-2 text-gray-500 flex gap-2 justify-center items-center">
            <LoaderIcon />
            Loading evaluations data...
          </div>
        ) : (
          <ScoreCardAreaChart data={chartData} />
        )}
      </div>
    </div>
  );

  if (isLoading && !hasLoadedOnce) {
    return <ScoreCardsProgressWidgetSkeleton />;
  }

  return widget;
};

export default ScoreCardsProgressWidget;
