import { TableHead, TableHeader, Text } from "_sredx/_ui";

export const TeamDetailsMemberListHeader = () => (
  <TableHeader>
    <TableHead>
      <Text>Name</Text>
    </TableHead>
    <TableHead>
      <Text>Status</Text>
    </TableHead>
    <TableHead>
      <Text>Email</Text>
    </TableHead>
    <TableHead>
      <Text>Source</Text>
    </TableHead>

    <TableHead>
      <Text>Invitation</Text>
    </TableHead>
    <TableHead>
      <Text>Role</Text>
    </TableHead>
    <TableHead align="right">
      <Text>Actions</Text>
    </TableHead>
  </TableHeader>
);
