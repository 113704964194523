import React from "react";
import { Tag } from "_sredx/_ui/Tag";

import { ScoreCardDto } from "_sredx/types";
import { Text } from "_sredx/_ui";

import styles from "./ScoreCardCategories.module.css";
import { getListOfUniqueCategoriesFromListOfRules } from "./utils";

interface ScoreCardCategoriesProps {
  scoreCard: ScoreCardDto;
}

export const ScoreCardCategories = ({
  scoreCard,
}: ScoreCardCategoriesProps) => {
  // Derived Variables
  const ruleCategories = getListOfUniqueCategoriesFromListOfRules(
    scoreCard.rules
  );
  const displayedCategories = ruleCategories.slice(0, 3);

  const length = ruleCategories.length;
  const hasMore = length > 3;

  return (
    <div className={styles.wrapper}>
      <Text block size={"sm"} className={styles.title}>
        Categories ({length})
      </Text>
      <div className={styles.content_wrapper}>
        {displayedCategories.map((category, index) => (
          <Tag key={index} tag={category.categoryName} />
        ))}
        {hasMore && (
          <div className={styles.more}>
            <Text size={"xs"}>+{length - 3}</Text>
          </div>
        )}
      </div>
    </div>
  );
};
