import React, {PropsWithChildren} from 'react';
import styles from "./IntegrationsConfigWrapper.module.css";

export const IntegrationsConfigWrapper = ({children} : PropsWithChildren) => {
  return (
      <div className={styles.wrapper}>
        <div className={styles.content_wrapper}>
            {children}
        </div>
      </div>
  );
}
