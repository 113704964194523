import { TeamInfoDto } from "_sredx/types";
import { Avatar, Text } from "_sredx/_ui";
import styles from "./TeamSwitcher.module.css";
import { capitalizeFirstLetter } from "_sredx/utils/string";
import { HeroCheckIcon } from "_sredx/_ui/Icon";
import React from "react";

interface TeamSwitcherItemProps {
  team: TeamInfoDto;
  isSelected?: boolean;
}

export const TeamSwitcherItem = ({
  team,
  isSelected,
}: TeamSwitcherItemProps) => {
  return (
    <div className={styles.item_wrapper}>
      <Avatar size={18} name={team.name} rectangle avatarStyle="identicon" />
      <Text>{capitalizeFirstLetter(team.name)}</Text>
      {isSelected && (
        <div className={styles.check_icon}>
          <HeroCheckIcon />
        </div>
      )}
    </div>
  );
};
