import { PR_CYCLE_TIME_TABS, PrCycleTimeTabType } from "./utils";
import { MetricTab, MetricTabs } from "_sredx/components/Metrics";
import React from "react";

interface PRCycleTimeTabsProps {
  activeTab: PrCycleTimeTabType;
  onTabChange: (tab: PrCycleTimeTabType) => void;
}

export const PRCycleTimeTabs = ({
  activeTab,
  onTabChange,
}: PRCycleTimeTabsProps) => {
  return (
    <MetricTabs>
      <MetricTab
        title="Cycle time"
        active={activeTab === PR_CYCLE_TIME_TABS.PULL_REQUESTS}
        onClick={() => onTabChange(PR_CYCLE_TIME_TABS.PULL_REQUESTS)}
      />
      <MetricTab
        title="Time to review"
        active={activeTab === PR_CYCLE_TIME_TABS.TIME_TO_REVIEW}
        onClick={() => onTabChange(PR_CYCLE_TIME_TABS.TIME_TO_REVIEW)}
      />
      <MetricTab
        title="Time to merge"
        active={activeTab === PR_CYCLE_TIME_TABS.TIME_TO_MERGE}
        onClick={() => onTabChange(PR_CYCLE_TIME_TABS.TIME_TO_MERGE)}
      />
      <MetricTab
        title="Cycle time breakdown"
        active={activeTab === PR_CYCLE_TIME_TABS.CYCLE_TIME_BREAKDOWN}
        onClick={() => onTabChange(PR_CYCLE_TIME_TABS.CYCLE_TIME_BREAKDOWN)}
      />
    </MetricTabs>
  );
};
