import { InitiativeServiceEvaluation } from "./types";

export const calculateTotalSuccessRules = (
  initiativeServiceEvaluation: InitiativeServiceEvaluation
) => {
  return initiativeServiceEvaluation.rules.reduce((count, rule) => {
    return rule.success ? count + 1 : count;
  }, 0);
};

export const resolveProgressColor = (progress: number) => {
  if (progress >= 80) return "#00b341";
  if (progress >= 50) return "#f5a623";
  return "#ff0000";
};
