import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import styles from "./Switcher.module.css";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Text } from "_sredx/_ui";
type Option = {
  label: string;
  value: string;
};
interface SwitcherProps {
  selected: Option;
  options: Option[];
  onSelect: (option: Option) => void;
}

export const DefaultSwitcher = ({
  selected,
  options = [],
  onSelect,
}: SwitcherProps) => {
  const handleOnSelect = (option: Option) => {
    onSelect && onSelect(option);
  };

  if (!options.length) {
    return null;
  }
  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger className={styles.trigger}>
        {/* <TeamSwitcherTrigger selectedTeam={selectedTeam} /> */}
        <div className={styles.trigger_wrapper}>
          <Text className={styles.team_name}>{selected.label}</Text>
          <div className={styles.arrow}>
            <ChevronDownIcon />
          </div>
        </div>
      </RadixDropdownMenu.Trigger>

      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content className={styles.dropdown_menu}>
          {options.map((option, index) => (
            <RadixDropdownMenu.Item
              key={index}
              className={styles.dropdown_menu_item}
              onSelect={() => handleOnSelect(option)}
            >
              <div className={styles.option}>{option.label}</div>
            </RadixDropdownMenu.Item>
          ))}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};
