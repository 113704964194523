interface ClockIconProps {
  size?: number;
  color?: string;
}
export const ClockIcon = ({ size, color = "currentColor" }: ClockIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={color}
        d="M6.5 10a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-.9a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2Zm-.45-3.415V2.8h.9v2.516l1.777 1.777-.634.635L6.05 5.684Z"
      />
    </svg>
  );
};
