import { Button, ButtonLabel, Card, Heading } from "_sredx/_ui";
import React from "react";
import styles from "./OnboardingNoValidConfigModal.module.css";

interface OnboardingNoValidConfigModalProps {
  onClose: () => void;
}

export const OnboardingNoValidConfigModal = ({
  onClose,
}: OnboardingNoValidConfigModalProps) => {
  return (
    <Card>
      <div className={styles.wrapper}>
        <Heading level={4}> You cannot proceed to next step</Heading>
        <hr />
        <p>
          None of the providers are configured or are not passing their
          connectivity test. Make sure at least one of them is configured and
          passes their connectivity test so we can import services.
        </p>

        <Button className={styles.button} variant="neutral" onClick={onClose}>
          <ButtonLabel>Ok</ButtonLabel>
        </Button>
      </div>
    </Card>
  );
};
