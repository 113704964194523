import { forwardRef } from "react";
import { IconProps } from "./types";

export const IstioIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
        <title>Istio</title>
        <path fill="#466BB0" d="M4 21 20 21 10 24zM4 20 10 19 10 8zM11 19 20 20 11 0z" />
      </svg>
    );
  }
);
