import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverItem,
  PopoverTrigger,
} from "../../Popover";
import clsx from "clsx";
import inputStyles from "../Input.module.css";
import { endOfDay, startOfDay } from "date-fns";
import { Calendar } from "../../Calendar";
import {
  DATE_OPTIONS,
  DateOption,
  mapDateOptionToRange,
  mapRangeToDateOption,
  mapRangeToLabel,
} from "./utils";

interface InputDateRangePopoverProps {
  value: [Date, Date];
  onChange: (value: [Date, Date]) => void;
}

export const InputDateRangePopover = ({
  value,
  onChange,
}: InputDateRangePopoverProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleDateOptionChange = (_value: [Date, Date]) => {
    setPopoverOpen(false);
    onChange(_value);
  };

  return (
    <Popover onOpenChange={(val) => setPopoverOpen(val)} open={popoverOpen}>
      <PopoverTrigger>
        <span className={clsx(!value && inputStyles.trigger_label)}>
          {mapRangeToLabel(value)}
        </span>
      </PopoverTrigger>
      <PopoverContent>
        <InputDateRangeOptions
          value={value}
          onChange={handleDateOptionChange}
        />
      </PopoverContent>
    </Popover>
  );
};

interface InputDateRangeOptionsProps {
  value: [Date, Date];
  onChange: (value: [Date, Date]) => void;
}

const InputDateRangeOptions = ({
  value,
  onChange,
}: InputDateRangeOptionsProps) => {
  const [option, setOption] = useState<DateOption | null>(
    mapRangeToDateOption(value)
  );
  const [displayOptions, setDisplayOptions] = useState(true);

  const handleOnChange = (_option: DateOption) => {
    if (_option === DATE_OPTIONS.DATE_TO_NOW) {
      setDisplayOptions(false);
    } else if (_option === DATE_OPTIONS.DATE_RANGE) {
      setDisplayOptions(false);
    } else {
      onChange(mapDateOptionToRange(_option));
    }
    setOption(_option);
  };

  const handleOnCalendarChange = (_value: [Date, Date]) => {
    const [start, end] = _value;
    if (DATE_OPTIONS.DATE_TO_NOW === option) {
      onChange([startOfDay(start), new Date()]);
    } else {
      onChange([startOfDay(start), endOfDay(end)]);
    }
  };

  if (!displayOptions) {
    const range = option === DATE_OPTIONS.DATE_RANGE;
    return <Calendar range={range} onChange={handleOnCalendarChange} />;
  }

  return (
    <>
      {Object.values(DATE_OPTIONS)
        .filter((dop) => dop !== DATE_OPTIONS.DATE_TO_NOW)
        .map((_option) => {
          const selected = _option === option;
          return (
            <PopoverItem
              key={_option}
              onClick={() => {
                handleOnChange(_option);
              }}
              selected={selected}
            >
              {_option}
            </PopoverItem>
          );
        })}
    </>
  );
};
