import { ListingCard } from "components/octo-ui/ListingCard";
import styles from "./ClusterCard.module.css";
import {
  CalendarIcon,
  CloudIcon,
  CloudUploadIcon,
  DownloadIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { BiGitBranch, BiLogoKubernetes } from "react-icons/bi";
import { IconThumbnail } from "components/service/ServiceCard/ServiceCard";
import { FaNetworkWired } from "react-icons/fa";
import { PiHexagon } from "react-icons/pi";
import { HiCubeTransparent } from "react-icons/hi2";
import { Cluster } from "types/selfService";
import { isKubeConfigDownloadEnabled, mapClusterPhaseToStatus, resolveLogoText, resolveToolUrl } from "./utils";
import { Badges } from "./Badges";
import { KeyValueProperties } from "./KeyValueProperties";
import StatusIndicator from "components/octo-ui/StatusIndicator/StatusIndicator";
import { Button } from "components/octo-ui/Button/Button";

interface ClusterCardProps {
  cluster: Cluster;
  onDownload?: (clusterId: string) => void;
  isDownloading?: boolean;
}



export const ClusterCard = ({ cluster, onDownload, isDownloading = false }: ClusterCardProps) => {

    const status = mapClusterPhaseToStatus(cluster.phase);

    const isDownloadEnabled = isKubeConfigDownloadEnabled(cluster);

    const handleOnDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();  
        if(onDownload) {
            onDownload(cluster.name);
        }
    };

  return (
    <ListingCard link={`clusters/${cluster.name}`}>
      <div className={styles.wrapper}>
          
      <div className={styles.head}>
            <h3>{cluster.name}</h3>
            {onDownload && isDownloadEnabled && (
                <Button 
                    variant="ghost" 
                    onClick={handleOnDownload}
                    isLoading={isDownloading}
                    icon={<DownloadIcon className="h-4" />}
                >
                    KubeConfig
                </Button>
            )}
        </div>
          
        <StatusIndicator label={cluster.phase} status={status} />
          
        <div className={styles.body}>
          <KeyValueProperties
            values={[
              {
                icon: <CalendarIcon />,
                key: "Last Update",
                value: cluster.age,
              },
              {
                icon: <CloudIcon />,
                key: "Provider",
                value: resolveLogoText(cluster.cloudProvider),
              },
              {
                icon: <UserIcon />,
                key: "Cloud Account",
                value: cluster.accountName,
              },
              {
                icon: <BiGitBranch />,
                key: "Gitops",
                value: cluster.repoName,
                link: cluster.repoUrl,
              },
              {
                icon: <CloudUploadIcon />,
                key: "CD Tool",
                value: resolveLogoText(cluster.gitOpsTool),
              },
            ]}
          />
          <Badges
            badges={[
              {
                icon: <BiLogoKubernetes />,
                key: "Kubernetes version",
                value: cluster.version,
              },
              {
                icon: <PiHexagon />,
                key: "Node Type",
                value: cluster.nodeType,
              },
              {
                icon: <HiCubeTransparent />,
                key: "Pool Size",
                value: cluster.size.toString(),
              },
              {
                icon: <FaNetworkWired />,
                key: "CNI Type",
                value: cluster.cni,
              },
            ]}
          />
        </div>
        <div className={styles.tools}>
          {cluster.tools.map((tool, i) => (
            <IconThumbnail
              key={i}
              name={tool}
              icon={resolveToolUrl(tool) }
              popover
              link="#"
            />
          ))}
        </div>
      </div>
    </ListingCard>
  );
};
