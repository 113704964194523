import clsx from "clsx";
import styles from "componentsv2/shared/StepperComponent/Stepper.module.css";

interface resolveStepperHeaderItemStyleProps {
  condition: boolean;
}

export const resolveStepperHeaderTitle = (order: number) =>
  `Step ${order.toString()}`;

export const resolveStepperHeaderItemStyle = ({
  condition,
}: resolveStepperHeaderItemStyleProps) =>
  clsx({
    [styles.currentStep]: condition,
  });
