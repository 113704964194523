import { ActionsWrapper } from "components/scorecard/ScoreCardForm/ActionsWrapper/ActionsWrapper";
import { MainInfoForm } from "components/scorecard/ScoreCardForm/MainInfoForm/MainInfoForm";
import { Step, StepperProvider } from "componentsv2/shared/StepperComponent";
import { SCORECARD_CREATION_STEP } from "containers/ScoreCardDeleteContainer/constant";
import { useCreateLevelMedia } from "hooks/media/useCreateLevelMedia";
import { useUpdateLevelMedia } from "hooks/media/useUpdateLevelMedia";
import { useCreateNewScoreCard } from "hooks/scorcards/useCreateNewScoreCard";
import { useUpdateScoreCard } from "hooks/scorcards/useUpdateScoreCard";
import { STEPPER_MODE } from "hooks/stepper/constant";
import { useStepper } from "hooks/stepper/useStepper";
import React, { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  convertListOfRulesToGroupedByCategory,
  getListOfUniqueCategoriesFromListOfRules,
} from "services/scorecards/scorecards.helper";
import { ExtendedLevel, extractLevelsMedia } from "utils/scoreCard";
import Level from "../../../../models/scorecard/Level";
import Rule from "../../../../models/scorecard/Rule";
import RuleCategory from "../../../../models/scorecard/RuleCategory";
import ScoreCard from "../../../../models/scorecard/ScoreCard";
import ConfirmModalComponent from "../../../basic/ConfirmModalComponent/ConfirmModalComponent";
import ListOfScoreCardLevels from "../ListOfLevels/ListOfScorecardLevels";
import ListOfScoreCardRules from "../ListOfRules/ListOfScoreCardRules";

interface ScoreCardFormComponentProps {
  scoreCard?: ScoreCard;
}

export const ScoreCardFormComponent: FC<ScoreCardFormComponentProps> = ({
  scoreCard,
}) => {
  const stepperMethods = useStepper({
    steps: SCORECARD_CREATION_STEP,
    defaultStep: SCORECARD_CREATION_STEP[0].stepKey,
    mode: STEPPER_MODE.TAB,
    displaySteps: false,
  });
  const [ruleCategoriesList, setRuleCategoriesList] = useState(
    getListOfUniqueCategoriesFromListOfRules(scoreCard?.rules ?? [])
  );

  const methods = useForm<ScoreCard>({
    mode: "onChange",
    defaultValues: scoreCard ?? {},
  });

  const navigate = useNavigate();
  const [isRulesEmptyErrorAlertShown, setIsRulesEmptyErrorAlertShown] =
    useState(false);

  const { mutate: createScoreCard } = useCreateNewScoreCard();
  const { mutate: updateScoreCard } = useUpdateScoreCard();
  const { mutate: createLevelMedia } = useCreateLevelMedia();
  const { mutate: updateLevelMedia } = useUpdateLevelMedia();
  const saveScoreCard = async (data: any) => {
    if (!data.rules || data.rules.length === 0) {
      setIsRulesEmptyErrorAlertShown(true);
      return;
    }
    const mediaData = extractLevelsMedia(data.levels as ExtendedLevel[]);
    let apiCall;
    let mediaApiCall: typeof createLevelMedia;
    let mediasMutaion: Array<any> = [];
    if (scoreCard) {
      apiCall = updateScoreCard;
      mediaApiCall = updateLevelMedia;
    } else {
      apiCall = createScoreCard;
      mediaApiCall = createLevelMedia;
    }
    if (mediaData && mediaData.length !== null) {
      mediaData.forEach((mediaData) => {
        const mediaPromise = mediaApiCall(mediaData, {
          onError: (error) => {},
        });
        mediasMutaion.push(mediaPromise);
      });
    }

    apiCall(data, {
      onSuccess: async (newScoreCard: ScoreCard) => {
        await Promise.all(mediasMutaion);
        navigate(`/scorecards/${newScoreCard.id}`);
      },
      onError: (error) => {
        console.log("##error : ", error);
      },
    });
  };

  const cancelChanges = () => {
    methods.reset(
      scoreCard ?? {
        name: "",
        tag: "",
        targetTags: [],
        description: "",
      },
      { keepDefaultValues: true }
    );
  };

  const addRule = (rule: Rule) => {
    const rules = methods.getValues("rules") ?? [];
    methods.setValue("rules", [...rules, rule], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const addlevel = (level: Level) => {
    const levels = methods.getValues("levels") ?? [];
    methods.setValue("levels", [...levels, level], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const updateRule = (updatedRule: Rule) => {
    const rules = methods.getValues("rules") ?? [];
    methods.setValue(
      "rules",
      rules.map((r: any) => {
        if (r.id === updatedRule.id) return updatedRule;
        return r;
      }),
      { shouldDirty: true, shouldValidate: true }
    );
  };

  const updateLevel = (updatedLevel: Level) => {
    const levels = methods.getValues("levels") ?? [];
    methods.setValue(
      "levels",
      levels.map((r: any) => {
        if (r.id === updatedLevel.id) return updatedLevel;
        return r;
      }),
      { shouldDirty: true, shouldValidate: true }
    );
  };

  const deleteRuleById = (deletedId: string) => {
    const rules = methods.getValues("rules") ?? [];
    methods.setValue(
      "rules",
      rules.filter((r: any) => r.id !== deletedId),
      { shouldDirty: true, shouldValidate: true }
    );
  };

  const deleteLevelById = (deletedId: string) => {
    const levels = methods.getValues("levels") ?? [];
    methods.setValue(
      "levels",
      levels.filter((r: any) => r.id !== deletedId),
      { shouldDirty: true, shouldValidate: true }
    );
  };

  const addRuleCategory = (ruleCategory: RuleCategory) => {
    setRuleCategoriesList([ruleCategory, ...ruleCategoriesList]);
  };
  const isSaveButtonValid =
    !methods.formState.isValid || methods.getValues("rules")?.length === 0;
  return (
    <div>
      <StepperProvider {...stepperMethods}>
        <FormProvider {...methods}>
          <ActionsWrapper
            onSubmit={methods.handleSubmit(saveScoreCard)}
            onDiscard={cancelChanges}
            isSaveDisabled={isSaveButtonValid}
          >
            <Step stepKey={SCORECARD_CREATION_STEP[0].stepKey}>
              <MainInfoForm errors={methods.formState.errors} />

              <ConfirmModalComponent
                title={"Ooops!"}
                message={"A scoreCard can't be created without any rules."}
                closeText={"Got it!"}
                isOpen={isRulesEmptyErrorAlertShown}
                setIsOpen={setIsRulesEmptyErrorAlertShown}
              />
            </Step>
            <Step stepKey={SCORECARD_CREATION_STEP[1].stepKey}>
              <ListOfScoreCardRules
                possibleRulesCategories={ruleCategoriesList}
                saveNewCategoryCallBack={addRuleCategory}
                rules={convertListOfRulesToGroupedByCategory(
                  methods.getValues("rules") ?? []
                )}
                deleteRuleByIdCallBack={deleteRuleById}
                updateRuleDetailsCallBack={updateRule}
                creatNewRuleCallBack={addRule}
              />
            </Step>

            {/* /////// ****list of levels creation ***** ////// */}
            <Step stepKey={SCORECARD_CREATION_STEP[2].stepKey}>
              <ListOfScoreCardLevels
                rules={methods.getValues("rules") ?? []}
                levels={methods.watch("levels") ?? []}
                updateLevelDetailsCallBack={updateLevel}
                creatNewLevelCallBack={addlevel}
                deleteLevelByIdCallBack={deleteLevelById}
              />
            </Step>
          </ActionsWrapper>
        </FormProvider>
      </StepperProvider>
    </div>
  );
};
