import { FaSpinner } from "react-icons/fa";
import styles from "./styles.module.css";
export const LoadingOverlay = ({ message }: { message: string }) => {
  return (
    <div className={styles.wrapper}>
      <div>{message}</div>
      <FaSpinner className="animate-spin" />
    </div>
  );
};
