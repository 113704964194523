import React from "react";

import {
  Button,
  Empty,
  Input,
  Loader,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  Text,
} from "_sredx/_ui";
import styles from "./InitiativeDetailsServices.module.css";
import { InitiativeServiceEvaluation } from "./types";
import { InitiativeDetailsServicesRows } from "./InitiativeDetailsServicesRows";
import { InitiativeDetailsServicesHeader } from "./InitiativeDetailsServicesHeader";

interface InitiativeDetailsServicesProps {
  isLoading: boolean;
  initiativeServices: InitiativeServiceEvaluation[];
  onUpdateServices: () => void;
}

export const InitiativeDetailsServices = ({
  isLoading,
  initiativeServices,
  onUpdateServices,
}: InitiativeDetailsServicesProps) => {
  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>Loading services...</Text>
      </div>
    );
  }

  if (!isLoading && initiativeServices && initiativeServices.length === 0) {
    return (
      <Empty
        message={"No services found in this initiative."}
        actionLabel={"Add new services"}
        onActionClick={onUpdateServices}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Input placeholder="Search services" />
        <Button onClick={onUpdateServices}>Edit services</Button>
      </div>
      <div className={styles.table_wrapper}>
        <Table>
          <InitiativeDetailsServicesHeader />
          <TableBody>
            {initiativeServices.map((service) => (
              <InitiativeDetailsServicesRows
                initiativeServiceEvaluation={service}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableFooterCell colSpan={5}>
              <Text>{initiativeServices.length} Services</Text>
            </TableFooterCell>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
};
