const ClusterDetailsInfoSkeleton = () => {
    return (
        <div className="p-6 bg-gray-100">
            <div className="w-full h-8 mb-6 bg-gray-300 animate-pulse"></div>

            <div className="flex space-x-4">
                <div className="flex-1 space-y-4">
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                </div>

                <div className="flex-1 space-y-4">
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                </div>

                <div className="flex-1 space-y-4">
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                    <div className="h-5 bg-gray-300 animate-pulse"></div>
                </div>
            </div>
        </div>
    );
}

export default ClusterDetailsInfoSkeleton;
