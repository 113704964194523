import Tooltip from "components/octo-ui/Tootlip/Tooltip";
import styles from "./Badges.module.css";

export const Badges = ({
  badges,
}: {
  badges: { icon: JSX.Element; value: string; key: string }[];
}) => (
  <div className={styles.badges}>
    {badges.map((badge, i) => (
      <Tooltip key={i} content={[badge.key]} delay={0} direction="top">
        <div className={styles.badge}>
          {badge.icon}
          {badge.value}
        </div>
      </Tooltip>
    ))}
  </div>
);
