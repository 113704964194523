import React from 'react';
import RulesBar from './rules-bar/RulesBar';
import style from "./RulesStyles.module.css";
import "styles/fonts.css";
import clsx from 'clsx';

interface RulesProps{
    passing : number,
    totalRules : number
}

export const Rules = ({passing, totalRules} : RulesProps) => {
  return (
    <div>
          <div className={style.progress_bar }>
            <span className={"text_sm" }>Rules</span>
            <span className={clsx("text_sm", "bold") }>{totalRules}</span>
          </div>
            <RulesBar succeeded={passing} failed={totalRules-passing}/>
          </div>
  )
}
