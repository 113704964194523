import React, { PropsWithChildren } from "react";
import styles from "./Badge.module.css";
import clsx from "clsx";
interface BadgeProps {
  color?: "SUCCESS" | "DANGER" | "WARNING" | "INFO";
}
export const Badge = ({ color, children }: PropsWithChildren<BadgeProps>) => {
  return (
    <div
      className={clsx("nemo-badge", styles.wrapper, styles[color || "default"])}
    >
      {children}
    </div>
  );
};
