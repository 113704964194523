import { PropsWithChildren } from "react";
import clsx from "clsx";
import { Text } from "../Text";
import styles from "./Button.module.css";

export const ButtonLabel = ({ children }: PropsWithChildren) => {
  return (
    <Text className={clsx("nemo-button-label", styles.label)}>{children}</Text>
  );
};
