import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "_sredx/_ui";
import styles from "./ResourceCreationGeneralInfoForm.module.css";
import { FormFieldInput, FormFieldSelect, FormFieldTextArea } from "../Form";
import { TagsInput } from "_sredx/_ui/TagsInput";
import { EOL_PRODUCTS_OPTIONS, RESOURCE_TYPES } from "_sredx/types/resources";
import { useFormContext } from "react-hook-form";
import { FormField } from "components/basic/FormField";
import { ResourceCreationFormData } from "_sredx/containers/ResourcesCreationContainer/utils";

export const ResourceCreationGeneralInfoForm = () => {
  const { control, setValue, formState } =
    useFormContext<ResourceCreationFormData>();

  return (
    <Card>
      <CardHeader>
        <CardTitle>General information</CardTitle>
      </CardHeader>
      <CardBody>
        <form className={styles.form_wrapper}>
          <FormFieldInput
            name={"generalInfo.name"}
            control={control}
            label={"name"}
            placeholder={"Resource name"}
            errorMessage={formState.errors.generalInfo?.name?.message}
            fullWidth
          />
          <FormFieldTextArea
            name={"generalInfo.description"}
            control={control}
            label={"Description"}
            placeholder={"Resource description"}
            errorMessage={formState.errors.generalInfo?.description?.message}
            fullWidth
          />
          <div className={styles.form_row}>
            <FormFieldSelect
              name={"generalInfo.product"}
              control={control}
              label={"Product"}
              placeholder={"Product"}
              errorMessage={formState.errors.generalInfo?.product?.message}
              options={EOL_PRODUCTS_OPTIONS.map((product) => ({
                label: product,
                value: product,
              }))}
              fullWidth
            />
            <FormFieldInput
              name={"generalInfo.version"}
              control={control}
              label={"Version"}
              placeholder={"Version"}
              errorMessage={formState.errors.generalInfo?.version?.message}
              fullWidth
            />
          </div>
          <div className={styles.form_row}>
            <FormFieldSelect
              fullWidth
              name={"generalInfo.type"}
              control={control}
              label={"Type"}
              placeholder={"Type"}
              //@ts-ignore
              errorMessage={formState.errors.generalInfo?.type?.message}
              options={Object.keys(RESOURCE_TYPES).map((type) => ({
                label: type,
                value: type,
              }))}
            />
            <FormField
              id="tags"
              component={TagsInput}
              name="generalInfo.tags"
              placeholder="Service tags, separated by a comma..."
              label="tags"
              fullWidth
            />
          </div>
        </form>
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
};
