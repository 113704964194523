import React from 'react';
import styles from "./ImgBoxStyles.module.css"


const ImgBox  = ({img } : {img : string}) => {
  return (
    <div className={styles.service_team }>
        <img src={img} className={styles.service_team_img } alt=''></img>
    </div>
    
  )
}

export default ImgBox