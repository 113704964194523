import barStyles from './Header.module.css';
import SredxLogo from "./SredxLogo";
import HeaderAction from "./header-actions/HeaderAction";
import clsx from "clsx";


interface NavBarProps {
    onLogin: () => void;
    backgroundVisible?: boolean;
}


export const Header = ({onLogin, backgroundVisible=true} : NavBarProps) => {

    const handleOnLogin = () => {
        onLogin();
    }

    return (
    <header className={clsx(barStyles.wrapper, !backgroundVisible ? barStyles.background_visible : "")}>
        <div className={clsx("container", barStyles.content_wrapper)}>
            <SredxLogo />
            <HeaderAction
              onLogin={handleOnLogin}
            />
        </div>
      </header>
    );
}