import { PagedResponse, ServiceRichDto } from "_sredx/types";
import { InfiniteData } from "react-query";

export const mapInfiniteServices = (
  infiniteServices?: InfiniteData<PagedResponse<ServiceRichDto>>
): ServiceRichDto[] => {
  if (
    !infiniteServices ||
    !infiniteServices.pages ||
    !Array.isArray(infiniteServices.pages) ||
    infiniteServices.pages.length === 0
  ) {
    return [];
  }

  const { pages } = infiniteServices;
  return pages.reduce((result: ServiceRichDto[], page) => {
    if (page.content && Array.isArray(page.content)) {
      result.push(...page.content);
    }
    return result;
  }, []);
};
