import { PageContent } from "_sredx/_ui";
import { RoadmapListingContainer } from "_sredx/containers";

export const RoadmapListingPage = () => {
  return (
    <PageContent>
      <RoadmapListingContainer />
    </PageContent>
  );
};
