import {
  Card,
  Empty,
  Loader,
  Pagination,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_sredx/_ui";
import { UserDto } from "_sredx/types";
import { MemberDataTableRow } from "../MemberDataTableRow";
import { MEMBER_DATA_TABLE_HEADER_ITEMS } from "./constant";
import styles from "./MemberDataTable.module.css";
import { ProgressBar } from "_sredx/_ui/ProgressBar/ProgressBar";

interface MemberDataTableProps {
  members?: UserDto[];
  isLoading: boolean;
  isFetching: boolean;
  totalPages?: number;
  onPageChange?: (page: number) => void;
  onAddMembers?: () => void;
}

export const MemberDataTable = ({
  members = [],
  isLoading,
  totalPages = 1,
  onPageChange,
  isFetching,
}: MemberDataTableProps) => {
  const headerCells = Object.values(MEMBER_DATA_TABLE_HEADER_ITEMS);

  // Derived variables
  const showPagination = totalPages > 1;

  const handleOnPageChange = (page: number) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };
  if (isLoading) {
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading members...</Text>
        </div>
      </Card>
    );
  }
  if (!isLoading && members && members.length === 0) {
    return (
      <Card>
        <Empty message={"No members found for your organization."} />
      </Card>
    );
  }
  return (
    <Table>
      {/*Header*/}
      <TableHeader>
        {headerCells.map((cell) => (
          <TableHead className={styles.header_cell} key={cell}>
            <Text>{cell}</Text>
          </TableHead>
        ))}
      </TableHeader>
      {/*body*/}
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {members.map((member) => (
          <MemberDataTableRow
            key={member.id}
            member={member}
            onRowClick={() => {}}
          />
        ))}
      </TableBody>
      {/*footer*/}
      <TableFooter>
        <TableFooterCell colSpan={headerCells.length}>
          <div className={styles.footer}>
            <Text>{members.length} Members</Text>
            {showPagination && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handleOnPageChange}
              />
            )}
          </div>
        </TableFooterCell>
      </TableFooter>
    </Table>
  );
};
