import React from 'react'

export const SonarQube = () => {
  return (
    <svg viewBox="0 0 24 24"
     fill="currentColor" >
      <path d="m15.685.386-.465.766c3.477 2.112 6.305 5.27 7.966 8.89l.814-.372c-1.734-3.783-4.687-7.08-8.315-9.284zm-7.223.524-.305 1.075c6.89 1.976 12.384 7.64 13.997 14.421l1.085-.258c-1.704-7.171-7.504-13.151-14.777-15.239zm-8.462 1.757v1.342c10.963 0 19.883 8.795 19.883 19.605h1.342c0-11.55-9.522-20.947-21.225-20.947z" />
    </svg>
  );
}
