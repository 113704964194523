import { ReactNode, useMemo } from "react";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { BarStack } from "@visx/shape";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";

import { ChartProps } from "../types";
import {
  axisDefaultLabelMapper,
  CHART_GRID_COLOR,
  DEFAULT_CHART_MARGIN,
  MIN_CHART_WIDTH,
} from "../config";

let tooltipTimeout: number;

const tooltipStyles = {
  ...defaultStyles,
  padding: 0,
  margin: 0,
};

interface BarStackChartProps<T extends Record<string, any>> extends ChartProps {
  data: T[];
  xMapper: (d: T) => string;
  keys: (keyof T)[];
  colorMapper: (key: keyof T) => string;
  yLabelMapper?: (val: any) => string;
  xLabelMapper?: (val: any) => string;
  renderTooltip?: (d: T) => ReactNode;
}

const getTotal = <T extends Record<string, any>>(
  data: T[],
  keys: (keyof T)[]
): number[] => {
  return data.reduce((allTotals: number[], currentItem: T) => {
    const total = keys.reduce((accu, key) => {
      return accu + Number(currentItem[key]);
    }, 0);
    allTotals.push(total);
    return allTotals;
  }, []);
};

export const BarStackChart = <T extends Record<string, any>>({
  data,
  xMapper,
  colorMapper,
  keys,
  width,
  yLabelMapper = axisDefaultLabelMapper,
  xLabelMapper = axisDefaultLabelMapper,
  height,
  bgColor = "transparent",
  renderTooltip,
  margin = DEFAULT_CHART_MARGIN,
}: BarStackChartProps<T>) => {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<T>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = useMemo(() => {
    return scaleBand<string>({
      range: [0, xMax],
      domain: data.map(xMapper),
      padding: 0.2,
    });
  }, [xMax, data, xMapper]);

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...getTotal<T>(data, keys))],
      }),
    [yMax]
  );

  const colorScale = useMemo(() => {
    return scaleOrdinal({
      domain: keys,
      range: keys.map(colorMapper),
    });
  }, [keys, colorMapper]);

  if (width < MIN_CHART_WIDTH) {
    return null;
  }

  return (
    <div>
      <svg width={width} height={height} ref={containerRef}>
        <rect x={0} y={0} width={width} height={height} fill={bgColor} />
        <GridRows
          scale={yScale}
          width={xMax}
          height={yMax}
          top={margin.top}
          left={margin.left}
          stroke={CHART_GRID_COLOR}
        />
        <Group top={margin.top} left={margin.left}>
          <BarStack<T, any>
            data={data}
            keys={keys}
            x={xMapper}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) => {
                return barStack.bars.map((bar) => {
                  return (
                    <rect
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      height={bar.height}
                      width={bar.width}
                      fill={bar.color}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={(event) => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        // TooltipInPortal expects coordinates to be relative to containerRef
                        // localPoint returns coordinates relative to the nearest SVG, which
                        // is what containerRef is set to in this example.
                        const eventSvgCoords = localPoint(event);
                        const left = bar.x + bar.width / 2;
                        showTooltip({
                          tooltipData: bar.bar.data,
                          tooltipTop: eventSvgCoords?.y,
                          tooltipLeft: left,
                        });
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  );
                });
              })
            }
          </BarStack>
          <AxisLeft
            hideAxisLine
            left={0}
            hideTicks
            scale={yScale}
            tickFormat={yLabelMapper}
            tickLabelProps={{
              fill: "#03001C",
              fontSize: 11,
              textAnchor: "end",
              dy: "0.33em",
            }}
          />
          {xScale && (
            <AxisBottom
              top={yMax}
              scale={xScale}
              tickFormat={xLabelMapper}
              stroke={"#03001C"}
              tickStroke={"#03001C"}
            />
          )}
        </Group>
      </svg>
      {renderTooltip && tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div>{renderTooltip(tooltipData)}</div>
        </TooltipInPortal>
      )}
    </div>
  );
};
