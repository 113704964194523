const ConfigurationAccountsSkeleton = () => {
    return (
        <div className="p-4 space-y-4 animate-pulse border border-black">
            <div className="flex items-center space-x-4">
                <div className="w-8 h-8 bg-gray-400"></div>
                <div className="flex-1 space-y-2 py-1">
                    <div className="h-4 bg-gray-400 w-5/12"></div>
                    <div className="h-4 bg-gray-400 w-3/4"></div>
                </div>
                <div className="flex space-x-3">
                    <div className="w-6 h-6 bg-gray-400"></div>
                    <div className="w-6 h-6 bg-gray-400"></div>
                </div>
            </div>
            <div className="flex items-center space-x-4">
                <div className="w-8 h-8 bg-gray-400"></div>
                <div className="flex-1 space-y-2 py-1">
                    <div className="h-4 bg-gray-400 w-5/12"></div>
                    <div className="h-4 bg-gray-400 w-3/4"></div>
                </div>
                <div className="flex space-x-3">
                    <div className="w-6 h-6 bg-gray-400"></div>
                    <div className="w-6 h-6 bg-gray-400"></div>
                </div>
            </div>
            <div className="flex items-center space-x-4">
                <div className="w-8 h-8 bg-gray-400"></div>
                <div className="flex-1 space-y-2 py-1">
                    <div className="h-4 bg-gray-400 w-5/12"></div>
                    <div className="h-4 bg-gray-400 w-3/4"></div>
                </div>
                <div className="flex space-x-3">
                    <div className="w-6 h-6 bg-gray-400"></div>
                    <div className="w-6 h-6 bg-gray-400"></div>
                </div>
            </div>
            <div className="flex items-center space-x-4">
                <div className="w-8 h-8 bg-gray-400"></div>
                <div className="flex-1 space-y-2 py-1">
                    <div className="h-4 bg-gray-400 w-5/12"></div>
                    <div className="h-4 bg-gray-400 w-3/4"></div>
                </div>
                <div className="flex space-x-3">
                    <div className="w-6 h-6 bg-gray-400"></div>
                    <div className="w-6 h-6 bg-gray-400"></div>
                </div>
            </div>
            <div className="flex items-center space-x-4">
                <div className="w-8 h-8 bg-gray-400"></div>
                <div className="flex-1 space-y-2 py-1">
                    <div className="h-4 bg-gray-400 w-5/12"></div>
                    <div className="h-4 bg-gray-400 w-3/4"></div>
                </div>
                <div className="flex space-x-3">
                    <div className="w-6 h-6 bg-gray-400"></div>
                    <div className="w-6 h-6 bg-gray-400"></div>
                </div>
            </div>
            <div className="flex items-center space-x-4">
                <div className="w-8 h-8 bg-gray-400"></div>
                <div className="flex-1 space-y-2 py-1">
                    <div className="h-4 bg-gray-400 w-5/12"></div>
                    <div className="h-4 bg-gray-400 w-3/4"></div>
                </div>
                <div className="flex space-x-3">
                    <div className="w-6 h-6 bg-gray-400"></div>
                    <div className="w-6 h-6 bg-gray-400"></div>
                </div>
            </div>
        </div>
    );
}

export default ConfigurationAccountsSkeleton