import {
  Button,
  ButtonLabel,
  List,
  ListItem,
  ListTitle,
  Text,
} from "_sredx/_ui";
import { InitiativeBaseDto } from "_sredx/types";
import { RoadmapCreationInitiativesActions } from "../RoadmapCreationInitiatives";
import { InitiativeRow } from "./InitiativeRow";
import styles from "./RoadmapCreationInitiativesList.module.css";

interface RoadmapCreationInitiativesListProps {
  initiatives: InitiativeBaseDto[];
  onAction: (action: RoadmapCreationInitiativesActions) => void;
  emptyMessage?: string;
  withAddAction?: boolean;
}

export const RoadmapCreationInitiativesList = ({
  initiatives,
  onAction,
  emptyMessage = "No Initiatives",
  withAddAction = false,
}: RoadmapCreationInitiativesListProps) => {
  const handleOnAddInitiative = () => {
    onAction({
      type: "CLICK_ADD_INITIATIVE",
    });
  };

  if (initiatives.length === 0) {
    return (
      <div className={styles.empty_wrapper}>
        <Text className={styles.empty_content}>{emptyMessage}</Text>
        {withAddAction && (
          <Button onClick={handleOnAddInitiative} variant="neutral">
            <ButtonLabel>Add Initiatives</ButtonLabel>
          </Button>
        )}
      </div>
    );
  }
  return (
    <>
      <List>
        <ListTitle>Initiatives</ListTitle>
        {initiatives.map((initiative) => (
          <ListItem key={initiative.id}>
            <InitiativeRow initiative={initiative} onAction={onAction} />
          </ListItem>
        ))}
      </List>
    </>
  );
};
