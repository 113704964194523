import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { format } from "date-fns";
import { hoursToAxisLabel } from "_sredx/utils/charts";
import { ChartPeriodType, PullRequestDto } from "_sredx/types";
import { BarStackChart } from "_sredx/components/Charts";
import {
  getPRCycleTimeBreakdown,
  PR_CYCLE_TIME_BREAKDOWN_KEYS,
  prCycleTimeColorMapper,
} from "./utils";
import { PRCycleTimeBreakdownTooltip } from "./PRCycleTimeBreakdownTooltip";
import React from "react";

interface PRCycleTimeBreakdownChartProps {
  pullRequests: PullRequestDto[];

  period: ChartPeriodType;
}

export const PRCycleTimeBreakdownChart = ({
  pullRequests,
  period,
}: PRCycleTimeBreakdownChartProps) => {
  const pullRequestBreakdown = getPRCycleTimeBreakdown(
    pullRequests,
    period.startDate.toISOString(),
    period.endDate.toISOString()
  );
  return (
    <div style={{ width: "100%", height: "500px" }}>
      <ParentSize>
        {({ width, height }) => (
          <BarStackChart
            data={pullRequestBreakdown}
            xMapper={(d) => d.startDate}
            xLabelMapper={(val) => format(new Date(val), "dd LLL yyyy ")}
            yLabelMapper={(val) => hoursToAxisLabel(val)}
            keys={PR_CYCLE_TIME_BREAKDOWN_KEYS}
            colorMapper={prCycleTimeColorMapper}
            width={width}
            height={height}
            renderTooltip={(data) => (
              <PRCycleTimeBreakdownTooltip data={data} />
            )}
          />
        )}
      </ParentSize>
    </div>
  );
};
