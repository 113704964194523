import { forwardRef } from "react";
import { IconProps } from "./types";

export const ChevronLeftIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          fillRule="evenodd"
          d="m10.06 12 5.47 5.47-1.06 1.06L7.94 12l6.53-6.53 1.06 1.06L10.06 12Z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
);
