import { PropsWithChildren } from "react";
import styles from "./SelectPopoverContent.module.css";
import clsx from "clsx";

export const SelectPopoverContent = ({ children }: PropsWithChildren) => {
  return (
    <div className={clsx(styles.wrapper, "nemo-select-popover-content")}>
      {children}
    </div>
  );
};
