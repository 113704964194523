import { PageContent } from "_sredx/_ui";
import { useAuth } from "_sredx/auth";
import { UserOnBoardingContainer } from "_sredx/containers";
import { isNull } from "_sredx/utils";
import { Navigate, useSearchParams } from "react-router-dom";
import styles from "./UserOnBoardingPage.module.css";

export const UserOnBoardingPage = () => {
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useAuth();

  // useCurrentUser or useMe

  const token = searchParams.get("token");

  //check if user is authenticated
  if (isAuthenticated) {
    return <Navigate to={"/*"} replace={true} />;
  }

  if (!token || isNull(token)) {
    return <Navigate to={"/*"} replace={true} />;
  }

  return (
    <PageContent align={"center"} maxWidth={700}>
      <div className={styles.wrapper}>
        <UserOnBoardingContainer token={token} />
      </div>
    </PageContent>
  );
};
