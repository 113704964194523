import { Searchbar, ViewToggler } from "_sredx/_ui";
import { API_FIRST_PAGE } from "_sredx/constants";
import { ServiceListingCardContainer } from "_sredx/containers/ServicesListingContainer/ServiceListingCardContainer";
import { ServiceListingDataTableContainer } from "_sredx/containers/ServicesListingContainer/ServiceListingDataTableContainer";
import {
  DomainBaseDto,
  ServiceSearchCriteria,
  TeamBaseDto,
} from "_sredx/types";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { TeamSelectContainer } from "../TeamSelectContainer";
import styles from "./ServicesListingContainer.module.css";
import { DomainSelectContainer } from "../DomainSelectContainer";

const INITIATIVE_SEARCH_CRITERIA_DEFAULT: ServiceSearchCriteria = {
  name: "",
  teams: [],
  pageNumber: 1,
};
export const ServicesListingContainer = () => {
  //hooks
  const [view, setView] = useState<"card" | "datatable">("card");
  const [query, setQuery] = useState<ServiceSearchCriteria>(
    INITIATIVE_SEARCH_CRITERIA_DEFAULT
  );

  // Events handler
  const toggleView = () => {
    setView(view === "card" ? "datatable" : "card");
  };

  const handleSearch = (e: string) => {
    setQuery((prevState) => ({
      ...prevState,
      name: e,
      pageNumber: API_FIRST_PAGE,
    }));
  };
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 400), []);

  const handleTeamsFilter = (teams: TeamBaseDto[]) => {
    const teamsNames = teams.map((team: TeamBaseDto) => team.name);
    setQuery((prevState) => ({
      ...prevState,
      teams: teamsNames,
      pageNumber: API_FIRST_PAGE,
    }));
  };

  const handleDomainFilter = (domains: DomainBaseDto[]) => {
    const domainIds = domains.map((domain: DomainBaseDto) => domain.id);
    setQuery((prevState) => ({
      ...prevState,
      domains: domainIds,
      pageNumber: API_FIRST_PAGE,
    }));
  };

  const resolveView = () => {
    if (view === "card") {
      return <ServiceListingCardContainer query={query} />;
    }
    return <ServiceListingDataTableContainer query={query} />;
  };

  return (
    <div className={styles.wrapper}>
      {/*  Search & Filter  */}
      <div className={styles.search_filter_wrapper}>
        <div className={styles.searchbar_wrapper}>
          <Searchbar
            onChange={debouncedHandleSearch}
            placeholder="Search services by name"
          />
        </div>
        <TeamSelectContainer onChange={handleTeamsFilter} />
        <DomainSelectContainer onChange={handleDomainFilter} />
        <div className={styles.view_toggler}>
          <ViewToggler value={view} onToggle={toggleView} />
        </div>
      </div>
      {resolveView()}
    </div>
  );
};
