import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { PagedResponse } from "_sredx/types";
import { ResourceDto, ResourceSearchCriteria } from "_sredx/types/resources";
import { useInfiniteQuery } from "react-query";
import { ClientApi } from "../clientApi";

interface useInfiniteGetResourcesProps {
    params: ResourceSearchCriteria;
  }
  
  export const useInfiniteGetResources = ({
    params,
  }: useInfiniteGetResourcesProps) => {
    return useInfiniteQuery<PagedResponse<ResourceDto>>({
      queryKey: [QUERY_KEYS.infiniteResources, params],
      queryFn: ({ pageParam = 0 }) => {
        return ClientApi.get(API_ENDPOINTS.getResources(), {
          params: {
            ...params,
            pageNumber: pageParam,
          },
        });
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.last) {
          return undefined;
        }
        return lastPage.pageNo + 1;
      },
      refetchOnWindowFocus: false,
    });
  };
  