import { useToaster } from "hooks/useToaster";
import { Toast } from "./Toaster";

export const ToastContainer = () => {
    const toasts = useToaster((state) => state.toasts);
    return (
      <div className="flex flex-col gap-4 fixed bottom-10 right-10">
        {toasts.map((toast) => (
          <Toast key={toast.id} id={toast.id} message={toast.message} type={toast.type}/>
        ))}
      </div>
    );
  };
  