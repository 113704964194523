import clsx from "clsx";
import styles from "./ScoreCardCreationContainer.module.css";
import { ScoreCardFormComponent } from "components/scorecard/ScoreCardForm/ScoreCardFormComponent/ScoreCardFormComponent";

export const ScoreCardCreationContainer = () => {
  return (
    <div className={clsx(styles.wrapper)}>
      <ScoreCardFormComponent />
    </div>
  );
};
