import styles from "./ProgressBarStyles.module.css";

interface ProgressBarProps {
    percentage: number;
}
  
const ProgressBar = ({ percentage } : ProgressBarProps) => {
  const validPercentage = Math.min(Math.max(percentage, 0), 100);

  return (
    <div  className={styles.progress_bar}>
      <div
      className={styles.progress}
        style={{ width: `${validPercentage}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
  