import { ScorecardLevelHealth, ScorecardsLevelsHealthsDto } from "_sredx/types";
import { BarChartData, ScorecardsLevelsHealthsKeys } from "./types";

export const transformToStackedBarData = (
  data: ScorecardsLevelsHealthsDto
): {
  transformedData: BarChartData<ScorecardsLevelsHealthsKeys>[];
  maxLevels: number;
} => {
  let transformedData: BarChartData<ScorecardsLevelsHealthsKeys>[] = [];
  let levelsNames = [];

  data.scorecardsLevelHealths.forEach((scorecard: ScorecardLevelHealth) => {
    const transformed = {
      key: scorecard.scorecardName,
    } as BarChartData<ScorecardsLevelsHealthsKeys>;
    scorecard.levelsHealthInsights.forEach((level, index) => {
      transformed[`${index}`] = level.count;
    });

    transformedData.push(transformed);
  });

  //get the max length of levels for all scorecards and fill those with no lower number of levels with 0, taking into account the key field
  const maxLevels = transformedData.reduce(
    (max, current) => Math.max(max, Object.keys(current).length - 1),
    0
  );

  transformedData = transformedData.map((scorecard) => {
    const keys = Object.keys(scorecard);
    for (let i = 0; i < maxLevels; i++) {
      if (!keys.includes(`${i}`)) {
        scorecard[`${i}`] = 0;
      }
    }
    return scorecard;
  });

  return { transformedData, maxLevels };
};

export const getLevelsNames = (
  data: ScorecardsLevelsHealthsDto
): { key: string; levelsNames: string[] }[] => {
  let levelsNames: { key: string; levelsNames: string[] }[] = [];
  data.scorecardsLevelHealths.forEach((scorecard: ScorecardLevelHealth) => {
    const transformed = {
      key: scorecard.scorecardName,
      levelsNames: [] as string[],
    };
    scorecard.levelsHealthInsights.forEach((level) => {
      transformed.levelsNames.push(level.levelName);
    });
    levelsNames.push(transformed);
  }
);
  return levelsNames;
};
