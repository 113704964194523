import {AxiosInstance} from "axios";
import {ScoreCardEvaluation} from "../../models/evaluations/ScoreCardEvaluation";

// Should return list of [last evaluation made to each service]
export const getScoreCardLastEvaluation = (axios: AxiosInstance, scoreCardId: string): Promise<ScoreCardEvaluation[]> => {
    return axios.get(`/evaluations/scorecard/${scoreCardId}/last`)
        .then((r) => {
            return r.data;
        });
}
export const getScoreCardLastEvaluationByEvaluationTime = (axios: AxiosInstance, scorecardId: string, rangeTime:string ): Promise<ScoreCardEvaluation[]> => {
    return axios.get(`/evaluations/scorecard/last?timeRange=${rangeTime}&scorecardId=${scorecardId}`)
        .then((r) => {
            return r.data;
        });
}

export const getFilteredScoreCardEvaluations = (
    axios: AxiosInstance,
    scorecardId: string,
    serviceId: string,
    startDate: number,
    endDate: number
  ): Promise<ScoreCardEvaluation[]> => {
    let url = `/evaluations?scoreCardId=${scorecardId}&serviceId=${serviceId}&startDate=${startDate}&endDate=${endDate}`;
    return axios.get(url).then((r) => {
      return r.data;
    });
};

// Should return all evaluations made by a scorecard to a service
export const getServiceEvaluationsHistoryByScoreCard = (axios: AxiosInstance, scoreCardId: string, serviceId: string): Promise<ScoreCardEvaluation[]> => {
    return axios.get(`/evaluations/scorecard/${scoreCardId}/services/${serviceId}`)
        .then((r) => {
            return r.data;
        });
}

// Should return list of all evaluations made to a service by all scorecards
export const getServiceEvaluationsHistory = (axios: AxiosInstance, serviceId: string): Promise<ScoreCardEvaluation[]> => {
    return axios.get(`/evaluations/service/${serviceId}`)
        .then((r) => {
            return r.data;
        });
}
// Should return list of last evaluations made to a service by each scorecardFS
export const getServiceLastEvaluations = (axios: AxiosInstance, serviceId: string): Promise<ScoreCardEvaluation[]> => {
    return axios.get(`/evaluations/service/${serviceId}/last`)
        .then((r) => {
            return r.data;
        });
}

