import { useParams } from "react-router-dom";

import { InitiativeDetails } from "_sredx/components";
import { Card, Heading, Loader, Text } from "_sredx/_ui";
import { useGetInitiative } from "_sredx/services";

import styles from "./InitiativeDetailsContainer.module.css";
import { InitiativeDetailsOverview } from "_sredx/components";

export const InitiativeDetailsContainer = () => {
  const { initiativeId } = useParams<{ initiativeId: string }>();
  const {
    data: initiative,
    error,
    isLoading,
  } = useGetInitiative({
    id: initiativeId ?? "",
  });

  if (isLoading)
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading...</Text>
        </div>
      </Card>
    );

  if (error || !initiative) {
    return null;
  }

  return (
    <>
      <Heading>{initiative.name}</Heading>

      <div className={styles.wrapper}>
        <InitiativeDetails initiative={initiative} />
        <InitiativeDetailsOverview initiative={initiative} />
      </div>
    </>
  );
};
