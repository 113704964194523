import { AdvancedFiltersInputValueProps } from "../types";

interface InputBooleanProps extends AdvancedFiltersInputValueProps {}

export const InputBoolean = ({ value, onChange }: InputBooleanProps) => {
  return (
    <div>
      <input type="number" />
    </div>
  );
};
