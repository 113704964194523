import { CurrentScoreCard } from "../../../types/ProgressInfo";
import { Fragment } from "react";
import { FaCheck, FaSpinner } from "react-icons/fa";


interface Props {
  allScorecards: {scorecard: CurrentScoreCard, status: 'done' | 'inProgress'}[];
}

const SidebarEvaluationContentComponent: React.FC<Props> = ({
  allScorecards,
}) => {
  return (
    <div className="p-1">
      {allScorecards.map(({ scorecard, status }, index) => (
        <Fragment key={index} >
          <div  className="flex text-lg justify-between items-center p-2 mb-2">
            <span className="text-gray-500 font-medium">
              {scorecard.scorecardName}
            </span>
            <div className="flex items-center">
              {status === 'done' ? (
                <div className="flex items-center text-green-500">
                  <FaCheck className="mr-2" />
                  Done
                </div>
              ) : (
                <div className="flex items-center text-blue-500">
                  <FaSpinner className="mr-2 animate-spin" />
                  In Progress...
                </div>
              )}
            </div>
          </div>
          <hr />
        </Fragment>
      ))}
    </div>
  );
};

export default SidebarEvaluationContentComponent;
