interface IconProps {
  size?: number;
  color?: string;
}
export const ChevronDownIcon = ({
  size,
  color = "currentColor",
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="m11.999 13.94 5.47-5.47 1.06 1.06L12 16.06 5.47 9.53l1.06-1.06L12 13.94Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
