import { IToggle, useFlags } from "@unleash/proxy-client-react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { NotFoundPage } from "pages/404";
import { LandingPage } from "pages/landing/LandingPage";
import {
  ADMIN_BASE_PATH,
  ADMIN_DASHBOARD_PAGE,
  HOME_BASE_PATH,
  LOGIN_BASE_PATH,
} from "./paths";
import { PrivateRoute } from "./PrivateRoute";
import { ADMIN_ROUTES, APP_ROUTES, RouteType } from "./routes";
import React, { useState } from "react";
import { REACT_APP_USE_V2 } from "config/config";
import { useAppV1Store } from "state/appv2";

const renderRoutes = (routes: RouteType[], flags: IToggle[]) => {
  return routes.map((route, index) => {
    if (route.isFeatureEnabled && !route.isFeatureEnabled(flags)) {
      return null;
    }
    const Component = route.component;

    if (!route.isPrivate) {
      return <Route key={index} path={route.path} element={<Component />} />;
    }
    return (
      <Route
        key={`${route.path}-${index}`}
        path={route.path}
        element={
          <PrivateRoute component={<Component />} roleHandler={route.role} />
        }
      >
        {route.children?.map((childRoute, index) => {
          const ChildComponent = childRoute.component;
          return (
            <Route
              index={childRoute.index}
              key={`${childRoute.path}-${index}`}
              path={childRoute.index ? undefined : childRoute.path}
              element={<ChildComponent />}
            />
          );
        })}
      </Route>
    );
  });
};

const ShowV2App = () => {
  const [isV2defined] = useState(REACT_APP_USE_V2 !== undefined);
  const [show, setShow] = useState(true);
  const { isV1Enabled, toggleV1 } = useAppV1Store();

  const navigate = useNavigate();

  const handleOnToggle = () => {
    toggleV1();
    navigate("/");
  };

  if (!show || !isV2defined) {
    return null;
  }
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        textAlign: "center",
        zIndex: 9999,
        padding: "1rem 2rem",
        color: "#fff",
        background: "#000",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <button onClick={handleOnToggle}>
        Use {!isV1Enabled ? "version 1" : "version 2"} ? Yes
      </button>
      <button onClick={() => setShow(false)}>close</button>
    </div>
  );
};

export const AppRoutes = () => {
  const flags: IToggle[] = useFlags();
  return (
    <BrowserRouter>
      <Routes>
        <Route path={HOME_BASE_PATH}>
          {renderRoutes(APP_ROUTES, flags)}
          <Route path={ADMIN_BASE_PATH}>
            <Route
              index
              element={<Navigate replace to={ADMIN_DASHBOARD_PAGE} />}
            />
            {renderRoutes(ADMIN_ROUTES, flags)}
          </Route>
        </Route>
        <Route path={LOGIN_BASE_PATH} element={<LandingPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ShowV2App />
    </BrowserRouter>
  );
};
