import {
  axisDefaultLabelMapper,
  CHART_GRID_COLOR,
  DEFAULT_CHART_MARGIN,
  MIN_CHART_WIDTH,
} from "../config";
import { ChartProps } from "../types";
import { useMemo } from "react";
import { scaleLinear, scaleTime } from "@visx/scale";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { LinePath } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import * as curves from "@visx/curve";
import { max } from "@visx/vendor/d3-array";

interface LineChart<T> extends ChartProps {
  data: T[][];
  xMapper: (d: T) => Date;
  yMapper: (d: T) => number;
  onClick?: (d: T) => void;
  yLabelMapper?: (val: any) => string;
  xLabelMapper?: (val: any) => string;
  lineColorMapper?: (d: T) => string;
  startDate: Date;
  endDate: Date;
  curve?: keyof typeof curves;
}

export const LineChart = <T,>({
  data,
  xMapper,
  yMapper,
  yLabelMapper = axisDefaultLabelMapper,
  xLabelMapper = axisDefaultLabelMapper,
  lineColorMapper,
  startDate,
  endDate,
  width,
  height,
  bgColor = "transparent",
  margin = DEFAULT_CHART_MARGIN,
  curve = "curveCardinal",
}: LineChart<T>) => {
  const yMax = height - margin.top - margin.bottom;
  const xMax = width - margin.left - margin.right;

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, max(data.flat(), yMapper) as number],
      }),
    [yMax, data]
  );

  const xScale = useMemo(() => {
    return scaleTime<number>({
      range: [0, xMax],
      domain: [startDate, endDate],
    });
  }, [xMax, startDate, endDate]);

  if (width < MIN_CHART_WIDTH) {
    return null;
  }

  return (
    <div>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill={bgColor} />
        <GridRows
          scale={yScale}
          width={xMax}
          height={yMax}
          top={margin.top}
          left={margin.left}
          stroke={CHART_GRID_COLOR}
        />
        <Group top={margin.top} left={margin.left}>
          {data.map((d, i) => {
            return (
              <LinePath<T>
                curve={curves[curve]}
                data={d}
                x={(d) => xScale(xMapper(d)) ?? 0}
                y={(d) => yScale(yMapper(d)) ?? 0}
                stroke={
                  lineColorMapper && d.length > 0
                    ? lineColorMapper(d[0])
                    : "black"
                }
                strokeWidth={2}
                shapeRendering="geometricPrecision"
              />
            );
          })}
          <AxisLeft
            hideAxisLine
            left={0}
            hideTicks
            scale={yScale}
            tickFormat={yLabelMapper}
            tickLabelProps={{
              fill: "#03001C",
              fontSize: 11,
              textAnchor: "end",
              dy: "0.33em",
            }}
          />
          {xScale && (
            <AxisBottom
              top={yMax}
              scale={xScale}
              stroke={"#03001C"}
              tickStroke={"#03001C"}
              tickFormat={xLabelMapper}
            />
          )}
        </Group>
      </svg>
    </div>
  );
};
