import { TableCell, TableRow, Text } from "_sredx/_ui";
import { RoadmapBaseDto } from "_sredx/types";
import styles from "./RoadmapDataTableRow.module.css";

interface RoadmapDataTableRowProps {
  roadmap: RoadmapBaseDto;
  onRoadmapClick: (id: string) => void;
}

export const RoadmapDataTableRow = ({
  roadmap,
  onRoadmapClick,
}: RoadmapDataTableRowProps) => {
  const { name, startDate, endDate, initiativesIds } = roadmap;
  const initiativesCount = initiativesIds ? initiativesIds.length : 0;
  const formattedStartDate = startDate.toString().replaceAll("-", "/");
  const formattedEndDate = endDate.toString().replaceAll("-", "/");

  const handleRoadmapRowClick = () => {
    onRoadmapClick(roadmap.id);
  };
  return (
    <TableRow className={styles.roadmap_row} onClick={handleRoadmapRowClick}>
      <TableCell>
        <div className={styles.col1_wrapper}>
          <Text>{name}</Text>
        </div>
      </TableCell>
      <TableCell>{formattedStartDate}</TableCell>
      <TableCell>{formattedEndDate}</TableCell>
      <TableCell align={"right"}>{initiativesCount}</TableCell>
    </TableRow>
  );
};
