import { Button, Heading } from "_sredx/_ui";
import { DASHBOARD_WIDGETS, WidgetInfoDto } from "_sredx/types/dashboards";
import { PropsWithChildren, useState } from "react";
import { DashboardWidgetPreview } from "../DashboardWidgetPreview";
import styles from "./DashboardWidgetsModal.module.css";
import { mapWidgetComponents } from "_sredx/containers/DashboardContainer/utils";

interface DashboardWidgetsModalProps {
  onClose: () => void;
  defaultSelection?: WidgetInfoDto[];
  onSave: (widgets: WidgetInfoDto[]) => void;
}

export const DashboardWidgetsModal = ({
  onClose,
  onSave,
  defaultSelection,
}: DashboardWidgetsModalProps) => {
  const [selectedWidgets, setSelectedWidgets] = useState<WidgetInfoDto[]>(
    defaultSelection ?? []
  );

  const handleSave = () => {
    onSave(selectedWidgets);
    onClose();
  };

  const handleSelectWidget = (widget: string) => {
    if (selectedWidgets.some((w) => w.i === widget)) {
      setSelectedWidgets(selectedWidgets.filter((w) => w.i !== widget));
    } else {
      const mappedWidget = mapWidgetComponents([widget])[0];
      setSelectedWidgets([
        ...selectedWidgets,
        {
          i: widget,
          x: Infinity,
          y: Infinity,
          w: (mappedWidget?.dimensions.cols ?? 2) * 2,
          h: (mappedWidget?.dimensions.rows ?? 2) * 2,
        },
      ]);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Heading level={3}>Dashboard Widgets</Heading>
      <div className={styles.content}>
        <WidgetsSection title="Services Widgets">
          {Object.keys(DASHBOARD_WIDGETS.SERVICES).map((widget, i) => (
            <DashboardWidgetPreview
              key={i}
              id={widget}
              isSelected={selectedWidgets.some((w) => w.i === widget)}
              onSelect={handleSelectWidget}
            />
          ))}
        </WidgetsSection>
        <WidgetsSection title="Teams Widgets">
          {Object.keys(DASHBOARD_WIDGETS.TEAMS).map((widget, i) => (
            <DashboardWidgetPreview
              key={i}
              isSelected={selectedWidgets.some((w) => w.i === widget)}
              id={widget}
              onSelect={handleSelectWidget}
            />
          ))}
        </WidgetsSection>
        <WidgetsSection title={"Scorecards Widgets"}>
          {Object.keys(DASHBOARD_WIDGETS.SCORECARDS).map((widget, i) => (
            <DashboardWidgetPreview
              key={i}
              isSelected={selectedWidgets.some((w) => w.i === widget)}
              id={widget}
              onSelect={handleSelectWidget}
            />
          ))}
        </WidgetsSection>
        <WidgetsSection title={"Rules Widgets"}>
          {Object.keys(DASHBOARD_WIDGETS.RULES).map((widget, i) => (
            <DashboardWidgetPreview
              key={i}
              isSelected={selectedWidgets.some((w) => w.i === widget)}
              id={widget}
              onSelect={handleSelectWidget}
            />
          ))}
        </WidgetsSection>
        <WidgetsSection title={"Initiatives Widgets"}>
          {Object.keys(DASHBOARD_WIDGETS.INITIATIVES).map((widget, i) => (
            <DashboardWidgetPreview
              key={i}
              id={widget}
              isSelected={selectedWidgets.some((w) => w.i === widget)}
              onSelect={handleSelectWidget}
            />
          ))}
        </WidgetsSection>
        <WidgetsSection title={"Clusters Widgets"}>
          {Object.keys(DASHBOARD_WIDGETS.CLUSTERS).map((widget, i) => (
            <DashboardWidgetPreview
              key={i}
              id={widget}
              isSelected={selectedWidgets.some((w) => w.i === widget)}
              onSelect={handleSelectWidget}
            />
          ))}
        </WidgetsSection>
      </div>
      <div className={styles.footer}>
        <Button variant="neutral" onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleSave}>Import</Button>
      </div>
    </div>
  );
};

interface WidgetsSectionProps {
  title: string;
}

const WidgetsSection = ({
  title,
  children,
}: PropsWithChildren<WidgetsSectionProps>) => {
  return (
    <div className={styles.section}>
      <div className={styles.section_header}>
        <Heading level={4}>{title}</Heading>
      </div>
      <div className={styles.section_body}>{children}</div>
    </div>
  );
};
