import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, ButtonLabel, Heading, Searchbar } from "_sredx/_ui";
import { InitiativeSearchCriteria, TeamBaseDto } from "_sredx/types";
import { API_FIRST_PAGE, API_PAGE_SIZE_DEFAULT } from "_sredx/constants/api";
import { useGetInitiatives } from "_sredx/services";
import { useDebounce } from "_sredx/hooks";
import { InitiativeDataTable } from "_sredx/components";
import { parsePageNumber } from "_sredx/utils";

import styles from "./InitiativeListingContainer.module.css";
import { TeamSelectContainer } from "../TeamSelectContainer";

export const INITIATIVE_SEARCH_CRITERIA_DEFAULT: InitiativeSearchCriteria = {
  pageNumber: 1,
  pageSize: API_PAGE_SIZE_DEFAULT,
};

export const InitiativeListingContainer = () => {
  // State
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTeams, setSelectedTeams] = useState<TeamBaseDto[]>([]);

  // hooks
  const navigate = useNavigate();
  const debouncedQuery = useDebounce(searchQuery, 400);

  // Derived variables
  const currentPage = parsePageNumber(searchParams.get("page"));

  // services
  const {
    data: initiatives,
    isLoading,
    isFetching,
  } = useGetInitiatives({
    params: {
      ...INITIATIVE_SEARCH_CRITERIA_DEFAULT,
      name: debouncedQuery,
      pageNumber: currentPage,
      evaluated: true,
      teamIds: selectedTeams.map((team) => team.id),
    },
  });

  // event handlers
  const handleOnSearch = (value: string) => {
    setSearchParams({ page: API_FIRST_PAGE.toString() });
    setSearchQuery(value);
  };

  const handleCreateInitiative = () => {
    navigate("/initiatives/create");
  };

  const handleClickInitiativeRow = (initiativeId: string) => {
    navigate(`/initiatives/${initiativeId}`);
  };

  const handleOnPageChange = (page: number) => {
    setSearchParams({ page: (page + 1).toString() });
  };

  const handleTeamsSelect = (teams: TeamBaseDto[]) => {
    setSearchParams({ page: API_FIRST_PAGE.toString() });
    setSelectedTeams(teams);
  };

  return (
    <div className={styles.wrapper}>
      {/*  Header */}
      <div className={styles.header_wrapper}>
        <div>
          <Heading>Initiatives</Heading>
          <p>
            Forge a path to success by creating, communicating, and seamlessly
            tracking initiatives tailored to achieve your organization's goals.
          </p>
        </div>
        <div>
          <Button onClick={handleCreateInitiative}>
            <ButtonLabel>Create initiative</ButtonLabel>
          </Button>
        </div>
      </div>

      <div className={styles.search_filter_wrapper}>
        <div className={styles.searchbar_wrapper}>
          <Searchbar
            value={searchQuery}
            onChange={handleOnSearch}
            placeholder="Search initiatives by name"
          />
        </div>
        <TeamSelectContainer onChange={handleTeamsSelect} />
      </div>

      <div className={styles.table_wrapper}>
        <InitiativeDataTable
          initiatives={initiatives && initiatives.content}
          isLoading={isLoading}
          isFetching={isFetching}
          onRowClick={handleClickInitiativeRow}
          onAddInitiativeClick={handleCreateInitiative}
          totalPages={initiatives && initiatives.totalPages}
          onPageChange={handleOnPageChange}
          currentPage={currentPage}
          totalElements={initiatives && initiatives.totalElements}
          isTeamsSelected={selectedTeams.length > 0}
        />
      </div>
    </div>
  );
};
