import { useQuery } from "react-query";
import { ScoreCardDto } from "_sredx/types";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "../clientApi";

export interface UseGetScoreCardsProps {
  includeOverview: boolean;
}

export const useGetScoreCards = ({
  includeOverview,
}: UseGetScoreCardsProps) => {
  return useQuery<ScoreCardDto[]>({
    queryKey: [QUERY_KEYS.scoreCards, includeOverview],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getScoreCards(), {
        params: {
          overview: includeOverview,
        },
      });
    },
    keepPreviousData: true,
  });
};
