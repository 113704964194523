import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { ApiErrorResponse, TeamBaseDto, TeamsImportRequestDto } from "_sredx/types";
import { useMutation, useQueryClient } from "react-query";
import { ClientApi } from "../clientApi";

export const useTeamsDiscovery = () => {
    const queryClient = useQueryClient();
  return useMutation<TeamBaseDto[], ApiErrorResponse, TeamsImportRequestDto>({
    mutationKey: [MUTATION_KEYS.importTeams],
    mutationFn: (teamsImportRequestDto: TeamsImportRequestDto) => {
      return ClientApi.post(
        API_ENDPOINTS.importTeams(),
        teamsImportRequestDto
      );
    },
    
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.teams], {
        exact: false,
      });
    },
  });

  
};
