interface IconProps {
  size?: number;
  color?: string;
}
export const ChevronUpIcon = ({ size, color = "currentColor" }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="m12.001 10.06-5.47 5.47-1.06-1.06L12 7.94l6.53 6.53-1.06 1.06L12 10.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
