import clsx from "clsx";
import styles from "./Loader.module.css";

interface LoaderProps {
  size?: "small" | "medium" | "large";
}
export const Loader = ({ size = "medium" }: LoaderProps) => {
  const classNames = clsx(styles.wrapper, styles[size]);
  return (
    <div className={classNames}>
      <div className={styles.item_1}></div>
      <div className={styles.item_2}></div>
      <div className={styles.item_3}></div>
      <div className={styles.item_4}></div>
    </div>
  );
};
