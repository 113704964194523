export const mapStatusToIndicator = (toolStatus: string) => {
  switch (toolStatus.toLowerCase()) {
    case "true":
    case "healthy":
      return "success";
    case "progressing":
      return "warning";
    case "degraded":
    case "suspended":
    case "missing":
    case "false":
      return "danger";
    case "unknown":
    default:
      return "unknown";
  }
};

export const determineStatusLabel = (toolStatus: string) => {
  switch (toolStatus.toLowerCase()) {
    case "true":
      return "Ready";
    case "false":
      return "Not Ready";
    default:
      return toolStatus;
  }
};
