import { useInfiniteGetServices } from "_sredx/services";
import { useMemo } from "react";
import { InfiniteData } from "react-query";
import { PagedResponse, ServiceBaseDto } from "_sredx/types";

const mapInfiniteServices = (
  infiniteServices?: InfiniteData<PagedResponse<ServiceBaseDto>>
) => {
  if (
    !infiniteServices ||
    !infiniteServices.pages ||
    !Array.isArray(infiniteServices.pages) ||
    infiniteServices.pages.length === 0
  ) {
    return [];
  }
  const { pages } = infiniteServices;
  return pages.reduce((result: ServiceBaseDto[], page) => {
    if (page.content && Array.isArray(page.content)) {
      result.push(...page.content);
    }

    return result;
  }, []);
};
export const useFilterOptionsServices = (params?: any) => {
  const {
    data: infiniteServices,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    isError,
  } = useInfiniteGetServices({
    params: {
      name: params?.searchQuery,
    },
  });

  const services = useMemo(() => {
    return mapInfiniteServices(infiniteServices);
  }, [infiniteServices]);

  const getNextPage = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  return {
    isLoading,
    isFetching,
    fetchNextPage: getNextPage,
    isError,
    data: services.map((service) => ({
      label: service.name,
      value: service.id,
    })),
  };
};
