import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import {
  PagedResponse,
  ServiceRichDto,
  ServiceSearchCriteria,
} from "_sredx/types";
import { useQuery } from "react-query";

interface UseGetEvaluatedServicesParams {
  params: ServiceSearchCriteria;
}

export const useGetEvaluatedServices = ({
  params,
}: UseGetEvaluatedServicesParams) => {
  return useQuery<PagedResponse<ServiceRichDto>>({
    queryKey: [QUERY_KEYS.services_scores, params],
    queryFn: async () =>
      await ClientApi.get(API_ENDPOINTS.getEvaluatedServices(), {
        params: {
          ...params,
        },
      }),
    refetchOnWindowFocus: false,
    retry: 0,
    keepPreviousData: true,
  });
};
