import { Card, Heading, LogoSREDXBlack, Text } from "_sredx/_ui";
import styles from "./UserExpiredTokenStep.module.css";

export const UserExpiredTokenStep = () => {
  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.wrapper_header}>
          <LogoSREDXBlack />
        </div>
        <div className={styles.wrapper_content}>
          <Heading level={2}>Invalid Token</Heading>
          <Text>
            Provided invite link is invalid or expired. Please request a new URL
            in order to create your account.
          </Text>
        </div>
      </div>
    </Card>
  );
};
