import { Controller, FieldValues } from "react-hook-form";
import { FormFieldProps } from "./types";
import { Label, Text, TextArea } from "_sredx/_ui";
import styles from "./FormFieldTextArea.module.css";
import React from "react";

export const FormFieldTextArea = <FieldsType extends FieldValues>({
  label,
  control,
  name,
  placeholder,
  disabled = false,
  errorMessage,
  fullWidth = false,
  ...props
}: FormFieldProps<FieldsType>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <div className={styles.wrapper}>
            {label && <Label>{label}</Label>}
            <TextArea
              value={field.value}
              placeholder={placeholder}
              id={field.name}
              onChange={field.onChange}
              name={field.name}
              ref={field.ref}
              onBlur={field.onBlur}
              disabled={disabled}
              fullWidth={fullWidth}
              {...props}
            />
            {errorMessage && (
              <Text className={styles.error_message}>{errorMessage}</Text>
            )}
          </div>
        );
      }}
    />
  );
};
