import { FormField } from "components/basic/FormField";
import { TextInput } from "components/octo-ui/Forms/TextInput";
import React, { FC, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useIntegrationsTemplates } from "../../../../hooks/integrations/templates/useIntegrationsTemplates";
import IntegrationTemplate, {
  RuleTemplate,
} from "../../../../models/integration/IntegrationTemplate";
import Rule from "../../../../models/scorecard/Rule";
import RuleCategory from "../../../../models/scorecard/RuleCategory";
import {
  getIntegrationFromRuleKey,
  getRuleTemplateFromRuleKey,
} from "../../../../services/integrations/templates/ruletemplates.converter";
import { FormErrorMessageComponent } from "../../../basic/FormErroMessage/FormErrorMessageComponent";
import GenericRuleOperandFieldComponent from "../../../basic/GenericRuleOperandFieldComponent/GenericRuleOperandFieldComponent";
import GenericRuleParamFieldComponent from "../../../basic/GenericRuleParamFieldComponent/GenericRuleParamFieldComponent";
import { FormActions } from "../FormActions/FormActions";
import {
  ruleCategoryValidator,
  ruleNameValidator,
  rulenDescriptionValidator,
  rulenScoreValidator,
} from "./EditRuleFormValidators";
import { customStyles } from "./styles";

interface EditRuleComponentProps {
  originalRule: Rule;
  isNewRule: boolean;
  closeEditRuleComponentCallBack: Function;
  listOfCategories: RuleCategory[];

  addNewCategoryCallBack(category: RuleCategory): void;

  updateRuleDetailsCallBack(rule: Rule): void;

  creatNewRuleCallBack(rule: Rule): void;
}

const EditRuleFormComponent: FC<EditRuleComponentProps> = ({
  listOfCategories,
  addNewCategoryCallBack,
  originalRule,
  isNewRule,
  closeEditRuleComponentCallBack,
  updateRuleDetailsCallBack,
  creatNewRuleCallBack,
}) => {
  const {
    data: listOfIntegrationsTemplates,
    isLoading: isLoadingListOfIntegrations,
  } = useIntegrationsTemplates();

  const [updatedRule, setUpdatedRule] = useState({ ...originalRule });
  const [isListOfIntegrationsReady, setIsListOfIntegrationsReady] =
    useState(false);

  const [selectedIntegration, setSelectedIntegration] =
    useState<IntegrationTemplate | null>(null);
  const [selectedRuleTemplate, setSelectedRuleTemplate] =
    useState<RuleTemplate | null>(null);

  const methods = useForm<Rule>();
  const { isSubmitting, isDirty, errors } = useFormState({
    control: methods.control,
  });

  useEffect(() => {
    setIsListOfIntegrationsReady(false);
    const newSelectedIntegration = getIntegrationFromRuleKey(
      listOfIntegrationsTemplates,
      updatedRule.key!
    );
    setSelectedIntegration(newSelectedIntegration ?? null);
    setSelectedRuleTemplate(
      getRuleTemplateFromRuleKey(
        newSelectedIntegration?.ruleTemplates,
        updatedRule.key!
      ) ?? null
    );
    setTimeout(() => {
      setIsListOfIntegrationsReady(true);
    });
  }, [listOfIntegrationsTemplates, updatedRule]);
  useEffect(() => {
    // Set rule to edit
    setUpdatedRule(originalRule);
  }, [originalRule]);
  useEffect(() => {
    // Set ruleCategory select value
    methods.reset(updatedRule);
  }, [methods, updatedRule]);

  const integrationFieldKey = "integration";
  const ruleTemplateFieldKey = "ruleTemplate";
  const saveRule = async (savedRule: Rule) => {
    if (!selectedIntegration) {
      // @ts-ignore
      methods.setError(integrationFieldKey, {
        message: "Choose an integration type to continue.",
      });
      return;
    }
    if (!selectedRuleTemplate) {
      // @ts-ignore
      methods.setError(ruleTemplateFieldKey, {
        message: "Choose a rule to continue.",
      });
      return;
    }

    savedRule.key = selectedRuleTemplate?.ruleKey;
    if (isNewRule) {
      creatNewRuleCallBack(savedRule);
    } else {
      updateRuleDetailsCallBack(savedRule);
    }
    methods.reset();
  };

  const deleteOperationFromUpdatedRule = () => {
    methods.setValue("operation", undefined);
  };

  // conditions on colors
  const isValidateButtonDisabled = !isDirty || isSubmitting;
  return (
    <>
      <FormProvider {...methods}>
        <form
          id="edit-rule-form"
          className="space-y-8 ml-5 p-5 "
          onSubmit={methods.handleSubmit(saveRule)}
        >
          <FormActions
            formId={"edit-rule-form"}
            showResetButton={isValidateButtonDisabled}
            onReset={() => closeEditRuleComponentCallBack()}
            isSaveButtonDisabled={isValidateButtonDisabled}
          >
            <div className="space-y-8 ">
              <div className="grid grid-cols-10">
                <div id="editRuleFormFields" className="col-span-9">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label
                        htmlFor={`ruleCategory`}
                        className="block text-sm font-medium text-gray-700"
                      >
                        Choose Rule Category
                      </label>
                      <div className="mt-1">
                        <Controller
                          control={methods.control}
                          rules={{
                            required: {
                              value: true,
                              message: "You must choose a rule category.",
                            },

                            validate: ruleCategoryValidator,
                          }}
                          name={`ruleCategory`}
                          render={({ field: { onChange, value, ref } }) => (
                            <CreatableSelect
                              id="rule-category-select-field"
                              inputId={"rule-category-select-input-field"}
                              onChange={onChange}
                              styles={customStyles}
                              ref={ref}
                              defaultValue={updatedRule.ruleCategory}
                              isClearable={true}
                              onCreateOption={(input) => {
                                const newCategory = {
                                  categoryName: input,
                                  categoryKey: input,
                                };
                                addNewCategoryCallBack(newCategory);
                                onChange(newCategory);
                              }}
                              allowCreateWhileLoading={false}
                              getNewOptionData={(inputValue: string) => ({
                                categoryKey: inputValue,
                                categoryName: `Create ${inputValue}`,
                              })}
                              value={value}
                              options={listOfCategories}
                              getOptionLabel={(option) => option.categoryName}
                              getOptionValue={(option) => option.categoryKey}
                            />
                          )}
                        />
                        <FormErrorMessageComponent
                          errors={errors}
                          fieldName={`ruleCategory`}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <div className="mt-1">
                        <label
                          htmlFor={"name"}
                          className="block text-sm font-medium text-gray-700"
                        >
                          Rule Name
                        </label>
                        <FormField
                          rules={{
                            required: {
                              value: true,
                              message: "The name is required.",
                            },
                            validate: ruleNameValidator,
                          }}
                          id="rule-name-field"
                          name={"name"}
                          component={TextInput}
                          defaultValue={updatedRule.name}
                          error={errors?.name?.message}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <div className="mt-1">
                        <label
                          htmlFor={"description"}
                          className="block text-sm font-medium text-gray-700"
                        >
                          Rule Description
                        </label>
                        <FormField
                          rules={{
                            required: {
                              value: true,
                              message: "The description is required.",
                            },
                            validate: rulenDescriptionValidator,
                          }}
                          id="rule-description-field"
                          name={"description"}
                          component={TextInput}
                          defaultValue={updatedRule.description}
                          error={errors?.description?.message}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <div className="mt-1">
                        <label
                          htmlFor={"score"}
                          className="block text-sm font-medium text-gray-700"
                        >
                          Rule Score
                        </label>
                        <FormField
                          rules={{
                            required: {
                              value: true,
                              message: "The score is required.",
                            },
                            validate: rulenScoreValidator,
                          }}
                          name={"score"}
                          id="rule-score-field"
                          error={errors.score?.message}
                          defaultValue={updatedRule.score}
                          component={TextInput}
                          type={"number"}
                        />
                      </div>
                    </div>
                    {/*Select wanted integration*/}
                    {isListOfIntegrationsReady && (
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="integration"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Choose Integration Type
                        </label>
                        <div className="mt-1">
                          <Select
                            styles={customStyles}
                            id={"rule-integration-field"}
                            classNamePrefix={"rule_integration_field"}
                            options={listOfIntegrationsTemplates}
                            isLoading={isLoadingListOfIntegrations}
                            defaultValue={selectedIntegration}
                            isClearable={true}
                            onChange={(newValue) => {
                              if (selectedIntegration !== newValue) {
                                setSelectedIntegration(newValue!);

                                // @ts-ignore
                                methods.clearErrors(integrationFieldKey);
                                setSelectedRuleTemplate(null);
                                deleteOperationFromUpdatedRule();
                              }
                            }}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                          />

                          <FormErrorMessageComponent
                            errors={errors}
                            fieldName={integrationFieldKey}
                          />
                        </div>
                      </div>
                    )}
                    {/*Select from list of possible rules*/}
                    {isListOfIntegrationsReady && !!selectedIntegration && (
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="rule-key"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Choose a Rule
                        </label>
                        <div className="mt-1">
                          <Select
                            id={"rule-key-field"}
                            styles={customStyles}
                            classNamePrefix={"rule_key_field"}
                            options={selectedIntegration?.ruleTemplates}
                            value={selectedRuleTemplate}
                            isClearable={true}
                            getOptionValue={(option) => option.ruleKey}
                            getOptionLabel={(option) => option.name}
                            onChange={(newRule) => {
                              if (selectedRuleTemplate !== newRule) {
                                setSelectedRuleTemplate(newRule);
                                // @ts-ignore
                                methods.clearErrors(ruleTemplateFieldKey);
                                deleteOperationFromUpdatedRule();
                              }
                            }}
                          />
                          <FormErrorMessageComponent
                            errors={errors}
                            fieldName={ruleTemplateFieldKey}
                          />
                        </div>
                      </div>
                    )}

                    {/*Show list of params*/}
                    {isListOfIntegrationsReady &&
                      selectedIntegration &&
                      selectedRuleTemplate &&
                      selectedRuleTemplate.arguments.map((argument) => {
                        return (
                          <GenericRuleParamFieldComponent
                            key={argument.key}
                            // handleChange={handleParamChange}
                            initialValue={
                              updatedRule.operation?.params[argument.key]
                            }
                            control={methods.control}
                            argumentTemplate={argument}
                          />
                        );
                      })}
                    {/*Show Operand*/}
                    {isListOfIntegrationsReady &&
                      selectedIntegration &&
                      selectedRuleTemplate && (
                        <GenericRuleOperandFieldComponent
                          formControl={methods.control}
                          binaryOperatorInitialValue={
                            updatedRule.operation?.binaryOperator
                          }
                          targetOperandInitialValue={
                            updatedRule.operation?.targetOperand
                          }
                          returnType={selectedRuleTemplate.returnType}
                        />
                      )}
                  </div>
                </div>
                <div className={"col-span-1 flex justify-center m-0"}>
                  <div
                    className={"h-6 w-6"}
                    id="close-edit-rule-form-button"
                    onClick={() => closeEditRuleComponentCallBack()}
                  >
                    <button className="text-red-700 hover:bg-gray-200 h-6 w-6">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </FormActions>
        </form>
      </FormProvider>
    </>
  );
};

export default EditRuleFormComponent;
