import emptyImage from "assets/image/Group 1.png";
import CapImg from "assets/image/image3.png";
import IronManImg from "assets/image/image4.png";
import thorImg from "assets/image/image5.png";
import { useMediaQuery } from "hooks/useMediaQuery";
import Media from "models/scorecard/Media";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { dataURLtoFile, mergeRefs, resolveMediaUrl } from "./helpers";
import styles from "./uploadImages.module.css";

const elements = [
  {
    classname: styles.UploadImage_item,
    src: CapImg,
  },
  {
    classname: styles.UploadImage_item,
    src: IronManImg,
  },
  {
    classname: styles.UploadImage_item,
    src: thorImg,
  },
];

interface UploadImagesProps {
  defaultValue: string;
  name: string;
  onChange: Function;
  value: Media | null;
}

export const UploadImages = forwardRef(
  ({ defaultValue = "", onChange, name, value }: UploadImagesProps, ref) => {
    const matchesSmallScreen = useMediaQuery("(max-width: 700px)");
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [selectedImage, setSelectedImage] = useState<string>(defaultValue);
    const handleOnClickInput = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (inputRef) {
        inputRef.current?.click();
      }
    };

    const handleImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
      e.preventDefault();
      setSelectedImage(e.currentTarget.src);
      const file = dataURLtoFile(e.currentTarget.src, "image.png");
      onChange(file);
    };
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files && inputRef && inputRef.current) {
        inputRef.current.files = files;
        const fileURLs = Array.from(files).map((file) =>
          URL.createObjectURL(file)
        );
        setSelectedImage(fileURLs[0]);
        onChange(files[0]);
      }
    };
    const handleOnEmptyImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
      e.preventDefault();
      if (matchesSmallScreen && inputRef) {
        inputRef.current?.click();
      }
    };
    useEffect(() => {
      if (value) {
        const url = resolveMediaUrl(value.mediaData);
        setSelectedImage(url);
      }
    }, [value]);
    return (
      <div className={styles.UploadImage_wrapper}>
        <div
          onClick={handleOnEmptyImageClick}
          className={styles.UploadImage_image_container}
        >
          {selectedImage ? (
            <img
              id="image"
              className={styles.UploadImage_image_container}
              src={selectedImage}
              alt={"selected"}
            />
          ) : (
            <img src={emptyImage} alt={"empty"} />
          )}
        </div>
        <div className={styles.UploadImage_images}>
          {elements.map((element, index) => (
            <img
              onClick={handleImageClick}
              key={index.toString()}
              className={element.classname}
              src={element.src}
              alt={`Default ${index + 1}`}
            />
          ))}
          {!matchesSmallScreen && (
            <button
              onClick={handleOnClickInput}
              className={styles.UploadImage_input_button}
            />
          )}

          <input
            data-testid={"input"}
            className={styles.UploadImage_input}
            type={"file"}
            accept="image/png, image/jpeg"
            ref={mergeRefs(inputRef, ref)}
            name={name}
            hidden
            onChange={handleOnChange}
          />
        </div>
      </div>
    );
  }
);
