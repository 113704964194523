import { Heading, PageContent } from "_sredx/_ui";
import { ServicesCreationContainer } from "_sredx/containers";

export const ServicesCreationPage = () => {
  return (
    <PageContent>
      <Heading>Add services</Heading>
      <ServicesCreationContainer />
    </PageContent>
  );
};
