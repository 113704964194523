import { useQuery } from "react-query";

import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { IntegrationStatus } from "_sredx/types";
import { ClientApi } from "../clientApi";

export const useGetIntegrationsStatus = () => {
  return useQuery<IntegrationStatus[]>({
    queryKey: [QUERY_KEYS.integrations, "status"],
    queryFn: async () => ClientApi.get(API_ENDPOINTS.getIntegrationsStatus()),
  });
};
