// Initialize some variables

// BrushChart
export const brushMargin = { top: 10, bottom: 15, left: 50, right: 20 };
export const chartSeparation = 30;
export const PATTERN_ID = "brush_pattern";
export const GRADIENT_ID = "brush_gradient";
export const accentColor = "#000"; //"#f6acc8";
export const background = "#584153";
export const background2 = "#50BFCE"; //"#af8baf";
export const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: "black",
};

// AreaChart
export const axisColor = "#000";
export const axisBottomTickLabelProps = {
  textAnchor: "middle" as const,
  fontFamily: "Arial",
  fontSize: 10,
  fill: axisColor,
};
export const axisLeftTickLabelProps = {
  dx: "-0.25em",
  dy: "0.25em",
  fontFamily: "Arial",
  fontSize: 10,
  textAnchor: "end" as const,
  fill: axisColor,
};
