import React, { FC, useEffect, useMemo, useState } from "react";
import { RefreshIcon, SearchIcon } from "@heroicons/react/solid";
import { useScoreCards } from "../../../hooks/scorcards/useScoreCards";
import ScoreCard from "../../../models/scorecard/ScoreCard";
import ServiceScoreCardStats from "../../../models/evaluations/ServiceStatsByScoreCard";
import { ScoreCardEvaluation } from "../../../models/evaluations/ScoreCardEvaluation";
import ServiceOverviewPerScoreCardComponent from "../../scorecard/ScoreCardDetails/ScoreCardServicesList/ServiceOverviewPerScoreCard/ServiceOverviewPerScoreCardComponent";
import ServiceRulesListPerScoreCardComponent from "../../scorecard/ScoreCardDetails/ScoreCardServicesList/ServiceOverviewPerScoreCard/ServiceRulesListPerScoreCard";
import ServiceScoreProgressPerScoreCardComponent from "../../scorecard/ScoreCardDetails/ScoreCardServicesList/ServiceOverviewPerScoreCard/ServiceScoreProgressPerScoreCardComponent";
import { getServiceStatsFromScoreCardLastEvaluation } from "../../../services/evaluations/ServiceEvaluations.helper";
import ServiceLevelsPerScoreCardComponent from "../../scorecard/ScoreCardDetails/ScoreCardServicesList/ServiceOverviewPerScoreCard/ServiceLevelsPerScorecardComponent";
import { TextInput } from "components/octo-ui/Forms/TextInput";

const TABS = {
  OVERVIEW: "Overview",
  RULES: "Rules",
  LEVELS: "Service Levels",
  SCORE: "Trends",
};

interface ServiceScoreCardsListComponentProps {
  serviceId: string;
  //List of last evaluation by each scorecard on this service
  serviceLastEvaluations?: ScoreCardEvaluation[];
  borderless?: boolean;
}

const ServiceScoreCardsListComponent: FC<
  ServiceScoreCardsListComponentProps
> = ({ serviceId, serviceLastEvaluations, borderless }) => {
  const evaluatedScoreCardsIdsList: string[] = useMemo(
    () => serviceLastEvaluations?.map((e) => e.scoreCardId) ?? [],
    [serviceLastEvaluations]
  );

  const {
    data: scoreCardsData,
    isSuccess: isScoreCardsDataLoaded,
    isLoading: isLoadingScoreCardsData,
  } = useScoreCards(false);

  const [selectedScoreCard, setSelectedScoreCard] = useState<
    ScoreCard | undefined
  >();
  const [selectedScoreCardStats, setSelectedScoreCardStats] = useState<
    ServiceScoreCardStats | undefined
  >();
  const [shownScoreCardsData, setShownScoreCardsData] = useState<
    ScoreCard[] | undefined
  >(
    scoreCardsData?.filter((s) => evaluatedScoreCardsIdsList.includes(s.id)) ??
      []
  );
  const [activeTab, setActiveTab] = useState(TABS.OVERVIEW);

  useEffect(() => {
    if (selectedScoreCard) {
      setSelectedScoreCardStats(
        getServiceStatsFromScoreCardLastEvaluation(
          serviceLastEvaluations
            ?.filter((e) => e.scoreCardId == selectedScoreCard.id)
            .at(0)!
        )
      );
    }
  }, [selectedScoreCard, serviceLastEvaluations]);

  useEffect(() => {
    setShownScoreCardsData(
      scoreCardsData?.filter((s) =>
        evaluatedScoreCardsIdsList.includes(s.id)
      ) ?? []
    );
  }, [evaluatedScoreCardsIdsList, scoreCardsData, serviceLastEvaluations]);

  useEffect(() => {
    setSelectedScoreCard(shownScoreCardsData?.at(0));
  }, [shownScoreCardsData]);
  const handleSearchScorecards = (value: string) => {
    setShownScoreCardsData(
      scoreCardsData?.filter(
        (scorecard) =>
          evaluatedScoreCardsIdsList.includes(scorecard.id) &&
          scorecard.name.toLowerCase().includes(value.toLowerCase())
      ) ?? []
    );
  };

  const resolveTabContent = () => {
    switch (activeTab) {
      case TABS.OVERVIEW:
        if (selectedScoreCard && selectedScoreCardStats)
          return (
            <ServiceOverviewPerScoreCardComponent
              overViewData={selectedScoreCardStats.overview}
            />
          );
        return <></>;
      case TABS.LEVELS:
        if (selectedScoreCard && selectedScoreCardStats)
          return (
            <ServiceLevelsPerScoreCardComponent
              levels={selectedScoreCard.levels}
              rules={selectedScoreCardStats.rulesStatus}
            />
          );
        return <></>;

      case TABS.SCORE:
        if (selectedScoreCard && selectedScoreCardStats)
          return (
            <ServiceScoreProgressPerScoreCardComponent
              scoreCardId={selectedScoreCard.id}
              serviceId={serviceId}
            />
          );
        return <></>;
      case TABS.RULES:
        if (selectedScoreCard && selectedScoreCardStats)
          return (
            <ServiceRulesListPerScoreCardComponent
              rulesStatusByCategory={selectedScoreCardStats.rulesStatus}
            />
          );
        return <></>;
    }
  };

  return (
    <>
      <div id="service-scorecards-list-container" className="w-full">
        <div className="mx-auto">
          {!borderless && (
            <h2 className="text-lg leading-6 font-medium text-gray-900 pl-4 my-4">
              Scorecards
            </h2>
          )}
          {shownScoreCardsData?.length == 0 && isScoreCardsDataLoaded && (
            <div className={"text-center"}>
              No scorecards were used to evaluate this service.
            </div>
          )}
          {isLoadingScoreCardsData && (
            <RefreshIcon className={"animate-spin h-5 w-5 mx-auto"} />
          )}
          {isScoreCardsDataLoaded && (
            <div
              className={`lg:grid lg:grid-cols-4 h-full min-h-0 ${
                borderless ? "border-t" : "border"
              } border-black`}
            >
              <div className="flex h-full justify-center lg:justify-start lg:flex-col overflow-x-auto lg:overflow-y-auto border-r border-black">
                <div className="p-4 border-b border-black">
                  <TextInput
                    endIcon={<SearchIcon className="h-5 text-gray-400" />}
                    placeholder="Scorecard"
                    onChange={(e: any) =>
                      handleSearchScorecards(e.target.value)
                    }
                  />
                </div>
                <div className="overflow-y-auto">
                  {shownScoreCardsData?.map((item, index) => (
                    <div
                      key={item.id}
                      id={`scorecards-list-item-${index}`}
                      onClick={() => {
                        setSelectedScoreCard(item);
                      }}
                      className={`flex-shrink ${
                        item.id === selectedScoreCard?.id
                          ? "bg-black text-white"
                          : "text-gray-600 hover:bg-cyan-50 hover:text-gray-900"
                      }
                      'group flex items-center px-3 py-2 text-sm font-medium border-b border-black cursor-pointer`}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-span-3 shadow">
                <div className="flex mb-4">
                  {Object.values(TABS).map((tab, i) => (
                    <button
                      key={i}
                      className={`px-4 py-2 ${
                        activeTab === tab
                          ? "border-b-2 border-black font-semibold"
                          : ""
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
                <div className={"flex-1 overflow-y-auto"}>
                  {resolveTabContent()}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceScoreCardsListComponent;
