import React from "react";
import tagStyle from "./TagStyles.module.css";

interface TagArguments {
    tag : string;
    color : string;
}

const Tag = ({tag, color} : TagArguments) => {
  return (
    <div className={tagStyle.tag} style={{color : `rgb(${color})`, backgroundColor : `rgba(${color}, 0.3)`}}>
        <svg className={tagStyle.tagIcon} fill={`rgb(${color})`} >
            <circle cx="4" cy="4" r="4"/>
        </svg>
        <span className={tagStyle.tagText}>{tag}</span>
    </div>
  )
}
export default Tag;
