import React, { useEffect, useState } from "react";
import { ParentSize } from "@visx/responsive";

import { Loader } from "_sredx/_ui";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from "_sredx/_ui/Select";
import { BrushChart } from "_sredx/components";
import { ServiceOrTeamSelectorContainer } from "_sredx/containers";
import { useGetScoreCards, useGetWidgetData } from "_sredx/services";
import {
  DASHBOARD_WIDGETS,
  ScoreInsight,
  ScoreTimelineDto,
} from "_sredx/types";

import { mapScorecardsToOptions } from "./utils";
import styles from "./ScoreTimelineContainer.module.css";

export const ScoreTimelineContainer = () => {
  // States
  const [filters, setFilters] = useState<{ [key: string]: string }>({});

  // Services
  const { data = { scoresInsights: [] }, isLoading } =
    useGetWidgetData<ScoreTimelineDto>({
      key: DASHBOARD_WIDGETS.SCORECARDS.SCORE_TIMELINE,
      filters,
      enabled: !!filters.scorecard && !!filters.service,
    });

  const { data: scorecards } = useGetScoreCards({
    includeOverview: false,
  });

  // Event handlers
  const handleScorecardChange = (value: string) => {
    setFilters({
      ...filters,
      scorecard: value,
    });
  };

  const handleTeamOrServiceChange = (value: string, type: string) => {
    setFilters({
      ...filters,
      ...(type === "SERVICE" ? { service: value } : {}),
      ...(type === "TEAM" ? { team: value } : {}),
    });
  };

  // Side effects
  useEffect(() => {
    if (scorecards && scorecards.length) {
      setFilters({
        ...filters,
        scorecard: scorecards[0].id,
      });
    }
  }, [scorecards]);

  return (
    <div className={styles.wrapper}>
      <ServiceOrTeamSelectorContainer
        onChange={(value, type) => {
          handleTeamOrServiceChange(value.id, type);
        }}
      />
      <div className={styles.chart_wrapper}>
        {filters.scorecard && (
          <div className={styles.select_wrapper}>
            <Select
              onChange={handleScorecardChange}
              defaultValue={filters.scorecard}
            >
              <SelectTrigger
                placeholder={"select scorecard"}
                variant={"outlined"}
              />
              <SelectContent>
                {mapScorecardsToOptions(scorecards).map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}

        <Chart data={data.scoresInsights} isLoading={isLoading} />
      </div>
    </div>
  );
};

const Chart = ({
  data,
  isLoading,
}: {
  data: ScoreInsight[];
  isLoading: boolean;
}) => {
  if (isLoading)
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
      </div>
    );
  return (
    <>
      {data.length ? (
        <ParentSize>
          {({ width, height }) => (
            <BrushChart
              width={width}
              height={height}
              data={data.map((d) => ({
                date: d.evaluationDate,
                close: d.scorePercentage,
              }))}
            />
          )}
        </ParentSize>
      ) : (
        <div className={styles.empty_state}>no data found</div>
      )}
    </>
  );
};
