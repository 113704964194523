import React from "react";
import TopServicesDashboard from "./TopServicesDashboard";
import RiskServicesDashboard from "./RiskServiceDashboard";
import TopTeamDashboard from "./TopTeamDashboard";
import RiskTeamDashboard from "./RiskTeamDashboard";
import ImprovedServicesDashboard from "./ImprovedServicesDashboard";
import DropedServicesDashboard from "./DropedServicesDashboard";
import ImprovedTeamsDashboard from "./ImprovedTeamsDashboad";
import DropedTeamsDashboard from "./DropedTeamsDashboard";
import RulesAtRiskDashboard from "./RulesAtRisk";
import MovedRulesDashboard from "./MovedRules";
import ServiceScores from "../../../models/leaderboard/ServiceScores";
import OwnerServicesScores from "../../../models/dashborad/OwnerServicesScores";
import { ScoreCardEvaluation } from "../../../models/evaluations/ScoreCardEvaluation";

interface TableRendererProps {
    selectedTable?: string;
    serviceScoresData?: ServiceScores[];
    ownerServiceScoresData?: OwnerServicesScores[];
    scoresServiceData?: ServiceScores[];
    ownersServiceScoresData?: OwnerServicesScores[];
    EvaluationData?: ScoreCardEvaluation[];
    isSuccessServiceScores: boolean;
    isSuccesOwnerServiceScores: boolean;
    isSuccesScoresService: boolean;
    isSuccesOwnersServiceScores: boolean;
    isSuccesEvaluation: boolean;
}

const TableRenderer: React.FC<TableRendererProps> = ({
    selectedTable,
    serviceScoresData,
    ownerServiceScoresData,
    scoresServiceData,
    ownersServiceScoresData,
    EvaluationData,
    isSuccessServiceScores,
    isSuccesOwnerServiceScores,
    isSuccesScoresService,
    isSuccesOwnersServiceScores,
    isSuccesEvaluation,
  }) => {
    switch (selectedTable) {
        case "Top Services":
          return isSuccessServiceScores ? (
            <TopServicesDashboard servicesScoresData={serviceScoresData ||[]} />
          ) : null;
        case "Services at risk":
          return isSuccessServiceScores ? (
            <RiskServicesDashboard servicesScoresData={serviceScoresData ||[]} />
          ) : null;
        case "Top team":
          return isSuccesOwnerServiceScores ? (
            <TopTeamDashboard ownersData={ownerServiceScoresData ||[]} />
          ) : null;
        case "Team at risk":
          return isSuccesOwnerServiceScores ? (
            <RiskTeamDashboard ownersData={ownerServiceScoresData ||[]} />
          ) : null;
        case "Most improved services":
          return isSuccesScoresService ? (
            <ImprovedServicesDashboard dataServices={scoresServiceData ||[]} />
          ) : null;
        case "Worst drop services":
          return isSuccesScoresService ? (
            <DropedServicesDashboard dataServices={scoresServiceData ||[]} />
          ) : null;
        case "Most improved teams":
          return isSuccesOwnersServiceScores ? (
            <ImprovedTeamsDashboard dataOwnersServices={ownersServiceScoresData ||[]} />
          ) : null;
        case "Worst drop teams":
          return isSuccesOwnersServiceScores ? (
            <DropedTeamsDashboard dataOwnersServices={ownersServiceScoresData ||[]} />
          ) : null;
        case "Rules at most risk":
          return isSuccesEvaluation ? (
            <RulesAtRiskDashboard scorecardEvaluationData={EvaluationData ||[]} />
          ) : null;
        case "Most moved Rules":
          return isSuccesEvaluation ? (
            <MovedRulesDashboard scorecardEvaluationData={EvaluationData ||[]} />
          ) : null;
        default:
          return null;
      }
};
    
export default TableRenderer;