import { AdvancedFilterConfig } from "./types";

export const getPropertyConfigByKey = (
  config: AdvancedFilterConfig[],
  key: AdvancedFilterConfig["key"] | undefined,
): AdvancedFilterConfig | undefined => {
  return config.find((item) => item.key === key);
};

export const getPropertyTypeByKey = (
  config: AdvancedFilterConfig[],
  key: AdvancedFilterConfig["key"] | undefined,
): AdvancedFilterConfig["type"] | undefined => {
  const property = getPropertyConfigByKey(config, key);
  return property?.type;
};
