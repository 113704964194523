import { Heading, PageContent } from "_sredx/_ui";
import { RoadmapCreationContainer } from "_sredx/containers";
import styles from "./RoadmapCreationPage.module.css";

export const RoadmapCreationPage = () => {
  return (
    <PageContent align={"flex-start"} maxWidth={800} className={styles.wrapper}>
      <Heading>Create Roadmap</Heading>
      <RoadmapCreationContainer />
    </PageContent>
  );
};
