import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  KeyValue,
  LogoSREDXBlack,
  Tag,
  Text,
} from "_sredx/_ui";
import { ServiceBaseDto } from "_sredx/types";
import styles from "./ServiceDetailsOverview.module.css";
import { useNavigate } from "react-router-dom";
import Tooltip from "components/octo-ui/Tootlip/Tooltip";
import { useMemo } from "react";
import { resolveIntegration, resolveResourceIcon } from "_sredx/utils";
import { MdOpenInNew } from "react-icons/md";
import { useModal } from "_sredx/hooks";
import { InfoCircularIcon } from "_sredx/_ui/Icon";
import { ServiceAnnotationsModal } from "../ServiceAnnotationsModal";
import { ServiceDetailsResourcesModal } from "../ServiceDetailsResourcesModal";

interface ServiceDetailsOverviewProps {
  service: ServiceBaseDto;
}

export const ServiceDetailsOverview = ({
  service,
}: ServiceDetailsOverviewProps) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const resolvedTools = useMemo(() => {
    if (!service.tools) return [];
    return service.tools.map((tool) => {
      const resolved = resolveIntegration(tool.key);
      return {
        ...resolved,
        url: tool.url,
      };
    });
  }, [service.tools]);

  const handleClickTool = (url: string) => {
    window.open(url, "_blank");
  };

  const handleClickResource = () => {
    navigate(`/resources`);
  };

  const onClickViewAnnotations = () => {
    openModal({
      content: (
        <ServiceAnnotationsModal service={service} onClose={closeModal} />
      ),
      options: {},
    });
  };

  const onClickViewResources = () => {
    openModal({
      content: (
        <ServiceDetailsResourcesModal
          resources={service.resources ?? []}
          onClose={closeModal}
        />
      ),
      options: {},
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>About</CardTitle>
      </CardHeader>

      <CardBody>
        <div className={styles.body}>
          <KeyValue label={"Service tag"} value={service.tag} />
          <KeyValue
            className={styles.description}
            label={"Service Description"}
            value={service.description}
          />

          <KeyValue
            label={"Tags"}
            value={
              <div className={styles.tags}>
                {service.metadata?.tags?.map((tag, i) => (
                  <Tag key={i} tag={tag} />
                ))}
              </div>
            }
          />

          <KeyValue
            label={"Service Teams"}
            value={
              <div>
                <div className={styles.teams_cell}>
                  {service.owners.slice(0, 2).map((owner, i) => (
                    <div
                      key={i}
                      onClick={() => navigate(`/teams/${owner.id}/services`)}
                    >
                      <Tooltip content={owner.name}>
                        <Avatar
                          key={owner.id}
                          size={20}
                          name={owner.name}
                          rectangle
                          avatarStyle="identicon"
                        />
                      </Tooltip>
                    </div>
                  ))}
                  {service.owners.length > 2 && (
                    <span className={styles.more_owners}>
                      +{service.owners.length - 2}
                    </span>
                  )}
                </div>
              </div>
            }
          />
          <KeyValue
            label={"Domains"}
            value={
              <div className={styles.domains_cell}>
                {service.domains.length > 0 ? (
                  service.domains.map((domain, i) => (
                    <div className={styles.domain_cell}>
                      <Avatar
                        rectangle
                        size={16}
                        name={domain.name}
                        avatarStyle={"initials"}
                      />
                      <span className={styles.selected_item_label}>
                        {domain.name}
                      </span>
                    </div>
                  ))
                ) : (
                  <Text>-</Text>
                )}
              </div>
            }
          />

          <KeyValue
            label={"Integrations"}
            value={
              <div>
                <div className={styles.tags}>
                  {resolvedTools.map(
                    (tool, i) =>
                      tool.id != 0 && (
                        <Button
                          key={i}
                          ghost={true}
                          variant="neutral"
                          size="small"
                          onClick={() => {
                            handleClickTool(tool.url);
                          }}
                        >
                          <div className={styles.integration_button}>
                            <div>{tool.icon}</div>
                            {tool.name}
                            <MdOpenInNew size={12} />
                          </div>
                        </Button>
                      )
                  )}
                </div>
                <button
                  className={styles.view_annotations_button}
                  onClick={onClickViewAnnotations}
                >
                  View annotations
                  <InfoCircularIcon />
                </button>
              </div>
            }
          />
          <KeyValue
            label={"Resources"}
            value={
              <div>
                <div className={styles.tags}>
                  {service.resources && service.resources.length > 0
                    ? service.resources.slice(0, 2).map((resource, i) => (
                        <Button
                          key={i}
                          ghost={true}
                          variant="neutral"
                          size="small"
                          onClick={() => {
                            handleClickResource();
                          }}
                        >
                          <div className={styles.integration_button}>
                            <div>
                              {resolveResourceIcon({
                                ...resource,
                                services: [],
                              })}
                            </div>
                            {resource.name}
                            <MdOpenInNew size={12} />
                          </div>
                        </Button>
                      ))
                    : "-"}
                </div>
                {service.resources?.length && service.resources?.length > 2 ? (
                  <button
                    className={styles.view_annotations_button}
                    onClick={onClickViewResources}
                  >
                    {service.resources?.length - 2} more resources
                    <InfoCircularIcon />
                  </button>
                ) : null}
              </div>
            }
          />
          <KeyValue
            label={"Imported from"}
            value={
              <div className={styles.importedFrom}>
                {service.source == "sredx" || service.source == null ? (
                  <div className={styles.sredx_logo}>
                    <LogoSREDXBlack />
                  </div>
                ) : (
                  <div className={styles.integration_button}>
                    <div>{resolveIntegration(service.source).icon}</div>
                    {resolveIntegration(service.source).name}
                  </div>
                )}
              </div>
            }
          />
        </div>
      </CardBody>
      <CardFooter />
    </Card>
  );
};
