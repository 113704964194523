import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { REACT_APP_API_URL } from "config/config";
import { useMemo, useState } from "react";

/**
 * Returns an authorizated axios instance
 *
 * @param {Object} config is the default config to be sent to the axios creator
 * @param {boolean} v2 is a flag to use the v2 api
 *
 * @return {Object} an object containing the axios instance and the initialized prop
 *
 */
export const useAxios = (config: any = {}, v2: boolean = false) => {
  const { initialized: authInitialized, keycloak } = useKeycloak();
  const { token } = keycloak;

  const [initialized, setInitialized] = useState(false);

  const expensiveAxios = () => {
    const API_BASE_URL = REACT_APP_API_URL ?? "http://localhost:8080";

    const instance = axios.create({
      ...config,
      baseURL: `${API_BASE_URL}${v2 ? "/api/v2" : "/api/internal/v1"}`,
      headers: {
        ...(config.headers || {}),
        "Content-Type": "application/json",
        Authorization: authInitialized && token ? `Bearer ${token}` : undefined,
      },
    });
    setInitialized(true);
    return { instance };
  };
  const axiosInstance = useMemo(
    () => expensiveAxios(),
    [token, authInitialized]
  );

  return { axios: axiosInstance.instance, initialized };
};
