import { useQuery } from "react-query";
import { ClusterDetailsFoundation } from "types/selfService";
import { SELF_SERVICE_PATHS, SELF_SERVICE_QUERY_KEYS } from "./paths";
import { useAxios } from "hooks/useAxios";

export const useClusterFoundations = (id: string) => {
  const { axios } = useAxios();

  const fetchClusters = async () => {
    const response = await axios(
      SELF_SERVICE_PATHS.CLUSTER_DETAIL_FOUNDATIONS(id)
    );
    return response.data;
  };

  return useQuery<ClusterDetailsFoundation[]>(
    [SELF_SERVICE_QUERY_KEYS.CLUSTER_DETAIL_FOUNDATIONS, id],
    fetchClusters,
    {
      //initialData: mockData,
    }
  );
};