import { useReducer } from "react";
import { useModal } from "_sredx/hooks";
import { Button, ButtonLabel } from "_sredx/_ui";
import {
  TeamCreationMembers,
  TeamCreationMembersAction,
  TeamCreationServices,
  TeamCreationServicesAction,
  TeamInfoForm,
  TeamInfoFormData,
} from "_sredx/components";
import {
  useAssignServices,
  useCreateUsers,
  useTeamCreation,
  useTeamMember,
} from "_sredx/services";
import { TeamCreationFormData } from "./types";
import { ServicesAssignmentContainer } from "../ServicesAssignmentContainer";
import {
  isValidTeamCreationFormData,
  mapTeamCreationFormDataToTeamCreationDto,
  mapTeamServicesToServiceBaseDtos,
  teamCreationReducer,
} from "./utils";
import styles from "./TeamCreationContainer.module.css";
import { TeamInvitationContainer } from "../TeamInvitationContainer";
import { useNavigate } from "react-router-dom";
import { useToaster } from "hooks/useToaster";
import { ServiceBaseDto } from "_sredx/types";
import { chainTeamMembersCreationMutations } from "../TeamDetailsMembersContainer/utils";

const TEAM_CREATION_DEFAULT_FORM_DATA: TeamCreationFormData = {
  generalInfo: {
    name: "",
    description: "",
  },
  members: [],
  services: [],
};

export const TeamCreationContainer = () => {
  // Hooks
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { addToast } = useToaster();

  // States
  const [teamCreationData, dispatch] = useReducer(
    teamCreationReducer,
    TEAM_CREATION_DEFAULT_FORM_DATA
  );

  // Services
  const { mutate: createTeam, isLoading } = useTeamCreation();

  const { mutateAsync: assignMembers } = useTeamMember();
  const { mutateAsync: mutateNewUsers } = useCreateUsers();

  const { mutate: assignServices } = useAssignServices();

  // Event handlers
  const handleTeamMembersAction = (action: TeamCreationMembersAction) => {
    switch (action.type) {
      case "CLICK_ADD_MEMBERS":
        handleOnAddMembers();
        return;
      case "DELETE_MEMBER":
        dispatch({
          type: "DELETE_MEMBER",
          payload: {
            memberEmail: action.payload.memberEmail,
          },
        });
        return;
      case "CHANGE_ROLE":
        dispatch({
          type: "CHANGE_MEMBER_ROLE",
          payload: {
            memberEmail: action.payload.memberEmail,
            role: action.payload.role,
          },
        });
        return;
      default:
        return;
    }
  };

  const handleTeamServicesAction = (action: TeamCreationServicesAction) => {
    switch (action.type) {
      case "CLICK_ADD_SERVICES":
        handleOnAddServices();
        return;
      case "DELETE_SERVICE":
        dispatch({
          type: "DELETE_SERVICE",
          payload: {
            service: action.payload.service,
          },
        });
        return;
      default:
        return;
    }
  };

  const handleOnSubmitServices = (services: ServiceBaseDto[]) => {
    dispatch({
      type: "SET_SERVICES",
      payload: {
        services,
      },
    });
    closeModal();
  };

  const handleOnCancelAddServices = () => {
    closeModal();
  };

  const handleOnTeamInfoChange = (generalInfo: TeamInfoFormData) => {
    dispatch({
      type: "SET_GENERAL_INFO",
      payload: {
        generalInfo,
      },
    });
  };
  const handleOnAddMembers = () => {
    openModal({
      content: (
        <div className={styles.modal_members_wrapper}>
          <TeamInvitationContainer
            hide={closeModal}
            members={teamCreationData.members}
            onSubmit={(members) => {
              dispatch({
                type: "SET_MEMBERS",
                payload: {
                  members,
                },
              });
              closeModal();
            }}
          />
        </div>
      ),
    });
  };

  const handleOnAddServices = () => {
    openModal({
      content: (
        <div className={styles.modal_services_wrapper}>
          <ServicesAssignmentContainer
            onCancel={handleOnCancelAddServices}
            onConfirm={handleOnSubmitServices}
            selectedServices={mapTeamServicesToServiceBaseDtos(
              teamCreationData.services
            )}
          />
        </div>
      ),
    });
  };

  const handleCancel = () => {
    navigate("/teams");
  };

  const handleCreateTeam = () => {
    if (!isValidTeamCreationFormData(teamCreationData)) {
      return;
    }
    createTeam(
      mapTeamCreationFormDataToTeamCreationDto(teamCreationData).team,
      {
        onSuccess: async (response) => {
          const teamId = response as unknown as string;
          await chainTeamMembersCreationMutations({
            teamId: teamId ?? "",
            members: teamCreationData.members,
            mutateNewUsers,
            assignMembers,
          });
          await assignServices({
            id: teamId ?? "",
            services: teamCreationData.services.map((s) => ({
              id: s.id,
            })),
          });

          navigate("/teams");
        },

        onError: (e: any) => {
          addToast({
            type: "error",
            message:
              e?.response?.data?.message ||
              e?.message ||
              "Something went wrong",
          });
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <TeamInfoForm onChange={handleOnTeamInfoChange} />
      <TeamCreationMembers
        members={teamCreationData.members}
        onAction={handleTeamMembersAction}
      />
      <TeamCreationServices
        services={teamCreationData.services}
        onAction={handleTeamServicesAction}
      />
      <div className={styles.action_buttons}>
        <Button variant={"neutral"} ghost onClick={handleCancel}>
          <ButtonLabel>cancel</ButtonLabel>
        </Button>
        <Button
          disabled={!isValidTeamCreationFormData(teamCreationData)}
          isLoading={isLoading}
          onClick={handleCreateTeam}
        >
          <ButtonLabel>create</ButtonLabel>
        </Button>
      </div>
    </div>
  );
};
