import { Link, useLocation } from "react-router-dom";
import { generateBreadcrumbs } from "./utils";
import { ROUTES } from "_sredx/routes";
import { Breadcrumbs, Text } from "_sredx/_ui";
import clsx from "clsx";
import styles from "./AppBreadcrumbs.module.css";

interface AppBreadcrumbsProps {
  transparent?: boolean;
}

export const AppBreadcrumbs = ({ transparent }: AppBreadcrumbsProps) => {
  const { pathname } = useLocation();
  const crumbs = generateBreadcrumbs(ROUTES, pathname);
  return (
    <div className={clsx(styles.wrapper, transparent && styles.transparentBg)}>
      <Breadcrumbs>
        {crumbs.map((crumb, index) => (
          <Link key={index} to={crumb.path}>
            <Text>{crumb.label}</Text>
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
};
