import { Button, ButtonLabel, Loader, Text } from "_sredx/_ui";
import { OnboardingImportedServicesTable } from "_sredx/components";
import { useBatchServiceDiscovery } from "_sredx/services";
import { ServiceBaseDto } from "_sredx/types";
import { UseQueryResult } from "react-query";
import { AdminOnboardingStep } from "_sredx/containers";
import { EXTERNAL_PROVIDERS } from "_sredx/containers/ProviderConfigurationStepContainer/types";
import styles from "./OnboardingServicesImportStepContainer.module.css";
import { useModal } from "_sredx/hooks";
import { NoServicesSelectedWarningModal } from "./NoServicesSelectedWarningModal";
import { mapServiceProvidersToDiscoveryKeys } from "./utils";

interface OnboardingServicesImportStepContainerProps
  extends AdminOnboardingStep {
  selectedServices: ServiceBaseDto[];
  providersValidationResults: Record<string, boolean>;
  setSelectedServices: (services: ServiceBaseDto[]) => void;
}

export const OnboardingServicesImportStepContainer = ({
  onNextStep,
  onPreviousStep,
  selectedServices,
  providersValidationResults,
  setSelectedServices,
}: OnboardingServicesImportStepContainerProps) => {
  // Hooks
  const { openModal, closeModal } = useModal();

  const serviceProviders = EXTERNAL_PROVIDERS.filter(
    (provider) => providersValidationResults[provider.key]
  );

  // Services
  const results = useBatchServiceDiscovery({
    externalServiceProviderKeys:
      mapServiceProvidersToDiscoveryKeys(serviceProviders),
  }) as UseQueryResult<{
    data: ServiceBaseDto[];
    failedInstances: string[];
  }>[];

  // Derived variables
  const isLoading =
    !results?.length || results.some((query) => query.isLoading);

  const anySuccessfulQuery = results.some((query) => !query.isError);

  const services = results.flatMap((result) => result?.data?.data ?? []);

  // Event handlers
  const handleOnNextStep = () => {
    if (onNextStep) {
      if (!selectedServices.length) {
        openModal({
          content: <NoServicesSelectedWarningModal onClose={closeModal} />,
        });
      } else onNextStep();
    }
  };

  const handleOnPreviousStep = () => {
    if (onPreviousStep) {
      onPreviousStep();
    }
  };

  const handleOnCheckChange = (checked: boolean, service: ServiceBaseDto) => {
    if (checked) {
      setSelectedServices([...selectedServices, service]);
    } else {
      setSelectedServices(
        selectedServices.filter((s) => s.name !== service.name)
      );
    }
  };

  const handleOnCheckAll = (checked: boolean) => {
    if (checked && results) {
      setSelectedServices(services);
    } else {
      setSelectedServices([]);
    }
  };

  // UI waterfall
  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>
          Loading Services... <br /> Please wait, this might take a moment.
        </Text>
      </div>
    );
  }

  if (!anySuccessfulQuery) {
    return (
      <div className={styles.error_wrapper}>
        <Text>An unexpected error has occured</Text>
        <Text>The import failed for all providers</Text>
        <Text>Check again the providers' config and retry</Text>
        <Button onClick={handleOnPreviousStep} variant="neutral">
          <ButtonLabel>Previous</ButtonLabel>
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <OnboardingImportedServicesTable
        serviceProviders={serviceProviders}
        selectedServices={selectedServices}
        onSelectService={handleOnCheckChange}
        importedServices={
          results as UseQueryResult<{
            data: ServiceBaseDto[];
            failedInstances: string[];
          }>[]
        }
        onCheckAll={handleOnCheckAll}
      />
      <div className={styles.actions_wrapper}>
        <Button onClick={handleOnPreviousStep} variant="neutral">
          <ButtonLabel>Previous</ButtonLabel>
        </Button>

        <Button onClick={handleOnNextStep}>
          <ButtonLabel>Next</ButtonLabel>
        </Button>
      </div>
    </div>
  );
};
