import { Button, ButtonIcon, Text } from "_sredx/_ui";
import { TrashIcon } from "_sredx/_ui/Icon";
import { Service, ServicesListAction } from "../types";
import styles from "./ServiceRow.module.css";

interface ServiceRowProps {
  service: Service;
  onAction: (action: ServicesListAction) => void;
}

export const ServiceRow = ({ service, onAction }: ServiceRowProps) => {
  const handleDeleteClicked = () => {
    onAction({
      type: "DELETE_SERVICE",
      payload: {
        service,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <Text>{service.name}</Text>
      <Button onClick={handleDeleteClicked} variant={"neutral"} ghost>
        <ButtonIcon>
          <TrashIcon />
        </ButtonIcon>
      </Button>
    </div>
  );
};
