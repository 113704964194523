import { ConfigParam } from "models/integration/IntegrationTemplate";
import * as Yup from "yup";

export const createYupSchema = (fields: ConfigParam[]) => {
    const shape: Record<string, Yup.AnySchema> = {
        instanceName : Yup.string().required("Instance name is required and should be unique")
    };
  
    fields.forEach((field) => {
      let schema: Yup.AnySchema ;
  
      switch (field.type) {
        case "STRING":
          schema = Yup.string();
        
          break;
        case "INTEGER":
          schema = Yup.number().integer();
          break;
        case "DOUBLE":
          schema = Yup.number();
          break;
        case "BOOLEAN":
          schema = Yup.boolean();
          break;
        case "LIST":
          schema = Yup.string().oneOf(Object.keys(field.values));
          break;
        default:
          schema = Yup.string();
          break;
      }
  
      if (!field.notRequired) {
        schema = schema.required(`${field.name} is required`);
      }

      if (field.multiple ) {
        schema = Yup.array().of(schema);
      }
  
      shape[field.key] = schema;
    });
  
    return Yup.object().shape(shape);
  };