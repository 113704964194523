import Service, { ServiceUpdateInput } from "models/service/Service";
import { FormProvider, useForm } from "react-hook-form";
import styles from "./styles.module.css";
import { useUpdateService } from "hooks/useUpdateService";
import { yupResolver } from "@hookform/resolvers/yup";
import { ServiceFormFields } from "./ServiceFormFields";
import { mapServiceToFormData, mapFormDataToService, Schema } from "./utils";
import { XIcon } from "@heroicons/react/outline";

interface EditServiceFormProps {
  service: Service;
  hide: () => void;
}

export const EditServiceForm = ({ service, hide }: EditServiceFormProps) => {
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(Schema),
    defaultValues: mapServiceToFormData(service),
  });

  const { mutate } = useUpdateService();

  const handleSubmitForm = (formData: any) => {
    const updatedServiceData: ServiceUpdateInput = {
      ...mapFormDataToService(formData),
      id: service.id,
    };
    mutate(updatedServiceData, {
      onSuccess: () => {
        hide();
      },
    });
  };

  return (
    <div className="p-4 bg-white border border-black max-h-[90vh] overflow-auto">
      <FormProvider {...methods}>
        <div className={styles.wrapper}>
          <div className="flex justify-between items-center">
            <h1 className="text-2xl">Edit Service</h1>
            <button onClick={hide}>
              <XIcon height={"20px"} />
            </button>
          </div>
          <ServiceFormFields />
          <div className="flex justify-end mt-2">
            <button
              className={`${styles.saveButton} ${styles.saveButtonValid}`}
              onClick={methods.handleSubmit(handleSubmitForm)}
            >
              Update
            </button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};
