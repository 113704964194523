import { InputDatePopover } from "./InputDatePopover";
import { InputDateRangePopover } from "./InputDateRangePopover";
import { AdvancedFiltersInputValueProps } from "../types";
import { FILTER_OPERATORS } from "../../types";

interface InputDateProps extends AdvancedFiltersInputValueProps {}

export const InputDate = ({ value, operator, onChange }: InputDateProps) => {
  const handleOnDateChange = (value: [Date, Date] | Date) => {
    onChange(value);
  };

  if (
    operator == FILTER_OPERATORS.AFTER ||
    operator == FILTER_OPERATORS.BEFORE
  ) {
    return <InputDatePopover onChange={handleOnDateChange} value={value} />;
  }

  return <InputDateRangePopover onChange={handleOnDateChange} value={value} />;
};
