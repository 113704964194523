import React, { ComponentProps } from "react";
import { Controller } from "react-hook-form";

export type SingleValue = string | number | bigint | boolean | object | File;
export type Value = SingleValue | SingleValue[];

type FunctionProps = {
  [key: string]: Function;
};

type CustomPropsType = {
  customProps: FunctionProps;
  value: Value;
};

const buildCustomProps = ({ customProps, value }: CustomPropsType) =>
  Object.keys(customProps).reduce(
    (acc, key: string) => ({ ...acc, [key]: customProps[key](value) }),
    {}
  );

type FormProps<T extends React.ElementType> = {
  id: string;
  name: string;
  component: React.ElementType;
  defaultValue?: Value;
  valueField?: string;
  rules?:any;
  refField?: string;
  onChange?: Function;
  customProps?: FunctionProps;
} & Partial<ComponentProps<T>>;

export const FormField = <T extends React.ElementType>({
  name,
  defaultValue,
  refField = "inputRef",
  valueField = "value",
  component: Component,
  customProps = {},
  onChange,
    rules = {},
  ...props
}: FormProps<T>) => (
  // @ts-ignore
  <Controller
    name={name}
    rules={rules}
    defaultValue={defaultValue}
    render={({ field: { value, ref, onChange: _onChange, ...fieldProps } }) => {
      return (
        <Component
          {...{
            ...fieldProps,
            ...props,
            ...buildCustomProps({ customProps, value }),
            onChange: (_value: Value) => {
              _onChange(_value);
              if (onChange) {
                onChange(_value);
              }
            },
            [valueField]: value,
            [refField]: ref,
          }}
        />
      );
    }}
  />
);
