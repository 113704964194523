interface UserIconProps {
  size?: number;
  color?: string;
}
export const UserIcon = ({ size, color = "currentColor" }: UserIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M6 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM3.5 3.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm-1.5 7a4 4 0 1 1 8 0 .5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5Zm1.041-.5H8.96a3 3 0 0 0-5.918 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
