import styles from "./RoadmapCalendarHeader.module.css";
import { RoadmapBaseDto } from "_sredx/types";
import { AppReturnButton } from "_sredx/Layout";
import {
  Button,
  ButtonIcon,
  ButtonLabel,
  Heading,
  PageContent,
} from "_sredx/_ui";
import { PlusIcon } from "_sredx/_ui/Icon";

interface RoadmapCalendarHeaderProps {
  roadmap: RoadmapBaseDto;
  onAddInitiative: () => void;
}
export const RoadmapCalendarHeader = ({
  roadmap,
  onAddInitiative,
}: RoadmapCalendarHeaderProps) => {
  return (
    <PageContent>
      <div className={styles.wrapper}>
        <AppReturnButton to={"/roadmaps"} label={"Back to roadmaps"} />
        <div className={styles.title_actions}>
          <div className={styles.title}>
            <Heading level={1}>{roadmap.name}</Heading>
          </div>
          <div className={styles.actions}>
            <Button onClick={onAddInitiative}>
              <ButtonIcon>
                <PlusIcon />
              </ButtonIcon>
              <ButtonLabel>Add initiative</ButtonLabel>
            </Button>
          </div>
        </div>
      </div>
    </PageContent>
  );
};
