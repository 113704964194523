import clsx from "clsx";
import { Text } from "../Text";
import styles from "./Tabs.module.css";

interface TabItemProps {
  active?: boolean;
  label: string;
  onClick?: () => void;
}

export const TabItem = ({ label, active = false, onClick }: TabItemProps) => {
  return (
    <li
      className={clsx(styles.tab_item, active && styles.tab_item_active)}
      onClick={onClick}
    >
      <Text>{label}</Text>
    </li>
  );
};
