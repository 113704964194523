import { PageLayout } from "components/Layout";
import { ServiceListContainer } from "containers/ServiceListContainer";
import { ADMIN_SERVICES_PAGE } from "routes/paths";
import { useNavigate } from "react-router-dom";
import { Button } from "components/octo-ui/Button/Button";

export const AdminServicesPage = () => {
  const navigate = useNavigate();
  const CallToAction = () => (
    <Button
      variant="primaryDark"
      onClick={() => navigate(`${ADMIN_SERVICES_PAGE}/add`)}>
      New service
    </Button>
  );

  return (
    <PageLayout
      title={"Service Catalog"}
      description="Integrate, create and manage new services with ease"
      actions={<CallToAction />}
      // extended
    >
      <ServiceListContainer />
    </PageLayout>
  );
};
