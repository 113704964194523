import React from "react";

import styles from "./Achievements.module.css";
import { AchievementDto } from "./types";
import { groupAchievements } from "./utils";
import { AchievementItem } from "./AchievementItem";

interface AchievementsProps {
  achievements: AchievementDto[];
  onClick?: (scoreCardId: string) => void;
}

export const Achievements = ({ achievements, onClick }: AchievementsProps) => {
  // Derived variables
  const groupedAchievements = groupAchievements(achievements);

  return (
    <div className={styles.wrapper}>
      {Object.keys(groupedAchievements).map((key,i) => {
        const achievements = groupedAchievements[key];
        return (
          <AchievementItem key={i} achievements={achievements} onClick={onClick} />
        );
      })}
    </div>
  );
};
