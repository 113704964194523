import { useMutation, useQueryClient } from "react-query";
import { ClientApi } from "../clientApi";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { WidgetInfoDto } from "_sredx/types";

export const useEditDashboard = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      id: string;
      name: string;
      description: string;
      widgets: WidgetInfoDto[];
    }) => ClientApi.put(API_ENDPOINTS.editDashboard(data.id), data),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.me]);
    },
  });
};
