import { InfoCircleIcon } from "_sredx/_ui/Icon";
import { MetricChartCompareOption } from "./types";
import styles from "./MetricChartHeader.module.css";

interface MetricChartHeaderProps {
  title: string;
  tooltip?: string;
  compareOptions?: MetricChartCompareOption[];
  onCompareChange?: (compareWith: MetricChartCompareOption[]) => void;
}
export const MetricChartHeader = ({ title }: MetricChartHeaderProps) => {
  return (
    <header className={styles.wrapper}>
      <div className={styles.title_wrapper}>
        <h3 className={styles.title}>{title}</h3>
        {/*  TODO - tooltip info component */}
        <span className={styles.tooltip_wrapper}>
          <InfoCircleIcon size={12} />
        </span>
      </div>
      <div className={styles.action_wrapper}></div>
    </header>
  );
};
