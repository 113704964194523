import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { useMutation, useQueryClient } from "react-query";
import { ClientApi } from "../clientApi";

interface AssignServicesDto {
  id: string;
  services: ServiceDto[];
}

interface ServiceDto {
  id: string;
}

export const useAssignServices = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEYS.assignServices],
    mutationFn: async (data: AssignServicesDto) => {
      return await ClientApi.post(API_ENDPOINTS.assignServices(data.id), data.services);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.teams], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.team], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.services], {
        exact: false,
      });
    },
  });
};
