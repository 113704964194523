import { useEffect, useRef, useState } from "react";

export const useSse = <T>(path: string) => {
    const envSettings = window as any;
    const baseUrl = envSettings.REACT_APP_API_URL ? envSettings.REACT_APP_API_URL : "http://localhost:8080";
    const url = `${baseUrl}${path}`;
    
    const [sseData, setSseData] = useState<T | null>(null);
    const [connectionStatus, setConnectionStatus] = useState<string>("disconnected");
  
    const eventSourceRef = useRef<EventSource | null>(null);
  
    const connect = () => {
      eventSourceRef.current = new EventSource(url);
      setConnectionStatus("connecting");
  
      eventSourceRef.current.onopen = () => {
        setConnectionStatus("connected");
        console.log("connected")

      };
  
      eventSourceRef.current.onerror = () => {
        setConnectionStatus("error");
      };
  
      eventSourceRef.current.onmessage = (event: MessageEvent) => {
        const parsedData = JSON.parse(event.data);
        setSseData(parsedData);
      };
    };
  
    const disconnect = () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        setConnectionStatus("disconnected");
        console.log("disconnected")
      }
    };
  
    useEffect(() => {
      return () => {
        disconnect();
      };
    }, []);
  
    return { sseData, connectionStatus, connect, disconnect };
};

export default useSse;
