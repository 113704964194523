import React from 'react';
import style from "./ConfigParamsContainer.module.css";

export const ConfigParamsSkeleton = () => {
  return (
    <div className={style.skeleton_wrapper}>
      <div className={style.config_skeleton}>
        <div className={style.param_skeleton}></div>
        <div className={style.param_value_skeleton}></div>
      </div>
      <div className={style.config_skeleton}>
        <div className={style.param_skeleton}></div>
        <div className={style.param_value_skeleton}></div>
      </div>
      <div className={style.config_skeleton}>
        <div className={style.param_skeleton}></div>
        <div className={style.param_value_skeleton}></div>
      </div>
      <div className={style.config_skeleton}>
        <div className={style.param_skeleton}></div>
        <div className={style.param_value_skeleton}></div>
      </div>
      <div className={style.config_skeleton}>
        <div className={style.param_skeleton}></div>
        <div className={style.param_value_skeleton}></div>
      </div>
    </div>
  );
}
