import { ToolCard } from "components/self-service/ClusterDetails/ToolCard";
import { useClusterFoundations } from "services/self-service";
import styles from "./ClusterDetailsToolsContainer.module.css";
import ClusterDetailsToolsSkeleton from "./ClusterDetailsToolsSkeleton";
import { useEffect } from "react";
import { useToaster } from "hooks/useToaster";
interface ClusterDetailsToolsContainerProps {
  id: string;
}
export const ClusterDetailsToolsContainer = ({
  id,
}: ClusterDetailsToolsContainerProps) => {
  const { data, isLoading, isError } = useClusterFoundations(id);
  const { addToast } = useToaster();
  useEffect(() => {
    if (isError) {
      addToast({
        message: `Error Occured while fetching Foundations for cluster ${id}.`,
        type: "error",
      });
    }
  }, [addToast, id, isError]);

  if (isLoading) return <ClusterDetailsToolsSkeleton />;
  return (
    <div className={styles.wrapper}>
      <h3>Foundations</h3>
      <div className={styles.section}>
        {/* <h4>{foundation.type}</h4> */}
        <div className={styles.toolList}>
          {data?.map((foundation, i) => (
            <ToolCard key={i} {...foundation} />
          ))}
        </div>
      </div>
    </div>
  );
};
