import { useState } from "react";
import {InteractionData} from "types/heatmap";
import { Renderer } from "../renderer/Renderer";
import { Tooltip } from "../tooltip/Tooltip";
import { ColorLegend } from "../color-legend/ColorLegend";
import emptyCanvasSvg from "../../../assets/svg/blank_canvas.svg";
import * as d3 from "d3";
import { HeatmapSekeleton } from "./HeatmapSkeleton";
import ServiceScores from "models/leaderboard/ServiceScores";

type HeatmapProps = {
  width: number;
  servicesScores: ServiceScores[] ;
  isLoadingServicesScores? : boolean;
  isErrorServicesScores? : boolean;
};



export const Heatmap = ({ width, servicesScores, isLoadingServicesScores , isErrorServicesScores  }: HeatmapProps) => {
  const [hoveredCell, setHoveredCell] = useState<InteractionData | null>(null);
  const [height, setHeight] = useState<number>(400);


  var colorScale = d3
    .scaleLinear<string>()
    .domain([0, 100])
    .interpolate(d3.interpolateHsl)
    .range(["red", "lightgreen"]);

  if (isLoadingServicesScores) {
    return (
      <div className="grid grid-cols-1 justify-items-center">
        <HeatmapSekeleton width={width} />
      </div>
    );
  }

  if (isErrorServicesScores) {
    return (
      <div className="grid grid-cols-1 justify-items-center">
        <p className="text-center text-gray-500">Something went wrong...</p>
      </div>
    );
  }

  if(!servicesScores || servicesScores.length === 0) {
    return <div className="grid grid-cols-1 justify-items-center">
    <div className="flex flex-col justify-center items-center w-full">
          <img className="w-1/6" src={emptyCanvasSvg} alt="Empty" />
          <p className="text-center text-gray-500 text-2xl">
            No Data available for the moment
          </p>
        </div>
  </div>
  }

  return (
    <div className="grid grid-cols-1 justify-items-center">
         <div className="divide-y">
          <div className="ml-6 mb-2 text-lg text-black  md:mt-0 ">
            Services per scorecard
          </div>
          <div className="flex flex-col items-center justify-center p-2">
            <div style={{ position: "relative" }}>
              <Renderer
                width={width}
                height={height}
                data={servicesScores}
                setHeight={setHeight}
                setHoveredCell={setHoveredCell}
                colorScale={colorScale}
              />
              <Tooltip interactionData={hoveredCell} width={width} height={height} />
            </div>
            <div className="ml-2">
              <ColorLegend
                width={(width * 2) / 3}
                height={80}
                colorScale={colorScale}
              />
            </div>
          </div>
        </div>

      </div>
  );
  
};
