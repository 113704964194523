import { API_ENDPOINTS, MUTATION_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import { ApiErrorResponse, RoadmapCreationDto } from "_sredx/types";
import { useMutation } from "react-query";

export const useRoadmapCreation = () => {
  return useMutation<string, ApiErrorResponse, RoadmapCreationDto>({
    mutationKey: [MUTATION_KEYS.createRoadmap],
    mutationFn: (data: RoadmapCreationDto) =>
      ClientApi.post(API_ENDPOINTS.createRoadmap(), data),
  });
};
