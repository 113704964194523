declare global {
  interface Window {
    REACT_APP_KEYCLOAK_URL: string;
    REACT_APP_KEYCLOAK_REALM: string;
    REACT_APP_KEYCLOAK_CLIENT_ID: string;
    REACT_APP_API_URL: string;
    REACT_APP_UNLEASH_URL: string;
    REACT_APP_UNLEASH_TOKEN: string;
    REACT_APP_UNLEASH_APP_NAME: string;
    REACT_APP_USE_V2: boolean;
  }
}
export const REACT_APP_KEYCLOAK_URL =
  process.env?.REACT_APP_KEYCLOAK_URL || window.REACT_APP_KEYCLOAK_URL;
export const REACT_APP_KEYCLOAK_REALM =
  process.env?.REACT_APP_KEYCLOAK_REALM || window.REACT_APP_KEYCLOAK_REALM;
export const REACT_APP_KEYCLOAK_CLIENT_ID =
  process.env?.REACT_APP_KEYCLOAK_CLIENT_ID ||
  window.REACT_APP_KEYCLOAK_CLIENT_ID;
export const REACT_APP_API_URL =
  process.env?.REACT_APP_API_URL || window.REACT_APP_API_URL;
export const REACT_APP_UNLEASH_URL =
  process.env?.REACT_APP_UNLEASH_URL || window.REACT_APP_UNLEASH_URL;
export const REACT_APP_UNLEASH_TOKEN =
  process.env?.REACT_APP_UNLEASH_TOKEN || window.REACT_APP_UNLEASH_TOKEN;
export const REACT_APP_UNLEASH_APP_NAME =
  process.env?.REACT_APP_UNLEASH_APP_NAME || window.REACT_APP_UNLEASH_APP_NAME;
export const REACT_APP_USE_V2 =
  process.env?.REACT_APP_USE_V2 || window.REACT_APP_USE_V2;
