import { PRCloseIcon, PRMergeIcon, PROpenIcon } from "_sredx/_ui/Icon";
import styles from "./PRState.module.css";
import clsx from "clsx";
import { PullRequestDto } from "_sredx/types";

const PRStateIcon = ({ state }: { state: "open" | "closed" | "merged" }) => {
  if (state === "open") {
    return <PROpenIcon size={14} color={"#fff"} />;
  }
  if (state === "closed") {
    return <PRCloseIcon size={14} color={"#fff"} />;
  }
  if (state === "merged") {
    return <PRMergeIcon size={14} color={"#fff"} />;
  }
  return null;
};

const PRStateText = ({ state }: { state: "open" | "closed" | "merged" }) => {
  if (state === "open") {
    return <span className={styles.label}>Open</span>;
  }
  if (state === "closed") {
    return <span className={styles.label}>Closed</span>;
  }
  if (state === "merged") {
    return <span className={styles.label}>Merged</span>;
  }
  return null;
};

const getPRStateKey = (pr: PullRequestDto) => {
  const { state, merged } = pr;
  if (state === "open") {
    return "open";
  }
  if (state === "closed") {
    return merged ? "merged" : "closed";
  }
  return null;
};

interface PRStateProps {
  pullRequest: PullRequestDto;
}
export const PRState = ({ pullRequest }: PRStateProps) => {
  const prStateKey = getPRStateKey(pullRequest);

  if (!prStateKey) {
    return null;
  }
  return (
    <span className={clsx(styles.wrapper, styles[prStateKey])}>
      <PRStateIcon state={prStateKey} />
      <PRStateText state={prStateKey} />
    </span>
  );
};
