import { Avatar, TableCell, TableRow } from "_sredx/_ui";
import { MemberDetails } from "_sredx/types";
import { resolveStatus } from "_sredx/utils";
import React from "react";
import { resolveInvitation } from "./utils";
import { capitalizeFirstLetter } from "_sredx/utils/string";
import { BiUnlink } from "react-icons/bi";

interface TeamDetailsMemberListRowProps {
  member: MemberDetails;
  onUnlink: (member: MemberDetails) => void;
}

export const TeamDetailsMemberListRow = ({
  member,
  onUnlink
}: TeamDetailsMemberListRowProps) => {
  const handleUnlink = () => {
    onUnlink(member);
  }

  return (
    <TableRow>
      <TableCell>
        <div className="flex items-center gap-2">
          <Avatar
            name={
              member.firstName
                ? `${member.firstName} ${member.lastName ?? ""}`
                : member.email ?? member.username
            }
          />
          {member.firstName
            ? `${member.firstName} ${member.lastName ?? ""}`
            : member.email ?? member.username}
        </div>
      </TableCell>
      <TableCell>{resolveStatus(member.status)}</TableCell>
      <TableCell>{member.email}</TableCell>
      <TableCell>{capitalizeFirstLetter(member.source)}</TableCell>
      <TableCell>
        {resolveInvitation(member.invitation, member.invitationExp)}
      </TableCell>
      <TableCell>{capitalizeFirstLetter(member.role)}</TableCell>
      <TableCell align="right">
        <button onClick={handleUnlink}>
          <BiUnlink />
        </button>
      </TableCell>
    </TableRow>
  );
};
