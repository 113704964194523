import * as yup from "yup";
import { InitiativeSchedulingFormData } from "./types";

export const schema = yup.object<InitiativeSchedulingFormData>().shape({
  startDate: yup.date().required("Start date is required."),
  endDate: yup
    .date()
    .when(
      "startDate",
      (startDate, yup) =>
        startDate && yup.min(startDate, "End date cannot be before start date.")
    ),
});
