import React, { ReactNode } from "react";

export const insertSeparators = (
  items: ReactNode[],
  className: string,
  separator: ReactNode = "/"
) => {
  return items.reduce((acc: ReactNode[], current, index) => {
    if (index < items.length - 1) {
      acc = acc.concat(
        current,
        <li aria-hidden key={`separator-${index}`} className={className}>
          {separator}
        </li>
      );
    } else {
      acc.push(current);
    }
    return acc;
  }, []);
};
