import {PropsWithChildren} from "react";
import styles from "./FormActions.module.css";

interface FormActionsProps {
    showResetButton: boolean;
    onReset: () => void;
    formId: string;
    isSaveButtonDisabled?: boolean;
}

export const FormActions = ({
                                showResetButton = false,
                                onReset,
                                children,
                                isSaveButtonDisabled = false,
                                formId,
                            }: PropsWithChildren<FormActionsProps>) => {
    return (
        <div className={"flex flex-col justify-between"}>
            {children}
            <div className={styles.FormActions_wrapper}>
                <button
                    onClick={onReset}
                    className={`${styles.Reset_Button} ${
                        !showResetButton ? "" : styles.Reset_Button_Hidden
                    }`}
                >
                    Reset
                </button>
                <button
                    id={"test-save"}
                    disabled={isSaveButtonDisabled}
                    form={formId}
                    type={"submit"}
                    className={`${styles.Save_Button} ${
                        isSaveButtonDisabled ? styles.Save_Button_Disabled : ""
                    }`}
                >
                    Save
                </button>
            </div>
        </div>
    );
};
