export const OPERATORS = {
  GTE: "GTE",
  LTE: "LTE",
  GT: "GT",
  LT: "LT",
  EQ: "EQ",
  NE: "NE",
  IN: "IN",
  NOT_IN: "NOT_IN",
} as const;

export type Operator = (typeof OPERATORS)[keyof typeof OPERATORS];
