import { TeamCreationAction, TeamCreationFormData } from "./types";
import {
  ServiceRichDto,
  TeamCreationDto,
  TeamMember,
  TeamMemberRoleType,
  TeamService,
} from "_sredx/types";
import { isEmpty } from "_sredx/utils";

export const mapTeamCreationFormDataToTeamCreationDto = (
  teamCreationFormData: TeamCreationFormData
): TeamCreationDto => {
  return {
    team: {
      name: teamCreationFormData.generalInfo.name,
      description: teamCreationFormData.generalInfo.description,
      tags: [],
    },
    members: teamCreationFormData.members.map((m) => ({
      userId: m.userId,
      userEmail: m.email,
      role: m.role,
    })),
    services: teamCreationFormData.services.map((s) => s.id),
  };
};

export const isValidTeamCreationFormData = (
  teamCreationFormData?: TeamCreationFormData
): boolean => {
  if (!teamCreationFormData || teamCreationFormData.members.length === 0)
    return false;
  return !isEmpty(teamCreationFormData.generalInfo.name);
};

export const teamCreationReducer = (
  state: TeamCreationFormData,
  action: TeamCreationAction
): TeamCreationFormData => {
  switch (action.type) {
    case "SET_GENERAL_INFO":
      return {
        ...state,
        generalInfo: action.payload.generalInfo,
      };
    case "DELETE_MEMBER":
      return {
        ...state,
        members: deleteMember(state.members, action.payload.memberEmail),
      };
    case "CHANGE_MEMBER_ROLE":
      return {
        ...state,
        members: changeMemberRole(
          state.members,
          action.payload.memberEmail,
          action.payload.role
        ),
      };
    case "SET_MEMBERS":
      return {
        ...state,
        members: [...action.payload.members],
      };
    case "DELETE_SERVICE":
      return {
        ...state,
        services: deleteService(state.services, action.payload.service),
      };
    case "SET_SERVICES":
      return {
        ...state,
        services: [...action.payload.services],
      };
    default:
      return state;
  }
};

const changeMemberRole = (
  members: TeamMember[],
  memberEmail: string,
  role: TeamMemberRoleType
): TeamMember[] => {
  return members.map((m) => {
    if (m.email === memberEmail) {
      return {
        ...m,
        role,
      };
    }
    return m;
  });
};

const deleteMember = (
  members: TeamMember[],
  memberEmail: string
): TeamMember[] => {
  return members.filter((m) => m.email !== memberEmail);
};

const deleteService = (services: TeamService[], service: TeamService) => {
  return services.filter((s) => s.id !== service.id);
};

// Mappers

export const mapTeamServicesToServiceBaseDtos = (
  teamServices: TeamService[]
) => {
  return teamServices.map(
    (s) =>
      ({
        id: s.id,
        name: s.name,
        owners: [],
        domains: [],
        tools: [],
        source: "",
        allRulesCount: 0,
        activeRulesCount: 0,
        description: "",
        failedPercentage: 0,
        failedRulesCount: 0,
        score: 0,
        scorePercentage: 0,
        successfulRulesCount: 0,
        tags: [],
        successPercentage: 0,
        tag: "",
        totalPossible: 0,
      } as ServiceRichDto)
  );
};
