import { Button, Card, Heading } from "_sredx/_ui";
import { TeamBaseDto } from "_sredx/types";
import React from "react";
import styles from "./DeleteTeamConfirmationModal.module.css";

interface DeleteTeamConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  team?: TeamBaseDto;
}

export const DeleteTeamConfirmationModal = ({
  onConfirm,
  onCancel,
  team,
}: DeleteTeamConfirmationModalProps) => {
  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Heading level={4}>Confirm Deletion</Heading>
          <p>
            Are you sure you want to delete <strong>{team?.name}</strong>?
          </p>
        </div>
        <div className={styles.actions}>
          <Button ghost onClick={onCancel} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={onConfirm}
            className={styles.deleteButton}
          >
            Delete
          </Button>
        </div>
      </div>
    </Card>
  );
};
