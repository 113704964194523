import { Button, ButtonIcon, Heading, Loader } from "_sredx/_ui";
import styles from "./ServiceDetailsContainer.module.css";
import { useParams } from "react-router-dom";
import { useGetService } from "_sredx/services/services/useGetService";
import { ServiceDetailsOverview } from "_sredx/components/ServiceDetailsOverview/ServiceDetailsOverview";
import { BiEdit } from "react-icons/bi";
import Service from "models/service/Service";
import { EditServiceForm } from "components/service/ServiceForm";
import { ServiceDeleteModal } from "_sredx/components/ServiceDeleteModal";
import { useDeleteService } from "hooks/useDeleteService";
import { MdDelete } from "react-icons/md";
import { useModal } from "_sredx/hooks";

export const ServiceDetailsContainer = ({ setError }: any) => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const { data, error, isLoading } = useGetService({ id: serviceId ?? "" });
  const {openModal , closeModal} = useModal();
  const mutation = useDeleteService();

  const handleEditService = (service: Service) => {
    openModal({
      content: <EditServiceForm service={service} hide={closeModal} />,
    });
  };
  const handleDeleteService = (service: Service) => {
    openModal({
      content: (
        <ServiceDeleteModal
          service={{ name: service.name, id: service.id }}
          onClose={closeModal}
          deleteMutation={mutation}
        />
      ),
    });
  };

  if (isLoading)
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
      </div>
    );
  if (error || !data) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Heading>{data.name}</Heading>
        <div className={styles.actions}>
          <Button onClick={() => handleEditService(data as Service)}>
            <ButtonIcon>
              <BiEdit />
            </ButtonIcon>
            Edit
          </Button>
          <Button onClick={() => handleDeleteService(data as Service)}>
            <ButtonIcon>
              <MdDelete />
            </ButtonIcon>
            Delete
          </Button>
        </div>
      </div>
      <ServiceDetailsOverview service={data} />
    </div>
  );
};
