import ClusterDetailsInfo from "components/self-service/ClusterDetails/ClusterDetailsInfo";
import ClusterDetailsInfoSkeleton from "components/self-service/ClusterDetails/ClusterDetailsInfoSkeleton";
import { useClusterDetailInfo } from "services/self-service/useClusterDetailInfo";
import { useKubeConfig } from "services/self-service/useKubeConfig";

const ClusterDetailsInfoFetchContainer = ({
  clusterId,
}: {
  clusterId: string;
}) => {
  const { data: cluster, isLoading, isError } = useClusterDetailInfo(clusterId);
  const { isClusterDownloading, handleDownload } = useKubeConfig();

  const handleClusterDownload = () => {
    if (cluster?.name) {
      handleDownload(cluster.name);
    }
  };

  if (isLoading) {
    return <ClusterDetailsInfoSkeleton />;
  }

  if (isError || !cluster) {
    return <p>Error fetching cluster details.</p>;
  }

  return (
    <ClusterDetailsInfo
      isDownloading={isClusterDownloading(cluster.name)}
      onDownload={handleClusterDownload}
      cluster={cluster}
    />
  );
};

export default ClusterDetailsInfoFetchContainer;
