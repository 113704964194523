import {
  Card,
  Empty,
  Loader,
  Pagination,
  ProgressBar,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_sredx/_ui";
import { RoadmapDataTableRow } from "_sredx/components/RoadmapListing/RoadmapDataTableRow";
import { ROADMAP_DEFAULT_SEARCHING_CRITERIA } from "_sredx/containers";
import { RoadmapBaseDto } from "_sredx/types";
import styles from "./RoadmapDataTable.module.css";

interface RoadmapDataTableProps {
  roadmaps?: RoadmapBaseDto[];
  isLoading: boolean;
  isFetching: boolean;
  totalPages?: number;
  currentPage?: number;
  totalElements?: number;
  onPageChange?: (page: number) => void;
  onAddRoadmapClick: () => void;
  onRowClick: (roadmapId: string) => void;
}

const HEADER_CELLS = ["Roadmap", "Start date", "End date", "Initiatives"];
export const RoadmapDataTable = ({
  roadmaps = [],
  isLoading,
  isFetching,
  onAddRoadmapClick,
  totalPages = 1,
  currentPage = 0,
  totalElements,
  onPageChange,
  onRowClick,
}: RoadmapDataTableProps) => {
  const showPagination = totalPages > 1;
  const count =
    (ROADMAP_DEFAULT_SEARCHING_CRITERIA.pageSize ?? 10) * currentPage +
    roadmaps.length;

  // Events handlers
  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };

  if (isLoading) {
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading roadmaps...</Text>
        </div>
      </Card>
    );
  }
  if (!isLoading && (!roadmaps || roadmaps.length == 0)) {
    return (
      <Card>
        <Empty
          message={"No roadmaps found."}
          actionLabel={"Add new Roadmap"}
          onActionClick={onAddRoadmapClick}
        />
      </Card>
    );
  }

  return (
    <Table>
      <TableHeader>
        {HEADER_CELLS.map((cell) => (
          <TableHead className={styles.header_cell} key={cell}>
            {cell}
          </TableHead>
        ))}
      </TableHeader>
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {roadmaps.map((roadmap) => (
          <RoadmapDataTableRow
            onRoadmapClick={onRowClick}
            roadmap={roadmap}
            key={roadmap.name}
          />
        ))}
      </TableBody>
      {/* Footer */}
      <TableFooter>
        <TableFooterCell colSpan={HEADER_CELLS.length}>
          <div className={styles.footer}>
            <span>
              {count} / {totalElements} Roadmaps
            </span>
            {showPagination && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handleOnPageChange}
                currentPage={currentPage}
              />
            )}
          </div>
        </TableFooterCell>
      </TableFooter>
    </Table>
  );
};
