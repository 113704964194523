import clsx from "clsx";
import { ChevronRightIcon } from "_sredx/_ui/Icon";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  Button,
  ButtonIcon,
  MenuItem,
  MenuItemIcon,
  MenuItemLabel,
  MenuItemLink,
  MenuList,
} from "_sredx/_ui";
import { ShortcutModal } from "_sredx/components/ShortcutModal";
import { useModal } from "_sredx/hooks";
import { MdEdit, MdPlaylistAdd } from "react-icons/md";
import { IoMdLink } from "react-icons/io";
import { NavLink } from "react-router-dom";
import styles from "./AppShortcutDropDown.module.css";
import { useState } from "react";

export const AppShortcutDropDown = ({
  isCollapsed,
}: {
  isCollapsed: boolean;
}) => {
  const [open , setOpen] = useState<boolean>(false);
  const { openModal, closeModal } = useModal();
  const [shortcuts, setShortcuts] = useLocalStorage<
    {
      title: string;
      url: string;
      id: number;
    }[]
  >("shortcuts", []);

  const handleAddShortcut = (shortcut: { title: string; url: string }) => {
    const newShortcut = {
      ...shortcut,
      id: new Date().getTime(),
    };
    setShortcuts([...shortcuts, newShortcut]);
  };

  const handleEditShortcut = (shortcut: any) => {
    const shortcutToEdit = shortcuts.find((s) => s.id === shortcut.id);
    if (!shortcutToEdit) return;
    const newShortcuts = shortcuts.map((s) =>
      s.id === shortcut.id ? { ...s, ...shortcut } : s
    );
    setShortcuts(newShortcuts);
  };
  const onAddShortcut = () => {
    openModal({
      content: (
        <ShortcutModal onSave={handleAddShortcut} onClose={closeModal} />
      ),
    });
  };
  const onEditShortcut = (shortcut: {
    title: string;
    url: string;
    id: number;
  }) => {
    openModal({
      content: (
        <ShortcutModal
          onSave={handleEditShortcut}
          onClose={closeModal}
          onDelete={onDeleteShortcut}
          shortcut={shortcut}
        />
      ),
    });
  };
  const onDeleteShortcut = (shortcut: {
    title: string;
    url: string;
    id: number;
  }) => {
    const newShortcuts = shortcuts.filter((s) => s.id !== shortcut.id);
    setShortcuts(newShortcuts);
  };
  return (
    <div className={styles.wrapper}>
      <MenuList>
        <MenuItem>
          <RadixDropdownMenu.Root open={open} onOpenChange={setOpen}>
            <RadixDropdownMenu.Trigger className={styles.trigger_style}>
              <MenuItemLink>
                {!isCollapsed && <MenuItemLabel>Shortcuts</MenuItemLabel>}
                <MenuItemIcon>
                  <ChevronRightIcon />
                </MenuItemIcon>
              </MenuItemLink>
            </RadixDropdownMenu.Trigger>

            <RadixDropdownMenu.Portal>
              <RadixDropdownMenu.Content
                className={styles.dropdown_menu_content}
                side="right"
                style={{ transform: "tr" }}
              >
                {shortcuts?.map((shortcut) => {
                  return (
                    <RadixDropdownMenu.Item
                      className={styles.dropdown_menu_item}
                    >
                      <div key={shortcut.url} className={styles.shortcut_item}>
                        <NavLink
                        onClick={() => setOpen(false)}
                          to={shortcut.url}
                          className={clsx(
                            styles.menu_item_link,
                            styles.shortcut
                          )}
                          key={shortcut.url}
                        >
                          <div className={styles.menu_item}>
                            <MenuItemIcon>
                              <IoMdLink className={styles.icon} />
                            </MenuItemIcon>
                            {!isCollapsed && <div>{shortcut.title}</div>}
                          </div>
                        </NavLink>
                        {!isCollapsed && (
                          <Button
                            ghost
                            className={styles.shortcut_edit}
                            onClick={() => {
                              onEditShortcut(shortcut);
                            }}
                          >
                            <ButtonIcon>
                              <MdEdit />
                            </ButtonIcon>
                          </Button>
                        )}
                      </div>{" "}
                    </RadixDropdownMenu.Item>
                  );
                })}
                <RadixDropdownMenu.Separator
                  className={styles.dropdown_menu_separator}
                />
                <RadixDropdownMenu.Item>
                  <div className={styles.shortcut_item}>
                    <NavLink
                      to={"#"}
                      onClick={onAddShortcut}
                      className={styles.menu_item_link}
                    >
                      <MenuItemLink>
                        <MenuItemIcon>
                          <MdPlaylistAdd />
                        </MenuItemIcon>
                        {!isCollapsed && (
                          <MenuItemLabel>Add Shortcut</MenuItemLabel>
                        )}
                      </MenuItemLink>
                    </NavLink>
                  </div>
                </RadixDropdownMenu.Item>
              </RadixDropdownMenu.Content>
            </RadixDropdownMenu.Portal>
          </RadixDropdownMenu.Root>
        </MenuItem>
      </MenuList>
    </div>
  );
};
