import styles from "./Achievements.module.css";
import { Card, CardBody, Heading, Text } from "_sredx/_ui";
import { AchievementDto } from "./types";
import { capitalizeFirstLetter } from "_sredx/utils/string";
import { convertToElement } from "./utils";
import Tooltip from "components/octo-ui/Tootlip/Tooltip";
import clsx from "clsx";

interface AchievementLevelProps {
  achievements: AchievementDto[];
  onClick?: (scoreCardId: string) => void;
  isColored?: boolean;
  displayService?: boolean;
  displayScoreCard?: boolean;
}

export const AchievementItem = ({
  achievements,
  onClick,
  displayService = true,
  isColored = true,
  displayScoreCard = true,
}: AchievementLevelProps) => {
  // Derived variables
  const { levelName, scoreCardName, scoreCardId } = achievements[0];
  const services = achievements.map((achievement) => achievement.serviceName);

  // Temp --------
  const badgesList = ["/Speed/L1.svg", "/Growth/L1.svg", "/SuperStar/L1.svg"];
  const levelImage = "/images/" + convertToElement(scoreCardName, badgesList);
  // --------------

  // Events handlers
  const handleOnClick = () => {
    onClick && onClick(scoreCardId);
  };
  const ConditionalCard = ({ disabled, children }: any) =>
    disabled ? <>{children}</> : <Card clickable={!!onClick}>{children}</Card>;

  return (
    <div className={styles.achievement_wrapper} onClick={handleOnClick}>
      <ConditionalCard disabled={!displayScoreCard}>
        <CardBody>
          <div
            className={clsx(
              styles.body_wrapper,
              !isColored ? styles.disabled : ""
            )}
          >
            <figure className={styles.level_image}>
              <img src={levelImage} alt="level" />
            </figure>
            <div className={styles.info}>
              <Heading level={5}>{capitalizeFirstLetter(levelName)}</Heading>
              {displayScoreCard && <Text size={"sm"}>{scoreCardName}</Text>}
            </div>
            {services.length > 0 && displayService && (
              <div className={styles.services_wrapper}>
                <div className={styles.service}>
                  <Text size={"xs"}>{services[0]}</Text>
                </div>
                {services.length > 1 && <RestServices services={services} />}
              </div>
            )}
          </div>
        </CardBody>
      </ConditionalCard>
    </div>
  );
};

const RestServices = ({ services }: { services: String[] }) => {
  return (
    <Tooltip
      content={
        <ol>
          {services.slice(1).map((service,i) => (
            <li key={i}>- {service}</li>
          ))}
        </ol>
      }
      direction="bottom"
    >
      <div className={styles.service}>
        <Text size={"xs"}>+{services.length - 1}</Text>
      </div>
    </Tooltip>
  );
};
