import { PropsWithChildren } from "react";

interface PlannerBodyGridItemProps {
  row: number;
  startColumn: number;
  endColumn: number;
}

export const PlannerBodyGridItem = ({
  children,
  row,
  startColumn,
  endColumn,
}: PropsWithChildren<PlannerBodyGridItemProps>) => {
  const gridColumn = `${startColumn} / span ${endColumn - startColumn + 1}`;
  const gridRow = row;
  return (
    <div data-testid="item-wrapper" style={{ gridColumn, gridRow }}>
      {children}
    </div>
  );
};
