import {
  AdvancedFilterConfig,
  AdvancedFilterValue,
  FILTER_OPERATORS,
  FILTER_PROPERTY_TYPES,
  FilterOperatorType,
  FilterPropertyType,
  GetOptionsByKeyFn,
} from "../types";
import styles from "./AdvancedFiltersPill.module.css";
import { AdvancedFiltersProperty } from "../AdvancedFiltersProperty";
import { AdvancedFiltersOperator } from "../AdvancedFiltersOperator";
import { AdvancedFiltersValue } from "../AdvancedFiltersValue";
import { getPropertyTypeByKey } from "../utils";
import { CloseIcon } from "_sredx/_ui/Icon";

const getDefaultOperator = (
  type?: FilterPropertyType
): FilterOperatorType | undefined => {
  switch (type) {
    case FILTER_PROPERTY_TYPES.DATE:
      return FILTER_OPERATORS.FROM;
    case FILTER_PROPERTY_TYPES.NUMBER:
      return FILTER_OPERATORS.BETWEEN;
    case FILTER_PROPERTY_TYPES.STRING:
      return FILTER_OPERATORS.CONTAINS;
    case FILTER_PROPERTY_TYPES.ENUM:
      return FILTER_OPERATORS.IN;
    case FILTER_PROPERTY_TYPES.BOOLEAN:
      return FILTER_OPERATORS.IS;
    case FILTER_PROPERTY_TYPES.DURATION:
      return FILTER_OPERATORS.BETWEEN;
    case FILTER_PROPERTY_TYPES.LIST:
      return FILTER_OPERATORS.IN;
  }
};

interface AdvancedFiltersPillProps {
  config: AdvancedFilterConfig[];
  selectedKeys?: AdvancedFilterConfig["key"][];
  onChange: (value: AdvancedFilterValue) => void;
  value: AdvancedFilterValue;
  getOptionsByKey?: GetOptionsByKeyFn;
  deleteButtonVisible?: boolean;
  onDelete: () => void;
}

export const AdvancedFiltersPill = ({
  config,
  value,
  onChange,
  getOptionsByKey,
  selectedKeys = [],
  deleteButtonVisible,
  onDelete,
}: AdvancedFiltersPillProps) => {
  const handlePropertyChange = (key: AdvancedFilterConfig["key"]) => {
    const propertyType = getPropertyTypeByKey(config, key);
    onChange({
      key,
      operator: getDefaultOperator(propertyType),
      value: undefined,
    });
  };

  const handleOperatorChange = (operator: FilterOperatorType) => {
    onChange({
      ...value,
      value: undefined,
      operator,
    });
  };

  const handleValueChange = (_value: any) => {
    onChange({
      ...value,
      value: _value,
    });
  };

  const handleDeleteFilter = () => {
    onDelete();
  };

  return (
    <div className={styles.wrapper}>
      <AdvancedFiltersProperty
        options={config}
        disabledOptions={selectedKeys}
        value={value.key}
        onChange={handlePropertyChange}
      />
      <AdvancedFiltersOperator
        type={getPropertyTypeByKey(config, value.key)}
        value={value.operator}
        onChange={handleOperatorChange}
      />
      <AdvancedFiltersValue
        type={getPropertyTypeByKey(config, value.key)}
        operator={value.operator}
        value={value.value}
        onChange={handleValueChange}
        propertyKey={value?.key}
        getOptionsByKey={getOptionsByKey}
      />
      {deleteButtonVisible && (
        <button className={styles.delete_button} onClick={handleDeleteFilter}>
          <CloseIcon className={styles.close_icon} />
        </button>
      )}
    </div>
  );
};
