import { Cluster } from "_sredx/types";

export const isKubeConfigDownloadEnabled = (cluster: Cluster) => {
  const ageInSeconds = parseToSeconds(cluster.age);
  const minimumAgeSeconds = 90;
  return ageInSeconds >= minimumAgeSeconds;
};

const parseToSeconds = (age: string): number => {
  let minutes = 0,
    seconds = 0;

  const minutesMatch = age.match(/(?:\d{1,2})m/);
  const secondsMatch = age.match(/(?:\d{1,2})s/);

  if (minutesMatch) {
    minutes = parseInt(minutesMatch[0].replace("m", ""));
  }
  if (secondsMatch) {
    seconds = parseInt(secondsMatch[0].replace("s", ""));
  }

  return minutes * 60 + seconds;
};
