import { DeleteServiceModalBody } from 'componentsv2/specific/DeleteServiceModal/DeleteServiceModalBody';
import { useDeleteService } from 'hooks/useDeleteService';
import { useModal } from 'hooks/useModal';
import Service from 'models/service/Service';


export const DeleteServiceModal = () => {
  const { show, hide } = useModal();
  const deleteServiceMutation = useDeleteService();

  const handleDelete = (service: Service) => {
    show({
      body: (
        <DeleteServiceModalBody
          data-testid="delete-service-modal-body"
          service={service}
          hide={hide}
          deleteServiceMutation={deleteServiceMutation}
        />
      )
    });
  };

  return { handleDelete };
};