import {forwardRef} from "react";
import {IconProps} from "./types";

export const ChartIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
          {/*<path  d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM305.8 637.7c3.1 3.1 8.1 3.1 11.3 0l138.3-137.6L583 628.5c3.1 3.1 8.2 3.1 11.3 0l275.4-275.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6a8.03 8.03 0 0 0-11.3 0l-230 229.9L461.4 404a8.03 8.03 0 0 0-11.3 0L266.3 586.7a8.03 8.03 0 0 0 0 11.3l39.5 39.7z"/>*/}
          <path fill={color} fillRule="evenodd" d="M6 4.65A1.35 1.35 0 0 0 4.65 6v12c0 .745.604 1.35 1.35 1.35h12A1.35 1.35 0 0 0 19.35 18V6A1.35 1.35 0 0 0 18 4.65H6ZM3.35 6A2.65 2.65 0 0 1 6 3.35h12A2.65 2.65 0 0 1 20.65 6v12A2.65 2.65 0 0 1 18 20.65H6A2.65 2.65 0 0 1 3.35 18V6ZM12 7.35a.65.65 0 0 1 .65.65v9a.65.65 0 1 1-1.3 0V8a.65.65 0 0 1 .65-.65Zm-4 3a.65.65 0 0 1 .65.65v6a.65.65 0 1 1-1.3 0v-6a.65.65 0 0 1 .65-.65Zm8 3a.65.65 0 0 1 .65.65v3a.65.65 0 1 1-1.3 0v-3a.65.65 0 0 1 .65-.65Z" clipRule="evenodd"/>
      </svg>
    );
  }
);
