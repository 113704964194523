import { forwardRef } from "react";
import { IconProps } from "./types";

export const KeycloakIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 44.216 39.861"
        {...props}
        ref={ref}
      >
        <title>Keycloak</title>
        <path
          d="m88.61 138.456 5.716-9.865 23.018-.004 5.686 9.965.007 19.932-5.691 9.957-23.012.008-5.782-9.965z"
          style={{
            display: "inline",
            fill: "#4d4d4d",
            fillOpacity: 1,
            strokeWidth: 0.264583,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="M88.552 158.481h10.375l-5.699-10.041 4.634-9.982-9.252-.002-5.795 10.065"
          style={{
            fill: "#ededed",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="M102.073 158.481h7.582l6.706-9.773-6.589-10.156h-8.921l-5.373 9.814z"
          style={{
            fill: "#e0e0e0",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="m82.815 148.52 5.738 9.964h10.374l-5.636-9.93z"
          style={{
            fill: "#acacac",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="m95.589 148.522 6.484 9.963h7.582l6.601-9.959z"
          style={{
            fill: "#9e9e9e",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="m98.157 148.529-1.958.569-1.877-.572 7.667-13.288 1.918 3.316"
          style={{
            fill: "#00b8e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="m103.9 158.482-1.909 3.332-5.093-5.487-2.58-7.797v-.004h3.838"
          style={{
            fill: "#33c6e9",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="M94.322 148.526h-.003v.003l-1.918 3.322-1.925-3.307 1.952-3.386 5.728-9.92h3.834"
          style={{
            fill: "#008aaa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="M115.42 158.481h11.611l-.007-19.93h-11.605z"
          style={{
            fill: "#d4d4d4",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="M115.42 148.554v9.93h11.59v-9.93z"
          style={{
            fill: "#919191",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="M101.992 161.817h-3.836l-5.755-9.966 1.918-3.321z"
          style={{
            fill: "#00b8e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="m117.333 148.526-7.669 13.289c-.705-1.036-1.913-3.331-1.913-3.331l5.753-9.959z"
          style={{
            fill: "#008aaa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="m113.495 161.815-3.831-.001 7.67-13.288 1.917-3.317 1.921 3.34m-3.839-.023h-3.828l-5.755-9.973 1.905-3.314 4.658 5.922z"
          style={{
            fill: "#00b8e3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
        <path
          d="M119.25 145.205v.003l-1.917 3.318-7.677-13.286 3.841.002z"
          style={{
            fill: "#33c6e9",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.330729,
          }}
          transform="translate(-82.815 -128.588)"
        />
      </svg>
    );
  }
);
