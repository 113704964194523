import React, { forwardRef } from "react";
import clsx from "clsx";
import styles from "./TableHeader.module.css";

export const TableHeader = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, children, ...props }, ref) => {
  return (
    <thead
      ref={ref}
      className={clsx("nemo-table-header", styles.wrapper, className)}
      {...props}
    >
      <tr className={clsx(styles.tr)}>{children}</tr>
    </thead>
  );
});
