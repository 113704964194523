import { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./Tabs.module.css";

export const Tabs = ({ children }: PropsWithChildren) => {
  return (
    <nav>
      <ul className={clsx("nemo-tabs-list", styles.tab_list)}>{children}</ul>
    </nav>
  );
};
