import { PullRequestDto, RepoChangesStats } from "_sredx/types";
import { PR_COLORS } from "../PRCycleTimeWidget/utils";

export const groupPRsByRepo = (
  prs: PullRequestDto[]
): Record<string, PullRequestDto[]> => {
  // TODO: Sort from backend
  const prsCopy = prs.slice();
  prsCopy.sort((a, b) => {
    return new Date(a.mergedAt).getTime() - new Date(b.mergedAt).getTime();
  });
  return prsCopy.reduce((acc, pr) => {
    const repoName = pr.repository;
    if (!acc[repoName]) {
      acc[repoName] = [];
    }
    acc[repoName].push(pr);
    return acc;
  }, {} as Record<string, PullRequestDto[]>);
};

export const groupPullRequestsByDate = (
  pullRequests: PullRequestDto[]
): RepoChangesStats[] => {
  const aggregatedData: { [key: string]: RepoChangesStats } = {};

  pullRequests.forEach((pr) => {
    const date = pr.mergedAt.split("T")[0];
    const key = `${pr.repository}_${date}`;

    if (!aggregatedData[key]) {
      aggregatedData[key] = {
        repository: pr.repository,
        nbrLineAdded: 0,
        nbrLineDeleted: 0,
        date: date,
      };
    }

    aggregatedData[key].nbrLineAdded += pr.nbrLineAdded;
    aggregatedData[key].nbrLineDeleted += pr.nbrLineDeleted;
  });

  return Object.values(aggregatedData);
};

export const getLineColorsByRepo = (prs: PullRequestDto[]) => {
  const colors = new Map<string, string>();
  let i = 0;
  prs.forEach((pr) => {
    if (!colors.has(pr.repository)) {
      colors.set(pr.repository, PR_COLORS[i % PR_COLORS.length]);
      i++;
    }
  });
  return colors;
};
