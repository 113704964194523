import { Button, ButtonLabel, Card, Heading } from "_sredx/_ui";
import React from "react";
import styles from "./OnboardingProviderConfirmationModal.module.css";

interface OnboardingProviderConfirmationModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

export const OnboardingProviderConfirmationModal = ({
  onClose,
  onConfirm,
}: OnboardingProviderConfirmationModalProps) => {
  const handleConfirm = () => {
    onClose();
    onConfirm();
  };
  return (
    <Card>
      <div className={styles.wrapper}>
        <Heading level={4}> Confirm your providers choice </Heading>
        <hr />
        <p>
          Some of the providers you configured did not pass their connectivity
          test. This means the import of services from failing providers will
          fail. Therefore, the services will be imported only from providers
          that passed the test.
        </p>

        <span>Are you sure you want to proceed ?</span>

        <div className={styles.buttons_wrapper}>
          <Button variant="neutral" onClick={onClose}>
            <ButtonLabel>Cancel </ButtonLabel>
          </Button>

          <Button variant="danger" onClick={handleConfirm}>
            <ButtonLabel>Confirm </ButtonLabel>
          </Button>
        </div>
      </div>
    </Card>
  );
};
