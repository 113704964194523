import React from 'react';
import style from "./CallForAction.module.css";
import clsx from 'clsx';



interface CallForActionProps {
    onGetStarted: () => void;
}

const CallForAction = ({onGetStarted}: CallForActionProps)  => {
  return (
            <div
              className={style.wrapper}
            >
                <p className={style.title}>
                  Ready to unleash engineering productivity?
                  <br />
                  Join the SREDX revolution today!
                </p>

              <div>
                <button onClick={onGetStarted} className={clsx("btn", "btn_primary")}>
                  Get started
                </button>
              </div>
            </div>
  )
}

export default CallForAction;