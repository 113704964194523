export interface AdminOnboardingStep {
  onNextStep?: () => void;
  onPreviousStep?: () => void;
}

export const OrderedAdminOnboardingSteps : OrderedStep[] = [
  {
    step: 1,
    key: "PROVIDERS_CONNFIGURATION_STEP",
    label: "Which tool does your organization utilize ?",
  },
  {
    step: 2,
    key: "SERVICES_IMPORT_STEP",
    label: "Which services are you interested in importing ?",
  },
  {
    step: 3,
    key: "TEAMS_IMPORT_STEP",
    label:
      "Would you like to import and send invitations to members of your organization ?",
  },
  {
    step: 4,
    key: "USERS_IMPORT_STEP",
    label: "which members are you interested in importing ?",
  },
  
];

export interface OrderedStep {
  step: number,
  key: string,
  label: string
}


