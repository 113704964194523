import { Service, ServicesListAction } from "../types";
import {
  Button,
  ButtonLabel,
  List,
  ListItem,
  ListTitle,
  Text,
} from "_sredx/_ui";
import { ServiceRow } from "../ServiceRow";
import styles from "./ServicesList.module.css";

interface ServicesListProps {
  services?: Service[];
  onAction: (action: ServicesListAction) => void;
  emptyMessage?: string;
  withAddAction?: boolean;
}

export const ServicesList = ({
  services = [],
  onAction,
  emptyMessage = "No services",
  withAddAction = false,
}: ServicesListProps) => {
  const handleOnAddService = () => {
    onAction({
      type: "CLICK_ADD_SERVICES",
    });
  };

  if (services.length === 0) {
    return (
      <div className={styles.empty_wrapper}>
        <Text className={styles.empty_content}>{emptyMessage}</Text>
        {withAddAction && (
          <Button onClick={handleOnAddService} variant="neutral">
            <ButtonLabel>Add Services</ButtonLabel>
          </Button>
        )}
      </div>
    );
  }
  return (
    <>
      <List>
        <ListTitle>Services</ListTitle>
        {services.map((service) => (
          <ListItem key={service.id}>
            <ServiceRow service={service} onAction={onAction} />
          </ListItem>
        ))}
      </List>
      {withAddAction && (
        <div className={styles.action_wrapper}>
          <Button onClick={handleOnAddService} variant="neutral">
            <ButtonLabel>Add Services</ButtonLabel>
          </Button>
        </div>
      )}
    </>
  );
};
