import { TrashIcon } from "@heroicons/react/solid";
import React, { FC, useState } from "react";
import Level from "../../../../models/scorecard/Level";
import Rule from "../../../../models/scorecard/Rule";
import EditLevelFormComponent from "../EditLevelForm/EditLevelFormComponent";
import { useSortable } from "@dnd-kit/sortable";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { v4 as uuidv4 } from 'uuid';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useFormContext } from "react-hook-form";
import { FaGripVertical } from "react-icons/fa";

interface ScorecardComponentProps {
  rules: Rule[];
  levels: Level[];

  updateLevelDetailsCallBack(level: Level): void;

  creatNewLevelCallBack(level: Level): void;

  deleteLevelByIdCallBack(levelId: string): void;
}

const ListOfScoreCardLevels: FC<ScorecardComponentProps> = ({
  levels,
  rules,
  updateLevelDetailsCallBack,
  creatNewLevelCallBack,
  deleteLevelByIdCallBack,
}) => {
  const { setValue } = useFormContext();
  const [editedLevel, setEditedLevel] = useState<Level | undefined>(undefined);
  const [isCreatingNewLevel, setIsCreatingNewLevel] = useState<boolean>(false);
  const [selectedRulesIds, setSelectedRulesIds] = useState<string[]>(
    levels.flatMap((level) => level.ruleIds ?? [])
  );
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const levelsArr = (items: Level[]) => {
      const oldIndex = items.findIndex((level) => level.id === active?.id);
      const newIndex = items.findIndex((level) => level.id === over?.id);
      return arrayMove(items, oldIndex, newIndex);
    };
    if (active.id !== over?.id) {
      setValue("levels", levelsArr(levels));
    }
  };

  const closeEditLevelForm = () => {
    setIsCreatingNewLevel(false);
    setEditedLevel(undefined);
  };
  const handleOnDeleteLevel = (
    e: React.MouseEvent<HTMLButtonElement>,
    level: Level
  ) => {
    e.preventDefault();
    deleteLevelByIdCallBack(level.id!);
    e.stopPropagation();
  };

  return (
    <div id="rulesList" className="space-y-8 bg-white px-5 py-5 font-sans">
      <h3 className="text-2xl leading-6 font-medium text-gray-900">Levels</h3>
      {levels.length === 0 && !isCreatingNewLevel && !editedLevel && (
        <p className={"text-center text-2xl"}> No levels for the moment. </p>
      )}
      <div className={"grid md:grid-cols-2 md:space-x-4"}>
        <div className={`md:col-span-1`}>
          <div id={"rules-by-category-list"} className="bg-white">
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={levels.map((level) => level.id ?? "")}
                strategy={verticalListSortingStrategy}
              >
                {levels.map((level, index) => (
                  <LevelAccordion
                    key={level.id}
                    rules={rules}
                    onDelete={(e) => handleOnDeleteLevel(e, level)}
                    level={level}
                    onClick={() => {
                      setIsCreatingNewLevel(false);
                      setEditedLevel(level);
                    }}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>
        </div>
        <div
          id="edit-rule-form-container"
          className={`p-4 ${
            editedLevel !== undefined ? "md:col-span-1" : "hidden"
          }`}
        >
          {editedLevel && (
            <EditLevelFormComponent
              rules={rules}
              originalLevel={editedLevel}
              isNewLevel={isCreatingNewLevel}
              closeEditLevelComponentCallBack={closeEditLevelForm}
              selectedRulesIds={selectedRulesIds}
              setSelectedRulesIds={setSelectedRulesIds}
              updateLevelDetailsCallBack={(level: Level) => {
                updateLevelDetailsCallBack(level);
                setEditedLevel(undefined);
              }}
              createNewLevelCallBack={(level: Level) => {
                setIsCreatingNewLevel(false);
                creatNewLevelCallBack(level);
                setEditedLevel(undefined);
              }}
            />
          )}
        </div>
      </div>
      <div
        id="add-new-level-button"
        className="flex justify-center"
        onClick={() => {
          const newLevel: Level = {id:uuidv4()};
          setIsCreatingNewLevel(true);
          setEditedLevel(newLevel);
        }}
      >
        {!isCreatingNewLevel && !editedLevel && (
          <button
            type="button"
            className="inline-flex items-center  my-5 px-8 py-2  text-lg  text-white bg-green-500 hover:opacity-50 "
          >
            Add new level
          </button>
        )}
      </div>
    </div>
  );
};

export default ListOfScoreCardLevels;

const LevelAccordion = ({
  level,
  rules,
  onClick,
  onDelete,
}: {
  level: Level;
  rules: Rule[];
  onClick: () => void;
  onDelete: (e: any) => void;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleLevelAccordion = () => {
    setExpanded(!expanded);
  };
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: level.id ?? "" });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      key={level.id}
      style={style}
      className={` hover:bg-cyan-50 transition flex`}
      {...attributes}
      {...listeners}
      onClick={(e) => {
        e.preventDefault();
        toggleLevelAccordion();
        ////****** here for editing the level */
        e.stopPropagation();
        onClick();
      }}
      id={`accordion-collapse-${level.id}`}
    >
      <div className="px-4 self-stretch flex items-center text-gray-400 cursor-move">
        <FaGripVertical />
      </div>
      <div className="flex flex-col grow">
        <div className="flex justify-between">
          <div className="flex items-start py-5 pr-5 w-full font-medium text-left text-black capitalize rounded-t-xl ">
            <ChevronRightIcon
              className={`h-5 w-5 mr-2 transition ${
                expanded ? "rotate-90" : ""
              }`}
            />
            <div className="flex-col justify-between items-center pl-1 text-sm">
              <div className={"flex justify-items-start gap-3"}>
                <p>{level.name}</p>
                <span className="inline-flex items-center  px-2 py-0.5 rounded-md text-xs font-medium bg-black text-white">
                  {level.ruleIds?.length} rules
                </span>
              </div>

              <p className={"text-gray-500"}>{level.description}</p>
            </div>
          </div>
          <div
            className={
              "flex justify-end rounded  m-4 w-1/4 h-1/2  overflow-hidden"
            }
          >
            <button
              type={"button"}
              onClick={onDelete}
              id={"delete-button"}
              data-testid={"delete-button"}
            >
              <TrashIcon width={20} />
            </button>
          </div>
        </div>

        <div
          id={`${level.id}-rules-list`}
          className={`${!expanded ? "hidden" : ""}`}
        >
          <ul className="space-y-4 ">
            <div className="flex-col px-5">
              <p className="py-2 mx-4 px-5 ">Rules</p>

              {level.ruleIds?.map((ruleId, index) => (
                <li className="m-auto sm:mx-6 " key={ruleId}>
                  <div className=" px-4 sm:px-6 gap-4 ">
                    <div
                      id={`${ruleId}-list-item-${index}`}
                      className="w-3/4 flex-1 flex  flex-col"
                    >
                      <div className={"flex gap-5 items-center "}>
                        <p className="text-sm font-medium truncate">
                          {rules.find((rule) => rule.id === ruleId)?.name}
                        </p>
                      </div>
                      <div className={"flex gap-5 items-center "}>
                        <p className="truncate mb-2 text-sm text-gray-500">
                          {
                            rules.find((rule) => rule.id === ruleId)
                              ?.description
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
