import { RuleDto } from "_sredx/types";
import { InitiativeCreationRulesListAction } from "./types";
import styles from "./InitiativeCreationRulesList.module.css";
import { Button, ButtonIcon } from "_sredx/_ui";
import { TrashIcon } from "_sredx/_ui/Icon";

interface RuleRowProps {
  rule: RuleDto;
  onAction: (action: InitiativeCreationRulesListAction) => void;
}

export const RuleRow = ({ rule, onAction }: RuleRowProps) => {
  const handleOnRemoveRule = () => {
    onAction({
      type: "DELETE_RULE",
      payload: {
        rule,
      },
    });
  };

  return (
    <div className={styles.row_wrapper}>
      <div className={styles.row_cell}>{rule.ruleName}</div>
      <div className={styles.row_cell}>{rule.scoreCardName}</div>
      <Button onClick={handleOnRemoveRule} variant={"neutral"} ghost>
        <ButtonIcon>
          <TrashIcon />
        </ButtonIcon>
      </Button>
    </div>
  );
};
