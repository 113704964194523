import { Button, ButtonLabel } from "_sredx/_ui";
import {
  RoadmapCreationInfoForm,
  RoadmapCreationInitiatives,
  RoadmapCreationInitiativesActions,
  RoadmapInfoFormData,
} from "_sredx/components";
import { useModal } from "_sredx/hooks";
import {
  useAddRoadmapInitiatives,
  useRoadmapCreation,
  useUpdateInitiatives,
} from "_sredx/services";
import { InitiativeBaseDto } from "_sredx/types";
import { AxiosError } from "axios";
import clsx from "clsx";
import { useToaster } from "hooks/useToaster";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { RoadmapInitiativesAssignmentContainer } from "../RoadmapInitiativesAssignmentContainer";

import styles from "./RoadmapCreationContainer.module.css";
import { RoadmapCreationFormData } from "./types";
import {
  buildInitiativesUpdateDtoList,
  isValidRoadmapCreationFormData,
  mapRoadmapCreationFormDataTORoadmapCreationDto,
  roadmapCreationReducer,
} from "./utils";

const ROADMAP_CREATION_DEFAULT_FORM_DATA: RoadmapCreationFormData = {
  generalInfo: {
    name: "",
    description: "",
    year: 2024,
  },
  initiatives: [],
};

export const RoadmapCreationContainer = () => {
  //state
  const navigate = useNavigate();
  const { addToast } = useToaster();
  const { openModal, closeModal } = useModal();
  const [roadmapCreationData, dispatch] = useReducer(
    roadmapCreationReducer,
    ROADMAP_CREATION_DEFAULT_FORM_DATA
  );
  //query
  const { mutateAsync: createRoadmap, isLoading: isLoadingRoadmapCreation } =
    useRoadmapCreation();
  const {
    mutateAsync: addRoadmapInitiatives,
    isLoading: isLoadingRoadmapInitiatives,
  } = useAddRoadmapInitiatives();

  const { mutateAsync: updateInitiativesRoadmap } = useUpdateInitiatives();

  const isLoading = isLoadingRoadmapCreation || isLoadingRoadmapInitiatives;

  //handlers
  const handleOnRoadmapInfoChange = (generalInfo: RoadmapInfoFormData) => {
    dispatch({
      type: "SET_GENERAL_INFO",
      payload: {
        generalInfo,
      },
    });
  };

  const handleRoadmapsInitiativesAction = (
    action: RoadmapCreationInitiativesActions
  ) => {
    switch (action.type) {
      case "CLICK_ADD_INITIATIVE":
        handleOnAddInitiative();
        return;
      case "DELETE_INITIATIVE":
        dispatch({
          type: "DELETE_INITIATIVE",
          payload: {
            initiative: action.payload.initiative,
          },
        });
        return;
      default:
        return;
    }
  };
  const handleOnCancelAddInitiatives = () => {
    closeModal();
  };
  const HandleOnSubmitAddInitiatives = (initiatives: InitiativeBaseDto[]) => {
    dispatch({
      type: "SET_INITIATIVES",
      payload: {
        initiatives,
      },
    });
    closeModal();
  };
  const handleOnAddInitiative = () => {
    openModal({
      content: (
        <div className={styles.modal_initiative_wrapper}>
          <RoadmapInitiativesAssignmentContainer
            onCancel={handleOnCancelAddInitiatives}
            onConfirm={HandleOnSubmitAddInitiatives}
            selectedInitiatives={roadmapCreationData.initiatives}
          />
        </div>
      ),
    });
  };
  const handleCreateRoadmap = async () => {
    if (!isValidRoadmapCreationFormData(roadmapCreationData)) return;

    try {
      const roadmapId = await createRoadmap(
        mapRoadmapCreationFormDataTORoadmapCreationDto(roadmapCreationData)
      );
      await addRoadmapInitiatives({
        roadmapId,
        initiativesId: roadmapCreationData.initiatives.map(({ id }) => id),
      });

      await updateInitiativesRoadmap(
        buildInitiativesUpdateDtoList(
          roadmapCreationData.initiatives,
          roadmapId
        )
      );

      navigate("/roadmaps");
    } catch (error) {
      if (error instanceof AxiosError)
        addToast({
          type: "error",
          message: error.response?.data.message,
        });
    }
  };
  const handleCancel = () => {
    navigate("/roadmaps");
  };
  //effect

  return (
    <div className={clsx(styles.wrapper)}>
      <RoadmapCreationInfoForm
        onChange={handleOnRoadmapInfoChange}
        defaultValues={ROADMAP_CREATION_DEFAULT_FORM_DATA.generalInfo}
      />
      <RoadmapCreationInitiatives
        initiatives={roadmapCreationData.initiatives}
        onAction={handleRoadmapsInitiativesAction}
      />
      <div className={styles.action_buttons}>
        <Button variant={"neutral"} ghost onClick={handleCancel}>
          <ButtonLabel>cancel</ButtonLabel>
        </Button>
        <Button
          onClick={handleCreateRoadmap}
          isLoading={isLoading}
          disabled={!isValidRoadmapCreationFormData(roadmapCreationData)}
        >
          <ButtonLabel>create</ButtonLabel>
        </Button>
      </div>
    </div>
  );
};
