import { TableHead, TableHeader } from "_sredx/_ui";

export const InitiativeDetailsServicesHeader = () => {
  return (
    <TableHeader>
      <TableHead>service Name</TableHead>
      <TableHead>status</TableHead>
      <TableHead>rules</TableHead>
      <TableHead>progress</TableHead>
      <TableHead></TableHead>
    </TableHeader>
  );
};
