import clsx from "clsx";
import styles from "./MembersSelectAutoComplete.module.css";
import { Avatar, List, ListItem, Loader, Text } from "_sredx/_ui";
import { Member } from "_sredx/types";
import { UseComboboxPropGetters } from "downshift";
import { isNull } from "_sredx/utils";
import { ReactNode } from "react";
import { useInView } from "react-intersection-observer";

interface MemberOptionsListProps {
  memberOptions: Member[];
  selectedMember: Member | null;
  isOpen: boolean;
  highlightedIndex: number;
  getItemProps: UseComboboxPropGetters<Member>["getItemProps"];
  getMenuProps: UseComboboxPropGetters<Member>["getMenuProps"];
  isLoading?: boolean;
  isFetching?: boolean;
  loadMore?: () => void;
  loadingCaption?: ReactNode;
}

export const MemberOptionsList = ({
  memberOptions,
  getMenuProps,
  isOpen,
  getItemProps,
  highlightedIndex,
  selectedMember,
  isLoading,
  isFetching,
  loadMore,
}: MemberOptionsListProps) => {
  // Hooks
  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (inView && loadMore) {
        loadMore();
      }
    },
  });

  return (
    <div className={styles.options_list_wrapper}>
      <div
        className={clsx(
          styles.options_list,
          !(isOpen && memberOptions.length) && styles.hidden
        )}
      >
        <List {...getMenuProps()}>
          {isOpen &&
            memberOptions.map((member, index) => {
              const fullName = !isNull(member.firstName)
                ? `${member.firstName} ${member.lastName}`
                : undefined;
              return (
                <ListItem
                  key={`${member.id}${index}`}
                  {...getItemProps({ item: member, index })}
                >
                  <div
                    className={clsx(
                      styles.list_item,
                      highlightedIndex === index && styles.highlighted,
                      selectedMember === member && styles.selected
                    )}
                  >
                    <Avatar name={fullName ?? member.email} size={26} />
                    <div className={styles.member_info}>
                      <Text size="sm">{fullName ?? member.email}</Text>
                    </div>
                  </div>
                </ListItem>
              );
            })}
          {isFetching && (
            <div className={styles.fetching_wrapper}>
              <Loader size={"small"} />
            </div>
          )}
          <div ref={ref}></div>
        </List>
      </div>
    </div>
  );
};
