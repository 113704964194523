import {Avatar, Text} from "_sredx/_ui";
import {TeamMember} from "_sredx/types";
import styles from "./TeamDataTableRowMember.module.css";

interface TeamDataTableRowMembersProps {
  members?: Array<TeamMember>;
}

export const TeamDataTableRowMembers = ({
  members = [],
}: TeamDataTableRowMembersProps) => {
  if (members.length === 0) {
    return <Text>no members</Text>;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.avatar_container}>
        {members.map(({ firstName, lastName, userId, email }) => (
          <Avatar
            key={userId}
            name={
              firstName && lastName ? `${firstName} ${lastName}` : email || ''
            }
          />
        ))}
      </div>
      <Text>{members.length} members</Text>
    </div>
  );
};
