import React from "react";
import { generateImprovedOwnersRanks } from "../../../utils/generateDropedImprovedOwnersRanks";
import DataTable from "./DataTable";
import OwnerServicesScores from "../../../models/dashborad/OwnerServicesScores";
import NoDataMessage from "./NoDataMessage";

type MostImprovedServicesProps = {
  dataOwnersServices: OwnerServicesScores[];
};

const ImprovedTeamsDashboard: React.FC<MostImprovedServicesProps> = ({
  dataOwnersServices,
}) => {
  const onwersRank = generateImprovedOwnersRanks(dataOwnersServices)
    .sort((a, b) => b.changePercentage - a.changePercentage)
    .slice(0, 6);

  const headerColumns = ["Team", "Total Services", "Growth Rate"];
  const dataRows = onwersRank.map((data) => [
    data.owner,
    data.totalServices,
    <span
      className={`inline-block rounded-full px-4 text-sm font-semibold ${
        data.changePercentage >= 0 ? "bg-green-200 text-green-700" : "bg-red-200 text-red-700"
      }`}
      key={data.owner}
    >
      {data.changePercentage}%
    </span>,
  ]);

  return (
    <div data-testid="improved-teams-dashboard" className="flex flex-col justify-center items-center">
      {onwersRank.length > 0 ? (
        <DataTable   headerColumns={headerColumns} dataRows={dataRows} />
      ) : (
        <div className="flex justify-center items-center mt-20">
        <NoDataMessage />
      </div>
      )}
    </div>
  );
};

export default ImprovedTeamsDashboard;
