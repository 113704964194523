import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useInfiniteGetServices, useInfiniteGetTeams } from "_sredx/services";
import { Input } from "_sredx/_ui";
import { InfiniteScrollList } from "_sredx/components";
import { ServiceBaseDto, TeamBaseDto } from "_sredx/types";
import styles from "./ServiceOrTeamSelectorContainer.module.css";

interface ServiceOrTeamSelectorContainerProps {
  onChange?: (
    selected: ServiceBaseDto | TeamBaseDto,
    type: "SERVICE" | "TEAM"
  ) => void;
}

export const ServiceOrTeamSelectorContainer = ({
  onChange,
}: ServiceOrTeamSelectorContainerProps) => {
  // State
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [selectedTab, setSelectedTab] = useState<"Services" | "Teams">(
    "Services"
  );

  const [selectedValue, setSelectedValue] = useState<
    ServiceBaseDto | TeamBaseDto
  >();

  useEffect(() => {
    if (selectedValue)
      onChange?.(
        selectedValue,
        selectedTab === "Services" ? "SERVICE" : "TEAM"
      );
  }, [selectedValue]);

  // Services
  const {
    data: infiniteServices,
    hasNextPage: hasNextPageServices,
    fetchNextPage: fetchNextPageServices,
    isLoading: isLoadingServices,
    isFetching: isFetchingServices,
  } = useInfiniteGetServices({
    params: {
      name: searchQuery,
    },
  });

  // Teams
  const {
    data: infiniteTeams,
    hasNextPage: hasNextPageTeams,
    fetchNextPage: fetchNextPageTeams,
    isLoading: isLoadingTeams,
    isFetching: isFetchingTeams,
  } = useInfiniteGetTeams({
    params: {
      name: searchQuery,
    },
  });

  // Derived data
  const services = useMemo(() => {
    if (
      !infiniteServices ||
      !infiniteServices.pages ||
      !Array.isArray(infiniteServices.pages) ||
      infiniteServices.pages.length === 0
    ) {
      return [];
    }
    const { pages } = infiniteServices;
    return pages.reduce((result: ServiceBaseDto[], page) => {
      if (page.content && Array.isArray(page.content)) {
        result.push(...page.content);
      }

      return result;
    }, []);
  }, [infiniteServices]);

  const teams = useMemo(() => {
    if (
      !infiniteTeams ||
      !infiniteTeams.pages ||
      !Array.isArray(infiniteTeams.pages) ||
      infiniteTeams.pages.length === 0
    ) {
      return [];
    }
    const { pages } = infiniteTeams;
    return pages.reduce((result: TeamBaseDto[], page) => {
      if (page.content && Array.isArray(page.content)) {
        result.push(...page.content);
      }

      return result;
    }, []);
  }, [infiniteTeams]);

  useEffect(() => {
    if (!services.length) return;
    setSelectedValue(services[0]);
  }, [isLoadingServices, isLoadingTeams]);

  // Event handlers
  const handleOnSearch = (value: string) => {
    // [TODO] add debounce
    setSearchQuery(value);
  };

  const handleOnClear = () => {
    setSelectedValue(undefined);
    // onChange?.([]);
  };

  const handleOnLoadMoreServices = async () => {
    if (hasNextPageServices) {
      await fetchNextPageServices();
    }
  };

  const handleOnLoadMoreTeams = async () => {
    if (hasNextPageTeams) {
      await fetchNextPageTeams();
    }
  };

  const handleSelectTab = (tab: "Services" | "Teams") => () => {
    // setSelectedTab((prev) => {
    //   if (services.length > 0 && prev !== "Services") {
    //     setSelectedValue(services[0]);
    //   }
    //   return "Services";
    // });

    setSelectedTab((prev) => {
      if (tab === "Services" && services.length > 0 && prev !== "Services") {
        setSelectedValue(services[0]);
      }
      if (tab === "Teams" && teams.length > 0 && prev !== "Teams") {
        setSelectedValue(teams[0]);
      }
      return tab;
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <button
          className={clsx(
            styles.tab,
            selectedTab === "Services" && styles.selected_tab
          )}
          onClick={handleSelectTab("Services")}
        >
          Services
        </button>
        <button
          className={clsx(
            styles.tab,
            selectedTab === "Teams" && styles.selected_tab
          )}
          onClick={handleSelectTab("Teams")}
        >
          Teams
        </button>
      </div>

      <Input
        type="text"
        className={styles.search_input}
        placeholder={`Search ${selectedTab}`}
        onChange={(e) => handleOnSearch(e.target.value)}
      />

      <InfiniteScrollList
        items={selectedTab === "Services" ? services : teams}
        onItemClick={(item) => {
          setSelectedValue(item);
        }}
        selectedValue={{
          ...selectedValue,
          type: selectedTab === "Services" ? "Service" : "Team",
        }}
        loadingCaption={"Loading..."}
        isLoading={
          selectedTab === "Services" ? isLoadingServices : isLoadingTeams
        }
        isFetching={
          selectedTab === "Services" ? isFetchingServices : isFetchingTeams
        }
        loadMore={
          selectedTab === "Services"
            ? handleOnLoadMoreServices
            : handleOnLoadMoreTeams
        }
      />
    </div>
  );
};
