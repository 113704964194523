import { GithubIcon, BackstageIcon, GitlabIcon } from "_sredx/_ui/Icon";
import { ServiceProvider } from "types/discovery";

export const EXTERNAL_PROVIDERS_KEYS = {
  GITHUB: "github",
  GITLAB: "gitlab",
  BITBUCKET: "bitbucket",
  BACKSTAGE: "backstage",
};

export type ExternalProviderKeyType =
  (typeof EXTERNAL_PROVIDERS_KEYS)[keyof typeof EXTERNAL_PROVIDERS_KEYS];

export const EXTERNAL_PROVIDERS: ServiceProvider[] = [
  {
    id: 1,
    name: "Github",
    iconComponent: GithubIcon,
    configured: false,
    supported: true,
    key: EXTERNAL_PROVIDERS_KEYS.GITHUB,
    discoveryKey: "github",
  },
  {
    id: 2,
    name: "Gitlab",
    iconComponent: GitlabIcon,
    configured: false,
    supported: true,
    key: EXTERNAL_PROVIDERS_KEYS.GITLAB,
    discoveryKey: "gitlab",
  },
  {
    id: 3,
    name: "Backstage",
    iconComponent: BackstageIcon,
    configured: false,
    supported: true,
    key: EXTERNAL_PROVIDERS_KEYS.BACKSTAGE,
    discoveryKey: "backstage",
  },
];
