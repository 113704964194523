import { EvaluationLevelsResultDto, Level } from "_sredx/types";
import styles from "./ScorecardLevels.module.css";
import { NextLevel } from "../NextLevelActions/NextLevel";
import { Heading } from "_sredx/_ui";
import clsx from "clsx";
import { AchievementItem } from "../Achievements/AchievementItem";

interface ScorecardLevelsProps {
  possibleLevels: Level[];
  evaluationLevels?: EvaluationLevelsResultDto;
}

export const ScorecardLevels = ({
  possibleLevels,
  evaluationLevels,
}: ScorecardLevelsProps) => {

  const achievedLevelIndex = possibleLevels.findIndex(
    (level) => level.id === evaluationLevels?.currentLevel?.levelId
  );
  const displayedLevels = possibleLevels.map((level, index) => {
    if (index <= achievedLevelIndex) {
      return { ...level, isAchieved: true };
    } else {
      return { ...level, isAchieved: false };
    }
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {evaluationLevels?.currentLevel?.levelName ? (
          <Heading level={4}>
            You are currently in{" "}
            <span className={styles.current_level_heading}>
              {evaluationLevels?.currentLevel?.levelName}
            </span>
          </Heading>
        ) : (
          <Heading level={4}>You have not achieved any level yet</Heading>
        )}
      </div>
      <div className={styles.levels_wrapper}>
        {displayedLevels.map((level, index) => {
          return (
            <>
              {index !== 0 && (
                <div
                  className={clsx(
                    styles.level_bridge,
                    level.isAchieved ? styles.level_achieved : ""
                  )}
                ></div>
              )}
              <AchievementItem
                achievements={[
                  {
                    levelName: level?.name ?? "",
                    levelId: level?.id ?? "",
                    scoreCardName:
                      evaluationLevels?.scorecard.scoreCardName ?? "",
                    scoreCardId: evaluationLevels?.scorecard.scoreCardId ?? "",
                    serviceName: evaluationLevels?.service.serviceName ?? "",
                  },
                ]}
                displayService={false}
                displayScoreCard={false}
                isColored={level.isAchieved}
              />
            </>
          );
        })}
      </div>
      <br />
      {evaluationLevels && <NextLevel evaluation={evaluationLevels} />}
    </div>
  );
};
