import clsx from "clsx";
import styles from "./StatusBarLegends.module.css";
import React from "react";
import { Status } from "../types";

interface StatusBarLegendsProps {
  statuses: Status[];
}

export const StatusBarLegends = ({ statuses }: StatusBarLegendsProps) => {
  return (
    <div className={clsx("nemo-status-bar-legends", styles.wrapper)}>
      {statuses.map((status, index) => {
        return (
          <div className={styles.legend} key={index}>
            <div className={clsx(styles.dot, status.color)} />
            <span>
              {status.label} : {status.value}
            </span>
          </div>
        );
      })}
    </div>
  );
};
