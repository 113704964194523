import { PropsWithChildren } from "react";
import styles from "./ActionsWrapper.module.css";

interface ActionsWrapperProps {
  onDiscard: () => void;
  onSubmit?: () => void;
  isSaveDisabled: boolean;
}

export const ActionsWrapper = ({
  children,
  onDiscard,
  isSaveDisabled,
  onSubmit,
}: PropsWithChildren<ActionsWrapperProps>) => {
  const handleDiscard = () => onDiscard();
  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };
  return (
    <div className={"flex flex-col justify-between bg-white"}>
      <div className={"font-sans"}>{children}</div>
      <div className={styles.Actions}>
        <button className={styles.Action_Discard} onClick={handleDiscard}>
          Reset
        </button>
        <button
          type={"submit"}
          onClick={handleOnSubmit}
          className={`${styles.Action_Save} ${
            isSaveDisabled ? styles.Action_Save_disabled : ""
          }`}
        >
          Save
        </button>
      </div>
    </div>
  );
};
