import clsx from "clsx";
import { PropsWithChildren } from "react";
import styles from "./Header.module.css";

interface HeaderProps {
  fixed?: boolean;
}

export const Header = ({
  fixed = true,
  children,
}: PropsWithChildren<HeaderProps>) => {
  return (
    <header
      className={clsx("nemo-header", styles.wrapper, fixed && styles.fixed)}
    >
      {children}
    </header>
  );
};
