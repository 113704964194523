import styles from "./RulesAssignmentTable.module.css";
import {
  Checkbox,
  Empty,
  Loader,
  Pagination,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_sredx/_ui";
import { RuleDto } from "_sredx/types";
import { RulesAssignmentRows } from "./RulesAssignmentRows";
import { ProgressBar } from "_sredx/_ui/ProgressBar/ProgressBar";
import { useEffect, useRef } from "react";

interface RulesAssignmentTableProps {
  rules: RuleDto[];
  checkedRules: RuleDto[];
  isLoading?: boolean;
  isFetching?: boolean;
  currentPage?: number;
  totalPages?: number;
  totalRules?: number;
  onPageChange?: (page: number) => void;
  onCheckChange: (checked: boolean, ruleId: string) => void;
  onCheckAll: (checkAll: boolean) => void;
}

export const RulesAssignmentTable = ({
  rules,
  checkedRules,
  isLoading,
  isFetching,
  currentPage = 0,
  totalPages = 1,
  totalRules = 0,
  onPageChange,
  onCheckChange,
  onCheckAll,
}: RulesAssignmentTableProps) => {
  // Derived Variables
  const showPagination = totalPages > 1;
  const cRef = useRef<HTMLInputElement>(null);

  const checkAll =
    checkedRules.filter((rule) => rules.find((r) => r.id === rule.id))
      .length === rules.length;
  const checkNone = !checkedRules.length;

  const indeterminate = !(checkAll || checkNone);

  useEffect(() => {
    if (cRef.current) cRef.current.indeterminate = indeterminate;
  }, [cRef, indeterminate, rules, currentPage]);


  // Events handlers
  const handleOnPageChange = (page: number) => {
    onPageChange && onPageChange(page);
  };

  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"sm"}>Loading rules...</Text>
      </div>
    );
  }
  if (!isLoading && rules.length === 0) {
    return <Empty message={"No rules found"} />;
  }
  return (
    <Table>
      <TableHeader>
        <TableHead>
        <Checkbox
            ref={cRef}
            checked={checkAll}
            onCheck={onCheckAll}
            label={"Rules"}
          />
          {/* <Text>Name</Text> */}
        </TableHead>
        <TableHead>
          <Text>ScoreCard</Text>
        </TableHead>
        <TableHead>
          <Text>Category</Text>
        </TableHead>
        <TableHead align={"right"}>
          <Text>Points</Text>
        </TableHead>
      </TableHeader>
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        <RulesAssignmentRows
          rules={rules}
          checkedRules={checkedRules}
          onCheckChange={onCheckChange}
        />
      </TableBody>
      <TableFooter>
        <TableFooterCell colSpan={4}>
          <div className={styles.footer}>
            <Text>
              {checkedRules.length} Selected - {totalRules} Rules
            </Text>{" "}
            {showPagination && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handleOnPageChange}
                currentPage={currentPage}
              />
            )}
          </div>
        </TableFooterCell>
      </TableFooter>
    </Table>
  );
};
