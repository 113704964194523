export const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
    return formattedDate;
  };
  
export const padL = (nr: number, len = 2, chr = '0') => String(nr).padStart(len, chr);