import { ChartPeriodType, PullRequestDto } from "_sredx/types";
import React, { useMemo } from "react";
import { getPRMergeFrequencyByWeek } from "_sredx/utils/pullRequests";
import { ParentSize } from "@visx/responsive";
import {
  BarGroupChart,
  ChartLegend,
  ChartLegendWrapper,
} from "_sredx/components/Charts";
import { format } from "date-fns";
import { PR_COLORS } from "_sredx/components/PullRequest/PRCycleTimeWidget/utils";
import { PRMergeFrequencyTooltipChart } from "./PRMergeFrequencyTooltipChart";

interface PRMergeFrequencyChartProps {
  pullRequests: PullRequestDto[];
  period: ChartPeriodType;
}
export const PRMergeFrequencyChart = ({
  pullRequests,
  period,
}: PRMergeFrequencyChartProps) => {
  const prMergeFrequencyData = useMemo(() => {
    // const mergedPullRequests = getMergedPRs(pullRequests);
    return getPRMergeFrequencyByWeek(pullRequests, period);
  }, [pullRequests, period]);

  const repositories = useMemo(() => {
    return Object.keys(prMergeFrequencyData[0]).filter(
      (d) => d !== "startDate" && d !== "endDate"
    );
  }, [prMergeFrequencyData]);

  return (
    <div>
      <div style={{ width: "100%", height: "500px" }}>
        <ParentSize>
          {({ width, height }) => (
            <BarGroupChart
              data={prMergeFrequencyData}
              xMapper={(d) => d.startDate.toISOString()}
              xLabelMapper={(val) => format(val, " dd MMM yyyy")}
              yLabelMapper={(val) => val}
              x1keys={repositories}
              x1Colors={PR_COLORS.slice(0, repositories.length)}
              renderTooltip={(d) => {
                return <PRMergeFrequencyTooltipChart data={d} />;
              }}
              width={width}
              height={height}
            />
          )}
        </ParentSize>
      </div>
      <ChartLegendWrapper>
        {repositories.map((key, i) => (
          <ChartLegend key={key} color={PR_COLORS[i]} label={key} />
        ))}
      </ChartLegendWrapper>
    </div>
  );
};
