import { Button, Card, PageContent, TabItem, Tabs } from "_sredx/_ui";
import React, { useState } from "react";
import styles from "./ServiceDetailsPage.module.css";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { AppReturnButton } from "_sredx/Layout";
import { ServiceDetailsContainer } from "_sredx/containers/ServiceDetailsContainer";

export const ServiceDetailsPage = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  if (error)
    return (
      <PageContent className={styles.error_wrapper}>
        <h1>{error}</h1>
        <Button onClick={() => navigate("/services")}>
          Return to services
        </Button>
      </PageContent>
    );
  return (
    <PageContent className={styles.wrapper}>
      <AppReturnButton to={"/services"} label={"Back to services"} />
      <ServiceDetailsContainer setError={setError} />

      <Card>
        <Tabs>
          <NavLink to="scorecards">
            {({ isActive }) => (
              <TabItem label={"Scorecards"} active={isActive} />
            )}
          </NavLink>
          {/* <NavLink to="overview">
            {({ isActive }) => (
              <TabItem label={"Overview"} active={isActive} />
            )}
          </NavLink> */}
        </Tabs>
        <Outlet />
      </Card>
    </PageContent>
  );
};