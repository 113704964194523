import React from "react";

import { InitiativeBaseDto } from "_sredx/types";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  KeyValue,
} from "_sredx/_ui";

import styles from "./InitiativeDetails.module.css";
import { formatISODate, resolveInitiativeStatus } from "_sredx/utils";

interface InitiativeDetailsProps {
  initiative: InitiativeBaseDto;
}

export const InitiativeDetails = ({ initiative }: InitiativeDetailsProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>About</CardTitle>
      </CardHeader>

      <CardBody>
        <div className={styles.body}>
          <KeyValue label={"Initiative Name"} value={initiative.name} />
          <KeyValue
            className={styles.description}
            label={"Initiative Description"}
            value={initiative.description}
          />
          <KeyValue
            label={"Status"}
            value={resolveInitiativeStatus(initiative.status)}
          />
          <KeyValue
            label={"Start date"}
            value={formatISODate(new Date(initiative.startDate))}
          />
          <KeyValue
            label={"End date"}
            value={formatISODate(new Date(initiative.endDate))}
          />
        </div>
      </CardBody>
      <CardFooter />
    </Card>
  );
};
