import { ClusterCardComponent } from "_sredx/components";
import { useKubeConfig } from "_sredx/hooks";
import { Cluster } from "_sredx/types";
import { isKubeConfigDownloadEnabled } from "./utils";

interface ClusterCardContainerProps {
  cluster: Cluster;
}

export const ClusterCardContainer = ({
  cluster,
}: ClusterCardContainerProps) => {
  const { handleDownload, isClusterDownloading } = useKubeConfig();
  const isButtonLoading = isClusterDownloading(cluster.name);
  const isDownloadEnabled = true;
  isKubeConfigDownloadEnabled(cluster);

  const handleOnDownload = (clusterName: string) => {
    handleDownload(clusterName);
  };
  return (
    <ClusterCardComponent
      cluster={cluster}
      onDownload={handleOnDownload}
      isButtonLoading={isButtonLoading}
      isDownloadEnabled={isDownloadEnabled}
    />
  );
};
