import { useQuery } from "react-query";
import { Cluster } from "types/selfService";
import { SELF_SERVICE_QUERY_KEYS, SELF_SERVICE_PATHS } from "./paths";
import { useAxios } from "hooks/useAxios";

export const useClusters = () => {
  const { axios } = useAxios();
  const fetchClusters = async () => {
    const response = await axios(SELF_SERVICE_PATHS.CLUSTERS);
    return response.data;
  };

  return useQuery<Cluster[]>(
    [SELF_SERVICE_QUERY_KEYS.CLUSTERS],
    fetchClusters,
    {
      retry: 5,
      retryDelay: 1000,
    }
  );
};
