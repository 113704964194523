interface CalendarIconProps {
  size?: number;
  color?: string;
}
export const CalendarIcon = ({
  size,
  color = "currentColor",
}: CalendarIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.3 1.2a.45.45 0 0 1 .9 0v.45h3.6V1.2a.45.45 0 0 1 .9 0v.45h.45c.746 0 1.35.604 1.35 1.35v6.3a1.35 1.35 0 0 1-1.35 1.35h-6.3A1.35 1.35 0 0 1 1.5 9.3V3c0-.746.604-1.35 1.35-1.35h.45V1.2Zm4.5 1.35V3a.45.45 0 1 0 .9 0v-.45h.45A.45.45 0 0 1 9.6 3v1.35H2.4V3a.45.45 0 0 1 .45-.45h.45V3a.45.45 0 1 0 .9 0v-.45h3.6Zm-5.4 2.7V9.3c0 .249.201.45.45.45h6.3a.45.45 0 0 0 .45-.45V5.25H2.4Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
