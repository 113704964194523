import { useGetWidgetData } from "_sredx/services";
import { DashboardWidgetFilter } from "_sredx/types/dashboards";
import { useState } from "react";
import RadarData from "./RadarChart";
import { ParentSize } from "@visx/responsive";
import {
  ServiceTeamListSelectorContainer,
  ServiceTeamType,
} from "../ServiceTeamListSelectorContainer";

export const ServicePerformanceContainer = () => {
  const [selected, setSelected] = useState<DashboardWidgetFilter>({
    service: undefined,
    team: undefined,
    scorecard: undefined,
  });

  const { data: tet } = useGetWidgetData<any>({
    key: "SERVICES_PERFORMANCE",
    filters: selected,
  });
  const apiData = [
    {
      siteName: "Service 1",
      info: [
        {
          key: "scorecard 1",
          value: 100,
        },
        // {
        //   key: "scorecard 2",
        //   value: 10,
        // },
        // {
        //   key: "scorecard 3",
        //   value: 5,
        // },
        {
          key: "scorecard 4",
          value: 2.3,
        },
        {
          key: "scorecard 5",
          value: 0.43,
        },
      ],
    },
    {
      siteName: "高雄市(左營)",
      info: [
        {
          key: "AQI",
          value: 86,
        },
        {
          key: "PM10",
          value: 73,
        },
        {
          key: "PM2.5",
          value: 33,
        },
        {
          key: "SO2",
          value: 4.9,
        },
        {
          key: "CO",
          value: 0.41,
        },
        {
          key: "NO2",
          value: 8.5,
        },
      ],
    },
  ];
  const data = apiData[1].info;

  //derived data
  const perfs = tet?.servicesPerformances?.map((perf: any) => {
    return {
      key: perf.scorecard,
      value: perf.scorePercentage,
      metadata: perf,
    };
  });
  const handleChange = (val: ServiceTeamType) => {
    if (val.type === "Service") {
      setSelected({
        service: val.id,
        team: undefined,
        scorecard: undefined,
      });
    }
    if (val.type === "Team") {
      setSelected({
        service: undefined,
        team: val.id,
        scorecard: undefined,
      });
    }
    return;
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <ServiceTeamListSelectorContainer onChange={handleChange} />
      <div className="flex-1 h-full">
        <ParentSize>
          {({ width, height }) => (
            <RadarData
              data={perfs ?? []}
              width={width}
              height={height}
              levels={perfs?.length ?? 0}
            />
          )}
        </ParentSize>
      </div>
    </div>
  );
};
