import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { GitopsAccountFormData, mapFormDataToApiData, schema } from './utils'
import { yupResolver } from '@hookform/resolvers/yup'
import styles from './GitopsAccountCreateContainer.module.css';
import GitopsAccountForm from 'components/self-service/Configuration/GitopsAccountForm/GitopsAccountForm';
import { useCreateGitopsAccount } from 'services/self-service/useCreateGitopsAccount';
import axios from 'axios';
import { Button } from 'components/octo-ui/Button/Button';


interface GitopsAccountCreateContainerProps {
    onCancel: () => void
}

const GitopsAccountCreateContainer = ({onCancel} : GitopsAccountCreateContainerProps) => {

    const createGitopsAccountMutation = useCreateGitopsAccount();
    let errorMessage = null;

    const methods = useForm<GitopsAccountFormData>({
        mode: "onChange",
        resolver: yupResolver(schema)
    });

    const handleFormSubmit = async (data: GitopsAccountFormData) => {
        const apiData = mapFormDataToApiData(data);
        await createGitopsAccountMutation.mutateAsync(apiData)
            .then(onCancel)  
            .catch(error => {
                console.error('Error creating gitops account:', error);
            });
    };

    if (createGitopsAccountMutation.error && axios.isAxiosError(createGitopsAccountMutation.error)) {
        const statusCode = createGitopsAccountMutation.error.response?.status;
        errorMessage = `Failed to create gitops account. Server responded with status code ${statusCode}.`;
    }

    const handleClickCreate = () => {
        methods.handleSubmit(handleFormSubmit)();
    };


    return (
        <div className={styles.root} >
            <h1>Create new gitops account</h1>
            <FormProvider {...methods}>
                <GitopsAccountForm
                    errors={methods.formState.errors}
                />
            </FormProvider>
            {errorMessage && <div className={styles.error}>{errorMessage}</div>}
            <div className='flex justify-end gap-4 mt-6' >
                <button onClick={onCancel}>Cancel</button>
                <Button onClick={handleClickCreate}>Save</Button>
            </div>
      </div>
      )
}

export default GitopsAccountCreateContainer