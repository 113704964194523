import { ParentSize } from "@visx/responsive";
import { Loader } from "_sredx/_ui";
import { BrushChart } from "_sredx/components";
import { useGetWidgetData } from "_sredx/services";
import { DASHBOARD_WIDGETS, ScoreTimelineDto } from "_sredx/types";
import styles from "./ServiceDetailsEvaluationHistoryContainer.module.css";

interface ServiceDetailsEvaluationHistoryContainerProps {
  scorecardId: string;
  serviceId: string;
}

export const ServiceDetailsEvaluationHistoryContainer = ({
  scorecardId,
  serviceId,
}: ServiceDetailsEvaluationHistoryContainerProps) => {
  const { data = { scoresInsights: [] }, isLoading } =
    useGetWidgetData<ScoreTimelineDto>({
      key: DASHBOARD_WIDGETS.SCORECARDS.SCORE_TIMELINE,
      filters: {
        scorecard: scorecardId,
        service: serviceId,
      },
    });
  if (isLoading)
    return (
      <div className={styles.loader}>
        <Loader />
        Loading data...
      </div>
    );
  if (!data) return null;

  return (
    <>
      {data.scoresInsights.length ? (
        <ParentSize>
          {({ width, height }) => {
            return (
              <BrushChart
                height={height}
                width={width}
                data={data.scoresInsights.map((d) => ({
                  date: d.evaluationDate,
                  close: d.scorePercentage,
                }))}
              />
            );
          }}
        </ParentSize>
      ) : (
        <div>No data available</div>
      )}
    </>
  );
};
