import { forwardRef } from "react";
import { IconProps } from "./types";

export const InfoCircularIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          d="M13.125 15.938h-.563V10.91l-.001-.018.002-.017a.563.563 0 0 0-.563-.563h-1.125a.563.563 0 0 0 0 1.126h.563v4.5h-.563a.563.563 0 0 0 0 1.124h2.25a.563.563 0 0 0 0-1.125ZM12 9.188a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm0 16.892c-4.343 0-7.875-3.55-7.875-7.893 0-4.342 3.532-7.875 7.875-7.875S19.875 7.657 19.875 12 16.343 19.893 12 19.893Z"
        />
      </svg>
    );
  }
);
