import { useAxios } from "hooks/useAxios";
import { useMutation, useQueryClient } from "react-query";
import { CreateCloudAccountDTO } from "types/selfService";
import { SELF_SERVICE_PATHS, SELF_SERVICE_QUERY_KEYS } from "./paths";

export const useCreateCloudAccount = () => {
    const queryClient = useQueryClient();
    const { axios } = useAxios();

    const createCloudAccount = async (dto: CreateCloudAccountDTO) => {
        // console.log(JSON.stringify(dto, null, 2)); 
        // await new Promise(resolve => setTimeout(resolve, 1000));  
        // return { data: "Simulated response" };  // Simulated response
        return await axios.post(SELF_SERVICE_PATHS.CLOUD_ACCOUNTS, dto);
    };
    
    return useMutation(createCloudAccount, {
        onSuccess: () => {
            queryClient.invalidateQueries(SELF_SERVICE_QUERY_KEYS.CLOUD_ACCOUNTS);
        },
    });
};