import React, {useState} from 'react';
import style from "./ScorecardComponent.module.css";
import ScoreCard from 'models/scorecard/ScoreCard';
import { getListOfUniqueCategoriesFromListOfRules } from 'services/scorecards/scorecards.helper';
import { ScorecardCategoriesSectionComponent } from '../ScorecardCategoriesSectionComponent/ScorecardCategoriesSectionComponent';
import { ScorecardLevelsSectionComponent } from '../ScorecardLevelsSectionComponent/ScorecardLevelsSectionComponent';
import { ScorecardOverviewSectionComponent } from '../ScorecardOverviewSectionComponent/ScorecardOverviewSectionComponent';
import { ScorecardMainInfosSectionComponent } from '../ScorecardMainInfosSectionComponent/ScorecardMainInfosSectionComponent';

interface ScorecardComponentProps{
    scorecard : ScoreCard;
}


export const ScorecardComponent = ({scorecard} : ScorecardComponentProps) => {
    const [ruleCategoriesList] = useState(getListOfUniqueCategoriesFromListOfRules(scorecard?.rules ?? []));


  return (
    <div className={style.wrapper}>
        <ScorecardMainInfosSectionComponent name={scorecard.name} description={scorecard.description}/>
        <ScorecardCategoriesSectionComponent ruleCategories={ruleCategoriesList}/>
        <ScorecardLevelsSectionComponent levels={scorecard.levels ?? []}/>
        <ScorecardOverviewSectionComponent overview={scorecard.overview!} />
    </div>
  )
}
