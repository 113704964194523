import { GroupedBarData } from "_sredx/components/GroupedBarChart/types";
import { Keys, ScoreCardsHealthDto, ScoreCardsHealthKeys } from "./types";

export const transformScoreCardsToGroupedBarData = (
  scoreCards: ScoreCardsHealthDto[]
): GroupedBarData<ScoreCardsHealthKeys>[] => {
  return scoreCards.map((scoreCard) => {
    // Initialize the base structure with all possible keys set to zero
    const groupedBar: GroupedBarData<ScoreCardsHealthKeys> = {
      key: scoreCard.name,
      PASSING: 0,
      PROGRESSING: 0,
      "NEEDS ATTENTION": 0,
      FAILING: 0,
    };

    // Aggregate counts for each status
    scoreCard.serviceHealths.forEach((health) => {
      if (Keys.includes(health.status as ScoreCardsHealthKeys)) {
        // Safely cast status as KeysType since it matches entries in Keys
        const key = health.status as ScoreCardsHealthKeys;

        const currentCount = groupedBar[key];
        groupedBar[key] = currentCount + health.count;
      }
    });

    return groupedBar;
  });
};
