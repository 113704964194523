import { useQuery } from "react-query";
import { useAxios } from "hooks/useAxios";
import { Cluster } from "types/selfService";
import { SELF_SERVICE_PATHS, SELF_SERVICE_QUERY_KEYS } from "./paths";


export const useClusterDetailInfo = (clusterName: string) => {
  const { axios } = useAxios();
  const fetchCluster = async () => {
    const response = await axios.get(
      SELF_SERVICE_PATHS.CLUSTER_DETAIL(clusterName)
    );
    return response.data;
  };

  return useQuery<Cluster | undefined, Error>(
    [SELF_SERVICE_QUERY_KEYS.CLUSTER_DETAIL, clusterName],
    fetchCluster);
};
