import { TeamBaseDto, TeamHeaderCreationDto } from "_sredx/types";
import styles from "./UpdateTeamModal.module.css";
import { FormFieldInput, FormFieldTextArea } from "../Form";
import { Resolver, useForm } from "react-hook-form";
import { Button, Heading } from "_sredx/_ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface UpdateTeamModalProps {
  team?: TeamBaseDto;
  onConfirm: (team: TeamHeaderCreationDto) => void;
  onCancel: () => void;
}

export const UpdateTeamModal = ({
  team,
  onConfirm,
  onCancel,
}: UpdateTeamModalProps) => {
  const methods = useForm<TeamHeaderCreationDto>({
    defaultValues: {
      name: team?.name,
      description: team?.description,
      tags: team?.tags ?? [],
    },
    resolver: yupResolver(schema) as Resolver<TeamHeaderCreationDto, any>,
    reValidateMode: "onChange",
  });

  const handleConfirm = () => {
    onConfirm(methods.getValues());
  };

  return (
    <div className={styles.wrapper}>
      <Heading level={3}>Update Team</Heading>
      <FormFieldInput
        label="Team Name"
        name="name"
        control={methods.control}
        placeholder="Enter team name"
        fullWidth
        errorMessage={methods.formState.errors.name?.message}
      />
      <FormFieldTextArea
        control={methods.control}
        label="Description"
        name="description"
        placeholder="Enter team description"
        fullWidth
        errorMessage={methods.formState.errors.description?.message}

      />

      <div className={styles.footer}>
        <Button onClick={onCancel} variant="neutral">
          Cancel
        </Button>
        <Button onClick={methods.handleSubmit(handleConfirm)}>Save</Button>
      </div>
    </div>
  );
};

const schema = yup.object().shape({
  name: yup.string().required("Team name is required"),
  description: yup.string().required("Team description is required"),
  tags: yup.array().of(
    yup.object().shape({
      key: yup.string().required("Tag key is required"),
      value: yup.string().required("Tag value is required"),
    })
  ),
});
