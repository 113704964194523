import { GitopsAccountDto } from "types/selfService";
import styles from "./ConfigurationGitopsAccounts.module.css";
import { resolveLogoText } from "components/self-service/ClusterCard/utils";
import { PiPencil, PiTrash } from "react-icons/pi";
import StatusIndicator from "components/octo-ui/StatusIndicator/StatusIndicator";
import { mapAccountPhaseToStatus } from "../utils";
import { ExclamationIcon } from "@heroicons/react/outline";

interface ConfigurationGitopsAccountsProps {
  accounts: GitopsAccountDto[];
  onDelete?: (accountId: string) => void;
  onEdit?: (accountId: string) => void;
}
const ConfigurationGitopsAccounts = ({
  accounts,
  onEdit,
  onDelete,
}: ConfigurationGitopsAccountsProps) => {
  const handleDeleteClick = (e: React.MouseEvent, accountId: string) => {
    e.preventDefault();
    if (onDelete) onDelete(accountId);
  };

  const handleEditClick = (e: React.MouseEvent, accountId: string) => {
    e.preventDefault();
    if (onEdit) onEdit(accountId);
  };

  return (
    <div className={styles.root}>
      {accounts.map((account) => (
        <div
          key={account.name}
          className={styles.accountRow}
          data-testid="account-row"
        >
          <div className={styles.accountProvider}>
            <StatusIndicator
              shouldPulse={false}
              status={mapAccountPhaseToStatus(account.phase)}
            />
            <div className={styles.icon_wrapper}>
              {resolveLogoText("github", false)}
            </div>
            {account.name}
            {account.phase === "ERROR" && (
              <span className={styles.details}>
                <ExclamationIcon />
                expired
              </span>
            )}
          </div>
          <div className={styles.actions}>
            {onEdit && (
              <button
                onClick={(e) => handleEditClick(e, account.name)}
                data-testid="edit-button"
              >
                <PiPencil />
              </button>
            )}
            {onDelete && (
              <button
                onClick={(e) => handleDeleteClick(e, account.name)}
                data-testid="delete-button"
              >
                <PiTrash />
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConfigurationGitopsAccounts;
