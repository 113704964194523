export const stringToHSLColor = (input: string) => {
  const str = input.toLowerCase();
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    // @ts-ignore
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let h = hash % 360;

  if (str === "javascript") h = 42;
  if (str === "python") h = 182;
  if (str === "java") h = 33;
  if (str === "csharp") h = 10;
  if (str === "ruby") h = 0;
  if (str === "swift") h = 218;
  if (str === "css") h = 209;
  if (str === "html") h = 39;
  if (str === "php") h = 240;
  if (str === "kotlin") h = 45;
  if (str === "typescript") h = 204;
  if (str === "rust") h = 12;
  if (str === "go") h = 192;
  if (str === "sql") h = 209;
  if (str === "cplusplus") h = 33;
  if (str === "scala") h = 218;
  if (str === "perl") h = 213;
  if (str === "lua") h = 39;

  return {
    backgroundColor: "hsl(" + h + ",50%,85%)",
    color: "hsl(" + h + ",50%,40%)",
  };
};
