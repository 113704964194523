export const FILTER_PROPERTY_TYPES = {
  NUMBER: "NUMBER",
  STRING: "STRING",
  DATE: "DATE",
  BOOLEAN: "BOOLEAN",
  ENUM: "ENUM",
  DURATION: "DURATION",
  LIST: "LIST",
} as const;

export const FILTER_OPERATORS = {
  GREATER_THAN_OR_EQUAL: "GTE",
  LESS_THAN_OR_EQUAL: "LTE",
  GREATER_THAN: "GT",
  LESS_THAN: "LT",
  EQUALS: "EQ",
  NOT_EQUALS: "NE",
  IN: "IN",
  NOT_IN: "NOT_IN",

  AFTER: "AFTER",
  BEFORE: "BEFORE",

  FROM: "FROM",
  BETWEEN: "BETWEEN",

  IS_NOT_NULL: "NE",
  IS: "EQ",

  CONTAINS: "CONTAINS",
  NOT_CONTAINS: "NOT_CONTAINS",
  NOT_BETWEEN: "NOT_BETWEEN",
  IS_NULL: "IS_NULL",
} as const;

export const DURATION_UNITS = {
  SECONDS: "Seconds",
  MINUTES: "Minutes",
  HOURS: "Hours",
  DAYS: "Days",
  WEEKS: "Weeks",
  MONTHS: "Months",
  YEARS: "Years",
} as const;

export type DurationUnitType =
  (typeof DURATION_UNITS)[keyof typeof DURATION_UNITS];

export type FilterOperatorType =
  (typeof FILTER_OPERATORS)[keyof typeof FILTER_OPERATORS];

export type FilterPropertyType =
  (typeof FILTER_PROPERTY_TYPES)[keyof typeof FILTER_PROPERTY_TYPES];

export interface AdvancedFilterConfig {
  key: string;
  label: string;
  description?: string;
  category: string;
  icon: string;
  type: FilterPropertyType;
}

export interface OptionsData {
  value: string;
  label: string;
}

export interface OptionsByKeyResult {
  isLoading: boolean;
  isFetching: boolean;
  data?: OptionsData[];
  isError: boolean;
  fetchNextPage?: () => void;
}

export type GetOptionsByKeyFn = (
  key?: string
) => (params?: any) => OptionsByKeyResult;

export interface AdvancedFilterValue {
  key: string;
  operator?: FilterOperatorType;
  value?: any;
}
