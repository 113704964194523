import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from '_sredx/config';
import { useMutation, useQueryClient } from 'react-query';
import { ClientApi } from '../clientApi';



export const useDetachMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.affectMember],
    mutationFn: (data: DetachTeamMember ) => {
      return ClientApi.post(API_ENDPOINTS.detachTeamMember(data.teamId), [data.memberId]);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.teams], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.users], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.team], {
        exact: false,
      });
    },
  });
};

interface DetachTeamMember{
    teamId: string;
    memberId: string;
}