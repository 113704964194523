export const INVITATION_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED",
  DELETED: "DELETED",
} as const;

export type InvitationStatusType =
  (typeof INVITATION_STATUS)[keyof typeof INVITATION_STATUS];

export interface InvitationDto {
  id: string;

  recipientEmail: string;

  inviterFirstName: string;

  inviterLastName: string;

  invitationStatus: InvitationStatusType;
}
