import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import clsx from "clsx";
import * as SelectPrimitive from "@radix-ui/react-select";
import { ChevronDownIcon } from "_sredx/_ui/Icon";

import styles from "./SelectContent.module.css";

export const SelectContent = forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={clsx(
        "nemo-select-content",
        styles.content_wrapper,
        position === "popper" && styles.popper,
        className
      )}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        style={
          position == "popper"
            ? {
                height: "var(--radix-select-trigger-height)",
                minWidth: "var(--radix-select-trigger-width)",
                width: "100%",
              }
            : {}
        }
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));

const SelectScrollUpButton = forwardRef<
  ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={clsx(
      "nemo-select-scroll-up-button",
      styles.select_scroll_button,
      styles.up,
      className
    )}
    {...props}
  >
    <div className={styles.arrow_icon} style={{ transform: "rotate(180deg)" }}>
      <ChevronDownIcon />
    </div>
  </SelectPrimitive.ScrollUpButton>
));

const SelectScrollDownButton = forwardRef<
  ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={clsx(
      "nemo-select-scroll-down-button",
      styles.select_scroll_button,
      styles.down,
      className
    )}
    {...props}
  >
    <div className={styles.arrow_icon}>
      <ChevronDownIcon />
    </div>
  </SelectPrimitive.ScrollDownButton>
));
