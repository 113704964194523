import { create } from "zustand";
import { createModalSlice, ModalSlice } from "./modal";

type State = ModalSlice;

// State hook
export const useSredxStore = create<State>()((...initializer) => ({
  ...createModalSlice(...initializer),
}));

export * from "./modal";
