import { AxiosInstance } from "axios";
import Rule from "../../models/scorecard/Rule";
import ScoreCard from "../../models/scorecard/ScoreCard";


// export const getAllScoreCards = (
//   axios: AxiosInstance,
//   includeOverview: boolean
// ): Promise<ScoreCard[]> => {
//   return axios.get(`scorecards`, { params: { overview : includeOverview} }).then((r) => {
//     return r.data;
//   });
export const getAllScoreCards = async (
  axios: AxiosInstance,
  includeOverview : boolean
): Promise<ScoreCard[]> => {
  const response = await axios.get<ScoreCard[]>("scorecards", {params : {overview : includeOverview}});
  return response.data;
};

export const getSingleScoreCardById = (
  axios: AxiosInstance,
  scoreCardId: string
): Promise<ScoreCard> => {
  return axios.get(`scorecards/${scoreCardId}`).then((r) => {
    return {
      id: r.data.id,
      tag: r.data.tag,
      name: r.data.name,
      description: r.data.description,
      targetTags: r.data.targetTags,
      rules: r.data.rules,
      levels: r.data.levels,
    };
  });
};

export const updateRuleDetails = (
  axios: AxiosInstance,
  scorecardId: string,
  rule: Rule
): Promise<Rule> => {
  return axios
    .put(`scorecards/${scorecardId}/rules/${rule.id}`, rule)
    .then((r: { data: Rule }) => {
      return r.data;
    });
};

export const createNewRule = (
  axios: AxiosInstance,
  scorecardId: string,
  rule: Rule
): Promise<Rule> => {
  return axios
    .post(`scorecards/${scorecardId}/rules/`, rule)
    .then((r: { data: Rule }) => {
      return r.data;
    });
};

export const createNewScoreCard = (
  axios: AxiosInstance,
  scoreCard: ScoreCard
): Promise<ScoreCard> => {
  return axios.post(`scorecards`, scoreCard).then((r: { data: ScoreCard }) => {
    return r.data;
  });
};

export const updateScoreCard = (
  axios: AxiosInstance,
  scoreCard: ScoreCard
): Promise<ScoreCard> => {
  //TODO call converter to convert map of rules to list of rules
  return axios.put(`scorecards`, scoreCard).then((r) => {
    return r.data;
  });
};

export const deleteScoreCard = async (
  axios: AxiosInstance,
  scoreCardId: string
): Promise<number> => {
  return axios.delete(`scorecards/${scoreCardId}`).then((r) => {
    return r.data;
  });
};

export const evaluateScoreCard = async (
  axios: AxiosInstance,
  scoreCardId: string,
  connexionId: string
) => {
  await axios.post(
    `evaluations/scorecard/${scoreCardId}/evaluate?connexionId=${connexionId}`
  );
};

export const evaluateAllScoreCards = async (
  axios: AxiosInstance,
  connexionId: string
) => {
  await axios.post(
    `evaluations/scorecards/evaluations?connexionId=${connexionId}`
  );
};
