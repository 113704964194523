import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Schema, mapFormDataToService, mapServiceToFormData } from "./utils";
import { ServiceInput } from "models/service/Service";
import styles from "./styles.module.css";
import { ServiceFormFields } from "./ServiceFormFields";

interface AddServiceFormProps {
  setServices: React.Dispatch<React.SetStateAction<ServiceInput[]>>;
  services: ServiceInput[];
  hide: () => void;
  defaultValues?: ServiceInput;
}

export const AddServiceForm = ({
  setServices,
  services,
  defaultValues,
  hide,
}: AddServiceFormProps) => {
  const mappedDefaultValues = defaultValues
    ? mapServiceToFormData(defaultValues)
    : {};
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(Schema),
    defaultValues: mappedDefaultValues,
  });

  const handleSubmitForm = (formData: any) => {
    if (defaultValues) {
      //replace service based on tag
      const index = services.findIndex(
        (service) => service.tag === defaultValues.tag
      );
      if (index !== -1) {
        const newServices = [...services];
        newServices[index] = mapFormDataToService(formData);
      setServices(newServices);
      }
      hide();
      return;
    }
    setServices((prev: ServiceInput[]) => {
      return [...prev, mapFormDataToService(formData)];
    });
    hide();
    return;
  };

  return (
    <div className="p-4 bg-white border border-black max-h-[90vh] overflow-auto">
    <FormProvider {...methods}>
      <div className={styles.wrapper}>
        <h1 className="text-2xl">Add a new service</h1>
        <ServiceFormFields />
        <div className={styles.action_buttons}>
          <button onClick={hide}>cancel</button>
          <button
            className={`${styles.saveButton} ${methods.formState.isValid
                ? styles.saveButtonValid
                : styles.saveButtonInvalid
              }`}
            onClick={methods.handleSubmit(handleSubmitForm)}
          >
            Save
          </button>
        </div>
      </div>
    </FormProvider>
    </div>
  );
};
