import { AdvancedFiltersInputValueProps } from "../types";
import inputStyles from "../Input.module.css";
import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverItem,
  PopoverTrigger,
} from "../../Popover";
import {
  DURATION_UNITS,
  DurationUnitType,
  FILTER_OPERATORS,
} from "../../types";

const calculateDurationInSeconds = (value: string, unit: DurationUnitType) => {
  if (!value) {
    return 0;
  }
  if (unit === DURATION_UNITS.SECONDS) {
    return parseInt(value);
  }
  if (unit === DURATION_UNITS.MINUTES) {
    return parseInt(value) * 60;
  }
  if (unit === DURATION_UNITS.HOURS) {
    return parseInt(value) * 60 * 60;
  }
  if (unit === DURATION_UNITS.DAYS) {
    return parseInt(value) * 60 * 60 * 24;
  }
  if (unit === DURATION_UNITS.WEEKS) {
    return parseInt(value) * 60 * 60 * 24 * 7;
  }
  if (unit === DURATION_UNITS.MONTHS) {
    return parseInt(value) * 60 * 60 * 24 * 30;
  }
  if (unit === DURATION_UNITS.YEARS) {
    return parseInt(value) * 60 * 60 * 24 * 365;
  }

  return 0;
};

const getUnit = (value: number) => {
  if (!value) {
    return DURATION_UNITS.HOURS;
  }
  if (value % 60 === 0) {
    return DURATION_UNITS.MINUTES;
  }
  if (value % (60 * 60) === 0) {
    return DURATION_UNITS.HOURS;
  }
  if (value % (60 * 60 * 24) === 0) {
    return DURATION_UNITS.DAYS;
  }
  if (value % (60 * 60 * 24 * 7) === 0) {
    return DURATION_UNITS.WEEKS;
  }
  if (value % (60 * 60 * 24 * 30) === 0) {
    return DURATION_UNITS.MONTHS;
  }
  if (value % (60 * 60 * 24 * 365) === 0) {
    return DURATION_UNITS.YEARS;
  }
  return DURATION_UNITS.SECONDS;
};

const caluclatDurationByUnit = (value: number, unit: DurationUnitType) => {
  if (!value) {
    return "";
  }
  if (unit === DURATION_UNITS.SECONDS) {
    return value;
  }
  if (unit === DURATION_UNITS.MINUTES) {
    return value / 60;
  }
  if (unit === DURATION_UNITS.HOURS) {
    return value / 60 / 60;
  }
  if (unit === DURATION_UNITS.DAYS) {
    return value / 60 / 60 / 24;
  }
  if (unit === DURATION_UNITS.WEEKS) {
    return value / 60 / 60 / 24 / 7;
  }
  if (unit === DURATION_UNITS.MONTHS) {
    return value / 60 / 60 / 24 / 30;
  }
  if (unit === DURATION_UNITS.YEARS) {
    return value / 60 / 60 / 24 / 365;
  }
  return value;
};

interface InputDurationProps extends AdvancedFiltersInputValueProps {}

export const InputDuration = ({
  value,
  operator,
  onChange,
}: InputDurationProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [currentUnit, setCurrentUnit] = useState<DurationUnitType>(
    getUnit(Array.isArray(value) ? value[0] : value)
  );
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(calculateDurationInSeconds(e.target.value, currentUnit));
  };

  const handleOnUnitChange = (unit: DurationUnitType) => {
    setCurrentUnit(unit);
    setPopoverOpen(false);
    onChange("");
  };

  const handleOnIntervalUnitChange = (unit: DurationUnitType) => {
    setCurrentUnit(unit);
    setPopoverOpen(false);
    onChange(["", ""]);
  };

  const handleOnChangeMinMax = ([min, max]: [string, string]) => {
    onChange([
      calculateDurationInSeconds(min, currentUnit),
      calculateDurationInSeconds(max, currentUnit),
    ]);
  };

  if (operator === FILTER_OPERATORS.BETWEEN) {
    const [min, max] = value || ["", ""];
    return (
      <div className={inputStyles.wrapper}>
        <input
          className={inputStyles.input}
          value={caluclatDurationByUnit(min, currentUnit)}
          onChange={(e) =>
            handleOnChangeMinMax([
              e.target.value,
              caluclatDurationByUnit(max, currentUnit) + "",
            ])
          }
          type="text"
          placeholder={"Min"}
        />
        <span className={inputStyles.to}>And</span>
        <input
          className={inputStyles.input}
          value={caluclatDurationByUnit(max, currentUnit)}
          onChange={(e) =>
            handleOnChangeMinMax([
              caluclatDurationByUnit(min, currentUnit) + "",
              e.target.value,
            ])
          }
          type="text"
          placeholder={"Max"}
        />
        <Popover onOpenChange={(val) => setPopoverOpen(val)} open={popoverOpen}>
          <PopoverTrigger open={popoverOpen} className={inputStyles.unit_w}>
            {currentUnit}
          </PopoverTrigger>
          <PopoverContent>
            <div>
              {Object.values(DURATION_UNITS).map((unit) => (
                <PopoverItem
                  key={unit}
                  onClick={() => handleOnIntervalUnitChange(unit)}
                  selected={unit === currentUnit}
                >
                  {unit}
                </PopoverItem>
              ))}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    );
  }

  return (
    <div className={inputStyles.wrapper}>
      <input
        value={caluclatDurationByUnit(value, currentUnit)}
        onChange={handleOnChange}
        className={inputStyles.input}
        type="text"
        placeholder={"Value"}
      />
      <Popover onOpenChange={(val) => setPopoverOpen(val)} open={popoverOpen}>
        <PopoverTrigger open={popoverOpen} className={inputStyles.unit_w}>
          {currentUnit}
        </PopoverTrigger>
        <PopoverContent>
          <div>
            {Object.values(DURATION_UNITS).map((unit) => (
              <PopoverItem
                key={unit}
                onClick={() => handleOnUnitChange(unit)}
                selected={unit === currentUnit}
              >
                {unit}
              </PopoverItem>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};
