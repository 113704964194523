import { Avatar, Button, Heading, Loader } from "_sredx/_ui";
import { TeamOverview } from "_sredx/components/TeamDetails";
import styles from "./TeamDetailsContainer.module.css";
import { useGetTeam } from "_sredx/services/teams/useGetTeam";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateTeam } from "_sredx/services/teams/useUpdateTeam";
import { useDeleteTeam } from "_sredx/services/teams/useDeleteTeam";
import { useModal } from "_sredx/hooks";
import { DeleteTeamConfirmationModal } from "_sredx/components/TeamDetails/DeleteTeamConfirmationModal";
import { UpdateTeamModal } from "_sredx/components/TeamDetails/UpdateTeamModal";

export const TeamDetailsOverviewContainer = ({ setError }: any) => {
  const { teamId } = useParams<{ teamId: string }>();
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetTeam({ id: teamId ?? "" });
  const { mutate: deleteTeam } = useDeleteTeam({ id: teamId ?? "" });
  const { mutate: updateTeam } = useUpdateTeam({ id: teamId ?? "" });

  const { openModal, closeModal } = useModal();

  const handleDeleteTeam = () => {
    openModal({
      content: (
        <DeleteTeamConfirmationModal
          team={data}
          onConfirm={() => {
            deleteTeam(undefined, {
              onSuccess: () => {
                closeModal();
                navigate("/teams");
              },
            });
          }}
          onCancel={closeModal}
        />
      ),
    });
  };

  const handleUpdateTeam = () => {
    openModal({
      content: (
        <UpdateTeamModal
          team={data}
          onConfirm={(team) => {
            updateTeam(team, {
              onSuccess: () => {
                closeModal();
              },
            });
          }}
          onCancel={closeModal}
        />
      ),
    });
  };

  if (isLoading)
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
      </div>
    );
  if (error || !data) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Avatar size={32} name={data.name} rectangle avatarStyle="identicon" />
        <Heading>{data.name}</Heading>
        <div className="ml-auto flex gap-4">
          <Button onClick={handleUpdateTeam}>Edit</Button>
          <Button onClick={handleDeleteTeam}>Delete</Button>
        </div>
      </div>
      <TeamOverview team={data} />
    </div>
  );
};
