import { AuthClientEvent } from "@react-keycloak/core/lib/types";
import { ModalContainer as NewModalContainer } from "_sredx/containers";
import React from "react";
import keycloak from "config/keycloak";
import { useAppV1Store } from "state/appv2";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { LoadingPage } from "pages/loading";
import { ToastContainer } from "components/octo-ui/Toaster/ToasterContainer";
import { AuthProvider } from "_sredx/auth";
import { SredxApp } from "_sredx/SredxApp";
import { ModalContainer } from "components/octo-ui/Modal";
import { AppRoutes } from "./routes/AppRoutes";
import FlagProvider from "@unleash/proxy-client-react";
import { unleashConfig } from "./config/unleash";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

function App() {
  const { isV1Enabled } = useAppV1Store();
  const handleOnEvent = async (event: AuthClientEvent) => {
    if (event === "onTokenExpired") {
      keycloak.login({
        redirectUri: window.location.href,
        prompt: "login",
      });
    }
  };

  if (!isV1Enabled) {
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        LoadingComponent={<LoadingPage />}
        onEvent={handleOnEvent}
        initOptions={{
          onLoad: "check-sso",
          pkceMethod: "S256",
        }}
      >
        <FlagProvider config={unleashConfig}>
          <AuthProvider>
            <SredxApp />
            <NewModalContainer />
            <ToastContainer />
            <ModalContainer />
          </AuthProvider>
        </FlagProvider>
      </ReactKeycloakProvider>
    );
  }

  return (
    <>
      <ReactKeycloakProvider
        authClient={keycloak}
        LoadingComponent={<LoadingPage />}
        onEvent={handleOnEvent}
        initOptions={{
          onLoad: "check-sso",
          pkceMethod: "S256",
        }}
      >
        <FlagProvider config={unleashConfig}>
          <AppRoutes />
        </FlagProvider>
        <ToastContainer />
        <ModalContainer />
      </ReactKeycloakProvider>
    </>
  );
}

export default App;
