import { useAxios } from "../../useAxios";
import { useQuery } from "react-query";
import IntegrationTemplate from "../../../models/integration/IntegrationTemplate";
import { getListOfIntegrations } from "../../../services/integrations/templates/ruletemplates.service";

export const useIntegrationsTemplates = () => {
  const { axios } = useAxios();
  return useQuery<IntegrationTemplate[], Error>(
    ["integrations", "templates"],
    (): Promise<IntegrationTemplate[]> => getListOfIntegrations(axios)
  );
};
