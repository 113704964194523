import { AxisBottom, AxisLeft } from "@visx/axis";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { BarGroup } from "@visx/shape";
import { BarGroupBar } from "@visx/shape/lib/types";
import { defaultStyles, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import React, { useCallback } from "react";
import styles from "./GroupedBarChart.module.css";
import { GroupedBarData, GroupedBarTooltipData, KeysType } from "./types";

interface GroupedBarChartProps<K extends KeysType> {
  data: GroupedBarData<K>[];
  keys: K[];
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
}

const COLORS = {
  PASSING: "#92D37A",
  PROGRESSING: "#7DDFD9",
  "NEEDS ATTENTION": "#FFD978",
  FAILING: "#FF7878",
};

const defaultMargin = { top: 40, right: 0, bottom: 40, left: 25 };

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "#eeeeee",
};

const axisColor = "#000000";
const axisBottomTickLabelProps = {
  fontFamily: "",
  fontSize: 9,
  textAnchor: "middle" as const,
  fill: axisColor,
};

export const GroupedBarChart = <K extends KeysType>({
  data,
  width,
  height,
  keys,
  margin = defaultMargin,
}: GroupedBarChartProps<K>) => {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<GroupedBarTooltipData<K>>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  let tooltipTimeout: number;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = scaleBand<string>({
    domain: data.map((d) => d.key),
    padding: 0.2,
  }).rangeRound([0, xMax]);

  const statusScale = scaleBand<string>({
    domain: keys,
    padding: 0.1,
  }).rangeRound([0, xScale.bandwidth()]);

  const countScale = scaleLinear<number>({
    domain: [
      0,
      Math.max(
        // @ts-ignore
        ...data.map((d) => Math.max(...keys.map((key) => Number(d[key]))))
      ),
    ],
  }).range([yMax, 0]);

  const colorScale = scaleOrdinal<K, string>({
    domain: keys,
    range: [
      COLORS.PASSING,
      COLORS.PROGRESSING,
      COLORS["NEEDS ATTENTION"],
      COLORS.FAILING,
    ],
  });

  // handlers
  const handleTooltipTimeout = () => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  };
  const handleTooltip = useCallback(
    (bar: BarGroupBar<K>) => {
      return (
        event:
          | React.TouchEvent<SVGRectElement>
          | React.MouseEvent<SVGRectElement>
      ) => {
        const { x, y } = localPoint(event) || { x: 0, y: 0 };
        showTooltip({
          tooltipData: bar,
          tooltipLeft: x,
          tooltipTop: y,
        });
      };
    },
    [showTooltip, localPoint]
  );

  if (width < 10) return null;
  return (
    <div className={styles.wrapper}>
      <svg ref={containerRef} width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          rx={14}
          fill="transparent"
        />
        <Group top={margin.top} left={margin.left}>
          <BarGroup
            data={data}
            keys={keys}
            height={height - margin.bottom - margin.top}
            x0={(d) => d.key}
            x0Scale={xScale}
            x1Scale={statusScale}
            yScale={countScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                >
                  {barGroup.bars.map((bar) => {
                    return (
                      <rect
                        key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                        x={bar.x}
                        y={bar.y}
                        width={bar.width}
                        height={bar.height}
                        fill={bar.color}
                        rx={keys.length}
                        onMouseLeave={handleTooltipTimeout}
                        onMouseMove={handleTooltip(bar)}
                      />
                    );
                  })}
                </Group>
              ))
            }
          </BarGroup>
          <AxisLeft scale={countScale} numTicks={10} />
          <AxisBottom
            top={yMax}
            scale={xScale}
            tickFormat={(d) => d}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={axisBottomTickLabelProps}
          />
        </Group>
      </svg>
      <div
        className={styles.tooltip_wrapper}
        style={{
          top: margin.top / 2 - 10,
        }}
      >
        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <div style={{ color: colorScale(tooltipData.key) }}>
              <strong>{tooltipData.key}</strong>
            </div>
            <div>
              <strong>value : {tooltipData.value}</strong>
            </div>
          </TooltipInPortal>
        )}
      </div>
    </div>
  );
};
