import React, { useState } from "react";
import { ScorecardRulesByCategory } from "../ScorecardRulesByCategory";
import { GoGraph } from "react-icons/go";
import { SiLevelsdotfyi } from "react-icons/si";
import { MdRule } from "react-icons/md";
import Tooltip from "components/octo-ui/Tootlip/Tooltip";
import styles from "./ScorecardRowAccordionContainer.module.css";
import clsx from "clsx";
import {
  EvaluationLevelsResultDto,
  Level,
  Rule,
  ValueObject,
} from "_sredx/types";
import { ScorecardLevels } from "../ScorecardLevels";
import { ServiceDetailsEvaluationHistoryContainer } from "_sredx/containers/ServiceDetailsEvaluationHistoryContainer";

interface ScorecardRowAccordionContainerProps {
  rules?: {
    rule: Rule;
    errors: string[];
    success: boolean;
    actualValue: ValueObject;
  }[];
  possibleLevels: Level[];
  evaluationLevels?: EvaluationLevelsResultDto;
}

export const ScorecardRowAccordionContainer = ({
  rules,
  possibleLevels,
  evaluationLevels,
}: ScorecardRowAccordionContainerProps) => {
  const [activeTab, setActiveTab] = useState<"RULES" | "LEVELS" | "TRENDS">(
    "RULES"
  );
  const resolveDisplayedTab = (activeTab: "RULES" | "LEVELS" | "TRENDS") => {
    switch (activeTab) {
      case "RULES":
        return <ScorecardRulesByCategory rules={rules} />;
      case "LEVELS":
        return (
          <ScorecardLevels
            possibleLevels={possibleLevels}
            evaluationLevels={evaluationLevels}
          />
        );
      case "TRENDS":
        return (
          evaluationLevels && (
            <ServiceDetailsEvaluationHistoryContainer
              scorecardId={evaluationLevels.scorecard.scoreCardId}
              serviceId={evaluationLevels.service.serviceId}
            />
          )
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.side_menu}>
        <Tooltip content="Rules">
          <div
            onClick={() => setActiveTab("RULES")}
            className={clsx(
              styles.side_menu_item,
              activeTab == "RULES" ? styles.active_item : ""
            )}
          >
            <MdRule />
          </div>
        </Tooltip>
        <Tooltip content="Levels">
          <div
            onClick={() => setActiveTab("LEVELS")}
            className={clsx(
              styles.side_menu_item,
              activeTab == "LEVELS" ? styles.active_item : ""
            )}
          >
            <SiLevelsdotfyi />
          </div>
        </Tooltip>
        <Tooltip content="Graph">
          <div
            onClick={() => setActiveTab("TRENDS")}
            className={clsx(
              styles.side_menu_item,
              activeTab == "TRENDS" ? styles.active_item : ""
            )}
          >
            <GoGraph />
          </div>
        </Tooltip>
      </div>
      <div className={styles.main}>{resolveDisplayedTab(activeTab)}</div>
    </div>
  );
};
