import React from "react";
import { Heading, PageContent } from "_sredx/_ui";
import { SelfserviceSettingsContainer } from "_sredx/containers/SelfserviceSettingsContainer";
import styles from "./SelfserviceSettingsContainer.module.css";

export const SelfserviceSettingsPage = () => {
  return (
    <PageContent>
      <div className={styles.header_wrapper}>
        <div>
          <Heading>Self service settings</Heading>
          <p>Manage your cloud and gitops accounts</p>
        </div>
      </div>
      <SelfserviceSettingsContainer />
    </PageContent>
  );
};
