import styles from "./EvaluationStatus.module.css";
import clsx from "clsx";
import { Text } from "_sredx/_ui";

interface EvaluationStatusProps {
  success: boolean;
}

export const EvaluationStatus = ({ success }: EvaluationStatusProps) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(styles.dot, success ? styles.success : styles.failed)}
      />
      <Text className={styles.content}>{success ? "passed" : "failed"}</Text>
    </div>
  );
};
