import styles from "./PlannerHeaderMonth.module.css";
import clsx from "clsx";
import { resolveMonthName } from "./utils";

interface PlannerHeaderMonthsProps {
  month: Date;
  isLoading?: boolean;
}

export const PlannerHeaderMonth = ({ month }: PlannerHeaderMonthsProps) => {
  const isCurrentMonth = month.getMonth() === new Date().getMonth();
  return (
    <div
      data-testid="month-wrapper"
      className={clsx(
        styles.wrapper,
        isCurrentMonth && "nemo-planner-header-current-month"
      )}
    >
      <div className={styles.month_item_label}>{resolveMonthName(month)}</div>
    </div>
  );
};
