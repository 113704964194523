import * as RadixPopover from "@radix-ui/react-popover";
import { ChevronDownIcon } from "_sredx/_ui/Icon";
import {
  Avatar,
  Searchbar,
  SelectPopoverContent,
  SelectPopoverItems,
  SelectPopoverSelectedItem,
  SelectPopoverSelectedItems,
  SelectPopoverTitle,
} from "_sredx/_ui";
import { useInfiniteGetResources } from "_sredx/services/resources/useGetInfiniteResources";
import { ResourceDto, ResourceMinDto } from "_sredx/types/resources";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./ResourceSelectorContainer.module.css";
import { mapInfiniteDomains } from "../DomainSelectContainer/utils";
import { mapInfiniteResources } from "./utils";
import { resolveResourceIcon } from "_sredx/utils";

interface ResourceSelectContainerProps {
  onChange?: (resources: ResourceMinDto[]) => void;
  countIndicator?: boolean;
  initialValue?: {
    name: string;
    id: string;
    [key: string]: any;
  }[];
}
export const ResourceSelectorContainer = ({
  onChange,
  countIndicator = true,
  initialValue,
}: ResourceSelectContainerProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedResources, setSelectedResources] = useState<ResourceMinDto[]>(
    []
  );

  // Services
  const {
    data: infiniteResources,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
  } = useInfiniteGetResources({
    params: {
      name: searchQuery,
    },
  });

  //Side effects
  useEffect(() => {
    if (initialValue) {
      setSelectedResources(
        initialValue.map((resource) => ({
          name: resource.name,
          id: resource.id,
          product: resource.product,
          type: resource.type,
          tags: resource.tags,
          version: resource.version,
        }))
      );
    }
  }, [initialValue]);

  // Derived data
  const resources = useMemo(() => {
    return mapInfiniteResources(infiniteResources);
  }, [infiniteResources]);

  const displayedDomains = useMemo(() => {
    return resources.filter((resource) => {
      const isSelected = selectedResources.some(
        (selectedDomain) => selectedDomain.id === resource.id
      );
      return !isSelected;
    });
  }, [resources, selectedResources]);

  // Event handlers
  const handleOnSearch = (value: string) => {
    // [TODO] add debounce
    setSearchQuery(value);
  };
  const handleOnAddResource = (domain: ResourceMinDto) => {
    setSelectedResources((prev) => [...prev, domain]);
    onChange?.([...selectedResources, domain]);
  };
  const handleOnClear = () => {
    setSelectedResources([]);
    onChange?.([]);
  };
  const handleOnRemoveResource = (resource: ResourceMinDto) => {
    setSelectedResources((prev) => prev.filter((t) => t.id !== resource.id));
    onChange?.(selectedResources.filter((t) => t.id !== resource.id));
  };
  const handleOnLoadMore = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  return (
    <RadixPopover.Root>
      <RadixPopover.Trigger asChild>
        <button className={styles.trigger_btn}>
          {(countIndicator || selectedResources.length == 0) && (
            <span className={styles.trigger_label}>Resources</span>
          )}

          {selectedResources.length > 0 && (
            <div className="flex gap-2">
              {countIndicator ? (
                selectedResources.length
              ) : (
                <>
                  {selectedResources.slice(0, 2).map((resource) => (
                    <SelectPopoverSelectedItem key={resource.id}>
                      <SelectedResource resource={resource} />
                    </SelectPopoverSelectedItem>
                  ))}
                  {selectedResources.length > 2 &&
                    `+${selectedResources.length - 2}`}
                </>
              )}
            </div>
          )}
          <div className={styles.trigger_icon}>
            <ChevronDownIcon />
          </div>
        </button>
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          className={styles.content}
          align={"start"}
          sideOffset={8}
        >
          <SelectPopoverContent>
            <SelectPopoverTitle title={"Resource"} onClear={handleOnClear} />
            <Searchbar
              placeholder={"Search resources by name"}
              size={"small"}
              autoFocus
              onChange={handleOnSearch}
              value={searchQuery}
            />
            <SelectPopoverSelectedItems>
              {selectedResources.map((resource) => (
                <SelectPopoverSelectedItem
                  key={resource.id}
                  onRemove={() => handleOnRemoveResource(resource)}
                >
                  <SelectedResource resource={resource} />
                </SelectPopoverSelectedItem>
              ))}
            </SelectPopoverSelectedItems>

            <SelectPopoverItems
              items={displayedDomains}
              renderItem={(resource) => <ResourceOption resource={resource} />}
              onItemClick={handleOnAddResource}
              isLoading={isLoading}
              isFetching={isFetching}
              loadMore={handleOnLoadMore}
              loadingCaption={"Loading resources..."}
            />
          </SelectPopoverContent>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

const SelectedResource = ({
  resource,
}: {
  resource: ResourceDto | ResourceMinDto;
}) => {
  return (
    <>
      {resolveResourceIcon(resource)}
      <span className={styles.selected_item_label}>{resource.name}</span>
    </>
  );
};
const ResourceOption = ({
  resource,
}: {
  resource: ResourceMinDto | ResourceDto;
}) => {
  return (
    <>
      {/* <Avatar rectangle size={16} name={resource.name} avatarStyle={"initials"} /> */}
      {resolveResourceIcon(resource)}
      <span className={styles.item_label}>{resource.name}</span>
    </>
  );
};
