import React from 'react';
import { ScorecardListContainer } from 'containers/ScorecardListContainer';
import { PageLayout } from 'components/Layout';
import { useNavigate } from 'react-router-dom';
import { ADMIN_CREATE_NEW_SCORECARD_PAGE } from 'routes/paths';
import { Button } from 'components/octo-ui/Button/Button';

export const ScorecardsPage = ()  => {

  const navigate = useNavigate();

    const handleOnNewScorecard = () => {
        navigate(ADMIN_CREATE_NEW_SCORECARD_PAGE);
    }

    const newScorecardButton = <Button variant='primaryDark' onClick={handleOnNewScorecard}>New Scorecard</Button>

  return (
    <PageLayout title="Scorecards" description="Level Up with Scorecards: Quality, Readiness, and Migration" actions={newScorecardButton}>
        <ScorecardListContainer/>
    </PageLayout>
  )
}