// import { ThemeColors } from "./componentsv2/shared/TagLabel/TagLabelTypes";

export const sredxPallete = {
  sky: "#22D3EE",
  green: "#65CE53",
  red: "#FF176B",
  violet: "#DF18FF",
  yellow: "#FFD700",
  gray: "#ddd",
};

export const integrationIconColors = {
  git: "text-orange-600",
  sonar: "text-blue-700",
  harbor: "text-green-600",
  pivotal: "text-orange-700",
  datadog: "text-violet-700",
  pagerduty: "text-green-700",
  dynatrace: "text-blue-500",
  jira: "text-blue-700",
  default: "",
};

export const statusColors = {
  success: sredxPallete.green,
  danger: sredxPallete.red,
  info: sredxPallete.sky,
  warning: sredxPallete.yellow,
  neutral: sredxPallete.gray,
};
