import clsx from "clsx";
import styles from "./DashboardWidgetPreview.module.css";
import { useState } from "react";
import { Text } from "_sredx/_ui";
import { WIDGET_META, resolveWidgetImage } from "_sredx/types";

interface DashboardWidgetPreviewProps {
  id: string;
  onSelect: (widget: string) => void;
  isSelected?: boolean;
}
export const DashboardWidgetPreview = ({
  id,
  onSelect,
  isSelected,
}: DashboardWidgetPreviewProps) => {
  const [selected, setSelected] = useState(isSelected);
  //   const img = resolveWidgetImage(id);
  const handleSelect = () => {
    setSelected(!selected);
    onSelect(id);
  };
  //@ts-ignore
  const { title, description, type } = WIDGET_META[id];

  return (
    <div className={clsx(styles.wrapper)} onClick={handleSelect}>
      <div className={clsx(styles.img, selected ? styles.selected : null)}>
        {/* {type ?? "unknown"} */}
        <img
          src={`/images/Dataviz/${resolveWidgetImage(type ?? "")}`}
          alt={type ?? ""}
        />
      </div>
      <Text>{title ?? "Unknown"}</Text>
    </div>
  );
};
