import { Empty, Loader, Text } from "_sredx/_ui";
import { ClusterDetailsFoundation } from "_sredx/types";
import { ClusterFoundationsToolCard } from "../ClusterFoundationsToolCard";
import styles from "./ClusterFoundationsList.module.css";

interface ClusterFoundationsListProps {
  clusterFoundations?: ClusterDetailsFoundation[];
  isLoading?: boolean;
}

export const ClusterFoundationsList = ({
  clusterFoundations = [],
  isLoading = false,
}: ClusterFoundationsListProps) => {
  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>Loading cluster foundations ...</Text>
      </div>
    );
  }
  if (!clusterFoundations || clusterFoundations.length == 0) {
    return <Empty message={"No Foundation for cluster was found ..."} />;
  }
  return (
    <div className={styles.wrapper}>
      {clusterFoundations.map((clusterFoundations) => (
        <ClusterFoundationsToolCard
          clusterFoundation={clusterFoundations}
          key={clusterFoundations.name}
        />
      ))}
    </div>
  );
};
