import { PullRequestDto } from "_sredx/types";
import {
  MetricChartStats,
  MetricChartStatsWrapper,
} from "_sredx/components/Metrics";
import {
  calculatePRAvgCycleTime,
  calculatePRAvgTimeToMerge,
  calculatePRAvgTimeToReview,
} from "_sredx/utils/pullRequests";
import React from "react";
import { formatSeconds } from "_sredx/utils";

interface PRCycleTimeStatsProps {
  pullRequests: PullRequestDto[];
}

export const PRCycleTimeStats = ({
  pullRequests = [],
}: PRCycleTimeStatsProps) => {
  const avgCycleTime = calculatePRAvgCycleTime(pullRequests);
  const avgTimeToReview = calculatePRAvgTimeToReview(pullRequests);
  const avgTimeToMerge = calculatePRAvgTimeToMerge(pullRequests);
  return (
    <MetricChartStatsWrapper>
      <MetricChartStats
        label={"Pull requests"}
        value={pullRequests.length}
        unit={"PRs"}
      />
      <MetricChartStats
        label={"Avg cycle time"}
        value={formatSeconds(avgCycleTime)}
        unit={"Hours"}
        tooltip={"dedes"}
      />
      <MetricChartStats
        label={"Avg time to review"}
        value={formatSeconds(avgTimeToReview)}
        unit={"Hours"}
      />
      <MetricChartStats
        label={"Avg time to merge"}
        value={formatSeconds(avgTimeToMerge)}
        unit={"Hours"}
      />
    </MetricChartStatsWrapper>
  );
};
