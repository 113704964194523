import {
  Checkbox,
  Table,
  TableBody,
  TableFooter,
  TableFooterCell,
  TableHead,
  TableHeader,
  Text,
} from "_sredx/_ui";
import { ServiceBaseDto } from "_sredx/types";
import { UseQueryResult } from "react-query";
import { ServiceProvider } from "types/discovery";
import { OnboardingProviderImportedServicesRows } from "../OnboardingProviderImportedServicesRows";
import styles from "./OnboardingImportedServicesTable.module.css";
import { useEffect, useRef } from "react";

interface OnboardingImportedServicesTableProps {
  serviceProviders: ServiceProvider[];
  selectedServices: ServiceBaseDto[];
  onSelectService: (checked: boolean, service: ServiceBaseDto) => void;
  importedServices: UseQueryResult<{
    data: ServiceBaseDto[];
    failedInstances: string[];
  }>[];
  onCheckAll: (checkAll: boolean) => void;
}

export const OnboardingImportedServicesTable = ({
  serviceProviders,
  selectedServices,
  onSelectService,
  importedServices,
  onCheckAll,
}: OnboardingImportedServicesTableProps) => {
  const totalServices = importedServices.reduce((acc, result) => {
    return acc + (result.data?.data?.length ?? 0);
  }, 0);

  const cRef = useRef<HTMLInputElement>(null);

  const checkAll = selectedServices.length === totalServices;
  const checkNone = !selectedServices.length;

  const indeterminate = !checkAll && !checkNone;

  useEffect(() => {
    if (cRef.current) cRef.current.indeterminate = indeterminate;
  }, [cRef, indeterminate]);

  return (
    <div className={styles.wrapper}>
      <Table>
        <TableHeader>
          <TableHead>
            <Checkbox
              ref={cRef}
              checked={checkAll}
              onCheck={onCheckAll}
              label={"Services"}
            />
          </TableHead>
          <TableHead align={"left"}>
            <Text>Tags</Text>
          </TableHead>
          <TableHead align={"right"}>
            <Text>Tools</Text>
          </TableHead>
        </TableHeader>

        <TableBody>
          {serviceProviders.map((provider, index) => (
            <OnboardingProviderImportedServicesRows
              key={index}
              //@ts-ignore
              importedServices={importedServices[index].data.data ?? []}
              onCheckChange={onSelectService}
              checkedServices={selectedServices}
              serviceProvider={provider}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableFooterCell colSpan={3}>
            <div className={styles.footer}>
              <Text>
                {selectedServices.length} Selected - {totalServices} Services
              </Text>
            </div>
          </TableFooterCell>
        </TableFooter>
      </Table>
    </div>
  );
};
