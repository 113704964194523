import { useQuery } from "react-query";
import {
  ServiceBaseDto,
} from "_sredx/types";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";

interface UseGetServicesProps {
  id: string;
}

export const useGetService = ({ id }: UseGetServicesProps) =>
  useQuery<ServiceBaseDto>({
    queryKey: [QUERY_KEYS.services, id],
    queryFn: async () => {
      return await ClientApi.get(API_ENDPOINTS.getServiceById(id));
    },
    enabled: !!id,
  });
