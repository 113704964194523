import { StepperHeader } from "componentsv2/shared/StepperComponent/StepperHeader";
import { StepperContext } from "context/StepperContext";
import { StepperProviderProps, UseStepperReturn } from "types/Stepper";

export const StepperProvider = (props: StepperProviderProps) => {
  const { children, ...data } = props;

  return (
    <StepperContext.Provider value={data as UseStepperReturn}>
      <StepperHeader />
      {children}
    </StepperContext.Provider>
  );
};
