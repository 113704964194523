import { FormField } from "components/basic/FormField";
import { SelectInput } from "components/basic/SelectInput";
import { TextInput } from "components/octo-ui/Forms/TextInput";
import { resolveToolUrl } from "../ClusterCard/utils";

interface ClusterInfoFormProps {
  cloudAccounts: { name: string; provider: string }[];
  gitopsAccounts: { name: string; owner: string }[];
  nodeTypes: string[];
  k8sVersions: string[];
  poolSizeMax: number;
  display: boolean;
  errors?: any;
}
export const ClusterInfoForm = ({
  display,
  cloudAccounts,
  k8sVersions,
  nodeTypes,
  poolSizeMax,
  gitopsAccounts,
  errors,
}: ClusterInfoFormProps) => {
  const resolveLabelValue = (array: string[]) => {
    return array.map((val) => ({
      value: val,
      label: val,
    }));
  };
  const resolvePoolSize = (max: number) => {
    return Array.from({ length: max }, (v, k) => k + 1).map((val) => ({
      value: val,
      label: val,
    }));
  };

  return (
    <div className={`flex flex-col gap-4 ${display ? "" : "hidden"}`}>
      <FormField
        id="name"
        name="name"
        label="Cluster name"
        placeholder="Enter cluster name"
        component={TextInput}
        error={errors?.name?.message}
      />
      <FormField
        id="accountName"
        name="accountName"
        label="Cloud Account"
        placeholder="Select cloud account"
        component={SelectInput}
        options={cloudAccounts.map((account) => {
          const ToolImg = resolveToolUrl(account.provider);
          if (ToolImg)
            return {
              value: account.name,
              label: (
                <>
                  <ToolImg className="h-4" />
                  {account.name}
                </>
              ),
            };
        })}
        errors={errors?.accountName?.message}
      />
      <div className="flex justify-between gap-6">
        <FormField
          id="gitOpsName"
          name="gitOpsName"
          label="Gitops Account"
          placeholder="Select a gitops account"
          component={SelectInput}
          options={gitopsAccounts.map((account) => ({
            value: account.name,
            label: account.name,
          }))}
          error={errors?.gitOpsName?.message}
        />
        <FormField
          id="repoName"
          name="repoName"
          label="Repository Name"
          className="w-full"
          placeholder="Repository Name"
          component={TextInput}
          error={errors?.repoName?.message}
        />
      </div>
      <FormField
        id="gitOpsTool"
        name="gitOpsTool"
        label="GitOps Tool"
        className="w-full"
        placeholder="Select GitOps tool"
        component={SelectInput}
        options={[
          { value: "flux", label: "FluxCD" },
          { value: "argo", label: "ArgoCD" },
        ]}
        error={errors?.gitOpsTool?.message}
      />
      <div className="flex justify-between gap-6">
        <FormField
          id="cni"
          name="cni"
          label="CNI Type"
          placeholder="Select CNI Type"
          component={SelectInput}
          options={[{ value: "kilo", label: "Kilo" }]}
          error={errors?.cni?.message}
        />
        <FormField
          id="version"
          name="version"
          label="Kubernetes Version"
          placeholder="Kubernetes version"
          component={SelectInput}
          options={resolveLabelValue(k8sVersions)}
          error={errors?.version?.message}
        />
      </div>
      <div className="flex justify-between gap-6">
        <FormField
          id="size"
          name="size"
          label="Pool Size"
          placeholder="Select Pool size"
          component={SelectInput}
          options={resolvePoolSize(poolSizeMax)}
          error={errors?.size?.message}
        />
        <FormField
          id="nodeType"
          name="nodeType"
          label="Node Type"
          placeholder="Select node type"
          component={SelectInput}
          options={resolveLabelValue(nodeTypes)}
          error={errors?.nodeType?.message}
        />
      </div>
    </div>
  );
};
