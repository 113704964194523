import { useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../../Popover";
import clsx from "clsx";
import inputStyles from "../Input.module.css";
import { format } from "date-fns";
import { Calendar } from "../../Calendar";

interface InputDatePopoverProps {
  value: Date;
  onChange: (value: Date) => void;
}

export const InputDatePopover = ({
  value,
  onChange,
}: InputDatePopoverProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const handleDateOptionChange = (_value: Date[]) => {
    setPopoverOpen(false);
    onChange(_value[0]);
  };

  return (
    <Popover onOpenChange={(val) => setPopoverOpen(val)} open={popoverOpen}>
      <PopoverTrigger>
        <span className={clsx(!value && inputStyles.trigger_label)}>
          {value ? `${format(value, "dd/MM/yyyy")}` : "Select date"}
        </span>
      </PopoverTrigger>
      <PopoverContent>
        <Calendar range={false} onChange={handleDateOptionChange} />
      </PopoverContent>
    </Popover>
  );
};
