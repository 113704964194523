import styles from "./ScoreCardDetailsPage.module.css";
import { AppReturnButton } from "_sredx/Layout";
import { Card, PageContent, TabItem, Tabs } from "_sredx/_ui";
import { NavLink, Outlet } from "react-router-dom";
import { ScoreCardDetailsContainer } from "_sredx/containers";

export const ScoreCardDetailsPage = () => {
  return (
    <PageContent className={styles.wrapper}>
      <AppReturnButton to={"/scorecards"} label={"Back to scorecards"} />
      <ScoreCardDetailsContainer />

      <Card>
        <Tabs>
          <NavLink to="services">
            {({ isActive }) => <TabItem label={"Services"} active={isActive} />}
          </NavLink>
        </Tabs>
        <Outlet />
      </Card>
    </PageContent>
  );
};
