import React, { useState, useMemo } from "react";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import { ParentSize } from "@visx/responsive";
import { animated } from "@react-spring/web";
import { DonutData } from "_sredx/types";
import styles from "./DonutChart.module.css";
import { COLORS } from "./constants";

interface DonutChartProps {
  data: DonutData[];
}

const DonutChart: React.FC<DonutChartProps> = ({ data }) => {
  // State
  const [hovered, setHovered] = useState<string | null>(null);

  // Derived  variables
  const total = useMemo(
    () => data.reduce((acc, item) => acc + item.count, 0),
    [data]
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: data.map((d) => d.status),
        range: COLORS,
      }),
    [data]
  );

  // Events handlers
  const handleMouseEnter = (status: string) => () => {
    setHovered(status);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  return (
    <ParentSize>
      {({ width, height }) => {
        const innerWidth = width;
        const innerHeight = height;
        const radius = Math.min(innerWidth, innerHeight) / 2;
        const centerY = innerHeight / 2;
        const centerX = innerWidth / 2;
        const donutThickness = 15;

        return (
          <svg width={width} height={height}>
            <Group top={centerY} left={centerX}>
              <Pie
                data={data}
                pieValue={(data) => data.count}
                outerRadius={radius - innerWidth / 8}
                innerRadius={(arc) =>
                  hovered === arc.data.status
                    ? radius - 10
                    : radius - donutThickness
                }
                cornerRadius={2}
                padAngle={0.005}
              >
                {(pie) =>
                  pie.arcs.map((arc) => {
                    const [centroidX, centroidY] = pie.path.centroid(arc);
                    const opacity =
                      hovered === arc.data.status || hovered === null ? 1 : 0.5;

                    return (
                      <animated.g
                        key={`arc-${arc.data.status}`}
                        onMouseEnter={handleMouseEnter(arc.data.status)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          transformOrigin: `${centroidX}px ${centroidY}px`,
                        }}
                      >
                        <animated.path
                          d={pie.path(arc) || ""}
                          fill={colorScale(arc.data.status)}
                          className={styles.animated}
                          style={{ opacity }}
                        />
                      </animated.g>
                    );
                  })
                }
              </Pie>

              <text textAnchor="middle" fill="#000000" fontSize={22} y={5}>
                {hovered
                  ? `${data.find((d) => d.status === hovered)?.count}`
                  : total}
              </text>
            </Group>
          </svg>
        );
      }}
    </ParentSize>
  );
};

export default DonutChart;
