export const getMonthsBetweenDates = (startDate: Date, endDate: Date) => {
  const months = [];
  const endTime = endDate.getTime();

  const currentMonth = new Date(startDate);
  currentMonth.setHours(0, 0, 0, 0);
  currentMonth.setDate(1);

  while (currentMonth.getTime() <= endTime) {
    months.push(new Date(currentMonth));
    currentMonth.setMonth(currentMonth.getMonth() + 1);
  }

  return months;
};
