import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { DomainUpdateDto } from "_sredx/types";
import { ClientApi } from "../clientApi";

export const useUpdateDomain = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEYS.updateDomain],
    mutationFn: (domainUpdateDto: DomainUpdateDto) => {
      return ClientApi.put(
        API_ENDPOINTS.updateDomain(domainUpdateDto.id),
        domainUpdateDto
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.domains], {
        exact: false,
      });
    },
  });
};
