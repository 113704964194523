const ClusterDetailsToolsSkeleton = () => {
    return (
        <div className="p-6 bg-gray-100">

            <div className="w-1/4 h-6 mb-6 bg-gray-300 animate-pulse"></div>

            <div className="grid grid-cols-3 gap-6 mb-6">
                <div className="h-32 bg-gray-300 animate-pulse"></div>
                <div className="h-32 bg-gray-300 animate-pulse"></div>
                <div className="h-32 bg-gray-300 animate-pulse"></div>
            </div>

            <div className="w-1/4 h-6 mb-6 bg-gray-300 animate-pulse"></div>

            <div className="grid grid-cols-3 gap-6 mb-6">
                <div className="h-32 bg-gray-300 animate-pulse"></div>
                <div className="h-32 bg-gray-300 animate-pulse"></div>
                <div className="h-32 bg-gray-300 animate-pulse"></div>
            </div>

        </div>
    );
}

export default ClusterDetailsToolsSkeleton;
