import { PULL_REQUESTS_KEYS } from "_sredx/types";
import {
  AdvancedFilterValue,
  FILTER_OPERATORS,
} from "_sredx/components/AdvancedFilters/types";
import { startOfDay, subMonths } from "date-fns";
import { useState } from "react";
import { useGetFilteredPullRequests } from "_sredx/services";
import {
  FILTER_CONFIG,
  filterValueValidator,
  getFilterByDateKey,
  getOptionsByKey,
  getPeriodFromFilters,
  mapFilterValueToPRFilterDto,
} from "./utils";
import { AdvancedFilters } from "_sredx/components/AdvancedFilters";
import {
  PRChangesWidget,
  PRCycleTimeWidget,
  PRMergeFrequencyWidget,
} from "_sredx/components/PullRequest";
import { useDebounce } from "_sredx/hooks";

export const PullRequestMetricsContainer = () => {
  const initialFilters = [
    {
      key: PULL_REQUESTS_KEYS.MERGED_AT,
      operator: FILTER_OPERATORS.FROM,
      value: [startOfDay(subMonths(new Date(), 1)), new Date()],
    },
  ];
  const [filters, setFilters] = useState<AdvancedFilterValue[]>(initialFilters);

  const debouncedFilters = useDebounce(filters, 500);

  const period = getPeriodFromFilters(debouncedFilters);
  const filterByDateKey = getFilterByDateKey(debouncedFilters);

  const { data, isLoading } = useGetFilteredPullRequests({
    filters: mapFilterValueToPRFilterDto(debouncedFilters),
  });

  const handleOnChangeFilters = (filters: AdvancedFilterValue[]) => {
    if (filterValueValidator(filters)) {
      setFilters(filters);
    }
  };

  return (
    <>
      <AdvancedFilters
        config={FILTER_CONFIG}
        initialValue={initialFilters}
        getOptionsByKey={getOptionsByKey}
        onChange={handleOnChangeFilters}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginTop: "1rem",
          marginBottom: "4rem",
        }}
      >
        <PRMergeFrequencyWidget
          period={period}
          pullRequests={data}
          isLoading={isLoading}
          filterByDateKey={filterByDateKey}
        />
        <PRCycleTimeWidget
          period={period}
          pullRequests={data}
          isLoading={isLoading}
          filterByDateKey={filterByDateKey}
        />
        <PRChangesWidget
          pullRequests={data}
          period={period}
          isLoading={isLoading}
          filterByDateKey={filterByDateKey}
        />
      </div>
    </>
  );
};
