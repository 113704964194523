import ProgressBar from "../../shared/ProgressBar/ProgressBar";

interface AverageScoreComponentProps {
  totalScore: number;
  totalPossibleScore: number;
}

const AverageScoreComponent: React.FC<AverageScoreComponentProps> = ({
  totalScore,
  totalPossibleScore
}) => {
  let percentage = 0;

  if (totalPossibleScore !== 0) {
    percentage = (totalScore / totalPossibleScore) * 100;
  }

  return (
    <div>
      <div className="flex justify-between">
        <div className="font-light text-sm">Total score</div>
        <div className="font-bold text-sm">{totalScore}/{totalPossibleScore}</div>
      </div>
      <div>
        <div className="w-full bg-gray-200">
          <ProgressBar statusTheme="success" percentage={percentage} />
        </div>
      </div>
    </div>
  );
};

export default AverageScoreComponent;

