import * as RadixPopover from "@radix-ui/react-popover";
import styles from "./Popover.module.css";
import clsx from "clsx";
import { ChevronDownIcon, ChevronUpIcon } from "../Icons";

export const PopoverTrigger = ({
  children,
  className,
  onClick,
  open = false,
  disabled = false,
}: React.PropsWithChildren<{
  className?: string;
  onClick?: () => void;
  open?: boolean;
  disabled?: boolean;
}>) => {
  return (
    <RadixPopover.Trigger
      onClick={onClick}
      className={clsx(styles.trigger, disabled && styles.disabled, className)}
      disabled={disabled}
    >
      <span>{children}</span>
      {open ? <ChevronUpIcon size={16} /> : <ChevronDownIcon size={16} />}
    </RadixPopover.Trigger>
  );
};

export const PopoverContent = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <RadixPopover.Content
      align={"start"}
      sideOffset={8}
      className={clsx(styles.content, className)}
    >
      {children}
    </RadixPopover.Content>
  );
};

export const PopoverItem = ({
  children,
  onClick,
  selected = false,
  className,
}: React.PropsWithChildren<{
  onClick: () => void;
  selected?: boolean;
  className?: string;
}>) => {
  return (
    <div
      className={clsx(styles.item, selected && styles.selected, className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

interface PopoverProps {
  open?: boolean;
  onOpenChange?: (val: boolean) => void;
}

export const Popover = ({
  children,
  open = false,
  onOpenChange,
}: React.PropsWithChildren<PopoverProps>) => {
  return (
    <RadixPopover.Root onOpenChange={onOpenChange} open={open}>
      {children}
    </RadixPopover.Root>
  );
};
