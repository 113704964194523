import { ServiceProvider } from "types/discovery";
import styles from "./styles.module.css";
import { ServiceProviderKeyType } from "containers/ServiceAddContainer";
import { CheckIcon } from "@heroicons/react/outline";

interface ExternalServiceProviderCardProps {
  onClick: () => void;
  serviceProvider: ServiceProvider;
  selectedServiceProvider?: ServiceProviderKeyType;
}

export const ExternalServiceProviderCard = ({
  onClick,
  serviceProvider,
  selectedServiceProvider,
}: ExternalServiceProviderCardProps) => {
  const ProviderIcon = serviceProvider.iconComponent;
  return (
    <div
      onClick={onClick}
      className={`${styles.serviceProviderCard} ${
        serviceProvider.supported && serviceProvider.configured
          ? serviceProvider.key === selectedServiceProvider
            ? `${styles.selected} ${styles.enabled}`
            : `${styles.hover}  ${styles.enabled}`
          : styles.disabled
      }`}
    >
      <div className={styles.leftSection}>
        {ProviderIcon && (
          <div className={styles.icon_wrapper}>
            <ProviderIcon />
          </div>
        )}
        <span className={styles.serviceProviderName}>
          {serviceProvider.name}
        </span>
      </div>
      {!serviceProvider.supported && (
        <Badge type="disabled" text="Coming soon" />
      )}
      {serviceProvider.supported && serviceProvider.configured && (
        <Badge type="success" text="Configured" />
      )}
      {serviceProvider.supported && !serviceProvider.configured && (
        <Badge type="disabled" text="Not Configured" />
      )}
    </div>
  );
};

const Badge = ({
  type,
  text,
}: {
  type: "success" | "disabled";
  text: string;
}) => {
  const resolveColor = () => {
    switch (type) {
      case "success":
        return (
          <div className="flex items-center">
            <div
              className={`text-xs  text-green-600 flex items-center px-2 gap-2`}
            >
              <CheckIcon className={`h-4 w-4`} />
              {text}
            </div>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <div
              className={`text-xs text-slate-600 flex items-center px-2 gap-2`}
            >
              {text}
            </div>
          </div>
        );
    }
  };
  return <div className="flex items-center">{resolveColor()}</div>;
};
