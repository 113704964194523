import { BsGraphDownArrow, BsGraphUpArrow } from "react-icons/bs";
import styles from "./ServicesDropRiseList.module.css";
import clsx from "clsx";
import { useEffect, useState } from "react";

export const ServicesDropRiseList = ({
  data,
  sort = "DESC",
}: {
  data: { totalPercentageDifference: number; serviceName: string }[];
  sort?: "DESC" | "ASC";
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const [displayedData, setDisplayedData] = useState<
    { totalPercentageDifference: number; serviceName: string }[]
  >([]);

  useEffect(() => {
    if (sort === "ASC") {
      const sortedData = data.sort(
        (a, b) => a.totalPercentageDifference - b.totalPercentageDifference
      );

      if (expanded) {
        setDisplayedData(sortedData);
      } else {
        setDisplayedData(sortedData.slice(0, 10));
      }
    }
    if (sort === "DESC") {
      const sortedData = data.sort(
        (b, a) => a.totalPercentageDifference - b.totalPercentageDifference
      );

      if (expanded) {
        setDisplayedData(sortedData);
      } else {
        setDisplayedData(sortedData.slice(0, 10));
      }
    }
  }, [data, sort, expanded]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.services_list}>
        {displayedData.map((service) => (
          <div key={service.serviceName} className={styles.service_row}>
            <div>{service.serviceName}</div>
            {resolvePercentage(service.totalPercentageDifference)}
          </div>
        ))}
        <div className={styles.show_more}>
          <button onClick={handleExpand}>
            {expanded ? "Show Less" : "Show More"} 
          </button>
        </div>
      </div>
    </div>
  );
};

export const resolvePercentage = (percentage: number) => {
  if (percentage > 0) {
    return (
      <span className={clsx(styles.trend, styles.red)}>
        <BsGraphDownArrow />
        {Math.abs(percentage * 100).toFixed(3)}%
      </span>
    );
  }
  if (percentage < 0) {
    return (
      <span className={clsx(styles.trend, styles.green)}>
        <BsGraphUpArrow />
        {Math.abs(percentage * 100).toFixed(3)}%
      </span>
    );
  }
  return <div>{percentage * 100}%</div>;
};
