import { Avatar, Button, ButtonIcon, Text } from "_sredx/_ui";
import { TrashIcon } from "_sredx/_ui/Icon";
import { Member, TeamMemberRoleType } from "_sredx/types";
import { MemberListAction } from "../types";
import styles from "./MemberRow.module.css";
import { isEmpty } from "_sredx/utils";
import {
  Select,
  SelectTrigger,
  SelectItem,
  SelectContent,
} from "_sredx/_ui/Select";
import { ROLE_OPTIONS } from "./constants";

interface MemberRowProps {
  member: Member;
  onAction: (action: MemberListAction) => void;
}

export const MemberRow = ({ member, onAction }: MemberRowProps) => {
  // Derived variables
  const fullName = !isEmpty(member.firstName)
    ? `${member.firstName} ${member.lastName}`
    : undefined;
  const isInactiveUser = member.status === "INACTIVE";

  // Event handlers
  const handleChangeRole = (role: string) => {
    onAction({
      type: "CHANGE_ROLE",
      payload: {
        memberEmail: member.email,
        role: role as TeamMemberRoleType,
      },
    });
  };

  const handleDeleteClicked = () => {
    onAction({
      type: "DELETE_MEMBER",
      payload: {
        memberEmail: member.email,
      },
    });
  };

  // Ui waterfalls
  return (
    <div className={styles.wrapper}>
      <div className={styles.member_wrapper}>
        <Avatar size={28} name={fullName ?? member.email} />
        <div className={styles.member_info}>
          <Text>{fullName}</Text>
          <Text size={"sm"}>{member.email}</Text>
          {!member.status && (
            <Text size={"sm"} className={styles.status}>
              Invitation will be sent
            </Text>
          )}
          {isInactiveUser && (
            <Text size={"sm"} className={styles.status}>
              Inactive User
            </Text>
          )}
        </div>
      </div>

      <div className={styles.actions}>
        <Select onChange={handleChangeRole} defaultValue={member.role}>
          <SelectTrigger placeholder={"select role"} />
          <SelectContent>
            {ROLE_OPTIONS.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Button onClick={handleDeleteClicked} variant={"neutral"} ghost>
          <ButtonIcon>
            <TrashIcon />
          </ButtonIcon>
        </Button>
      </div>
    </div>
  );
};
