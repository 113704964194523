import {Disclosure} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import Box from "../../octo-ui/Box/Box";
import Header from "../../octo-ui/Header/Header";
import MobileHamburgerButton from "./modules/MobileHamburgerButton";
import UserMenu from "./modules/UserMenu";
import AdminMobilePanel from "./modules/AdminMobilePanel";
import AdminAppMenu from "./modules/AdminAppMenu";
import SredxLogo from "components/Landing/header/SredxLogo";

const AdminAppHeader = () => {
    const [user, setUser] = useState<any>(null);
    const {keycloak, initialized} = useKeycloak();
    const {logout} = keycloak;

    useEffect(() => {
        if (!initialized) {
            return;
        }

        const fetchUserinfo = async () => {
            const result = await keycloak.loadUserInfo();
            setUser(result);
        };

        if (keycloak.authenticated) {
            fetchUserinfo();
        }
    }, [keycloak, initialized]);

    return (
        <Disclosure as="nav" className="bg-black" data-testid="app-header">
            {({open}) => (
                <>
                    <Header>
                        <Box className="flex items-center">
                            <SredxLogo className="mt-1 mr-12"/>
                            <AdminAppMenu/>
                        </Box>
                        <Box className="flex gap-4">
                            <Box className="hidden md:block">
                                <Box className="flex items-center">
                                    {/* Profile dropdown */}
                                    <UserMenu logout={logout}/>
                                </Box>
                            </Box>
                        </Box>
                        {/* Mobile Menu hamburger */}
                        <MobileHamburgerButton open={open}/>
                    </Header>
                    <AdminMobilePanel user={user} logout={logout}/>
                </>
            )}
        </Disclosure>
    );
};

export default AdminAppHeader;