import {
  CalendarIcon,
  CloudIcon,
  CloudUploadIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { LogoText } from "_sredx/components/ClusterCard/LogoText";
import { Cluster } from "_sredx/types";
import { BiGitBranch, BiLogoKubernetes } from "react-icons/bi";
import { FaNetworkWired } from "react-icons/fa";
import { HiCubeTransparent } from "react-icons/hi2";
import { PiHexagon } from "react-icons/pi";

export const resolveClusterProperties = (cluster: Cluster) => {
  return [
    {
      icon: CalendarIcon,
      key: "Last Update",
      value: cluster.age,
    },
    {
      icon: CloudIcon,
      key: "Provider",
      value: LogoText(cluster.cloudProvider),
    },
    {
      icon: UserIcon,
      key: "Cloud Account",
      value: cluster.accountName,
    },
    {
      icon: BiGitBranch,
      key: "Gitops",
      value: cluster.repoName,
      link: cluster.repoUrl,
    },
    {
      icon: CloudUploadIcon,
      key: "CD Tool",
      value: LogoText(cluster.cloudProvider),
    },
  ];
};
export const resolveClusterBadges = (cluster: Cluster) => {
  return [
    {
      icon: BiLogoKubernetes,
      key: "Kubernetes version",
      value: cluster.version,
    },
    {
      icon: PiHexagon,
      key: "Node Type",
      value: cluster.nodeType,
    },
    {
      icon: HiCubeTransparent,
      key: "Pool Size",
      value: cluster.size.toString(),
    },
    {
      icon: FaNetworkWired,
      key: "CNI Type",
      value: cluster.cni,
    },
  ];
};
