import { useKeycloak } from "@react-keycloak/web";
import { useAuth } from "_sredx/auth";
import { LoadingPage } from "pages/loading";
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { LOGIN_BASE_PATH } from "routes/paths";

interface PrivateRouteProps {
  component?: React.ReactElement;
}

export const PrivateRoute = ({ component }: PrivateRouteProps) => {
  const { isAuthenticated, signinRedirect, isLoading } = useAuth();
  const { keycloak, initialized } = useKeycloak();
  const { authenticated } = keycloak;
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      void signinRedirect({
        redirect_uri: window.location.href,
      });
    }
    if (initialized && !authenticated) {
      <Navigate
        to={{ pathname: LOGIN_BASE_PATH }}
        state={{ from: location }}
        replace={true}
      />;
    }
  }, [isAuthenticated, isLoading, signinRedirect]);

  if (isLoading || !initialized) return <LoadingPage />;

  return <>{component}</>;
};
