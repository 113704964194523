export interface ScoreCardsHealthDto {
  name: string;
  serviceHealths: {
    count: number;
    status: string;
  }[];
}
export const Keys = [
  "PASSING",
  "PROGRESSING",
  "NEEDS ATTENTION",
  "FAILING",
] as const;

export type ScoreCardsHealthKeys = (typeof Keys)[number];
