import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { DomainCreationDto } from "_sredx/types";
import { ClientApi } from "../clientApi";

export const useCreateDomain = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEYS.createDomain],
    mutationFn: (domainCreationDto: DomainCreationDto) => {
      return ClientApi.post(API_ENDPOINTS.createDomain(), domainCreationDto);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.domains], {
        exact: false,
      });
      await queryClient.invalidateQueries([QUERY_KEYS.infiniteDomains], {
        exact: false,
      });
    },
  });
};
