import {StepType} from "types/Stepper";


export const SCORECARD_CREATION_STEP: StepType[] = [
    {
        stepKey: "MAIN_INFOS",
        order: 1,
        description: "Main infos",
    },
    {
        stepKey: "RULES",
        order: 2,
        description: "Rules",
    },
    {
        stepKey: "LEVELS",
        order: 3,
        description: "Levels",
    },
]