import {StepperContext} from "context/StepperContext";
import {useContext} from "react";
import {UseStepperReturn} from "types/Stepper";

export const useStepperContext = (): UseStepperReturn => {
    const context = useContext(StepperContext);

    if (!context) {
        throw new Error("useStepperContext must be used within a StepperProvider");
    }
    return context;
};
