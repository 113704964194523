import { useModal } from "_sredx/hooks";
import { Dialog, DialogContent } from "_sredx/_ui";

export const ModalContainer = () => {
  const { isModalOpen, modalContent } = useModal();
  return (
    <Dialog open={isModalOpen}>
      <DialogContent>{modalContent}</DialogContent>
    </Dialog>
  );
};
