import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import {
  IntegrationInstanceDto, IntegrationInstanceSearchingCriteria,
} from "_sredx/types";
import { useQuery } from "react-query";

interface useGetIntegrationInstancesProps {
  params: IntegrationInstanceSearchingCriteria;
}

export const useGetIntegrationInstances = ({ params }: useGetIntegrationInstancesProps) => {
  return useQuery<IntegrationInstanceDto[]>({
    queryKey: [QUERY_KEYS.integrationInstances, params],
    queryFn: () =>
      ClientApi.get(API_ENDPOINTS.getIntegrationInstances(), {
        params,
      }),
  });
};
