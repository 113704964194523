import React from 'react';
import style from "./TitleStyles.module.css";
import collection from "./collection.svg";
import Tag from '../tag/Tag';
import "styles/fonts.css"
import clsx from 'clsx';

interface TilteProps {
    name : string,
    tags : {name : string, color : string}[]
}


export const Title = ({name, tags}: TilteProps) => {
  return (

    <div className={style.service_title_section} >
          <img src={collection} alt=''/>
          <div>
            <span className={clsx("bold", style.service_name) } >{name}</span> 
            <div className={style.service_tags }>
                {tags.map((tag, index )=> <Tag key={index} tag={tag.name} color={tag.color}/>)}
            </div>
          </div>
        </div>
  )
}
