import { ReactNode } from "react";

export const DOMAIN_ROW_ACTIONS_TYPE = {
  UPDATE: "UPDATE",
  DELETE: "DELETE",
} as const;

export type DomainRowActionType = keyof typeof DOMAIN_ROW_ACTIONS_TYPE;

export interface DomainRowAction {
  type: DomainRowActionType;
  component: ReactNode;
}
