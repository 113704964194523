import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "_sredx/_ui";
import { ServicesList, ServicesListAction } from "../ServicesList";
import { ResourceService } from "_sredx/containers/ResourcesCreationContainer/utils";

interface ResourceCreationServiceFormProps {
  onAction: (action: ServicesListAction) => void;

  services: ResourceService[];
}

export const ResourceCreationServiceForm = ({
  onAction,
  services,
}: ResourceCreationServiceFormProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Resource Services</CardTitle>
        <CardSubtitle>Associate services to this resource</CardSubtitle>
      </CardHeader>
      <CardBody noPadding>
        <ServicesList
          services={services}
          onAction={onAction}
          emptyMessage={"This Resource has no services"}
          withAddAction
        />
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
};
