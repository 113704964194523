import { Label } from "_sredx/_ui";
import { TagsInput } from "_sredx/_ui/TagsInput";
import {
  FormFieldInput,
  FormFieldSelect,
  FormFieldTextArea,
} from "_sredx/components/Form";
import { DomainSelectContainer } from "_sredx/containers";
import { TeamSelectContainer } from "_sredx/containers/TeamSelectContainer";
import { DomainBaseDto, TeamBaseDto } from "_sredx/types";
import { ResourceDto } from "_sredx/types/resources";
import { FormField } from "components/basic/FormField";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./styles.module.css";
import { getIntegrationInstancesOptions, resolveAnnotationKeys, resolveLinkedIntegrations } from "./utils";
import { useGetIntegrationInstances } from "_sredx/services";
import { useGetIntegrationsStatus } from "_sredx/services/integrations";
import { ResourceSelectorContainer } from "_sredx/containers/ResourceSelectorContainer";

export const ServiceFormFields = () => {
  const { data: integrationsStatus } = useGetIntegrationsStatus();
  const methods = useFormContext<{
    name: string;
    tag: string;
    description: string;
    owners: { name: string; id: string }[];
    domains: { name: string; id: string }[];
    resources: { name: string; id: string; type: string }[];
    metadata: {
      tags: string[];
      annotations: { [key: string]: string };
    };
    toolsInput: { [key: string]: string };
  }>();

  const defaultIntegrations = resolveLinkedIntegrations(
    methods.getValues("metadata.annotations")
  );
  const [selectedIntegrations, setSelectedIntegrations] =
    useState<string[]>(defaultIntegrations);

  const { data: integrationInstances } = useGetIntegrationInstances({
    params: {
      integrationKeys: selectedIntegrations,
    },
  });
  const configuredIntegrations = useMemo(() => {
    if (!integrationsStatus) {
      return [];
    }
    return integrationsStatus.filter((integrationStatus) => integrationStatus.configured)
  }, [integrationsStatus])

  const handleSelectIntegration = (integration: {
    key: string;
    configured: boolean;
  }) => {
    if (!integration.configured) return;
    //unselect github if gitlab is selected and vice versa
    if (
      selectedIntegrations.includes("github") &&
      integration.key === "gitlab"
    ) {
      setSelectedIntegrations((prev) => prev.filter((i) => i !== "github"));
    }
    if (
      selectedIntegrations.includes("gitlab") &&
      integration.key === "github"
    ) {
      setSelectedIntegrations((prev) => prev.filter((i) => i !== "gitlab"));
    }
    if (selectedIntegrations.includes(integration.key)) {
      setSelectedIntegrations(
        selectedIntegrations.filter((i) => i !== integration.key)
      );
    } else {
      setSelectedIntegrations((prev) => [...prev, integration.key]);
    }
  };

  return (
    <div className="flex">
      <div className={styles.inputsContainer}>
        <FormFieldInput
          control={methods.control}
          name="name"
          label="Name"
          id="name"
          errorMessage={methods.formState.errors.name?.message}
        />
        <FormFieldInput
          control={methods.control}
          label="Tag"
          placeholder="Service tag ..."
          id="tag"
          errorMessage={methods.formState.errors.tag?.message}
          name="tag"
        />
        <FormFieldTextArea
          control={methods.control}
          name="description"
          label="Description"
          id="description"
          placeholder="Service description ..."
          errorMessage={methods.formState.errors.description?.message}
        />

        <div className="flex flex-col">
          <Label>Teams</Label>
          <FormField
            name="owners"
            id="owners"
            component={TeamSelectContainer}
            onChange={(teams: TeamBaseDto[]) => {
              methods.setValue(
                "owners",
                teams.map((team) => ({ name: team.name, id: team.id }))
              );
            }}
            fullWidth
            countIndicator={false}
            initialValue={methods.getValues("owners")}
          />
        </div>

        <div className="flex flex-col">
          <Label>Domains</Label>
          <FormField
            name="domains"
            id="domains"
            component={DomainSelectContainer}
            onChange={(domains: DomainBaseDto[]) => {
              methods.setValue(
                "domains",
                domains.map((domain) => ({ name: domain.name, id: domain.id }))
              );
            }}
            fullWidth
            countIndicator={false}
            initialValue={methods.getValues("domains")}
          />
        </div>

        <div className="flex flex-col">
          <Label>Resources</Label>
          <FormField
            name="resources"
            id="resources"
            component={ResourceSelectorContainer}
            onChange={(resources: ResourceDto[]) => {
              methods.setValue("resources", resources);
            }}
            fullWidth
            countIndicator={false}
            initialValue={methods.getValues("resources")}
          />
        </div>

        <FormField
          id="metadata.tags"
          component={TagsInput}
          name="metadata.tags"
          placeholder="Service tags, separated by a comma..."
          label="tags"
        />
      </div>
      <div className="border-l mx-6" />
      <div className="w-96">
        <div className={styles.inputsContainer}>
          <h3>Link Integrations</h3>

          <div className="flex flex-wrap gap-2">
            {/* configured integrations */}
            {configuredIntegrations.map((integration, index) => {
              return (
                <div
                  key={index}
                  className={`border p-2 ${integration.configured
                    ? "cursor-pointer hover:bg-black hover:text-white transition"
                    : "bg-gray-200"
                    }
                    ${selectedIntegrations.includes(integration.key)
                      ? "bg-black text-white"
                      : ""
                    }
                    `}
                  onClick={() => handleSelectIntegration(integration)}
                >
                  {integration.key}
                </div>
              );
            })}
          </div>
        </div>
        {selectedIntegrations.map((integration, index) => {
          const annotations = resolveAnnotationKeys(integration);
          return (
            <div key={`${integration} - ${index}`}>
              <FormFieldInput
                control={methods.control}
                id={`toolsInput.${integration}`}
                name={`toolsInput.${integration}`}
                errorMessage={
                  methods.formState.errors.toolsInput?.[integration]?.message
                }
                label={`Url for ${integration} service`}
                placeholder={`Url for ${integration} service`}
              />
              {annotations?.map((annotation, i) => {
                if (!annotation.value) {
                  switch (annotation.type) {
                    case "select":
                      return (
                        <FormFieldSelect
                          control={methods.control}
                          key={`${annotation.key}-${i}`}
                          name={`metadata.annotations.${annotation.key}`}
                          options={getIntegrationInstancesOptions({
                            integrationKey: integration,
                            integrationInstances: integrationInstances
                          })}
                          variant={"outlined"}
                          errorMessage={
                            methods.formState.errors.metadata?.annotations?.[
                              annotation.key
                            ]?.message
                          }
                          label={annotation.label}
                          placeholder={annotation.placeholder}
                        />
                      );
                    case "text":
                      return (
                        <FormFieldInput
                          control={methods.control}
                          key={`${annotation.key}-${i}`}
                          id={`metadata.annotations.${annotation.key}`}
                          name={`metadata.annotations.${annotation.key}`}
                          errorMessage={
                            methods.formState.errors.metadata?.annotations?.[
                              annotation.key
                            ]?.message
                          }
                          label={annotation.label}
                          placeholder={annotation.placeholder}
                        />
                      );
                    default:
                      return <></>;
                  }
                }
                methods.setValue(
                  `metadata.annotations.${annotation.key}`,
                  annotation.value
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
