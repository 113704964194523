export const hoursToAxisLabel = (hours: number): string => {
  if (hours < 1) {
    const minutes = Math.round(hours * 60);
    return `${minutes} m`;
  }
  
  if (hours < 24) {
    const roundedHours = Math.floor(hours);
    const minutes = Math.round((hours - roundedHours) * 60);
    return minutes > 0 ? `${roundedHours} h ${minutes} m` : `${roundedHours} h`;
  }

  const days = Math.floor(hours / 24);
  const remainingHours = Math.floor(hours % 24);

  return remainingHours > 0 
    ? `${days} d ${remainingHours} h`
    : `${days} d`;
};