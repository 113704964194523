import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { isEmpty } from "_sredx/utils";
import { useQuery } from "react-query";
import { ClientApi } from "../clientApi";
import { IntegrationInstanceDto } from "_sredx/types/instances";

interface UseGetIntegrationInstancesParams {
  integrationKeys: string[] | null;
}

export const UseGetIntegrationInstances = ({
  integrationKeys,
}: UseGetIntegrationInstancesParams) => {
  return useQuery<IntegrationInstanceDto[]>({
    queryKey: [QUERY_KEYS.integration_instances, integrationKeys],
    queryFn: async () =>
      ClientApi.get(API_ENDPOINTS.getIntegrationInstances(), {
        params: {
          integrationKeys: integrationKeys,
        },
      }),
    enabled: !!integrationKeys && !isEmpty(integrationKeys),
  });
};
