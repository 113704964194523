import clsx from "clsx";
import styles from "./ScoreCardDetailsOverview.module.css";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Heading,
  Text,
} from "_sredx/_ui";
import ScoreCardOverview from "models/scorecard/ScoreCardOverview";
import ScoreCard from "models/scorecard/ScoreCard";

interface ScoreCardDetailsOverviewProps {
  scoreCard: ScoreCard;
  scoreCardOverview: ScoreCardOverview;
}

export const ScoreCardDetailsOverview = ({
  scoreCard,
  scoreCardOverview,
}: ScoreCardDetailsOverviewProps) => {
  return (
    <div className={clsx(styles.wrapper)}>
      <Card>
        <CardHeader>
          <CardTitle>score</CardTitle>
          <CardSubtitle>Overall average score</CardSubtitle>
        </CardHeader>
        <CardBody>
          <div className={styles.body_wrapper}>
            <Heading>{scoreCardOverview.averageScore.toFixed(2)}</Heading>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>passing services</CardTitle>
          <CardSubtitle>Total number of passing services</CardSubtitle>
        </CardHeader>
        <CardBody>
          <div className={styles.body_wrapper}>
            <Heading>
              {scoreCardOverview.passingServices} /{" "}
              {scoreCardOverview.totalServices}
            </Heading>
            <Text>services</Text>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>rules</CardTitle>
          <CardSubtitle>Total number of rules</CardSubtitle>
        </CardHeader>
        <CardBody>
          <div className={styles.body_wrapper}>
            <Heading>{scoreCard.rules ? scoreCard.rules.length : 0}</Heading>
            <Text>rules</Text>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
