import React, { useState } from "react";
import Service, { ServiceInput } from "models/service/Service";
import { AddServiceForm } from "../../../components/service/ServiceForm/AddServiceForm";
import { ServiceAddBody } from "..";
import { ServiceCard } from "components/service/ServiceCard/ServiceCard";
import styles from "./styles.module.css";
import { useModal } from "_sredx/hooks";

interface ServiceCreationStepProps {
  importedServices: ServiceInput[];
  onNextStep: (services: ServiceInput[]) => void;
  onPreviousStep: () => void;
}

export const ServiceCreationStepContainer = ({
  importedServices,
  onNextStep,
  onPreviousStep,
}: ServiceCreationStepProps) => {
  const [services, setServices] = useState<ServiceInput[]>(importedServices);
  const { openModal, closeModal } = useModal();

  const Actions = () => {
    return (
      <div className="flex gap-4">
        <button className={styles.prevButton} onClick={onPreviousStep}>
          Previous
        </button>
        <button
          disabled={services.length < 1}
          className={`${styles.nextButton} ${services.length > 0
            ? styles.nextButtonValid
            : styles.nextButtonInvalid
            }`}
          onClick={() => {
            if (services.length > 0) onNextStep(services);
          }}
        >
          Next
        </button>
      </div>
    );
  };
  const handleAddService = () => {
    openModal({
      content: (
        <AddServiceForm
          setServices={setServices}
          services={services}
          hide={closeModal}
        />
      ),
    });
  };

  return (
    <ServiceAddBody actions={<Actions />}>
      <h1>Add Services</h1>
      <div className={styles.servicesGrid}>
        {services.map((service, i) => {
          return (
            <ServiceCard
              key={i}
              service={service as Service}
              onEdit={(service) => {
                openModal({
                  content: (
                    <AddServiceForm
                      setServices={setServices}
                      services={services}
                      hide={closeModal}
                      defaultValues={service}
                    />
                  ),
                });
              }}
              onDelete={(service) => {
                setServices((prev) =>
                  prev.filter((prevService) => prevService.tag !== service.tag)
                );
              }}
            />
          );
        })}

        <button
          className={styles.addService}
          style={{
            height: "240px",
          }}
          onClick={handleAddService}
        >
          <span className={styles.button}>Add Service</span>
        </button>
      </div>
    </ServiceAddBody>
  );
};
