import {
  Button,
  ButtonLabel,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Input,
} from "_sredx/_ui";
import { ServicesAssignmentTable } from "_sredx/components";
import { API_FIRST_PAGE, API_PAGE_SIZE_DEFAULT } from "_sredx/constants/api";
import { useDebounce } from "_sredx/hooks";
import { useGetServices } from "_sredx/services";
import { ServiceBaseDto, ServiceSearchCriteria } from "_sredx/types";
import { useEffect, useState } from "react";
import styles from "./ServicesAssignmentContainer.module.css";

interface ServicesAssignmentContainerProps {
  onCancel: () => void;
  onConfirm: (services: ServiceBaseDto[]) => void;
  selectedServices?: ServiceBaseDto[];
}

const SERVICES_SEARCH_CRITERIA_DEFAULT: ServiceSearchCriteria = {
  pageNumber: 1,
  pageSize: API_PAGE_SIZE_DEFAULT,
};

export const ServicesAssignmentContainer = ({
  onCancel,
  onConfirm,
  selectedServices = [],
}: ServicesAssignmentContainerProps) => {
  // State
  const [query, setQuery] = useState<string>("");
  const [checkedServices, setCheckedServices] =
    useState<ServiceBaseDto[]>(selectedServices);
  const [currentPage, setCurrentPage] = useState<number>(API_FIRST_PAGE);

  // hooks
  const debouncedQuery = useDebounce(query, 400);

  // Data fetching
  const {
    data: servicesData,
    isLoading: isServicesLoading,
    isFetching: isServicesFetching,
  } = useGetServices({
    params: {
      ...SERVICES_SEARCH_CRITERIA_DEFAULT,
      name: debouncedQuery,
      pageNumber: currentPage,
    },
  });

  // Derived Variables
  const services = (servicesData && servicesData.content) || [];

  // Event handlers
  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCurrentPage(API_FIRST_PAGE);
    setQuery(value);
  };

  const handleCheckAll = (checkAll: boolean) => {
    if (checkAll && servicesData?.pageNo == currentPage) {
      setCheckedServices((prev) => [...prev, ...services]);
    } else if (!checkAll && servicesData?.pageNo == currentPage) {
      setCheckedServices((prev) =>
        prev.filter((service) => !services.find((s) => s.id == service.id))
      );
    }
  };

  const handleOnCheckChange = (checked: boolean, serviceId: string) => {
    if (checked) {
      const service = services.find((s) => s.id === serviceId);
      if (service) {
        setCheckedServices([...checkedServices, service]);
      }
    } else {
      setCheckedServices(checkedServices.filter((s) => s.id !== serviceId));
    }
  };

  const handleOnConfirm = () => {
    onConfirm(checkedServices);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Add services</CardTitle>
        <CardSubtitle>search and add services</CardSubtitle>
      </CardHeader>
      <CardBody noPadding>
        <div className={styles.search_wrapper}>
          <Input
            placeholder={"Search services"}
            value={query}
            onChange={handleOnSearch}
            fullWidth
          />
        </div>
        <div className={styles.table_wrapper}>
          <ServicesAssignmentTable
            services={services}
            onCheckAll={handleCheckAll}
            checkedServices={checkedServices.map((s) => s.id)}
            onCheckChange={handleOnCheckChange}
            isLoading={isServicesLoading}
            isFetching={isServicesFetching}
            currentPage={currentPage}
            totalPages={servicesData?.totalPages}
            onPageChange={setCurrentPage}
            totalServices={servicesData?.totalElements}
          />
        </div>
        <div className={styles.button_wrapper}>
          <Button onClick={onCancel} variant={"neutral"} ghost>
            <ButtonLabel>Cancel</ButtonLabel>
          </Button>
          <Button onClick={handleOnConfirm}>
            <ButtonLabel>Confirm</ButtonLabel>
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};
