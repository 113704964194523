import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { ClientApi } from "_sredx/services/clientApi";
import {
  PagedResponse,
  ServiceRichDto,
  ServiceSearchCriteria,
} from "_sredx/types";
import { useInfiniteQuery } from "react-query";

interface UseInfiniteGetEvaluatedServicesParams {
  params: ServiceSearchCriteria;
}

export const useInfiniteGetEvaluatedServices = ({
  params,
}: UseInfiniteGetEvaluatedServicesParams) => {
  return useInfiniteQuery<PagedResponse<ServiceRichDto>>({
    queryKey: [QUERY_KEYS.infiniteEvaluatedServices, params],
    queryFn: ({ pageParam = 0 }) => {
      return ClientApi.get(API_ENDPOINTS.getEvaluatedServices(), {
        params: {
          ...params,
          pageNumber: pageParam,
        },
      });
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.last) {
        return undefined;
      }
      return lastPage.pageNo + 1;
    },
    refetchOnWindowFocus: false,
  });
};
