import React from "react";

import ScoreCardServicesListComponent from "components/scorecard/ScoreCardDetails/ScoreCardServicesList/ScoreCardServicesListComponent";
import { ScoreCardEvaluation } from "models/evaluations/ScoreCardEvaluation";

import { Empty, Loader, Text } from "_sredx/_ui";
import styles from "./ScoreCardDetailsServices.module.css";

interface ScoreCardDetailsServicesProps {
  isLoading: boolean;
  scorecardId: string;
  scoreCardLastEvaluations: ScoreCardEvaluation[];
}

export const ScoreCardDetailsServices = ({
  isLoading,
  scorecardId,
  scoreCardLastEvaluations,
}: ScoreCardDetailsServicesProps) => {
  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>Loading services...</Text>
      </div>
    );
  }

  if (
    !isLoading &&
    scoreCardLastEvaluations &&
    scoreCardLastEvaluations.length === 0
  ) {
    return <Empty message={"No services found in this scorecard."} />;
  }
  return (
    <ScoreCardServicesListComponent
      scoreCardId={scorecardId}
      scoreCardLastEvaluations={scoreCardLastEvaluations}
    />
  );
};
