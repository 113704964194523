import styles from "./PRDiffStats.module.css";

interface PRDiffStatsProps {
  added: number;
  deleted: number;
}

export const PRDiffStats = ({ added, deleted }: PRDiffStatsProps) => {
  const total = (added || 0) + (deleted || 0);
  const addedPercent = total && added ? (added / total) * 100 : 0;
  const deletedPercent = total && deleted ? (deleted / total) * 100 : 0;

  const nbrOfAddedBars = Math.floor(addedPercent / 20);
  const nbrOfDeletedBars = Math.floor(deletedPercent / 20);
  const nbrOfEmptyBars = 5 - nbrOfAddedBars - nbrOfDeletedBars;

  return (
    <span className={styles.wrapper}>
      <span className={styles.added}>{`+${added || 0}`}</span>
      <span className={styles.deleted}>{`-${deleted || 0}`}</span>
      <span className={styles.blocks}>
        {[...Array(nbrOfAddedBars)].map((_, i) => (
          <span key={i} className={styles.block_added} />
        ))}
        {[...Array(nbrOfDeletedBars)].map((_, i) => (
          <span key={i} className={styles.block_deleted} />
        ))}
        {[...Array(nbrOfEmptyBars)].map((_, i) => (
          <span key={i} className={styles.block_neutral} />
        ))}
      </span>
    </span>
  );
};
