import clsx from "clsx";
import styles from "./ClusterCardStatusIndicator.module.css";

interface ClusterCardStatusIndicatorProps {
  status: "success" | "warning" | "danger" | "unknown";
  label?: string;
  shouldPulse?: boolean;
}

const statusStyles = {
  success: { class: styles.green, label: "success" },
  danger: { class: styles.red, label: "danger" },
  warning: { class: styles.yellow, label: "warning" },
  unknown: { class: styles.gray, label: "unknown" },
};
const renderStatus = (
  status: string,
  statusClass: string,
  applyPulse: boolean
) => {
  switch (status) {
    case "danger":
      return <></>;
    default:
      return (
        <div
          className={clsx(
            styles.status_dot,
            statusClass,
            applyPulse && "after:animate-ping"
          )}
        />
      );
  }
};
export const ClusterCardStatusIndicator = ({
  status,
  label,
  shouldPulse = true,
}: ClusterCardStatusIndicatorProps) => {
  const { class: statusClass } = statusStyles[status];
  const applyPulse = shouldPulse && status !== "unknown";

  return (
    <div className={clsx(styles.wrapper)}>
      {renderStatus(status, statusClass, applyPulse)}
      {label}
    </div>
  );
};
