import { ClockIcon } from "./ClockIcon";
import { UserIcon } from "./UserIcon";
import { PullRequestIcon } from "./PullRequestIcon";
import { CalendarIcon } from "./CalendarIcon";
import { HashtagIcon } from "./HashtagIcon";

export const PropertyIcon = ({ iconName }: { iconName: string }) => {
  const size = 16;
  if (iconName === "clock") {
    return <ClockIcon size={size} />;
  }
  if (iconName === "user") {
    return <UserIcon size={size} />;
  }
  if (iconName === "pull-request") {
    return <PullRequestIcon size={size} />;
  }
  if (iconName === "calendar") {
    return <CalendarIcon size={size} />;
  }
  if (iconName === "hashtag") {
    return <HashtagIcon size={size} />;
  }
  return null;
};
