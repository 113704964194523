import { IConfig } from "@unleash/proxy-client-react";
import {
  REACT_APP_UNLEASH_APP_NAME,
  REACT_APP_UNLEASH_TOKEN,
  REACT_APP_UNLEASH_URL,
} from "./config";

export const unleashConfig: IConfig = {
  url: REACT_APP_UNLEASH_URL,
  clientKey: REACT_APP_UNLEASH_TOKEN,
  refreshInterval: 15,
  appName: REACT_APP_UNLEASH_APP_NAME,
};
