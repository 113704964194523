import clsx from "clsx";
import styles from "./TrendIndicator.module.css";

export type TrendIndicatorStatus = "up" | "down" | "neutral";

const TrendIndicatorArrow = ({ status }: { status: TrendIndicatorStatus }) => {
  if (status === "up") {
    return <span className={styles.arrow}>↑</span>;
  }
  if (status === "down") {
    return <span className={styles.arrow}>↓</span>;
  }

  /* TODO - return neutral status */
  return null;
};
interface TrendIndicatorProps {
  status?: TrendIndicatorStatus;
  value?: number;
  color: "danger" | "success" | "neutral";
  unit?: string;
}
export const TrendIndicator = ({
  status,
  value,
  color,
  unit = "%",
}: TrendIndicatorProps) => {
  if (!status || !value) return null;
  return (
    <div className={clsx(styles.wrapper, styles[color])}>
      <TrendIndicatorArrow status={status} />
      <span className={styles.value}>
        {value} {unit}
      </span>
    </div>
  );
};
