import React, { forwardRef } from "react";
import clsx from "clsx";
import { TableRow } from "../TableRow";
import styles from "./TableFooter.module.css";

export const TableFooter = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, children, ...props }, ref) => {
  return (
    <tfoot
      ref={ref}
      className={clsx("nemo-table-footer", styles.wrapper, className)}
      {...props}
    >
      <TableRow>{children}</TableRow>
    </tfoot>
  );
});
