import { PlannerPeriod } from "../types";
import { useMemo } from "react";
import { getMonthsBetweenDates } from "./utils";
import styles from "./PlannerHeaderMonths.module.css";
import { PlannerHeaderMonth } from "../PlannerHeaderMonth";

interface PlannerHeaderMonths {
  period: PlannerPeriod;
  isLoading: boolean;
}

export const PlannerHeaderMonths = ({
  period,
  isLoading,
}: PlannerHeaderMonths) => {
  const displayedMonths = useMemo(() => {
    return getMonthsBetweenDates(
      new Date(period.startDate),
      new Date(period.endDate)
    );
  }, [period]);

  return (
    <div
      className={styles.wrapper}
      style={{ gridTemplateColumns: `repeat(${displayedMonths.length}, 1fr)` }}
    >
      {displayedMonths.map((month) => {
        return (
          <PlannerHeaderMonth
            key={month.toISOString()}
            month={month}
            isLoading={isLoading}
          />
        );
      })}
    </div>
  );
};
