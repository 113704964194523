import { useIntegrationsTemplates } from "../../../hooks/integrations/templates/useIntegrationsTemplates";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  ADMIN_CONFIG_PAGE_PREFIX,
  NOT_FOUND_PATH,
} from "../../../routes/paths";
import { Toaster } from "react-hot-toast";
import { CHILD_SEPARATOR } from "components/IntegrationsConfig/IntegrationTemplateMenuItem/IntegrationTemplateMenuItem";
import { PageLayout } from "components/Layout";
import { IntegrationsConfigWrapper } from "components/IntegrationsConfig/IntegrationsConfigComponents/IntegrationsConfigWrapper/IntegrationsConfigWrapper";
import { IntegrationTemplatesMenuComponent } from "components/IntegrationsConfig/IntegrationsConfigComponents/IntegrationTemplatesMenu/IntegrationTemplatesMenuComponent";
import { IntegrationConfigContainer } from "components/IntegrationsConfig/IntegrationsConfigComponents/IntegrationConfigContainer/IntegrationConfigContainer";
import { IntegrationTemplatesMenuSkeleton } from "components/IntegrationsConfig/IntegrationsConfigComponents/IntegrationTemplatesMenu/IntegrationTemplatesMenuSkeleton";
import { IntegrationConfigContainerSkeleton } from "components/IntegrationsConfig/IntegrationsConfigComponents/IntegrationConfigContainer/IntegrationConfigContainerSkeleton";


const ConfigPage = () => {
  
  const { data, isSuccess, isLoading } = useIntegrationsTemplates();
  let { templateKey } = useParams();
  

  let currentTemplate: any = undefined;


  if (isLoading) {
    return (
      <PageLayout
        title="Integrations Configuration"
        description="Manage Your Essential Integrations Configuration Details"
        extended
      >
        <IntegrationsConfigWrapper>
          <IntegrationTemplatesMenuSkeleton />
          <IntegrationConfigContainerSkeleton />
        </IntegrationsConfigWrapper>
      </PageLayout>
    );
  }

  if (!isSuccess || !data) {
    return <Navigate to={NOT_FOUND_PATH} replace={true} />;
  }

  if (!templateKey) {
    let link = ADMIN_CONFIG_PAGE_PREFIX + "/";
    if (data[0]?.children && data[0]?.children?.length > 0)
      link += data[0].key + CHILD_SEPARATOR + data[0].children[0].key;
    else link += data[0].key;
    return <Navigate to={link} replace={true} />;
  }

  currentTemplate = data.find(
    (template) => template.key === templateKey!.split(CHILD_SEPARATOR)[0]
  );

  if (templateKey.includes(CHILD_SEPARATOR)) {
    currentTemplate = currentTemplate.children!.find(
      (child: any) => child.key === templateKey!.split(CHILD_SEPARATOR)[1]
    );
  }

  return (
    <PageLayout
      title="Integrations Configuration"
      description="Manage Your Essential Integrations Configuration Details"
      extended
    >
      <IntegrationsConfigWrapper>
            <IntegrationTemplatesMenuComponent integrationTemplates={data} currentTemplate={currentTemplate} templateKey={templateKey} />
            <IntegrationConfigContainer currentTemplate={currentTemplate} />
          </IntegrationsConfigWrapper>
      <Toaster />
    </PageLayout>
  );
};

export default ConfigPage;
