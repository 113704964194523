import IntegrationTemplate from "models/integration/IntegrationTemplate";
import { CONFIG_PARAMS_ICONS as ConfParamsIcons } from "../Integrations/utils";
import { ShieldCheckIcon } from "@heroicons/react/outline";
import { INTEGRATIONS } from "../Integrations/utils";

interface ConfigParamInfo {
    icon: React.ElementType;
    name: string;
  }

  
interface ConfigParamsIcons {
    [key: string]: ConfigParamInfo;
  }

  interface IntergrationLogos {
    [key : string] :  React.ElementType;
  }


export const getParamValue = (configParam: any, conf: any) => {
    if (configParam.type === "LIST") {
      const target = Object.entries(configParam.values!).find(
        ([key, value]) => key === conf[configParam.key]
      );
      if (target) return target[1];
      return undefined;
    }
    return conf[configParam.key];
  };

export const resolveConfigParameters = (currentTemplate : IntegrationTemplate ) => {

        const resolvedConfigParams: ConfigParamsIcons = {};
        currentTemplate.configParams!.forEach((param: any) => {
          let correspondingIcon = ConfParamsIcons.find(
            (configParamIcon) =>
              configParamIcon.type.includes(param.name.toLowerCase) ||
              param.name.toLowerCase().includes(configParamIcon.type.toLowerCase())
          )?.icon;
    
          resolvedConfigParams[param.key] = {
            name: param.name,
            icon: correspondingIcon ?? ShieldCheckIcon,
          };
        });
         return resolvedConfigParams ;

};

export const resolveIntegrationTemplates = (integrationTemplates : IntegrationTemplate[]) => {
    const resolvedIntegrations: IntergrationLogos = {};
    integrationTemplates?.forEach((int) => {
      if (int.children && int.children.length > 0) {
        int.children.forEach((child) => {
          let correpsondingInt = INTEGRATIONS.find(
            (integration) =>
              integration.key.toLowerCase() === child.key.toLowerCase()
          );
          if (correpsondingInt)
            resolvedIntegrations[child.key] = correpsondingInt.icon;
        });
      } else {
        let correpsondingInt = INTEGRATIONS.find(
          (integration) =>
            integration.key.toLowerCase() === int.key.toLowerCase()
        );
        if (correpsondingInt)
          resolvedIntegrations[int.key] = correpsondingInt.icon;
      }
    });
    return resolvedIntegrations;
}

  