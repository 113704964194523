import styles from "./MembersSelectAutoComplete.module.css";
import { ReactNode, useEffect, useState } from "react";
import { Member } from "_sredx/types";
import { useCombobox, useMultipleSelection } from "downshift";
import { MemberOptionsList } from "./MemberOptionsList";
import { SelectedMembers } from "./SelectedMembers";
import { getNewSelectedMembers } from "./utils";

interface MembersAutoCompleteProps {
  memberOptions: Member[];
  onSearch: (value: string) => void;
  onSelect: (members: Member[]) => void;
  selectedMembers?: Member[];
  isLoading?: boolean;
  isFetching?: boolean;
  loadMore?: () => void;
  loadingCaption?: ReactNode;
}

export const MembersSelectAutoComplete = ({
  onSearch,
  memberOptions,
  onSelect,
  selectedMembers = [],
  isLoading,
  isFetching,
  loadingCaption,
  loadMore,
}: MembersAutoCompleteProps) => {
  // State
  const [inputValue, setInputValue] = useState<string | undefined>("");

  // Side effects
  useEffect(() => {
    onSearch(inputValue || "");
  }, [inputValue, onSearch]);

  // Event handlers
  const handleAddItem = (newSelectedMember: Member | undefined | null) => {
    const newSelectedMembers = getNewSelectedMembers({
      selectedMembers,
      newSelectedMember,
      inputValue,
    });
    onSelect(newSelectedMembers);
    setInputValue("");
  };

  // hooks
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items: memberOptions,
    itemToString(item) {
      return item ? item.email : "";
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: false, // keep the menu open after selection.
            highlightedIndex: 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          handleAddItem(newSelectedItem);
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue);
          break;
        default:
          break;
      }
    },
  });

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems: selectedMembers,
      onStateChange: ({ selectedItems: newSelectedItems, type }) => {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            newSelectedItems && onSelect(newSelectedItems);
            break;
          default:
            break;
        }
      },
    });

  return (
    <div className={styles.wrapper}>
      <div className={styles.multiselect_wrapper}>
        <SelectedMembers
          selectedMembers={selectedMembers}
          getSelectedItemProps={getSelectedItemProps}
          removeSelectedItem={removeSelectedItem}
        />
        <div className={styles.input_wrapper}>
          <input
            placeholder={"Email"}
            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
          />
        </div>
      </div>

      {memberOptions && memberOptions.length > 0 && (
        <MemberOptionsList
          memberOptions={memberOptions}
          selectedMember={selectedItem}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          isOpen={isOpen}
          highlightedIndex={highlightedIndex}
          loadMore={loadMore}
          loadingCaption={loadingCaption}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
