import {
  TeamInfoDto,
  TeamMemberRoleType,
  UserDto,
  UserProfileDto,
} from "_sredx/types";

export const getTeamMembers = (
  selectedUsers: UserProfileDto[],
  team: TeamInfoDto,
  savedUsers: UserDto[]
) => {
  const teamMembers = selectedUsers.filter((user) =>
    user.teams.map((team) => team.id).includes(team.id)
  );
  return savedUsers
    .filter((user: UserDto) => {
      if (!user.email || !user.email.length) {
        return teamMembers
          .map((member) => member.username)
          .includes(user.username);
      }
      return teamMembers.map((member) => member.email).includes(user.email);
    })
    .map((savedMember: UserDto) => {
      return {
        userId: savedMember.id,
        role: "MEMBER" as TeamMemberRoleType,
      };
    });
};
