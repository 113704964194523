import { ResourceMinDto } from "_sredx/types/resources";
import styles from "./ServiceDetailsResourcesModal.module.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
} from "_sredx/_ui";
import { resolveResourceIcon } from "_sredx/utils";
import { Link } from "react-router-dom";
import { MdOpenInNew } from "react-icons/md";

interface ServiceDetailsResourcesModalProps {
  resources: ResourceMinDto[];
  onClose: () => void;
}

export const ServiceDetailsResourcesModal = ({
  resources,
  onClose,
}: ServiceDetailsResourcesModalProps) => {
  return (
    <Card className="min-w-[500px]">
      <CardHeader>
        <Heading level={3}>Resources</Heading>
      </CardHeader>
      <CardBody>
        <div className={styles.wrapper}>
          <div className={styles.column}>
            {resources.map((resource, index) => (
              <div key={index} className={styles.name}>
                {resolveResourceIcon(resource)}
                {resource.name}
              </div>
            ))}
          </div>
          <div className={styles.column}>
            {resources.map((resource, index) => (
              <Link
                key={index}
                className={styles.resource_type}
                to={"/resources"}
              >
                {resource.product}
                {resource.version && ` @ ${resource.version}`}
                <MdOpenInNew size={12} />
              </Link>
            ))}
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div className={styles.buttons}>
          <Button onClick={onClose}>Close</Button>
        </div>
      </CardFooter>
    </Card>
  );
};
