import { useQuery } from "react-query";
import { API_ENDPOINTS, QUERY_KEYS } from "_sredx/config";
import { TeamBaseDto } from "_sredx/types";
import { ClientApi } from "_sredx/services/clientApi";

interface UseGetTeamProps {
  id: string;
}

export const useGetTeam = ({ id }: UseGetTeamProps) => {
  return useQuery<TeamBaseDto>({
    queryKey: [QUERY_KEYS.team, id],
    queryFn: () => {
      return ClientApi.get(API_ENDPOINTS.getTeamById(id));
    },
    retry: 0,
    refetchOnWindowFocus: false,
  });
};
