import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";

import { DomainRowAction, DomainRowActionType } from "./types";
import { Text } from "_sredx/_ui";
import { ThreeDotsIcon, TrashIcon } from "_sredx/_ui/Icon";
import React from "react";

import styles from "./DomainDataTableRow.module.css";
import clsx from "clsx";
import { PencilIcon } from "@heroicons/react/outline";

interface DomainRowActionsProps {
  onAction: (actionType: DomainRowActionType) => void;
}

export const DOMAIN_ROW_ACTIONS: DomainRowAction[] = [
  {
    type: "UPDATE",
    component: (
      <div className={styles.action_wrapper}>
        <PencilIcon className={styles.icon} />
        <Text size={"md"}>Update</Text>
      </div>
    ),
  },
  {
    type: "DELETE",
    component: (
      <div className={clsx(styles.action_wrapper, styles.trash)}>
        <TrashIcon className={styles.icon} />
        <Text size={"md"}>Delete</Text>
      </div>
    ),
  },
];

export const DomainRowActions = ({ onAction }: DomainRowActionsProps) => {
  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger className={styles.dropdown_trigger}>
        <div
          style={{
            width: "20px",
            height: "20px",
          }}
        >
          <ThreeDotsIcon />
        </div>
      </RadixDropdownMenu.Trigger>

      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content className={styles.dropdown_menu} align="end">
          {DOMAIN_ROW_ACTIONS.map((action, index) => {
            return (
              <RadixDropdownMenu.Item
                key={index}
                className={styles.dropdown_menu_item}
                onSelect={() => onAction(action.type)}
              >
                {action.component}
              </RadixDropdownMenu.Item>
            );
          })}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};
