import { Loader } from "_sredx/_ui/Loader";
import styles from "./SelectPopoverLoader.module.css";
import { ReactNode } from "react";

interface SelectPopoverLoaderProps {
  caption?: ReactNode;
}

export const SelectPopoverLoader = ({ caption }: SelectPopoverLoaderProps) => {
  return (
    <div className={styles.wrapper}>
      <Loader />
      <span>{caption}</span>
    </div>
  );
};
