import { API_ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from "_sredx/config";
import { AccountCreationDto } from "_sredx/types";
import { useMutation, useQueryClient } from "react-query";
import { ClientApi } from "../clientApi";

export const useCreateAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.createAccount],
    mutationFn:  (accountCreationDto: AccountCreationDto) =>  {
      return ClientApi.post(API_ENDPOINTS.createAccount(),accountCreationDto,{
        headers:{
          Accept:"application/json"
        }
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.users]);
    },
  });
};
