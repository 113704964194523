import {
  Github,
  Gitlab,
  Harbor,
  Pivotal,
  Jira,
  SonarQube,
  DTrack,
  Datadog,
  PagerDuty,
  Dynatrace,
  Backstage,
  Jenkins,
  CodeCov
} from "./IntegrationsLogos";

import {
  KeyIcon,
  AtSymbolIcon,
  LockClosedIcon,
  LinkIcon,
  CheckIcon,
  AdjustmentsIcon,
  ShieldCheckIcon,
} from "components/octo-ui/Icons/outline";

export const INTEGRATIONS_KEYS = {
  GITHUB: "github",
  GITLAB: "gitlab",
  JIRA: "jira",
  SONAR: "sonar",
  HARBOR: "harbor",
  DATADOG: "datadog",
  DYNATRACE: "dynatrace",
  PAGERDUTY: "pagerduty",
  PIVOTAL: "pivotal",
  DEPENDENCYTRACK: "dependency-track",
  BACKSTAGE: "backstage",
  JENKINS: "jenkins",
  CODECOV: "codecov",
};

export type ExternalServiceKeyType =
  (typeof INTEGRATIONS_KEYS)[keyof typeof INTEGRATIONS_KEYS];

export const INTEGRATIONS = [
  {
    id: 1,
    name: "Github",
    icon: Github,
    key: INTEGRATIONS_KEYS.GITHUB,
  },

   {
     id: 2,
     name: "Gitlab",
     icon: Gitlab,
     key: INTEGRATIONS_KEYS.GITLAB,
   },
   {
     id: 3,
     name: "Jira",
     icon: Jira,
     key: INTEGRATIONS_KEYS.JIRA,
   },
   {
     id: 4,
     name: "Sonar",
     icon: SonarQube,
     key: INTEGRATIONS_KEYS.SONAR,
   },
   {
     id: 5,
     name: "Harbor",
     icon: Harbor,
     key: INTEGRATIONS_KEYS.HARBOR,
   },
   {
     id: 6,
     name: "Datadog",
     icon: Datadog,
     key: INTEGRATIONS_KEYS.DATADOG,
   },
   {
     id: 7,
     name: "Dynatrace",
     icon: Dynatrace,
     key: INTEGRATIONS_KEYS.DYNATRACE,
   },
   {
     id: 8,
     name: "PagerDuty",
     icon: PagerDuty,
     key: INTEGRATIONS_KEYS.PAGERDUTY,
   },
   {
     id: 9,
     name: "Pivotal",
     icon: Pivotal,
     key: INTEGRATIONS_KEYS.PIVOTAL,
   },
   {
     id: 10,
     name: "DependencyTrack",
     icon: DTrack,
     key: INTEGRATIONS_KEYS.DEPENDENCYTRACK,
   },
   {
     id: 11,
     name: "Backstage",
     icon: Backstage,
     key: INTEGRATIONS_KEYS.BACKSTAGE,
   },
  {
    id: 12,
    name: "Jenkins",
    icon: Jenkins,
    key: INTEGRATIONS_KEYS.JENKINS,
  },
  {
    id: 13,
    name: "CodeCov",
    icon: CodeCov,
    key: INTEGRATIONS_KEYS.CODECOV,
  },
];

export const CONFIG_PARAMS_ICONS = [
  {
    id: 1,
    type: "token",
    icon: ShieldCheckIcon,
  },
  {
    id: 2,
    type: "key",
    icon: KeyIcon,
  },
  {
    id: 3,
    type: "username",
    icon: AtSymbolIcon,
  },
  {
    id: 4,
    type: "password",
    icon: LockClosedIcon,
  },
  {
    id: 5,
    type: "url",
    icon: LinkIcon,
  },
  {
    id: 6,
    type: "url",
    icon: LinkIcon,
  },
  {
    id: 7,
    type: "ID",
    icon: CheckIcon,
  },
  {
    id: 8,
    type: "type",
    icon: AdjustmentsIcon,
  },
];
