import { forwardRef } from "react";
import { IconProps } from "./types";

export const EditIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          fillOpacity=".66"
          fillRule="evenodd"
          d="M1 22.429h22v-2.132H1v2.132Zm10.826-6.394H7.6V11.66L18.423 1 23 5.41 11.826 16.035Z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
);
