import { PropsWithChildren } from "react";
import styles from "./SelectPopoverItems.module.css";

interface SelectPopoverItemProps {
  onClick?: () => void;
}
export const SelectPopoverItem = ({
  onClick,
  children,
}: PropsWithChildren<SelectPopoverItemProps>) => {
  return (
    <li className={styles.item} onClick={onClick}>
      {children}
    </li>
  );
};
