function ClusterCardSkeleton() {
  return (
    <div className="p-4 bg-gray-100 rounded-md">

    <div className="mb-4 flex justify-between items-center">
        <div className="w-1/3 h-5 bg-gray-300 animate-pulse"></div>
        <div className="w-2/4 h-5 bg-gray-300 animate-pulse"></div>
    </div>

    <div className="mb-4 flex items-center">
        <div className="w-6 h-6 bg-gray-300 animate-pulse mr-2"></div>
        <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
    </div>

        <div  className="mb-4 flex items-center">
            <div className="w-6 h-6 bg-gray-300 animate-pulse mr-2"></div>
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
        </div>
        <div  className="mb-4 flex items-center">
            <div className="w-6 h-6 bg-gray-300 animate-pulse mr-2"></div>
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
        </div>
        <div  className="mb-4 flex items-center">
            <div className="w-6 h-6 bg-gray-300 animate-pulse mr-2"></div>
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
        </div>
        <div  className="mb-4 flex items-center">
            <div className="w-6 h-6 bg-gray-300 animate-pulse mr-2"></div>
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
        </div>
        <div  className="mb-4 flex items-center">
            <div className="w-6 h-6 bg-gray-300 animate-pulse mr-2"></div>
            <div className="w-full h-4 bg-gray-300 animate-pulse"></div>
        </div>

    <div className="flex space-x-2">
            <div  className="w-8 h-8 bg-gray-300 animate-pulse"></div>
            <div  className="w-8 h-8 bg-gray-300 animate-pulse"></div>
            <div  className="w-8 h-8 bg-gray-300 animate-pulse"></div>
            <div  className="w-8 h-8 bg-gray-300 animate-pulse"></div>
            <div  className="w-8 h-8 bg-gray-300 animate-pulse"></div>
    </div>

</div>
  )
}

export default ClusterCardSkeleton