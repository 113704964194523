import React from 'react';
import {generatePath, Link} from "react-router-dom";
import { ADMIN_SCORECARD_DETAILS_PAGE } from 'routes/paths';
import { ScorecardComponent } from 'components/ScorecardComponents/ScorecardComponent';
import style from "./ScorecardListContainer.module.css";
import { useScoreCards } from 'hooks/scorcards/useScoreCards';
import { ScorecardSkeletonComponent } from 'components/ScorecardComponents/ScorecardSkeletonComponent/ScorecardSkeletonComponent';
import { useLevelsMedia } from 'hooks/media/useLevelsMedia';
import { getScorecardWithMedia } from 'services/media/media.helper';

export const ScorecardListContainer = () => {

    const {data : scorecardData, isLoading : isScorecardsLoading , isLoadingError : isScorecardsLoadingError} = useScoreCards(true);
    const {data : mediaData , isLoading : isMediaLoading , isLoadingError : isMediaLoadingError } = useLevelsMedia();

    const getPath = (id : string) => {
        return generatePath(ADMIN_SCORECARD_DETAILS_PAGE, {id})
    }


    if(isScorecardsLoading || isMediaLoading) {
        return <div className={style.wrapper}>
            <ScorecardSkeletonComponent/>
            <ScorecardSkeletonComponent/>
            <ScorecardSkeletonComponent/>
            <ScorecardSkeletonComponent/>
        </div>
    }

    if(isScorecardsLoadingError || isMediaLoadingError) {
        return <div className={style.error_message}>Something went wrong ...</div>
    }

  return (
    <div className={style.wrapper} data-testid="scorecards-list">
      {scorecardData?.map((scorecard) => (
        <Link key={scorecard.id} to={{pathname : getPath(scorecard.id)}}>
          <ScorecardComponent  scorecard={getScorecardWithMedia(scorecard, mediaData ?? [])} />
        </Link>
      ))}
    </div>
  );
}
