import React from 'react';
import style from "./ScorecardMainInfosSectionComponent.module.css";

interface ScorecardMainInfosSectionComponentProps{
    name: string;
    description : string;
}

export const ScorecardMainInfosSectionComponent = ({name, description} : ScorecardMainInfosSectionComponentProps) => {
  return (
    <div className={style.wrapper}>
        <p className={style.name}>{name}</p>
        <p className={style.description}>{description}</p>
    </div>
  )
}
