// Temp =====================================================================

import { AchievementDto } from "./types";

export const convertToElement = (inputString: string, badgesList: string[]) => {
  const hash = calculateHash(inputString);
  const index = hash % badgesList.length;

  // Return the corresponding element from the list
  return badgesList[index];
};

export const calculateHash = (inputString: string) => {
  let hash = 0;
  for (let i = 0; i < inputString.length; i++) {
    hash = (hash << 6) - hash + inputString.charCodeAt(i);
  }
  return Math.abs(hash);
};

// =====================================================================

export const groupAchievements = (achievements: AchievementDto[]) => {
  const result: Record<string, AchievementDto[]> = {};

  for (const achievement of achievements) {
    const key = achievement.levelName + achievement.scoreCardName;
    if (result[key]) {
      result[key].push(achievement);
    } else {
      result[key] = [achievement];
    }
  }

  return result;
};
