import { useState } from "react";
import {
  FilterPropertyType,
  FilterOperatorType,
  FILTER_OPERATORS,
} from "../types";
import {
  Popover,
  PopoverContent,
  PopoverItem,
  PopoverTrigger,
} from "../Popover";
import styles from "./AdvancedFilterOperator.module.css";
import { getOperatorOptions } from "./utils";

const getOperatorLabel = (operator?: FilterOperatorType) => {
  switch (operator) {
    case FILTER_OPERATORS.EQUALS:
      return "Equals";
    case FILTER_OPERATORS.NOT_EQUALS:
      return "Not equals";
    case FILTER_OPERATORS.CONTAINS:
      return "Contains";
    case FILTER_OPERATORS.NOT_CONTAINS:
      return "Not contains";
    case FILTER_OPERATORS.GREATER_THAN:
      return "Greater than";
    case FILTER_OPERATORS.GREATER_THAN_OR_EQUAL:
      return "Greater than or equal";
    case FILTER_OPERATORS.LESS_THAN:
      return "Less than";
    case FILTER_OPERATORS.LESS_THAN_OR_EQUAL:
      return "Less than or equal";
    case FILTER_OPERATORS.BETWEEN:
      return "Between";
    case FILTER_OPERATORS.NOT_BETWEEN:
      return "Not between";
    case FILTER_OPERATORS.IN:
      return "In";
    case FILTER_OPERATORS.NOT_IN:
      return "Not in";
    case FILTER_OPERATORS.IS_NULL:
      return "Is null";
    case FILTER_OPERATORS.IS_NOT_NULL:
      return "Is not null";
    case FILTER_OPERATORS.FROM:
      return "From";
    case FILTER_OPERATORS.BEFORE:
      return "Before";
    case FILTER_OPERATORS.AFTER:
      return "After";

    default:
      return "Operator";
  }
};

interface AdvancedFiltersOperatorProps {
  type?: FilterPropertyType;
  value?: FilterOperatorType;
  onChange: (operator: FilterOperatorType) => void;
  disabled?: boolean;
}

export const AdvancedFiltersOperator = ({
  value,
  type,
  onChange,
}: AdvancedFiltersOperatorProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const options = getOperatorOptions(type);

  const handleOperatorChange = (operator: FilterOperatorType) => {
    setPopoverOpen(false);
    onChange(operator);
  };
  return (
    <div className={styles.wrapper}>
      <Popover onOpenChange={(val) => setPopoverOpen(val)} open={popoverOpen}>
        <PopoverTrigger disabled={!value}>
          {getOperatorLabel(value)}
        </PopoverTrigger>
        <PopoverContent>
          {options.map((option) => {
            const selected = option.value === value;
            return (
              <PopoverItem
                key={option.value}
                selected={selected}
                onClick={() => handleOperatorChange(option.value)}
              >
                {option.label}
              </PopoverItem>
            );
          })}
        </PopoverContent>
      </Popover>
    </div>
  );
};
