import { Button, ButtonLabel, Loader, Text } from "_sredx/_ui";
import { OnboardingNoValidConfigModal } from "_sredx/components";
import { OnboardingProviderConfirmationModal } from "_sredx/components";
import { ProviderConfigurationCreationContainer } from "_sredx/containers";
import { useModal } from "_sredx/hooks";
import { useUpdateUser } from "_sredx/services";
// import { useGetIntegrationsConfig } from "_sredx/services/configurations";
import { useIntegrationsTemplates } from "hooks/integrations/templates/useIntegrationsTemplates";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminOnboardingStep } from "../AdminOnboardingContainer";
import styles from "./ProviderConfigurationStepContainer.module.css";
import { EXTERNAL_PROVIDERS } from "./types";
import {
  getCurrentIntegrationInstanceConfig,
  resolveIntegrationConfig,
  // resolveIntegrationInstanceConfig,
} from "./utils";
import { UseGetIntegrationInstances } from "_sredx/services/configurations/useGetIntegrationInstances";

interface ProviderConfigurationStepContainerProps extends AdminOnboardingStep {
  providerValidationResults: Record<string, boolean>;
  handleAddProviderValidationResult: (
    provider: string,
    result: boolean
  ) => void;
}

export const ProviderConfigurationStepContainer = ({
  onNextStep,
  providerValidationResults,
  handleAddProviderValidationResult,
}: ProviderConfigurationStepContainerProps) => {
  // Hooks
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  // States
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  // Services
  const {
    data: integrationTemplates,
    isLoading: isLoadingIntegrationTemplates,
    isError: isErrorIntegrationTempltaes,
  } = useIntegrationsTemplates();

  const { mutate: updateUser, isLoading: isLoadingProfileUpdate } =
    useUpdateUser();

  const providersKeys = EXTERNAL_PROVIDERS.map((provider) => provider.key);
  const {
    data: integrationsInstances,
    isLoading: isLoadingConfig,
    isError: isErrorConfig,
  } = UseGetIntegrationInstances({
    integrationKeys: providersKeys,
  });

  // Event handlers
  const handleItemClick = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleOnNextStep = () => {
    if (onNextStep) {
      const failingProviderConfig = Object.keys(providerValidationResults).some(
        (provider) => !providerValidationResults[provider]
      );
      const passingProviderConfig = Object.keys(providerValidationResults).some(
        (provider) => providerValidationResults[provider]
      );

      if (!passingProviderConfig)
        openModal({
          content: <OnboardingNoValidConfigModal onClose={closeModal} />,
        });
      else if (failingProviderConfig)
        openModal({
          content: (
            <OnboardingProviderConfirmationModal
              onClose={closeModal}
              onConfirm={onNextStep}
            />
          ),
        });
      else onNextStep();
    }
  };

  const handleOnboardingSkip = () => {
    updateUser(
      { onboarded: true },
      {
        onSuccess: () => {
          navigate("/");
        },
      }
    );
  };

  // Derived variables
  const configParams = useMemo(() => {
    return resolveIntegrationConfig(integrationTemplates, providersKeys);
  }, [integrationTemplates]);

  // UI waterfall
  if (isLoadingIntegrationTemplates || isLoadingConfig) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>
          Loading integration... <br /> Please wait, this might take a moment.
        </Text>
      </div>
    );
  }

  if (isLoadingProfileUpdate) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text size={"lg"}>
          Updating profile... <br /> Please wait, this might take a moment.
        </Text>
      </div>
    );
  }

  if (isErrorIntegrationTempltaes || isErrorConfig) {
    return (
      <div className={styles.error_wrapper}>
        <Text className={styles.text_wrapper}>Error loading integrations</Text>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.forms_wrapper}>
        {EXTERNAL_PROVIDERS.map((provider, index) => (
          <ProviderConfigurationCreationContainer
            key={index}
            serviceProvider={provider}
            configParams={configParams[provider.key] ?? []}
            providerValidationResult={providerValidationResults}
            onAddProviderValidationResult={handleAddProviderValidationResult}
            currentConfig={getCurrentIntegrationInstanceConfig(provider.key, integrationsInstances)}
            isOpen={activeIndex === index}
            onClick={() => handleItemClick(index)}
          />
        ))}
      </div>

      <div className={styles.actions_wrapper}>
        <Button variant="neutral" onClick={handleOnboardingSkip}>
          <ButtonLabel>Skip Onboarding</ButtonLabel>
        </Button>

        <Button onClick={handleOnNextStep}>
          <ButtonLabel>Next</ButtonLabel>
        </Button>
      </div>
    </div>
  );
};
