import {
  FaChessBishop,
  FaChessKing,
  FaChessKnight,
  FaChessPawn,
  FaChessQueen,
  FaChessRook,
} from "react-icons/fa";

type ChessIconResult = {
  icon: JSX.Element;
  bgColor: string;
  color: string;
  borderClass?: string;
};

export const getChessIconWithColorsByRank = (rank: number): ChessIconResult => {
  switch (rank) {
    case 1:
      return {
        icon: <FaChessKing className="h-6 w-6" />,
        bgColor: "#FFB300",
        color: "#AB7800",
      };
    case 2:
      return {
        icon: <FaChessQueen className="h-6 w-6" />,
        bgColor: "#C0C0C0",
        color: "#757575",
      };
    case 3:
      return {
        icon: <FaChessRook className="h-6 w-6" />,
        bgColor: "#CD7F32",
        color: "#885420",
      };
    case 4:
      return {
        icon: <FaChessBishop className="h-6 w-6" />,
        bgColor: "#AB6EBA",
        color: "#77577E",
      };
    case 5:
      return {
        icon: <FaChessKnight className="h-6 w-6" />,
        bgColor: "#6EB2BA",
        color: "#1D7985",
      };

    default:
      return {
        icon: <FaChessPawn className="h-6 w-6" />,
        bgColor: "#ffffff",
        color: "#59676C",
        borderClass: "border border-gray-300",
      };
  }
};
