import clsx from "clsx";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getScorecardWithMedia } from "services/media/media.helper";
import { ScoreCardFormComponent } from "components/scorecard/ScoreCardForm/ScoreCardFormComponent/ScoreCardFormComponent";
import { useSingleScoreCard } from "hooks/scorcards/useSingleScoreCard";
import { extractLevelsId } from "utils/scoreCard";
import { useLevelsMediaByOwnerTypeAndIds } from "hooks/media/useLevelsMediaByOwnerTypeAndIds";
import { useDeleteScoreCard } from "hooks/scorcards/useDeleteScoreCard";

import { useModal } from "_sredx/hooks";
import styles from "./ScoreCardEditContainer.module.css";
import {
  Button,
  ButtonIcon,
  ButtonLabel,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Heading,
  Loader,
  Text,
} from "_sredx/_ui";
import { TrashIcon } from "_sredx/_ui/Icon";

export const ScoreCardEditContainer = () => {
  // State
  const { scorecardId } = useParams<{ scorecardId: string }>();

  // Hooks
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  // Services
  const { data: scorecard, isLoading: isLoadingScoreCard } = useSingleScoreCard(
    scorecardId ?? ""
  );

  const extractedScoreCardLevelIds = extractLevelsId(scorecard?.levels);

  const { data: levelsMedia, isLoading: isLoadingLevelsMedia } =
    useLevelsMediaByOwnerTypeAndIds({
      levelsId: extractedScoreCardLevelIds,
      ownerType: "level",
    });

  const { mutateAsync: deleteScoreCard, isLoading: isDeleteLoading } =
    useDeleteScoreCard(scorecardId!, () => {
      navigate("/scorecards");
    });

  const handleOnDelete = () => {
    openModal({
      content: (
        <Card>
          <CardHeader>
            <CardTitle>Are you sure?</CardTitle>
          </CardHeader>
          <CardBody>
            <div style={{ minWidth: "300px", marginBottom: "24px" }}>
              This action might take time.
            </div>
          </CardBody>
          <CardFooter>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "16px",
              }}
            >
              <Button
                type="button"
                data-testid={"close-error-alert-button"}
                onClick={closeModal}
                variant={"neutral"}
                ghost
              >
                Cancel
              </Button>
              <Button
                type="button"
                data-testid={"confirm-error-alert-button"}
                onClick={async () => {
                  await deleteScoreCard();
                  closeModal();
                }}
              >
                Confirm
              </Button>
            </div>
          </CardFooter>
        </Card>
      ),
    });
  };
  const handleOnCancel = async () => {
    navigate(`/scorecards/${scorecardId}`);
  };

  if (isLoadingScoreCard || isLoadingLevelsMedia) {
    return (
      <Card>
        <div className={styles.loader_wrapper}>
          <Loader />
          <Text size={"lg"}>Loading...</Text>
        </div>
      </Card>
    );
  }

  if (!scorecard) return null;

  return (
    <div className={clsx(styles.wrapper)}>
      <div className={styles.header}>
        <Heading>{scorecard.name}</Heading>
        <div className={styles.actions_buttons}>
          <Button onClick={handleOnCancel}>
            <ButtonLabel>Cancel</ButtonLabel>
          </Button>
          <Button onClick={handleOnDelete} isLoading={isDeleteLoading}>
            <ButtonIcon>
              <TrashIcon />
            </ButtonIcon>
            <ButtonLabel>Delete</ButtonLabel>
          </Button>
        </div>
      </div>
      <div className={styles.form_wrapper}>
        <ScoreCardFormComponent
          scoreCard={getScorecardWithMedia(scorecard, levelsMedia ?? [])}
        />
      </div>
    </div>
  );
};
