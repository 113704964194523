import { RuleInsightItem } from "_sredx/containers/DashboardContainer/MostPassingRulesContainer/types";
import styles from "./RulesInsightList.module.css";
import clsx from "clsx";
import { ProgressBar, Text } from "_sredx/_ui";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";

export const RulesInsightList = ({ rules }: { rules: RuleInsightItem[] }) => {
  return (
    <div className={clsx(styles.wrapper)}>
      {rules.slice(0, 5).map((rule) => {
        return (
          <div key={rule.ruleId} className={styles.rule_row}>
            <Text className={styles.rule_name}>{rule.ruleName}</Text>
            <div className={styles.rules_count}>
              <div className={styles.rule_count}>
                <Text>{rule.totalSuccess}</Text>
                <CheckCircleIcon className={styles.success} />
              </div>
              <div className={styles.rule_count}>
                <Text>{rule.totalFailing}</Text>
                <XCircleIcon className={styles.failure} />
              </div>
            </div>
            <div className={styles.progress_wrapper}>
              <ProgressBar
                label={`${rule.successRatePercentage.toFixed(1)}%`}
                labelPosition={"right"}
                infinite={false}
                percent={rule.successRatePercentage}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
