import {
  Button,
  ButtonLabel,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "_sredx/_ui";
import { FormFieldInput, FormFieldTextArea } from "../Form";
import { Resolver, useForm } from "react-hook-form";
import { DomainInfoFormData } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./utils";

import styles from "./DomainInfoForm.module.css";

const DOMAIN_INFO_DEFAULT_FORM_DATA: DomainInfoFormData = {
  name: "",
  description: "",
};

interface DomainInfoFormProps {
  action: "CREATION" | "UPDATE";
  onSubmit: (data: DomainInfoFormData) => void;
  onCancel: () => void;
  isLoading: boolean;
  defaultValues?: DomainInfoFormData;
}

export const DomainInfoForm = ({
  action,
  onSubmit,
  onCancel,
  isLoading,
  defaultValues,
}: DomainInfoFormProps) => {
  // hooks
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<DomainInfoFormData>({
    mode: "onChange",
    resolver: yupResolver(schema) as Resolver<DomainInfoFormData>,
    defaultValues: defaultValues ?? DOMAIN_INFO_DEFAULT_FORM_DATA,
  });

  // event handlers
  const handleOnSubmit = (data: DomainInfoFormData) => {
    onSubmit(data);
  };

  const handleOnCancel = () => {
    onCancel();
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {action == "CREATION" ? "Create Domain" : "Update Domain"}
        </CardTitle>
      </CardHeader>

      <CardBody>
        <div className={styles.body}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <FormFieldInput
              name={"name"}
              control={control}
              label={"name"}
              placeholder={"domain name"}
              errorMessage={errors.name && errors.name.message}
              fullWidth
            />
            <FormFieldTextArea
              name={"description"}
              control={control}
              label={"description"}
              placeholder={"what is the domain all about"}
              errorMessage={errors.description && errors.description.message}
              fullWidth
              rows={3}
            />
            <div className={styles.action_buttons}>
              <Button
                variant={"neutral"}
                ghost
                onClick={handleOnCancel}
                type={"button"}
              >
                <ButtonLabel>cancel</ButtonLabel>
              </Button>
              <Button disabled={!isValid} isLoading={isLoading} type={"submit"}>
                <ButtonLabel>
                  {action == "CREATION" ? "create" : "update"}
                </ButtonLabel>
              </Button>
            </div>
          </form>
        </div>
      </CardBody>
    </Card>
  );
};
