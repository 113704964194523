import { Heading } from "_sredx/_ui";
import { InitiativeDataTable } from "_sredx/components";
import { useTeamContext } from "_sredx/context";
import { useGetInitiatives } from "_sredx/services";
import { parsePageNumber } from "_sredx/utils";
import clsx from "clsx";
import React, { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./TeamInitiativesContainer.module.css";

export const TeamInitiativesContainer = () => {
  //hooks
  const navigate = useNavigate();
  const { selectedTeam } = useTeamContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = parsePageNumber(searchParams.get("initiativePage"));

  const teamId = selectedTeam ? selectedTeam.id : "";
  //data
  const {
    data: initiatives,
    isLoading,
    isFetching,
  } = useGetInitiatives({
    params: {
      teamIds: [teamId],
      pageNumber: currentPage,
      evaluated: true,
    },
  });
  // Derived variables
  const resolvedInitiatives = useMemo(() => {
    if (!teamId || !initiatives) return [];
    return initiatives.content;
  }, [initiatives, teamId]);

  //handlers
  const handleClickInitiativeRow = (initiativeId: string) => {
    navigate(`/initiatives/${initiativeId}`);
  };
  const handleOnPageChange = (page: number) => {
    setSearchParams({ initiativePage: (page + 1).toString() });
  };

  return (
    <div className={clsx(styles.wrapper)}>
      <Heading level={4}>My team Initiatives </Heading>
      <InitiativeDataTable
        isLoading={isLoading}
        isFetching={isFetching}
        onRowClick={handleClickInitiativeRow}
        initiatives={resolvedInitiatives}
        totalElements={initiatives?.totalElements}
        totalPages={initiatives?.totalPages}
        currentPage={currentPage}
        onPageChange={handleOnPageChange}
        isTeamsSelected={true}
      />
    </div>
  );
};
