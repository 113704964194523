import { Text } from "_sredx/_ui";
import { ClusterDetailsFoundation } from "_sredx/types";
import clsx from "clsx";
import StatusIndicator from "components/octo-ui/StatusIndicator/StatusIndicator";
import { resolveToolUrl } from "components/self-service/ClusterCard/utils";
import React from "react";
import styles from "./ClusterFoundationsToolCard.module.css";
import { determineStatusLabel, mapStatusToIndicator } from "./utils";

interface ClusterFoundationsToolCardProps {
  clusterFoundation: ClusterDetailsFoundation;
}

interface FoundationImageProps {
  imageName: string;
}

const FoundationImage = ({ imageName }: FoundationImageProps) => {
  const ResolvedImg = resolveToolUrl(imageName);
  if(ResolvedImg){
    return <ResolvedImg />
  }
  return <Text>{imageName}</Text>;
};

export const ClusterFoundationsToolCard = ({
  clusterFoundation,
}: ClusterFoundationsToolCardProps) => {
  const status = mapStatusToIndicator(clusterFoundation.ready);
  const statusLabel = determineStatusLabel(clusterFoundation.ready);
  return (
    <div className={clsx(styles.wrapper)}>
      <div className={styles.imageContainer}>
        <FoundationImage imageName={clusterFoundation.name} />
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.left}>
          {clusterFoundation.name}
          {/* <div className={styles.badge}>{tool.type}</div> */}
        </div>
        <StatusIndicator label={statusLabel} status={status} />
      </div>
    </div>
  );
};
