import {
  Button,
  ButtonLabel,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Input,
} from "_sredx/_ui";
import { InitiativesAssignmentTable } from "_sredx/components/InitiativesAssignmentTable";
import { useDebounce } from "_sredx/hooks";
import { useGetInitiatives } from "_sredx/services";
import { InitiativeBaseDto } from "_sredx/types";
import React, { useState } from "react";
import styles from "./RoadmapInitiativesAssignmentContainer.module.css";

interface RoadmapInitiativesAssignmentContainerProps {
  onCancel: () => void;
  onConfirm: (initiatives: InitiativeBaseDto[]) => void;
  selectedInitiatives?: InitiativeBaseDto[];
}

export const RoadmapInitiativesAssignmentContainer = ({
  onCancel,
  onConfirm,
  selectedInitiatives = [],
}: RoadmapInitiativesAssignmentContainerProps) => {
  //state
  const [query, setQuery] = useState<string>("");
  const [checkedInitiatives, setCheckedInitiatives] =
    useState<InitiativeBaseDto[]>(selectedInitiatives);
  const [currentPage, setCurrentPage] = useState<number>(0);

  //hooks
  const debouncedQuery = useDebounce(query, 400);
  // query
  const {
    data: initiativesData,
    isLoading: isLoadingInitiatives,
    isFetching: isFetchingInitiatives,
  } = useGetInitiatives({
    params: {
      name: debouncedQuery,
      pageNumber: currentPage,
    },
  });
  //
  const initiatives = (initiativesData && initiativesData.content) || [];
  // Event handlers
  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    setCurrentPage(0);
  };
  const handleOnCheckChange = (checked: boolean, initiativeId: string) => {
    if (checked) {
      const initiative = initiatives.find((i) => i.id === initiativeId);
      if (initiative) {
        setCheckedInitiatives((prevState) => [...prevState, initiative]);
      }
    } else {
      setCheckedInitiatives(
        checkedInitiatives.filter((i) => i.id !== initiativeId)
      );
    }
  };
  const handleOnConfirm = () => {
    onConfirm(checkedInitiatives);
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle>Add initiatives</CardTitle>
        <CardSubtitle>search and add initiatives</CardSubtitle>
      </CardHeader>
      <CardBody noPadding>
        <div className={styles.search_wrapper}>
          <Input
            placeholder={"Search initiatives"}
            value={query}
            onChange={handleOnSearch}
            fullWidth
          />
        </div>
        <div className={styles.table_wrapper}>
          <InitiativesAssignmentTable
            initiatives={initiatives}
            checkedInitiatives={checkedInitiatives}
            totalPages={initiativesData && initiativesData.totalPages}
            totalInitiatives={initiativesData && initiativesData.totalElements}
            onCheckChange={handleOnCheckChange}
            onPageChange={setCurrentPage}
            isLoading={isLoadingInitiatives}
            isFetching={isFetchingInitiatives}
            currentPage={currentPage}
          />
        </div>
        <div className={styles.button_wrapper}>
          <Button onClick={onCancel} variant={"neutral"} ghost>
            <ButtonLabel>Cancel</ButtonLabel>
          </Button>
          <Button onClick={handleOnConfirm}>
            <ButtonLabel>Confirm</ButtonLabel>
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};
