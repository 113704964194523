import styles from "./NextLevelsActions.module.css";
import { NextLevel } from "./NextLevel";
import { EvaluationLevelsResultDto } from "_sredx/types";
import { useState } from "react";
import { Text } from "_sredx/_ui";

interface NextLevelActionsProps {
  evaluations: EvaluationLevelsResultDto[];
  onClick?: (serviceId: string) => void;
}

export const NextLevelsActions = ({
  evaluations,
  onClick,
}: NextLevelActionsProps) => {
  // State
  const [showAll, setShowAll] = useState(false);

  // Derived Variables
  const displayedEvaluations = showAll ? evaluations : evaluations.slice(0, 3);
  const hasMore = evaluations.length > 3;

  if (displayedEvaluations.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.wrapper}>
        {displayedEvaluations.map((evaluation,index) => {
          return <NextLevel key={index} evaluation={evaluation} onClick={onClick} />;
        })}
      </div>

      {hasMore && (
        <div className={styles.expand_wrapper}>
          <button onClick={() => setShowAll(!showAll)}>
            <Text size={"sm"}>{!showAll ? "Show more" : "Hide"}</Text>
          </button>
        </div>
      )}
    </>
  );
};
